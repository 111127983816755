import { red } from '@carbon/colors';
import { FormRowStatus } from 'atoms/create-resource-atom';
import { Map } from 'components/map';
import { createBoundingBoxAtom } from 'components/map/atoms';
import { QuarteredPolygon } from 'components/map/quartered-polygon';
import { MapUserConfigAtom } from 'components/map/types';
import { useCalculateMapDimensions } from 'components/map/utils';
import { useAtomValue } from 'jotai';
import { useMemo, useRef } from 'react';
import { ReferenceLocationResponse } from 'types/api-responses';

import style from './development-area-map.module.scss';
import { resourcesAtom } from 'atoms/root';

interface Props {
  referenceLocationIds: number[];
  geographies: {
    id: number | null;
    referenceLocation: ReferenceLocationResponse;
    quarterCalls: string[];
    status?: FormRowStatus;
  }[];
  mapConfigAtom: MapUserConfigAtom;
}

const DevelopmentAreaMap = ({
  referenceLocationIds,
  geographies,
  mapConfigAtom,
}: Props) => {
  const containerRef = useRef(null);
  const resources = useAtomValue(resourcesAtom);
  const idsMemoString = referenceLocationIds.join('-');
  const boundingBoxAtom = useMemo(
    () =>
      createBoundingBoxAtom(
        referenceLocationIds,
        resources?.boundingBoxes.href
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsMemoString, resources?.boundingBoxes.href]
  );
  const boundingBox = useAtomValue(boundingBoxAtom);
  const { latitude, longitude, zoom } = useCalculateMapDimensions({
    points: boundingBox.length ? boundingBox : undefined,
    containerRef,
  });

  return (
    <div ref={containerRef} className={style.container}>
      <Map
        className={style.map}
        longitude={longitude}
        latitude={latitude}
        zoom={zoom - 1}
        geographies={geographies.map((el) => ({
          id: el.referenceLocation.id,
          name: el.referenceLocation.name,
          quarterCalls: el.quarterCalls,
        }))}
        mapConfigAtom={mapConfigAtom}
        showControls
        showMapFilters
      >
        {geographies.map(({ referenceLocation, quarterCalls, status }, i) => (
          <QuarteredPolygon
            location={referenceLocation}
            quarterCalls={quarterCalls}
            id={referenceLocation.id}
            key={`${referenceLocation.id}-${i}`}
            color={status === 'trash' ? red[70] : undefined}
          />
        ))}
      </Map>
    </div>
  );
};

export { DevelopmentAreaMap };
