import {
  createResourceFormAtom,
  FormFieldState,
  FormRowStatus,
  hasChangedFields,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { atom, Getter, Setter } from 'jotai';
import { DocumentInterpretationResponse } from 'types/api-responses';
import { FormInitialValues, GrantorForm, GrantorFormErrors } from './types';

export type TextEntityAtoms = ReturnType<typeof createFormAtom>;

const createFormAtom = (defaultValue: GrantorForm, status: FormRowStatus) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      GrantorForm,
      { errors: { [key: string]: unknown } }
    >(defaultValue),
  };
};

const formDefault = (
  { id, entity, note }: FormInitialValues,
  state: FormFieldState
): GrantorForm =>
  initializeForm<GrantorForm>(
    {
      id,
      entity,
      note,
    },
    state
  );

const initForm = (
  response:
    | {
        type: 'grantor';
        entities: DocumentInterpretationResponse['grantorReferences'];
      }
    | {
        type: 'grantee';
        entities: DocumentInterpretationResponse['granteeReferences'];
      },
  state: FormFieldState
) => {
  if (!response.entities) {
    return [];
  }

  if (response.type === 'grantor') {
    return response.entities.map((entity) =>
      createFormAtom(
        formDefault(
          {
            id: entity.id,
            entity: {
              entityId: entity.grantorId,
            },
            note: entity.notes ?? undefined,
          },
          state
        ),
        rowStatus(state)
      )
    );
  } else {
    return response.entities.map((entity) =>
      createFormAtom(
        formDefault(
          {
            id: entity.id,
            entity: {
              entityId: entity.granteeId,
            },
            note: entity.notes ?? undefined,
          },
          state
        ),
        rowStatus(state)
      )
    );
  }
};

const initEmptyForm = () => {
  return createFormAtom(
    formDefault(
      {
        id: undefined,
        entity: { entityId: undefined },
        note: undefined,
      },
      'updated'
    ),
    'new'
  );
};

const validate = (atoms: TextEntityAtoms, get: Getter, set: Setter) => {
  let hasError = false;
  const fields = get(atoms.row.formAtom);

  const status = get(atoms.status);
  if (status === 'trash') return;

  const errorObj: GrantorFormErrors = { errors: {} };
  if (!fields.entity.value?.name && !fields.entity.value?.entityId) {
    errorObj.errors.entity = 'Name is mandatory';
    hasError = true;
  }
  set(atoms.row.dirtyFieldsAtom, errorObj);
  return hasError;
};

const getPayload = (
  atoms: TextEntityAtoms,
  get: Getter,
  partyType: 'grantor' | 'grantee'
) => {
  const form = get(atoms.row.formAtom);
  const rowStatus = get(atoms.status);

  if (rowStatus === 'trash') {
    return { _destroy: true, id: form.id.value };
  }

  if (!hasChangedFields(form)) return;

  if (form.entity.value.entityId) {
    return {
      id: form.id.value,
      [`${partyType}Id`]: form.entity.value.entityId,
      notes: form.note.value,
    };
  }

  if (form.entity.value?.name) {
    return {
      [`${partyType}Attributes`]: { name: form.entity.value?.name },
      notes: form.note.value,
    };
  }
};

export { initForm, initEmptyForm, validate, getPayload };
