import { FormField, getError } from 'atoms/create-resource-atom';
import { FieldState, getError as newGetError } from 'atoms/form-atoms';
import searchAtomPair, { SearchParams } from 'atoms/search-atom';
import { ComboBox, ComboBoxProps } from 'carbon-components-react';

import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { ReferenceLocationResponse } from 'types/api-responses';

interface FormProps
  extends Omit<ComboBoxProps<ReferenceLocationResponse>, 'items'> {
  location?:
    | FormField<ReferenceLocationResponse | undefined>
    | FieldState<ReferenceLocationResponse | undefined>;
  onChange: (data: {
    selectedItem: ReferenceLocationResponse | undefined | null;
  }) => void;
  index?: number;
  additionalPayload?: ReferenceLocationSearchParams;
  light?: boolean;
  disabled?: boolean;
  className?: string;
}

interface ReferenceLocationSearchParams extends SearchParams {
  adjacentQuarteredGeographies?: Array<{
    referenceLocationId: number;
    quarterCalls: string[];
  }>;
}

const SurveyAreaAutocomplete = ({
  location,
  onChange,
  additionalPayload,
  className,
  light = false,
  disabled = false,
  titleText = 'Survey Areas / Applicable Areas',
  ...rest
}: FormProps) => {
  const { searchAtom, resetSearchDataAtom } = useMemo(
    () =>
      searchAtomPair<ReferenceLocationResponse, ReferenceLocationSearchParams>(
        'referenceLocations'
      ),
    []
  );

  const [query, setPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);

  const debouncedSetPayload = useMemo(
    () => debounce(setPayload, 400),
    [setPayload]
  );

  return (
    <ComboBox<ReferenceLocationResponse>
      titleText={titleText}
      items={query?.data?.results || []}
      className={className}
      itemToString={(data) => {
        return data?.name || '';
      }}
      itemToElement={(result) => <>{result.name}</>}
      selectedItem={location?.value || null}
      onChange={({ selectedItem }) => {
        onChange?.({ selectedItem: selectedItem ?? undefined });
      }}
      onInputChange={(text) => {
        resetSearchData(undefined);
        debouncedSetPayload((c) => ({
          ...c,
          text,
          pageSize: 10,
          ...additionalPayload,
        }));
      }}
      invalid={
        location
          ? 'isValid' in location
            ? !location.isValid
            : !!getError(location)
          : false
      }
      invalidText={
        location
          ? 'isValid' in location
            ? newGetError(location)
            : getError(location)
          : ''
      }
      light={light}
      disabled={disabled}
      {...rest}
    />
  );
};

export { SurveyAreaAutocomplete };
