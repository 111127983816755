import { format, isValid, parse } from 'date-fns';

const getCurrentDateString = (): string => {
  const date = new Date();
  return format(date, 'yyyy-MM-dd');
};

const getDateString = (date: Date): string => {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (e) {
    return '';
  }
};

const parseDateString = (dateString: string | null): Date | null => {
  if (!dateString) {
    return null;
  }

  try {
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return isValid(parsedDate) ? parsedDate : null;
  } catch (e) {
    return null;
  }
};

export { getCurrentDateString, getDateString, parseDateString };
