function formatInterest(value: string): string {
  // Test that the number is valid
  const num = parseFloat(value);

  // Check if the value is a valid number
  if (isNaN(num)) {
    throw new Error('Invalid input: not a number');
  }

  // Now that we know it's valid, we're just going to operate on the original string
  // Remove all trailing 0's
  while (value.endsWith('0')) {
    value = value.slice(0, -1);
  }
  // If the last character is a period, remove it
  if (value.endsWith('.')) {
    value = value.slice(0, -1);
  }
  return value;
}

export { formatInterest };
