import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import style from './copy.module.scss';

interface Props {
  className?: string | null;
  heading?: ReactNode;
  subheading?: ReactNode;
}

const Copy: FC<Props> = ({ className, heading, subheading, children }) => {
  return (
    <section className={classNames(className, style.copy)}>
      {heading && (
        <h2 className={classNames(className, style.heading)}>{heading}</h2>
      )}
      {subheading && (
        <h3 className={classNames(className, style.subheading)}>
          {subheading}
        </h3>
      )}
      {children}
    </section>
  );
};

export default Copy;
