import { magenta, orange, red, yellow } from '@carbon/colors';
import chroma from 'chroma-js';
import { POLYGON_SELECTED_COLOR, POLYGON_UNSELECTED_COLOR } from 'lib/map/map';
import { ReferenceLocationResponse } from 'types/api-responses';
import { DocumentSearchParams } from './types';

const defaultPayload: DocumentSearchParams = {
  text: '',
  page: 1,
  pageSize: 10,
  instrumentDateMinYear: undefined,
  instrumentDateMaxYear: undefined,
  countyIds: [],
  referenceLocationIds: [],
};

const scale = chroma.scale([yellow, orange, red[60], magenta[80]]);

const referenceLocationStyle = (
  referenceLocation: ReferenceLocationResponse & { count: number },
  referenceLocationIds: number[],
  min: number,
  range: number
) => {
  const selected = referenceLocationIds.includes(referenceLocation.id);
  const fraction = range ? (referenceLocation.count - min) / range : 1;
  return choropleth({ fraction, selected });
};

const choropleth = ({
  fraction,
  selected,
}: {
  fraction: number;
  selected: boolean;
}) => {
  const outlineColor = selected
    ? POLYGON_SELECTED_COLOR
    : POLYGON_UNSELECTED_COLOR;
  const fillColor = scale(fraction).hex();

  return {
    fill: {
      'fill-color': fillColor,
      'fill-opacity': 0.6,
    },
    line: {
      'line-color': outlineColor,
      'line-width': 2,
      'line-opacity': 0.4,
    },
    fillHighlighted: {
      'fill-color': fillColor,
      'fill-opacity': 1,
    },
    lineHighlighted: {
      'line-color': 'yellow',
      'line-width': 3,
      'line-opacity': 1,
    },
  };
};

type ReferenceLocationStyleFn = (
  referenceLocation: ReferenceLocationResponse & { count: number }
) => ReturnType<typeof referenceLocationStyle>;

export { defaultPayload, referenceLocationStyle, ReferenceLocationStyleFn };
