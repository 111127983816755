import searchAtomPair, { SearchParams } from 'atoms/search-atom';
import {
  SkeletonPlaceholder,
  Search,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  RadioButtonGroup,
  RadioButton,
  Grid,
  Row,
  Tile,
  ContentSwitcher,
} from 'carbon-components-react';
import { CardsGrid } from 'components/cards-grid';
import { Date } from 'components/date';
import { EmptyState } from 'components/empty-state';
import ErrorToast from 'components/error-toast';
import { PageHeader } from 'components/page-header';
import { SearchResultCard } from 'components/search-result-card';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { PackagesQueryResponse } from 'types/api-responses';
import { Checkmark20, Table16, Grid16 } from '@carbon/icons-react';
import { Link } from 'components/link';
import { PaginatedTable } from 'components/table/paginated-table';
import { SkeletonTableRows } from 'components/skeleton-table-rows';

import style from './page.module.scss';
import { Package } from 'components/icons';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import {
  format,
  DECIMAL_FORMATTER_TWO_FRACTIONS,
  pageSizeAtom,
  DEFAULT_PAGE_SIZE,
} from 'lib/ui';
import plur from 'plur';
import { atomWithStorage } from 'jotai/utils';

const isTableViewAtom = atomWithStorage('packages-table-preference', false);

type PackageQueryParams = { type: string | null } & SearchParams;

const createSearchAtom = (pageSizePreference: number) =>
  searchAtomPair<PackagesQueryResponse, PackageQueryParams>('packages', {
    text: '',
    page: 1,
    pageSize: pageSizePreference,
    type: null,
  });

const ListPackagesPage = () => {
  const [isTable] = useAtom(isTableViewAtom);
  const [pageSizePreference] = useAtom(pageSizeAtom);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAtoms = useMemo(() => createSearchAtom(pageSizePreference), []);

  return (
    <>
      <Helmet>
        <title>{pageTitle('Packages')}</title>
      </Helmet>
      <PageHeader
        title="Packages"
        breadcrumbs={[['packages', 'Packages']]}
        actions={null}
      />

      <Grid className="bx--no-gutter" fullWidth>
        <Row className={style.filtersArea}>
          <PackageTypeFilter searchAtoms={searchAtoms} />
        </Row>
      </Grid>
      {isTable ? (
        <TablePackagesSearch searchAtoms={searchAtoms} />
      ) : (
        <ListPackagesSearch searchAtoms={searchAtoms} />
      )}
    </>
  );
};

const PackagesSearchField = ({
  searchAtoms: { searchAtom, resetSearchDataAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchDisplayText, setSearchDisplayText] = useState('');

  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        resetSearchData(undefined);
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              pageSize: current?.pageSize || 10,
              text: value,
              page: 1,
            }
        );
      }, 400),
    [setSearchPayload, resetSearchData]
  );

  return (
    <div className={style.packagesSearchField}>
      {searchResult?.error ? (
        <ErrorToast message={searchResult?.error} />
      ) : null}
      <Search
        labelText="Search"
        placeholder="Search"
        size="lg"
        autoFocus
        value={searchDisplayText}
        onChange={({ target: { value } }) => {
          setSearchDisplayText(value);
          debouncedSetSearchPayload(value);
        }}
      />
    </div>
  );
};

const PackageTypeFilter = ({
  searchAtoms,
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const { searchAtom } = searchAtoms;
  const [search, setSearchPayload] = useAtom(searchAtom);
  const [isTable, setIsTable] = useAtom(isTableViewAtom);
  const [pageSizePreference] = useAtom(pageSizeAtom);
  const packageTypeParam = search.currentPayload?.type;
  const selected =
    packageTypeParam === null
      ? -1
      : packageTypeParam === 'OpportunityPackage'
      ? 0
      : 1;

  const setPackageType = useCallback(
    (type: string | null) => {
      setSearchPayload((current) => ({
        ...current,
        type,
        page: 1,
        pageSize: pageSizePreference,
      }));
    },
    [setSearchPayload, pageSizePreference]
  );
  const totalPackages = search.data ? (
    `${format.number(search.data.totalCount || 0)} ${plur(
      'package',
      search.data.totalCount
    )}`
  ) : search.loading ? (
    <SkeletonPlaceholder className={style.placeholderSkeletonItem} />
  ) : null;
  return (
    <>
      <h2 className={style.totalPackages}>{totalPackages}</h2>

      <div className={style.actionsArea}>
        <RadioButtonGroup
          name="package-type"
          valueSelected={selected}
          onChange={(value) => {
            if (typeof value !== 'number') return;
            if (value < 0) {
              setPackageType(null);
              return;
            }
            setPackageType(
              value === 0 ? 'OpportunityPackage' : 'AccountPackage'
            );
          }}
        >
          <RadioButton value={-1} labelText="All" />
          <RadioButton value={0} labelText="Opportunity Packages" />
          <RadioButton value={1} labelText="Inventory Packages" />
        </RadioButtonGroup>
        <PackagesSearchField searchAtoms={searchAtoms} />
        <ContentSwitcher
          className={style.tableGridSwitcher}
          selectedIndex={isTable ? 0 : 1}
        >
          <Button
            hasIconOnly
            size="md"
            kind={isTable ? 'secondary' : 'ghost'}
            iconDescription="Table View"
            renderIcon={Table16}
            onClick={() => setIsTable(true)}
          />
          <Button
            hasIconOnly
            size="md"
            kind={!isTable ? 'secondary' : 'ghost'}
            iconDescription="Grid View"
            renderIcon={Grid16}
            onClick={() => setIsTable(false)}
          />
        </ContentSwitcher>
      </div>
    </>
  );
};

const ListPackagesSearch = ({
  searchAtoms: { searchAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const setPageSize = useSetAtom(pageSizeAtom);

  return (
    <>
      {searchResult?.error ? (
        <ErrorToast message={searchResult?.error} />
      ) : null}
      <CardsGrid<PackagesQueryResponse>
        searchResponse={searchResult.data}
        loading={searchResult.loading}
        itemName="package"
        onChange={(newSearch) => {
          if (newSearch.pageSize) {
            setPageSize(newSearch.pageSize);
          }
          setSearchPayload(
            (current) => current && { ...current, ...newSearch }
          );
        }}
        actions={null}
        renderCard={(packageInfo) => (
          <SearchResultCard<PackagesQueryResponse>
            Icon={Package}
            item={packageInfo}
            cardContent={
              <>
                {packageInfo.sellerName && (
                  <dl>
                    <dt className={style.label}>Seller</dt>
                    <dd className={style.content}>{packageInfo.sellerName}</dd>
                  </dl>
                )}
                {packageInfo.acquirerName && (
                  <dl>
                    <dt className={style.label}>Acquire Entity</dt>
                    <dd className={style.content}>
                      {packageInfo.acquirerName}
                    </dd>
                  </dl>
                )}
                {packageInfo.closeDate && (
                  <dl>
                    <dt className={style.label}>Close Date</dt>
                    <dd className={style.content}>
                      <Date date={packageInfo.closeDate} />
                    </dd>
                  </dl>
                )}
                {packageInfo.totalAcreage > 0 && (
                  <dl>
                    <dt className={style.label}>Total Acreage</dt>
                    <dd className={style.content}>
                      {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                        packageInfo.totalAcreage
                      )}
                    </dd>
                  </dl>
                )}
                {packageInfo.effectiveDate && (
                  <dl>
                    <dt className={style.label}>Effective Date</dt>
                    <dd className={style.content}>
                      <Date date={packageInfo.effectiveDate} />
                    </dd>
                  </dl>
                )}
                {packageInfo.includeChildren !== null && (
                  <dl>
                    <dt className={style.label}>Incl. Children</dt>
                    <dd className={style.content}>
                      {packageInfo.includeChildren ? <Checkmark20 /> : null}
                    </dd>
                  </dl>
                )}
                <dl>
                  <dt className={style.label}>Completed Evals</dt>
                  <dd className={style.content}>
                    {packageInfo.completedEvals || 0}
                  </dd>
                </dl>
                <dl>
                  <dt className={style.label}>Created At</dt>
                  <dd className={style.content}>
                    <Date date={packageInfo.createdAt} />
                  </dd>
                </dl>
              </>
            }
          />
        )}
        renderEmptyState={
          <EmptyState
            IconComponent={Package}
            headerText="No packages available"
            helperText="Currently you don't have any packages."
          />
        }
      />
    </>
  );
};

const TablePackagesSearch = ({
  searchAtoms: { searchAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const searchParams = searchResults.currentPayload;
  const pageSize = searchResults?.data?.pageSize || DEFAULT_PAGE_SIZE;
  const setPageSize = useSetAtom(pageSizeAtom);

  if (!searchResults.loading && !searchResults.data?.results.length) {
    return (
      <Tile>
        <EmptyState
          IconComponent={Package}
          headerText="No packages available"
          helperText="Currently you don't have any packages."
        />
      </Tile>
    );
  }

  return (
    <PaginatedTable
      pageSizes={[16, 24, 48, DEFAULT_PAGE_SIZE]}
      pageSize={pageSize}
      className={style.packagesTable}
      page={searchParams?.page || 1}
      totalItems={searchResults?.data?.totalCount || 0}
      onPaginate={({ page, pageSize }) => {
        setPageSize(pageSize);
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              page,
              pageSize,
            }
        );
      }}
    >
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Seller</TableHeader>
          <TableHeader>Acquire Entity</TableHeader>
          <TableHeader>Close Date</TableHeader>
          <TableHeader>Total Acreage</TableHeader>
          <TableHeader>Effective Date</TableHeader>
          <TableHeader>Include Children</TableHeader>
          <TableHeader>Completed Evals</TableHeader>
          <TableHeader>Created At</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults.loading ? (
          <SkeletonTableRows rows={pageSize} columns={9} />
        ) : searchResults ? (
          searchResults.data?.results.map((el) => {
            return (
              <TableRow key={el.id}>
                <TableCell>
                  {el.href ? (
                    <Link to={el.href} target="_blank">
                      {el.name}
                    </Link>
                  ) : (
                    el.name
                  )}
                </TableCell>
                <TableCell>{el.sellerName}</TableCell>
                <TableCell>{el.acquirerName}</TableCell>
                <TableCell>
                  <Date date={el.closeDate} />
                </TableCell>
                <TableCell>
                  {el.totalAcreage > 0
                    ? DECIMAL_FORMATTER_TWO_FRACTIONS.format(el.totalAcreage)
                    : ''}
                </TableCell>
                <TableCell>
                  {el.effectiveDate ? <Date date={el.effectiveDate} /> : null}
                </TableCell>
                <TableCell>
                  {el.includeChildren ? <Checkmark20 /> : null}
                </TableCell>
                <TableCell>{el.completedEvals || 0}</TableCell>
                <TableCell>
                  <Date date={el.createdAt} />
                </TableCell>
              </TableRow>
            );
          })
        ) : null}
      </TableBody>
    </PaginatedTable>
  );
};

export { ListPackagesPage };
