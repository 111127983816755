import searchAtomPair from 'atoms/search-atom';
import { Column, Row, ComboBox, Grid } from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { TextField } from 'components/form-fields/text-field';
import { DateField } from 'components/form-fields/date-field';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { CountyQueryResponse, DocumentResponse } from 'types/api-responses';
import { AtomizedDocumentEditForm } from './types';
import style from './edit-document-modal.module.scss';

const { searchAtom } = searchAtomPair<CountyQueryResponse>('counties');

export const EditDocumentForm = ({
  form,
  type,
  county,
}: {
  form: AtomizedDocumentEditForm;
  type: DocumentResponse['type'];
  county: DocumentResponse['county'];
}) => {
  const [counties, setCounties] = useAtom(searchAtom);
  const debouncedSetCounties = useMemo(
    () => debounce(setCounties, 400),
    [setCounties]
  );
  const setCountyId = useSetAtom(form.countyId);
  const [selectedCounty, setSelectedCounty] = useState<CountyQueryResponse>({
    id: 0,
    name: county?.name || '',
    stateAbbreviation: county?.state.abbreviation || '',
    fips: '',
  });

  return (
    <>
      <EditDocumentDates type={type} form={form} />
      {type === 'Document::Courthouse' && (
        <>
          <FormRow>
            <Grid className="bx--no-gutter" fullWidth>
              <Row className={style.rowThirds}>
                <Column>
                  <ComboBox
                    size="md"
                    id={`county`}
                    titleText="County"
                    items={counties?.data?.results || []}
                    placeholder=""
                    itemToString={(data) => {
                      return data
                        ? `${data.name}, ${data.stateAbbreviation}`
                        : '';
                    }}
                    selectedItem={selectedCounty}
                    itemToElement={(result) => (
                      <>{`${result.name}, ${result.stateAbbreviation}`}</>
                    )}
                    onChange={({ selectedItem }) => {
                      const county = counties?.data?.results?.find(
                        (result) => result.id === selectedItem?.id
                      );
                      if (county) {
                        setCountyId(county.id);
                        setSelectedCounty(county);
                      }
                    }}
                    onInputChange={(text) => {
                      debouncedSetCounties((current) => ({
                        ...current,
                        text,
                        pageSize: 10,
                      }));
                    }}
                  />
                </Column>

                <Column>
                  <TextField
                    fieldAtom={form.bookType}
                    id="book-type"
                    labelText="Book Type"
                  />
                </Column>
                <Column>
                  <TextField
                    fieldAtom={form.documentType}
                    id="document-type"
                    labelText="Document Type"
                  />
                </Column>
              </Row>
              <Row className={style.rowThirds}>
                <Column>
                  <TextField
                    id="document-volume"
                    fieldAtom={form.volume}
                    labelText="Volume"
                  />
                </Column>
                <Column>
                  <TextField
                    id="document-page"
                    fieldAtom={form.page}
                    labelText="Page"
                  />
                </Column>
                <Column>
                  <TextField
                    id="document-instrument-number"
                    fieldAtom={form.instrumentNumber}
                    labelText="Instrument Number"
                  />
                </Column>
              </Row>
            </Grid>
          </FormRow>
        </>
      )}
    </>
  );
};

const EditDocumentDates = ({
  type,
  form,
}: {
  type: DocumentResponse['type'];
  form: AtomizedDocumentEditForm;
}) => {
  return (
    <FormSection>
      {type === 'Document::Courthouse' && (
        <>
          <FormRow>
            <Row>
              <Column>
                <DateField
                  size="md"
                  id="effective-date"
                  labelText="Effective Date"
                  field={form.effectiveDate}
                />
              </Column>
              <Column>
                <DateField
                  size="md"
                  id="instrument-date"
                  labelText="Instrument Date"
                  field={form.instrumentDate}
                />
              </Column>
            </Row>
          </FormRow>
          <FormRow>
            <Row>
              <Column>
                <DateField
                  size="md"
                  id="file-date"
                  labelText="File Date"
                  field={form.fileDate}
                />
              </Column>
              <Column>
                <DateField
                  size="md"
                  id="recorded-date"
                  labelText="Recorded Date"
                  field={form.recordedDate}
                />
              </Column>
            </Row>
          </FormRow>
        </>
      )}
      {type === 'Document::NonCourthouse' && (
        <FormRow>
          <Row>
            <Column sm={8} md={4} lg={8}>
              <DateField
                size="md"
                id="effective-date"
                labelText="Effective Date"
                field={form.effectiveDate}
              />
            </Column>
          </Row>
        </FormRow>
      )}
    </FormSection>
  );
};
