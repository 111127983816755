import { AtomField, AtomizedForm } from 'atoms/form-atoms';
import {
  Checkbox,
  Column,
  ContentSwitcher,
  FormGroup,
  Grid,
  RadioButton,
  RadioButtonGroup,
  Row,
  Switch,
  Tile,
} from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import style from './drilling-assumption.module.scss';
import { useEffect, useState } from 'react';
import { NtdAttributes } from 'types/api-payloads';
import { ScenariosForm } from 'pages/scenarios/common/types';
import { DateField } from 'components/form-fields/date-field';
import { NumberField } from 'components/form-fields/number-field';
import {
  useValidateLTDFieldsAtom,
  useValidateNTDFieldsAtom,
} from '../validations';
import { FixedFloatingDate } from './fixed-floating-date';
import { InfillAssumptions } from './infill-assumptions';

const getLTDTabIndex = (
  assumptionType: ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']['kind']
) => {
  switch (assumptionType) {
    case 'per_dev_area':
      return 0;
    case 'target_most_recent':
    case 'target_as_of':
      return 1;
    case 'fractional_wells':
      return 2;
  }
};

const LTDTabOptions = ({
  form,
  field,
}: {
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']
  >;
  field: AtomField<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']['kind']
  >;
}) => {
  const [{ value }, setValue] = useAtom(field);
  const [selectedIndex, setSelectedIndex] = useState(getLTDTabIndex(value));
  const setLTDFieldValidations = useValidateLTDFieldsAtom(form);

  useEffect(() => {
    setLTDFieldValidations();
  }, [setLTDFieldValidations]);

  return (
    <ContentSwitcher
      selectedIndex={selectedIndex}
      onChange={(e) => {
        switch (e.index) {
          case 0:
            setValue('per_dev_area');
            setSelectedIndex(0);
            setLTDFieldValidations();
            break;
          case 1:
            setValue('target_most_recent');
            setSelectedIndex(1);
            setLTDFieldValidations();
            break;
          case 2:
            setValue('fractional_wells');
            setSelectedIndex(2);
            setLTDFieldValidations();
            break;
        }
      }}
    >
      <Switch text="Across Dev Area" disabled={false} />
      <Switch text="Target Average Well Drilled" disabled={false} />
      <Switch text="Fractional Wells" disabled={false} />
    </ContentSwitcher>
  );
};

const NTDTabOptions = ({
  assumptionsType,
  ntdBlendField,
  form,
}: {
  assumptionsType: AtomField<NtdAttributes['kind']>;
  ntdBlendField: AtomField<boolean>;
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ducAttributes']
      >
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['permitAttributes']
      >;
}) => {
  const [{ value }, setAssumptionsType] = useAtom(assumptionsType);
  const setNtdBlend = useSetAtom(ntdBlendField);
  const [selectedIndex, setSelectedIndex] = useState(
    value === 'discrete' ? 0 : 1
  );

  const setFieldValidations = useValidateNTDFieldsAtom(form);

  useEffect(() => {
    setFieldValidations();
  }, [setFieldValidations]);

  return (
    <ContentSwitcher
      selectedIndex={selectedIndex}
      onChange={(e) => {
        switch (e.index) {
          case 0:
            setAssumptionsType('discrete');
            setSelectedIndex(0);
            setFieldValidations();
            break;
          case 1:
            setAssumptionsType('fractional_wells');
            setSelectedIndex(1);
            setNtdBlend(true);
            setFieldValidations();
            break;
        }
      }}
    >
      <Switch text="Discrete" disabled={false} />
      <Switch text="Fractional Wells" disabled={false} />
    </ContentSwitcher>
  );
};

const LTDAssumptions = ({
  drillingAssumptions,
}: {
  drillingAssumptions: AtomizedForm<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']
  >;
}) => {
  const ltdAttributes = drillingAssumptions.ltdAttributes;
  return (
    <FormGroup legendText="">
      <FormRow>
        <NumberField
          id="drillingIntervalMonths"
          label="Months Between LTD"
          light
          min={0}
          field={ltdAttributes.drillingIntervalMonths}
        />
      </FormRow>
    </FormGroup>
  );
};

const LTDAssumptionsForTargetWellsDrilled = ({
  drillingAssumptions,
  form,
}: {
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']
  >;
  drillingAssumptions: AtomizedForm<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']
  >;
}) => {
  const ltdAttributes = drillingAssumptions.ltdAttributes;
  const [assumptionType, setAssumption] = useAtom(ltdAttributes.kind);
  const setLTDFieldValidations = useValidateLTDFieldsAtom(form);
  return (
    <FormGroup legendText="">
      <FormRow>
        <RadioButtonGroup
          name="wells-drilled-kind-radio"
          defaultSelected={assumptionType.value}
          orientation="horizontal"
          onChange={(
            value: ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']['kind']
          ) => {
            setAssumption(value);
            setLTDFieldValidations();
          }}
        >
          <RadioButton labelText="Most Recent" value={'target_most_recent'} />
          <RadioButton labelText="As Of:" value={'target_as_of'} />
        </RadioButtonGroup>
      </FormRow>
      <FormRow>
        <DateField
          id="as_of_date"
          light
          short
          datePickerType="single"
          labelText="As Of Date"
          disabled={assumptionType.value !== 'target_as_of'}
          field={ltdAttributes.asOfDate}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="firstDrillingStartDate"
          label="Years"
          light
          min={0}
          field={ltdAttributes.averageWellDrilledYears}
        />
      </FormRow>
    </FormGroup>
  );
};

const NTDAssumptionsForFractionalWells = ({
  ntdAttributes,
  type,
}: {
  type: 'permit' | 'duc';
  ntdAttributes:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ducAttributes']
      >
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['permitAttributes']
      >;
}) => {
  return (
    <FormGroup legendText="">
      <FormRow>
        <NumberField
          id={`${type}-fractionalStartMonth`}
          label="Start Month"
          light
          field={ntdAttributes.startMonth}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id={`${type}-fractionalEndMonth`}
          label="End Month"
          light
          field={ntdAttributes.endMonth}
        />
      </FormRow>
    </FormGroup>
  );
};

const LTDAssumptionsForFractionalWells = ({
  drillingAssumptions,
}: {
  drillingAssumptions: AtomizedForm<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']
  >;
}) => {
  const ltdAttributes = drillingAssumptions.ltdAttributes;
  return (
    <FormGroup legendText="">
      <FormRow>
        <NumberField
          id="averageWellDrilledYears"
          label="Average Well Drilled (Years)"
          light
          field={ltdAttributes.averageWellDrilledYears}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="drillStartMonths"
          label="Drill Start (Months)"
          light
          field={ltdAttributes.drillStartMonths}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="drillOffsetMonths"
          label="Drill Offset (Months)"
          light
          field={ltdAttributes.drillOffsetMonths}
        />
      </FormRow>
    </FormGroup>
  );
};

const NTDAssumptions = ({
  ntdAttributes,
  type,
  form,
}: {
  ntdAttributes:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ducAttributes']
      >
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['permitAttributes']
      >;
  type: 'permit' | 'duc';
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ducAttributes']
      >
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['permitAttributes']
      >;
}) => {
  const assumptionType = useAtomValue(ntdAttributes.kind);
  const isPermit = 'permitToProductionMonths' in ntdAttributes;
  const title = isPermit ? 'Permit' : 'DUC';
  return (
    <Tile>
      <h3 className={style.subtitle}>{`${title} Pace Assumptions`}</h3>
      <BlendCheckbox
        id={`blend-${type}-assumptions`}
        field={ntdAttributes.typeCurveBlend}
      />
      <FormGroup legendText="" className={style.ntdAssumptions}>
        <NTDTabOptions
          assumptionsType={ntdAttributes.kind}
          ntdBlendField={ntdAttributes.typeCurveBlend}
          form={form}
        />
        {assumptionType.value === 'discrete' && (
          <>
            {isPermit ? (
              <FormRow>
                <NumberField
                  id="permitToProductionMonths"
                  label="Permit to Production Months"
                  light
                  min={0}
                  invalidText="Must be positive number"
                  field={ntdAttributes.permitToProductionMonths}
                />
              </FormRow>
            ) : null}
          </>
        )}
        {assumptionType.value === 'fractional_wells' && (
          <NTDAssumptionsForFractionalWells
            type={isPermit ? 'permit' : 'duc'}
            ntdAttributes={ntdAttributes}
          />
        )}
      </FormGroup>
    </Tile>
  );
};

const BlendCheckbox = ({
  id,
  field,
}: {
  id: string;
  field: AtomField<boolean>;
}) => {
  const [{ value }, setValue] = useAtom(field);
  return (
    <FormRow>
      <Checkbox
        id={id}
        checked={value}
        onChange={(checked: boolean) => setValue(checked)}
        labelText="Use Blended Type Curves"
      />
    </FormRow>
  );
};

const DrillingAssumptions = ({
  form,
}: {
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']
  >;
}) => {
  const assumptionType = useAtomValue(form.ltdAttributes.kind);

  return (
    <Grid fullWidth condensed className={style.drillingForm}>
      <Row>
        <Column>
          <Tile className={style.floatTile}>
            <Grid fullWidth className="bx--no-gutter">
              <Row>
                <Column lg={8}>
                  <FixedFloatingDate
                    id="drilling-start-date"
                    label="Start Date"
                    dateType={form.startDateType}
                    date={form.startDate}
                    legendText="Start Date"
                  />
                </Column>
                <Column lg={8}>
                  <FormRow className={style.aligRowSpud}>
                    <NumberField
                      id="spudToProductionMonths"
                      label="Spud to Production (Months)"
                      light
                      min={0}
                      invalidText="Must be positive number"
                      field={form.spudToProductionMonths}
                    />
                  </FormRow>
                </Column>
              </Row>
            </Grid>
            <InfillAssumptions form={form.infillAttributes} />
          </Tile>
        </Column>
      </Row>
      <Row className={style.rowThirds}>
        <Column>
          <Tile>
            <h3 className={style.subtitle}>LTD Pace Assumptions</h3>
            <BlendCheckbox
              id="blend-ltd-assumptions"
              field={form.ltdAttributes.typeCurveBlend}
            />
            <LTDTabOptions
              form={form.ltdAttributes}
              field={form.ltdAttributes.kind}
            />
            {assumptionType.value === 'per_dev_area' && (
              <LTDAssumptions drillingAssumptions={form} />
            )}
            {(assumptionType.value === 'target_most_recent' ||
              assumptionType.value === 'target_as_of') && (
              <LTDAssumptionsForTargetWellsDrilled
                form={form.ltdAttributes}
                drillingAssumptions={form}
              />
            )}
            {assumptionType.value === 'fractional_wells' && (
              <LTDAssumptionsForFractionalWells drillingAssumptions={form} />
            )}
          </Tile>
        </Column>
        <Column>
          <NTDAssumptions
            ntdAttributes={form.permitAttributes}
            type="permit"
            form={form.permitAttributes}
          />
        </Column>
        <Column>
          <NTDAssumptions
            ntdAttributes={form.ducAttributes}
            type="duc"
            form={form.ducAttributes}
          />
        </Column>
      </Row>
    </Grid>
  );
};

export { DrillingAssumptions };
