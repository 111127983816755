import { Upload32 } from '@carbon/icons-react';
import classNames from 'classnames';
import { IconProps } from './types';

import style from './icons.module.scss';

const SupportingFile = ({ className }: IconProps) => {
  return (
    <div
      className={classNames(style.background, style.supportingFile, className)}
    >
      <Upload32 className={classNames(style.icon, style.supportingFile)} />
    </div>
  );
};

export default SupportingFile;
