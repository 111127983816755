import { useAtomValue } from 'jotai';
import { createRunsheetAtom } from 'pages/title-workspaces/overview/runsheet-graph/atoms';
import { useMemo, useEffect } from 'react';
import { Timeline } from './timeline';
import { Loading } from 'carbon-components-react';

import { Helmet } from 'react-helmet-async';
import { TitleWorkspaceResponse } from 'types/api-responses';
import styles from './page.module.scss';

const RunsheetTimelinePage = ({
  titleWorkspace,
}: {
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const queryHref = titleWorkspace.resources.queries.interpretations.href;

  const runsheetAtom = useMemo(() => {
    return createRunsheetAtom(queryHref);
  }, [queryHref]);

  const runsheet = useAtomValue(runsheetAtom);
  useEffect(() => {
    document.body.classList.add(`${styles.timelineContent}`);

    return () => {
      document.body.classList.remove(`${styles.timelineContent}`);
    };
  }, []);

  return runsheet ? (
    <>
      <Helmet>
        <title>{titleWorkspace.name} | Timeline | Insights</title>
      </Helmet>
      <Timeline runsheet={runsheet} />
    </>
  ) : (
    <Loading />
  );
};

export { RunsheetTimelinePage };
