import { Add16, Warning16 } from '@carbon/icons-react';
import { getError } from 'atoms/create-resource-atom';
import {
  Button,
  Column,
  ComboBox,
  Grid,
  Modal,
  RadioButton,
  RadioButtonGroup,
  Row,
  TextInput,
} from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import { atomWithStorage, splitAtom } from 'jotai/utils';
import { useMemo } from 'react';
import { DeleteRowButton } from '../../delete-row-button';
import {
  LeaseExtensionSurveySubformAtoms,
  LeaseManagementSubformAtoms,
  initLeaseExtensionSurveySubformEmpty,
} from './utils';

import leaseExtensions from 'enums/lease_extensions.json';
import { CenteredMap, PolygonArea } from 'components/map';
import style from './lease-management-modal.module.scss';
import { green, red } from '@carbon/colors';
import {
  createAbortableQuarteredGeometriesAtom,
  createLeaseSelectedAreasAtom,
  locationReferencesAtom,
} from './atoms';
import { ReferenceLocationResponse } from 'types/api-responses';
import { QuarterCalls } from 'components/quarter-calls';
import { LeaseExtensionSurveySubform, LeaseManagementSubform } from '../types';
import { QuarteredPolygon } from 'components/map/quartered-polygon';
import { getMapDefaultConfig } from 'components/map/atoms';
import { resourcesAtom } from 'atoms/root';

type LeaseExtensions = keyof typeof leaseExtensions;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  leaseManagementAtoms: LeaseManagementSubformAtoms;
}

interface FormProps {
  atoms: PrimitiveAtom<LeaseExtensionSurveySubformAtoms>;
  atomIdx: number;
  availableLocations: ReferenceLocationResponse[];
  onDelete?: (atomIdx: number) => void;
  disabled: boolean;
}

const SurveyArea = ({
  atomIdx,
  atoms,
  onDelete,
  availableLocations,
  disabled,
}: FormProps) => {
  const rowAtoms = useAtomValue(atoms);
  const [fields, setField] = useAtom(rowAtoms.row.formAtom);
  const status = useAtomValue(rowAtoms.status);

  return (
    <FormRow toTrash={status === 'trash'}>
      <Grid condensed fullWidth className={style.partialRow}>
        <Row>
          <Column md={3}>
            <ComboBox
              id={`${atomIdx}-reference-location`}
              titleText="Survey Area"
              items={availableLocations}
              placeholder=""
              itemToString={(data) => {
                return data?.name || '';
              }}
              itemToElement={(result) => <>{result.name}</>}
              selectedItem={fields.referenceLocation.value}
              onChange={({ selectedItem }) => {
                setField({
                  field: 'referenceLocation',
                  value: selectedItem ?? null,
                });
              }}
              invalid={
                !!getError<LeaseExtensionSurveySubform>(
                  fields,
                  'referenceLocation'
                )
              }
              invalidText={getError<LeaseExtensionSurveySubform>(
                fields,
                'referenceLocation'
              )}
              disabled={status === 'trash' || disabled}
              light
            />
          </Column>

          <Column sm={2} md={2}>
            <QuarterCalls
              light
              items={fields.quarterCalls.value}
              labelText="Quarter Call"
              placeholder="Quarter Call"
              id={`${atomIdx}-quarter-calls`}
              disabled={
                !fields.referenceLocation.value ||
                status === 'trash' ||
                disabled
              }
              onRemove={(quarterCall) => {
                setField((current) => ({
                  field: 'quarterCalls',
                  value: current.quarterCalls.value.filter(
                    (el) => el !== quarterCall
                  ),
                }));
              }}
              onAdd={(quarterCall) => {
                setField((current) => {
                  const quarterCalls = current.quarterCalls.value;
                  if (!quarterCalls.includes(quarterCall)) {
                    return {
                      field: 'quarterCalls',
                      value: [...quarterCalls, quarterCall],
                    };
                  }
                  return {
                    field: 'quarterCalls',
                    value: quarterCalls,
                  };
                });
              }}
            />
          </Column>
          <Column md={1} sm={1}>
            <TextInput
              light
              id={`${atomIdx}-start-depth`}
              labelText="Start Depth (ft)"
              placeholder="0"
              value={fields.startDepthInFeet.value ?? ''}
              disabled={status === 'trash' || disabled}
              onChange={(event) =>
                setField({
                  field: 'startDepthInFeet',
                  value: event.target.value,
                })
              }
              invalid={
                !!getError<LeaseExtensionSurveySubform>(
                  fields,
                  'startDepthInFeet'
                )
              }
              invalidText={getError<LeaseExtensionSurveySubform>(
                fields,
                'startDepthInFeet'
              )}
            />
          </Column>
          <Column md={1} sm={1}>
            <TextInput
              light
              id={`${atomIdx}-end-depth`}
              labelText="End Depth (ft)"
              placeholder="∞"
              value={fields.endDepthInFeet.value || ''}
              disabled={status === 'trash' || disabled}
              onChange={(event) =>
                setField({
                  field: 'endDepthInFeet',
                  value: event.target.value,
                })
              }
              invalid={
                !!getError<LeaseExtensionSurveySubform>(
                  fields,
                  'endDepthInFeet'
                )
              }
              invalidText={getError<LeaseExtensionSurveySubform>(
                fields,
                'endDepthInFeet'
              )}
            />
          </Column>
          <Column md={1} sm={1}>
            <DeleteRowButton
              statusAtom={rowAtoms.status}
              atomIdx={atomIdx}
              onDelete={onDelete}
              adjustForLabel={true}
              disabled={disabled}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

const SurveyAreaList = ({
  locationReferences,
  disabled,
}: {
  locationReferences: PrimitiveAtom<LeaseExtensionSurveySubformAtoms[]>;
  disabled: boolean;
}) => {
  const referencesAtomsAtom = splitAtom(locationReferences);
  const [locationRef, setLocationRef] = useAtom(referencesAtomsAtom);
  const availableLocations = useAtomValue(locationReferencesAtom).flatMap(
    (el) => {
      return el.value ? [el.value] : [];
    }
  );

  return (
    <>
      {locationRef.map((locationAtom, i) => {
        return (
          <SurveyArea
            key={i}
            atoms={locationAtom}
            atomIdx={i}
            availableLocations={availableLocations.map(
              (el) => el.referenceLocation
            )}
            onDelete={() => {
              setLocationRef({ type: 'remove', atom: locationAtom });
            }}
            disabled={disabled}
          />
        );
      })}
      <Button
        kind="ghost"
        renderIcon={Add16}
        onClick={() => {
          setLocationRef({
            type: 'insert',
            value: initLeaseExtensionSurveySubformEmpty(),
          });
        }}
        disabled={disabled}
      >
        Add Additional Survey Areas
      </Button>
    </>
  );
};

const LeaseTermForm = ({
  leaseManagementAtoms,
}: {
  leaseManagementAtoms: LeaseManagementSubformAtoms;
}) => {
  const [fields, setField] = useAtom(leaseManagementAtoms.formAtom);
  return (
    <FormRow className={style.formContainer}>
      <Grid condensed fullWidth>
        <Row>
          <Column sm={12} lg={10} className={style.contentSection}>
            <div className={style.scrollableCol}>
              <RadioButtonGroup
                name={`retained_lands`}
                legendText={'Retained Lands'}
                valueSelected={fields.retainedLand.value}
                onChange={(value: 'all' | 'partial') =>
                  setField({
                    field: 'retainedLand',
                    value,
                  })
                }
              >
                <RadioButton labelText="None" value={'none'} />
                <RadioButton labelText="All Lands" value={'all'} />
                <RadioButton labelText="Partial Lands" value={'partial'} />
              </RadioButtonGroup>

              <ComboBox
                light
                titleText="Lease Extension"
                placeholder=""
                onChange={({ selectedItem }) => {
                  const key = Object.keys(leaseExtensions).find(
                    (key) =>
                      leaseExtensions[key as LeaseExtensions] === selectedItem
                  );
                  setField({
                    field: 'leaseExtension',
                    value: key ?? null,
                  });
                }}
                id={`lease-extension`}
                items={Object.values(leaseExtensions)}
                type="default"
                selectedItem={
                  fields.leaseExtension.value
                    ? leaseExtensions[
                        fields.leaseExtension.value as LeaseExtensions
                      ]
                    : null
                }
                disabled={fields.retainedLand.value === 'none'}
                invalid={
                  !!getError<LeaseManagementSubform>(fields, 'leaseExtension')
                }
                invalidText={getError<LeaseManagementSubform>(
                  fields,
                  'leaseExtension'
                )}
              />

              <SurveyAreaList
                disabled={fields.retainedLand.value !== 'partial'}
                locationReferences={fields.locationReferences.value}
              />
              {!!getError<LeaseManagementSubform>(
                fields,
                'locationReferences'
              ) && (
                <WarningPrompt>
                  <Warning16 />
                  <p>
                    Please ensure each survey area is contained within its
                    corresponding applicable area.
                  </p>
                </WarningPrompt>
              )}
            </div>
          </Column>

          <Column sm={12} lg={6} className={style.mapSection}>
            <LeaseMap leaseManagementAtoms={leaseManagementAtoms} />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

const mapConfigAtom = atomWithStorage(
  'insights-lease-map-config',
  getMapDefaultConfig()
);

const LeaseMap = ({
  leaseManagementAtoms,
}: {
  leaseManagementAtoms: LeaseManagementSubformAtoms;
}) => {
  const interpretationLocations = useAtomValue(locationReferencesAtom);
  const resources = useAtomValue(resourcesAtom);
  const quarteredGeometriesAtom = useMemo(
    () =>
      createAbortableQuarteredGeometriesAtom(
        interpretationLocations,
        resources?.quarteredGeographies.href
      ),

    [interpretationLocations, resources?.quarteredGeographies.href]
  );
  const quarteredGeometries = useAtomValue(quarteredGeometriesAtom);
  const fields = useAtomValue(leaseManagementAtoms.formAtom);
  const locationsAtom = useMemo(
    () => createLeaseSelectedAreasAtom(fields.locationReferences.value),
    [fields.locationReferences.value]
  );
  const locationReferences = useAtomValue(locationsAtom);

  if (!interpretationLocations.length) {
    return null;
  }

  const geometry = quarteredGeometries[0];
  if (!geometry) {
    return null;
  }
  return (
    <CenteredMap geometry={geometry} mapConfigAtom={mapConfigAtom}>
      {quarteredGeometries.map((el, i) => (
        <PolygonArea
          key={i}
          geometry={el}
          id={i}
          color={fields.retainedLand.value === 'all' ? green[70] : undefined}
        />
      ))}
      {fields.retainedLand.value === 'partial' &&
        locationReferences.map((el, i) => (
          <QuarteredPolygon
            id={`${el.referenceLocation.value?.id}-${i}`}
            key={i}
            location={el.referenceLocation.value}
            quarterCalls={el.quarterCalls.value}
            color={el.forDeletion ? red[70] : green[70]}
          />
        ))}
    </CenteredMap>
  );
};

const WarningPrompt = ({ children }: { children: React.ReactNode }) => {
  return <div className={style.warningPrompt}>{children}</div>;
};

const LeaseManagementModal = ({
  isOpen,
  onClose,
  onSave,
  leaseManagementAtoms,
}: ModalProps) => {
  return (
    <>
      <Modal
        open={isOpen}
        size="lg"
        modalHeading="Lease Management"
        primaryButtonText="Apply"
        secondaryButtonText="Cancel"
        onRequestSubmit={onSave}
        onRequestClose={onClose}
        className={style.leaseManagmentModal}
      >
        {isOpen && (
          <LeaseTermForm leaseManagementAtoms={leaseManagementAtoms} />
        )}
      </Modal>
    </>
  );
};

export { LeaseManagementModal };
