import { useAtom, useSetAtom } from 'jotai';
import { subscribe } from 'lib/pusher';
import { throttle } from 'lodash';
import { useEffect } from 'react';
import { newReleaseAtom, newReleaseCheckerAtom } from './atoms';

const useNewReleaseNotifier = () => {
  const checkForNewVersion = useSetAtom(newReleaseCheckerAtom);
  useEffect(() => {
    const subscription = subscribe('application');

    const throttledCheckForNewVersion = throttle(
      () => checkForNewVersion(),
      10 * 1000,
      {
        leading: true,
      }
    );

    const visibilityChangeHandler = () => {
      if (!document.hidden) {
        throttledCheckForNewVersion();
      }
    };

    subscription.bind('new-release', () => {
      throttledCheckForNewVersion();
    });

    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      subscription.unsubscribe();
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      throttledCheckForNewVersion.cancel();
    };
  }, [checkForNewVersion]);

  return useAtom(newReleaseAtom);
};

export { useNewReleaseNotifier };
