import { Output } from '../calculation/output';
import { Button, InlineNotification } from 'carbon-components-react';
import { CalculationLoading } from '../../common/calculation-loading';
import { lazy, Suspense, useState } from 'react';
import style from './depth_range.module.scss';
import { ColumnDependency16 } from '@carbon/icons-react';
import { useAtomValue } from 'jotai';
import { depthRangesMapAtom } from './atoms';

const ChainViewer = lazy(() => import('../calculation/chain_viewer'));

export interface DepthRangeProps {
  tractName: string;
  href: string;
}

export function DepthRange({ tractName, href }: DepthRangeProps) {
  const depthRangeMap = useAtomValue(depthRangesMapAtom);
  const [graphOpen, setGraphOpen] = useState(false);
  const depthRange = depthRangeMap[href];

  if (!depthRange) return null;

  if (depthRange.data && 'error' in depthRange.data)
    return (
      <InlineNotification
        kind="error"
        title={depthRange.data.error}
        lowContrast
      />
    );

  if (!depthRange.data) return null;

  const debuggingGraphHref = depthRange.data.resources.debuggingGraph?.href;
  return (
    <section>
      {debuggingGraphHref && (
        <>
          <div className={style.popupBtnRow}>
            <Button
              kind="ghost"
              renderIcon={ColumnDependency16}
              onClick={() => setGraphOpen(true)}
            >
              Open mineral graph
            </Button>
          </div>
          {graphOpen && (
            <Suspense fallback={<div>Loading</div>}>
              <ChainViewer
                tractName={tractName}
                depthRangeName={depthRange.data.name}
                url={debuggingGraphHref}
                onClose={() => setGraphOpen(false)}
              />
            </Suspense>
          )}
        </>
      )}
      {depthRange.data.output ? (
        <Output
          type="mineral"
          tractName={tractName}
          depthRangeName={depthRange.data.name}
          graph={debuggingGraphHref ?? null}
          output={depthRange.data.output}
          outputRows={depthRange.data.outputRows}
          mineralLeaseholdRows={depthRange.data.mineralLeaseholdRows}
        />
      ) : (
        <CalculationLoading />
      )}
    </section>
  );
}
