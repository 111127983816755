import { ReactNode } from 'react';
import { Popup } from 'react-map-gl';
import { Link } from 'react-router-dom';

import style from './map.module.scss';
import { PopupState } from './types';
import { getAreaHref } from './utils';
import { WellPopup } from './well-popup';

interface Props {
  popup: PopupState;
  locations: Array<{ id: number; name: string; quarterCalls?: string[] }>;
  onClose: () => void;
  showPopupOnSingleDevArea?: boolean;
}

const MapPopup = ({
  popup,
  locations,
  onClose,
  showPopupOnSingleDevArea = false,
}: Props) => {
  if (!popup.visible) {
    return null;
  }

  if (popup.data?.type === 'wells') {
    return (
      <WellPopup
        closeButton={!!popup.pinned}
        onClose={onClose}
        latitude={popup.latitude}
        longitude={popup.longitude}
        wells={popup.data.value}
      />
    );
  }

  let content: ReactNode = null;

  switch (popup.data?.type) {
    case 'basin-tier':
      content = (
        <>
          {popup.data.value.map((el, i) => (
            <p key={`${el}-${i}`}>{el}</p>
          ))}
        </>
      );
      break;
    case 'development-areas': {
      if (popup.data.value.devAreas.length === 1 && !showPopupOnSingleDevArea)
        break;
      content = (
        <>
          {popup.data.value.basinTier.map((el, i) => (
            <p key={`${el}-${i}`}>{el}</p>
          ))}
          {popup.data.value.devAreas.map(({ name, evalId }, i) => {
            const href = getAreaHref(name, evalId);
            return (
              <p key={`${name}-${i}`}>
                <Link to={href} target={'_blank'}>
                  {name}
                </Link>
              </p>
            );
          })}
        </>
      );
      break;
    }
    case 'reference-locations':
      content = locations.length ? (
        <>
          {locations.map((referenceLocation, i) => {
            return (
              <p key={`${referenceLocation.id}-${i}`}>
                {referenceLocation.name}
                {referenceLocation.quarterCalls?.length
                  ? ` (${referenceLocation.quarterCalls.join(', ')})`
                  : null}
              </p>
            );
          })}
        </>
      ) : null;
      break;
  }

  if (!content) {
    return null;
  }

  return (
    <Popup
      key={Number(popup.pinned)}
      closeButton={popup.pinned}
      closeOnClick={false}
      onClose={onClose}
      latitude={popup.latitude}
      longitude={popup.longitude}
      className={style.popup}
    >
      {content}
    </Popup>
  );
};

export default MapPopup;
