import { Checkbox } from 'carbon-components-react';
import { useAtom } from 'jotai';
import { startCase } from 'lodash';
import { MapUserConfig, MapUserConfigAtom } from './types';
import classNames from 'classnames';

import style from './map.module.scss';

const LayerOption = ({
  layerKey,
  mapConfigAtom,
}: {
  layerKey: 'wells' | 'developmentAreas' | 'basinTier';
  mapConfigAtom: MapUserConfigAtom;
}) => {
  const [mapConfig, setMapConfig] = useAtom(mapConfigAtom);

  return (
    <>
      {Object.keys(mapConfig[layerKey].options).map((layer) => {
        const checkboxClassName = classNames({
          [style.surfaceHole]: layer === 'surfaceHole',
          [style.bottomHole]: layer === 'bottomHole',
          [style.stick]: layer === 'stick',
          [style.survey]: layer === 'survey',
          [style.vertical]: layer === 'vertical',
          [style.pricing]: layer === 'pricing',
          [style.units]: layer === 'units',
          [style.basin]: layer === 'basin',
          [style.tier]: layer === 'tier',
        });
        return (
          <div key={layer} className={checkboxClassName}>
            <Checkbox
              id={layer}
              labelText={startCase(layer)}
              checked={
                mapConfig[layerKey].options[
                  layer as keyof MapUserConfig[typeof layerKey]['options']
                ]
              }
              onChange={(checked: boolean) => {
                setMapConfig((current) => {
                  const allChecked = Object.keys(
                    current[layerKey].options
                  ).every((el) => {
                    if (el === layer) return checked;
                    return current[layerKey].options[
                      el as keyof MapUserConfig[typeof layerKey]['options']
                    ];
                  });
                  return {
                    ...current,
                    [layerKey]: {
                      checked: allChecked,
                      options: {
                        ...current[layerKey].options,
                        [layer]: checked,
                      },
                    },
                  };
                });
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const MapLayerSelector = ({
  mapConfigAtom,
}: {
  mapConfigAtom: MapUserConfigAtom;
}) => {
  const [mapConfig, setMapConfig] = useAtom(mapConfigAtom);
  return (
    <div className={style.layersSelector}>
      <div className={style.titleArea}>
        <div className={style.allCheckBox}>
          <Checkbox
            id={'well-layers'}
            labelText="Wells"
            checked={mapConfig.wells.checked}
            onChange={(checked: boolean) => {
              setMapConfig((current) => ({
                ...current,
                ['wells']: {
                  checked,
                  options: {
                    surfaceHole: checked,
                    bottomHole: checked,
                    stick: checked,
                    survey: checked,
                    vertical: checked,
                  },
                },
              }));
            }}
          />
        </div>
      </div>
      <div className={style.groupChecks}>
        <LayerOption layerKey="wells" mapConfigAtom={mapConfigAtom} />
      </div>
      <div className={style.titleArea}>
        <div className={style.allCheckBox}>
          <Checkbox
            id={'development-layers'}
            labelText="Development Areas"
            checked={mapConfig.developmentAreas.checked}
            onChange={(checked: boolean) => {
              setMapConfig((current) => ({
                ...current,
                ['developmentAreas']: {
                  checked,
                  options: {
                    pricing: checked,
                    units: checked,
                  },
                },
              }));
            }}
          />
        </div>
      </div>
      <div className={style.groupChecks}>
        <LayerOption
          layerKey="developmentAreas"
          mapConfigAtom={mapConfigAtom}
        />
      </div>
      <div className={style.titleArea}>
        <div className={style.allCheckBox}>
          <Checkbox
            id={'type-curves'}
            labelText="Type Curves"
            checked={mapConfig.typeCurves?.checked}
            onChange={(checked: boolean) => {
              setMapConfig((current) => ({
                ...current,
                ['typeCurves']: {
                  checked,
                },
              }));
            }}
          />
        </div>
      </div>
      <div className={style.titleArea}>
        <div className={style.allCheckBox}>
          <Checkbox
            id={'basin-tier-layers'}
            labelText="Basin/Tier"
            checked={mapConfig.basinTier.checked}
            onChange={(checked: boolean) => {
              setMapConfig((current) => ({
                ...current,
                ['basinTier']: {
                  checked,
                  options: {
                    basin: checked,
                    tier: checked,
                  },
                },
              }));
            }}
          />
        </div>
      </div>
      <div className={style.groupChecks}>
        <LayerOption layerKey="basinTier" mapConfigAtom={mapConfigAtom} />
      </div>
      <h3 className={style.label}>Settings</h3>
      <div className={style.titleArea}>
        <div className={style.allCheckBox}>
          <Checkbox
            id={'popups'}
            labelText="Popups"
            checked={mapConfig.popups.checked}
            onChange={(checked: boolean) => {
              setMapConfig((current) => ({
                ...current,
                ['popups']: {
                  checked,
                },
              }));
            }}
          />
        </div>
      </div>
      <div className={style.titleArea}>
        <div className={style.allCheckBox}>
          <Checkbox
            id={'scrollZoom'}
            labelText="Scroll Zoom"
            checked={mapConfig.scrollZoom.checked}
            onChange={(checked: boolean) => {
              setMapConfig((current) => ({
                ...current,
                ['scrollZoom']: {
                  checked,
                },
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { MapLayerSelector };
