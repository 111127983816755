import {
  Grid,
  Row,
  Column,
  Button,
  Link,
  Tile,
  InlineNotification,
  TextInput,
  Checkbox,
  FormLabel,
  Modal,
  InlineLoading,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';
import { atom, useAtom, useAtomValue } from 'jotai';
import {
  runMutationAtom,
  selectedAssumptionsFormAtom,
  useBasinTierNameListAtom,
  useScenarioFormAtom,
  useSelectedFormNameAtom,
  useSortedTiersAtom,
  useTierFormAtom,
} from './atoms';
import { GlobalScenarioForm } from './global-scenario-form';
import { Tiers } from './tiers';
import { TierForm } from './tier-form';
import {
  AtomizedForm,
  getFormHasChanges,
  getFormIsInvalidAtom,
} from 'atoms/form-atoms';
import { ScenariosForm } from './types';
import { TextField } from 'components/form-fields/text-field';

import style from './scenario-builder.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorToast from 'components/error-toast';
import { useMemo, useReducer, useState } from 'react';
import { ScenarioAssumptionsResponse } from 'types/scenarios-api/responses';
import { DevelopmentAreaExceptions } from './dev-area-exceptions';
import { NavigationGuard } from 'components/navigation-guard';
import { DropdownField } from 'components/form-fields/dropdown-field';
import { capitalizeFirstLetter } from 'utils/strings';
import { ScenariosResponse } from 'types/api-responses';
import { resourcesAtom } from 'atoms/root';
import { SuccessToast } from 'components/success-toast';
import { ErrorsType } from 'lib/types';
import { FieldErrorNotification } from 'components/field-error-notification';
import classNames from 'classnames';

export type DevAreasInclusionState = 'INCLUDED' | 'EXCLUDED';

const Buttons = ({
  type,
  href,
  form,
}: {
  type: 'POST' | 'PATCH';
  href: string;
  form: AtomizedForm<ScenariosForm>;
}) => {
  const history = useHistory();
  const [status, sendForm] = useAtom(runMutationAtom);
  const [error, setError] = useState<string | undefined>(undefined);
  const [allowNavigationGuard, setAllowNavigationGuard] =
    useState<boolean>(true);
  const invalidFormAtom = useMemo(() => {
    return getFormIsInvalidAtom(atom(form));
  }, [form]);

  const pendingChangesAtom = useMemo(() => {
    return getFormHasChanges(atom(form));
  }, [form]);

  const isFormInvalid = useAtomValue(invalidFormAtom);
  const hasPendingChanges = useAtomValue(pendingChangesAtom);

  const resources = useAtomValue(resourcesAtom);

  const [renameSuccessful, setRenameSuccessful] = useState(0);

  const cloneButtonDisabled =
    status.loading || isFormInvalid || hasPendingChanges;

  const basinTierListNameAtom = useBasinTierNameListAtom(form.tiersAttributes);
  const basinTierNameList = useAtomValue(basinTierListNameAtom);

  const location = useLocation<{ trigger: 'CLONE' }>();

  return (
    <div className={style.buttons}>
      {error && <ErrorToast key={error} message={error} />}
      {hasPendingChanges && (
        <InlineNotification
          kind="info"
          lowContrast
          title="Unsaved changes"
          hideCloseButton={true}
        />
      )}
      <NavigationGuard
        hasChanges={hasPendingChanges && allowNavigationGuard}
        message="Are you sure you want to discard this scenario?"
      />
      {type === 'PATCH' && (
        <>
          {renameSuccessful > 0 && (
            <SuccessToast
              key={renameSuccessful}
              message={'Scenario Updated'}
              subtitle={'You have successfully renamed the scenario'}
            />
          )}
          <Rename
            form={form}
            sending={status.loading}
            disable={hasPendingChanges}
            onRename={(
              name: string,
              onSuccess: () => void,
              onError: () => void
            ) => {
              if (resources) {
                sendForm({
                  type: 'PATCH',
                  href,
                  form,
                  name,
                  onSuccess: () => {
                    onSuccess();
                    setRenameSuccessful((c) => ++c);
                  },
                  onError,
                });
              }
            }}
          />
        </>
      )}
      {location.state?.trigger === 'CLONE' && (
        <SuccessToast
          message="Scenario Cloned"
          subtitle="You have successfully cloned the scenario"
          onClose={() => {
            window.history.replaceState({}, '');
            return true;
          }}
        ></SuccessToast>
      )}
      <Clone
        basinTierNameList={basinTierNameList}
        disabled={cloneButtonDisabled}
        isLoading={status.loading}
        onClone={(
          name,
          basinTierSelections,
          devAreasExceptionState,
          onError
        ) => {
          if (resources) {
            sendForm({
              type: 'POST',
              href: resources.scenarios.href,
              form,
              name,
              basinTierSelections,
              devAreasInclusion: devAreasExceptionState,
              onSuccess: (scenario: ScenariosResponse) => {
                history.push(scenario.href, { trigger: 'CLONE' });
              },
              onError,
            });
          }
        }}
      />
      <Button
        disabled={status.loading || isFormInvalid || !hasPendingChanges}
        onClick={() => {
          setError(undefined);
          setAllowNavigationGuard(false);
          sendForm({
            type,
            href,
            form,
            onSuccess: () => {
              history.push(`/scenarios`);
            },
            onError: () => {
              setError(
                `Unable to ${
                  type === 'PATCH' ? 'update' : 'create'
                } the scenario`
              );
              setAllowNavigationGuard(true);
            },
          });
        }}
      >
        {isFormInvalid ? 'Invalid form' : 'Save Scenario'}
      </Button>
    </div>
  );
};

const Clone = ({
  disabled,
  isLoading,
  onClone,
  basinTierNameList,
}: {
  disabled: boolean;
  isLoading: boolean;
  onClone: (
    scenarioName: string,
    basinTierSelections: Set<string>,
    devAreasExceptionState: DevAreasInclusionState,
    onError: (errors?: ErrorsType) => void
  ) => void;
  basinTierNameList: string[];
}) => {
  const [showNameInput, toggleShowNameInput] = useReducer((s) => !s, false);
  const [newName, setNewName] = useState('');
  const [errors, setErrors] = useState<ErrorsType | undefined>(undefined);
  const [basinTierSelections, setBasinTierSelections] = useState(
    new Set<string>()
  );

  const [devAreasExceptionState, setDevAreasExceptionState] =
    useState<DevAreasInclusionState>('INCLUDED');

  const selectAllState =
    basinTierSelections.size === basinTierNameList.length
      ? { checked: true }
      : basinTierSelections.size === 0
      ? { checked: false }
      : { indeterminate: true };

  const handleBasinTierSelection = (id: string) => {
    setBasinTierSelections((basinTierSelections) => {
      const updatedBasinTier = new Set(basinTierSelections);
      updatedBasinTier.has(id)
        ? updatedBasinTier.delete(id)
        : updatedBasinTier.add(id);
      return updatedBasinTier;
    });
  };

  return (
    <>
      <Modal
        modalHeading="Cloned Scenario Name"
        primaryButtonText={isLoading ? <InlineLoading /> : <p>Clone</p>}
        open={showNameInput}
        size="md"
        className={style.cloneScenarioModal}
        onRequestSubmit={() => {
          setErrors(undefined);
          onClone(
            newName,
            basinTierSelections,
            devAreasExceptionState,
            (errors) => {
              setErrors(errors);
            }
          );
        }}
        secondaryButtonText={
          <p className={classNames({ [style.disable]: isLoading })}>Cancel</p>
        }
        onRequestClose={() => {
          if (isLoading) return;
          setNewName('');
          setBasinTierSelections(new Set());
          setErrors(undefined);
          setDevAreasExceptionState('INCLUDED');
          toggleShowNameInput();
        }}
        primaryButtonDisabled={!newName.trim() || isLoading}
      >
        {errors && (
          <div className={style.errors}>
            <FieldErrorNotification
              errors={errors}
              onClose={() => setErrors(undefined)}
            />
          </div>
        )}
        <fieldset>
          <TextInput
            id={'clone-scenario-name'}
            labelText={'Enter Name* (required)'}
            size="lg"
            light
            value={newName}
            onChange={({ target: { value } }) => setNewName(value)}
            disabled={disabled}
          />
        </fieldset>
        {basinTierNameList.length > 0 && (
          <div className={style.basinTierSelections}>
            <FormLabel>Select Basin/Tier</FormLabel>
            <fieldset>
              <Checkbox
                id="allBasinTier"
                labelText="Select All"
                onClick={() => {
                  if (basinTierSelections.size) {
                    setBasinTierSelections(new Set());
                  } else {
                    setBasinTierSelections(new Set(basinTierNameList));
                  }
                }}
                {...selectAllState}
              ></Checkbox>
            </fieldset>
            <ul className={style.rowBasinTiers}>
              {basinTierNameList.map((basinTierName) => (
                <li key={basinTierName}>
                  <Checkbox
                    id={basinTierName}
                    labelText={basinTierName}
                    checked={basinTierSelections.has(basinTierName)}
                    onClick={() => handleBasinTierSelection(basinTierName)}
                  ></Checkbox>
                </li>
              ))}
            </ul>
          </div>
        )}
        <RadioButtonGroup
          name="Development Areas Exceptions"
          legendText="Development Areas Exceptions"
          valueSelected={devAreasExceptionState}
          onChange={(value: DevAreasInclusionState) =>
            setDevAreasExceptionState(value)
          }
        >
          <RadioButton labelText="Include" value={'INCLUDED'}></RadioButton>
          <RadioButton labelText="Exclude" value={'EXCLUDED'}></RadioButton>
        </RadioButtonGroup>
      </Modal>
      <Button onClick={() => toggleShowNameInput()} disabled={disabled}>
        Clone
      </Button>
    </>
  );
};

const Rename = ({
  onRename,
  form,
  sending,
  disable,
}: {
  form: AtomizedForm<ScenariosForm>;
  sending: boolean;
  onRename: (
    scenarioName: string,
    onSuccess: () => void,
    onError: () => void
  ) => void;
  disable: boolean;
}) => {
  const [open, toggleOpen] = useReducer((s) => !s, false);
  const [name, setName] = useAtom(form.name);
  const [newName, setNewName] = useState(name.value);
  const [error, setError] = useState('');
  const [step, setStep] = useState<'renaming' | 'confirming'>('renaming');
  const buttonLabel = step === 'renaming' ? 'OK' : 'Confirm';

  return (
    <>
      <Modal
        modalHeading="Rename Scenario"
        primaryButtonText={buttonLabel}
        open={open}
        size="md"
        className={style.renameScenarioModal}
        onRequestSubmit={() => {
          step === 'confirming'
            ? onRename(
                newName,
                () => {
                  setName({
                    value: newName,
                    __options: { skipDirtyCheck: true },
                  });
                  setStep('renaming');
                  toggleOpen();
                },
                () => setError('Unable to rename the scenario')
              )
            : setStep('confirming');
        }}
        secondaryButtonText="Cancel"
        onRequestClose={() => {
          setNewName(name.value);
          setStep('renaming');
          toggleOpen();
        }}
        primaryButtonDisabled={
          !newName.trim() || newName === name.value || sending
        }
      >
        {step === 'confirming' ? (
          <p>
            Are you sure you want to rename scenario from
            <em>
              &quot;{name.value}
              &quot;
            </em>
            to
            <em>
              &quot;
              {newName}&quot;
            </em>
          </p>
        ) : (
          <fieldset>
            <TextInput
              id={'rename-scenario-name'}
              labelText={'Name'}
              size="lg"
              light
              value={newName}
              onChange={({ target: { value } }) => setNewName(value)}
            />
          </fieldset>
        )}
        {error && <InlineNotification kind="error" title={error} lowContrast />}
      </Modal>
      <Button onClick={() => toggleOpen()} disabled={disable}>
        Rename
      </Button>
    </>
  );
};

const ScenarioBuilder = ({
  assumptions,
  type,
  href,
  isEditing,
}: {
  assumptions: ScenarioAssumptionsResponse;
  type: 'POST' | 'PATCH';
  href: string;
  isEditing?: boolean;
  getAssumptions: () => Promise<void>;
}) => {
  const [selectedTierForm, setSelectedTierForm] = useAtom(
    selectedAssumptionsFormAtom
  );
  const formAtoms = useScenarioFormAtom(
    assumptions,
    type === 'POST' ? 'new' : 'edit'
  );
  const form = useAtomValue(formAtoms);
  const sortedTiersAtom = useSortedTiersAtom(form.tiersAttributes);
  const tierFormAtom = useTierFormAtom(sortedTiersAtom);
  const selectedFormNameAtom = useSelectedFormNameAtom(tierFormAtom);
  const formName = useAtomValue(selectedFormNameAtom);

  return (
    <Grid className="bx--no-gutter" fullWidth condensed>
      <Row className={style.scenarioForm}>
        <Column md={2}>
          <ul className={style.tierList}>
            <li
              className={
                selectedTierForm === undefined
                  ? style.subNavActive
                  : style.subNavLinks
              }
            >
              <Link onClick={() => setSelectedTierForm('global')}>Global</Link>
            </li>
            <li
              className={
                selectedTierForm === undefined
                  ? style.subNavActive
                  : style.subNavLinks
              }
            >
              <Link
                onClick={() => setSelectedTierForm('developmentAreaExceptions')}
              >
                Development Areas Exceptions
              </Link>
            </li>
            <Tiers
              formAtom={form.tiersAttributes}
              globalAssumptions={assumptions.globalAssumptions}
            />
          </ul>
        </Column>
        <Column md={6}>
          <Tile className={style.scenarioTile}>
            <div className={style.formBtns}>
              <Buttons type={type} href={href} form={form} />
            </div>
            <div className={style.scenarioName}>
              <h2 className={style.tierName}>{formName}</h2>
              <TextField
                id={`name`}
                labelText="Name (Required)"
                fieldAtom={form.name}
                disabled={isEditing}
              />
            </div>
            <div className={style.interestType}>
              <DropdownField
                id="scenario-interest-type"
                items={['royalty', 'working']}
                label=""
                titleText="Interest Type"
                itemToString={(el) => capitalizeFirstLetter(el)}
                light
                field={form.interestType}
              />
            </div>
            {selectedTierForm === 'global' && (
              <GlobalScenarioForm
                evaluationParameters={form?.globalAssumptions}
              />
            )}
            {selectedTierForm === 'developmentAreaExceptions' && (
              <DevelopmentAreaExceptions
                form={form?.developmentAreasAttributes}
              />
            )}
            {typeof selectedTierForm !== 'string' && (
              <TierForm formAtom={tierFormAtom} />
            )}
          </Tile>
        </Column>
      </Row>
    </Grid>
  );
};

export { ScenarioBuilder };
