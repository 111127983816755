import { Help24 } from '@carbon/icons-react';
import { Button, Checkbox } from 'carbon-components-react';
import { Atom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { formAtoms } from '../atoms';

import style from './review-section-checkbox.module.scss';
import { ReviewableSections } from './types';

interface Props {
  label: string;
  updateTrackingAtom: Atom<number | undefined>;
  field: ReviewableSections;
  helpMessage: string;
}

const ReviewSectionCheckbox = ({
  updateTrackingAtom,
  field,
  label,
  helpMessage,
}: Props) => {
  const didMount = useRef(false);
  const lastUpdate = useAtomValue(updateTrackingAtom);
  const [fields, setFields] = useAtom(useAtomValue(formAtoms).formAtom);
  const [reviewed, setReviewed] = useState<{
    value: boolean;
    lastUpdate: number;
  }>({
    value: fields[field].value,
    lastUpdate: lastUpdate || 0,
  });

  if (lastUpdate && lastUpdate > reviewed.lastUpdate) {
    setReviewed({ lastUpdate, value: false });
  }

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    setFields({ field, value: reviewed.value });
    // We really need to depend only on the reviewed value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewed.value]);

  return (
    <div className={style.container}>
      <Checkbox
        className={style.reviewField}
        id={`checkbox-${field}`}
        checked={reviewed.value}
        onChange={(checked: boolean) => {
          setReviewed({
            lastUpdate: reviewed.lastUpdate,
            value: checked,
          });
        }}
        labelText={label}
      />
      <Button
        size="sm"
        hasIconOnly
        renderIcon={Help24}
        iconDescription={helpMessage}
        tooltipAlignment="center"
        tooltipPosition="left"
        kind="ghost"
      />
    </div>
  );
};

export { ReviewSectionCheckbox };
