import { atomField, AtomizedForm, required } from 'atoms/form-atoms';
import {
  ImportedEntityForm,
  IndividualEntityForm,
  OrganizationEntityForm,
} from './types';
import { FormRowStatus } from 'atoms/create-resource-atom';
import { EntityResponse } from 'types/api-responses';

const defaultContactAttributes = {
  streetAddress: null,
  city: null,
  state: null,
  zipCode: null,
  phoneNumber: null,
  email: null,
  notes: null,
};

const phoneNumberRow = (el?: EntityResponse['phoneNumbers'][0]) => {
  return {
    id: atomField(el?.id),
    number: atomField(el?.number || null, {
      validate: (v) => required(v, 'Phone number is required'),
    }),
    primary: atomField(el?.primary || false),
    _rowStatus: atomField<FormRowStatus>(el ? 'saved' : 'new'),
  };
};

const emailRow = (el?: EntityResponse['emailAddresses'][0]) => {
  return {
    id: atomField(el?.id),
    email: atomField(el?.email || null, {
      validate: (v) => required(v, 'Email is required'),
    }),
    primary: atomField(el?.primary || false),
    _rowStatus: atomField<FormRowStatus>(el ? 'saved' : 'new'),
  };
};

const addressRow = (el?: EntityResponse['addresses'][0]) => {
  return {
    id: atomField(el?.id),
    streetAddress: atomField(el?.streetAddress || null),
    city: atomField(el?.city || null),
    state: atomField(el?.state || null),
    zipCode: atomField(el?.zipCode || null),
    primary: atomField(el?.primary || false),
    _rowStatus: atomField<FormRowStatus>(el ? 'saved' : 'new'),
  };
};

const commonAttributes = (entity?: EntityResponse) => {
  return {
    phoneNumbersAttributes: atomField(
      entity?.phoneNumbers.map(phoneNumberRow) || []
    ),
    emailAddressesAttributes: atomField(
      entity?.emailAddresses.map(emailRow) || []
    ),
    addressesAttributes: atomField(entity?.addresses.map(addressRow) || []),
  };
};

const createIndividualEntityForm = (
  entity?: EntityResponse
): AtomizedForm<IndividualEntityForm> => {
  return {
    notes: atomField(entity?.notes || null),
    concreteEntityAttributes: {
      firstName: atomField(entity?.concreteEntity.firstName || null, {
        validate: (v) => required(v, 'First Name is required'),
      }),
      middleName: atomField(entity?.concreteEntity.middleName || null),
      lastName: atomField(entity?.concreteEntity.lastName || null, {
        validate: (v) => required(v, 'Last Name is required'),
      }),
      birthDate: atomField(entity?.concreteEntity.birthDate || null),
      deathDate: atomField(entity?.concreteEntity.deathDate || null),
    },
    ...commonAttributes(entity),
  };
};

const createOrganizationEntityForm = (
  entity?: EntityResponse
): AtomizedForm<OrganizationEntityForm> => {
  return {
    notes: atomField(entity?.notes || null),
    concreteEntityAttributes: {
      primaryName: atomField(entity?.concreteEntity.primaryName || null, {
        validate: (v) => required(v, 'Primary Name is required'),
      }),
      organizationType: atomField(
        entity?.concreteEntity.organizationType || null,
        {
          validate: (v) => required(v, 'Organization Type is required'),
        }
      ),
      formationDate: atomField(entity?.concreteEntity.formationDate || null),
      terminationDate: atomField(
        entity?.concreteEntity.terminationDate || null
      ),
    },
    ...commonAttributes(entity),
  };
};

const createImportedEntityForm = (
  entity?: EntityResponse
): AtomizedForm<ImportedEntityForm> => {
  return {
    concreteEntityAttributes: {
      name: atomField(entity?.name || null, {
        validate: (v) => required(v, 'Name is required'),
      }),
    },
    ...commonAttributes(entity),
  };
};

export {
  addressRow,
  createImportedEntityForm,
  createIndividualEntityForm,
  createOrganizationEntityForm,
  defaultContactAttributes,
  emailRow,
  phoneNumberRow,
};
