import { atom } from 'jotai';

import request from 'lib/request';
import type {
  ApiQueryResource,
  ApiQueryResponse,
  SearchResult,
} from 'types/api-responses';
import { getResources } from './root';

export interface SearchParams {
  text?: string;
  page?: number;
  pageSize?: number;
  sortAttribute?: string;
  sortDirection?: 'ASC' | 'DESC';
}

export type SearchAtom<
  Data extends ApiQueryResponse,
  Params extends SearchParams = SearchParams,
  Facets = undefined,
  Aggregations = undefined
> = {
  loading?: boolean;
  data?: SearchResult<Data, Facets, Aggregations>;
  currentPayload?: Params;
  error?: string;
};

const searchAtomPair = <
  Data extends ApiQueryResponse,
  Params extends SearchParams = SearchParams,
  Facets = undefined,
  Aggregations = undefined
>(
  resource: ApiQueryResource | { href: string },
  defaultPayload?: Params
) => {
  type SearchAtomApplied = SearchAtom<Data, Params, Facets, Aggregations>;
  const searchDataAtom = atom<SearchAtomApplied>({});
  let controller = new AbortController();

  const searchAtom = atom<
    SearchAtomApplied,
    [((params: Params | undefined) => Params | undefined) | undefined],
    undefined
  >(
    (get) => get(searchDataAtom),
    (get, set, payload) => {
      const runSearch = async () => {
        const currentParams = get(searchDataAtom).currentPayload;
        const searchPayload = payload?.(currentParams) ?? defaultPayload;
        if (!searchPayload) return;
        try {
          controller.abort();
          controller = new AbortController();
          set(searchDataAtom, (current) => ({
            ...current,
            currentPayload: searchPayload,
            loading: true,
          }));

          const resources = getResources(get);
          const queryHref =
            typeof resource === 'object' && 'href' in resource
              ? resource.href
              : resources?.queries[resource].href;
          if (!queryHref) return;

          const response = await request.post<
            SearchResult<Data, Facets, Aggregations>,
            unknown
          >(queryHref, searchPayload, controller);

          if (response.type === 'success') {
            set(searchDataAtom, {
              currentPayload: searchPayload,
              loading: false,
              data: response.data,
            });
            return;
          }

          if (response.type === 'fatal') {
            set(searchDataAtom, (current) => ({
              ...current,
              loading: false,
              error: response.message,
            }));
            return;
          }
        } catch (error) {
          if (error instanceof Error) {
            if (error.name === 'AbortError') {
              return;
            }
          }
          throw error;
        }
      };
      runSearch();
    }
  );

  searchAtom.onMount = (set) => {
    set(undefined);
  };

  const resetSearchDataAtom = atom<
    null,
    [{ unsetCurrentPayload: boolean } | undefined],
    undefined
  >(null, (get, set, payload) => {
    set(searchDataAtom, (current) => ({
      ...current,
      currentPayload: payload?.unsetCurrentPayload
        ? undefined
        : current.currentPayload,
      data: undefined,
    }));
  });

  return { resetSearchDataAtom, searchAtom };
};

export default searchAtomPair;
