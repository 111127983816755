import { Events20, User16, Launch16 } from '@carbon/icons-react';
import { Date } from 'components/date';
import { Link } from 'components/link';
import { Tag } from 'carbon-components-react';
import { ResourceFetcher } from 'components/resource-fetcher';
import { AliasedEntityResponse, EntityResponse } from 'types/api-responses';
import { DefinitionEntry } from './definition-entry';

import style from './entity-preview.module.scss';

const AliasedEntities = ({ data }: { data: AliasedEntityResponse[] }) => {
  if (!data.length) return null;
  const nonCanonical = data.filter((entity) => !entity.canonical);
  if (!nonCanonical.length) return null;
  return (
    <dl className={style.aliasesArea}>
      <dt>Aliases</dt>
      <dd>
        <ul>
          {nonCanonical.map(({ entity }) => (
            <li key={entity.id}>
              <Link
                to={entity.href}
                target="_blank"
                className={style.twoLinesTruncate}
              >
                <Launch16 />
                {entity.name}
              </Link>
            </li>
          ))}
        </ul>
      </dd>
    </dl>
  );
};

const EntityPreviewContent = ({ entity }: { entity: EntityResponse }) => {
  const type = entity.type;
  return (
    <div>
      <div className={style.titleArea}>
        <div className={style.iconArea}>
          {type === 'OrganizationEntity' ? <Events20 /> : <User16 />}
        </div>
        <div className={style.popName}>
          {entity.name} <p>Entity</p>
        </div>
      </div>
      <DefinitionEntry label="Entity Id">
        <>{entity.id}</>
      </DefinitionEntry>
      <DefinitionEntry label="Entity Type">
        <Tag size="sm" type="cyan">
          {type.replace('Entity', '')}
        </Tag>
      </DefinitionEntry>
      {type === 'IndividualEntity' && (
        <DefinitionEntry label="Date of Birth">
          <Date date={entity.concreteEntity.birthDate} fallback="(none)" />
        </DefinitionEntry>
      )}
      {type === 'IndividualEntity' && (
        <DefinitionEntry label="Date of Death">
          <Date date={entity.concreteEntity.deathDate} fallback="(none)" />
        </DefinitionEntry>
      )}
      {type === 'OrganizationEntity' && (
        <DefinitionEntry label="Formation Date">
          <Date date={entity.concreteEntity.formationDate} fallback="(none)" />
        </DefinitionEntry>
      )}
      {type === 'OrganizationEntity' && (
        <DefinitionEntry label="Termination Date">
          <Date
            date={entity.concreteEntity.terminationDate}
            fallback="(none)"
          />
        </DefinitionEntry>
      )}
      <div>
        <ResourceFetcher<AliasedEntityResponse[]>
          href={entity.resources.aliasedEntities.href}
          component={AliasedEntities}
        />
      </div>
    </div>
  );
};

const EntityPreview = ({ data }: { data: EntityResponse }) => {
  return (
    <div>
      <EntityPreviewContent entity={data} />
    </div>
  );
};

export { EntityPreview };
