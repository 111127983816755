import { useState, useMemo } from 'react';
import { Add16, Table16, Grid16 } from '@carbon/icons-react';
import { PageHeader } from 'components/page-header';
import {
  Button,
  Search,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  SkeletonPlaceholder,
  Grid,
  Row,
  Tile,
  ContentSwitcher,
} from 'carbon-components-react';
import { useAtom, useSetAtom } from 'jotai';
import { CardsGrid } from 'components/cards-grid';
import { EmptyState } from 'components/empty-state';
import ErrorToast from 'components/error-toast';
import { Workspace } from 'components/icons';
import { SearchResultCard } from 'components/search-result-card';
import searchAtomPair from 'atoms/search-atom';
import type { TitleWorkspaceResponse } from 'types/api-responses';
import CreateWorkspace from './create-workspace';
import { debounce } from 'lodash';
import { Date } from 'components/date';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import { Link } from 'components/link';
import { PaginatedTable } from 'components/table/paginated-table';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import {
  format,
  DECIMAL_FORMATTER_TWO_FRACTIONS,
  pageSizeAtom,
  DEFAULT_PAGE_SIZE,
} from 'lib/ui';
import plur from 'plur';
import style from './title-workspaces.module.scss';

import { atomWithStorage } from 'jotai/utils';

const isTableViewAtom = atomWithStorage(
  'title-workspace-table-preference',
  false
);

const TitleWorkspaces = () => {
  const [isTable] = useAtom(isTableViewAtom);
  const [newWorkspaceModalOpen, setNewWorkspaceModalOpen] = useState(false);
  const [pageSizePreference] = useAtom(pageSizeAtom);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAtoms = useMemo(() => createSearchAtom(pageSizePreference), []);
  const setSearchPayload = useSetAtom(searchAtoms.searchAtom);

  return (
    <>
      <Helmet>
        <title>{pageTitle('Title Workspaces')}</title>
      </Helmet>
      {newWorkspaceModalOpen ? (
        <CreateWorkspace
          onClose={() => setNewWorkspaceModalOpen(false)}
          onCreated={() => {
            setSearchPayload((current) => ({
              ...current,
              text: '',
              page: 1,
            }));
            setNewWorkspaceModalOpen(false);
          }}
        />
      ) : null}
      <PageHeader
        title="Title Workspaces"
        breadcrumbs={[['title_workspaces', 'Title Workspaces']]}
        actions={
          <div className={style.sortView}>
            <Button
              renderIcon={Add16}
              onClick={() => setNewWorkspaceModalOpen(true)}
              size="sm"
            >
              <span>Create Title Workspace</span>
            </Button>
          </div>
        }
      />
      <Grid className="bx--no-gutter" fullWidth>
        <Row className={style.filtersArea}>
          <TitleTypeFilter searchAtoms={searchAtoms} />
        </Row>
      </Grid>
      {isTable ? (
        <TitleWorkspaceTable searchAtoms={searchAtoms} />
      ) : (
        <TitleWorkspaceList searchAtoms={searchAtoms} />
      )}
    </>
  );
};

const TitleSearchField = ({
  searchAtoms: { searchAtom, resetSearchDataAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchDisplayText, setSearchDisplayText] = useState('');
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        resetSearchData(undefined);
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              pageSize: current?.pageSize || 10,
              text: value,
              page: 1,
            }
        );
      }, 400),
    [setSearchPayload, resetSearchData]
  );

  return (
    <div className={style.titleSearchField}>
      {searchResult?.error ? (
        <ErrorToast message={searchResult?.error} />
      ) : null}
      <Search
        labelText="Search"
        placeholder="Search"
        size="lg"
        autoFocus
        value={searchDisplayText}
        onChange={({ target: { value } }) => {
          setSearchDisplayText(value);
          debouncedSetSearchPayload(value);
        }}
      />
    </div>
  );
};

const TitleTypeFilter = ({
  searchAtoms,
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const { searchAtom } = searchAtoms;
  const [search] = useAtom(searchAtom);
  const [isTable, setIsTable] = useAtom(isTableViewAtom);

  const totalTitle = search.data ? (
    `${format.number(search.data.totalCount || 0)} ${plur(
      'title workspace',
      search.data.totalCount
    )}`
  ) : search.loading ? (
    <SkeletonPlaceholder className={style.placeholderSkeletonItem} />
  ) : null;
  return (
    <>
      <h2 className={style.totalTitle}>{totalTitle}</h2>
      <div className={style.actionsArea}>
        <TitleSearchField searchAtoms={searchAtoms} />
        <ContentSwitcher
          className={style.tableGridSwitcher}
          selectedIndex={isTable ? 0 : 1}
        >
          <Button
            hasIconOnly
            size="md"
            kind={isTable ? 'secondary' : 'ghost'}
            iconDescription="Table View"
            renderIcon={Table16}
            onClick={() => setIsTable(true)}
          />
          <Button
            hasIconOnly
            size="md"
            kind={!isTable ? 'secondary' : 'ghost'}
            iconDescription="Grid View"
            renderIcon={Grid16}
            onClick={() => setIsTable(false)}
          />
        </ContentSwitcher>
      </div>
    </>
  );
};

const createSearchAtom = (pageSizePreference: number) =>
  searchAtomPair<TitleWorkspaceResponse>('titleWorkspaces', {
    text: '',
    page: 1,
    pageSize: pageSizePreference,
  });

const TitleWorkspaceList = ({
  searchAtoms: { searchAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const setPageSize = useSetAtom(pageSizeAtom);

  return (
    <>
      <CardsGrid<TitleWorkspaceResponse>
        searchResponse={searchResult.data}
        loading={searchResult.loading}
        itemName="title workspace"
        onChange={(newSearch) => {
          if (newSearch.pageSize) {
            setPageSize(newSearch.pageSize);
          }
          setSearchPayload((current) => ({ ...current, ...newSearch }));
        }}
        actions={null}
        renderCard={(titleWorkspace) => (
          <SearchResultCard<TitleWorkspaceResponse>
            Icon={Workspace}
            item={titleWorkspace}
            cardContent={
              <>
                {titleWorkspace.createdBy && (
                  <dl>
                    <dt className={style.label}>Created By</dt>
                    <dd className={style.content}>
                      {titleWorkspace.createdBy}
                    </dd>
                  </dl>
                )}
                {titleWorkspace.createdAt && (
                  <dl>
                    <dt className={style.label}>Created At</dt>
                    <dd className={style.content}>
                      <Date date={titleWorkspace.createdAt} />
                    </dd>
                  </dl>
                )}

                <dl>
                  <dt className={style.label}>Last Calculated</dt>
                  <dd className={style.content}>
                    {titleWorkspace.lastCalculated ? (
                      <Date date={titleWorkspace.lastCalculated} />
                    ) : (
                      '--'
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt className={style.label}>Owners</dt>
                  <dd className={style.content}>
                    {titleWorkspace.numberOfOwners ?? '--'}
                  </dd>
                </dl>
                <dl>
                  <dt className={style.label}>Subtracts</dt>
                  <dd className={style.content}>
                    {titleWorkspace.numberOfTracts ?? '--'}
                  </dd>
                </dl>
                <dl>
                  <dt className={style.label}>Severences</dt>
                  <dd className={style.content}>
                    {titleWorkspace.numberOfDepths ?? '--'}
                  </dd>
                </dl>
                {titleWorkspace.startingInterestDate && (
                  <dl>
                    <dt className={style.label}>Starting Interest</dt>
                    <dd className={style.content}>
                      <Date date={titleWorkspace.startingInterestDate} />
                    </dd>
                  </dl>
                )}
                {titleWorkspace.legalAcreage && (
                  <dl>
                    <dt className={style.label}>Legal Acreage</dt>
                    <dd className={style.content}>
                      {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                        +titleWorkspace.legalAcreage
                      )}
                    </dd>
                  </dl>
                )}
                {titleWorkspace.referenceLocation.name && (
                  <dl>
                    <dt className={style.label}>Survey Area</dt>
                    <dd className={style.content}>
                      {titleWorkspace.referenceLocation.name}
                    </dd>
                  </dl>
                )}
              </>
            }
          />
        )}
        renderEmptyState={
          <EmptyState
            IconComponent={Workspace}
            headerText="No title workspaces available"
            helperText="Currently you don't have any title workspaces."
          />
        }
      />
    </>
  );
};

const TitleWorkspaceTable = ({
  searchAtoms: { searchAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const setPageSize = useSetAtom(pageSizeAtom);
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const searchParams = searchResults.currentPayload;
  const pageSize = searchResults?.data?.pageSize || DEFAULT_PAGE_SIZE;

  if (!searchResults.loading && !searchResults.data?.results.length) {
    return (
      <Tile>
        <EmptyState
          IconComponent={Workspace}
          headerText="No title workspaces available"
          helperText="Currently you don't have any title workspaces."
        />
      </Tile>
    );
  }

  return (
    <PaginatedTable
      pageSizes={[16, 24, 48, DEFAULT_PAGE_SIZE]}
      pageSize={pageSize}
      className={style.titleWorkspacesTable}
      page={searchParams?.page || 1}
      totalItems={searchResults?.data?.totalCount || 0}
      onPaginate={({ page, pageSize }) => {
        setPageSize(pageSize);
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              page,
              pageSize,
            }
        );
      }}
    >
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Created By</TableHeader>
          <TableHeader>Created At</TableHeader>
          <TableHeader>Last Calculated</TableHeader>
          <TableHeader>Owners</TableHeader>
          <TableHeader>Tracts</TableHeader>
          <TableHeader>Depth Severences</TableHeader>
          <TableHeader>Starting Interest</TableHeader>
          <TableHeader>Legal Acreage</TableHeader>
          <TableHeader>Survey Area</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults.loading ? (
          <SkeletonTableRows rows={pageSize} columns={10} />
        ) : searchResults ? (
          searchResults.data?.results.map((el) => {
            return (
              <TableRow key={el.id}>
                <TableCell>
                  {el.href ? (
                    <>
                      <Link to={el.href} target="_blank">
                        {el.name}
                      </Link>
                    </>
                  ) : (
                    el.name
                  )}
                </TableCell>
                <TableCell>{el.createdBy}</TableCell>
                <TableCell>
                  <Date date={el.createdAt} />
                </TableCell>
                <TableCell>
                  {el.lastCalculated ? <Date date={el.lastCalculated} /> : '--'}
                </TableCell>
                <TableCell>{el.numberOfOwners ?? '--'}</TableCell>
                <TableCell>{el.numberOfTracts ?? '--'}</TableCell>
                <TableCell>{el.numberOfDepths ?? '--'}</TableCell>
                <TableCell>
                  <Date date={el.startingInterestDate} />
                </TableCell>
                {el.legalAcreage ? (
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(+el.legalAcreage)}
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}
                <TableCell>{el.referenceLocation.name}</TableCell>
              </TableRow>
            );
          })
        ) : null}
      </TableBody>
    </PaginatedTable>
  );
};

export default TitleWorkspaces;
