import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import style from './definition-list.module.scss';

export interface DefinitionListProps extends HTMLAttributes<HTMLDListElement> {
  inverted?: boolean;
}

const DefinitionList = ({
  className,
  inverted = false,
  ...rest
}: DefinitionListProps) => {
  className = classNames(className, { [style.inverted]: inverted });
  return <dl className={className} {...rest} />;
};

export { DefinitionList };
