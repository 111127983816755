import { ChevronUp16, ChevronDown16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { TransactionFormAtoms } from 'components/interpretation-form/transaction/types';
import { FC } from 'react';
import style from './transaction-header.module.scss';
import { useAtomValue } from 'jotai';

interface Props {
  atoms: TransactionFormAtoms;
  legendTitle: string;
  relativePosition: number;
  totalTransactions: number;
  reorder: (direction: 'up' | 'down') => void;
}

const TransactionHeader: FC<Props> = ({
  atoms,
  legendTitle,
  reorder,
  relativePosition,
  totalTransactions,
}) => {
  const markedForDestruction = useAtomValue(atoms.status) === 'trash';
  return (
    <>
      <legend className={style.legendTitle}>{legendTitle}</legend>
      <div className={style.arrowContainer}>
        <div
          className={`${style.arrowSelectors} ${
            markedForDestruction && style.disabled
          }`}
        >
          <Button
            onClick={() => reorder('up')}
            kind="ghost"
            size="sm"
            renderIcon={ChevronUp16}
            iconDescription="Move up"
            disabled={markedForDestruction}
            hasIconOnly
          />
          <Button
            onClick={() => reorder('down')}
            kind="ghost"
            size="sm"
            renderIcon={ChevronDown16}
            iconDescription="Move down"
            hidden={markedForDestruction}
            disabled={markedForDestruction}
            hasIconOnly
          />
        </div>
        <div
          className={style.totalIndicator}
        >{`${relativePosition} of ${totalTransactions}`}</div>
      </div>
    </>
  );
};

export { TransactionHeader };
