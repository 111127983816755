import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  DataTableCustomRenderProps,
} from 'carbon-components-react';
import { Link } from 'components/link';
import style from './title_workspace.module.scss';
import { MineralLeaseOverrideRow } from 'types/api-responses';
import { Intersect } from 'types/utils';
import { LinkWithHover } from 'components/hover-card-resource';

const headers = [
  {
    key: 'from',
    header: 'From',
  },
  {
    key: 'to',
    header: 'To',
  },
  {
    key: 'lease',
    header: 'Lease',
  },
  {
    key: 'amount',
    header: 'Overriding Interest',
  },
];

export interface MineralLeaseOverrideRowProps {
  rows: MineralLeaseOverrideRow[] | undefined;
}

export function Overrides({ rows }: MineralLeaseOverrideRowProps) {
  const augmentedRows = rows?.map<
    Intersect<
      MineralLeaseOverrideRow,
      {
        id: string;
        from: string;
        fromHref: string;
        to: string;
        toHref: string;
        leaseHref: string;
      }
    >
  >((row, index) => ({
    ...row,
    id: String(index),
    from: `${row.from} (${row.fromId})`,
    to: `${row.to} (${row.toId})`,
  }));

  return augmentedRows?.length ? (
    <DataTable isSortable rows={augmentedRows} headers={headers} size="sm">
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
      }: DataTableCustomRenderProps) => (
        <TableContainer className={style.table}>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  return (
                    <TableHeader
                      {...getHeaderProps({ header })}
                      key={header.key}
                    >
                      {header.header}
                    </TableHeader>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow {...getRowProps({ row })} key={row.id}>
                    {row.cells.map((cell, cellIndex) => {
                      const cellKey =
                        cell.id + '_' + rowIndex + '_' + cellIndex;
                      const str = cell.id;
                      const augmentedRowIndex = Number(str.replace(/\D/g, ''));

                      if (cellIndex === 0) {
                        return (
                          <TableCell key={cellKey}>
                            <LinkWithHover
                              kind="entity"
                              href={`${augmentedRows[augmentedRowIndex].fromHref}`}
                            >
                              <Link
                                to={`${augmentedRows[augmentedRowIndex].fromHref}`}
                                target={'_blank'}
                                rel="noopener noreferrer"
                              >
                                {cell.value}
                              </Link>
                            </LinkWithHover>
                          </TableCell>
                        );
                      }
                      if (cellIndex === 1) {
                        return (
                          <TableCell key={cellKey}>
                            <LinkWithHover
                              kind="entity"
                              href={`${augmentedRows[augmentedRowIndex].toHref}`}
                            >
                              <Link
                                to={`${augmentedRows[augmentedRowIndex].toHref}`}
                                target={'_blank'}
                                rel="noopener noreferrer"
                              >
                                {cell.value}
                              </Link>
                            </LinkWithHover>
                          </TableCell>
                        );
                      }
                      if (cellIndex === 2) {
                        return (
                          <TableCell key={cellKey}>
                            <LinkWithHover
                              kind="lease"
                              href={`${augmentedRows[augmentedRowIndex].leaseHref}`}
                            >
                              <Link
                                to={`${augmentedRows[augmentedRowIndex].leaseHref}`}
                                target={'_blank'}
                                rel="noopener noreferrer"
                              >
                                {cell.value}
                              </Link>
                            </LinkWithHover>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cellKey}>{cell.value}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  ) : null;
}
