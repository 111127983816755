import { atom } from 'jotai';
import { TitleCalculationResponse } from 'types/api-responses';
import { resetAssetStateAtom } from './by-asset/atoms';
import { resetOwnerStateAtom } from './by-owner/atoms';
import { createResourceCacheMapAtom } from 'lib/atoms';

const calculationsMapAtom =
  createResourceCacheMapAtom<TitleCalculationResponse>();
const lastCalculationUriAtom = atom<string | null>(null);
const lastCalculationAtom = atom((get) => {
  const calculationMap = get(calculationsMapAtom);
  const lastCalculationHref = get(lastCalculationUriAtom);
  if (!lastCalculationHref) return undefined;

  return calculationMap[lastCalculationHref];
});

const selectedOutputAtom = atom<'assets' | 'owners'>('assets');
const calculationDateAtom = atom<string | null>(null);

const resetCalculationCacheAtom = atom(
  () => null,
  (get, set) => {
    set(calculationsMapAtom, { resetCache: true });
    set(resetAssetStateAtom);
    set(resetOwnerStateAtom);
    set(selectedOutputAtom, 'assets');
  }
);

export {
  calculationsMapAtom,
  lastCalculationAtom,
  lastCalculationUriAtom,
  resetCalculationCacheAtom,
  selectedOutputAtom,
  calculationDateAtom,
};
