import { Table, TableContainer, TableProps } from 'carbon-components-react';
import { Pagination } from 'components/pagination';
import {
  itemRangeText,
  pageRangeText,
} from 'components/search/common/format-helpers';
import { FC, ReactNode } from 'react';

interface Props extends TableProps {
  pageSize: number;
  page: number;
  totalItems: number;
  pageSizes?: number[];
  onPaginate: ({ page, pageSize }: { page: number; pageSize: number }) => void;
  toolbar?: ReactNode;
  paginatorOutsideTableContainer?: boolean;
}

const PaginatedTable: FC<Props> = ({
  children,
  pageSize,
  pageSizes,
  page,
  totalItems,
  onPaginate,
  toolbar,
  paginatorOutsideTableContainer,
  ...rest
}) => {
  const handlePageChange = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    window.scrollTo(0, 0);
    onPaginate({ page, pageSize });
  };

  const paginator = (
    <Pagination
      pageSizes={pageSizes ?? [16, 24, 48, 64]}
      pageSize={pageSize}
      page={page}
      totalItems={totalItems}
      onChange={handlePageChange}
      itemRangeText={itemRangeText}
      pageRangeText={pageRangeText}
    />
  );
  return (
    <>
      <TableContainer>
        {toolbar}
        <Table {...rest}>{children}</Table>
        {!paginatorOutsideTableContainer ? paginator : null}
      </TableContainer>
      {paginatorOutsideTableContainer ? paginator : null}
    </>
  );
};

export { PaginatedTable };
