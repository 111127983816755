import { atom, Getter, Setter } from 'jotai';
import {
  LeaseExtensionSurveySubform,
  LeaseManagementSubform,
  MineralLeaseTransactionForm,
} from '../types';

import {
  createResourceFormAtom,
  FormErrors,
  FormFieldState,
  FormRowStatus,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { LeaseExtension } from 'types/api-responses';
import { extensionsAreaValidationAtom } from './atoms';
import { formAtoms } from 'components/interpretation-form/atoms';
import { MineralLeaseAtoms } from '../lease-transaction';
import { ApplicableAreaForm } from 'components/interpretation-form/applicable-area/types';
import { LeaseExtensionValidationPayload } from './types';

export type LeaseManagementSubformAtoms = ReturnType<
  typeof leaseManagementSubform
>;

export type LeaseExtensionSurveySubformAtoms = ReturnType<
  typeof leaseExtensionSurveySubform
>;

const buildLocationReferenceValidationPayload = (
  applicableArea: ApplicableAreaForm
) => {
  if (!applicableArea.referenceLocation.value) return;

  return {
    locationReferenceLocationId:
      applicableArea.referenceLocation.value.referenceLocation.id,
    startDepthInFeet: applicableArea.startDepthInFeet.value,
    endDepthInFeet: applicableArea.endDepthInFeet.value,
    locationQuarterCalls: applicableArea.referenceLocation.value?.quarterCalls,
  };
};

const buildMineralLeaseTransactionValidationPayload = (
  form: MineralLeaseTransactionForm,
  get: Getter
): {
  type: 'Transaction::MineralLeaseTransaction';
  leaseExtensionsAttributes: LeaseExtensionValidationPayload;
} => {
  const leaseManagementForm = get(form.leaseManagement.value.formAtom);

  return {
    type: 'Transaction::MineralLeaseTransaction',
    leaseExtensionsAttributes: buildLeaseExtensionValidationPayload(
      leaseManagementForm,
      get
    ),
  };
};

const buildLeaseExtensionValidationPayload = (
  form: LeaseManagementSubform,
  get: Getter
) => {
  const isPartialLand = form.retainedLand.value === 'partial';

  const leases = get(form.locationReferences.value).map((el) => {
    const status = get(el.status);
    const row = get(el.row.formAtom);

    switch (status) {
      case 'new':
      case 'duplicated':
        if (!isPartialLand) {
          return undefined;
        }
        if (row.referenceLocation.value) {
          return {
            referenceLocationId: row.referenceLocation.value.id,
            extensionReason: form.leaseExtension.value,
            quarterCalls: row.quarterCalls.value,
            startDepthInFeet: row.startDepthInFeet.value,
            endDepthInFeet: row.endDepthInFeet.value,
          };
        }
        break;

      case 'saved':
        if (!isPartialLand && row.referenceLocation.value) {
          return undefined;
        }
        if (isPartialLand && row.referenceLocation.value) {
          return {
            referenceLocationId: row.referenceLocation.value.id,
            extensionReason: form.leaseExtension.value,
            quarterCalls: row.quarterCalls.value,
            startDepthInFeet: row.startDepthInFeet.value,
            endDepthInFeet: row.endDepthInFeet.value,
          };
        }
        break;

      case 'trash':
        return undefined;

      default:
        return undefined;
    }
  });

  return leases.flatMap((el) => (el ? [el] : []));
};

const validateSurveyAreas = ({
  leaseManagementAtoms,
  transactionAtoms,
  validationUrl,
  onSuccess,
  onError,
  get,
  set,
}: {
  leaseManagementAtoms: LeaseManagementSubformAtoms;
  transactionAtoms: MineralLeaseAtoms;
  validationUrl: string | undefined;
  onSuccess: () => void;
  onError?: () => void;
  get: Getter;
  set: Setter;
}) => {
  const transactionForm = get(transactionAtoms.formAtom);
  const parentFormFields = get(get(formAtoms).formAtom);

  if (!validationUrl) return;
  set(extensionsAreaValidationAtom, {
    url: validationUrl,
    type: 'POST',
    data: {
      transactionsAttributes: [
        buildMineralLeaseTransactionValidationPayload(transactionForm, get),
      ],
      locationReferencesAttributes: parentFormFields.locationReferences.value
        .map((el) =>
          buildLocationReferenceValidationPayload(get(el.row.formAtom))
        )
        .flatMap((el) => (el ? [el] : [])),
    },
    onError: (e) => {
      if (e?.errors['transactions.leaseExtensions.base']) {
        set(leaseManagementAtoms.dirtyFieldsAtom, {
          errors: {
            locationReferences:
              'The lease selection cannot be outside of the selected survey area',
          },
          skipAccumulation: true,
        });
        onError?.();
      } else {
        set(leaseManagementAtoms.dirtyFieldsAtom, {
          errors: {
            locationReferences: undefined,
          },
        });
        onSuccess();
      }
    },
  });
};

const validateLeaseManagement = (
  atoms: LeaseManagementSubformAtoms,
  get: Getter,
  set: Setter
) => {
  const form = get(atoms.formAtom);
  const areas = get(form.locationReferences.value);
  let hasError = false;
  areas.map((el) => {
    const fields = get(el.row.formAtom);
    if (!fields.referenceLocation.value) {
      set(el.row.dirtyFieldsAtom, {
        errors: { referenceLocation: 'Survey area cannot be blank' },
      });
      hasError = true;
    }
  });

  if (!form.leaseExtension.value) {
    set(atoms.dirtyFieldsAtom, {
      errors: { leaseExtension: 'Lease extension reason cannot be blank' },
    });
    hasError = true;
  }
  return hasError;
};

const leaseManagementSubform = (defaultValue: LeaseManagementSubform) => {
  return createResourceFormAtom<
    LeaseManagementSubform,
    FormErrors<LeaseManagementSubform>
  >(defaultValue);
};

export const leaseExtensionSurveySubform = (
  defaultValue: LeaseExtensionSurveySubform,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      LeaseExtensionSurveySubform,
      FormErrors<LeaseExtensionSurveySubform>
    >(defaultValue),
  };
};

type FormInitialValues = {
  [P in keyof LeaseExtensionSurveySubform]: LeaseExtensionSurveySubform[P]['value'];
};
export const leaseExtensionFormDefault = (
  {
    id,
    referenceLocation,
    quarterCalls,
    startDepthInFeet,
    endDepthInFeet,
  }: FormInitialValues,
  state: FormFieldState
) => {
  return initializeForm<LeaseExtensionSurveySubform>(
    {
      id,
      referenceLocation,
      quarterCalls,
      startDepthInFeet,
      endDepthInFeet,
    },
    state
  );
};

const initLeaseExtensionSurveySubform = (
  extensions: LeaseExtension[] | undefined,
  state: FormFieldState
) => {
  if (!extensions) {
    return [];
  }
  return extensions.map((e) =>
    leaseExtensionSurveySubform(
      leaseExtensionFormDefault(
        {
          id: e.id,
          referenceLocation: e.referenceLocation,
          endDepthInFeet: e.endDepthInFeet,
          startDepthInFeet: e.startDepthInFeet,
          quarterCalls: e.quarterCalls,
        },
        state
      ),
      rowStatus(state)
    )
  );
};

const initLeaseExtensionSurveySubformEmpty = () => {
  return leaseExtensionSurveySubform(
    leaseExtensionFormDefault(
      {
        id: undefined,
        referenceLocation: null,
        endDepthInFeet: null,
        startDepthInFeet: null,
        quarterCalls: [],
      },
      'stable'
    ),
    'new'
  );
};

export {
  initLeaseExtensionSurveySubform,
  initLeaseExtensionSurveySubformEmpty,
  leaseManagementSubform,
  validateLeaseManagement,
  validateSurveyAreas,
};
