import { AddDestroyProp, atomField, required } from 'atoms/form-atoms';
import {
  AtomizedTitleWorkspaceSettingsForm,
  TitleWorkspaceSettingsForm,
} from './types';
import { TitleWorkspaceResponse } from 'types/api-responses';
import { FormRowStatus } from 'atoms/create-resource-atom';

const titleWorkspacesSettingsForm = (
  titleWorkspace: TitleWorkspaceResponse
): AtomizedTitleWorkspaceSettingsForm => {
  return {
    name: atomField(titleWorkspace.name, {
      validate: (v) => required(v),
    }),
    legalAcreage: atomField(titleWorkspace.legalAcreage || ''),
    startingInterestDate: atomField(titleWorkspace.startingInterestDate || '', {
      validate: (v) => required(v),
    }),
    startingInterestEntriesAttributes: atomField(
      titleWorkspace.startingInterest.length
        ? titleWorkspace.startingInterest.map(entityRow)
        : [entityRow()]
    ),
  };
};

const entityRow = (entity?: TitleWorkspaceResponse['startingInterest'][0]) => {
  return {
    id: atomField(entity?.id),
    mineral: atomField(entity?.mineral || ''),
    surface: atomField(entity?.surface || ''),
    entity: atomField(entity?.entity, {
      validate: (v) => required(v),
    }),
    _rowStatus: atomField<FormRowStatus>(entity ? 'saved' : 'new'),
  };
};

const getPayload = (form: AddDestroyProp<TitleWorkspaceSettingsForm>) => {
  const interestEntries = (form.startingInterestEntriesAttributes || []).map(
    (el) => {
      const { entity, ...rest } = el;
      return {
        ...rest,
        entityId: entity?.id,
      };
    }
  );

  return { ...form, startingInterestEntriesAttributes: interestEntries };
};

export { titleWorkspacesSettingsForm, entityRow, getPayload };
