import { useMemo } from 'react';
import { atom } from 'jotai';
import { DocumentResponse } from 'types/api-responses';
import { documentEditForm } from './utils';
import {
  CreateResourceAtom,
  createMutationAtom,
} from 'atoms/create-resource-atom';
import { AtomizedDocumentEditForm } from './types';
import { getFormValuesAtom } from 'atoms/form-atoms';

export const documentAtom = atom<DocumentResponse | undefined>(undefined);

export const useDocumentEditFormAtom = (document: DocumentResponse) => {
  return useMemo(() => atom(documentEditForm(document)), [document]);
};

const updateDocumentAtom = createMutationAtom<DocumentResponse, 'document'>();

const createRunDocumentUpdateAtom = (
  href: string,
  form: AtomizedDocumentEditForm
) => {
  return atom<
    CreateResourceAtom<DocumentResponse, unknown>,
    [() => void],
    void
  >(
    (get) => get(updateDocumentAtom),
    (get, set, onSuccess) => {
      const formValues = getFormValuesAtom(atom(form), 'dirty-values');
      if (!document) {
        return;
      }

      set(updateDocumentAtom, {
        url: href,
        type: 'PATCH',
        data: get(formValues),
        onSuccess: () => {
          onSuccess();
        },
      });
    }
  );
};

export const useRunDocumentUpdateAtom = (
  href: string,
  form: AtomizedDocumentEditForm
) => {
  return useMemo(() => {
    return createRunDocumentUpdateAtom(href, form);
  }, [href, form]);
};
