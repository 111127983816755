import { useEffect, useState, useRef, useReducer } from 'react';
import { clearSelection, searchDiagram, updateLayout } from './gojs_graph';
import { Loading, Button, Tile, Dropdown } from 'carbon-components-react';
import GoJSViewer from './gojs_viewer';
import NoFlowchartSvg from 'images/srp-illustration-flowchart.svg';
import { Close16, Restart16, Search16 } from '@carbon/icons-react';
import prepareGraph from './title-sub-graph';

import type { Edge, Vertex } from './types';

import style from './chain-viewer.module.scss';
import { EmptyState } from 'components/empty-state';

interface Props {
  url: string;
  onClose: () => void;
  party?: string;
  tractName: string;
  depthRangeName?: string;
  isSurface?: boolean;
}

const ChainViewer = ({
  url,
  onClose,
  party,
  tractName,
  depthRangeName,
  isSurface,
}: Props) => {
  const [tree, setTree] = useState<{ vertices: Vertex[]; edges: Edge[] }>();
  const [loading, toggleLoading] = useReducer((s) => !s, true);
  const [error, toggleError] = useReducer((s) => !s, false);
  const searchInput = useRef<HTMLInputElement>(null);
  const [terminalParties, setTerminalParties] =
    useState<{ id: number; name: number }[]>();
  const [currentParty, setCurrentParty] = useState<{
    id: number;
    name: number;
  }>();
  const getSubgraphRef = useRef<any>(null);
  const rootVertex = useRef<number | null>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Unable to load JSON');
      })
      .then((debuggingGraph) => {
        if (party) {
          const { getSubgraph } = prepareGraph(
            debuggingGraph.vertices,
            debuggingGraph.edges
          );

          getSubgraphRef.current = getSubgraph;
          rootVertex.current = debuggingGraph.vertices[0].name;

          const terminalPartyNodes = debuggingGraph.vertices
            .filter(
              (v: Vertex) => v.color !== 'gray' && v.label.includes(party)
            )
            .map((el: Vertex, i: number) => ({ id: i + 1, name: el.name }));

          setTerminalParties(terminalPartyNodes);
          setCurrentParty(terminalPartyNodes[0]);

          if (rootVertex.current) {
            setTree(
              getSubgraph(rootVertex.current, terminalPartyNodes[0].name)
            );
          }
        } else {
          setTree(debuggingGraph);
        }
        toggleLoading();
      })
      .catch(() => {
        toggleLoading();
        toggleError();
      });
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [party, url]);

  return (
    <div className={style.box}>
      <div className={style.mainContainer}>
        <div className={style.actions}>
          <div className={style.searchField}>
            <input
              id="search-in-graph"
              type="text"
              placeholder="Search for entities inside the tree"
              ref={searchInput}
              className={style.searchInput}
              onKeyDown={(ev) =>
                ev.code === 'Enter' && searchDiagram(searchInput.current?.value)
              }
            />
            <Button
              kind="primary"
              renderIcon={Search16}
              iconDescription="Search"
              tooltipAlignment="center"
              tooltipPosition="bottom"
              size="md"
              hasIconOnly
              onClick={() => searchDiagram(searchInput.current?.value)}
            />
            <Button
              kind="ghost"
              renderIcon={Restart16}
              size="sm"
              onClick={() => clearSelection()}
            >
              <span>Clear Vertex Selection</span>
            </Button>
            <Dropdown
              id="layerSpacing"
              placeholder="Node spacing"
              label="Nodes spacing"
              items={['250', '500', '750', '1000']}
              defaultValue={'250'}
              onChange={(item) => updateLayout(item.selectedItem)}
              style={{ width: '300px' }}
            ></Dropdown>
            {terminalParties && terminalParties?.length > 1 ? (
              <Dropdown<{ id: number; name: number }>
                id="chainForParty"
                label={`Chains for ${party}`}
                items={terminalParties}
                itemToString={(el) => `Chain ${el.id} - ${party}`}
                selectedItem={currentParty}
                onChange={(item) => {
                  if (item.selectedItem) {
                    setCurrentParty(item.selectedItem);
                    setTree(
                      getSubgraphRef.current(
                        rootVertex.current,
                        item.selectedItem.name
                      )
                    );
                  }
                }}
                style={{ width: '300px' }}
              ></Dropdown>
            ) : null}
          </div>
          <div className={style.graphActions}>
            <div className={style.graphTitle}>
              <span className={style.tractName}>{tractName}</span>
              <br />
              {party &&
                terminalParties &&
                terminalParties?.length === 1 &&
                `${party} · `}
              {isSurface ? 'Surface' : `${depthRangeName}`}
            </div>
            <Button
              kind="ghost"
              hasIconOnly
              size="md"
              renderIcon={Close16}
              iconDescription="Close"
              tooltipAlignment="center"
              tooltipPosition="left"
              onClick={() => onClose()}
            />
          </div>
        </div>
        {loading ? (
          <>
            <Loading small />
          </>
        ) : tree ? (
          <GoJSViewer graph={tree} />
        ) : (
          <Tile>
            <EmptyState
              icon={NoFlowchartSvg}
              headerText={
                error ? 'There was an error' : 'No Flowchart Available'
              }
              helperText={
                error
                  ? 'There was an error opening the graph.'
                  : 'There are no Flowchart Available. Try Calculating the workspace again.'
              }
            />
          </Tile>
        )}
      </div>
    </div>
  );
};

export default ChainViewer;
