import { ChartColumn16, ChartPie16 } from '@carbon/icons-react';
import { CHART_VARIANTS, ChartTypes } from '../types';
import { Button } from 'carbon-components-react';
import { FC } from 'react';

export interface ChartTypeSelectionProps {
  setChartType: (chartType: ChartTypes) => void;
}

const ChartTypeSelection: FC<ChartTypeSelectionProps> = ({ setChartType }) => {
  return (
    <div>
      <Button
        hasIconOnly
        iconDescription="Column Chart"
        size="small"
        renderIcon={ChartColumn16}
        kind="ghost"
        onClick={() => setChartType(CHART_VARIANTS.column)}
      ></Button>
      <Button
        hasIconOnly
        iconDescription="Pie Chart"
        size="small"
        renderIcon={ChartPie16}
        kind="ghost"
        onClick={() => setChartType(CHART_VARIANTS.pie)}
      ></Button>
    </div>
  );
};

export { ChartTypeSelection };
