import {
  Column,
  Grid,
  InlineNotification,
  Row,
  SkeletonPlaceholder,
  Tile,
} from 'carbon-components-react';
import { useAtomValue } from 'jotai';
import { useWellFeaturesAtom } from './atoms';
import { Section } from 'components/section';
import classNames from 'classnames';
import { Map, Well } from 'components/map';
import { DateField, Field } from './field';

import wellStatusTypes from 'enums/well_status_types.json';
import { FormationAndDepth } from './formation-and-depth';
import { CumulativeProduction } from 'components/comulative-production';
import { Production } from './production';
import { SupportingFiles } from './supporting-files';
import NoMapSvg from 'images/srp-illustration-no-map.svg';
import style from './well-page-content.module.scss';
import { EmptyState } from 'components/empty-state';
import { getMapDefaultConfig } from 'components/map/atoms';
import { atomWithStorage } from 'jotai/utils';
import { WellsResponse } from 'types/api-responses';
import { ProvideRootResource } from 'components/hydrate-atoms';

const WellPageContent = ({
  well,
  hideMap,
}: {
  well: WellsResponse;
  hideMap?: boolean;
}) => {
  const gridProps = hideMap ? {} : { sm: 8, lg: 5 };
  return (
    <Tile className={style.tileNoPadding}>
      <Grid
        fullWidth
        condensed
        className={classNames(['bx--no-gutter', style.rowSeparator])}
      >
        <Row>
          <Column {...gridProps}>
            <Section header="Well Details">
              <Tile>
                <div className={style.dates}>
                  <DateField label="Permit Date" date={well.permitDate} />
                  <DateField label="Spud Date" date={well.spudDate} />
                  <DateField
                    label="Rig Release Date"
                    date={well.rigReleaseDate}
                  />
                  <DateField
                    label="Completion Date"
                    date={well.completionDate}
                  />
                  <DateField
                    label="First Production Date"
                    date={well.firstProductionDate}
                  />
                  <DateField
                    label="Last Production Date"
                    date={well.lastProductionDate}
                  />
                </div>
              </Tile>
            </Section>
          </Column>
          {!hideMap && (
            <Column sm={8} lg={11}>
              <ProvideRootResource>
                <WellMap featuresHref={well.resources.features.href} />
              </ProvideRootResource>
            </Column>
          )}
        </Row>
      </Grid>
      <Section header="Formation & Depth" className={style.rowSeparator}>
        <Tile>
          <FormationAndDepth well={well} />
        </Tile>
      </Section>
      <Section header="Cumulative Production" className={style.rowSeparator}>
        <Tile>
          <CumulativeProduction
            production={{
              totalLiquid: well.totalLiquid,
              totalGas: well.totalGas,
              totalWater: well.totalWater,
            }}
            monthsFromFirstToLastProduction={
              well.monthsFromFirstToLastProduction
            }
            monthsReported={well.monthsReported}
            cumulativeHorizontalPos
          />
        </Tile>
      </Section>
      <Section header="Well Analysis" className={style.rowSeparator}>
        <Tile>
          <Production
            productionForecastHref={well.resources.productionForecast.href}
            productionRecordHref={well.resources.productionRecord.href}
          />
        </Tile>
      </Section>
      <Section header="Supporting files">
        <Tile>
          <SupportingFiles href={well.resources.supportingFiles.href} />
        </Tile>
      </Section>
    </Tile>
  );
};

const mapConfigAtom = atomWithStorage(
  'insights-wells-show-map-config',
  getMapDefaultConfig({
    wells: {
      checked: true,
      options: {
        surfaceHole: true,
        bottomHole: true,
        stick: true,
        survey: true,
        vertical: true,
      },
    },
  })
);

const WellMap = ({ featuresHref }: { featuresHref: string }) => {
  const wellFeaturesAtom = useWellFeaturesAtom(featuresHref);
  const features = useAtomValue(wellFeaturesAtom);
  if (features.loading) return <SkeletonPlaceholder className={style.map} />;
  if (features.data && 'error' in features.data)
    return (
      <InlineNotification
        kind="error"
        title={features.data.error}
        lowContrast
      />
    );

  let latitude = 0;
  let longitude = 0;
  if (features.data?.surfaceHole) {
    [latitude, longitude] = features.data.surfaceHole.coordinates;
  } else {
    return (
      <EmptyState
        icon={NoMapSvg}
        iconClassName={style.emptyMapIcon}
        headerText="No Data Available"
        helperText="Currently Insights doesn’t have enough data to map this well."
      />
    );
  }

  return (
    <Map
      latitude={longitude}
      longitude={latitude}
      showControls
      showMapFilters
      className={style.wellsMap}
      disableWellsLayer
      mapConfigAtom={mapConfigAtom}
    >
      <Well id="well" features={features.data} mapConfigAtom={mapConfigAtom} />
    </Map>
  );
};

const WellHeaderContent = ({ well }: { well: WellsResponse }) => {
  return (
    <div className={style.detailsFields}>
      <div>
        <Field label="SRP Well Name" value={well.name} />
        <Field label="Current Operator" value={well.currentOperator} />
      </div>
      <div>
        <Field label="Hole Direction" value={well.holeDirection} />
        <Field
          label="SRP Well Status"
          value={wellStatusTypes[well.status as keyof typeof wellStatusTypes]}
        />
      </div>
    </div>
  );
};

export { WellPageContent, WellHeaderContent };
