import { EventResponse } from 'types/api-responses';
import {
  Bullhorn,
  TrashCan,
  Comment,
  SupportingFile,
  Accepted,
  Rejected,
  Undo,
} from 'components/icons';
import { Date } from 'components/date';

import style from './event.module.scss';

const Event = ({ item }: { item: EventResponse }) => {
  let Icon: React.FunctionComponent<{ className: string }>;
  const kind = item.data.kind;
  if (kind === 'create_comment') {
    Icon = Comment;
  } else if (kind === 'create_supporting_file') {
    Icon = SupportingFile;
  } else if (kind === 'destroy_supporting_file') {
    Icon = TrashCan;
  } else if (kind === 'completion_correct') {
    Icon = Accepted;
  } else if (kind === 'completion_incorrect') {
    Icon = Rejected;
  } else if (kind === 'completion_reverted') {
    Icon = Undo;
  } else {
    Icon = Bullhorn;
  }
  return (
    <li className={style.event}>
      <div className={style.header}>
        <Icon className={style.icon} />
        <div>
          <h6>{item.createdBy}</h6>
          <Date
            className={style.date}
            date={item.createdAt}
            format={'lll'}
            showTime
          />
        </div>
      </div>

      <div className={style.content}>
        <EventContent item={item.data} />
      </div>
    </li>
  );
};

function EventContent({ item }: { item: EventResponse['data'] }) {
  if (item.kind === 'completion_incorrect') {
    return (
      <div>
        <p>Status Updated: Incorrect</p>
        <p>Reason: {item.content.reason}</p>
        {item.content.details && <p>Details: {item.content.details}</p>}
      </div>
    );
  }

  if (item.kind === 'completion_correct') {
    return (
      <div>
        <p>Status Updated: Correct</p>
      </div>
    );
  }

  if (item.kind === 'completion_reverted') {
    return (
      <div>
        <p>Status Updated: Reverted</p>
      </div>
    );
  }

  if (typeof item.content === 'string') {
    return <div>{item.content}</div>;
  }

  return null;
}

export { Event };
