import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import style from './charts.module.scss';
import { FC } from 'react';

interface PieChartProps {
  data: Record<string, number> | undefined;
  titleText: string;
  seriesName: string;
  dataLabelsEnabled?: boolean;
}

const PieChart: FC<PieChartProps> = ({ data, titleText, seriesName }) => {
  const formattedData = Object.entries(data || {}).map(([key, value]) => ({
    name: key,
    y: value,
  }));

  return (
    <div className={style.chart}>
      <Chart
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
          },
          title: {
            text: titleText,
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              },
            },
          },
          series: [
            {
              name: seriesName,
              colorByPoint: true,
              data: formattedData,
            },
          ],
        }}
      ></Chart>
    </div>
  );
};

export { PieChart };
