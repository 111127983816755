import {
  Button,
  Checkbox,
  Column,
  FormGroup,
  Grid,
  Row,
  Search,
} from 'carbon-components-react';
import classNames from 'classnames';
import ErrorToast from 'components/error-toast';
import { formatNumber } from 'components/search/common/format-helpers';
import { atom, Atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import plur from 'plur';
import { useMemo, useState } from 'react';
import { WellsQueryResponse } from 'types/api-responses';
import {
  addWellFnAtom,
  resetSearchDataAtom,
  searchAtom,
  usedWellsAtom,
} from './atoms';
import { WellFacets } from './types';
import { defaultPayload, updatedCheckboxes } from './utils';
import { Wells } from './wells';

import style from './wells-search.module.scss';
import { ProvideRootResource } from 'components/hydrate-atoms';

const ResetFilters = () => {
  const [search, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  return (
    <div className={style.resetFilters}>
      <Button
        kind="ghost"
        size="md"
        onClick={() => {
          const currentText = search.currentPayload?.text;
          resetSearchData(undefined);
          setSearchPayload(() => ({
            ...defaultPayload,
            text: currentText || '',
          }));
        }}
      >
        Reset Filters
      </Button>
    </div>
  );
};

const Checkboxes = ({
  facetName,
  legend,
  queryTerm,
}: {
  facetName: keyof WellFacets;
  queryTerm: 'holeDirectionIds' | 'statusIds';
  legend: string;
}) => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const currentPayload = searchResult.currentPayload;
  const facet = searchResult?.data?.facets?.[facetName];
  return (
    <FormGroup legendText={legend}>
      {facet ? (
        facet.buckets.map((bucket) => (
          <Checkbox
            key={bucket.id}
            id={`search-facet-check-${bucket.id}`}
            labelText={`${bucket.name} (${formatNumber(bucket.count)})`}
            checked={currentPayload?.[queryTerm]?.includes(bucket.id) ?? false}
            onChange={() => {
              setSearchPayload((current) => ({
                ...current,
                page: 1,
                [queryTerm]: updatedCheckboxes(bucket.id, current?.[queryTerm]),
              }));
            }}
          />
        ))
      ) : (
        <Checkbox
          id={`search-facet-check-none`}
          labelText="None"
          disabled
          checked={false}
        />
      )}
    </FormGroup>
  );
};

const WellsSearcher = ({ noGutter }: { noGutter?: boolean }) => {
  const [searchDisplayText, setSearchDisplayText] = useState('');
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);

  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        resetSearchData(undefined);
        setSearchPayload(() => ({
          ...defaultPayload,
          text: value,
        }));
      }, 400),
    [setSearchPayload, resetSearchData]
  );

  return (
    <>
      {searchResult?.error ? <ErrorToast message={searchResult.error} /> : null}
      <div className={style.search}>
        <Search
          data-modal-primary-focus
          labelText="Search"
          placeholder="Search"
          size="lg"
          autoFocus={true}
          value={searchDisplayText}
          onChange={({ target: { value } }) => {
            setSearchDisplayText(value);
            debouncedSetSearchPayload(value);
          }}
        />
      </div>

      <Grid className={classNames({ 'bx--no-gutter': noGutter })} fullWidth>
        <Row>
          <Column md={2} xlg={3}>
            {searchResult.data?.facets && (
              <>
                <p className={style.resultsSummary}>
                  {searchResult.data?.totalCount}{' '}
                  {plur('result', searchResult.data?.totalCount)} available
                </p>
                <ResetFilters />
                <Checkboxes
                  queryTerm="holeDirectionIds"
                  facetName="holeDirection"
                  legend="Hole Direction"
                />
                <Checkboxes
                  queryTerm="statusIds"
                  facetName="status"
                  legend="Status"
                />
              </>
            )}
          </Column>
          <Column md={6} xlg={13}>
            <Wells
              loading={searchResult.loading}
              searchResult={searchResult.data}
              onChange={(newSearch) =>
                setSearchPayload((current) => ({
                  ...current,
                  ...newSearch,
                }))
              }
            />
          </Column>
        </Row>
      </Grid>
    </>
  );
};

export interface Props {
  onAddWell?: (doc: WellsQueryResponse) => void;
  usedWellsAtom?: Atom<number[]>;
  noGutter?: boolean;
}

const WellsSearch = (props: Props) => {
  // Used to keep the atom stable, expected empty deps.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const usedRefsAtom = useMemo(() => props.usedWellsAtom ?? atom([]), []);
  const usedRefs = useAtomValue(usedRefsAtom);

  return (
    <ProvideRootResource
      initialValues={[
        [addWellFnAtom, { fn: props.onAddWell }],
        [usedWellsAtom, usedRefs],
      ]}
    >
      <WellsSearcher noGutter={props.noGutter} />
    </ProvideRootResource>
  );
};

export { WellsSearch };
