import { Report24 } from '@carbon/icons-react';
import classNames from 'classnames';

import style from './icons.module.scss';

const ReportIcon = () => {
  return <Report24 className={classNames(style.icon, style.water)} />;
};

export default ReportIcon;
