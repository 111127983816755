import { AtomizedForm } from 'atoms/form-atoms';
import { FormGroup } from 'carbon-components-react';
import { NumberField } from 'components/form-fields/number-field';
import { FormRow } from 'components/forms';
import { useEffect } from 'react';
import { ScenariosForm } from '../types';
import { useValidateForecastSourceFieldsAtom } from '../validations';

const CommissionAdjustments = ({
  form,
}: {
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
  >;
}) => {
  const validateForecastSourceFields = useValidateForecastSourceFieldsAtom(
    form.leaseholdAttributes
  );
  const commissionAdjustment = form.commissionAdjustmentAttributes;

  useEffect(() => {
    validateForecastSourceFields();
  }, [validateForecastSourceFields]);

  return (
    <FormGroup legendText="Commission Adjustment (%)">
      <FormRow>
        <NumberField
          id="targetCommissionPercentage"
          light
          min={0}
          label="Target"
          invalidText="Must be positive number"
          field={commissionAdjustment.targetPercentage}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="maxCommissionPercentage"
          light
          min={0}
          label="Max"
          invalidText="Must be positive number"
          field={commissionAdjustment.maxPercentage}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="maxCommissionPercentage"
          light
          min={0}
          label="MTM"
          invalidText="Must be positive number"
          field={commissionAdjustment.markToMarketPercentage}
        />
      </FormRow>
    </FormGroup>
  );
};

export { CommissionAdjustments };
