import { DocumentBlank32 } from '@carbon/icons-react';
import { getError } from 'atoms/form-atoms';
import searchAtomPair from 'atoms/search-atom';
import { Column, ComboBox, Row } from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { CountyQueryResponse } from 'types/api-responses';
import { formAtom } from './atoms';

const { searchAtom, resetSearchDataAtom } =
  searchAtomPair<CountyQueryResponse>('counties');

const County = () => {
  const form = useAtomValue(formAtom);
  const [county, setCounty] = useAtom(form.countyId);
  const [query, setPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const debouncedSetPayload = useMemo(
    () => debounce(setPayload, 400),
    [setPayload]
  );
  return (
    <FormSection
      title="General Document Info"
      iconComponent={<DocumentBlank32 />}
    >
      <FormRow>
        <Row>
          <Column>
            <ComboBox
              id={`county`}
              titleText="County"
              items={query?.data?.results || []}
              placeholder=""
              itemToString={(data) => {
                return data ? `${data.name}, ${data.stateAbbreviation}` : '';
              }}
              itemToElement={(result) => (
                <>{`${result.name}, ${result.stateAbbreviation}`}</>
              )}
              selectedItem={county.value || null}
              onChange={({ selectedItem }) => {
                selectedItem && setCounty(selectedItem ?? null);
              }}
              onInputChange={(text) => {
                resetSearchData(undefined);
                debouncedSetPayload((current) => ({
                  ...current,
                  text,
                  pageSize: 10,
                }));
              }}
              invalid={!county.isValid}
              invalidText={getError(county)}
              light
            />
          </Column>
        </Row>
      </FormRow>
    </FormSection>
  );
};

export { County };
