import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from 'carbon-components-react';
import { sortBy } from 'lodash';
import style from '../page.module.scss';
import { CalculationOutput } from 'types/api-responses';

export function RoyaltyRate({ data }: { data: CalculationOutput }) {
  return Object.entries(data).length ? (
    <TableContainer className={style.table}>
      <Table size="xs" useStaticWidth={true}>
        <TableHead>
          <TableRow>
            <TableHeader>Lease</TableHeader>
            <TableHeader>Royalty Rate</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy(Object.entries(data), [([name]) => name]).map(
            ([leaseName, royaltyRate]) => (
              <TableRow key={leaseName}>
                <TableCell>{leaseName}</TableCell>
                <TableCell>{royaltyRate}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
