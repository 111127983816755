import {
  CheckmarkFilled16,
  InProgress16,
  CircleDash16,
} from '@carbon/icons-react';
import { DocumentInterpretationResponse } from 'types/api-responses';

import style from './interpretation-review-count.module.scss';
import { getReviewDetails } from 'lib/review-interpretation';
import reviewStatusTypes from 'enums/review_status_types.json';

const InterpretationReviewCount = ({
  reviewAttributes,
}: {
  reviewAttributes?: DocumentInterpretationResponse['reviewAttributes'];
}) => {
  if (!reviewAttributes) {
    return null;
  }

  const { sectionsCount, totalReviewed, reviewStatus } =
    getReviewDetails(reviewAttributes);

  return (
    <>
      {reviewStatus === reviewStatusTypes.reviewed && (
        <CheckmarkFilled16 className={style.success} />
      )}
      {reviewStatus === reviewStatusTypes.pending && (
        <CircleDash16 className={style.pending} />
      )}
      {reviewStatus === reviewStatusTypes.partial && (
        <InProgress16 className={style.partial} />
      )}
      <span
        className={style.reviewCount}
      >{`${totalReviewed} out of ${sectionsCount}`}</span>
    </>
  );
};

export { InterpretationReviewCount };
