import { AtomField } from 'atoms/form-atoms';
import { DatePicker, DatePickerProps } from 'components/date-picker';
import { useAtomValue, useSetAtom } from 'jotai';
import { getDateString } from 'utils/dates';

interface DateFieldProps extends DatePickerProps {
  field: AtomField<string | null> | AtomField<string>;
}

const DateField = ({ field, ...rest }: DateFieldProps) => {
  const fieldState = useAtomValue(field);
  const setValue = useSetAtom(field);

  return (
    <DatePicker
      {...rest}
      value={fieldState.value || undefined}
      onChange={(date) => {
        if (date[0]) setValue(getDateString(date[0]));
      }}
      invalid={!fieldState.isValid}
      invalidText={!fieldState.isValid ? fieldState.error : ''}
    />
  );
};

export { DateField };
