import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { documentAtom, interpretationAtom } from '../atoms';
import { useHistory } from 'react-router-dom';
import { DestroyRecordButton } from 'components/destroy-record-button';
import { createDeleteAtom } from 'atoms/create-resource-atom';
import { InlineNotification } from 'carbon-components-react';

const DestroyInterpretation = ({
  refetchInterpretations,
}: {
  refetchInterpretations: () => void;
}) => {
  const interpretationReq = useAtomValue(interpretationAtom);
  const document = useAtomValue(documentAtom);
  const deleteAtom = useMemo(() => createDeleteAtom(), []);
  const history = useHistory();

  const interpretation =
    interpretationReq.state === 'hasData' && interpretationReq.data;

  if (interpretation && 'error' in interpretation)
    return (
      <InlineNotification
        kind={'error'}
        title={interpretation.error}
        lowContrast
      />
    );

  if (interpretation && !interpretation?.auth.includes('destroy')) {
    return null;
  }

  if (!interpretation) return null;

  return (
    <DestroyRecordButton
      deleteAtom={deleteAtom}
      url={interpretation.href}
      onSuccess={() => {
        if (document) {
          refetchInterpretations();
          history.push(document.resources.interpretations.href);
        }
      }}
    />
  );
};

export { DestroyInterpretation };
