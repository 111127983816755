import classNames from 'classnames';
import {
  Button,
  ButtonDefaultProps,
  ButtonKindProps,
} from 'carbon-components-react';
import { TrashCan16 } from '@carbon/icons-react';
import style from './trash-button.module.scss';
import { VFC } from 'react';

export interface Props extends ButtonDefaultProps, ButtonKindProps {
  adjustForLabel?: boolean;
  description: string;
}

const TrashButton: VFC<Props> = ({
  adjustForLabel = false,
  className,
  description,
  renderIcon = TrashCan16,
  ...rest
}) => {
  className = classNames(className, { [style.adjustForLabel]: adjustForLabel });

  return (
    <Button
      hasIconOnly
      renderIcon={renderIcon}
      iconDescription={description}
      tooltipAlignment="end"
      tooltipPosition="top"
      className={className}
      {...rest}
    />
  );
};

export { TrashButton };
