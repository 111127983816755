import { Switch } from 'react-router-dom';
import routes from '~/routes';
import { InterpretationsPage } from 'pages/documents/interpretations/page';
import { GlobalInterpretationsPage } from 'pages/interpretations/page';
import { TitleWorkspaces } from 'pages/title-workspaces';
import { TitleWorkspaceEntrypointPage } from 'pages/title-workspaces/router/page';
import { DocumentOverviewPage } from 'pages/documents/overview/page';
import { EntityListPage } from 'pages/entities/list/page';
import { NewEntityPage } from 'pages/entities/new/page';
import { NewDocumentPage } from 'pages/documents/new/page';
import { ShowDevelopmentAreaPage } from 'pages/development-areas/show/page';
import { DevelopmentAreasPage } from 'pages/development-areas/page';
import { ShowWellPage } from 'pages/wells/show/page';
import { DocumentSearchPage } from 'pages/documents/search/page';
import { WellSearchPage } from 'pages/wells/search/page';
import { DevelopmentAreaSummaryMapPage } from 'pages/development-areas/summary-map/page';
import { ReferenceLocationsMapPage } from 'pages/reference-locations/map/page';
import { ReferenceLocationsShowPage } from 'pages/reference-locations/show/page';
import { ActivitiesPage } from 'pages/activities/page';
import { TitleChainCalculatorPage } from 'pages/title-chain-calculator/page';
import { Route } from 'components/route';
import { ShowPackagePage } from 'pages/packages/show/page';
import { ScenariosPage } from 'pages/scenarios/list/page';
import { NewScenarioPage } from 'pages/scenarios/new/page';
import { EditScenarioPage } from 'pages/scenarios/show/page';
import { ListPackagesPage } from 'pages/packages/list/page';
import { EntityPageEntryPoint } from 'pages/entities/router/page';

export function Router() {
  return (
    <Switch>
      <Route exact path={routes.rootPath}>
        <DevelopmentAreaSummaryMapPage />
      </Route>
      <Route exact path={routes.documentsPath}>
        <DocumentSearchPage />
      </Route>
      <Route exact path={routes.interpretationsPath}>
        <GlobalInterpretationsPage />
      </Route>
      <Route exact path={routes.newDocumentsPath}>
        <NewDocumentPage />
      </Route>
      <Route exact path={routes.courthouseDocumentPath}>
        <DocumentOverviewPage />
      </Route>
      <Route exact path={routes.nonCourthouseDocumentPath}>
        <DocumentOverviewPage />
      </Route>
      <Route path={routes.documentInterpretationsPath}>
        <InterpretationsPage />
      </Route>
      <Route exact path={routes.activitiesPath}>
        <ActivitiesPage />
      </Route>
      <Route exact path={routes.wellsPath}>
        <WellSearchPage />
      </Route>
      <Route exact path={routes.wellPath}>
        <ShowWellPage />
      </Route>
      <Route exact path={routes.developmentAreasPath}>
        <DevelopmentAreasPage />
      </Route>
      <Route path={routes.developmentAreaPath}>
        <ShowDevelopmentAreaPage />
      </Route>
      <Route exact path={routes.referenceLocationsPath}>
        <ReferenceLocationsMapPage />
      </Route>
      <Route exact path={routes.referenceLocationPath}>
        <ReferenceLocationsShowPage />
      </Route>
      <Route exact path={routes.titleWorkspacesPath}>
        <TitleWorkspaces />
      </Route>
      <Route path={routes.titleWorkspacePath}>
        <TitleWorkspaceEntrypointPage />
      </Route>
      <Route exact path={routes.titleChainCalculatorPath}>
        <TitleChainCalculatorPage />
      </Route>
      <Route exact path={routes.newEntityPath}>
        <NewEntityPage />
      </Route>
      <Route exact path={routes.entitiesPath}>
        <EntityListPage />
      </Route>
      <Route path={routes.entityPath}>
        <EntityPageEntryPoint />
      </Route>
      <Route exact path={routes.packagesPath}>
        <ListPackagesPage />
      </Route>
      <Route path={routes.packagePath}>
        <ShowPackagePage />
      </Route>
      <Route exact path={routes.newScenarioPath}>
        <NewScenarioPage />
      </Route>
      <Route exact path={routes.scenarioPath}>
        <EditScenarioPage />
      </Route>
      <Route exact path={routes.scenariosPath}>
        <ScenariosPage />
      </Route>
    </Switch>
  );
}
