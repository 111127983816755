import { FC, useState } from 'react';
import style from './charts.module.scss';
import { ChartTypeSelection } from './chart-type-selection';
import { ColumnChart } from './column-chart';
import { PieChart } from './pie-chart';
import { CHART_VARIANTS, ChartTypes } from './types';

interface ChartsProps {
  data: Record<string, number> | undefined;
  titleText: string;
  xAxisText: string;
  yAxisText: string;
  seriesName: string;
  dataLabelsEnabled?: boolean;
}

const Charts: FC<ChartsProps> = ({
  data,
  titleText,
  xAxisText,
  yAxisText,
  seriesName,
  dataLabelsEnabled = true,
}) => {
  const [chartType, setChartType] = useState<ChartTypes>(CHART_VARIANTS.column);
  return (
    <div className={style.chart}>
      {chartType === CHART_VARIANTS.column && (
        <ColumnChart
          data={data}
          titleText={titleText}
          xAxisText={xAxisText}
          yAxisText={yAxisText}
          seriesName={seriesName}
          dataLabelsEnabled={dataLabelsEnabled}
        />
      )}
      {chartType === CHART_VARIANTS.pie && (
        <PieChart
          data={data}
          titleText={titleText}
          seriesName={seriesName}
          dataLabelsEnabled={dataLabelsEnabled}
        />
      )}
      <div className={style.chartSelectionContainer}>
        <ChartTypeSelection setChartType={setChartType} />
      </div>
    </div>
  );
};

export { Charts };
