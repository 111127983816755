import { AtomField } from 'atoms/form-atoms';
import { Dropdown, DropdownProps } from 'carbon-components-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { ReactNode } from 'react';

interface SelectableFieldProps<Items extends string | null>
  extends DropdownProps<Items> {
  field: AtomField<Items>;
  titleText?: ReactNode;
  required?: boolean;
}
const DropdownField = <Items extends string | null>({
  field,
  onChange,
  titleText,
  required,
  ...rest
}: SelectableFieldProps<Items>) => {
  const setValue = useSetAtom(field);
  const fieldObj = useAtomValue(field);

  return (
    <Dropdown<Items>
      titleText={
        <label>
          {titleText}
          {required ? <span>(Required)</span> : ''}
        </label>
      }
      selectedItem={fieldObj.value}
      onChange={(event) => {
        if (
          typeof event.selectedItem !== 'undefined' &&
          event.selectedItem !== null
        ) {
          setValue(event.selectedItem);
          onChange?.(event);
        }
      }}
      itemToString={(item) => item || ''}
      type="default"
      invalid={!fieldObj.isValid && fieldObj.isDirty}
      invalidText={!fieldObj.isValid && fieldObj.isDirty ? fieldObj.error : ''}
      {...rest}
    />
  );
};

export { DropdownField };
