import { debounce, isEmpty } from 'lodash';
import { ColumnPinningState } from '@tanstack/react-table';

type TableCustomization =
  | {
      columnSizes: Record<string, number>;
      columnVisibility: Record<string, boolean>;
      columnOrder?: string[];
      columnPinning?: ColumnPinningState;
      isColumnFilterActive: boolean;
    }
  | undefined;

export function fetchTableCustomization(tableId: string) {
  const tableCustomization = JSON.parse(
    localStorage.getItem(tableId) ?? '{}'
  ) as TableCustomization;
  return tableCustomization;
}

export function fetchColumnSizes(tableId: string) {
  const tableCustomization = fetchTableCustomization(tableId);
  return tableCustomization?.columnSizes ?? {};
}

export const fetchColumnOrder = (tableId: string) => {
  const tableCustomization = fetchTableCustomization(tableId);
  return tableCustomization?.columnOrder ?? [];
};
export const fetchColumnPinning = (tableId: string) => {
  const tableCustomization = fetchTableCustomization(tableId);
  return tableCustomization?.columnPinning ?? { left: [] };
};

export const updateColumnSizes = debounce(
  (tableId: string, updatedSizes: Record<string, number>) => {
    const tableCustomization = fetchTableCustomization(tableId) ?? {
      columnSizes: {},
    };
    tableCustomization.columnSizes = {
      ...(isEmpty(updatedSizes) ? {} : tableCustomization.columnSizes),
      ...updatedSizes,
    };
    localStorage.setItem(tableId, JSON.stringify(tableCustomization));
  },
  500
);

export const updateColumnOrder = debounce(
  (tableId: string, updatedOrder: string[]) => {
    const tableCustomization = fetchTableCustomization(tableId) ?? {
      columnOrder: [],
    };
    tableCustomization.columnOrder = updatedOrder;
    localStorage.setItem(tableId, JSON.stringify(tableCustomization));
  },
  500
);

export const updateColumnPinning = debounce(
  (tableId: string, updatedPinning: ColumnPinningState) => {
    const tableCustomization = fetchTableCustomization(tableId) ?? {
      columnPinning: { left: [] },
    };
    tableCustomization.columnPinning = updatedPinning;
    localStorage.setItem(tableId, JSON.stringify(tableCustomization));
  },
  500
);

export function fetchColumnVisibility(tableId: string) {
  const tableCustomization = fetchTableCustomization(tableId);
  return tableCustomization?.columnVisibility ?? {};
}

export function updateColumnVisibility(
  tableId: string,
  updatedVisibleColumns: Record<string, boolean>
) {
  const tableCustomization = fetchTableCustomization(tableId) ?? {
    columnVisibility: {},
  };
  tableCustomization.columnVisibility = {
    ...(isEmpty(updatedVisibleColumns)
      ? {}
      : tableCustomization.columnVisibility),
    ...updatedVisibleColumns,
  };

  localStorage.setItem(tableId, JSON.stringify(tableCustomization));
}

export function fetchIsColumnFilterActive(tableId: string) {
  const tableCustomization = fetchTableCustomization(tableId);
  return tableCustomization?.isColumnFilterActive ?? false;
}

export function updateIsColumnFilterActive(
  tableId: string,
  isColumnFilterActive: boolean
) {
  const tableCustomization = fetchTableCustomization(tableId) ?? {
    isColumnFilterActive: false,
  };
  tableCustomization.isColumnFilterActive = isColumnFilterActive;

  localStorage.setItem(tableId, JSON.stringify(tableCustomization));
}
