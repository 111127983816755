import { format } from 'lib/ui';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Tile,
  TooltipDefinition,
} from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import DevAreaSvg from 'images/srp-development-areas.svg';
import classnames from 'classnames';
import { DevelopmentAreaResponse } from 'types/api-responses';

import style from './formations-summary.module.scss';
import { EvalSlotResponse } from 'types/evaluation-api/responses';

const FormationsSummary = ({
  formations,
  slots,
}: {
  formations: DevelopmentAreaResponse['formations'];
  slots: EvalSlotResponse[];
}) => {
  return (
    <TableContainer>
      <Table aria-label="Formations">
        <TableHead>
          <TableRow>
            <TableHeader scope="col">Slots</TableHeader>
            <TableHeader scope="col">Formation</TableHeader>
            <TableHeader scope="col">
              Depth <strong>(ft)</strong>
            </TableHeader>
            <TableHeader scope="col">Type Curve</TableHeader>
            <TableHeader scope="col">
              Status <strong></strong>
            </TableHeader>
            <TableHeader scope="col">
              Spacing <strong>(ft)</strong>
            </TableHeader>
            <TableHeader scope="col">
              Oil EUR <strong>(MBBL)</strong>
            </TableHeader>
            <TableHeader scope="col">
              Gas EUR <strong>(MMCF)</strong>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {formations.map((formation) => (
            <TableRow key={formation.name}>
              <TableCell>
                <SlotDots formation={formation.name} slots={slots} />
              </TableCell>
              <TableCell>{formation.name.replace(/_/g, ' ') || '--'}</TableCell>
              <TableCell>
                {`${format.number(Math.abs(Math.round(formation.depth)))}` ||
                  '--'}
              </TableCell>
              <TableCell>
                {formation.typeCurve?.name.replace(/_/g, ' ') || '--'}
              </TableCell>
              <TableCell>
                {formation.typeCurve?.status.replace(/_/g, ' ') || '--'}
              </TableCell>
              <TableCell>
                {(formation.typeCurve?.spacing &&
                  formation.typeCurve?.spacing.toLocaleString()) ||
                  '--'}
              </TableCell>
              <TableCell>
                {(formation.typeCurve?.oilEurMbbl30yr &&
                  formation.typeCurve?.oilEurMbbl30yr.toLocaleString()) ||
                  '--'}
              </TableCell>
              <TableCell>
                {(formation.typeCurve?.gasEurMmcf30yr &&
                  formation.typeCurve?.gasEurMmcf30yr.toLocaleString()) ||
                  '--'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FormationNotAvailable = () => {
  return (
    <Tile className={style.emptyTile}>
      <div className={style.container}>
        <EmptyState
          icon={DevAreaSvg}
          headerText="No Formations Available"
          helperText=""
        />
        <p className={style.helperText}>
          This development area lacks formation information which is required to
          evaluate it. You will not be able to create an eval of this
          development area until that information is available. Please head over
          to our slack channel
          <a
            href="https://slack.com/app_redirect?channel=CQJER6JTD"
            target="_blank"
            rel="noreferrer"
          >
            #eval-tool
          </a>
          to submit a request.
        </p>
      </div>
    </Tile>
  );
};

const SlotDots = ({
  formation,
  slots,
}: {
  formation: string;
  slots: EvalSlotResponse[];
}) => {
  const slotsForFormation = slots.filter((s) => s.formationName === formation);
  return (
    <ul className={style.slots}>
      {slotsForFormation.map(
        ({ reserveCategory, forecastStatus, slotNumber }) => {
          const showForecast = reserveCategory !== forecastStatus;
          return (
            <li key={slotNumber}>
              <TooltipDefinition
                className={classnames(
                  style.toolTip,
                  style[reserveCategory],
                  style[forecastStatus]
                )}
                direction="top"
                tooltipText={
                  <>
                    {reserveCategory}
                    {showForecast ? ` — ${forecastStatus}` : null}
                  </>
                }
              >
                &#11044;
              </TooltipDefinition>
            </li>
          );
        }
      )}
    </ul>
  );
};

export { FormationsSummary, FormationNotAvailable };
