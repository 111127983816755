import type { Edge, Vertex } from './types';

const prepareGraph = (vertices: Vertex[], edges: Edge[]) => {
  const adjList: Record<number, Edge[]> = {};
  const vertexMap = new Map<number, Vertex>();

  vertices.forEach((v) => {
    adjList[v.name] = [];
    vertexMap.set(v.name, v);
  });
  edges.forEach((edge) => adjList[edge.from].push(edge));

  function getSubgraph(source: number, dest: number) {
    const isVisited = new Set<number>();
    const pathList: Edge[] = [];
    const allPaths: Edge[] = [];
    const selectedNodes = new Set<Vertex>();

    calculateSubgraphUtil(source, dest, isVisited, pathList, allPaths);
    const uniqueEdges = Array.from(new Set([...allPaths]));

    if (!uniqueEdges.length) {
      return {
        vertices,
        edges,
      };
    }

    const fromVertex = vertexMap.get(uniqueEdges[0].from);
    if (fromVertex) {
      selectedNodes.add(fromVertex);
      uniqueEdges.forEach((edge) => {
        const toVertex = vertexMap.get(edge.to);
        if (toVertex) {
          selectedNodes.add(toVertex);
        }
      });
    }

    return {
      vertices: Array.from(selectedNodes),
      edges: uniqueEdges,
    };
  }

  function calculateSubgraphUtil(
    vertexA: number,
    vertexB: number,
    isVisited: Set<number>,
    localPathList: Edge[],
    allPaths: Edge[]
  ) {
    if (vertexA === vertexB) {
      allPaths.push(...localPathList);
      return;
    }

    isVisited.add(vertexA);

    // Iterate for all the vertices
    // adjacent to current vertex
    for (let i = 0; i < adjList[vertexA].length; i++) {
      if (!isVisited.has(adjList[vertexA][i].to)) {
        // store current vertex
        const to = adjList[vertexA][i];
        localPathList.push(to);
        calculateSubgraphUtil(
          adjList[vertexA][i].to,
          vertexB,
          isVisited,
          localPathList,
          allPaths
        );

        // remove current vertex
        localPathList.splice(localPathList.indexOf(adjList[vertexA][i]), 1);
      }
    }

    // Unmark the current vertex
    isVisited.delete(vertexA);
  }

  return { getSubgraph };
};

export default prepareGraph;
