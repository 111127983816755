import { useAtomValue } from 'jotai';
import { Layer, Source } from 'react-map-gl';
import { mapStylesIndexAtom } from './map';
import { standardMapStyles } from './map-styles';

const LandGrid = () => {
  const mapStyleIndex = useAtomValue(mapStylesIndexAtom);
  const selectedStyle = standardMapStyles[mapStyleIndex];
  return (
    <Source id="land-grid" type="vector" url={'/maps/sources/land_grid'}>
      <Layer
        id="Texas Sections"
        type="line"
        source-layer="Texas Sections"
        minzoom={9}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': selectedStyle.lineColors.a,
          'line-width': 1.333,
        }}
      />
      <Layer
        id="Texas Blocks"
        type="line"
        source-layer="Texas Blocks"
        minzoom={8}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': selectedStyle.lineColors.b,
          'line-width': 4,
        }}
      />
      <Layer
        id="Section"
        type="line"
        source-layer="Section"
        minzoom={9}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': selectedStyle.lineColors.a,
          'line-width': 0.533333,
        }}
      />
      <Layer
        id="Township"
        type="line"
        source-layer="Township"
        minzoom={8}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{ 'line-color': selectedStyle.lineColors.b, 'line-width': 4 }}
      />
      <Layer
        id="County/1"
        type="line"
        source-layer="County"
        minzoom={6}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': selectedStyle.lineColors.c,
          'line-width': 5.33333,
        }}
      />
      <Layer
        id="County/0"
        type="line"
        source-layer="County"
        minzoom={6}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': selectedStyle.lineColors.d,
          'line-dasharray': [5, 5],
          'line-width': 1.33333,
        }}
      />
      <Layer
        id="State"
        type="line"
        source-layer="State"
        minzoom={4}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': selectedStyle.lineColors.c,
          'line-width': 8,
        }}
      />
      <Layer
        id="County/label/Boundary"
        type="symbol"
        source-layer="County/label"
        filter={['==', '_label_class', 1]}
        minzoom={9}
        layout={{
          'symbol-placement': 'line',
          'symbol-spacing': 1000,
          'text-font': selectedStyle.fonts.REGULAR,
          'text-size': 10.6667,
          'text-letter-spacing': 0.25,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.c,
        }}
      />
      <Layer
        id="County/label/Center Label"
        type="symbol"
        source-layer="County/label"
        filter={['==', '_label_class', 0]}
        minzoom={6}
        maxzoom={10.52}
        layout={{
          'text-font': selectedStyle.fonts.CONDENSED_BOLD,
          'text-size': 13.3333,
          'text-letter-spacing': 0.25,
          'text-field': '{_name}',
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.c,
        }}
      />
      <Layer
        id="State/label/Boundary Label"
        type="symbol"
        source-layer="State/label"
        filter={['==', '_label_class', 0]}
        minzoom={6.4}
        layout={{
          'symbol-placement': 'line',
          'symbol-spacing': 1000,
          'text-font': selectedStyle.fonts.ITALIC,
          'text-size': 18.6667,
          'text-letter-spacing': 0.25,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.c,
        }}
      />
      <Layer
        id="State/label/Center Label"
        type="symbol"
        source-layer="State/label"
        filter={['==', '_label_class', 1]}
        minzoom={4}
        maxzoom={7}
        layout={{
          'text-font': selectedStyle.fonts.ITALIC,
          'text-size': 21.3333,
          'text-letter-spacing': 0.25,
          'text-field': '{_name}',
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.c,
        }}
      />
      <Layer
        id="Texas Sections/label/BlkTwnSection Label"
        type="symbol"
        source-layer="Texas Sections/label"
        filter={['==', '_label_class', 1]}
        minzoom={13.85}
        layout={{
          'text-font': selectedStyle.fonts.REGULAR,
          'text-size': 13.3333,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.b,
        }}
      />
      <Layer
        id="Texas Sections/label/Section Label"
        type="symbol"
        source-layer="Texas Sections/label"
        filter={['==', '_label_class', 0]}
        minzoom={9.95}
        maxzoom={13.85}
        layout={{
          'text-font': selectedStyle.fonts.REGULAR,
          'text-size': 10.6667,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.b,
        }}
      />
      <Layer
        id="Texas Blocks/label/Boundary label"
        type="symbol"
        source-layer="Texas Blocks/label"
        filter={['==', '_label_class', 1]}
        minzoom={11.9}
        layout={{
          'symbol-placement': 'line',
          'symbol-spacing': 1000,
          'text-font': selectedStyle.fonts.REGULAR,
          'text-size': 13.3333,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.e,
        }}
      />
      <Layer
        id="Texas Blocks/label/Center label"
        type="symbol"
        source-layer="Texas Blocks/label"
        filter={['==', '_label_class', 0]}
        minzoom={8}
        maxzoom={12}
        layout={{
          'text-font': selectedStyle.fonts.CONDENSED_BOLD,
          'text-size': 13.3333,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.e,
        }}
      />
      <Layer
        id="Section/label/TRS Label"
        type="symbol"
        source-layer="Section/label"
        filter={['==', '_label_class', 0]}
        minzoom={13.85}
        layout={{
          'text-font': selectedStyle.fonts.REGULAR,
          'text-size': 13.3333,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.b,
        }}
      />
      <Layer
        id="Section/label/Section Label"
        type="symbol"
        source-layer="Section/label"
        filter={['==', '_label_class', 1]}
        minzoom={9.95}
        maxzoom={13.85}
        layout={{
          'text-font': selectedStyle.fonts.REGULAR,
          'text-size': 10.6667,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.b,
        }}
      />
      <Layer
        id="Township/label/Center label"
        type="symbol"
        source-layer="Township/label"
        filter={['==', '_label_class', 0]}
        minzoom={8}
        maxzoom={12}
        layout={{
          'text-font': selectedStyle.fonts.CONDENSED_BOLD,
          'text-size': 13.3333,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-allow-overlap': true,
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.e,
        }}
      />
      <Layer
        id="Township/label/Boundary label"
        type="symbol"
        source-layer="Township/label"
        filter={['==', '_label_class', 1]}
        minzoom={12}
        layout={{
          'text-font': selectedStyle.fonts.REGULAR,
          'text-size': 13.3333,
          'text-letter-spacing': 0.1,
          'text-field': '{_name}',
          'text-optional': true,
        }}
        paint={{
          'text-color': selectedStyle.textColors.e,
        }}
      />
    </Source>
  );
};

export default LandGrid;
