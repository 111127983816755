import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import style from './definition-list.module.scss';

export interface DefinitionTermProps extends HTMLAttributes<HTMLElement> {
  noBorder?: boolean;
}
const DefinitionTerm = ({
  noBorder,
  className,
  ...rest
}: DefinitionTermProps) => {
  className = classNames(style.term, { [style.noBorder]: noBorder }, className);
  return <dt className={className} {...rest} />;
};

export { DefinitionTerm };
