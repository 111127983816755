import { Information32 } from '@carbon/icons-react';
import { Column, Grid, Row } from 'carbon-components-react';
import { DatePicker } from 'components/date-picker';
import { FormRow, FormSection } from 'components/forms';
import { useAtom } from 'jotai';
import { getDateString } from 'utils/dates';
import { StringField } from './string-field';
import { AtomizedTitleWorkspaceSettingsForm } from './types';

const GeneralSettings = ({
  form,
}: {
  form: AtomizedTitleWorkspaceSettingsForm;
}) => {
  const [startingDate, setStartingDate] = useAtom(form.startingInterestDate);
  return (
    <FormSection title="General Settings" iconComponent={<Information32 />}>
      <FormRow>
        <StringField
          light
          id="title-workspace-name"
          labelText="Title Workspace name"
          name="name"
          field={form.name}
        />
      </FormRow>
      <FormRow>
        <Grid className="bx--no-gutter">
          <Row>
            <Column lg={4}>
              <DatePicker
                size="md"
                light
                id="starting-date"
                labelText="Starting Date"
                value={startingDate.value}
                onChange={(date) => {
                  setStartingDate(date[0] ? getDateString(date[0]) : '');
                }}
                invalid={!startingDate.isValid}
                invalidText={!startingDate.isValid ? startingDate.error : ''}
              />
            </Column>
            <Column lg={4}>
              <StringField
                light
                id="legal-acreage"
                labelText="Starting Legal Acreage"
                name="legalAcreage"
                field={form.legalAcreage}
              />
            </Column>
          </Row>
        </Grid>
      </FormRow>
    </FormSection>
  );
};

export { GeneralSettings };
