import { AtomizedForm } from 'atoms/form-atoms';
import { Checkbox, FormGroup } from 'carbon-components-react';
import { NumberField } from 'components/form-fields/number-field';
import { FormRow } from 'components/forms';
import { useAtom } from 'jotai';
import { TierCashflowsPayload } from 'types/scenarios-api/responses';
import { ScenariosForm } from '../types';
import { useValidateEconomicLimitsAtom } from '../validations';

const EconomicLimits = ({
  form,
}: {
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
      >
    | AtomizedForm<TierCashflowsPayload>;
}) => {
  const economicLimitsAttributes = form.economicLimitAttributes;
  const [{ value: applyShutInLimit }, setApplyShutInLimit] = useAtom(
    economicLimitsAttributes.applyShutInLimit
  );

  const validateShutInLimit = useValidateEconomicLimitsAtom(
    economicLimitsAttributes.applyShutInLimit,
    economicLimitsAttributes.shutInLimit
  );

  return (
    <FormGroup legendText="Economic Limits">
      <FormRow>
        <Checkbox
          id={'applyShutInLimit'}
          checked={applyShutInLimit}
          onChange={(checked: boolean) => {
            setApplyShutInLimit(checked);
            validateShutInLimit();
          }}
          labelText="Apply Single Well Economic Limits"
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="shutInLimit"
          label="Single Well Economic Limit ($/Month)"
          light
          field={economicLimitsAttributes.shutInLimit}
          disabled={!applyShutInLimit}
        />
      </FormRow>
    </FormGroup>
  );
};

export { EconomicLimits };
