import { Policy16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useMemo } from 'react';
import { LeaseManagementModal } from './lease-management-modal';

import style from './lease-management-section.module.scss';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  createLeaseSelectedAreasAtom,
  leaseManagementValidationAtom,
  modalStateAtom,
  validationUrlAtom,
} from './atoms';
import { LeaseManagementSubformAtoms } from './utils';
import { MineralLeaseAtoms } from '../lease-transaction';

interface Props {
  transactionAtoms: MineralLeaseAtoms;
  leaseManagementAtoms: LeaseManagementSubformAtoms;
  trxRowDisabled: boolean;
}

const LeaseManagementSection = ({
  leaseManagementAtoms,
  transactionAtoms,
  trxRowDisabled,
}: Props) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const fields = useAtomValue(leaseManagementAtoms.formAtom);
  const locationsAtom = useMemo(
    () => createLeaseSelectedAreasAtom(fields.locationReferences.value),
    [fields.locationReferences.value]
  );
  const validateForm = useSetAtom(leaseManagementValidationAtom);
  const locations = useAtomValue(locationsAtom);
  const validationUrl = useAtomValue(validationUrlAtom);

  return (
    <div>
      <LeaseManagementModal
        isOpen={modalState.open}
        onClose={() => {
          setModalState({ atoms: transactionAtoms, action: 'close' });
        }}
        onSave={() => {
          validateForm({
            leaseManagementAtoms: leaseManagementAtoms,
            transactionAtoms: transactionAtoms,
            validationUrl: validationUrl,
            onSuccess: () => {
              setModalState({ atoms: transactionAtoms, action: 'save' });
            },
          });
        }}
        leaseManagementAtoms={leaseManagementAtoms}
      />
      {fields.retainedLand.value === 'partial' ||
      fields.retainedLand.value === 'all' ? (
        <div className={style.leaseManagement}>
          <div className={style.leaseTitle}>Lease Management Summary</div>
          <ul className={style.leaseContent}>
            <legend>Applicable Areas</legend>
            {fields.retainedLand.value === 'partial' &&
              locations.map(
                (el, i) =>
                  el && (
                    <li
                      className={el.forDeletion ? style.forDeletion : undefined}
                      key={`${el.referenceLocation.value?.id}-${i}`}
                    >
                      {el.referenceLocation.value?.name}
                      {el.quarterCalls.value.length ? ' | ' : null}
                      {el.quarterCalls.value.join(' • ')}
                      {el.startDepthInFeet.value
                        ? ` | ${el.startDepthInFeet.value}`
                        : null}
                      {el.endDepthInFeet.value
                        ? ` • ${el.endDepthInFeet.value}`
                        : null}
                    </li>
                  )
              )}
            {fields.retainedLand.value === 'all' ? <li>All Lands</li> : null}
          </ul>
        </div>
      ) : null}
      <Button
        kind="tertiary"
        size="md"
        renderIcon={Policy16}
        onClick={() => {
          setModalState({ atoms: transactionAtoms, action: 'open' });
        }}
        disabled={trxRowDisabled}
      >
        Lease Management
      </Button>
    </div>
  );
};

export { LeaseManagementSection };
