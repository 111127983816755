import classNames from 'classnames';
import { FC } from 'react';

import type { IconProps } from 'components/icons/types';
import style from './empty-state.module.scss';

interface Props {
  icon?: string; // The icon src
  IconComponent?: React.FunctionComponent<IconProps>;
  iconClassName?: string;
  className?: string;
  headerText?: string;
  helperText?: string;
}

const EmptyState: FC<Props> = ({
  icon = null,
  IconComponent,
  iconClassName,
  headerText,
  helperText,
  className,
  children,
}) => {
  const containerClassName = classNames(style.container, className);

  return (
    <div className={containerClassName}>
      {icon && <img className={style.icon} src={icon} />}
      {IconComponent && (
        <IconComponent className={classNames(style.icon, iconClassName)} />
      )}
      <h5 className={style.header}>{headerText}</h5>
      {helperText && <div className={style.helper}>{helperText}</div>}
      {children && <div className={style.body}>{children}</div>}
    </div>
  );
};

export default EmptyState;
