import { ErrorFilled16 } from '@carbon/icons-react';
import { AtomField } from 'atoms/form-atoms';
import { TextInput } from 'carbon-components-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { ReactNode } from 'react';

import style from './form.module.scss';

const TextField = ({
  id,
  labelText,
  fieldAtom,
  autoComplete,
  placeholder,
  required,
}: {
  id: string;
  labelText: ReactNode;
  fieldAtom: AtomField<string | null> | AtomField<string>;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
}) => {
  const field = useAtomValue(fieldAtom);
  const setField = useSetAtom(fieldAtom);
  return (
    <TextInput
      id={id}
      labelText={
        <label>
          {labelText}
          {required ? <span>(Required)</span> : ''}
          {!field.isValid ? (
            <span className={style.required}>
              <ErrorFilled16 />
            </span>
          ) : (
            ''
          )}
        </label>
      }
      size="md"
      light
      className={style.textInput}
      value={field.value || ''}
      onChange={({ target: { value } }) => setField(value)}
      invalid={!field.isValid && field.isDirty}
      invalidText={!field.isValid && field.isDirty ? field.error : ''}
      autoComplete={autoComplete}
      placeholder={placeholder}
    />
  );
};

export { TextField };
