import { Add16, Events32 } from '@carbon/icons-react';
import { FormRowStatus } from 'atoms/create-resource-atom';
import { AtomizedForm, ExtractAtom, getError } from 'atoms/form-atoms';
import { Button, Column, Row, TextInput } from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { DeleteRowButton } from 'components/interpretation-form/delete-row-button';
import { atom, useAtom, useAtomValue } from 'jotai';
import { memo, useCallback } from 'react';
import { formAtom } from './atoms';
import { DocumentForm } from './types';
import { entityRow } from './utils';

interface PartyProps {
  form: ExtractAtom<AtomizedForm<DocumentForm>['granteesAttributes']>[0];
  atomIdx: number;
  onDelete?: (atomIdx: number) => void;
  type: string;
}

const Party = memo(({ atomIdx, form, onDelete, type }: PartyProps) => {
  const [name, setName] = useAtom(form.name);

  return (
    <FormRow>
      <Row>
        <Column md={7} lg={15}>
          <TextInput
            light
            id={`${type}-${atomIdx}`}
            value={name.value || ''}
            onChange={({ target: { value } }) => setName(value)}
            labelText={`${type} (Optional)`}
            invalid={!name.isValid}
            invalidText={getError(name)}
          />
        </Column>
        <Column md={1} lg={1}>
          <DeleteRowButton
            statusAtom={atom<FormRowStatus>('new')}
            atomIdx={atomIdx}
            onDelete={onDelete}
            adjustForLabel={true}
          />
        </Column>
      </Row>
    </FormRow>
  );
});

Party.displayName = 'Party';

interface Props {
  title: string;
  addLabel: string;
  field: 'grantorsAttributes' | 'granteesAttributes';
}

const PartyList = ({ title, field, addLabel }: Props) => {
  const form = useAtomValue(formAtom);
  const [entities, setEntities] = useAtom(form[field]);

  const onDelete = useCallback(
    (index: number) => {
      setEntities((current) => current.filter((_, i) => i !== index));
    },
    [setEntities]
  );

  return (
    <FormSection title={title} iconComponent={<Events32 />}>
      <FormRow>
        {entities.value.map((form, i) => {
          return (
            <Party
              key={i}
              form={form}
              atomIdx={i}
              onDelete={onDelete}
              type={field.includes('grantors') ? 'Grantors' : 'Grantees'}
            />
          );
        })}
        <Button
          kind="ghost"
          renderIcon={Add16}
          onClick={() => {
            setEntities((current) => [...current, entityRow()]);
          }}
        >
          {addLabel}
        </Button>
      </FormRow>
    </FormSection>
  );
};

export { PartyList };
