import classNames from 'classnames';
import { useEffect } from 'react';
import DrawerPrimitive from 'react-modern-drawer';
import { PropsFrom } from 'types/utils';
import style from './drawer.module.scss';

const Drawer = ({ ...props }: PropsFrom<typeof DrawerPrimitive>) => {
  useEffect(() => {
    if (props.open) {
      document.body.classList.add('drawer-open');
      return;
    }
    document.body.classList.remove('drawer-open');
    return () => {
      document.body.classList.remove('drawer-open');
    };
  }, [props.open]);

  return <DrawerPrimitive {...props} />;
};
Drawer.displayName = 'Drawer';

const DrawerContent = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames(style.content, className)} {...props} />
);

const DrawerHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames(style.header, className)} {...props} />
);

export { Drawer, DrawerContent, DrawerHeader };
