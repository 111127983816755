import { ReactNode } from 'react';

export function optional(node: ReactNode) {
  return node || 'Unknown';
}

export interface OptionalProps {
  children: ReactNode;
}

export function Optional({ children }: OptionalProps) {
  return <>{optional(children)}</>;
}
