import { Tile } from 'carbon-components-react';
import { WarningAlt32 } from '@carbon/icons-react';
import { Copy } from 'components/copy';

const ErrorPage = () => {
  return (
    <Tile>
      <Copy
        heading={
          <>
            <WarningAlt32 /> There was an error.
          </>
        }
      >
        <p>Sorry!</p>

        <p>
          Please reload and try again. If this happens again, please let us know
          in the{' '}
          <a
            href="https://slack.com/app_redirect?channel=C02UVPQKXDL"
            target="_blank"
            rel="noreferrer"
          >
            #title-chaining-tool
          </a>{' '}
          channel on Slack.
        </p>
      </Copy>
    </Tile>
  );
};

export { ErrorPage };
