import { useEffect, useState } from 'react';
import {
  Button,
  ButtonSize,
  InlineNotification,
  Loading,
  TextArea,
} from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import { Events as EventsIcon } from 'components/icons';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  createCommentAtom,
  isEventsPanelOpenAtom,
  useEventsAtom,
} from './atoms';

import style from './events-panel.module.scss';
import { AddComment16, Close20 } from '@carbon/icons-react';
import { Event } from './event';
import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';

const Events = ({
  title,
  helperText,
  eventsHref,
  commentsHref,
  onComment,
}: {
  title: string;
  helperText?: string;
  commentsHref: string;
  eventsHref: string;
  onComment?: () => void;
}) => {
  const eventsAtom = useEventsAtom(eventsHref);
  const { loading, data: events } = useAtomValue(eventsAtom);
  const getEvents = useSetAtom(eventsAtom);

  if (loading) return <Loading />;
  if (events && 'error' in events)
    return <InlineNotification title={events.error} kind="error" lowContrast />;

  return (
    <>
      <div className={style.events}>
        {events?.length ? (
          <>
            <ul>
              {events
                .sort(
                  (a, b) =>
                    new Date(a.createdAt as string).getTime() -
                    new Date(b.createdAt as string).getTime()
                ) // Sort in ascending order (earliest to latest)
                .map((item) => (
                  <Event key={item.id} item={item} />
                ))}
            </ul>
          </>
        ) : (
          <EmptyState
            IconComponent={EventsIcon}
            headerText={title}
            helperText={helperText}
            className={style.empty}
          />
        )}
      </div>
      <Input
        getEvents={getEvents}
        commentsHref={commentsHref}
        onComment={onComment}
      />
    </>
  );
};

const Input = ({
  getEvents,
  commentsHref,
  onComment,
}: {
  getEvents?: () => Promise<void>;
  commentsHref: string;
  onComment?: () => void;
}) => {
  const [comment, setComment] = useState('');
  const [commentResult, createComment] = useAtom(createCommentAtom);
  return (
    <div className={style.input}>
      <TextArea
        labelText={null}
        placeholder="Comment"
        light
        autoFocus={true}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className={style.buttons}>
        <Button
          type="submit"
          disabled={!comment || commentResult.loading}
          onClick={() => {
            createComment({
              url: commentsHref,
              type: 'POST',
              data: { content: comment },
              onSuccess: () => {
                onComment?.();
                setComment('');
                getEvents?.();
              },
            });
          }}
        >
          Post
        </Button>
      </div>
    </div>
  );
};

type EventsPanelProps = {
  header: string;
  title: string;
  helperText?: string;
  commentsHref?: string;
  eventsHref?: string;
  onComment?: () => void;
  commentsPanel?: boolean;
};

type EventListProps = Omit<EventsPanelProps, 'header'>;

const EventsList = ({
  title,
  helperText,
  eventsHref,
  commentsHref,
  onComment,
  commentsPanel,
}: EventListProps) => {
  return (
    <div className={commentsPanel ? style.commentsPanel : style.panel}>
      <div className={style.content}>
        {eventsHref && commentsHref && (
          <Events
            title={title}
            helperText={helperText}
            eventsHref={eventsHref}
            commentsHref={commentsHref}
            onComment={onComment}
          />
        )}
      </div>
    </div>
  );
};

const EventsPanel = ({
  title,
  helperText,
  eventsHref,
  commentsHref,
  header,
}: EventsPanelProps) => {
  const [open, setOpen] = useAtom(isEventsPanelOpenAtom);
  const drawerTitle = 'Comments';

  useEffect(() => {
    return () => setOpen(false);
  }, [setOpen]);

  return (
    <Drawer
      lockBackgroundScroll
      open={open}
      direction="right"
      size={'100%'}
      className={style.commentsPanelDrawer}
      onClose={() => setOpen(false)}
    >
      {open && (
        <>
          <DrawerHeader>
            <div className={style.drawerHeader}>
              <div>
                {title}: {header}
                <h2>{drawerTitle}</h2>
              </div>
              <Button
                hasIconOnly
                size="lg"
                kind="ghost"
                onClick={() => setOpen(false)}
                iconDescription={'Close'}
                tooltipAlignment="center"
                tooltipPosition="bottom"
                renderIcon={Close20}
              />
            </div>
          </DrawerHeader>
          <DrawerContent>
            <EventsList
              title={title}
              helperText={helperText}
              eventsHref={eventsHref}
              commentsHref={commentsHref}
            />
          </DrawerContent>
        </>
      )}
    </Drawer>
  );
};

const EventsPanelOpenButton = ({
  buttonSize = 'md',
  tooltipPosition = 'top',
  tooltipAlignment = 'center',
}: {
  buttonSize?: ButtonSize;
  tooltipPosition?: string;
  tooltipAlignment?: string;
}) => {
  const [, setOpen] = useAtom(isEventsPanelOpenAtom);
  return (
    <Button
      hasIconOnly
      kind="tertiary"
      size={buttonSize}
      renderIcon={AddComment16}
      iconDescription={'Comments'}
      tooltipAlignment={tooltipAlignment}
      tooltipPosition={tooltipPosition}
      onClick={() => setOpen(true)}
      className={style.openCommentsButton}
    />
  );
};

function Comments({
  eventsHref,
  commentsHref,
  onComment,
}: {
  eventsHref: string;
  commentsHref: string;
  onComment: () => void;
}) {
  return (
    <EventsList
      title="No comments added"
      helperText="Add a comment for yourself or leave a note for others."
      eventsHref={eventsHref}
      commentsHref={commentsHref}
      commentsPanel
      onComment={onComment}
    />
  );
}

function CreateFirstComment({
  onCreateComment,
  title,
  helperText,
  saving,
}: {
  onCreateComment: (comment: string) => void;
  title: string;
  helperText: string;
  saving: boolean;
}) {
  const [comment, setComment] = useState('');
  return (
    <div className={style.firstCommentWrapper}>
      <div className={style.events}>
        <EmptyState
          IconComponent={EventsIcon}
          headerText={title}
          helperText={helperText}
          className={style.empty}
        />
      </div>
      <div className={style.input}>
        <TextArea
          labelText={null}
          placeholder="Comment"
          light
          autoFocus={true}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className={style.buttons}>
          <Button
            type="submit"
            disabled={!comment || saving}
            onClick={() => {
              onCreateComment(comment);
            }}
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
}

export {
  Comments,
  CreateFirstComment,
  EventsList,
  EventsPanel,
  EventsPanelOpenButton,
};
