import { Suspense, useState } from 'react';
import {
  CalculationOutput,
  CalculationOutputRow,
  MineralLeaseholdRow,
} from 'types/api-responses';
import {
  Overrides,
  InterestTable,
  MineralLeaseholdTable,
  AggregateSurfaceLeaseOwnership,
} from '.';
import ChainViewer from '../chain_viewer';

interface OutputProps {
  type: 'surface' | 'mineral';
  output: CalculationOutput;
  outputRows: CalculationOutputRow[];
  mineralLeaseholdRows: MineralLeaseholdRow[];
  graph: string | null;
  tractName: string;
  depthRangeName?: string;
}

export function Output({
  type,
  output,
  outputRows,
  mineralLeaseholdRows,
  graph,
  tractName,
  depthRangeName,
}: OutputProps) {
  const [party, setParty] = useState<string>('');

  return (
    <section aria-label="output">
      {party && graph ? (
        <Suspense fallback={<div>Loading</div>}>
          <ChainViewer
            tractName={tractName}
            depthRangeName={depthRangeName}
            isSurface={type === 'surface' ? true : false}
            url={graph}
            party={party}
            onClose={() => setParty('')}
          />
        </Suspense>
      ) : null}
      {output.aggregateSurfaceLeaseOwnership && (
        <AggregateSurfaceLeaseOwnership
          rows={output.aggregateSurfaceLeaseOwnership}
        />
      )}
      <InterestTable
        type={type}
        rows={outputRows}
        onPartySelected={(party: string) => setParty(party)}
        graphAvailable={!!graph}
      />
      {mineralLeaseholdRows && (
        <MineralLeaseholdTable rows={mineralLeaseholdRows} />
      )}
      <Overrides rows={output.mineralLeaseOverrides} />
    </section>
  );
}
