import { atom, useAtomValue } from 'jotai';
import request from 'lib/request';
import { useMemo } from 'react';
import { Popup } from 'react-map-gl';
import { SearchResult, WellsResponse } from 'types/api-responses';
import wellStatusTypes from 'enums/well_status_types.json';

import style from './map.module.scss';
import { resourcesAtom } from 'atoms/root';
import { Link } from 'react-router-dom';
import { Loading } from 'carbon-components-react';

type WellsMap = Record<
  string,
  { uwi: string; name: string; status: string } | undefined
>;

const createWellSearchAtom = (
  wells: WellsMap,
  queryHref: string | undefined
) => {
  const abortableAtom = atom<{ loading: boolean; wells: WellsMap }>({
    loading: false,
    wells,
  });

  abortableAtom.onMount = (setAtom) => {
    const abortController = new AbortController();

    const queryWells = async () => {
      const wellsCopy = { ...wells };
      if (!queryHref) return;
      const promises = Object.keys(wellsCopy).map((el) =>
        request
          .post<SearchResult<WellsResponse>, { uwi: string }>(
            queryHref,
            { uwi: el },
            abortController
          )
          .then((response) => {
            if (response.type === 'success') {
              const { results } = response.data;
              if (results.length) {
                const wellInfo = results[0];
                wellsCopy[wellInfo.uwi] = {
                  uwi: wellInfo.uwi,
                  name: wellInfo.name,
                  status: wellInfo.status,
                };
              }
            }
          })
      );

      await Promise.all(promises).catch();
      setAtom(() => ({ loading: false, wells: wellsCopy }));
    };

    // Wait for the user to settle in the mouse position before
    // triggering the wells queries as the well density is usually high.
    const timeout = setTimeout(() => {
      setAtom((current) => ({ loading: true, wells: { ...current.wells } }));
      queryWells();
    }, 500);

    return () => {
      clearTimeout(timeout);
      abortController.abort();
    };
  };
  return abortableAtom;
};

const WellPopup = ({
  wells,
  latitude,
  longitude,
  closeButton,
  onClose,
}: {
  wells: Record<string, undefined>;
  latitude: number;
  longitude: number;
  closeButton: boolean;
  onClose: () => void;
}) => {
  const resources = useAtomValue(resourcesAtom);
  const queryHref = resources?.queries['wells'].href;

  const wellsFetcherAtom = useMemo(
    () => createWellSearchAtom(wells, queryHref),
    [wells, queryHref]
  );
  const wellsFetcher = useAtomValue(wellsFetcherAtom);
  return (
    <Popup
      key={Number(closeButton)}
      closeButton={closeButton}
      closeOnClick={false}
      onClose={onClose}
      latitude={latitude}
      longitude={longitude}
      className={style.popup}
    >
      {Object.entries(wellsFetcher.wells).map(([key, data]) => (
        <div key={key} className={style.wellPopup}>
          {data ? (
            <>
              <p>
                <Link
                  to={`/wells/${data.uwi}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.uwi}
                </Link>
                <br />
                <span>
                  {data.name}
                  <br />
                  {wellStatusTypes[data.status as keyof typeof wellStatusTypes]}
                </span>
              </p>
            </>
          ) : (
            <p>{key}</p>
          )}
        </div>
      ))}
      {wellsFetcher.loading && (
        <div className={style.loading}>
          <Loading small />
        </div>
      )}
    </Popup>
  );
};

export { WellPopup };
