import { AtomField, AtomizedForm } from 'atoms/form-atoms';
import { InformationFilled16 } from '@carbon/icons-react';
import {
  Checkbox,
  Column,
  Grid,
  Row,
  TooltipDefinition,
} from 'carbon-components-react';
import { NumberField } from 'components/form-fields/number-field';
import { ScenariosForm } from '../types';
import { useAtomValue, useSetAtom } from 'jotai';
import style from './infill-assumptions.module.scss';

const InfillAssumptions = ({
  form,
}: {
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['infillAttributes']
      >
    | AtomizedForm<
        ScenariosForm['tiersAttributes'][0]['drillingAssumptionAttributes']['infillAttributes']
      >;
}) => {
  const IncludeUpsideCheckbox = ({
    id,
    field,
    label,
  }: {
    id: string;
    field: AtomField<boolean> | AtomField<boolean | null>;
    label: string;
  }) => {
    const setValue = useSetAtom(field);
    const fieldState = useAtomValue(field);
    return (
      <div className={style.tooltipRow}>
        <Checkbox
          id={id}
          checked={fieldState.value === null ? false : fieldState.value}
          onChange={(checked: boolean) => setValue(checked)}
          labelText={label}
        />
        <TooltipDefinition
          direction="top"
          tooltipText="If checked, active upside LTDs will be included in evaluations and the limit checks below"
        >
          <InformationFilled16 />
        </TooltipDefinition>
      </div>
    );
  };

  return (
    <div className={style.infillAssumptions}>
      <h3>Infill Assumptions</h3>
      <Grid fullWidth className="bx--no-gutter">
        <Row>
          <Column className={style.flexContainer}>
            <IncludeUpsideCheckbox
              id="infillIncludeUpside"
              field={form.includeUpside}
              label="Include Active Upside LTDs"
            />
          </Column>
        </Row>
        <Row className={style.rowThirds}>
          <Column className={style.relativeColumns}>
            <TooltipDefinition
              className={style.toolTip}
              direction="top"
              tooltipText="If >=X% of the slots within a single bench are filled (PDP, DUC, PMT, Shut-In), kill LTDs in that bench"
            >
              <InformationFilled16 />
            </TooltipDefinition>
            <NumberField
              id="infillFormationLimit"
              label="Formation Limit (%)"
              light
              min={0}
              invalidText="Must be positive number"
              field={form.formationLimitPercentage}
            />
          </Column>
          <Column className={style.relativeColumns}>
            <TooltipDefinition
              className={style.toolTip}
              direction="top"
              tooltipText="If >=X% of the slots in the unit are filled, kill LTDs in the unit"
            >
              <InformationFilled16 />
            </TooltipDefinition>
            <NumberField
              id="infillDevelopmentAreaLimit"
              label="Development Area Limit (%)"
              light
              min={0}
              invalidText="Must be positive number"
              field={form.developmentAreaLimitPercentage}
            />
          </Column>
          <Column className={style.relativeColumns}>
            <TooltipDefinition
              className={style.toolTip}
              direction="top"
              tooltipText="If there are fewer than X LTDs in the unit, kill them"
            >
              <InformationFilled16 />
            </TooltipDefinition>
            <NumberField
              id="infillLtdLimit"
              label="LTD Minimum"
              light
              min={0}
              invalidText="Must be positive number"
              field={form.ltdLimit}
            />
          </Column>
        </Row>
      </Grid>
    </div>
  );
};

export { InfillAssumptions };
