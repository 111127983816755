import { Add16 } from '@carbon/icons-react';
import { Button, Column, ComboBox, Grid, Row } from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { useAtomValue, useAtom, atom } from 'jotai';
import { memo, useMemo, useState } from 'react';
import { createUpdateTrackerAtom, formAtoms } from '../atoms';
import { DeleteRowButton } from '../delete-row-button';
import { FormRowManager } from '../form-row-manager';
import { DocumentReferencesModal } from './document-references-modal';
import { DocumentReferenceAtoms } from './types';
import { initEmptyForm } from './utils';

import documentReferenceTypes from 'enums/document_reference_types.json';
import { Link } from 'react-router-dom';

import style from './document-reference.module.scss';
import { ReviewSectionCheckbox } from '../review-section-checkbox';

type ReferenceTypes = keyof typeof documentReferenceTypes;

interface Props {
  atomIdx: number;
  atoms: DocumentReferenceAtoms;
  onDelete?: (idx: number) => void;
}

const DocumentReferenceForm = ({ atomIdx, onDelete, atoms }: Props) => {
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const status = useAtomValue(atoms.status);

  if (!fields.document.value) {
    return null;
  }

  const { documentTitle } = fields.document.value;
  return (
    <FormRow toTrash={status === 'trash'}>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column sm={1} md={3} lg={5} className={style.centerLink}>
            <Link
              target="_blank"
              to={fields.document.value.href}
              className={style.documentLink}
            >
              {documentTitle}
            </Link>
          </Column>
          <Column sm={2} md={3} lg={5}>
            <ComboBox
              light
              titleText="Document Reference Type"
              placeholder=""
              onChange={({ selectedItem }) => {
                const key = Object.keys(documentReferenceTypes).find(
                  (key) =>
                    documentReferenceTypes[key as ReferenceTypes] ===
                    selectedItem
                );
                setField({ field: 'referenceType', value: key || '' });
              }}
              id={`${atomIdx}-reference-type`}
              items={['', ...Object.values(documentReferenceTypes)]}
              type="default"
              disabled={status === 'trash'}
              selectedItem={
                fields.referenceType.value
                  ? documentReferenceTypes[
                      fields.referenceType.value as ReferenceTypes
                    ]
                  : null
              }
            />
          </Column>
          <Column sm={1} md={2} lg={2}>
            <DeleteRowButton
              statusAtom={atoms.status}
              atomIdx={atomIdx}
              onDelete={onDelete}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

const CurativeRequirementMemo = memo(DocumentReferenceForm);

type ManagedFormType = {
  field: 'documentReferences';
  value: DocumentReferenceAtoms[];
};
const DocumentReferenceList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const docReferences = useAtomValue(useAtomValue(formAtoms).formAtom)
    .documentReferences.value;
  const usedDocumentsAtom = useMemo(
    () =>
      atom((get) => {
        return docReferences.flatMap((el) => {
          const doc = get(el.row.formAtom);
          const docId = doc.document.value?.id;
          return docId ?? [];
        });
      }),
    [docReferences]
  );
  const documentReferencesUpdateAtom = useMemo(
    () => createUpdateTrackerAtom('documentReferences'),
    []
  );
  return (
    <>
      <FormRowManager<ManagedFormType>
        MemoedComponent={CurativeRequirementMemo}
        field={'documentReferences'}
        label="Add Document Reference"
        initEmptyForm={initEmptyForm}
        customAddButton={(onAdd) => {
          return (
            <>
              <DocumentReferencesModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                usedDocumentsAtom={usedDocumentsAtom}
                onAddDocument={(doc) => {
                  onAdd(initEmptyForm(doc));
                }}
              />
              <Button
                kind="tertiary"
                size="md"
                renderIcon={Add16}
                onClick={() => setModalOpen(true)}
              >
                Add Document Reference
              </Button>
            </>
          );
        }}
      />
      <ReviewSectionCheckbox
        updateTrackingAtom={documentReferencesUpdateAtom}
        field={'documentReferencesReviewed'}
        label="Document References Reviewed"
        helpMessage="Editing any document references will change the review status to unreviewed"
      />
    </>
  );
};

export { DocumentReferenceList };
