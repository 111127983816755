import { atom, Getter, PrimitiveAtom, Setter, WritableAtom } from 'jotai';
import { isObject } from 'lodash';
import { FormRowStatus } from './create-resource-atom';
import { EnsureNonFunction, NonFunction } from 'lib/types';

/**
 * Given a form object, it wraps the value types within an AtomField.
 * If the value extends an array of objects, we wrap the array and recursively
 * wrap the object members to support form inside forms, like a list of grantors
 * inside a transaction.
 */
export type AtomizedForm<Obj> = {
  [Prop in keyof Obj]: Obj[Prop] extends any[]
    ? Obj[Prop][0] extends Record<string, any>
      ? AtomField<Array<AtomizedRowForm<Obj[Prop][0]>>>
      : AtomField<Obj[Prop]>
    : Obj[Prop] extends Record<string, any>
    ? AtomizedForm<Obj[Prop]>
    : AtomField<EnsureNonFunction<Obj[Prop]>>;
};
export type AtomizedRowForm<V> = AtomizedForm<V> & {
  _rowStatus: AtomField<FormRowStatus>;
};
export type AtomFieldType<T> = T extends AtomField<infer A> ? A : never;
export type ExtractAtom<T> = T extends AtomField<infer A> ? A : never;

export type AddDestroyProp<T> = T extends Array<infer U>
  ?
      | Array<U extends object ? U & { _destroy: boolean | undefined } : U>
      | undefined
  : T extends object
  ? { [K in keyof T]: AddDestroyProp<T[K]> }
  : T;

// Helper that gets the values (unwrapped from the atoms) from an AtomizedForm object
const getFormValues = <Obj>(
  atoms: AtomizedForm<Obj>,
  get: Getter,
  collect: 'all-values' | 'dirty-values'
): AddDestroyProp<Obj> => {
  const values = Object.fromEntries(
    Object.entries<object>(atoms).map(([k, v]) => {
      if ('read' in v && 'write' in v) {
        const field = get(v as AtomField<any>);
        const fieldValue = field.value;
        const isDirty = field.isDirty;
        if (Array.isArray(fieldValue)) {
          if (fieldValue.length && isObject(fieldValue[0])) {
            return [k, fieldValue.map((el) => getFormValues(el, get, collect))];
          }
          return [
            k,
            isDirty || collect === 'all-values' ? fieldValue : undefined,
          ];
        }

        if (k === '_rowStatus' && fieldValue === 'trash') {
          return ['_destroy', true];
        }

        if (k === '_rowStatus') {
          return [k, undefined];
        }

        /**
         * We usually have an `id` field used to identify a stored record.
         * That `id` is used to update the record on a subsequent operation if needed.
         * The `id` is never dirty so we want to always deliver that `id` when collecting
         * form values.
         */
        if (isDirty || collect === 'all-values' || k === 'id') {
          return [k, fieldValue];
        }

        return [k, undefined];
      } else {
        return [k, getFormValues(v as AtomizedForm<unknown>, get, collect)];
      }
    })
  );
  return values as AddDestroyProp<Obj>;
};

const collectFormState = <Obj>(
  atoms: AtomizedForm<Obj>,
  get: Getter,
  mapperFn: (v: FieldState<unknown>) => unknown
): unknown[] => {
  return Object.values<object>(atoms)
    .map((v) => {
      if ('read' in v && 'write' in v) {
        const field = get(v as AtomField<any>);
        const state = mapperFn(field);
        const value = field.value;
        if (Array.isArray(value)) {
          if (value.length && isObject(value[0])) {
            return value
              .map((el) => collectFormState(el, get, mapperFn))
              .flat();
          }
          return state;
        }
        return state;
      } else {
        return collectFormState(v, get, mapperFn);
      }
    })
    .flat();
};

const runValidations = <Obj>(
  atoms: AtomizedForm<Obj>,
  get: Getter,
  set: Setter
) => {
  Object.values<object>(atoms).map((v) => {
    if ('read' in v && 'write' in v) {
      const field = get(v as AtomField<any>);
      const value = field.value;
      if (Array.isArray(value)) {
        if (value.length && isObject(value[0])) {
          return value.forEach((el) => runValidations(el, get, set));
        }
      }
      // Trigger the validation with the current value
      set(v as AtomField<any>, value);
    } else {
      runValidations(v, get, set);
    }
  });
};

// Returns an atom that subscribe to all the form changes and returns the same
// object with all form values unwrapped
const getFormValuesAtom = <Obj>(
  form: PrimitiveAtom<AtomizedForm<Obj>>,
  collect: 'all-values' | 'dirty-values' = 'dirty-values'
) => {
  return atom((get: Getter) => {
    return getFormValues(get(form), get, collect);
  });
};

const getFormIsInvalidAtom = <Obj>(form: PrimitiveAtom<AtomizedForm<Obj>>) => {
  return atom((get: Getter) => {
    return collectFormState(get(form), get, (v) => v.isValid).some((el) => !el);
  });
};

const getFormHasChanges = <Obj>(form: PrimitiveAtom<AtomizedForm<Obj>>) => {
  return atom((get: Getter) => {
    return collectFormState(get(form), get, (v) => v.isDirty).some((el) => el);
  });
};

const getFormErrorsAtom = <Obj>(form: PrimitiveAtom<AtomizedForm<Obj>>) => {
  return atom((get: Getter) => {
    return collectFormState(get(form), get, (v) => {
      return !v.isValid ? v.error : '';
    }).filter((el) => el) as string[];
  });
};

const validateAllFields = <Obj>(form: PrimitiveAtom<AtomizedForm<Obj>>) => {
  return atom(null, (get, set) => {
    runValidations(get(form), get, set);
  });
};

export type CommonState<Value> = {
  value: Value;
  isDirty: boolean;
};

export type ErrorState = { isValid: true } | { isValid: false; error: string };

export type FieldState<Value> = CommonState<Value> & ErrorState;

type Options<Value> = {
  areEqual?: (a: Value, b: Value) => boolean;
  validate?: (value: Value) => Value;
  dirty?: boolean;
};

type SetActionOptions = {
  skipDirtyCheck: boolean;
};

type SetAction<Value extends NonFunction> =
  | Value
  | ((prev: Value, get: Getter) => Value)
  | { validate: (value: Value) => Value }
  | { value: Value; __options: SetActionOptions };

export type AtomField<Value extends NonFunction> = WritableAtom<
  FieldState<Value>,
  [SetAction<Value>],
  void
>;

function atomField<Value extends NonFunction>(
  initialValue: Value,
  options?: Options<Value>
): AtomField<Value> {
  const { areEqual = Object.is, validate = (v: Value) => v } = options || {};
  let initialState: FieldState<Value>;
  let validateFn = validate;
  try {
    validate(initialValue);
    initialState = {
      value: initialValue,
      isDirty: options?.dirty ?? false,
      isValid: true,
    };
  } catch (error: any) {
    initialState = {
      value: initialValue,
      isDirty: false,
      isValid: false,
      error:
        typeof error === 'object' && error && 'message' in error
          ? error.message
          : 'Unknown error',
    };
  }
  const baseAtom = atom(initialState);
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, action: SetAction<Value>) => {
      const prevState = get(baseAtom);

      const tryValue = (value: Value, options?: SetActionOptions) => {
        try {
          const validatedValue = validateFn(value);
          const nextState: FieldState<Value> = {
            value: validatedValue,
            isDirty:
              options && options.skipDirtyCheck
                ? false
                : !areEqual(initialValue, validatedValue),
            isValid: true,
          };
          set(baseAtom, nextState);
        } catch (error: any) {
          const nextState: FieldState<Value> = {
            ...prevState,
            value,
            isValid: false,
            error:
              typeof error === 'object' && error && 'message' in error
                ? error.message
                : 'Unknown error',
          };
          set(baseAtom, nextState);
        }
      };

      let nextValue: Value;

      if (typeof action === 'function') {
        nextValue = action(prevState.value, get);
      } else if (isObject(action)) {
        if ('__options' in action) {
          const options = action.__options;
          tryValue(action.value, options);
          return;
        }

        if ('validate' in action) {
          validateFn = action.validate;
          tryValue(prevState.value);
          return;
        }

        nextValue = action;
      } else {
        nextValue = action;
      }

      tryValue(nextValue);
    }
  );
  return derivedAtom;
}

const getError = (field: FieldState<unknown>) => {
  if (!field.isValid) {
    return field.error;
  }
  return null;
};

const required = <V>(v: V, message = 'This field is required') => {
  if (v === null || v === undefined || (typeof v === 'string' && v === ''))
    throw new Error(message);
  return v;
};

const requiredElements = <V>(
  v: V[],
  message = 'At least one element is required'
) => {
  if (!v.length) throw new Error(message);
  return v;
};

export {
  atomField,
  getError,
  getFormErrorsAtom,
  getFormHasChanges,
  getFormIsInvalidAtom,
  getFormValues,
  getFormValuesAtom,
  required,
  requiredElements,
  validateAllFields,
};
