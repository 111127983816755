import { rootResourceAtom, rootStore } from 'atoms/root';
import { Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

interface Props {
  initialValues: any;
  children: JSX.Element;
}

const HydrateAtoms = ({ initialValues, children }: Props) => {
  useHydrateAtoms(initialValues);
  return children;
};

const ProvideRootResource = ({
  children,
  initialValues,
}: {
  initialValues?: any;
  children: JSX.Element;
}) => {
  const rootResource = rootStore.get(rootResourceAtom);
  return (
    <Provider>
      <HydrateAtoms
        initialValues={[[rootResourceAtom, rootResource]].concat(
          initialValues || []
        )}
      >
        {children}
      </HydrateAtoms>
    </Provider>
  );
};

export { HydrateAtoms, ProvideRootResource };
