import { WellsTable } from 'components/wells-table';
import { atomWithStorage } from 'jotai/utils';
import { getMapDefaultConfig } from 'components/map/atoms';
import { TitleWorkspaceResponse } from 'types/api-responses';
import { WorkspaceMap } from 'components/workspace-map';

const WellIndex = ({ workspaceId }: { workspaceId: number }) => {
  return <WellsTable withinTitleWorkspaceId={workspaceId} />;
};

const mapConfigAtom = atomWithStorage(
  'insights-workspace-well-index-map-config',
  getMapDefaultConfig()
);

const TitleWorkspaceWellsIndexPage = ({
  titleWorkspace,
}: {
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const referenceLocation = titleWorkspace.referenceLocation;
  const workspaceId = titleWorkspace.id;

  return (
    <div>
      <WorkspaceMap
        mapConfigAtom={mapConfigAtom}
        referenceLocationHref={referenceLocation.href}
      />
      <WellIndex workspaceId={workspaceId} />
    </div>
  );
};

export { TitleWorkspaceWellsIndexPage };
