import { memo, useMemo, useEffect, useRef } from 'react';
import { FormRow } from 'components/forms';
import {
  Column,
  ComboBox,
  Grid,
  Row,
  TextInput,
} from 'carbon-components-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';

import { QuarterCalls } from 'components/quarter-calls';
import { StatusIndicator } from 'components/status-indicator';

import { initEmptyForm, sortLocationResults } from './utils';

import searchAtomPair, { SearchParams } from 'atoms/search-atom';
import { createUpdateTrackerAtom, documentAtom } from '../atoms';
import { documentLocationsAtom, usedReferencedLocationsAtom } from './atoms';

import {
  ApplicableAreaForm,
  ApplicableFormAtoms,
  LocationAugmented,
} from './types';
import type { LocationResponse } from 'types/api-responses';

import { DeleteRowButton } from '../delete-row-button';
import { FormRowManager } from '../form-row-manager';
import { getError } from 'atoms/create-resource-atom';
import { ReviewSectionCheckbox } from '../review-section-checkbox';
import { shouldSkipSearch } from 'utils/forms';

function ResultOption(result: LocationAugmented) {
  const { confirmed, associatedToDocument } = result;
  const state =
    confirmed || associatedToDocument
      ? confirmed
        ? 'confirmed'
        : 'notConfirmed'
      : false;

  const qCalls = result.quarterCalls.length
    ? `(${result.quarterCalls.join(',')})`
    : '';

  return (
    <StatusIndicator title={result.referenceLocation.name} state={state}>
      {result.referenceLocation.name} {qCalls}
    </StatusIndicator>
  );
}

interface Props {
  atomIdx: number;
  atoms: ApplicableFormAtoms;
  onDelete?: (idx: number) => void;
}

interface LocationsSearchParams extends SearchParams {
  documentId?: number;
}

const { searchAtom, resetSearchDataAtom } = searchAtomPair<
  LocationResponse,
  LocationsSearchParams
>('locations');

const ApplicableArea = ({ atomIdx, onDelete, atoms }: Props) => {
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const status = useAtomValue(atoms.status);

  const document = useAtomValue(documentAtom);
  const usedReferences = useAtomValue(usedReferencedLocationsAtom);
  const documentLocations = useAtomValue(documentLocationsAtom);

  const [query, setPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const debouncedSetPayload = useMemo(
    () => debounce(setPayload, 400),
    [setPayload]
  );

  return (
    <FormRow toTrash={status === 'trash'}>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column sm={2} md={6} lg={10}>
            <ComboBox<LocationAugmented>
              id={`${atomIdx}-reference-location`}
              titleText="Survey Area"
              items={sortLocationResults(
                query?.data?.results.length
                  ? query?.data?.results
                  : fields.referenceLocation.value
                  ? [fields.referenceLocation.value]
                  : undefined,
                usedReferences,
                documentLocations
              )}
              placeholder=""
              itemToString={(data) => {
                return data?.referenceLocation.name || '';
              }}
              itemToElement={ResultOption}
              selectedItem={fields.referenceLocation.value}
              onChange={({ selectedItem }) => {
                setField({
                  field: 'referenceLocation',
                  value: selectedItem ?? null,
                });
              }}
              onInputChange={(text) => {
                if (shouldSkipSearch(firstRender.current, status)) return;
                resetSearchData(undefined);
                debouncedSetPayload((current) => ({
                  ...current,
                  text,
                  documentId: document?.id,
                  pageSize: 10,
                }));
              }}
              invalid={
                !!getError<ApplicableAreaForm>(fields, 'referenceLocation')
              }
              invalidText={getError<ApplicableAreaForm>(
                fields,
                'referenceLocation'
              )}
              disabled={status === 'trash'}
              light
            />
          </Column>
          <Column sm={2} md={2} lg={6}>
            <QuarterCalls
              light
              items={fields.referenceLocation.value?.quarterCalls}
              labelText="Quarter Call"
              placeholder="Quarter Call"
              id={`${atomIdx}-quarter-calls`}
              disabled={!fields.referenceLocation.value || status === 'trash'}
              onRemove={(quarterCall) => {
                const current = fields.referenceLocation.value;
                if (!current) return;
                setField({
                  field: 'referenceLocation',
                  value: {
                    ...current,
                    quarterCalls: current.quarterCalls.filter(
                      (el) => el !== quarterCall
                    ),
                  },
                });
              }}
              onAdd={(quarterCall) => {
                setField((current) => {
                  const location = current.referenceLocation.value;
                  if (
                    location &&
                    !location.quarterCalls.includes(quarterCall)
                  ) {
                    return {
                      field: 'referenceLocation',
                      value: {
                        ...location,
                        quarterCalls: [...location.quarterCalls, quarterCall],
                      },
                    };
                  }
                  return {
                    field: 'referenceLocation',
                    value: location,
                  };
                });
              }}
            />
          </Column>
        </Row>

        <Row>
          <Column sm={1} md={3} lg={5}>
            <TextInput
              light
              id={`${atomIdx}-start-depth`}
              labelText="Start Depth (ft)"
              placeholder="0"
              value={fields.startDepthInFeet.value ?? ''}
              disabled={status === 'trash'}
              onChange={(event) =>
                setField({
                  field: 'startDepthInFeet',
                  value: event.target.value,
                })
              }
              invalid={
                !!getError<ApplicableAreaForm>(fields, 'startDepthInFeet')
              }
              invalidText={getError<ApplicableAreaForm>(
                fields,
                'startDepthInFeet'
              )}
            />
          </Column>
          <Column sm={1} md={3} lg={5}>
            <TextInput
              light
              id={`${atomIdx}-end-depth`}
              labelText="End Depth (ft)"
              placeholder="∞"
              value={fields.endDepthInFeet.value || ''}
              disabled={status === 'trash'}
              onChange={(event) =>
                setField({
                  field: 'endDepthInFeet',
                  value: event.target.value,
                })
              }
              invalid={!!getError<ApplicableAreaForm>(fields, 'endDepthInFeet')}
              invalidText={getError<ApplicableAreaForm>(
                fields,
                'endDepthInFeet'
              )}
            />
          </Column>
          <Column sm={1} md={2} lg={2}>
            <DeleteRowButton
              statusAtom={atoms.status}
              atomIdx={atomIdx}
              onDelete={onDelete}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

const ApplicableAreaMemo = memo(ApplicableArea);

type ManagedForType = {
  field: 'locationReferences';
  value: ApplicableFormAtoms[];
};
const ApplicableAreaList = () => {
  const applicableAreaUpdateAtom = useMemo(
    () => createUpdateTrackerAtom('locationReferences'),
    []
  );
  return (
    <>
      <FormRowManager<ManagedForType>
        MemoedComponent={ApplicableAreaMemo}
        field={'locationReferences'}
        label="Add Applicable Area"
        initEmptyForm={initEmptyForm}
      />
      <ReviewSectionCheckbox
        updateTrackingAtom={applicableAreaUpdateAtom}
        field={'applicableAreasReviewed'}
        label="Applicable Areas Reviewed"
        helpMessage="Editing any survey areas, quarter calls, and depth ranges will change the review status to unreviewed"
      />
    </>
  );
};

export { ApplicableAreaList };
