import { buildPolygonStyle, POLYGON_UNSELECTED_COLOR } from 'lib/map';
import { Layer, Source } from 'react-map-gl';
import { GeoJSON } from 'geojson';

const STYLE = buildPolygonStyle({ color: POLYGON_UNSELECTED_COLOR });

interface Props {
  geometry?: GeoJSON.Geometry;
  id: number | string;
  color?: string;
  style?: typeof STYLE;
}

const PolygonArea = ({ geometry, id, color, style }: Props) => {
  style = !style ? (!color ? STYLE : buildPolygonStyle({ color })) : style;
  return geometry &&
    ['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(
      geometry.type
    ) ? (
    <Source
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: geometry,
            properties: null,
            id: id,
          },
        ],
      }}
    >
      <Layer
        key={`fill-${id}`}
        id={`polygon-fill-${id}`}
        type="fill"
        paint={style.fill}
      />
      <Layer
        key={`line-${id}`}
        id={`polygon-line-${id}`}
        type="line"
        paint={style.line}
      />
    </Source>
  ) : null;
};

export default PolygonArea;
