import { Column } from 'carbon-components-react';
import { Date } from 'components/date';
import { DefinitionList } from 'components/definition-list';
import { DefinitionDescription } from 'components/definition-list/definition-description';
import { DefinitionTerm } from 'components/definition-list/definition-term';
import { useAtomValue } from 'jotai';
import { DocumentResponse } from 'types/api-responses';

import style from './document-attributes.module.scss';
import { useStatesAtom } from './atoms';
import { resourcesAtom } from 'atoms/root';

const NULL_DISPLAY_VALUE = '--';

const CountyName = ({ county }: { county: DocumentResponse['county'] }) => {
  const resources = useAtomValue(resourcesAtom);
  const statesAtom = useStatesAtom(resources?.states.href);
  const states = useAtomValue(statesAtom);
  if (county) {
    if (states.data && 'error' in states.data) return null;
    const state = states.data?.find((state) => state.id === county.stateId);
    return <>{`${county.name}, ${state?.name || county.state.abbreviation}`}</>;
  } else {
    return <>{`${NULL_DISPLAY_VALUE}`}</>;
  }
};

const DocumentAttributes = ({
  document,
  singleColumn,
}: {
  document: DocumentResponse;
  singleColumn?: boolean;
}) => {
  return (
    <>
      <div
        className={
          singleColumn ? style.documentSingleColumn : style.documentTwoColumns
        }
      >
        <Column className={style.documentList}>
          <DefinitionList>
            <DefinitionTerm>County</DefinitionTerm>
            <DefinitionDescription>
              <span>
                <CountyName county={document.county} />
              </span>
            </DefinitionDescription>
          </DefinitionList>
          <DefinitionList>
            <DefinitionTerm>Book Type</DefinitionTerm>
            <DefinitionDescription>
              <span>{document.bookType || NULL_DISPLAY_VALUE}</span>
            </DefinitionDescription>
          </DefinitionList>
          <DefinitionList>
            <DefinitionTerm>Instrument Number</DefinitionTerm>
            <DefinitionDescription>
              <span>{document.instrumentNumber || 'Unknown'}</span>
            </DefinitionDescription>
          </DefinitionList>
          <DefinitionList>
            <DefinitionTerm>Document Type</DefinitionTerm>
            <DefinitionDescription>
              <span>{document.documentType || NULL_DISPLAY_VALUE}</span>
            </DefinitionDescription>
          </DefinitionList>
        </Column>
        <Column className={style.documentList}>
          <DefinitionList>
            <DefinitionTerm noBorder>Effective Date</DefinitionTerm>
            <DefinitionDescription>
              <span>
                <Date date={document.effectiveDate} fallback="(none)" />
              </span>
            </DefinitionDescription>
          </DefinitionList>
          <DefinitionList>
            <DefinitionTerm noBorder>Instrument Date</DefinitionTerm>
            <DefinitionDescription>
              <span>
                <Date date={document.instrumentDate} fallback="(none)" />
              </span>
            </DefinitionDescription>
          </DefinitionList>
          <DefinitionList>
            <DefinitionTerm noBorder>File Date</DefinitionTerm>
            <DefinitionDescription>
              <span>
                <Date date={document.fileDate} fallback="(none)" />
              </span>
            </DefinitionDescription>
          </DefinitionList>
          <DefinitionList>
            <DefinitionTerm noBorder>Recorded Date</DefinitionTerm>
            <DefinitionDescription>
              <span>
                <Date date={document.recordedDate} fallback="(none)" />
              </span>
            </DefinitionDescription>
          </DefinitionList>
        </Column>
      </div>
    </>
  );
};

export { DocumentAttributes };
