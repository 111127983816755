import { TableCell, TableRow } from 'carbon-components-react';
import classNames from 'classnames';
import { TrashButton } from 'components/trash-button';
import { FC } from 'react';

import style from './removable-row.module.scss';

interface Props {
  removable?: boolean;
  className?: string;
  markedForDestruction: boolean;
  onRemove: (rowIndex: number) => void;
  index: number;
}

const RemovableRow: FC<Props> = ({
  removable = true,
  onRemove,
  className,
  children,
  markedForDestruction,
  index,
  ...rest
}) => {
  className = classNames(className, style.removable, {
    [style.markedForDestruction]: markedForDestruction,
  });

  return (
    <>
      <TableRow className={className} {...rest}>
        {children}
        <TableCell align="right">
          {removable && (
            <TrashButton
              className={style.destroy}
              kind={markedForDestruction ? 'danger' : 'ghost'}
              size="small"
              description="Remove"
              onClick={() => {
                onRemove(index);
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export { RemovableRow };
