import { useHistory } from 'react-router-dom';
import { Calculation } from 'pages/title-workspaces/overview/calculation';
import {
  Button,
  InlineLoading,
  InlineNotification,
  Tile,
} from 'carbon-components-react';
import ChainSvg from 'images/srp-illustration-chain.svg';
import { Add16 } from '@carbon/icons-react';
import style from './overview-page.module.scss';
import { EmptyState } from 'components/empty-state';
import { useAtomValue } from 'jotai';
import { useTitleWorkspaceAtom } from '../atoms';

const StartInterestButton = ({ href }: { href: string }) => {
  const history = useHistory();
  return (
    <Button
      kind="tertiary"
      size="md"
      renderIcon={Add16}
      onClick={() => history.push(href)}
    >
      Add Starting Interest
    </Button>
  );
};

const TitleWorkspaceOverviewPage = ({
  titleWorkspaceHref,
}: {
  titleWorkspaceHref: string;
}) => {
  const titleWorkspaceAtom = useTitleWorkspaceAtom(titleWorkspaceHref);
  const titleWorkspace = useAtomValue(titleWorkspaceAtom);

  if (titleWorkspace.data && 'error' in titleWorkspace.data)
    return (
      <InlineNotification
        kind="error"
        title={titleWorkspace.data.error}
        lowContrast
      />
    );
  if (titleWorkspace.loading || !titleWorkspace.data) return <InlineLoading />;
  if (!titleWorkspace.data) return null;

  const titleWorkspaceData = titleWorkspace.data;
  const startingInterestDate = titleWorkspaceData.startingInterestDate;
  return (
    <div className={style.page}>
      {startingInterestDate ? (
        <Calculation
          titleWorkspace={titleWorkspaceData}
          titleWorkspaceAtom={titleWorkspaceAtom}
        />
      ) : (
        <>
          {!startingInterestDate && (
            <Tile className={style.emptyPanel}>
              <EmptyState
                icon={ChainSvg}
                headerText="No Title Chain to Calculate"
                helperText="To calculate the area covered, add starting interest and then calculate."
              />
              <div className={style.centerButtons}>
                <StartInterestButton
                  href={titleWorkspaceData.resources.startingInterest.href}
                />
              </div>
            </Tile>
          )}
        </>
      )}
    </div>
  );
};

export { TitleWorkspaceOverviewPage };
