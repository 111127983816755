import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import { TitleWorkspaceResponse } from 'types/api-responses';

export const useTitleWorkspaceAtom = (titleWorkspaceHref: string) =>
  useMemo(
    () => createResourceAtom<TitleWorkspaceResponse>(titleWorkspaceHref),
    [titleWorkspaceHref]
  );

export type TitleWorkspaceAtomType = ReturnType<typeof useTitleWorkspaceAtom>;
