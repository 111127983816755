import { Grid, Button } from 'carbon-components-react';
import { CopyFile20 } from '@carbon/icons-react';
import { PageHeader } from 'components/page-header';
import { useLocation, useHistory } from 'react-router-dom';
import { EntityResponse } from 'types/api-responses';
import { EntityTabs } from './entity-tabs';
import style from './page.module.scss';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import { EntitiesAnchorJumpMenu, EntityForm } from 'components/entity-form';

const PageContent = ({
  entity,
  getEntity,
  loading,
}: {
  entity: EntityResponse | undefined;
  getEntity: () => Promise<void>;
  loading: boolean;
}) => {
  const entityType = entity ? entity.type : undefined;

  return (
    <Grid className={style.entityPageContainer}>
      <EntitiesAnchorJumpMenu />
      <EntityForm
        key={entityType}
        entity={entity}
        getEntity={getEntity}
        loading={loading}
        entityTypeValue={entity ? entity.type : undefined}
      />
      {entity && <EntityTabs entity={entity} />}
    </Grid>
  );
};

const Header = ({ entity }: { entity: EntityResponse | undefined }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  return (
    <PageHeader
      breadcrumbs={[
        ['/entities', 'Entities'],
        [pathname, 'Entity Profile'],
      ]}
      title={entity?.name || ''}
      stickyHeader
      actions={
        entity ? (
          <Button
            renderIcon={CopyFile20}
            size="sm"
            onClick={() =>
              history.push(`${entity.resources.deduplication.href}`)
            }
          >
            <span>Deduplicate</span>
          </Button>
        ) : null
      }
    />
  );
};

const ShowEntityPage = ({
  entity,
  loading,
  getEntity,
}: {
  entity: EntityResponse | undefined;
  loading: boolean;
  getEntity: () => Promise<void>;
}) => {
  return (
    <>
      <Header entity={entity} />
      {entity && (
        <Helmet>
          <title>{pageTitle(`${entity.name} | Entity Profile`)}</title>
        </Helmet>
      )}
      <PageContent entity={entity} getEntity={getEntity} loading={loading} />
    </>
  );
};

export { ShowEntityPage };
