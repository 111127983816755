import {
  Button,
  InlineNotification,
  TextArea,
  Tile,
} from 'carbon-components-react';
import { useState } from 'react';
import { Mineral, Surface } from './output';
import { useAtomValue, useSetAtom } from 'jotai';
import { runTitleAtom } from './atoms';

import style from './page.module.scss';

const DEFAULT_INPUT = `
parties:
  texas: State of Texas
  rr: Texas and Pacific Railroad Company
starting_interest:
  surface:
    texas: 1
transactions:
  - type: conveyance
    from: texas
    to:
      - party: rr
        surface:
          interest: 1
`.trim();

const Form = () => {
  const [value, setValue] = useState(DEFAULT_INPUT);
  const runTitle = useSetAtom(runTitleAtom);
  return (
    <>
      <TextArea
        labelText="Input YAML"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        rows={20}
        className={style.input}
        spellCheck={false}
      />
      <Button onClick={() => runTitle(value)}>Calculate</Button>
    </>
  );
};

const Output = () => {
  const calculator = useAtomValue(runTitleAtom);

  if (!calculator) return null;

  if ('error' in calculator) {
    return (
      <InlineNotification
        kind="error"
        title="Error"
        subtitle={calculator.error}
        lowContrast
      />
    );
  }

  return (
    <>
      <section className={style.output}>
        <Surface calculator={calculator} />
        <Mineral calculator={calculator} />
        <Tile className={style.graph}>
          <img src={calculator.imageHref} />
        </Tile>
      </section>
    </>
  );
};

const TitleChainCalculatorPage = () => {
  return (
    <>
      <Form />
      <Output />
    </>
  );
};

export { TitleChainCalculatorPage };
