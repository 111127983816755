import classNames from 'classnames';
import { ReactNode } from 'react';
import {
  DefinitionList,
  DefinitionListProps,
} from 'components/definition-list';
import { DefinitionTerm } from 'components/definition-list/definition-term';
import { DefinitionDescription } from 'components/definition-list/definition-description';

import style from './field.module.scss';
import { Date } from 'components/date';

interface Props extends DefinitionListProps {
  label: string;
  value?: ReactNode;
  truncate?: boolean;
}

const FlexField = ({ label, ...props }: Props) => {
  return <Field label={label} className={style.flexField} {...props} />;
};

const BlockField = ({ label, ...props }: Props) => {
  return <Field label={label} className={style.blockField} {...props} />;
};

const DateField = ({
  label,
  date,
  ...rest
}: Props & { date: string | null }) => {
  return (
    <FlexField
      label={label}
      value={date ? <Date date={date} /> : null}
      {...rest}
    />
  );
};

const Field = ({ label, value, truncate = false, ...rest }: Props) => {
  return (
    <DefinitionList {...rest}>
      <DefinitionTerm className={style.fieldLabel}>{label}</DefinitionTerm>
      <DefinitionDescription
        className={classNames(style.fieldValue, { [style.truncate]: truncate })}
      >
        {value ?? <>&mdash;</>}
      </DefinitionDescription>
    </DefinitionList>
  );
};

export { FlexField, BlockField, Field, DateField };
