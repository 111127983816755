import classNames from 'classnames';
import { FC } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import style from './link.module.scss';

interface Props extends LinkProps {
  truncate?: boolean;
}

const Link: FC<Props> = ({
  truncate = false,
  children,
  className,
  ...rest
}) => {
  return (
    <RouterLink
      className={classNames(className, style.link, {
        [style.truncate]: truncate,
      })}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};

const PlainLink = (
  props: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    className?: string;
    truncate?: boolean;
  }
) => (
  <a
    {...props}
    className={classNames(props.className, style.link, {
      [style.truncate]: props.truncate,
    })}
  />
);

export { Link, PlainLink };
