import { InlineNotification, Modal, ModalProps } from 'carbon-components-react';
import style from './destroy-confirmation.module.scss';
interface DestroyConfirmationProps extends ModalProps {
  error?: string;
}

const DestroyConfirmation = ({
  open,
  onRequestSubmit,
  onRequestClose,
  error,
  ...rest
}: DestroyConfirmationProps) => {
  return (
    <Modal
      open={open}
      modalHeading="Are you sure you want to delete this?"
      danger
      primaryButtonText="Yes, delete"
      secondaryButtonText="No, cancel"
      onRequestClose={onRequestClose}
      onRequestSubmit={onRequestSubmit}
      {...rest}
    >
      {error ? (
        <InlineNotification
          aria-label="closes notification"
          kind="error"
          statusIconDescription="notification"
          title={error}
          subtitle={'Please try again!'}
          hideCloseButton
          className={style.customNotification}
        />
      ) : (
        'It will be removed immediately. You can’t undo this action.'
      )}
    </Modal>
  );
};

export { DestroyConfirmation };
