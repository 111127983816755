import { ToastNotification } from 'carbon-components-react';

import style from './error-toast.module.scss';

const TIMEOUT = 5000;

interface Props {
  message?: string;
  onClose?: () => boolean;
}

const ErrorToast = ({ message, onClose }: Props) => {
  return (
    <div className={style.errorToast}>
      <ToastNotification
        title={message ?? 'Something went wrong'}
        kind="error"
        lowContrast
        onClose={onClose}
        timeout={TIMEOUT}
      />
    </div>
  );
};

export default ErrorToast;
