import { atomWithStorage } from 'jotai/utils';

const documentIndexSortPreferenceAtom = atomWithStorage<{
  sortAttribute: string;
  sortDirection: 'ASC' | 'DESC';
}>(
  'document-index-sort-preference',
  {
    sortAttribute: 'instrument_date',
    sortDirection: 'DESC',
  },
  undefined,
  { getOnInit: true }
);

export { documentIndexSortPreferenceAtom };
