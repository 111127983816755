import { Document20 } from '@carbon/icons-react';
import { DocumentAttributes } from 'components/document-attributes';
import { DocumentResponse } from 'types/api-responses';

import style from './document-preview.module.scss';

const DocumentPreview = ({ data }: { data: DocumentResponse }) => {
  return (
    <div>
      <div className={style.titleArea}>
        <div className={style.iconArea}>
          <Document20 />
        </div>
        <div className={style.popName}>
          {data.documentTitle} <p>Document</p>
        </div>
      </div>
      <DocumentAttributes document={data} singleColumn={true} />
    </div>
  );
};

export { DocumentPreview };
