import { atomWithStorage } from 'jotai/utils';
import { startCase } from 'lodash';
import {
  TransactionQueryResponse,
  TransactionResponse,
} from 'types/api-responses';

const DEFAULT_PAGE_SIZE = 64;

const renderTransactionType = (
  transaction: TransactionResponse | TransactionQueryResponse
) => {
  const transactionType = transaction['type'];
  return startCase(transactionType.split('::').pop());
};

const numberFormat = new Intl.NumberFormat('en-US');
const format = {
  number(number: unknown) {
    return typeof number === 'number' ? numberFormat.format(number) : '';
  },
};

const FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const DECIMAL_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const DECIMAL_FORMATTER_TWO_FRACTIONS = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const PERCENT_FORMATTER_TWO_DECIMALS = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const pageSizeAtom = atomWithStorage(
  'list-pagination-size',
  DEFAULT_PAGE_SIZE,
  undefined,
  { getOnInit: true }
);

export {
  renderTransactionType,
  format,
  FORMATTER,
  DECIMAL_FORMATTER,
  DECIMAL_FORMATTER_TWO_FRACTIONS,
  PERCENT_FORMATTER_TWO_DECIMALS,
  pageSizeAtom,
  DEFAULT_PAGE_SIZE,
};
