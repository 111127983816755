import {
  Button,
  InlineLoading,
  InlineNotification,
  Grid,
  Row,
  Column,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  Close20,
  MapBoundary16,
  CenterCircle16,
  Information16,
} from '@carbon/icons-react';
import { Well as WellIcon } from 'components/icons/well';
import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { mapRefAtom } from 'components/map/map';
import { atom, useAtom, useAtomValue } from 'jotai';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { PackageResponse } from 'types/packages-api/responses';
import { PackagesMap } from '../map/packages-map';
import { ProvideRootResource } from 'components/hydrate-atoms';
import centroid from '@turf/centroid';

import style from './map-isolator.module.scss';
import { createResourceAtom } from 'lib/atoms';
import { WellsResponse } from 'types/api-responses';
import {
  WellHeaderContent,
  WellPageContent,
} from 'components/well-page-content';
import { getMapDefaultConfig } from 'components/map/atoms';
import { LinkWithHover } from 'components/hover-card-resource';

export type MapPoints = Record<
  string,
  {
    id: number;
    href: string;
    name: string | null;
    wells: {
      uwi: string;
      operator: string | null;
      name: string | null;
    }[];
  }[]
>;

const assetsLayerAtom = atom(true);
const developmentAreaLayerAtom = atom(true);
const titleLayerAtom = atom(true);

function sortKeysByWellCount(obj: MapPoints) {
  // Convert object keys to an array of [key, totalWells] pairs
  const pairs = Object.keys(obj).map((key) => {
    const totalWells = obj[key].reduce(
      (acc, current) => acc + current.wells.length,
      0
    );
    return [key, totalWells] as const;
  });

  // Sort the pairs by totalWells, in descending order
  return pairs.sort((a, b) => b[1] - a[1]);
}

function sortKeysByUnitCount(obj: MapPoints) {
  const pairs = Object.keys(obj).map((key) => {
    return [key, obj[key].length] as const;
  });

  // Sort by number of units, in descending order
  return pairs.sort((a, b) => b[1] - a[1]);
}

const mapConfigAtom = atom(getMapDefaultConfig());

const MapIsolator = ({
  packageData,
  mapPoints,
  type,
}: {
  packageData: PackageResponse;
  mapPoints: MapPoints;
  type: 'slots' | 'units';
}) => {
  const [open, setOpen] = useReducer((s) => !s, false);
  const [mapKey, triggerMapRender] = useReducer((s) => s + 1, 0);

  return (
    <>
      <Button
        size="md"
        kind="tertiary"
        hasIconOnly
        onClick={setOpen}
        iconDescription={'Map Tools'}
        tooltipAlignment="center"
        tooltipPosition="top"
        renderIcon={MapBoundary16}
      />
      <Drawer
        lockBackgroundScroll
        open={open}
        direction="bottom"
        size={'90%'}
        onClose={setOpen}
        className={style.mapIsolatorDrawer}
      >
        <DrawerHeader>
          <div className={style.drawerHeader}>
            <h3>Map Tools</h3>
            <Button
              hasIconOnly
              size="lg"
              kind="ghost"
              onClick={setOpen}
              iconDescription={'Close'}
              tooltipAlignment="center"
              tooltipPosition="bottom"
              renderIcon={Close20}
            />
          </div>
        </DrawerHeader>
        <DrawerContent>
          {packageData.boundingBox && open && (
            <ProvideRootResource>
              <>
                <PackagesMap
                  key={mapKey}
                  boundingBox={packageData.boundingBox}
                  mapSources={packageData.resources.maps}
                  layerAtoms={{
                    devAreas: developmentAreaLayerAtom,
                    assets: assetsLayerAtom,
                    title: titleLayerAtom,
                  }}
                  customMapConfigAtom={mapConfigAtom}
                />

                <div className={style.contentHeight}>
                  <MapElements
                    mapPoints={mapPoints}
                    reRenderMap={triggerMapRender}
                    type={type}
                  />
                </div>
              </>
            </ProvideRootResource>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

const MapElements = ({
  mapPoints,
  reRenderMap,
  type,
}: {
  mapPoints: MapPoints;
  reRenderMap: () => void;
  type: 'slots' | 'units';
}) => {
  const mapRef = useAtomValue(mapRefAtom);
  const [assetMap, setAssetMap] = useState<
    Record<string, GeoJSON.Geometry> | undefined
  >(undefined);
  const eventInstalled = useRef(false);
  const [, setVisibleDevAreas] = useAtom(developmentAreaLayerAtom);
  const [, setVisibleAssets] = useAtom(assetsLayerAtom);
  const [, setMapConfig] = useAtom(mapConfigAtom);

  const [currentTract, setCurrentTract] = useState<string | undefined>(
    undefined
  );
  const [currentDevArea, setCurrentDevArea] = useState<string | undefined>(
    undefined
  );
  const [currentWell, setCurrentWell] = useState<string | undefined>(undefined);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    return () => {
      setVisibleAssets(true);
      setVisibleDevAreas(true);
    };
  }, [setVisibleAssets, setVisibleDevAreas]);

  const onRecenterMap = useCallback(() => {
    reRenderMap();
    setCurrentTract(undefined);
    setCurrentDevArea(undefined);
    setCurrentWell(undefined);
    setVisibleAssets(true);
    setVisibleDevAreas(true);
    setMapConfig((current) => ({
      ...current,
      wells: {
        checked: true,
        options: {
          surfaceHole: true,
          bottomHole: true,
          stick: true,
          survey: true,
          vertical: true,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedDevAreas = currentTract ? mapPoints[currentTract] : [];
  const selectedWells = currentDevArea
    ? selectedDevAreas.find((el) => el.name === currentDevArea)?.wells ?? []
    : [];

  const isolateWells = useCallback(
    (well: string[]) => {
      mapRef
        ?.getMap()
        .setFilter('wells-surface-hole-isolated', ['in', 'uwi', ...well]);
      mapRef
        ?.getMap()
        .setFilter('wells-bottom-hole-isolated', ['in', 'uwi', ...well]);
      mapRef
        ?.getMap()
        .setFilter('wells-survey-isolated', ['in', 'uwi', ...well]);
      mapRef
        ?.getMap()
        .setFilter('wells-stick-isolated', ['in', 'uwi', ...well]);
    },
    [mapRef]
  );

  const isolateDevAreas = useCallback(
    (areas: string[]) => {
      mapRef
        ?.getMap()
        .setFilter('development-area-fill-scoped-package-isolated', [
          'in',
          'name',
          ...areas,
        ]);
      mapRef
        ?.getMap()
        .setFilter('development-area-line-scoped-package-isolated', [
          'in',
          'name',
          ...areas,
        ]);
    },
    [mapRef]
  );

  if (!eventInstalled.current) {
    if (!mapRef) return null;

    eventInstalled.current = true;

    const handler = () => {
      if (assetMap) return;

      if (
        mapRef.getSource('package-assets-layer-source') &&
        mapRef.isSourceLoaded('package-assets-layer-source')
      ) {
        const assets = mapRef
          .queryRenderedFeatures(undefined, {
            layers: ['package-assets-fill'],
          })
          .reduce((acc, current) => {
            if (
              current.properties &&
              'coordinates' in current.geometry &&
              !acc[current.properties.name]
            ) {
              acc[current.properties.name] = current.geometry;
            }
            return acc;
          }, {} as Record<string, any>);

        if (Object.keys(assets).length) {
          setAssetMap(assets);
          mapRef.off('sourcedata', handler);
        }
      }
    };
    mapRef.on('sourcedata', handler);
  }

  const highlightAsset = (asset: string) => {
    mapRef?.getMap().moveLayer('package-assets-line-isolated-highlighted');
    mapRef
      ?.getMap()
      .setFilter('package-assets-line-isolated-highlighted', [
        'in',
        'name',
        asset,
      ]);
  };

  const sortedAssetsByWellCount =
    type === 'slots'
      ? sortKeysByWellCount(mapPoints)
      : sortKeysByUnitCount(mapPoints);

  return (
    <Grid className={style.grid} fullWidth>
      <Row className={style.contentList}>
        <Column lg={5}>
          <Row className={style.mapElementsList}>
            <Column className={style.colRelative}>
              {sortedAssetsByWellCount.length ? (
                <Button
                  hasIconOnly
                  className={style.recenterBtn}
                  kind="primary"
                  size="sm"
                  iconDescription="Recenter"
                  tooltipAlignment="center"
                  tooltipPosition="left"
                  renderIcon={CenterCircle16}
                  onClick={() => onRecenterMap()}
                />
              ) : null}
              <ul>
                {sortedAssetsByWellCount.length ? (
                  <legend>Assets</legend>
                ) : null}
                {assetMap &&
                  sortedAssetsByWellCount.map(([el, wells]) => {
                    return (
                      <li
                        key={el}
                        className={currentTract === el ? style.selected : ''}
                      >
                        {assetMap[el] ? (
                          <a
                            onClick={() => {
                              const [longitude, latitude] = centroid(
                                assetMap[el] as any
                              ).geometry.coordinates;

                              setCurrentTract(el);
                              setCurrentDevArea(undefined);
                              setCurrentWell(undefined);
                              isolateWells([]);

                              mapRef?.flyTo({
                                center: [longitude, latitude],
                                zoom: 12,
                              });
                              setVisibleAssets(false);
                              setVisibleDevAreas(false);
                              setMapConfig((current) => ({
                                ...current,
                                wells: {
                                  checked: false,
                                  options: {
                                    surfaceHole: false,
                                    bottomHole: false,
                                    stick: false,
                                    survey: false,
                                    vertical: false,
                                  },
                                },
                              }));

                              const devAreas = mapPoints[el].map((el) => el);
                              isolateDevAreas(
                                devAreas.map((el) => el.name || '')
                              );
                              isolateWells(
                                devAreas
                                  .flatMap((el) => el.wells)
                                  .map((el) => el.uwi)
                              );
                              highlightAsset(el);

                              mapRef
                                ?.getMap()
                                .setFilter('package-assets-fill-isolated', [
                                  'in',
                                  'name',
                                  el,
                                ]);
                              mapRef
                                ?.getMap()
                                .setFilter('package-assets-line-isolated', [
                                  'in',
                                  'name',
                                  el,
                                ]);
                            }}
                          >
                            {el} <strong>({wells})</strong>
                          </a>
                        ) : (
                          el
                        )}
                      </li>
                    );
                  })}
              </ul>
            </Column>

            <Column className={style.colRelative}>
              <ul>
                {selectedDevAreas.length ? (
                  <legend>Development Areas</legend>
                ) : null}
                {selectedDevAreas.map((area) => {
                  const wells =
                    (currentTract ? mapPoints[currentTract] : []).find(
                      (el) => el.name === area.name
                    )?.wells ?? [];

                  return (
                    <li
                      key={area.id}
                      className={
                        currentDevArea === area.name ? style.selected : ''
                      }
                    >
                      {area.name ? (
                        <LinkWithHover kind="development-area" href={area.href}>
                          <a
                            onClick={() => {
                              if (!area.name) return;
                              setCurrentDevArea(area.name);
                              setCurrentWell(undefined);
                              isolateWells(wells.map((el) => el.uwi));
                              isolateDevAreas([area.name]);
                            }}
                          >
                            {area.name} <strong>({wells.length})</strong>
                          </a>
                        </LinkWithHover>
                      ) : (
                        area.name
                      )}
                    </li>
                  );
                })}
              </ul>
            </Column>

            <Column className={style.colRelative}>
              <ul>
                {selectedWells.length ? <legend>Wells</legend> : null}
                {selectedWells.map((well) => (
                  <li
                    key={well.uwi}
                    className={currentWell === well.uwi ? style.selected : ''}
                  >
                    <a
                      onClick={() => {
                        setCurrentWell(well.uwi);
                        isolateWells([well.uwi]);
                      }}
                    >
                      <strong>{well.uwi}</strong>
                      <p>
                        <TooltipDefinition
                          direction="top"
                          align="start"
                          tooltipText="SRP Well Name"
                        >
                          <Information16 />
                        </TooltipDefinition>
                        <strong>{well.name}</strong>
                      </p>
                      <p>
                        <TooltipDefinition
                          direction="top"
                          align="start"
                          tooltipText="Current Operator"
                        >
                          <Information16 />
                        </TooltipDefinition>
                        <strong>{well.operator}</strong>
                      </p>
                    </a>
                  </li>
                ))}
              </ul>
            </Column>
          </Row>
        </Column>

        <Column lg={11} className={style.colScroll}>
          {currentWell && (
            <WellInfo uwi={currentWell} href={`/wells/${currentWell}`} />
          )}
        </Column>
      </Row>
    </Grid>
  );
};

const useWellAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellsResponse>(href), [href]);

const WellInfo = ({ uwi, href }: { uwi: string; href: string }) => {
  const wellAtom = useWellAtom(href);
  const well = useAtomValue(wellAtom);

  if (well.data && 'error' in well.data)
    return (
      <InlineNotification kind="error" title={well.data.error} lowContrast />
    );

  return well.data ? (
    <>
      <div className={style.wellHeader}>
        <WellIcon medium hasBackground={true} />
        <h2 className={style.wellTitle}>{uwi}</h2>
      </div>
      <WellHeaderContent well={well.data} />
      <WellPageContent well={well.data} />
    </>
  ) : (
    <InlineLoading />
  );
};

export { MapIsolator };
