import { DragEndEvent, DragMoveEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CSS, Transform } from '@dnd-kit/utilities';
import {
  Cell,
  Column,
  ColumnPinningState,
  Header,
  Row,
} from '@tanstack/react-table';
import { TableCell } from 'carbon-components-react';
import { CSSProperties } from 'react';
import {
  PackageEvaluationAllocationResponse,
  PackageEvaluationSlotAllocationsResponse,
} from 'types/packages-api/responses';
import { MapPoints } from './map-isolator';

const getTotalCells = (
  headersList: { key: string }[],
  cellContentMapping: { [key: string]: JSX.Element },
  className: string
) => {
  const totalCells: JSX.Element[] = [];
  headersList.forEach(({ key }, i) => {
    const cellContent = cellContentMapping[key] || (
      <TableCell key={`empty-total-${i}`} className={className} />
    );
    totalCells.push(cellContent);
  });
  return totalCells;
};

const buildMapPointsFromUnits = (
  allocations: PackageEvaluationAllocationResponse[]
) => {
  const devAreasMap: Record<string, boolean> = {};
  return allocations.reduce((acc, prev) => {
    const assetName = prev.asset.name;
    const devArea = prev.developmentArea;
    const devAreaName = devArea.name;
    if (!assetName || !devArea) return acc;
    if (!devAreaName) return acc;

    if (acc[assetName]) {
      if (!devAreasMap[`${assetName}-${devAreaName}`]) {
        const devAreaWithWells = {
          ...devArea,
          wells: [],
        };
        acc[assetName].push(devAreaWithWells);
        devAreasMap[`${assetName}-${devAreaName}`] = true;
      }
    } else {
      const devAreaWithWells = {
        ...devArea,
        wells: [],
      };
      acc[assetName] = [devAreaWithWells];
      devAreasMap[`${assetName}-${devAreaName}`] = true;
    }
    return acc;
  }, {} as MapPoints);
};

const buildMapPointsFromSlots = (
  allocations: PackageEvaluationSlotAllocationsResponse[]
) => {
  const devAreasMap: Record<string, boolean> = {};
  return allocations.reduce((acc, prev) => {
    const assetName = prev.asset.name;
    const devArea = prev.developmentArea;
    const devAreaName = devArea.name;
    const uwi = prev.well?.uwi;
    const well = uwi
      ? {
          uwi,
          operator: prev.operator.name,
          name: prev.well?.name ?? null,
        }
      : undefined;
    if (!assetName || !devArea) return acc;
    if (!devAreaName) return acc;

    if (acc[assetName]) {
      if (!devAreasMap[`${assetName}-${devAreaName}`]) {
        const devAreaWithWells = {
          ...devArea,
          wells: well ? [well] : [],
        };
        acc[assetName].push(devAreaWithWells);
        devAreasMap[`${assetName}-${devAreaName}`] = true;
      } else {
        const devAreaObj = acc[assetName].find((el) => el.name === devAreaName);
        if (devAreaObj && uwi) {
          const index = devAreaObj.wells.findIndex((el) => el.uwi === uwi);
          if (index < 0 && well) devAreaObj.wells.push(well);
        }
      }
    } else {
      const devAreaWithWells = {
        ...devArea,
        wells: well ? [well] : [],
      };
      acc[assetName] = [devAreaWithWells];
      devAreasMap[`${assetName}-${devAreaName}`] = true;
    }
    return acc;
  }, {} as MapPoints);
};

type ColumnType<TData, TValue> = Column<TData, TValue>;

const getPinningStyles = <TData, TValue>(
  column: ColumnType<TData, TValue>
): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? 'inset -5px 0px 6px -6px rgba(141,141,141,1)'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
};

/**
 * Handles the end of a drag-and-drop event for columns.
 *
 * event: -The drag end event containing information about the active and target columns.
 * columnPinning:  Current state of column pinning.
 * columnOrder: Current order of columns.
 * setColumnOrder: Function to update the column order state.
 * setColumnPinning: Function to update the column pinning state.
 * setHoveredColumnId: Function to clear the hovered column ID.
 */

const handleDragEnd = (
  event: DragEndEvent,
  columnPinning: ColumnPinningState,
  columnOrder: string[],
  setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>,
  setColumnPinning: React.Dispatch<React.SetStateAction<ColumnPinningState>>,
  hoveredColumnIdRef: React.MutableRefObject<string | null>
) => {
  // Extract the active (dragged) and over (target) column IDs from the event
  const { active, over } = event;

  // Check if the drag event is valid and ensure the IDs are strings
  if (
    !active ||
    !over ||
    typeof active.id !== 'string' ||
    typeof over.id !== 'string' ||
    active.id === over.id
  ) {
    return; // Exit if the drag event is invalid or the column hasn't moved
  }

  // Get the IDs of the columns involved in the drag event
  const activeId = active.id;
  const overId = over.id;

  // Get the list of pinned columns on the left
  const leftPinnedColumns = columnPinning.left ?? [];

  // Determine if the dragged column and target column are pinned
  const movingPinnedColumn = leftPinnedColumns.includes(activeId);
  const targetPinnedColumn = leftPinnedColumns.includes(overId);

  // Determine if the dragged and target columns are among the unpinned columns
  const movingUnPinnedColumn = !targetPinnedColumn;
  const targetUnpinnedColumn = !movingPinnedColumn;

  // Check if both dragged and target columns belong to the same group (either pinned or unpinned)
  const isColumnGroupSame =
    (movingPinnedColumn && targetPinnedColumn) ||
    (movingUnPinnedColumn && targetUnpinnedColumn);

  // If the columns belong to the same group, update the column order and pinning state
  if (isColumnGroupSame) {
    setColumnOrder((prevColumnOrder) => {
      // Find the old and new index of the dragged column
      const oldIndex = prevColumnOrder.indexOf(activeId);
      const newIndex = prevColumnOrder.indexOf(overId);

      // Move the column to its new position
      const newColumnOrder = arrayMove(prevColumnOrder, oldIndex, newIndex);

      // Update the column pinning state based on the new column order
      setColumnPinning((prevPinning) => {
        // Retrieve the list of currently pinned columns from the previous state.
        const leftPinnedColumns = prevPinning.left ?? [];

        // Create a map where the keys are column IDs and the values are their new positions in the updated column order.
        // This helps us to find the new index positions of the pinned columns.
        const columnIndexMap = new Map(
          newColumnOrder.map((id, index) => [id, index])
        );

        // Filter out only the pinned columns that are still present in the new column order.
        // We use `columnIndexMap.has(id)` to ensure that we only include pinned columns that still exist in the new order.
        const updatedLeftPinnedColumns = leftPinnedColumns
          .filter((id) => columnIndexMap.has(id)) // Keep only those pinned columns that are still in the new order.
          .sort((a, b) => columnIndexMap.get(a)! - columnIndexMap.get(b)!); // Sort the filtered pinned columns based on their new indices.

        // Return the updated pinning state.
        // The `left` property is updated with the new order of pinned columns.
        return {
          left: updatedLeftPinnedColumns,
        };
      });

      return newColumnOrder;
    });
  }

  // Clear the hovered column ID after the drag ends
  hoveredColumnIdRef.current = null;
};

const handleDragMove = (
  event: DragMoveEvent,
  hoveredColumnIdRef: React.MutableRefObject<string | null>
) => {
  const { active, over } = event;

  if (over && active.id !== over.id && typeof over.id === 'string') {
    const columnId = over.id;
    hoveredColumnIdRef.current = columnId;
  } else {
    hoveredColumnIdRef.current = null;
  }
};

const draggingStyle = (transform: Transform | null): CSSProperties => ({
  opacity: 0.8,
  transform: CSS.Translate.toString(transform),
  zIndex: 1,
});

interface DragHeaderProps<TData, TValue> {
  header: Header<TData, TValue>;
  hoveredColumnIdRef: React.RefObject<string | null>;
}
interface DragCellProps<TData, TValue> {
  cell: Cell<TData, TValue>;
  row: Row<TData>;
  hoveredColumnIdRef: React.RefObject<string | null>;
}

const getInitialColumnOrder = (columns: Array<{ id?: string }>): string[] => {
  return columns
    .map((col) => col.id)
    .filter((item): item is string => item !== undefined);
};

export {
  DragCellProps,
  DragHeaderProps,
  buildMapPointsFromSlots,
  buildMapPointsFromUnits,
  draggingStyle,
  getInitialColumnOrder,
  getPinningStyles,
  getTotalCells,
  handleDragEnd,
  handleDragMove,
};
