import { EntityForm } from 'components/entity-form';
import { PageHeader } from 'components/page-header';
import { useSetAtom } from 'jotai';
import { useEntityAtom } from '../common/atoms';
import { ProvideRootResource } from 'components/hydrate-atoms';

const NewEntityPage = () => {
  const entityAtom = useEntityAtom();
  const getEntity = useSetAtom(entityAtom);
  return (
    <>
      <PageHeader
        breadcrumbs={[
          ['/entities', 'Entities'],
          ['/entities/new', 'New Entity'],
        ]}
        title="Entity Creation"
        stickyHeader
        actions={null}
      />
      <ProvideRootResource>
        <EntityForm entity={undefined} getEntity={getEntity} loading={false} />
      </ProvideRootResource>
    </>
  );
};

export { NewEntityPage };
