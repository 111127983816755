import classNames from 'classnames';
import { atom, useAtom, useAtomValue } from 'jotai';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactMapbox, {
  MapLayerMouseEvent,
  MapProps,
  MapRef,
  NavigationControl,
  ViewStateChangeEvent,
  FullscreenControl,
} from 'react-map-gl';

import type { TitleWindow } from 'types/window';
import { TierLayer } from './tier-layer';
import { DevelopmentAreasLayer } from './development-areas-layer';
import LandGrid from './land-grid';
import MapPopup from './map-popup';
import { standardMapStyles } from './map-styles';

import style from './map.module.scss';
import { StylesSelector } from './style-selector';
import {
  getAreaHref,
  getDevAreas,
  getWells,
  isLocationHovered,
  setPopupState,
  usePopup,
} from './utils';
import WellsLayer from './wells-layer';
import { BasinLayer } from './basin-layer';
import { MapUserConfigAtom } from './types';
import { TypeCurvesLayer } from './type-curves-layer';

declare let window: TitleWindow;

interface Props extends Omit<MapProps, 'fog' | 'terrain'> {
  longitude: number;
  latitude: number;
  zoom?: number;
  showControls?: boolean;
  showMapFilters?: boolean;
  geographies?: {
    id: number;
    name: string;
    quarterCalls?: string[];
  }[];
  blockPopups?: boolean;
  className?: string;
  disableWellsLayer?: boolean;
  mapConfigAtom: MapUserConfigAtom;
  scopedLayers?: ReactNode;
  showPopupOnSingleDevArea?: boolean;
}
export const mapStylesIndexAtom = atom(0);

export const mapRefAtom = atom<MapRef | null>(null);

const Map = ({
  longitude,
  latitude,
  zoom = 12,
  children,
  showControls,
  showMapFilters = false,
  className,
  geographies = [],
  blockPopups = false,
  disableWellsLayer,
  mapConfigAtom,
  scopedLayers,
  showPopupOnSingleDevArea,
  ...rest
}: Props) => {
  const [viewState, setViewState] = useState({ longitude, latitude, zoom });
  const mapStyleIndex = useAtomValue(mapStylesIndexAtom);
  const [mapRef, setMapRef] = useAtom(mapRefAtom);
  const mapConfig = useAtomValue(mapConfigAtom);

  useEffect(() => {
    setViewState({ longitude, latitude, zoom });
  }, [longitude, latitude, zoom]);

  const [popup, setPopup] = usePopup();

  const onMapMove = useMemo(
    () => (e: MapLayerMouseEvent) => {
      if (!popup.pinned && mapConfig.popups.checked && !blockPopups) {
        setPopupState({ e, setPopup });
      }
    },
    [popup.pinned, setPopup, mapConfig.popups.checked, blockPopups]
  );

  const onClosePopUp = useCallback(() => {
    setPopup({ latitude: 0, longitude: 0, visible: false });
  }, [setPopup]);

  const onViewportChange = useCallback((ev: ViewStateChangeEvent) => {
    setViewState({
      latitude: ev.viewState.latitude,
      longitude: ev.viewState.longitude,
      zoom: ev.viewState.zoom,
    });
  }, []);

  const onDragStart = useCallback(() => {
    setPopup({ latitude: 0, longitude: 0, visible: false });
  }, [setPopup]);

  const onDblClick = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef) return;

      const wells = getWells(mapRef.getMap(), e.point);

      if (wells.length) {
        setPopup((current) => ({
          ...current,
          pinned: true,
        }));
        return;
      }

      const areas = getDevAreas(mapRef.getMap(), e.point);
      if (areas.length === 1) {
        const { name, evalId } = areas[0];
        window.open(getAreaHref(name, evalId), '_blank', 'noopener,noreferrer');
        return;
      }

      setPopup((current) => ({
        ...current,
        pinned: true,
      }));
    },
    [setPopup, mapRef]
  );

  const hoveredReferenceLocations = geographies.flatMap(
    ({ id, name, quarterCalls }) => {
      if (isLocationHovered(popup, id)) {
        return [
          {
            id,
            name,
            quarterCalls,
          },
        ];
      }
      return [];
    }
  );

  const styles = standardMapStyles[mapStyleIndex];
  const settings = showControls
    ? {
        dragRotate: false,
        scrollZoom: mapConfig.scrollZoom.checked,
        touchZoom: false,
        touchRotate: false,
        keyboard: false,
        doubleClickZoom: false,
        showCompass: false,
        fadeDuration: 0,
      }
    : undefined;

  className = classNames(style.container, className);
  return (
    <div className={className}>
      <div className={style.mapArea}>
        <ReactMapbox
          ref={setMapRef}
          mapboxAccessToken={window.CONFIG.mapbox.token}
          testMode={window.CONFIG.mapbox.testMode}
          mapStyle={styles.url}
          onMove={onViewportChange}
          onMouseMove={onMapMove}
          onDblClick={onDblClick}
          onDragStart={onDragStart}
          latitude={viewState.latitude}
          longitude={viewState.longitude}
          zoom={viewState.zoom}
          style={{ width: '100%', height: '100%' }}
          {...rest}
          {...settings}
        >
          <LandGrid />
          <TypeCurvesLayer mapConfigAtom={mapConfigAtom} />
          <BasinLayer mapConfigAtom={mapConfigAtom} mapRef={mapRef} />
          <TierLayer mapConfigAtom={mapConfigAtom} mapRef={mapRef} />
          <DevelopmentAreasLayer mapConfigAtom={mapConfigAtom} />
          {!disableWellsLayer && <WellsLayer mapConfigAtom={mapConfigAtom} />}
          <MapPopup
            onClose={onClosePopUp}
            locations={hoveredReferenceLocations}
            popup={popup}
            showPopupOnSingleDevArea={showPopupOnSingleDevArea}
          />
          {children}
          {showControls ? <NavigationControl showCompass={false} /> : null}
          <FullscreenControl />
          <StylesSelector
            showMapFilters={showMapFilters}
            mapConfigAtom={mapConfigAtom}
            scopedLayers={scopedLayers}
          />
        </ReactMapbox>
      </div>
    </div>
  );
};

const MapProvider: FC<Props> = (props) => {
  return <Map {...props} />;
};

export default MapProvider;
