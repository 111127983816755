import { ColumnPinningState } from '@tanstack/react-table';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useMemo } from 'react';
import {
  fetchColumnOrder,
  fetchColumnPinning,
  updateColumnOrder,
  updateColumnPinning,
} from 'utils/tables';

interface UseColumnStateProps {
  tableId: string;
  initialColumnOrder: string[];
  initialColumnPinning: ColumnPinningState;
}

export function useColumnState({
  tableId,
  initialColumnOrder,
  initialColumnPinning,
}: UseColumnStateProps) {
  const columnOrderAtom = useMemo(
    () =>
      atomWithStorage<string[]>(
        tableId,
        initialColumnOrder,
        {
          getItem: (key: string) => {
            const fetchedOrder = fetchColumnOrder(key);
            return fetchedOrder && fetchedOrder.length > 0
              ? fetchedOrder
              : initialColumnOrder;
          },
          setItem: (key: string, newValue: string[]) =>
            updateColumnOrder(key, newValue),
          removeItem: () => void 0,
        },
        { getOnInit: true }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableId]
  );

  const columnPinningAtom = useMemo(
    () =>
      atomWithStorage<ColumnPinningState>(
        tableId,
        initialColumnPinning,
        {
          getItem: (key: string) => {
            const fetchPinnedColumns = fetchColumnPinning(key);
            return fetchPinnedColumns?.left &&
              fetchPinnedColumns?.left.length > 0
              ? fetchPinnedColumns
              : initialColumnPinning;
          },
          setItem: (key: string, newValue: ColumnPinningState) =>
            updateColumnPinning(key, newValue),
          removeItem: () => void 0,
        },
        { getOnInit: true }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableId]
  );

  const [columnOrder, setColumnOrder] = useAtom(columnOrderAtom);
  const [columnPinning, setColumnPinning] = useAtom(columnPinningAtom);

  return {
    columnOrder,
    setColumnOrder,
    columnPinning,
    setColumnPinning,
  };
}
