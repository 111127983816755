import { atomWithStorage } from 'jotai/utils';

const showDocumentFilePreferenceAtom = atomWithStorage(
  'show-document-file-interpretation-page',
  false
);

const showInterpretationListPreferenceAtom = atomWithStorage(
  'show-interpretation-list-interpretation-page',
  false
);

export { showDocumentFilePreferenceAtom, showInterpretationListPreferenceAtom };
