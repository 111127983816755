import { additionalInformationUpdatedAtom } from '../atoms';
import { Note } from '../note';
import { PageReference } from '../page-reference';
import { ReviewSectionCheckbox } from '../review-section-checkbox';

const AdditionalInformation = () => {
  return (
    <>
      <Note />
      <PageReference />
      <ReviewSectionCheckbox
        updateTrackingAtom={additionalInformationUpdatedAtom}
        field={'additionalInformationReviewed'}
        label="Additional Information Reviewed"
        helpMessage="Editing any additional information will change the review status to unreviewed"
      />
    </>
  );
};

export { AdditionalInformation };
