import { StrictMode } from 'react';
import { Router } from './router';
import { AppShell } from './app-shell';

import 'react-modern-drawer/dist/index.css';

export function App({ csrfToken }: { csrfToken: string }) {
  return (
    <StrictMode>
      <AppShell Router={Router} csrfToken={csrfToken} />
    </StrictMode>
  );
}
