import { getMapDefaultConfig } from 'components/map/atoms';
import { atomWithStorage } from 'jotai/utils';
import { Map } from 'components/map';
import { useRef } from 'react';
import { useCalculateMapDimensions } from 'components/map/utils';
import { ScopedPackageLayerSelection } from './scoped-package-layer-selection';
import { Point } from 'types/api-responses';
import style from './packages-map.module.scss';
import { ScopedPackageAssetsLayer } from './scoped-package-assets-layer';
import { PackageResponse } from 'types/packages-api/responses';
import { ScopedPackageDevelopmentAreasLayer } from './scoped-package-dev-areas-layer';
import { ScopedPackageTitleLayer } from './scoped-package-title-layer';
import { MapRef } from 'react-map-gl';
import { PrimitiveAtom } from 'jotai';
import { MapUserConfigAtom } from 'components/map/types';

const mapConfigAtom = atomWithStorage(
  'insights-package-show-page-map-config',
  getMapDefaultConfig()
);

const PackagesMap = ({
  boundingBox,
  mapSources,
  layerAtoms,
  customMapConfigAtom,
  key,
}: {
  key: React.Key;
  boundingBox: [Point, Point];
  mapSources: PackageResponse['resources']['maps'];
  setMapRef?: (mapRef: MapRef) => void;
  layerAtoms?: {
    assets: PrimitiveAtom<boolean>;
    devAreas: PrimitiveAtom<boolean>;
    title: PrimitiveAtom<boolean>;
    wells?: PrimitiveAtom<boolean>;
  };
  customMapConfigAtom?: MapUserConfigAtom;
}) => {
  const containerRef = useRef(null);
  const { latitude, longitude, zoom } = useCalculateMapDimensions({
    points: boundingBox,
    containerRef,
  });
  return (
    <div ref={containerRef}>
      <Map
        key={key}
        className={style.map}
        longitude={longitude}
        latitude={latitude}
        zoom={zoom}
        mapConfigAtom={customMapConfigAtom || mapConfigAtom}
        showControls
        showMapFilters
        scopedLayers={<ScopedPackageLayerSelection layerAtoms={layerAtoms} />}
        showPopupOnSingleDevArea
      >
        {mapSources && (
          <>
            <ScopedPackageAssetsLayer
              source={mapSources.assets}
              layerAtom={layerAtoms?.assets}
            />
            <ScopedPackageDevelopmentAreasLayer
              source={mapSources.developmentAreas}
              layerAtom={layerAtoms?.devAreas}
            />
            <ScopedPackageTitleLayer source={mapSources.title} />
          </>
        )}
      </Map>
    </div>
  );
};

export { PackagesMap };
