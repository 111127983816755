import { Tile } from 'carbon-components-react';
import { Link } from 'components/link';
import classNames from 'classnames';
import { ReactNode } from 'react';

import type { IconProps } from 'components/icons/types';
import style from './search-result-card.module.scss';

interface Props<ApiResponse extends { href: string; name: string }> {
  Icon: React.FunctionComponent<IconProps>;
  aside?: ReactNode;
  item: ApiResponse;
  cardContent: ReactNode;
}

const SearchResultCard = <ApiResponse extends { href: string; name: string }>({
  Icon,
  aside,
  item,
  cardContent,
}: Props<ApiResponse>) => {
  const className = classNames(style.card, style.clickable);

  return (
    <div className={className}>
      <Link className={style.header} to={item.href}>
        <div className={style.icon}>
          <Icon />
        </div>
        <div className={style.metadata}>
          <h2 className={style.name}>{item.name}</h2>
        </div>
        {aside && <aside>{aside}</aside>}
      </Link>
      <Tile className={style.details}>
        <div className={style.detail}>{cardContent}</div>
      </Tile>
    </div>
  );
};

export default SearchResultCard;
