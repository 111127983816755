import { mapColors } from 'components/map/map-colors';
import { useAtomValue } from 'jotai';
import { buildPolygonStyle } from 'lib/map';
import { titleLayerAtom } from './atoms';
import { Layer, Source } from 'react-map-gl';
import { standardMapStyles } from 'components/map/map-styles';
import { SymbolLayout } from 'mapbox-gl';

const STYLE = buildPolygonStyle({
  color: mapColors.scopedTitleFill,
  lineColor: mapColors.scopedTitleBorder,
});

interface Props {
  source: string;
}

const textLayout = {
  'text-field': [
    'format',
    ['upcase', ['get', 'name']],
    {
      'font-scale': 1,
      'text-font': ['literal', standardMapStyles[0].fonts.CONDENSED_BOLD],
    },
    '\n',
    {},
  ],
  'text-justify': 'left',
  'text-size': {
    base: 1,
    stops: [
      [12, 8],
      [18, 24],
    ],
  },
} as SymbolLayout;

const ScopedPackageTitleLayer = ({ source }: Props) => {
  const titleVisible = useAtomValue(titleLayerAtom);
  return (
    <Source id="package-title-layer" type="vector" url={source}>
      {titleVisible && (
        <Layer
          id="title-fill-scoped-package"
          type="fill"
          paint={STYLE.fill}
          source-layer="package-title"
        />
      )}
      {titleVisible && (
        <Layer
          id="title-line-scoped-package"
          type="line"
          paint={STYLE.line}
          source-layer="package-title"
        />
      )}
      {titleVisible && (
        <Layer
          id="title-symbol-scoped-package"
          type="symbol"
          source-layer="package-title"
          filter={['==', 'type', 'centroid']}
          layout={textLayout}
        />
      )}
    </Source>
  );
};

export { ScopedPackageTitleLayer };
