import { useState } from 'react';
import {
  Column,
  Grid,
  InlineLoading,
  InlineNotification,
  Row,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'carbon-components-react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';

import style from './free-cash-flow.module.scss';
import { useCreateEvaluationPackageValuationOverviewAtom } from './atoms';
import { useAtomValue } from 'jotai';
import { PackageEvaluationsWindow } from 'types/window';
import { PackageEvaluationValuationOverviewResponse } from 'types/packages-api/responses';
import { FORMATTER } from 'lib/ui';

declare let window: PackageEvaluationsWindow;

const FreeCashFlow = ({ href }: { href: string }) => {
  const valuationOverviewAtom =
    useCreateEvaluationPackageValuationOverviewAtom(href);

  const [activeSeries, setActiveSeries] = useState([true, true, true, true]);

  const toggleSeries = (index: number): void => {
    const newActiveSeries = [...activeSeries];
    newActiveSeries[index] = !newActiveSeries[index];
    setActiveSeries(newActiveSeries);
  };

  const valuationOverview = useAtomValue(valuationOverviewAtom);

  if (valuationOverview.data && 'error' in valuationOverview.data)
    return (
      <InlineNotification
        kind="error"
        title={valuationOverview.data.error}
        lowContrast
      />
    );

  if (valuationOverview.loading)
    return <InlineLoading title="Loading valuation overview" />;

  if (!valuationOverview.data) {
    return (
      <InlineNotification
        kind="error"
        title={'Something went wrong'}
        lowContrast
      />
    );
  }

  const LIMIT = window.CONFIG.packageEvaluations.valuationOverviewYearsLimit;

  return (
    <Grid className={style.content}>
      <Row>
        <Column>
          <Chart
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'column',
              },

              title: {
                text: 'Projected Asset Level Free Cash Flow',
                align: 'center',
              },

              xAxis: {
                categories: valuationOverview.data.cashflows.years.slice(
                  0,
                  LIMIT
                ),
              },

              yAxis: {
                allowDecimals: false,
                title: {
                  text: 'Yearly CCF ($)',
                },
                stackLabels: {
                  enabled: true,
                  align: 'center',
                  axis: { series: [{ yData: [] }] },
                  x: 0,
                  formatter: function (this: Highcharts.StackItemObject) {
                    let total = 0;
                    this.axis.series.forEach((series: any) => {
                      total += series.yData[this.x];
                    });
                    if (total < 0 === this.isNegative) {
                      return `$${Highcharts.numberFormat(total, 0, '.', ',')}`;
                    }
                  },
                },
              },
              tooltip: {
                enabled: false,
              },
              plotOptions: {
                column: {
                  stacking: 'normal',
                },
                series: {
                  dataLabels: [
                    {
                      enabled: true,
                      y: 0,
                      formatter: function () {
                        return this.y === 0
                          ? null
                          : `$${Highcharts.numberFormat(this.y, 0, '.', ',')}`;
                      },
                    },
                  ],
                },
              },

              series: [
                {
                  name: 'LTD Cash Flows',
                  data: valuationOverview.data.cashflows.ltd.slice(0, LIMIT),
                  color: 'rgb(155,156,159)',
                  legendIndex: 4,
                  visible: activeSeries[3],
                },
                {
                  name: 'Permit Cash Flow',
                  data: valuationOverview.data.cashflows.permit.slice(0, LIMIT),
                  color: 'rgba(78,135,174,0.5)',
                  legendIndex: 3,
                  visible: activeSeries[2],
                },
                {
                  name: 'DUC Cash Flow',
                  data: valuationOverview.data.cashflows.duc.slice(0, LIMIT),
                  color: 'rgb(78,135,174)',
                  legendIndex: 2,
                  visible: activeSeries[1],
                },
                {
                  name: 'PDP Cash Flow',
                  data: valuationOverview.data.cashflows.pdp.slice(0, LIMIT),
                  color: 'rgb(71,81,96)',
                  legendIndex: 1,
                  visible: activeSeries[0],
                },
              ],

              legend: {
                enabled: false,
              },
            }}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <div id="legend" className={style.legendsArea}>
            <div>
              <button
                onClick={() => toggleSeries(0)}
                className={activeSeries[0] ? undefined : style.notVisible}
              >
                <span
                  className={style.circleIndicator}
                  style={{ backgroundColor: 'rgb(71,81,96)' }}
                />
                <span>PDP Cash Flow</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => toggleSeries(1)}
                className={activeSeries[1] ? undefined : style.notVisible}
              >
                <span
                  className={style.circleIndicator}
                  style={{ backgroundColor: 'rgb(78,135,174)' }}
                />
                <span>DUC Cash Flow</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => toggleSeries(2)}
                className={activeSeries[2] ? undefined : style.notVisible}
              >
                <span
                  className={style.circleIndicator}
                  style={{ backgroundColor: 'rgba(78,135,174,0.5)' }}
                />
                <span>Permit Cash Flow</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => toggleSeries(3)}
                className={activeSeries[3] ? undefined : style.notVisible}
              >
                <span
                  className={style.circleIndicator}
                  style={{ backgroundColor: 'rgb(155,156,159)' }}
                />
                <span>LTD Cash Flows</span>
              </button>
            </div>
            <div>
              <label>Total</label>
            </div>
          </div>
          <CumulativeTable data={valuationOverview.data.cashflows} />
        </Column>
      </Row>
    </Grid>
  );
};

const transformData = (
  data: PackageEvaluationValuationOverviewResponse['cashflows']
) => {
  const sumReducer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const limit = window.CONFIG.packageEvaluations.valuationOverviewYearsLimit;

  const cumCashFlowYearLimit = {
    pdp: data.pdp.slice(0, limit).reduce(sumReducer, 0),
    duc: data.duc.slice(0, limit).reduce(sumReducer, 0),
    permit: data.permit.slice(0, limit).reduce(sumReducer, 0),
    ltd: data.ltd.slice(0, limit).reduce(sumReducer, 0),
    total: 0,
  };

  const cumCashFlowLimitless = {
    pdp: data.pdp.reduce(sumReducer, 0),
    duc: data.duc.reduce(sumReducer, 0),
    permit: data.permit.reduce(sumReducer, 0),
    ltd: data.ltd.reduce(sumReducer, 0),
    total: 0,
  };

  cumCashFlowYearLimit.total = Object.values(cumCashFlowYearLimit).reduce(
    sumReducer,
    0
  );
  cumCashFlowLimitless.total = Object.values(cumCashFlowLimitless).reduce(
    sumReducer,
    0
  );

  return { cumCashFlowLimitless, cumCashFlowYearLimit };
};

const CumulativeTable = ({
  data,
}: {
  data: PackageEvaluationValuationOverviewResponse['cashflows'];
}) => {
  const yearA =
    data.years[
      window.CONFIG.packageEvaluations.valuationOverviewYearsLimit - 1
    ];
  const yearB = data.years[data.years.length - 1];
  const { cumCashFlowYearLimit, cumCashFlowLimitless } = transformData(data);
  return (
    <Table className={style.cumulativeTable}>
      <TableBody>
        <TableRow>
          <TableCell>Cumulative Cash Flow (through {yearA})</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowYearLimit.pdp
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowYearLimit.duc
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowYearLimit.permit
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowYearLimit.ltd
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowYearLimit.total
          )}`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cumulative Cash Flow (through {yearB})</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowLimitless.pdp
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowLimitless.duc
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowLimitless.permit
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowLimitless.ltd
          )}`}</TableCell>
          <TableCell>{`${FORMATTER.format(
            cumCashFlowLimitless.total
          )}`}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { FreeCashFlow };
