import { atom } from 'jotai';
import { titleWorkspacesSettingsForm } from './utils';
import { createMutationAtom } from 'atoms/create-resource-atom';
import { TitleWorkspaceResponse } from 'types/api-responses';
import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';

const useTitleWorkspaceAtom = (href: string) =>
  useMemo(() => createResourceAtom<TitleWorkspaceResponse>(href), [href]);

const useTitleWorkspaceSettingsFormAtom = (
  workspace: TitleWorkspaceResponse
) => {
  return useMemo(
    () => atom(titleWorkspacesSettingsForm(workspace)),
    [workspace]
  );
};

const saveStartingInterestAtom = createMutationAtom<
  null,
  'titleWorkspacesSettings'
>();

export {
  useTitleWorkspaceSettingsFormAtom,
  saveStartingInterestAtom,
  useTitleWorkspaceAtom,
};
