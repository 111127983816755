import moment from 'moment';

export interface DateTimeProps
  extends Omit<React.TimeHTMLAttributes<HTMLTimeElement>, 'dateTime'> {
  dateTime: string | null | undefined;
  fallback?: React.ReactNode;
}

const DateTime = ({ dateTime, fallback, ...rest }: DateTimeProps) => {
  const date = moment(dateTime);

  return date ? (
    <time dateTime={date.format(moment.HTML5_FMT.DATETIME_LOCAL)} {...rest}>
      {date.format('lll')}
    </time>
  ) : (
    <span {...rest}>{fallback ?? null}</span>
  );
};

export { DateTime };
