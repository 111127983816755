import { Add16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { useAtomValue, useSetAtom, atom } from 'jotai';
import { FC, ReactNode, useMemo } from 'react';
import { formAtoms } from '../atoms';
import { FormState } from '../types';

type Forms = Pick<
  FormState,
  | 'locationReferences'
  | 'capacities'
  | 'transactions'
  | 'grantors'
  | 'grantees'
  | 'curativeRequirements'
  | 'documentReferences'
>;
type AcceptedForms = {
  [P in keyof Forms]: { field: P; value: Forms[P]['value'] };
}[keyof Forms];

interface RowComponent<T extends AcceptedForms> {
  atomIdx: number;
  atoms: T['value'][0];
  onDelete?: (idx: number) => void;
}

interface Props<T extends AcceptedForms> extends RowComponent<T> {
  MemoedComponent: React.MemoExoticComponent<FC<RowComponent<T>>>;
}

/*
 * This is a component to optimize re-renders when adding/removing
 * elements from the applicable area list. We only care to provide an
 * onDelete prop if the row is new. For old rows, we don't delete it
 * here but tag the record to be deleted by the server.
 * By not providing an onDelete function to existing records, the memoed
 * components remains stable and only the new ones are re-rendered.
 */
const ManagedRow = <T extends AcceptedForms>({
  onDelete,
  MemoedComponent,
  ...rest
}: Props<T>) => {
  const state = useAtomValue(rest.atoms.status);
  if (state === 'new' || state === 'duplicated') {
    return <MemoedComponent {...rest} onDelete={onDelete} />;
  }

  return <MemoedComponent {...rest} />;
};

interface ManagerProps<T extends AcceptedForms> {
  MemoedComponent: React.MemoExoticComponent<FC<RowComponent<T>>>;
  field: T['field'];
  label: string;
  initEmptyForm: () => T['value'][0];
  customAddButton?: (fn: (emptyForm: T['value'][0]) => void) => ReactNode;
}

const FormRowManager = <T extends AcceptedForms>({
  MemoedComponent,
  field,
  label,
  initEmptyForm,
  customAddButton,
}: ManagerProps<T>) => {
  const interpretationField = useMemo(
    () =>
      atom((get) => {
        return get(get(formAtoms).formAtom)[field].value;
      }),
    // Used to keep the atom stable, expected empty deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setField = useSetAtom(useAtomValue(formAtoms).formAtom);
  const rows = useAtomValue(interpretationField);

  const onDelete = (atomIdx: number) => {
    setField({
      field,
      value: [...rows.slice(0, atomIdx), ...rows.slice(atomIdx + 1)],
    } as T);
  };

  return (
    <>
      {rows.map((atoms, i) => {
        return (
          <ManagedRow
            MemoedComponent={MemoedComponent}
            key={i}
            atomIdx={i}
            atoms={atoms}
            onDelete={onDelete}
          />
        );
      })}
      <FormRow>
        {customAddButton ? (
          customAddButton((emptyForm) => {
            setField({
              field,
              value: [...rows, emptyForm],
            } as T);
          })
        ) : (
          <Button
            renderIcon={Add16}
            kind="tertiary"
            size="md"
            onClick={() =>
              setField({
                field,
                value: [...rows, initEmptyForm()],
              } as T)
            }
          >
            {label}
          </Button>
        )}
      </FormRow>
    </>
  );
};

export { FormRowManager };
