import searchAtomPair from 'atoms/search-atom';
import { ActivitiesQueryResponse } from 'types/api-responses';
import { ActivitiesQueryParams } from './types';
import {
  DatePicker,
  DatePickerInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import { useAtom, useAtomValue } from 'jotai';
import { format, parse, subDays } from 'date-fns';

import style from './page.module.scss';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import { PageHeader } from 'components/page-header';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';

const getInterpretationActivitiesTotals = (
  results: ActivitiesQueryResponse[]
) => {
  let newInterpretationsTotal = 0;
  let editedInterpretationsTotal = 0;
  results?.forEach((el) => {
    newInterpretationsTotal += el.newInterpretationsCount;
    editedInterpretationsTotal += el.editedInterpretationsCount;
  });
  return { newInterpretationsTotal, editedInterpretationsTotal };
};

const formatDateForInput = (date: string) => {
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
};

const { searchAtom } = searchAtomPair<
  ActivitiesQueryResponse,
  ActivitiesQueryParams
>('activities', {
  startDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
});

const Form = () => {
  const [search, runSearch] = useAtom(searchAtom);

  const startDate = search.currentPayload?.startDate;
  const endDate = search.currentPayload?.endDate;

  if (!startDate || !endDate) return null;

  return (
    <div className={style.facets}>
      <DatePicker
        maxDate={format(new Date(), 'MM/dd/yyyy')}
        datePickerType="range"
        value={[formatDateForInput(startDate), formatDateForInput(endDate)]}
        onChange={(dates) => {
          if (dates.length === 2) {
            runSearch(() => ({
              startDate: format(dates[0], 'yyyy-MM-dd'),
              endDate: format(dates[1], 'yyyy-MM-dd'),
            }));
          }
        }}
      >
        <DatePickerInput
          autoComplete="off"
          labelText="Start Date"
          id="activities-start-date"
          placeholder="mm/dd/yyyy"
        />
        <DatePickerInput
          autoComplete="off"
          labelText="End Date"
          id="activities-end-date"
          placeholder="mm/dd/yyyy"
        />
      </DatePicker>
    </div>
  );
};

const Results = () => {
  const search = useAtomValue(searchAtom);
  const { newInterpretationsTotal, editedInterpretationsTotal } =
    getInterpretationActivitiesTotals(search.data?.results || []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Email</TableHeader>
            <TableHeader>New Interpretations</TableHeader>
            <TableHeader>Edited Interpretations</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {search.loading ? (
            <SkeletonTableRows rows={5} columns={3} />
          ) : (
            search.data?.results.map((activity) => (
              <TableRow key={activity.email}>
                <TableCell>{activity.email}</TableCell>
                <TableCell>{activity.newInterpretationsCount}</TableCell>
                <TableCell>{activity.editedInterpretationsCount}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell>Totals</TableCell>
            <TableCell>{newInterpretationsTotal}</TableCell>
            <TableCell>{editedInterpretationsTotal}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

const ActivitiesPage = () => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle('Activities')}</title>
      </Helmet>
      <PageHeader
        breadcrumbs={[['/activities', 'Activities']]}
        title="Activities"
        subtitle="Insights Activity allows you to track the progress you and your peers have made with interpretations"
        actions={null}
      />
      <Form />
      <Results />
    </div>
  );
};

export { ActivitiesPage };
