import {
  Column,
  Grid,
  InlineLoading,
  InlineNotification,
  Row,
  Tile,
  Button,
} from 'carbon-components-react';
import { ChevronLeft16, ChevronRight16 } from '@carbon/icons-react';
import { EvaluationCount } from 'components/evaluations/evaluation-count';
import { EvaluationList } from 'components/evaluations/evaluation-list';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { PackageEvaluationsResponse } from 'types/evaluation-api/responses';
import {
  createShowPackageEvalDraftAtom,
  usePackageEvaluationsAtom,
} from './atoms';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { EvaluationForm } from './evaluation-form';
import { EvaluationResult } from './evaluation-result';

import style from './packages-evaluation.module.scss';

const EvaluationsImpl = ({
  evaluations,
  getEvaluations,
  href,
}: {
  evaluations: PackageEvaluationsResponse[];
  getEvaluations: () => Promise<void>;
  href: string;
}) => {
  const location = useLocation();
  const evalCount = evaluations ? evaluations.length : 0;
  const showDratInitialValue =
    evalCount === 0 || location.pathname.includes('/new');
  const showPackageEvalDraftAtom = useMemo(
    () => createShowPackageEvalDraftAtom(showDratInitialValue),
    [showDratInitialValue]
  );
  const [showDraft, setShowDraft] = useAtom(showPackageEvalDraftAtom);
  const { path } = useRouteMatch();

  const [isEvaluationListVisible, setEvaluationListVisible] = useState(false);
  const handleClick = () => {
    setEvaluationListVisible((current) => !current);
  };

  return (
    <Grid className="bx--no-gutter" condensed fullWidth>
      <h2 className={style.subtitleEval}>Package Evaluation</h2>
      <Row>
        <Column
          md={isEvaluationListVisible ? 0 : !isEvaluationListVisible ? 13 : 13}
          lg={isEvaluationListVisible ? 0 : !isEvaluationListVisible ? 3 : 3}
          className={style.scrollRelativeColumn}
        >
          {evaluations && <EvaluationCount count={evaluations.length} />}
          <EvaluationList
            evaluations={evaluations}
            showDraft={showDraft}
            onDraftClick={() => {
              setShowDraft(true);
            }}
            onEvaluationClick={() => setShowDraft(false)}
            draftLabel="New"
          />
        </Column>
        <Column
          md={isEvaluationListVisible ? 16 : 12}
          lg={isEvaluationListVisible ? 16 : 13}
          className={style.contentEvaluation}
        >
          {evaluations && evaluations.length ? (
            <div className={style.closeAreabtn}>
              <Button
                renderIcon={
                  isEvaluationListVisible ? ChevronRight16 : ChevronLeft16
                }
                kind="ghost"
                hasIconOnly
                iconDescription={
                  isEvaluationListVisible
                    ? `Show Evaluation List`
                    : `Hide Evaluation List`
                }
                tooltipAlignment="start"
                tooltipPosition="top"
                size="sm"
                className={style.closePanelBtn}
                onClick={handleClick}
              />
            </div>
          ) : null}
          <Tile className={style.contentEvaluation}>
            <Switch>
              <Route exact path={`${path}/`}>
                {evaluations ? (
                  evaluations.length ? (
                    <Redirect to={evaluations[0].href} />
                  ) : (
                    <EvaluationForm
                      href={href}
                      getEvaluations={getEvaluations}
                    />
                  )
                ) : (
                  <InlineLoading />
                )}
              </Route>
              <Route exact path={`${path}/evaluations/new`}>
                <EvaluationForm href={href} getEvaluations={getEvaluations} />
              </Route>
              <Route exact path={`${path}/evaluations/combinations`}>
                {showDraft ? (
                  <EvaluationForm href={href} getEvaluations={getEvaluations} />
                ) : (
                  <EvaluationResult isCombined />
                )}
              </Route>
              <Route exact path={`${path}/evaluations/:evalId`}>
                {showDraft ? (
                  <EvaluationForm href={href} getEvaluations={getEvaluations} />
                ) : (
                  <EvaluationResult />
                )}
              </Route>
            </Switch>
          </Tile>
        </Column>
      </Row>
    </Grid>
  );
};

const Evaluations = ({ href }: { href: string }) => {
  const evaluationsAtom = usePackageEvaluationsAtom(href);
  const [evaluations, getEvaluations] = useAtom(evaluationsAtom);

  if (evaluations.data && 'error' in evaluations.data)
    return (
      <InlineNotification
        kind="error"
        title={evaluations.data.error}
        lowContrast
      />
    );

  return (
    <EvaluationsImpl
      href={href}
      evaluations={evaluations.data || []}
      getEvaluations={getEvaluations}
    />
  );
};

export { Evaluations };
