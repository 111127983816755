import searchAtomPair from 'atoms/search-atom';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tile,
} from 'carbon-components-react';
import { PaginatedTable } from 'components/table/paginated-table';
import { useAtom } from 'jotai';
import { WellsResponse } from 'types/api-responses';
import WellSvg from 'images/well.svg';

import { Link } from 'components/link';
import { Date } from 'components/date';
import { SkeletonTableRows } from 'components/skeleton-table-rows';

import wellStatusTypes from 'enums/well_status_types.json';
import { EmptyState } from 'components/empty-state';
import { useMemo } from 'react';
import { WellSearchParams } from './types';

const BLANK_RENDER_VALUE = '—';
const DEFAULT_PAGE_SIZE = 50;

type Props = Pick<
  WellSearchParams,
  'withinDevelopmentAreaId' | 'withinTitleWorkspaceId'
>;

const WellsTable = (props: Props) => {
  const { searchAtom } = useMemo(
    () =>
      searchAtomPair<WellsResponse, WellSearchParams>('wells', {
        withinTitleWorkspaceId: props.withinTitleWorkspaceId,
        withinDevelopmentAreaId: props.withinDevelopmentAreaId,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        sortAttribute: 'uwi',
        sortDirection: 'ASC',
      }),
    [props.withinTitleWorkspaceId, props.withinDevelopmentAreaId]
  );
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const currentPayload = searchResults.currentPayload;
  const pageSize = searchResults?.data?.pageSize || DEFAULT_PAGE_SIZE;

  const skeletonRows =
    searchResults?.data?.totalCount &&
    searchResults?.data?.totalCount < pageSize
      ? searchResults?.data?.totalCount
      : 5;

  const sortBy = (sortAttribute: string) => {
    setSearchPayload(
      (current) =>
        current && {
          ...current,
          sortAttribute,
          page: 1,
          sortDirection: current?.sortDirection === 'ASC' ? 'DESC' : 'ASC',
        }
    );
  };

  const sortProps = (sortAttribute: string) => ({
    isSortable: true,
    isSortHeader: currentPayload?.sortAttribute === sortAttribute,
    sortDirection: currentPayload?.sortDirection,
    onClick: () => sortBy(sortAttribute),
  });

  if (!searchResults.loading && !searchResults.data?.results.length) {
    return (
      <Tile>
        <EmptyState
          icon={WellSvg}
          headerText="No Wells Available"
          helperText="Currently there are no active intersecting wells found"
        />
      </Tile>
    );
  }

  return (
    <PaginatedTable
      pageSizes={[DEFAULT_PAGE_SIZE, 100]}
      pageSize={pageSize}
      page={searchResults?.data?.page || 1}
      totalItems={searchResults?.data?.totalCount || 0}
      onPaginate={({ page, pageSize }) => {
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              page,
              pageSize,
            }
        );
      }}
    >
      <TableHead>
        <TableRow>
          <TableHeader {...sortProps('lease_name')}>Name</TableHeader>
          <TableHeader {...sortProps('uwi')}>UWI</TableHeader>
          <TableHeader {...sortProps('status')}>Status</TableHeader>
          <TableHeader {...sortProps('current_operator')}>
            Current Operator
          </TableHeader>
          <TableHeader {...sortProps('hole_direction')}>
            Hole Direction
          </TableHeader>
          <TableHeader {...sortProps('permit_date')}>Permit Date</TableHeader>
          <TableHeader {...sortProps('spud_date')}>Spud Date</TableHeader>
          <TableHeader {...sortProps('first_production_date')}>
            First Production Date
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults.loading ? (
          <SkeletonTableRows rows={skeletonRows} columns={8} />
        ) : searchResults.data ? (
          searchResults.data.results.map((el) => {
            return (
              <TableRow key={el.id}>
                <TableCell>{el.name}</TableCell>
                <TableCell>
                  {el.href ? (
                    <Link to={el.href} target="_blank">
                      {el.uwi}
                    </Link>
                  ) : (
                    el.uwi
                  )}
                </TableCell>
                <TableCell>
                  {wellStatusTypes[el.status as keyof typeof wellStatusTypes]}
                </TableCell>
                <TableCell>{el.currentOperator}</TableCell>
                <TableCell>
                  {el.holeDirection
                    ? el.holeDirection.slice(0, 1) +
                      el.holeDirection.slice(1).toLowerCase()
                    : BLANK_RENDER_VALUE}
                </TableCell>
                <TableCell>
                  {el.permitDate ? (
                    <Date date={el.permitDate} />
                  ) : (
                    BLANK_RENDER_VALUE
                  )}
                </TableCell>
                <TableCell>
                  {el.spudDate ? (
                    <Date date={el.spudDate} />
                  ) : (
                    BLANK_RENDER_VALUE
                  )}
                </TableCell>
                <TableCell>
                  {el.firstProductionDate ? (
                    <Date date={el.firstProductionDate} />
                  ) : (
                    BLANK_RENDER_VALUE
                  )}
                </TableCell>
              </TableRow>
            );
          })
        ) : null}
      </TableBody>
    </PaginatedTable>
  );
};

export { WellsTable };
