import { FormRowStatus } from 'atoms/create-resource-atom';
import { atomField } from 'atoms/form-atoms';
import { WellsToExclude } from 'types/scenarios-api/responses';

const createWellToExcludeElement = (
  excludedWell: WellsToExclude,
  rowStatus: FormRowStatus = 'new'
) => {
  const dirty = rowStatus === 'new';
  return {
    formationName: atomField(excludedWell.formationName, {
      dirty,
    }),
    slotNumber: atomField(excludedWell.slotNumber, {
      dirty,
    }),
    uwi: atomField(excludedWell.uwi, { dirty }),
    _rowStatus: atomField<FormRowStatus>(rowStatus),
  };
};

export { createWellToExcludeElement };
