import {
  ChevronDown16,
  ChevronUp16,
  DataTable16,
  DataTableReference16,
} from '@carbon/icons-react';

import style from './calculation-details.module.scss';
import { prepareData } from './utils';
import {
  InterestCalculationDetails,
  StickKeys,
} from 'types/title-calculations/types';
import {
  Button,
  InlineLoading,
  InlineNotification,
} from 'carbon-components-react';
import { useReducer, useState } from 'react';
import { InterestMath } from './interest-math';
import { InterpretationDrawer } from 'components/interpretation-view/interpretation-view';
import { Link } from 'components/link';
import { HighlightRunsheetRowProps } from 'lib/types';
import { useCreateInterpretationCalculationDetailsAtom } from './atoms';
import { useAtomValue } from 'jotai';
import { TitleCalculationDetailsResponse } from 'types/api-responses';
import { formatInterest } from 'utils/format-interest';
import classNames from 'classnames';
import { LinkWithHover } from 'components/hover-card-resource';

export type ValueContext = {
  transactionId: number;
  interpretationHref: string;
  documentTitle: string;
};

export type OnSelectedTransaction = React.Dispatch<
  React.SetStateAction<ValueContext | undefined>
>;

const InterestCell = ({
  cellValue,
  onClick,
  onExpandedClick,
  changed,
  interest,
  setSelectedTransaction,
  refsMap,
  setHighlightedRow,
  interpretationId,
  expandedRowIndex,
  expandedRowInterest,
  cellInterest,
  index,
}: {
  cellValue: InterestCalculationDetails | null;
  onClick: () => void;
  onExpandedClick?: () => void;
  changed: boolean;
  interest: InterestCalculationDetails | null;
  setSelectedTransaction: OnSelectedTransaction;
  interpretationId: number;
  expandedRowIndex: number;
  expandedRowInterest: StickKeys | null;
  cellInterest: StickKeys;
  index: number;
} & HighlightRunsheetRowProps) => {
  if (!cellValue) return <>--</>;
  const cellNode =
    cellValue.total.before !== cellValue.total.after
      ? `${formatInterest(cellValue.total.before)} -> ${formatInterest(
          cellValue.total.after
        )}`
      : formatInterest(cellValue.total.before);

  return (
    <div className={style.relativeCell}>
      <div className={style.alignCell}>
        {cellNode}

        <Button
          hasIconOnly
          kind="ghost"
          size="sm"
          iconDescription="Expand"
          tooltipAlignment="center"
          tooltipPosition="top"
          onClick={onExpandedClick ? onExpandedClick : onClick}
          renderIcon={onExpandedClick ? ChevronUp16 : ChevronDown16}
        />
      </div>
      {interest !== null &&
        expandedRowInterest !== null &&
        expandedRowIndex === index &&
        expandedRowInterest === cellInterest && (
          <div className={style.popoverDiv}>
            <InterestMath
              interest={interest}
              setSelectedTransaction={setSelectedTransaction}
              refsMap={refsMap}
              setHighlightedRow={setHighlightedRow}
              interpretationId={interpretationId}
              changed={changed}
            />
          </div>
        )}
    </div>
  );
};

type CalculationDetailsProps = {
  titleWorkspace: number;
  titleWorkspaceHref: string;
  referenceLocationHref: string;
  interpretationId: number;
  lastCalculationHref?: string;
} & HighlightRunsheetRowProps;

const CalculationDetailsTable = ({
  titleWorkspace,
  referenceLocationHref,
  refsMap,
  setHighlightedRow,
  interpretationId,
  calculationDetails,
}: CalculationDetailsProps & {
  calculationDetails: TitleCalculationDetailsResponse[];
}) => {
  const [expandedRow, setExpandedRow] = useState<
    | {
        rowIndex: number;
        interest: StickKeys;
      }
    | { rowIndex: -1; interest: null }
  >({
    rowIndex: -1,
    interest: null,
  });
  const [selectedTransaction, setSelectedTransaction] = useState<
    ValueContext | undefined
  >(undefined);

  const hasChangedOwnerships = calculationDetails.some((el) => el.changed);

  const [showOnlyChangedRows, setShowOnlyChangedRows] = useReducer(
    (s) => !s,
    hasChangedOwnerships
  );

  const preparedData = prepareData(calculationDetails, showOnlyChangedRows);

  const onExpandedClick = (expandedIndex: number, interest: StickKeys) => {
    return expandedRow.rowIndex === expandedIndex &&
      expandedRow.interest === interest
      ? () => setExpandedRow({ rowIndex: -1, interest: null })
      : undefined;
  };

  const onExpandClick = (i: number, interest: StickKeys) => {
    return () => {
      setExpandedRow({ rowIndex: -1, interest: null });
      setExpandedRow({ rowIndex: i, interest });
    };
  };

  const interest =
    expandedRow.rowIndex !== -1 && expandedRow.interest !== null
      ? preparedData[expandedRow.rowIndex].entity[expandedRow.interest]
      : null;

  return (
    <div>
      <InterpretationDrawer
        open={!!selectedTransaction}
        onClose={() => setSelectedTransaction(undefined)}
        documentTitle={selectedTransaction?.documentTitle || ''}
        interpretationHref={selectedTransaction?.interpretationHref || ''}
        titleWorkspaceId={titleWorkspace}
        referenceLocationHref={referenceLocationHref}
        showEditButton={false}
      />
      {calculationDetails.length !== 0 && (
        <div className={style.alignRight}>
          <Button
            kind="ghost"
            size="md"
            renderIcon={
              showOnlyChangedRows ? DataTable16 : DataTableReference16
            }
            onClick={() => setShowOnlyChangedRows()}
          >{`Show ${
            showOnlyChangedRows ? 'All' : 'Changed'
          } Ownerships`}</Button>
        </div>
      )}
      {calculationDetails.length === 0 ? (
        <InlineNotification
          kind="warning"
          title="This interpretation has no transactions"
          lowContrast
        />
      ) : preparedData.length ? (
        <div className={style.detailsTableWrap}>
          <table className={style.detailsTable}>
            <thead>
              <tr>
                <th>Transaction</th>
                <th>Tract</th>
                <th>Depth</th>
                <th>Entity</th>
                <th>Surface</th>
                <th>Executive</th>
                <th>Bonus</th>
                <th>Delay</th>
                <th>Royalty</th>
                <th>Fixed Royalty</th>
                <th>WI</th>
                <th>ORRI</th>
                <th>NRI</th>
                <th>Burdened Entities</th>
              </tr>
            </thead>
            <tbody>
              {preparedData.map((el, i) => {
                return (
                  <tr
                    key={i}
                    className={
                      hasChangedOwnerships && !el.entity.changed
                        ? style.unchangedRow
                        : ''
                    }
                  >
                    {el.transactionRowSpan ? (
                      <td
                        rowSpan={el.transactionRowSpan}
                        className={style.verticalTop}
                      >
                        <div className={style.stickyCellTitle}>
                          <LinkWithHover
                            kind="document"
                            href={`/documents/${el.interpretation.document.id}`}
                            align={'start'}
                            side={'bottom'}
                            avoidCollisions={false}
                            cardContentClassName={style.documentHoverCard}
                          >
                            <a
                              onClick={() =>
                                setSelectedTransaction({
                                  documentTitle:
                                    el.interpretation.document.documentTitle,
                                  transactionId: el.transactionId,
                                  interpretationHref: el.interpretation.href,
                                })
                              }
                            >
                              {el.interpretation.document.documentTitle} #
                              {el.transactionId}
                            </a>
                          </LinkWithHover>
                          {el.expiration && <p>Lease Termination</p>}
                        </div>
                      </td>
                    ) : null}
                    {el.tractRowSpan ? (
                      <td
                        rowSpan={el.tractRowSpan}
                        className={style.verticalTop}
                      >
                        <div
                          className={classNames(
                            style.stickyCell,
                            style.twoLinesTruncate
                          )}
                        >
                          {el.tract}
                        </div>
                      </td>
                    ) : null}
                    {el.depthRowSpan ? (
                      <td
                        rowSpan={el.depthRowSpan}
                        className={style.verticalTop}
                      >
                        <div className={style.stickyCell}>{el.depth}</div>
                      </td>
                    ) : null}
                    <td className={style.entityCol}>
                      <LinkWithHover kind="entity" href={el.entity.entity.href}>
                        <Link to={el.entity.entity.href} target="_blank">
                          <span className={style.truncateText}>
                            {el.entity.entity.name}
                          </span>
                        </Link>
                      </LinkWithHover>
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.surface}
                        onClick={onExpandClick(i, 'surface')}
                        onExpandedClick={onExpandedClick(i, 'surface')}
                        changed={!!el.entity.surface?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="surface"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.executive}
                        onClick={onExpandClick(i, 'executive')}
                        onExpandedClick={onExpandedClick(i, 'executive')}
                        changed={!!el.entity.executive?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="executive"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.bonus}
                        onClick={onExpandClick(i, 'bonus')}
                        onExpandedClick={onExpandedClick(i, 'bonus')}
                        changed={!!el.entity.bonus?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="bonus"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.delay}
                        onClick={onExpandClick(i, 'delay')}
                        onExpandedClick={onExpandedClick(i, 'delay')}
                        changed={!!el.entity.delay?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="delay"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.royalty}
                        onClick={onExpandClick(i, 'royalty')}
                        onExpandedClick={onExpandedClick(i, 'royalty')}
                        changed={!!el.entity.royalty?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="royalty"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.fixedRoyalty}
                        onClick={onExpandClick(i, 'fixedRoyalty')}
                        onExpandedClick={onExpandedClick(i, 'fixedRoyalty')}
                        changed={!!el.entity.fixedRoyalty?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="fixedRoyalty"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.working}
                        onClick={onExpandClick(i, 'working')}
                        onExpandedClick={onExpandedClick(i, 'working')}
                        changed={!!el.entity.working?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="working"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.override}
                        onClick={onExpandClick(i, 'override')}
                        onExpandedClick={onExpandedClick(i, 'override')}
                        changed={!!el.entity.override?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="override"
                        index={i}
                      />
                    </td>
                    <td>
                      <InterestCell
                        cellValue={el.entity.netRevenueInterest}
                        onClick={onExpandClick(i, 'netRevenueInterest')}
                        onExpandedClick={onExpandedClick(
                          i,
                          'netRevenueInterest'
                        )}
                        changed={!!el.entity.netRevenueInterest?.changed}
                        setSelectedTransaction={setSelectedTransaction}
                        refsMap={refsMap}
                        setHighlightedRow={setHighlightedRow}
                        interpretationId={interpretationId}
                        interest={interest}
                        expandedRowIndex={expandedRow.rowIndex}
                        expandedRowInterest={expandedRow.interest}
                        cellInterest="netRevenueInterest"
                        index={i}
                      />
                    </td>
                    <td className={style.entityCol}>
                      <ul>
                        {el.burdenedEntities.map((entity) => (
                          <li key={entity.id}>
                            <Link to={entity.href} target="_blank">
                              <p className={style.truncateText}>
                                {entity.name}
                              </p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <InlineNotification
          kind="info"
          title="This interpretation does not affect ownerships"
          lowContrast
        />
      )}
    </div>
  );
};

const CalculationDetailsFetcher = ({
  renderTableProp,
  interpretationId,
  titleWorkspaceHref,
}: {
  titleWorkspaceHref: string;
  interpretationId: number;
  renderTableProp: (
    calculationDetails: TitleCalculationDetailsResponse[]
  ) => JSX.Element;
}) => {
  const calculationDetailsHref = `${titleWorkspaceHref}/interpretations/${interpretationId}/transactions`;

  const interpretationCalculationDetailsAtom =
    useCreateInterpretationCalculationDetailsAtom(calculationDetailsHref);
  const interpretationCalculationDetails = useAtomValue(
    interpretationCalculationDetailsAtom
  );

  if (
    interpretationCalculationDetails.data &&
    'error' in interpretationCalculationDetails.data
  ) {
    return (
      <InlineNotification
        kind="error"
        title={'Error loading calculation details for this interpretation'}
        lowContrast
      />
    );
  }
  if (interpretationCalculationDetails.loading)
    return <InlineLoading description="Loading calculation details..." />;

  if (!interpretationCalculationDetails.data) return null;

  const data = interpretationCalculationDetails.data;

  return <>{renderTableProp(data)}</>;
};

const CalculationDetails = (props: CalculationDetailsProps) => {
  return (
    <CalculationDetailsFetcher
      titleWorkspaceHref={props.titleWorkspaceHref}
      interpretationId={props.interpretationId}
      renderTableProp={(calculationDetails) => (
        <CalculationDetailsTable
          {...props}
          calculationDetails={calculationDetails}
        />
      )}
    />
  );
};

export { CalculationDetails };
