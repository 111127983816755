import { DotMark16 } from '@carbon/icons-react';
import style from './status-indicator.module.scss';
import classNames from 'classnames';
import { FC } from 'react';

interface Props {
  state: 'notConfirmed' | 'confirmed' | boolean;
  title?: string;
}

const StatusIndicator: FC<Props> = ({ state, children, title }) => {
  return (
    <span title={title} className={style.statusIndicator}>
      {state && (
        <DotMark16
          className={classNames(style.statusIndicatorIcon, {
            [style.confirmed]: state === 'confirmed',
          })}
        />
      )}
      {children}
    </span>
  );
};

export { StatusIndicator };
