const sortFields = [
  {
    id: 'date',
    text: 'Transaction Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'date',
    text: 'Transaction Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'effective_date',
    text: 'Effective Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'effective_date',
    text: 'Effective Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'file_date',
    text: 'File Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'file_date',
    text: 'File Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'instrument_date',
    text: 'Instrument Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'instrument_date',
    text: 'Instrument Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'recorded_date',
    text: 'Recorded Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'recorded_date',
    text: 'Recorded Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'updated_at',
    text: 'Updated Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'updated_at',
    text: 'Updated Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'type',
    text: 'Transaction Type - Ascending',
    direction: 'ASC',
  },
  {
    id: 'type',
    text: 'Transaction Type - Descending',
    direction: 'DESC',
  },
] as const;

export default sortFields;
