import { atom } from 'jotai';
import {
  WellFeaturesResponse,
  DepthRangeResponse,
  TractResponse,
  WellIntersectionResponse,
} from 'types/api-responses';
import { lastCalculationAtom } from '../atoms';
import { createResourceCacheMapAtom } from 'lib/atoms';

const selectedAssetIndexAtom = atom<{
  asset: 'WellIntersection' | 'Subtract';
  index: number;
}>({
  asset: 'Subtract',
  index: 0,
});

const subtractsAtom = atom((get) => {
  const calculation = get(lastCalculationAtom);

  if (!calculation) return [];
  if (!calculation.data) return [];
  if (calculation.data && 'error' in calculation.data) return [];

  return (calculation?.data.tracts || []).sort((a, b) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  });
});

const tractsMapAtom = createResourceCacheMapAtom<TractResponse>();
const depthRangesMapAtom = createResourceCacheMapAtom<DepthRangeResponse>();
const wellFeaturesMapAtom = createResourceCacheMapAtom<WellFeaturesResponse>();
const wellIntersectionsMapAtom =
  createResourceCacheMapAtom<WellIntersectionResponse>();

const wellIntersectionsAtom = atom((get) => {
  const calculation = get(lastCalculationAtom);
  if (!calculation) return [];
  if (!calculation.data) return [];
  if (calculation.data && 'error' in calculation.data) return [];

  return (calculation.data.wellIntersections || []).sort((a, b) => {
    return a.well.name < b.well.name ? -1 : a.well.name > b.well.name ? 1 : 0;
  });
});

const subtractCurrentDepthRangeMapBaseAtom = atom<
  Record<string, number | undefined>
>({});
const subtractCurrentDepthRangeMapAtom = atom<
  Record<string, number | undefined>,
  [{ subtractId: number; depthIndex: number }],
  void
>(
  (get) => get(subtractCurrentDepthRangeMapBaseAtom),
  (get, set, { subtractId, depthIndex }) => {
    const currentMap = get(subtractCurrentDepthRangeMapBaseAtom);
    set(subtractCurrentDepthRangeMapBaseAtom, {
      ...currentMap,
      [subtractId]: depthIndex,
    });
  }
);

const resetAssetStateAtom = atom(
  () => null,
  (_get, set) => {
    set(subtractCurrentDepthRangeMapBaseAtom, {});
    set(wellFeaturesMapAtom, { resetCache: true });
    set(selectedAssetIndexAtom, { asset: 'Subtract', index: 0 });
  }
);

export {
  resetAssetStateAtom,
  selectedAssetIndexAtom,
  subtractCurrentDepthRangeMapAtom,
  subtractsAtom,
  wellFeaturesMapAtom,
  wellIntersectionsAtom,
  tractsMapAtom,
  depthRangesMapAtom,
  wellIntersectionsMapAtom,
};
