import {
  Button,
  Form,
  InlineNotification,
  Tile,
} from 'carbon-components-react';
import { Buttons } from 'components/buttons';

import style from './page.module.scss';
import { GeneralSettings } from './general-settings';
import { StartingInterestList } from './starting-interest-list';
import { useHistory } from 'react-router-dom';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  saveStartingInterestAtom,
  useTitleWorkspaceAtom,
  useTitleWorkspaceSettingsFormAtom,
} from './atoms';
import {
  getFormHasChanges,
  getFormIsInvalidAtom,
  getFormValuesAtom,
} from 'atoms/form-atoms';
import { useMemo, useState } from 'react';
import { getPayload } from './utils';
import { AtomizedTitleWorkspaceSettingsForm } from './types';
import { FieldErrorNotification } from 'components/field-error-notification';
import { ErrorsType } from 'lib/types';
import { TitleWorkspaceResponse } from 'types/api-responses';
import { getCurrentDateString } from 'utils/dates';
import {
  newTitleCalculationAtom,
  titleCalculationPusherErrorMessageAtom,
} from 'atoms/title-calculation-atoms';
import ErrorToast from 'components/error-toast';

const ButtonList = ({
  calculationsHref,
  form,
  workspaceHref,
  runningCalculationChannel,
}: {
  calculationsHref: string;
  form: AtomizedTitleWorkspaceSettingsForm;
  workspaceHref: string;
  runningCalculationChannel?: string | null;
}) => {
  const [isInvalidAtom, formValuesAtom] = useMemo(() => {
    const formAtom = atom(form);
    return [
      getFormIsInvalidAtom(formAtom),
      getFormValuesAtom(formAtom, 'dirty-values'),
    ] as const;
  }, [form]);

  const [errors, setErrors] = useState<ErrorsType | undefined>(undefined);
  const isInvalid = useAtomValue(isInvalidAtom);
  const formValues = useAtomValue(formValuesAtom);
  const [saveInterests, runSaveInterest] = useAtom(saveStartingInterestAtom);
  const history = useHistory();
  const setTitleCalculationPusherErrorMessage = useSetAtom(
    titleCalculationPusherErrorMessageAtom
  );
  const runCalculation = useSetAtom(newTitleCalculationAtom);
  const [isTitleCalculationError, setIsTitleCalculationError] = useState(false);

  return (
    <>
      {isTitleCalculationError && (
        <ErrorToast message="Calculation failed. Please contact support." />
      )}
      {errors && <FieldErrorNotification errors={errors} />}
      <Buttons>
        <Button
          onClick={() => {
            setIsTitleCalculationError(false);
            runSaveInterest({
              url: workspaceHref,
              type: 'PATCH',
              data: getPayload(formValues),
              onSuccess: () => {
                runCalculation({
                  url: calculationsHref,
                  type: 'POST',
                  data: {
                    asOfDate: getCurrentDateString(),
                  },
                  onSuccess: () => {
                    setTitleCalculationPusherErrorMessage('');
                    history.goBack();
                  },
                  onError: () => {
                    setIsTitleCalculationError(true);
                  },
                });
              },
              onError: (errors) => {
                setErrors(errors);
              },
            });
          }}
          disabled={
            isInvalid || saveInterests.loading || runningCalculationChannel
          }
        >
          Update Workspace
        </Button>
        <div style={{ width: '1rem' }} />
        <Button kind="secondary" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Buttons>
    </>
  );
};

const UnsavedChanges = ({
  form,
}: {
  form: AtomizedTitleWorkspaceSettingsForm;
}) => {
  const hasPendingChangesAtom = useMemo(
    () => getFormHasChanges(atom(form)),
    [form]
  );
  const hasPendingChanges = useAtomValue(hasPendingChangesAtom);

  if (!hasPendingChanges) return null;

  return (
    <InlineNotification
      kind="info"
      lowContrast
      title="Unsaved changes"
      hideCloseButton={true}
    />
  );
};

const StartingInterestPageImpl = ({
  workspace,
  href,
}: {
  workspace: TitleWorkspaceResponse;
  href: string;
}) => {
  const formAtom = useTitleWorkspaceSettingsFormAtom(workspace);
  const form = useAtomValue(formAtom);
  if (!form) return null;

  return (
    <div className={style.businessCard}>
      <Tile className={style.tile}>
        <Form title="">
          <GeneralSettings form={form} />
          <StartingInterestList form={form} />
          <UnsavedChanges form={form} />
          <ButtonList
            form={form}
            workspaceHref={href}
            calculationsHref={workspace.resources.calculations.href}
            runningCalculationChannel={workspace.channel}
          />
        </Form>
      </Tile>
    </div>
  );
};

const StartingInterestPage = ({ workspaceHref }: { workspaceHref: string }) => {
  const workspaceAtom = useTitleWorkspaceAtom(workspaceHref);
  const workspace = useAtomValue(workspaceAtom);

  if (workspace.data && 'error' in workspace.data)
    return (
      <InlineNotification
        kind="error"
        title={workspace.data.error}
        lowContrast
      />
    );

  if (!workspace.data)
    return (
      <InlineNotification
        kind="error"
        title={'Something went wrong'}
        lowContrast
      />
    );
  return (
    <StartingInterestPageImpl workspace={workspace.data} href={workspaceHref} />
  );
};

export { StartingInterestPage };
