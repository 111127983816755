import { Content } from 'carbon-components-react';
import { LoginPage } from './login';
import { Header } from './header';
import { useAtomValue } from 'jotai';
import { rootResourceAtom } from 'atoms/root';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NewReleaseModal } from 'components/new-release-modal';

const AppShell = ({
  Router,
  csrfToken,
}: {
  Router: React.FunctionComponent;
  csrfToken: string;
}) => {
  const root = useAtomValue(rootResourceAtom);
  if (!root) return null;
  return (
    <HelmetProvider>
      <Helmet>
        <title>Insights</title>
      </Helmet>
      <Header title={'Insights'} />
      <Content id="main-content">
        {root && root.session ? (
          <>
            <NewReleaseModal />
            <Router />
          </>
        ) : (
          <LoginPage csrfToken={csrfToken} />
        )}
      </Content>
    </HelmetProvider>
  );
};

export { AppShell };
