import { Tab, Tabs } from 'carbon-components-react';
import { useHistory, useLocation } from 'react-router-dom';

const selectedIndex = (pathname: string) => {
  if (pathname.includes('documents')) return 0;
  if (pathname.includes('wells')) return 1;
  return 0;
};

const SearchTabs = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  return (
    <Tabs
      selected={selectedIndex(pathname)}
      onSelectionChange={(index) => {
        if (index === 0) {
          history.push('/documents');
          return;
        }
        if (index === 1) {
          history.push('/wells');
          return;
        }
      }}
      selectionMode="manual"
    >
      <Tab id="tab-documents" label="Documents" renderContent={() => null} />
      <Tab id="tab-wells" label="Wells" renderContent={() => null} />
    </Tabs>
  );
};

export { SearchTabs };
