import {
  createResourceAtom,
  createResourceCacheMapAtom,
  createSingleCacheEntryAtom,
} from 'lib/atoms';
import { DevelopmentAreaResponse } from 'types/api-responses';
import {
  DevelopmentAreaEvaluationResponse,
  DevelopmentAreaEvaluationsResponse,
  EvalSlotResponse,
} from 'types/evaluation-api/responses';
import { useMemo } from 'react';

const useDevelopmentAreaAtom = (href: string) => {
  return useMemo(
    () => createResourceAtom<DevelopmentAreaResponse>(href),
    [href]
  );
};

const useDevelopmentAreaSlotsAtom = (href: string) => {
  return useMemo(() => createResourceAtom<EvalSlotResponse[]>(href), [href]);
};

const useEvaluationListAtom = (href: string) => {
  return useMemo(
    () => createResourceAtom<DevelopmentAreaEvaluationsResponse[]>(href),
    [href]
  );
};

const evaluationCacheAtom =
  createResourceCacheMapAtom<DevelopmentAreaEvaluationResponse>();

const useEvaluationAtom = (href: string) =>
  useMemo(() => createSingleCacheEntryAtom(evaluationCacheAtom)(href), [href]);

export {
  useDevelopmentAreaAtom,
  useDevelopmentAreaSlotsAtom,
  useEvaluationAtom,
  useEvaluationListAtom,
};
