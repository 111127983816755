import { ChartBarFloating16, AppConnectivity16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useSetAtom } from 'jotai';
import { Suspense, lazy, useState } from 'react';
import { graphEntitiesAtom } from './atoms';
import { setPartsPropertyOnNodeClick } from './gojs-graph';
import { TitleWorkspaceResponse } from 'types/api-responses';

const RunsheetGraph = lazy(() => import('./runsheet-graph'));

const RunsheetModal = ({
  titleWorkspace,
}: {
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const [graphOpen, setGraphOpen] = useState(false);
  const setGraphEntities = useSetAtom(graphEntitiesAtom);
  return (
    <>
      <div>
        <Button
          kind="ghost"
          size="sm"
          renderIcon={ChartBarFloating16}
          onClick={() =>
            window.open(
              `${titleWorkspace.href}/timeline`,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <span>Runsheet Timeline</span>
        </Button>
      </div>
      <div>
        <Button
          kind="ghost"
          size="sm"
          renderIcon={AppConnectivity16}
          onClick={() => setGraphOpen(true)}
        >
          <span>Open runsheet graph</span>
        </Button>
      </div>
      {graphOpen && (
        <Suspense fallback={<div>Loading</div>}>
          <RunsheetGraph
            onClose={() => {
              setGraphOpen(false);
              setGraphEntities({ type: null, entities: [] });
              setPartsPropertyOnNodeClick('Opacity');
            }}
            titleWorkspace={titleWorkspace}
          />
        </Suspense>
      )}
    </>
  );
};

export { RunsheetModal };
