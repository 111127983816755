import { Route as ReactRouterRoute, RouteProps } from 'react-router-dom';
import { ErrorPage } from './error-page';
import ErrorBoundary from './error-boundary';

const Route = ({ children, ...props }: RouteProps) => {
  return (
    <ReactRouterRoute {...props}>
      <ErrorBoundary fallback={<ErrorPage />}>{children}</ErrorBoundary>
    </ReactRouterRoute>
  );
};

export { Route };
