import { useAtomValue } from 'jotai';
import { Layer, Source } from 'react-map-gl';
import { createMapSourceAtom } from './atoms';
import { MapUserConfigAtom } from './types';
import { circlePaint, textSizeInterpolate } from './utils';
import { mapColors } from './map-colors';
import { useMemo } from 'react';

const WellsLayer = ({
  mapConfigAtom,
}: {
  mapConfigAtom: MapUserConfigAtom;
}) => {
  const wellSourceAtom = useMemo(() => createMapSourceAtom('wells'), []);
  const mapConfig = useAtomValue(mapConfigAtom);
  const wellSource = useAtomValue(wellSourceAtom);

  if (!wellSource) {
    return null;
  }

  const wellLayers = mapConfig.wells.options;

  return (
    <Source id="wells" type="vector" url={wellSource}>
      <Layer
        id="wells-surface-hole-isolated"
        type="circle"
        source-layer="surface-hole"
        filter={[
          'all',
          ['==', 'hole_direction', 'HORIZONTAL'],
          ['!=', 'status', 'inactive'],
          ['!=', 'status', 'expired_permit'],
          ['in', 'uwi', ''],
        ]}
        paint={circlePaint(mapColors.surfaceHoleFill)}
      />
      <Layer
        id="wells-bottom-hole-isolated"
        type="circle"
        source-layer="bottom-hole"
        filter={[
          'all',
          ['==', 'hole_direction', 'HORIZONTAL'],
          ['!=', 'status', 'inactive'],
          ['!=', 'status', 'expired_permit'],
          ['in', 'uwi', ''],
        ]}
        paint={circlePaint(mapColors.bottomHoleFill)}
      />
      <Layer
        id="wells-survey-isolated"
        type="line"
        source-layer="survey"
        filter={[
          'all',
          ['==', 'hole_direction', 'HORIZONTAL'],
          ['!=', 'status', 'inactive'],
          ['!=', 'status', 'expired_permit'],
          ['in', 'uwi', ''],
        ]}
        paint={{
          'line-color': mapColors.surveyFill,
          'line-width': 0.5,
        }}
      />
      <Layer
        id="wells-stick-isolated"
        type="line"
        source-layer="stick"
        filter={[
          'all',
          ['==', 'hole_direction', 'HORIZONTAL'],
          ['==', 'has_survey', false],
          ['!=', 'status', 'inactive'],
          ['!=', 'status', 'expired_permit'],
          ['in', 'uwi', ''],
        ]}
        paint={{
          'line-color': mapColors.stickFill,
          'line-width': 0.5,
          'line-dasharray': [6, 2],
        }}
      />
      {wellLayers.surfaceHole && (
        <Layer
          id="wells-surface-hole"
          type="circle"
          source-layer="surface-hole"
          filter={[
            'all',
            ['==', 'hole_direction', 'HORIZONTAL'],
            ['!=', 'status', 'inactive'],
            ['!=', 'status', 'expired_permit'],
          ]}
          paint={circlePaint(mapColors.surfaceHoleFill)}
        />
      )}
      {wellLayers.bottomHole && (
        <Layer
          id="wells-bottom-hole"
          type="circle"
          source-layer="bottom-hole"
          filter={[
            'all',
            ['==', 'hole_direction', 'HORIZONTAL'],
            ['!=', 'status', 'inactive'],
            ['!=', 'status', 'expired_permit'],
          ]}
          paint={circlePaint(mapColors.bottomHoleFill)}
        />
      )}
      {wellLayers.survey && (
        <Layer
          id="wells-survey"
          type="line"
          source-layer="survey"
          filter={[
            'all',
            ['==', 'hole_direction', 'HORIZONTAL'],
            ['!=', 'status', 'inactive'],
            ['!=', 'status', 'expired_permit'],
          ]}
          paint={{
            'line-color': mapColors.surveyFill,
            'line-width': 0.5,
          }}
        />
      )}
      {wellLayers.stick && (
        <Layer
          id="wells-stick"
          type="line"
          source-layer="stick"
          filter={[
            'all',
            ['==', 'hole_direction', 'HORIZONTAL'],
            ['==', 'has_survey', false],
            ['!=', 'status', 'inactive'],
            ['!=', 'status', 'expired_permit'],
          ]}
          paint={{
            'line-color': mapColors.stickFill,
            'line-width': 0.5,
            'line-dasharray': [6, 2],
          }}
        />
      )}
      {wellLayers.vertical && (
        <Layer
          id="wells-vertical"
          type="symbol"
          source-layer="surface-hole"
          filter={[
            'all',
            ['==', 'hole_direction', 'VERTICAL'],
            ['!=', 'status', 'inactive'],
            ['!=', 'status', 'expired_permit'],
          ]}
          layout={{
            'text-field': [
              'match',
              ['get', 'status'],
              ['producing', 'duc', 'permit'],
              '●',
              '×',
            ],
            'text-allow-overlap': true,
            'text-size': textSizeInterpolate,
          }}
        />
      )}
    </Source>
  );
};

export default WellsLayer;
