import { AtomField, AtomizedForm } from 'atoms/form-atoms';
import {
  Checkbox,
  Column,
  ContentSwitcher,
  FormGroup,
  Grid,
  RadioButton,
  RadioButtonGroup,
  Row,
  Switch,
  Tile,
} from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { useAtom, useAtomValue } from 'jotai';

import style from './drilling-assumption.module.scss';
import { useMemo, useState } from 'react';
import {
  DucAttributes,
  NtdAttributes,
  PermitAttributes,
} from 'types/api-payloads';
import { DateField } from 'components/form-fields/date-field';
import { NumberField } from 'components/form-fields/number-field';
import { DrillingAssumptionsParameters } from './types';

const getLTDTabIndex = (
  assumptionType: DrillingAssumptionsParameters['ltdAttributes']['kind']
) => {
  switch (assumptionType) {
    case 'per_dev_area':
      return 0;
    case 'target_most_recent':
    case 'target_as_of':
      return 1;
    case 'fractional_wells':
      return 2;
  }
};

const LTDTabOptions = ({
  field,
}: {
  field: AtomField<DrillingAssumptionsParameters['ltdAttributes']['kind']>;
}) => {
  const [{ value }, setValue] = useAtom(field);
  const [selectedIndex, setSelectedIndex] = useState(getLTDTabIndex(value));
  return (
    <ContentSwitcher
      selectedIndex={selectedIndex}
      onChange={(e) => {
        switch (e.index) {
          case 0:
            setValue('per_dev_area');
            setSelectedIndex(0);
            break;
          case 1:
            setValue('target_most_recent');
            setSelectedIndex(1);
            break;
          case 2:
            setValue('fractional_wells');
            setSelectedIndex(2);
            break;
        }
      }}
    >
      <Switch text="Across Dev Area" disabled={false} />
      <Switch text="Target Average Well Drilled" disabled={false} />
      <Switch text="Fractional Wells" disabled={false} />
    </ContentSwitcher>
  );
};

const NTDTabOptions = ({
  assumptionsType,
  ntdBlendField,
}: {
  assumptionsType: AtomField<NtdAttributes['kind']>;
  ntdBlendField: AtomField<boolean>;
}) => {
  const [{ value }, setAssumptionsType] = useAtom(assumptionsType);
  const [ntdBlend, setNtdBlend] = useAtom(ntdBlendField);
  const [selectedIndex, setSelectedIndex] = useState(
    value === 'discrete' ? 0 : 1
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originalNtdBlend = useMemo(() => ntdBlend.value, []);
  return (
    <ContentSwitcher
      selectedIndex={selectedIndex}
      onChange={(e) => {
        switch (e.index) {
          case 0:
            setAssumptionsType('discrete');
            setSelectedIndex(0);
            setNtdBlend(originalNtdBlend);
            break;
          case 1:
            setAssumptionsType('fractional_wells');
            setSelectedIndex(1);
            setNtdBlend(true);
            break;
        }
      }}
    >
      <Switch text="Discrete" disabled={false} />
      <Switch text="Fractional Wells" disabled={false} />
    </ContentSwitcher>
  );
};

const LTDAssumptions = ({
  drillingAssumptions,
}: {
  drillingAssumptions: AtomizedForm<DrillingAssumptionsParameters>;
}) => {
  const ltdAttributes = drillingAssumptions.ltdAttributes;
  return (
    <FormGroup legendText="">
      <FormRow>
        <DateField
          id="ltd-start-date"
          labelText="First LTD Start Date"
          light
          field={ltdAttributes.firstDrillingStartDate}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="spudToProductionMonths"
          label="Spud to Production (Months)"
          light
          min={0}
          invalidText="Must be positive number"
          field={ltdAttributes.spudToProductionMonths}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="drillingIntervalMonths"
          label="Months Between LTD"
          light
          min={0}
          invalidText="Must be positive number"
          field={ltdAttributes.drillingIntervalMonths}
        />
      </FormRow>
    </FormGroup>
  );
};

const LTDAssumptionsForTargetWellsDrilled = ({
  drillingAssumptions,
}: {
  drillingAssumptions: AtomizedForm<DrillingAssumptionsParameters>;
}) => {
  const ltdAttributes = drillingAssumptions.ltdAttributes;
  const [assumptionType, setAssumption] = useAtom(ltdAttributes.kind);
  return (
    <FormGroup legendText="">
      <FormRow>
        <RadioButtonGroup
          name="wells-drilled-kind-radio"
          defaultSelected={assumptionType.value}
          orientation="horizontal"
          onChange={(
            value: DrillingAssumptionsParameters['ltdAttributes']['kind']
          ) => {
            setAssumption(value);
          }}
        >
          <RadioButton labelText="Most Recent" value={'target_most_recent'} />
          <RadioButton labelText="As Of:" value={'target_as_of'} />
        </RadioButtonGroup>
      </FormRow>
      <FormRow>
        <DateField
          id="as_of_date"
          light
          short
          datePickerType="single"
          labelText="As Of Date"
          disabled={assumptionType.value !== 'target_as_of'}
          field={ltdAttributes.asOfDate}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="firstDrillingStartDate"
          label="Years"
          light
          min={0}
          invalidText="Must be positive number"
          field={ltdAttributes.averageWellDrilledYears}
        />
      </FormRow>
      <FormRow>
        <DateField
          id="ltd-start-date"
          labelText="First LTD Start Date"
          light
          field={ltdAttributes.firstDrillingStartDate}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="spudToProductionMonths"
          label="Spud to Production (Months)"
          light
          min={0}
          invalidText="Must be positive number"
          field={ltdAttributes.spudToProductionMonths}
        />
      </FormRow>
    </FormGroup>
  );
};

const NTDAssumptionsForFractionalWells = ({
  ntdAttributes,
  type,
}: {
  type: 'permit' | 'duc';
  ntdAttributes: AtomizedForm<Required<NtdAttributes>>;
}) => {
  return (
    <FormGroup legendText="">
      <FormRow>
        <DateField
          id={`${type}-fractionalStartDate`}
          labelText="Start Date"
          light
          field={ntdAttributes.startDate}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id={`${type}-fractionalStartMonth`}
          label="Start Month"
          light
          field={ntdAttributes.startMonth}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id={`${type}-fractionalEndMonth`}
          label="End Month"
          light
          field={ntdAttributes.endMonth}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id={`${type}-fractionalSpudToProductionMonths`}
          label="Spud to Production (Months)"
          light
          field={ntdAttributes.spudToProductionMonths}
        />
      </FormRow>
    </FormGroup>
  );
};

const LTDAssumptionsForFractionalWells = ({
  drillingAssumptions,
}: {
  drillingAssumptions: AtomizedForm<DrillingAssumptionsParameters>;
}) => {
  const ltdAttributes = drillingAssumptions.ltdAttributes;
  return (
    <FormGroup legendText="">
      <FormRow>
        <DateField
          id="fractionalStartDate"
          labelText="Start Date"
          light
          field={ltdAttributes.startDate}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="averageWellDrilledYears"
          label="Average Well Drilled (Years)"
          light
          field={ltdAttributes.averageWellDrilledYears}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="drillStartMonths"
          label="Drill Start (Months)"
          light
          field={ltdAttributes.drillStartMonths}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="drillOffsetMonths"
          label="Drill Offset (Months)"
          light
          field={ltdAttributes.drillOffsetMonths}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="spudToProductionMonths"
          label="Spud to Production (Months)"
          light
          field={ltdAttributes.spudToProductionMonths}
        />
      </FormRow>
    </FormGroup>
  );
};

const NTDAssumptions = ({
  ntdAttributes,
  type,
}: {
  ntdAttributes:
    | AtomizedForm<Required<DucAttributes>>
    | AtomizedForm<Required<PermitAttributes>>;
  type: 'permit' | 'duc';
}) => {
  const assumptionType = useAtomValue(ntdAttributes.kind);
  const isPermit = 'permitToProductionMonths' in ntdAttributes;
  const title = isPermit ? 'Permit' : 'DUC';
  return (
    <Tile>
      <h3 className={style.subtitle}>{`${title} Pace Assumptions`}</h3>
      <BlendCheckbox
        id={`blend-${type}-assumptions`}
        field={ntdAttributes.typeCurveBlend}
      />
      <FormGroup legendText="" className={style.ntdAssumptions}>
        <NTDTabOptions
          assumptionsType={ntdAttributes.kind}
          ntdBlendField={ntdAttributes.typeCurveBlend}
        />
        {assumptionType.value === 'discrete' && (
          <>
            {isPermit ? (
              <FormRow>
                <NumberField
                  id="permitToProductionMonths"
                  label="Permit to Production Months"
                  light
                  min={0}
                  invalidText="Must be positive number"
                  field={ntdAttributes.permitToProductionMonths}
                />
              </FormRow>
            ) : (
              <FormRow>
                <NumberField
                  id="duc-spudToProductionMonths"
                  label="Spud to Production (Months)"
                  light
                  min={0}
                  invalidText="Must be positive number"
                  field={ntdAttributes.spudToProductionMonths}
                />
              </FormRow>
            )}
          </>
        )}
        {assumptionType.value === 'fractional_wells' && (
          <NTDAssumptionsForFractionalWells
            type={isPermit ? 'permit' : 'duc'}
            ntdAttributes={ntdAttributes}
          />
        )}
      </FormGroup>
    </Tile>
  );
};

const BlendCheckbox = ({
  id,
  field,
}: {
  id: string;
  field: AtomField<boolean>;
}) => {
  const [{ value }, setValue] = useAtom(field);
  return (
    <FormRow>
      <Checkbox
        id={id}
        checked={value}
        onChange={(checked: boolean) => setValue(checked)}
        labelText="Use Blended Type Curves"
      />
    </FormRow>
  );
};

const DrillingAssumptions = ({
  form,
}: {
  form: AtomizedForm<DrillingAssumptionsParameters>;
}) => {
  const assumptionType = useAtomValue(form.ltdAttributes.kind);

  return (
    <Grid fullWidth condensed className={style.drillingForm}>
      <Row className={style.rowThirds}>
        <Column>
          <Tile>
            <h3 className={style.subtitle}>LTD Pace Assumptions</h3>
            <BlendCheckbox
              id="blend-ltd-assumptions"
              field={form.ltdAttributes.typeCurveBlend}
            />
            <LTDTabOptions field={form.ltdAttributes.kind} />
            {assumptionType.value === 'per_dev_area' && (
              <LTDAssumptions drillingAssumptions={form} />
            )}
            {(assumptionType.value === 'target_most_recent' ||
              assumptionType.value === 'target_as_of') && (
              <LTDAssumptionsForTargetWellsDrilled drillingAssumptions={form} />
            )}
            {assumptionType.value === 'fractional_wells' && (
              <LTDAssumptionsForFractionalWells drillingAssumptions={form} />
            )}
          </Tile>
        </Column>
        <Column>
          <NTDAssumptions ntdAttributes={form.permitAttributes} type="permit" />
        </Column>
        <Column>
          <NTDAssumptions ntdAttributes={form.ducAttributes} type="duc" />
        </Column>
      </Row>
    </Grid>
  );
};

export { DrillingAssumptions };
