import { useAtomValue } from 'jotai';
import { useCreateEvaluationPackageOverviewCombinedAtom } from './atoms';
import {
  DataTable,
  DataTableCustomRenderProps,
  InlineLoading,
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import React from 'react';
import { PackageEvaluationOverviewCombinedResponse } from 'types/packages-api/responses';
import { FORMATTER } from 'lib/ui';
import { capitalizeFirstLetter } from 'utils/strings';
import { Link } from 'components/link';
import style from './package-overview-combined.module.scss';

const PackageOverviewCombined = ({ href }: { href: string }) => {
  const overviewAtom = useCreateEvaluationPackageOverviewCombinedAtom(href);
  const overview = useAtomValue(overviewAtom);

  if (overview.loading) return <InlineLoading title="Loading overview" />;

  if (overview.data && 'error' in overview.data)
    return (
      <InlineNotification
        kind="error"
        title={overview.data.error}
        lowContrast
      />
    );

  if (!overview.data) {
    return (
      <InlineNotification
        kind="error"
        title={'Something went wrong'}
        lowContrast
      />
    );
  }

  return <CombinedTable combinedOverview={overview.data} />;
};

const headersList = [
  {
    key: 'pricingCase',
    header: 'Pricing Case',
  },
  {
    key: 'name',
    header: 'Reserve Category',
  },
  {
    key: 'totalDollarAmount',
    header: 'Total $ Amount',
    format: (v: unknown) => (typeof v === 'number' ? FORMATTER.format(v) : v),
  },
];

const CombinedTable = ({
  combinedOverview,
}: {
  combinedOverview: PackageEvaluationOverviewCombinedResponse;
}) => {
  const maxRows = combinedOverview.combinedPricingCases
    .find((el) => el.name === 'Max')
    ?.reserveCategories?.map((el, i) => ({
      ...el,
      pricingCase: 'Max',
      id: `max-${i}`,
    }));
  const targetRows = combinedOverview.combinedPricingCases
    .find((el) => el.name === 'Target')
    ?.reserveCategories?.map((el, i) => ({
      ...el,
      pricingCase: 'Target',
      id: `target-${i}`,
    }));

  const rows = [...(targetRows || []), ...(maxRows || [])];

  return (
    <DataTable rows={rows} headers={headersList}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
      }: DataTableCustomRenderProps) => (
        <Table {...getTableProps()} className={style.combinedTable}>
          <TableHead>
            <TableRow>
              <TableExpandHeader />
              {headers.map((header) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              let reserveCategory: string | undefined = undefined;
              let pricingCase: string | undefined = undefined;
              return (
                <React.Fragment key={row.id}>
                  <TableExpandRow {...getRowProps({ row })}>
                    {row.cells.map((cell, cellIndex) => {
                      reserveCategory = cell.id.includes(':name')
                        ? cell.value
                        : reserveCategory;
                      pricingCase = cell.id.includes(':pricingCase')
                        ? cell.value
                        : pricingCase;
                      return (
                        <TableCell key={cell.id}>
                          {headersList[cellIndex].format?.(cell.value) ??
                            (cell.value || '--')}
                        </TableCell>
                      );
                    })}
                  </TableExpandRow>
                  <TableExpandedRow
                    colSpan={headers.length + 1}
                    className={style.combinedCell}
                  >
                    <DetailsTable
                      evaluations={combinedOverview.evaluations}
                      reserveCategory={reserveCategory}
                      pricingCase={pricingCase}
                    />
                  </TableExpandedRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
    </DataTable>
  );
};

const DetailsTable = ({
  evaluations,
  reserveCategory,
  pricingCase,
}: {
  evaluations: PackageEvaluationOverviewCombinedResponse['evaluations'];
  reserveCategory: string | undefined;
  pricingCase: string | undefined;
}) => {
  if (!pricingCase || !reserveCategory) return null;
  const details = evaluations.map((el) => {
    const cases = el.pricingCases.find((el) => el.name === pricingCase);
    return {
      unitType: el.unitType,
      evaluation: el.evaluation,
      cases: cases?.reserveCategories.find((el) => el.name === reserveCategory),
    };
  });

  return (
    <TableContainer className={style.combinedEvaluationsTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Scenario</TableHeader>
            <TableHeader>Interest Type</TableHeader>
            <TableHeader>PV Rate</TableHeader>
            <TableHeader>$/Unit</TableHeader>
            <TableHeader>Units</TableHeader>
            <TableHeader>UOM</TableHeader>
            <TableHeader>Total $ Amount</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((el, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  <Link to={el.evaluation.scenario.href} target="_blank">
                    {el.evaluation.scenario.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {capitalizeFirstLetter(el.evaluation.scenario.interestType)}
                </TableCell>
                <TableCell>{'--'}</TableCell>
                <TableCell>
                  {el.cases?.dollarsPerNetAcre
                    ? FORMATTER.format(el.cases.dollarsPerNetAcre)
                    : '--'}
                </TableCell>
                <TableCell>
                  {el.cases?.netAcres ? el.cases.netAcres.toFixed(2) : '--'}
                </TableCell>
                <TableCell>{el.unitType}</TableCell>
                <TableCell>
                  {el.cases?.totalDollarAmount
                    ? FORMATTER.format(el.cases.totalDollarAmount)
                    : '--'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { PackageOverviewCombined };
