import { Atom, useAtom, useAtomValue } from 'jotai';
import {
  PackageEvaluationAllocationResponse,
  PackageEvaluationSlotAllocationsResponse,
} from 'types/packages-api/responses';
import {
  slotAllocationsAtom,
  slotAllocationsHrefAtom,
  unitAllocationsAtom,
  unitAllocationsHrefAtom,
} from '../atoms';
import { InlineLoading, InlineNotification } from 'carbon-components-react';
import { useEffect } from 'react';
import { Loadable } from 'jotai/vanilla/utils/loadable';

const LoadAllocationsImpl = <
  ReturnType extends unknown[],
  AtomType extends Atom<Loadable<ReturnType | undefined | { error: string }>>
>({
  render,
  atom,
}: {
  atom: AtomType;
  render: (slots: ReturnType | null) => JSX.Element;
}) => {
  const slotsRequest = useAtomValue(atom);
  if (slotsRequest.state === 'loading') return <InlineLoading />;
  if (
    slotsRequest.state === 'hasData' &&
    slotsRequest.data &&
    'error' in slotsRequest.data
  ) {
    return (
      <InlineNotification
        kind="error"
        title={slotsRequest.data.error}
        lowContrast
      />
    );
  }

  if (
    slotsRequest.state === 'hasData' &&
    slotsRequest.data &&
    !('error' in slotsRequest.data)
  )
    return render(slotsRequest.data.length ? slotsRequest.data : null);

  return null;
};

const LoadUnitsAllocations = ({
  href,
  render,
}: {
  href: string;
  render: (
    allocations: PackageEvaluationAllocationResponse[] | null
  ) => JSX.Element;
}) => {
  const [unitsHref, setUnitsHref] = useAtom(unitAllocationsHrefAtom);
  useEffect(() => {
    setUnitsHref(href);
  }, [href, setUnitsHref]);

  if (!unitsHref) return null;

  return (
    <LoadAllocationsImpl<
      PackageEvaluationAllocationResponse[],
      typeof unitAllocationsAtom
    >
      atom={unitAllocationsAtom}
      render={render}
    />
  );
};
const LoadSlotAllocations = ({
  slotHref,
  renderSlots,
  unitHref,
  renderUnits,
}: {
  slotHref: string;
  unitHref?: string;
  renderSlots: (
    slots: PackageEvaluationSlotAllocationsResponse[] | null
  ) => JSX.Element;
  renderUnits?: (
    units: PackageEvaluationAllocationResponse[] | null
  ) => JSX.Element;
}) => {
  const [slotsHref, setSlotHref] = useAtom(slotAllocationsHrefAtom);
  useEffect(() => {
    setSlotHref(slotHref);
  }, [slotHref, setSlotHref]);

  if (!slotsHref) return null;

  return (
    <LoadAllocationsImpl<
      PackageEvaluationSlotAllocationsResponse[],
      typeof slotAllocationsAtom
    >
      atom={slotAllocationsAtom}
      render={(data) => {
        if (!data && unitHref && renderUnits) {
          return <LoadUnitsAllocations href={unitHref} render={renderUnits} />;
        }
        return renderSlots(data);
      }}
    />
  );
};

export { LoadSlotAllocations };
