import {
  Tabs,
  Tab,
  Grid,
  Row,
  Column,
  InlineNotification,
  Tile,
} from 'carbon-components-react';
import { Field } from './field';
import { Date } from 'components/date';
import { ProductionGraph } from 'components/production-graph';
import { useAtomValue } from 'jotai';
import { useProductionForecastAtom, useProductionRecordAtom } from './atoms';
import NoDataSvg from 'images/srp-illustration-data.svg';
import { EmptyState } from 'components/empty-state';

import style from './production.module.scss';

function Forecast({ href }: { href: string }) {
  const productionForecastAtom = useProductionForecastAtom(href);
  const forecast = useAtomValue(productionForecastAtom);

  if (forecast.data && 'error' in forecast.data)
    return (
      <InlineNotification
        kind="error"
        title={forecast.data.error}
        lowContrast
      />
    );
  if (!forecast.data) return null;

  const { gas, liquid, water, dates, engineer, generatedOn, notes } =
    forecast.data;
  return (
    <Grid className="bx--no-gutter" fullWidth>
      <Row>
        <Column lg={10}>
          {dates ? (
            <ProductionGraph
              dates={dates}
              gas={gas || []}
              liquid={liquid || []}
              water={water || []}
              className={style.graph}
            />
          ) : (
            <Tile>
              <EmptyState
                headerText="No Data Available"
                icon={NoDataSvg}
                helperText="Currently Insights doesn’t have any production data."
              />
            </Tile>
          )}
        </Column>
        <Column lg={6}>
          <div className={style.statistics}>
            <section>
              <h4 className={style.sectionSubheader}>Decline details</h4>
              <div className={style.row}>
                <Field
                  label="Date of Decline"
                  value={generatedOn && <Date date={generatedOn} />}
                />
                <Field label="Engineer" value={engineer} />
              </div>
              <div className={style.row}>
                <Field label="Comments, Notes, and Parameters" value={notes} />
              </div>
            </section>
          </div>
        </Column>
      </Row>
    </Grid>
  );
}

export function Production({
  productionRecordHref,
  productionForecastHref,
}: {
  productionRecordHref: string;
  productionForecastHref: string;
}) {
  const productionRecordAtom = useProductionRecordAtom(productionRecordHref);
  const productionRecord = useAtomValue(productionRecordAtom);

  if (productionRecord.data && 'error' in productionRecord.data)
    return (
      <InlineNotification
        kind="error"
        title={productionRecord.data.error}
        lowContrast
      />
    );
  if (!productionRecord.data) return null;

  return (
    <>
      <Tabs className={style.variableWidth}>
        <Tab label="Production" key="production-tab">
          {productionRecord.data.dates ? (
            <ProductionGraph
              dates={productionRecord.data.dates}
              gas={productionRecord.data.gas}
              liquid={productionRecord.data.liquid}
              water={productionRecord.data.water}
              className={style.graph}
            />
          ) : (
            <Tile>
              <EmptyState
                headerText="No Data Available"
                icon={NoDataSvg}
                helperText="Currently Insights doesn’t have any production data."
              />
            </Tile>
          )}
        </Tab>
        <Tab label="PDP Declines" key="declines-tab">
          <Forecast href={productionForecastHref} />
        </Tab>
      </Tabs>
    </>
  );
}
