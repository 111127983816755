import { useState } from 'react';
import { TrashCan16 } from '@carbon/icons-react';
import { Button, ButtonProps } from 'carbon-components-react';
import { DeleteAtom } from 'atoms/create-resource-atom';
import { useAtom } from 'jotai';
import { DestroyConfirmation } from 'components/destroy-confirmation';

interface Props extends ButtonProps {
  deleteAtom: DeleteAtom;
  onSuccess: () => void;
  onError?: (error: unknown) => void;
  url: string;
}

const DestroyRecordButton = ({
  deleteAtom,
  url,
  onSuccess,
  onError,
  ...rest
}: Props) => {
  const [{ loading }, runDelete] = useAtom(deleteAtom);
  const [showDestroy, setShowDestroy] = useState(false);

  return (
    <>
      <Button
        renderIcon={TrashCan16}
        iconDescription={'Delete'}
        tooltipAlignment="end"
        tooltipPosition="top"
        kind="danger--ghost"
        onClick={() => setShowDestroy(true)}
        {...rest}
      >
        Delete
      </Button>
      <DestroyConfirmation
        open={showDestroy}
        primaryButtonDisabled={loading}
        onRequestClose={() => setShowDestroy(false)}
        onRequestSubmit={() => {
          runDelete({
            url,
            onSuccess,
            onError: (error) => {
              setShowDestroy(false);
              onError?.(error);
            },
          });
        }}
      />
    </>
  );
};

export { DestroyRecordButton };
