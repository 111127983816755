import { EntitySearch } from 'components/entity-search';
import { TitleWorkspaceResponse } from 'types/api-responses';

const TitleWorkspaceEntitiesPage = ({
  titleWorkspace,
}: {
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const href = titleWorkspace.resources.queries.entities.href;
  return (
    <div>
      <EntitySearch href={href} />
    </div>
  );
};

export { TitleWorkspaceEntitiesPage };
