import {
  CheckmarkFilledWarning16,
  CheckmarkOutlineWarning16,
} from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { formAtoms, documentAtom } from '../atoms';
import { AnchorJumpMenu } from '../anchor-jump-menu/anchor-jump-menu';

import style from './interpretation-navigation-buttons.module.scss';

interface Props {
  documentHeaderAttributes?: JSX.Element;
}

const InterpretationNavigationButtons = ({
  documentHeaderAttributes,
}: Props) => {
  const [document] = useAtom(documentAtom);
  const isAssumptionAtom = useMemo(
    () =>
      atom((get) => {
        return get(get(formAtoms).formAtom).assumption;
      }),
    []
  );
  const setField = useSetAtom(useAtomValue(formAtoms).formAtom);
  const isAssumption = useAtomValue(isAssumptionAtom).value;

  if (!document) {
    return null;
  }

  return (
    <nav className={style.assumptionArea}>
      {documentHeaderAttributes ? <>{documentHeaderAttributes}</> : null}
      <div className={style.assumptionRow}>
        <AnchorJumpMenu />
        <div className={style.btnsArea}>
          <Button
            renderIcon={
              isAssumption
                ? CheckmarkFilledWarning16
                : CheckmarkOutlineWarning16
            }
            kind="ghost"
            size="md"
            className={style.btnHeader}
            onClick={() =>
              setField({ field: 'assumption', value: !isAssumption })
            }
          >
            {isAssumption ? 'Unmark as an Assumption' : 'Mark as an Assumption'}
          </Button>
        </div>
      </div>
    </nav>
  );
};

export { InterpretationNavigationButtons };
