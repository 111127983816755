import { WarningFilled32 } from '@carbon/icons-react';
import { getError } from 'atoms/form-atoms';
import {
  Column,
  FileUploaderDropContainer,
  FileUploaderItem,
  Row,
} from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { TrashButton } from 'components/trash-button';
import { useAtom, useAtomValue } from 'jotai';

import style from './additional-info.module.scss';
import { fileAtom } from './atoms';
import { DocumentForms } from './types';

interface Props {
  formAtom: DocumentForms;
}

const UploadImage = ({ formAtom }: Props) => {
  const form = useAtomValue(formAtom);
  const [file, setFile] = useAtom(form.file);
  const [upload, setUpload] = useAtom(fileAtom);
  const hasFile = file.value;
  const error = getError(file);
  return (
    <FormRow>
      {!hasFile && (
        <FormRow>
          <Row>
            <Column
              lg={16}
              className={style.fileUploaderContainer}
              data-testid={'upload-area'}
            >
              <legend>Upload Image</legend>
              <p>Select only one file to upload that is in a PDF format</p>
              <FileUploaderDropContainer
                labelText="Drag and drop files here or upload"
                accept={['.pdf']}
                onAddFiles={(_event, { addedFiles }) => {
                  setUpload({ file: addedFiles[0], formAtom });
                }}
              />
            </Column>
          </Row>
          {error ? <div className={style.error}>{error}</div> : null}
        </FormRow>
      )}
      {hasFile ? (
        <FormRow>
          <Row>
            <Column>
              <FileUploaderItem
                name={file.value?.name}
                status={upload}
                invalid={upload === 'edit'}
                onDelete={() => setFile(undefined)}
              />
            </Column>
            <Column>
              <TrashButton
                description="Remove file"
                onClick={() => setFile(undefined)}
              />
            </Column>
          </Row>
        </FormRow>
      ) : null}
    </FormRow>
  );
};

const AdditionalInfo = ({ formAtom }: Props) => {
  return (
    <FormSection
      title="Additional Information"
      iconComponent={<WarningFilled32 />}
    >
      <UploadImage formAtom={formAtom} />
    </FormSection>
  );
};

export { AdditionalInfo };
