import { RunsheetGraphDataPoints } from 'pages/title-workspaces/overview/runsheet-graph/utils';

export type NodeDate = { year: number; month: number };
export type Node = {
  id: string;
  type: 'both' | 'no-link-out' | 'no-link-in';
  color: string;
  dates: Array<NodeDate>;
  hasLinksIn: boolean;
  hasLinksOut: boolean;
  index: number;
  name: string;
};

export type Link = {
  transactionDate: string;
  from: string;
  to: string;
  document: string;
  transactionType: string;
  transactionHref?: string;
};

const transform = (runsheetData: RunsheetGraphDataPoints) => {
  const uniqueYears: number[] = [];

  runsheetData.nodes.sort(
    (a, b) =>
      new Date(a.transactionDate).getTime() -
      new Date(b.transactionDate).getTime()
  );

  const nodesObj: Record<string, Node> = {};
  const linksObj: Record<string, Link[]> = {};

  runsheetData.nodes.forEach((node, index) => {
    nodesObj[node.key] = {
      id: node.key,
      type: 'no-link-in', // assume all nodes have both links in and out initially
      dates: [],
      hasLinksIn: false,
      hasLinksOut: false,
      name: node.name,
      color: '',
      index,
    };
  });

  // Iterating through the links array
  runsheetData.links.forEach((link) => {
    const year = new Date(link.transactionDate).getFullYear();
    const month = new Date(link.transactionDate).getMonth();

    if (uniqueYears.indexOf(year) === -1) {
      uniqueYears.push(year);
    }

    const date = { year, month: month + 1 };
    nodesObj[link.from].dates.push(date);
    nodesObj[link.to].dates.push(date);

    nodesObj[link.from].hasLinksOut = true;
    nodesObj[link.to].hasLinksIn = true;

    const xCoordinate = calculateXCoordinate(
      uniqueYears.findIndex((el) => el === date.year),
      date.month
    );
    const linkInfo = {
      from: link.from,
      to: link.to,
      transactionDate: link.transactionDate,
      document: link.documentTitle,
      transactionType: link.transactionType,
      transactionHref: link.url,
    };

    if (linksObj[xCoordinate]) {
      linksObj[xCoordinate].push(linkInfo);
    } else {
      linksObj[xCoordinate] = [linkInfo];
    }
  });

  Object.keys(nodesObj).forEach((node) => {
    const obj = nodesObj[node];
    if (obj.hasLinksIn && obj.hasLinksOut) {
      obj.type = 'both';
    }

    if (obj.hasLinksIn && !obj.hasLinksOut) {
      obj.type = 'no-link-out';
    }
    obj.color =
      obj.type === 'both'
        ? 'rgb(66,190,101)'
        : obj.type === 'no-link-in'
        ? 'rgb(69,137,255)'
        : 'rgb(255,131,43)';
    nodesObj[node].dates.sort((a: NodeDate, b: NodeDate) => a.year - b.year);
  });

  return { runsheetData, nodesObj, linksObj, uniqueYears };
};

const calculateXCoordinate = (yearIndex: number, month: number) => {
  return yearIndex * 12 + month;
};

export { calculateXCoordinate, transform };
