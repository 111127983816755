import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  HoverCardArrow,
} from 'components/hover-card/hover-card';
import {
  DevelopmentAreaResponse,
  DocumentInterpretationResponse,
  DocumentResponse,
  EntityResponse,
} from 'types/api-responses';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { ResourceFetcher } from 'components/resource-fetcher';
import { EntityPreview } from './entity-preview';
import { DocumentPreview } from './document-preview';
import { LeasePreview } from './lease-preview';

import style from './hover-card-resource.module.scss';
import { DevelopmentAreaPreview } from './development-area-preview';

type ResourceKind = 'entity' | 'document' | 'lease' | 'development-area';

const HoverCardResource = ({
  kind,
  href,
}: {
  href: string;
  kind: ResourceKind;
}) => {
  if (kind === 'entity')
    return (
      <ResourceFetcher<EntityResponse> component={EntityPreview} href={href} />
    );
  if (kind === 'document')
    return (
      <ResourceFetcher<DocumentResponse>
        component={DocumentPreview}
        href={href}
      />
    );
  if (kind === 'lease')
    return (
      <ResourceFetcher<DocumentInterpretationResponse>
        component={LeasePreview}
        href={href}
      />
    );
  if (kind === 'development-area')
    return (
      <ResourceFetcher<DevelopmentAreaResponse>
        component={DevelopmentAreaPreview}
        href={href}
      />
    );
  return <div>Unknown kind: {kind}</div>;
};

type HoverCardContentProps = React.ComponentPropsWithoutRef<
  typeof HoverCardPrimitive.Content
>;

const LinkWithHover = ({
  kind,
  href,
  children,
  align,
  side,
  avoidCollisions,
  cardContentClassName,
}: {
  kind: ResourceKind;
  href: string;
  children: JSX.Element;
  cardContentClassName?: string;
} & HoverCardContentProps) => {
  return (
    <HoverCard>
      <HoverCardContent
        align={align}
        side={side}
        avoidCollisions={avoidCollisions}
        className={cardContentClassName}
      >
        <HoverCardResource kind={kind} href={href} />
        <HoverCardArrow className={style.hoverCardArrow} />
      </HoverCardContent>
      <HoverCardTrigger asChild className={style.popIconLink}>
        <div>{children}</div>
      </HoverCardTrigger>
    </HoverCard>
  );
};

export { HoverCardResource, LinkWithHover };
