import { FolderDetails32 } from '@carbon/icons-react';
import classNames from 'classnames';
import { IconProps } from './types';

import style from './icons.module.scss';

const Package = ({ className }: IconProps) => {
  return (
    <div className={classNames(style.background, style.package, className)}>
      <FolderDetails32 className={classNames(style.icon, style.package)} />
    </div>
  );
};

export default Package;
