import { Link } from 'carbon-components-react';
import { useState } from 'react';

import style from './note.module.scss';

const Note = ({ noteText }: { noteText: string | null }) => {
  const [showCompleteNote, setShowCompleteNote] = useState(false);

  const toggleShowMore = () => {
    setShowCompleteNote(!showCompleteNote);
  };
  if (!noteText) return <> -- </>;
  if (noteText.length < 40) return <> {noteText} </>;
  return (
    <>
      {showCompleteNote
        ? noteText
        : noteText.length > 40 && noteText.slice(0, 40) + '...'}
      {noteText.length > 40 && (
        <Link onClick={toggleShowMore} className={style.readMore}>
          {showCompleteNote ? 'Show less' : 'Show more'}
        </Link>
      )}
    </>
  );
};

export { Note };
