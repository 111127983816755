import { WorkspaceImport16 } from '@carbon/icons-react';
import { InlineLoading, InlineNotification } from 'carbon-components-react';
import { Link } from 'components/link';
import { useAtomValue } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import style from './title-workspace-referrer.module.scss';
import { TitleWorkspaceResponse } from 'types/api-responses';

const getWorkspaceHref = (text: string) => {
  const match = text.match(/^(\/title_workspaces\/\d+)(.*)$/);
  if (match) {
    return match[1];
  } else {
    return null;
  }
};

const TitleWorkspaceReferrerImp = ({
  href,
  referrer,
}: {
  href: string;
  referrer: string;
}) => {
  const titleWorkspaceAtom = useMemo(
    () => createResourceAtom<TitleWorkspaceResponse>(href),
    [href]
  );

  const titleWorkspace = useAtomValue(titleWorkspaceAtom);

  if (titleWorkspace.data && 'error' in titleWorkspace.data) {
    return (
      <InlineNotification
        kind="error"
        title={titleWorkspace.data.error}
        lowContrast
      />
    );
  }

  if (titleWorkspace.loading)
    return (
      <div className={style.loading}>
        <InlineLoading />
      </div>
    );
  if (!titleWorkspace.data) return null;

  return (
    <Link to={referrer} className={style.referrerLink}>
      <div className={style.background}>
        <WorkspaceImport16 />
      </div>
      {titleWorkspace.data.name} {titleWorkspace.data.referenceLocation.name}
    </Link>
  );
};

const TitleWorkspaceReferrer = ({ referrer }: { referrer: string }) => {
  const titleWorkspaceHref = getWorkspaceHref(referrer);

  if (!titleWorkspaceHref) return null;

  return (
    <TitleWorkspaceReferrerImp referrer={referrer} href={titleWorkspaceHref} />
  );
};

export { TitleWorkspaceReferrer };
