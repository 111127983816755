import classNames from 'classnames';
import styles from './hidden-columns.module.scss';
import Data from 'images/srp-illustration-data.svg';
import { EmptyState } from 'components/empty-state';

export function HiddenColumns({ className }: { className?: string }) {
  return (
    <div className={classNames(styles.container, className)}>
      <EmptyState
        icon={Data}
        headerText="All columns are hidden"
        helperText="Show columns by clicking the button at top right"
      ></EmptyState>
    </div>
  );
}
