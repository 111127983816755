import { AtomField } from 'atoms/form-atoms';
import { RadioButton, RadioButtonGroup } from 'carbon-components-react';
import { DateField } from 'components/form-fields/date-field';
import { FormRow } from 'components/forms';
import { useAtom } from 'jotai';
import { EvaluationDateType } from 'types/scenarios-api/responses';
import { useValidateDrillingStartDateAtom } from '../validations';

const FixedFloatingDate = ({
  dateType,
  date,
  label,
  id,
  legendText,
}: {
  dateType: AtomField<EvaluationDateType>;
  date: AtomField<string>;
  label: string;
  id: string;
  legendText: string;
}) => {
  const [dateTypeObj, setDateType] = useAtom(dateType);
  const [startDate, setStartDate] = useAtom(date);

  const validateStartDate = useValidateDrillingStartDateAtom(dateType, date);

  return (
    <>
      <FormRow>
        <RadioButtonGroup
          legendText={legendText}
          name={`${id}-radio`}
          defaultSelected={dateTypeObj.value}
          orientation="horizontal"
          onChange={(value: EvaluationDateType) => {
            if (value === 'fixed') {
              setStartDate(startDate.value);
            }
            setDateType(value);
            validateStartDate();
          }}
        >
          <RadioButton labelText="Start Of The Month" value={'floating'} />
          <RadioButton labelText="Fixed" value={'fixed'} />
        </RadioButtonGroup>
      </FormRow>
      <FormRow>
        <DateField
          id={id}
          light
          short
          size="md"
          datePickerType="single"
          labelText={label}
          disabled={dateTypeObj.value === 'floating'}
          field={date}
        />
      </FormRow>
    </>
  );
};

export { FixedFloatingDate };
