import { Modal } from 'carbon-components-react';
import { InterpretationsSearch } from 'components/interpretation-search/interpretations-search';
import { ReactNode } from 'react';
import { DocumentInterpretationResponse } from 'types/api-responses';

import style from './interpretation-search-modal.module.scss';

export type InterpretationsModalCallback = (
  interpretation: DocumentInterpretationResponse
) => ReactNode;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  actions: InterpretationsModalCallback;
  title: string;
}

export const InterpretationSearchModal = ({
  isOpen,
  onClose,
  actions,
  title,
}: Props) => {
  return (
    <>
      <Modal
        open={isOpen}
        size="lg"
        modalHeading={title}
        passiveModal
        onRequestSubmit={onClose}
        onRequestClose={onClose}
        className={style.interpretationSearchModal}
      >
        {isOpen && (
          <InterpretationsSearch
            actions={actions}
            className={style.stickyTopPosition}
            openRowInNewTab
          />
        )}
      </Modal>
    </>
  );
};
