import { InlineNotification } from 'carbon-components-react';
import { Route, useLocation, useRouteMatch, Switch } from 'react-router-dom';
import { useEntityAtom } from '../common/atoms';
import { useAtom } from 'jotai';
import { ShowEntityPage } from '../show/page';
import { DeDuplicateEntityPage } from '../deduplicate/page';

const EntityPageEntryPoint = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const [resource, id] = location.pathname.split('/').filter((el) => el);
  const entityHref = `/${resource}/${id}`;

  const entityAtom = useEntityAtom(entityHref);

  const [entity, getEntity] = useAtom(entityAtom);

  if (entity.data && 'error' in entity.data)
    return (
      <InlineNotification kind="error" title={entity.data.error} lowContrast />
    );

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ShowEntityPage
          entity={entity.data}
          loading={entity.loading}
          getEntity={getEntity}
        />
      </Route>
      <Route exact path={`${path}/deduplication`}>
        <DeDuplicateEntityPage loading={entity.loading} entity={entity.data} />
      </Route>
    </Switch>
  );
};

export { EntityPageEntryPoint };
