import {
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from 'carbon-components-react';
import { Date } from 'components/date';
import { FormSection } from 'components/forms';
import { useAtomValue } from 'jotai';
import { usePackagesAtom } from './atoms';
import { EntityResponse } from 'types/api-responses';
import { NoResults } from './no-results';
import { format, parseISO } from 'date-fns';
import { Link } from 'components/link';
import { customSortRow } from 'lib/ui/custom-row-sort';
import { Checkmark20 } from '@carbon/icons-react';

const Packages = ({ entity }: { entity: EntityResponse }) => {
  return (
    <FormSection title="Packages">
      <PackagesTable entity={entity} />
    </FormSection>
  );
};

const headersList = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'effectiveDate',
    header: 'Effective Date',
  },
  {
    key: 'includeChildren',
    header: 'Include Children',
  },
  {
    key: 'createAtFormatted',
    header: 'Created At',
  },
];

const PackagesTable = ({ entity }: { entity: EntityResponse }) => {
  const packagesAtom = usePackagesAtom(entity.resources.packages.href);
  const { loading, data } = useAtomValue(packagesAtom);

  if (data && 'error' in data)
    return <InlineNotification kind="error" title={data.error} lowContrast />;

  if (!loading && data && !('error' in data) && !data.length) {
    return <NoResults />;
  }

  const augmentedRows = data?.map((el) => ({
    ...el,
    id: String(el.id),
    createAtFormatted: format(parseISO(el.createdAt), 'MM/dd/yyyy h:mm aaaa'),
  }));

  return augmentedRows ? (
    <DataTable
      rows={augmentedRows}
      headers={headersList}
      sortRow={customSortRow}
      isSortable
    >
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        onInputChange,
      }: DataTableCustomRenderProps) => (
        <TableContainer>
          <TableToolbar>
            <TableToolbarContent>
              <TableToolbarSearch onChange={onInputChange} expanded />
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  return (
                    <TableHeader
                      {...getHeaderProps({ header })}
                      key={header.key}
                    >
                      {header.header}
                    </TableHeader>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow {...getRowProps({ row })} key={row.id}>
                    {row.cells.map((cell, cellIndex) => {
                      const cellKey =
                        cell.id + '_' + rowIndex + '_' + cellIndex;
                      const augmentedRowIndex = cell.id.replace(/\D/g, '');
                      const toHref = augmentedRows.find(
                        (el) => el.id === augmentedRowIndex
                      )?.href;
                      if (cellIndex === 0) {
                        return (
                          <TableCell key={cellKey}>
                            {toHref && (
                              <Link
                                to={toHref}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {cell.value}
                              </Link>
                            )}
                          </TableCell>
                        );
                      } else if (cellIndex === 1) {
                        return (
                          <TableCell key={cellKey}>
                            <Date date={cell.value} />
                          </TableCell>
                        );
                      } else if (cellIndex === 2) {
                        return (
                          <TableCell key={cellKey}>
                            {cell.value ? <Checkmark20 /> : '--'}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cellKey}>
                            {cell.value || '--'}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  ) : (
    <DataTableSkeleton
      showHeader={false}
      columnCount={headersList.length}
      rowCount={10}
      headers={headersList.map((el) => ({ header: el.header }))}
    />
  );
};

export { Packages };
