const getReferrerAsSearchParam = (searchParams: string) => {
  const params = new URLSearchParams(searchParams);
  const referrer = params.has('referrer')
    ? `?referrer=${params.get('referrer')}`
    : '';

  return referrer;
};

const addSearchParamToReferrer = (
  searchParams: string,
  name: string,
  value: string
) => {
  const oldParams = new URLSearchParams(searchParams);
  const newParams = new URLSearchParams();

  newParams.append(name, value);
  const referrer = oldParams.get('referrer');
  if (referrer) newParams.append('referrer', referrer);

  return newParams.toString();
};

export { getReferrerAsSearchParam, addSearchParamToReferrer };
