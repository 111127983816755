import { FormRowStatus } from 'atoms/create-resource-atom';

/*
 * whiteListedArrayKeys: These keys are allowed to be sent as [] to the server (empty array)
 */
function removeEmptyKeys(
  obj: object,
  whiteListedArrayKeys: string[] = []
): object | undefined {
  if (Array.isArray(obj)) {
    const originalLength = obj.length;
    if (originalLength === 0) {
      return [];
    }

    const filteredArray = obj
      .map((el) => removeEmptyKeys(el, whiteListedArrayKeys))
      .filter((item) => {
        if (item) {
          const keys = Object.keys(item);
          return keys.length !== 0 && !(keys.length === 1 && keys[0] === 'id');
        }
        return false;
      });

    const filteredLength = filteredArray.length;
    if (filteredLength === 0) {
      return undefined;
    }

    return filteredArray;
  }

  if (obj !== undefined && obj !== null && typeof obj === 'object') {
    const result: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(obj)) {
      const newValue = removeEmptyKeys(value, whiteListedArrayKeys);
      if (typeof newValue !== 'undefined' && newValue !== null) {
        if (
          typeof newValue !== 'object' ||
          (Array.isArray(newValue) && newValue.length) ||
          whiteListedArrayKeys.includes(key)
        ) {
          result[key] = newValue;
          continue;
        }

        const newKeys = Object.keys(newValue);
        if (
          (newKeys.length !== 0 &&
            !(newKeys.length === 1 && newKeys[0] === 'id')) ||
          typeof newValue !== 'object'
        ) {
          result[key] = newValue;
        }
      }
    }

    return result;
  }

  return obj;
}

// Do not trigger a search when we are just setting the initial
// value of the field and the record is already saved or is a duplicate.
const shouldSkipSearch = (isFirstRender: boolean, status: FormRowStatus) => {
  return isFirstRender && (status === 'saved' || status === 'duplicated');
};

export { removeEmptyKeys, shouldSkipSearch };
