import {
  Column,
  InlineNotification,
  SkeletonText,
  Grid,
  Row,
  Tile,
  InlineLoading,
  Accordion,
  AccordionItem,
  Button,
} from 'carbon-components-react';
import { useAtom, useAtomValue } from 'jotai';
import { useLocation, useParams } from 'react-router-dom';
import { PackageResponse } from 'types/packages-api/responses';
import { PageHeader } from 'components/page-header';
import { Evaluations } from './evaluations';
import { PackagesSummaryTable } from './packages-summary';
import { PackagesMap } from './map/packages-map';
import NoMapSvg from 'images/srp-illustration-no-map.svg';
import { EmptyState } from 'components/empty-state';
import { ChevronLeft16, ChevronRight16 } from '@carbon/icons-react';
import style from './page.module.scss';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import {
  accordionVisibleAtom,
  packageAtom,
  packageHrefAtom,
  summaryVisibleAtom,
} from './atoms';
import { ProvideRootResource } from 'components/hydrate-atoms';
import { EventsPanel, EventsPanelOpenButton } from 'components/events-panel';

const Header = ({
  packageData,
}: {
  packageData: PackageResponse | undefined;
}) => {
  const { pathname } = useLocation();

  return (
    <PageHeader
      breadcrumbs={[
        ['/packages', 'Packages'],
        [pathname, packageData?.name || <SkeletonText width="200px" />],
      ]}
      stickyHeader
      title={packageData?.name || <SkeletonText heading width="200px" />}
      actions={
        <>
          <EventsPanelOpenButton />
        </>
      }
    />
  );
};

const Content = ({
  packageData,
}: {
  packageData: PackageResponse | undefined;
}) => {
  const [isSummaryVisible, setSummaryVisible] = useAtom(summaryVisibleAtom);
  const [isAccordionOpen, setIsAccordionOpen] = useAtom(accordionVisibleAtom);
  const handleSummaryClick = () => {
    setSummaryVisible((current) => !current);
  };
  const handleAccordionClick = () => {
    setIsAccordionOpen((current) => !current);
  };
  if (!packageData) return null;

  return (
    <>
      <Grid condensed fullWidth className="bx--no-gutter">
        <Accordion align="end" size="lg" className={style.packagesAccordion}>
          <AccordionItem
            open={isAccordionOpen}
            title="Summary"
            onHeadingClick={handleAccordionClick}
          >
            <Row>
              <Column
                md={!isSummaryVisible ? 0 : 12}
                lg={!isSummaryVisible ? 0 : 3}
                className={style.packagesSummaryCol}
              >
                <PackagesSummaryTable packageData={packageData} />
              </Column>

              <Column
                md={!isSummaryVisible ? 12 : 12}
                lg={!isSummaryVisible ? 16 : 13}
                className={style.packagesMapCol}
              >
                <div className={style.closeAreabtn}>
                  <Button
                    renderIcon={
                      !isSummaryVisible ? ChevronRight16 : ChevronLeft16
                    }
                    kind="ghost"
                    hasIconOnly
                    iconDescription={
                      isSummaryVisible
                        ? `Hide summary details`
                        : `Show summary details`
                    }
                    tooltipAlignment="start"
                    tooltipPosition="top"
                    size="sm"
                    className={style.closePanelBtn}
                    onClick={handleSummaryClick}
                  />
                </div>
                {isAccordionOpen && packageData.boundingBox ? (
                  <ProvideRootResource>
                    <PackagesMap
                      key={String(isSummaryVisible)}
                      boundingBox={packageData.boundingBox}
                      mapSources={packageData.resources.maps}
                    />
                  </ProvideRootResource>
                ) : (
                  <Tile className={style.emptyTileMessage}>
                    <EmptyState
                      icon={NoMapSvg}
                      iconClassName={style.emptyMapIcon}
                      headerText="No map available"
                      helperText="Currently Insights doesn’t have enough data"
                    />
                  </Tile>
                )}
              </Column>
            </Row>
          </AccordionItem>
        </Accordion>
      </Grid>
      <Evaluations href={packageData.resources.evaluations.href} />
    </>
  );
};

const LoadPackage = ({
  render,
}: {
  render: (packageData: PackageResponse) => JSX.Element;
}) => {
  const packageRequest = useAtomValue(packageAtom);
  if (packageRequest.state === 'loading') return <InlineLoading />;
  if (
    packageRequest.state === 'hasData' &&
    packageRequest.data &&
    'error' in packageRequest.data
  ) {
    return (
      <InlineNotification
        kind="error"
        title={packageRequest.data.error}
        lowContrast
      />
    );
  }

  if (
    packageRequest.state === 'hasData' &&
    packageRequest.data &&
    !('error' in packageRequest.data)
  )
    return render(packageRequest.data);

  return null;
};

const EventsContent = ({ packageData }: { packageData: PackageResponse }) => {
  return (
    <EventsPanel
      header={packageData.name}
      title="Package"
      helperText="Context allows you to share information about a package."
      eventsHref={packageData.resources.events.href}
      commentsHref={packageData.resources.comments.href}
    />
  );
};

const ShowPackagePage = () => {
  const params = useParams<{ id: string }>();
  const packageHref = `/packages/${params.id}`;

  return (
    <ProvideRootResource initialValues={[[packageHrefAtom, packageHref]]}>
      <LoadPackage
        render={(packageData: PackageResponse) => {
          return (
            <>
              <Helmet>
                <title>{pageTitle(`${packageData.name}`)}</title>
              </Helmet>
              <Header packageData={packageData} />
              <Content packageData={packageData} />
              <EventsContent packageData={packageData} />
            </>
          );
        }}
      />
    </ProvideRootResource>
  );
};

export { ShowPackagePage, LoadPackage };
