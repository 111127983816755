import { InterpretationSearchParams } from 'components/runsheet/common/types';

export const defaultPayload: InterpretationSearchParams = {
  text: '',
  page: 1,
  pageSize: 30,
  sortDirection: 'DESC',
  sortAttribute: 'updated_at',
  startDate: '',
  endDate: '',
  dateTarget: 'none',
};
