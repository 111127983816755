import searchAtomPair from 'atoms/search-atom';
import { SkeletonPlaceholder, Tile } from 'carbon-components-react';
import { Copy } from 'components/copy';
import { DefinitionList } from 'components/definition-list';
import { DefinitionDescription } from 'components/definition-list/definition-description';
import { DefinitionTerm } from 'components/definition-list/definition-term';
import { QuarteredGeographies } from 'components/quartered-geographies';
import { TagListItem } from 'components/tag-list-item';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { WellsResponse } from 'types/api-responses';
import { formAtoms, geographiesAttributesAtom, geographiesAtom } from './atoms';
import { Errors } from './errors';

import style from './step-two.module.scss';
import { GeographyAttributes, WellsSearchParams } from './types';

const StepTwo = () => {
  const geographies = useAtomValue(geographiesAtom);
  const geographiesAttributes = useAtomValue(geographiesAttributesAtom);
  return (
    <>
      <Copy heading="Review" subheading="How does everything look?" />
      <Tile>
        <DefinitionList>
          <DefinitionTerm>Name</DefinitionTerm>
          <DefinitionDescription noBorder>
            <Name />
          </DefinitionDescription>

          <DefinitionTerm>Section</DefinitionTerm>
          <DefinitionDescription noBorder>
            <QuarteredGeographies
              quarteredGeographies={geographies.filter(
                (el) => el.status !== 'trash'
              )}
            />
          </DefinitionDescription>
          <DefinitionTerm>Wells</DefinitionTerm>
          <DefinitionDescription noBorder>
            <Wells geographiesAttributes={geographiesAttributes} />
          </DefinitionDescription>
        </DefinitionList>
        <Errors />
      </Tile>
    </>
  );
};

const Name = () => {
  const fields = useAtomValue(useAtomValue(formAtoms).formAtom);
  return <>{fields.name.value}</>;
};

const Wells = ({
  geographiesAttributes,
}: {
  geographiesAttributes: GeographyAttributes[];
}) => {
  const { searchAtom } = useMemo(
    () =>
      searchAtomPair<WellsResponse, WellsSearchParams>('wells', {
        holeDirectionIds: ['horizontal'],
        withinQuarteredGeographies: geographiesAttributes,
      }),
    [geographiesAttributes]
  );
  const searchResults = useAtomValue(searchAtom);
  if (searchResults.loading) {
    return (
      <>
        <SkeletonPlaceholder className={style.wellPlaceholder} />
        <SkeletonPlaceholder className={style.wellPlaceholder} />
        <SkeletonPlaceholder className={style.wellPlaceholder} />
      </>
    );
  }
  return searchResults.data?.results.length ? (
    <ul>
      {searchResults.data.results.map((well, index) => (
        <TagListItem number={index + 1} tagType="purple" key={well.href}>
          {well.uwi} &middot; {well.name}
        </TagListItem>
      ))}
    </ul>
  ) : (
    <p>(none)</p>
  );
};

export { StepTwo };
