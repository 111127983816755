import { InlineNotification } from 'carbon-components-react';
import { useAtomValue } from 'jotai';
import { duplicateDocumentAtom } from './atoms';

const DuplicateWarning = () => {
  const duplicateWarning = useAtomValue(duplicateDocumentAtom);
  if (!duplicateWarning) return null;
  return (
    <InlineNotification
      kind={'warning'}
      lowContrast
      title="Duplicate Document"
      subtitle="The document cannot be created as the County,
      Document Identifier, and File Date entered all match an
      existing document within Insights. Please submit a new document."
      hideCloseButton={true}
    />
  );
};

export { DuplicateWarning };
