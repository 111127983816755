import { mapColors } from './map-colors';

const standardMapStyles = [
  {
    name: 'Map',
    url: 'mapbox://styles/carbondesignsystem/ck7c8ce1y05h61ipb2fixfe76',
    fonts: {
      CONDENSED: [
        'IBM Plex Sans Condensed Regular',
        'Arial Unicode MS Regular',
      ],
      CONDENSED_BOLD: ['IBM Plex Sans Condensed Bold', 'Arial Unicode MS Bold'],
      ITALIC: ['IBM Plex Sans Italic', 'Arial Unicode MS Regular'],
      MEDIUM: ['IBM Plex Sans Medium', 'Arial Unicode MS Regular'],
      REGULAR: ['IBM Plex Sans Regular', 'Arial Unicode MS Regular'],
    },
    lineColors: {
      a: '#B2B2B2',
      b: '#9C9C9C',
      c: '#000000',
      d: '#E1E1E1',
    },
    textColors: {
      b: '#9C9C9C',
      c: '#000000',
      e: '#828282',
    },
  },
  {
    name: 'Satellite',
    url: 'mapbox://styles/mapbox/satellite-v9',
    fonts: {
      CONDENSED: ['Arial Unicode MS Regular'],
      CONDENSED_BOLD: ['Arial Unicode MS Bold'],
      ITALIC: ['Arial Unicode MS Regular'],
      MEDIUM: ['Arial Unicode MS Regular'],
      REGULAR: ['Arial Unicode MS Regular'],
    },
    lineColors: {
      a: '#FFFFFF',
      b: '#FFFFFF',
      c: '#FFFFFF',
      d: '#FFFFFF',
    },
    textColors: {
      b: '#FFFFFF',
      c: '#FFFFFF',
      e: '#FFFFFF',
    },
  },
];

const LINE_STYLES = {
  BLUE_DOTTED: {
    'line-color': mapColors.stickFill,
    'line-width': 1.5,
    'line-dasharray': [6, 2],
  },
  BLACK_SOLID: {
    'line-color': mapColors.verticalFill,
    'line-width': 1.5,
  },
};

export { standardMapStyles, LINE_STYLES };
