import { Section } from 'components/section';
import {
  Interest,
  MineralInterest,
  LeaseAggregation,
  Encumbrances,
  RoyaltyRate,
  Overrides,
} from '.';
import { TitleChainCalculatorYamlResponse } from 'types/api-responses';

export function Mineral({
  calculator,
}: {
  calculator: TitleChainCalculatorYamlResponse;
}) {
  return (
    <Section header="Mineral">
      <Interest
        header="Executive Interest"
        interest={calculator.aggregateExecutiveInterest}
      />
      <Interest
        header="Royalty Interest"
        interest={calculator.aggregateRoyaltyInterest}
      />
      <Interest
        header="Bonus Interest"
        interest={calculator.aggregateBonusInterest}
      />
      <Interest
        header="Delay Interest"
        interest={calculator.aggregateDelayInterest}
      />
      <MineralInterest
        interest={calculator.aggregateMineralInterestWithRoyalty}
        interestName="Royalty"
      />
      <MineralInterest
        interest={calculator.aggregateMineralInterestWithBonus}
        interestName="Bonus"
      />
      <MineralInterest
        interest={calculator.aggregateMineralInterestWithDelay}
        interestName="Delay"
      />
      <Encumbrances
        interestHeader="Encumbered Mineral Interest"
        encumbrances={calculator.mineralEncumbrances}
      />
      <LeaseAggregation
        interestHeader="Working Interest"
        leaseData={calculator.workingInterests}
      />
      <RoyaltyRate data={calculator.mineralLeaseRoyaltyRates} />
      <Overrides overrides={calculator.mineralLeaseOverrides} />
      <Interest
        header="Net Revenue Interest"
        interest={calculator.netRevenueInterests}
      />

      {calculator.wells.map((well) => {
        return (
          <Section key={well.name} header={well.name}>
            <Interest
              header="Executive Interest"
              interest={well.executiveInterests}
            />
            <LeaseAggregation
              interestHeader="Working Interest"
              leaseData={well.workingInterests}
            />
          </Section>
        );
      })}
    </Section>
  );
}
