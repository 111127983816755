import {
  TypeLayout,
  TypeLayoutBody,
  TypeLayoutCell,
  TypeLayoutRow,
} from '@carbon/ibm-security';
import { OutputSkeleton } from '../../common/output_skeleton';
import { CalculationLoading } from '../../common/calculation-loading';
import { Map, PolygonArea, Well } from 'components/map';
import { useAtomValue } from 'jotai';

import style from '../calculation/asset.module.scss';
import {
  TitleCalculationResponse,
  WellIntersectionWellResponse,
  WorkingInterestOwnerResponse,
} from 'types/api-responses';
import {
  subtractsAtom,
  wellFeaturesMapAtom,
  wellIntersectionsMapAtom,
} from 'pages/title-workspaces/overview/by-asset/atoms';
import { atomWithStorage } from 'jotai/utils';
import { getMapDefaultConfig } from 'components/map/atoms';
import {
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Link,
} from 'carbon-components-react';

const WorkingInterestOwners = ({
  workingInterestOwners,
}: {
  workingInterestOwners: WorkingInterestOwnerResponse[];
}) => {
  return workingInterestOwners.length ? (
    <TableContainer className={style.table}>
      <Table size="sm">
        <TableHead>
          <TableRow>
            <TableHeader>Working Interest Owner</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {workingInterestOwners
            .sort((a, b) => {
              return a.entity.name < b.entity.name
                ? -1
                : a.entity.name > b.entity.name
                ? 1
                : 0;
            })
            .map(({ entity }) => (
              <TableRow key={entity.name}>
                <TableCell>
                  <Link
                    href={entity.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {entity.name}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

const mapConfigAtom = atomWithStorage(
  'insights-wells-intersection-map-config',
  getMapDefaultConfig()
);

const WellFeaturesMap = ({ well }: { well: WellIntersectionWellResponse }) => {
  const wellFeaturesMap = useAtomValue(wellFeaturesMapAtom);
  const wellFeaturesHref =
    'resources' in well ? well.resources.features.href : undefined;
  const features = wellFeaturesHref
    ? wellFeaturesMap[wellFeaturesHref]
    : undefined;
  const subtracts = useAtomValue(subtractsAtom);

  if (!features) return <OutputSkeleton />;

  if (features.data && 'error' in features.data)
    return (
      <InlineNotification
        kind="error"
        title={features.data.error}
        lowContrast
      />
    );

  if (!features.data) return null;
  if (!features.data.surfaceHole) return null;

  const [longitude, latitude] = features.data.surfaceHole.coordinates;

  return (
    <Map
      latitude={latitude}
      longitude={longitude}
      mapConfigAtom={mapConfigAtom}
      showControls
      showMapFilters
      disableWellsLayer
    >
      {subtracts.map((subtract) => {
        return subtract.geometry ? (
          <PolygonArea
            key={subtract.id}
            geometry={subtract.geometry}
            id={subtract.id}
          />
        ) : null;
      })}
      <Well
        id={String(well.uwi)}
        features={features.data}
        mapConfigAtom={mapConfigAtom}
      />
    </Map>
  );
};

const WellIntersection = ({
  href,
  calculation,
}: {
  href: string;
  calculation: TitleCalculationResponse;
}) => {
  const wellIntersectionsMap = useAtomValue(wellIntersectionsMapAtom);
  const wellIntersection = wellIntersectionsMap[href];

  if (!wellIntersection) return <OutputSkeleton />;

  if (wellIntersection.data && 'error' in wellIntersection.data)
    return (
      <InlineNotification
        kind="error"
        title={wellIntersection.data.error}
        lowContrast
      />
    );
  if (!wellIntersection.data) return <OutputSkeleton />;

  const { well, workingInterestOwners } = wellIntersection.data;

  return (
    <section>
      <div className={style.assetGrid}>
        <div className={style.assetData}>
          <TypeLayout>
            <TypeLayoutBody>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.noLeftPadding}>
                  Name of Wellbore
                </TypeLayoutCell>
                <TypeLayoutCell className={style.nonEditable}>
                  {well.name}
                </TypeLayoutCell>
              </TypeLayoutRow>
            </TypeLayoutBody>
          </TypeLayout>
        </div>
        <hr className={style.separator} />
        <div className={style.wellsMap}>
          <WellFeaturesMap well={well} />
        </div>
      </div>
      {calculation?.status === 'processed' ? (
        <WorkingInterestOwners workingInterestOwners={workingInterestOwners} />
      ) : (
        <>
          <CalculationLoading />
        </>
      )}
    </section>
  );
};

export { WellIntersection };
