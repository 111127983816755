import { Grid, Row, Column, SkeletonText } from 'carbon-components-react';
import style from './interpretation_skeleton.module.scss';

export function InterpretationSkeleton() {
  return (
    <>
      <Grid fullWidth className={style.skeletonPage}>
        <Row className={style.skeletonRow}>
          <Column sm={{ span: 1, offset: 3 }}>
            <SkeletonText />
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column lg={6}>
            <SkeletonText heading width="5%" />
          </Column>
          <Column sm={{ span: 1, offset: 3 }}>
            <div>
              <SkeletonText width="50%" />
              <SkeletonText width="30%" />
            </div>
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column>
            <SkeletonText heading width="30%" />
            <SkeletonText width="50%" />
            <SkeletonText className={style.skeletonTextField} />
            <SkeletonText width="10%" className={style.skeletonBtn} />
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column lg={4}>
            <SkeletonText width="30%" />
            <SkeletonText width="100%" />
          </Column>
          <Column lg={4}>
            <SkeletonText width="30%" />
            <SkeletonText width="100%" />
          </Column>
          <Column lg={4}>
            <SkeletonText width="30%" />
            <SkeletonText width="100%" />
          </Column>
          <Column lg={4}>
            <SkeletonText width="30%" />
            <SkeletonText width="100%" />
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column>
            <SkeletonText heading width="30%" />
            <SkeletonText width="10%" className={style.skeletonBtn} />
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column>
            <SkeletonText heading width="30%" />
            <SkeletonText width="10%" className={style.skeletonBtn} />
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column>
            <SkeletonText heading width="30%" />
            <SkeletonText width="10%" className={style.skeletonBtn} />
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column>
            <SkeletonText heading width="30%" />
            <SkeletonText width="10%" className={style.skeletonBtn} />
          </Column>
        </Row>
        <Row className={style.skeletonRow}>
          <Column>
            <SkeletonText width="30%" />
            <SkeletonText className={style.skeletonTextField} />
            <SkeletonText width="30%" />
            <SkeletonText className={style.skeletonTextField} />
            <SkeletonText width="100%" />
            <SkeletonText width="10%" className={style.skeletonBtn} />
          </Column>
        </Row>
      </Grid>
    </>
  );
}
