import { SkeletonText, TableCell, TableRow } from 'carbon-components-react';

interface Props {
  columns: number;
  rows: number;
}

const SkeletonTableRows = ({ columns, rows }: Props) => {
  return (
    <>
      {[...Array(rows)].map((_el, i) => (
        <TableRow key={`skeleton-row-${i}`}>
          {[...Array(columns)].map((_el, j) => (
            <TableCell key={`skeleton-cell-${j}`}>
              <SkeletonText />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export { SkeletonTableRows };
