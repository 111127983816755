import { PageHeader } from 'components/page-header';
import { useHistory } from 'react-router-dom';
import { Settings16 } from '@carbon/icons-react';
import { Button, Column, Grid, Row } from 'carbon-components-react';
import { FC, ReactNode } from 'react';
import { WorkspaceTabs } from './tabs';

import style from './title-workspace-header.module.scss';
import { RunsheetModal } from '../overview/runsheet-graph';
import { TitleWorkspaceResponse } from 'types/api-responses';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';

const TitleWorkspaceHeader: FC<{
  subtitle: string;
  actions?: ReactNode;
  hideTabs?: boolean;
  title?: string;
  titleWorkspace: TitleWorkspaceResponse;
}> = ({ children, subtitle, actions, hideTabs, title, titleWorkspace }) => {
  const { referenceLocation, name, href, resources } = titleWorkspace;
  const history = useHistory();

  return (
    <div>
      <Helmet>
        <title>{pageTitle(`${subtitle} | ${name}`)}</title>
      </Helmet>
      <PageHeader
        breadcrumbs={[
          ['/title_workspaces', 'Title Workspaces'],
          [href, name],
        ]}
        stickyHeader
        title={title ? title : `${referenceLocation.name} · ${name}`}
        actions={actions}
      >
        {!hideTabs && (
          <Grid className="bx--no-gutter" fullWidth condensed>
            <Row>
              <Column sm={12} lg={10} className={style.tabsAlign}>
                <WorkspaceTabs titleWorkspace={titleWorkspace} />
              </Column>
              <Column sm={12} lg={6} className={style.alignRight}>
                <RunsheetModal titleWorkspace={titleWorkspace} />
                <Button
                  size="sm"
                  kind="ghost"
                  renderIcon={Settings16}
                  onClick={() => history.push(resources.startingInterest.href)}
                >
                  <span>Workspace Settings</span>
                </Button>
              </Column>
            </Row>
          </Grid>
        )}
      </PageHeader>
      <div className={style.overviewContainer}>{children}</div>
    </div>
  );
};

export { TitleWorkspaceHeader };
