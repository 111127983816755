import { AtomField } from 'atoms/form-atoms';
import { TextArea, TextAreaProps } from 'carbon-components-react';
import { useAtomValue, useSetAtom } from 'jotai';

interface Props extends TextAreaProps {
  fieldAtom: AtomField<string | null> | AtomField<string>;
}

export function TextAreaField({ fieldAtom, ...rest }: Props) {
  const setField = useSetAtom(fieldAtom);
  const field = useAtomValue(fieldAtom);
  return (
    <TextArea
      value={field.value || ''}
      onChange={({ target: { value } }) => setField(value)}
      invalid={!field.isValid && field.isDirty}
      invalidText={!field.isValid && field.isDirty ? field.error : ''}
      {...rest}
    />
  );
}
