import {
  createMutationAtom,
  CreateResourceAtom,
} from 'atoms/create-resource-atom';
import {
  getFormErrorsAtom,
  getFormHasChanges,
  getFormIsInvalidAtom,
  getFormValuesAtom,
} from 'atoms/form-atoms';
import { atom } from 'jotai';
import { ApiPayloads } from 'types/api-payloads';
import { EntityResponse, EntityType } from 'types/api-responses';
import {
  createImportedEntityForm,
  createIndividualEntityForm,
  createOrganizationEntityForm,
} from './utils';
import { useMemo } from 'react';

export type FormAtoms = ReturnType<typeof useFormAtoms>;

const useFormAtoms = (
  entity: EntityResponse | undefined,
  entityType: EntityType
) => {
  return useMemo(
    () =>
      atom(() => {
        if (entityType === 'IndividualEntity') {
          return {
            type: 'IndividualEntity',
            form: entity
              ? createIndividualEntityForm(entity)
              : createIndividualEntityForm(),
          } as const;
        }

        if (entityType === 'OrganizationEntity') {
          return {
            type: 'OrganizationEntity',
            form: entity
              ? createOrganizationEntityForm(entity)
              : createOrganizationEntityForm(),
          } as const;
        }

        return {
          type: 'ImportedEntity',
          form:
            entity && entity.type === 'ImportedEntity'
              ? createImportedEntityForm(entity)
              : createImportedEntityForm(),
        } as const;
      }),
    [entity, entityType]
  );
};

const createFormStateAtom = (
  fn: typeof getFormIsInvalidAtom | typeof getFormHasChanges,
  formAtoms: FormAtoms
) =>
  atom((get) => {
    const { type, form } = get(formAtoms);
    switch (type) {
      case 'ImportedEntity':
        return fn(atom(form));
      case 'IndividualEntity':
        return fn(atom(form));
      case 'OrganizationEntity':
        return fn(atom(form));
    }
  });

const useErrorListAtom = (formAtoms: FormAtoms) =>
  useMemo(
    () =>
      atom((get) => {
        const { type, form } = get(formAtoms);
        switch (type) {
          case 'ImportedEntity':
            return getFormErrorsAtom(atom(form));
          case 'IndividualEntity':
            return getFormErrorsAtom(atom(form));
          case 'OrganizationEntity':
            return getFormErrorsAtom(atom(form));
        }
      }),
    [formAtoms]
  );

const useFormIsInvalidAtom = (formAtoms: FormAtoms) =>
  useMemo(
    () => createFormStateAtom(getFormIsInvalidAtom, formAtoms),
    [formAtoms]
  );
const useFormHasChangesAtom = (formAtoms: FormAtoms) =>
  useMemo(() => createFormStateAtom(getFormHasChanges, formAtoms), [formAtoms]);

const entityMutationAtom = createMutationAtom<EntityResponse, 'entity'>();
const runMutationAtom = atom<
  CreateResourceAtom<EntityResponse, ApiPayloads['entity']['error']>,
  [
    {
      onSuccess: (entity: EntityResponse) => void;
      onError: (error?: ApiPayloads['entity']['error']) => void;
      formAtoms: FormAtoms;
      isUpdatingEntity: boolean;
      entityUri?: string;
    }
  ],
  void
>(
  (get) => get(entityMutationAtom),
  (
    get,
    set,
    { onSuccess, onError, formAtoms, isUpdatingEntity, entityUri }
  ) => {
    const formObj = get(formAtoms);
    const url = isUpdatingEntity && entityUri ? entityUri : '/entities';
    const isImportedEntity = formObj.type === 'ImportedEntity';

    const rest =
      formObj.type === 'IndividualEntity'
        ? get(getFormValuesAtom(atom(formObj.form), 'all-values'))
        : formObj.type === 'OrganizationEntity'
        ? get(getFormValuesAtom(atom(formObj.form), 'all-values'))
        : get(getFormValuesAtom(atom(formObj.form), 'all-values'));

    const data = {
      concreteEntityType: !isImportedEntity ? formObj.type : undefined,
      ...rest,
    };

    set(entityMutationAtom, {
      url,
      type: isUpdatingEntity ? 'PATCH' : 'POST',
      data: data,
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error) => {
        onError(error);
      },
    });
  }
);

export {
  useErrorListAtom,
  runMutationAtom,
  useFormAtoms,
  useFormHasChangesAtom,
  useFormIsInvalidAtom,
};
