import { Modal } from 'carbon-components-react';
import { Atom } from 'jotai';
import { AddWellFn } from 'components/wells-search/types';
import { WellsSearch } from 'components/wells-search/wells-search';
import style from './well-search-modal.module.scss';

interface Props {
  onAddWell?: AddWellFn;
  usedWellsAtom?: Atom<number[]>;
  isOpen: boolean;
  onClose: () => void;
}

export const WellSearchModal = ({
  onAddWell,
  isOpen,
  onClose,
  usedWellsAtom,
}: Props) => {
  return (
    <>
      <Modal
        open={isOpen}
        size="lg"
        modalHeading="Add Well"
        primaryButtonText="Back To Interpretation"
        className={style.wellSearchModal}
        onRequestSubmit={onClose}
        onRequestClose={onClose}
      >
        {isOpen && (
          <WellsSearch onAddWell={onAddWell} usedWellsAtom={usedWellsAtom} />
        )}
      </Modal>
    </>
  );
};
