import { Link, Row, Column, Tile, Button, Grid } from 'carbon-components-react';
import { compact } from 'lodash';
import classNames from 'classnames';
import { Add16, Document32 } from '@carbon/icons-react';

import style from './document-card.module.scss';
import { DocumentResponse } from 'types/api-responses';
import { Atom, atom, useAtomValue } from 'jotai';
import { Date } from 'components/date';
import plur from 'plur';

const NULL_DISPLAY_VALUE = '--';
interface Props {
  document: DocumentResponse;
  onAddDocument?: (doc: DocumentResponse) => void;
  usedDocumentsAtom: Atom<number[]>;
}

const usedDocumentReferencesAtom = atom<number[]>([]);

const DocumentCard = ({
  document,
  onAddDocument,
  usedDocumentsAtom,
}: Props) => {
  const secondaryHeaderData = compact([
    document.county &&
      `${document.county.name}, ${document.county.state.abbreviation}`,
    document.bookType,
    document.documentType,
  ]).join(', ');

  const usedDocumentReferences = useAtomValue(usedDocumentsAtom);

  return (
    <Tile className={style.documentCard}>
      <div className={classNames(style.background, style.document)}>
        <Document32 className={classNames(style.icon, style.document)} />
      </div>
      <div className={style.content}>
        <header className={style.header}>
          <h3 className={style.secondaryHeader}>{secondaryHeaderData}</h3>
          <h2 className={style.primaryTitle}>
            <Link
              className={style.primaryHeader}
              href={document.href}
              onClick={() => {}}
              target="_blank"
              visited={true}
            >
              {document.documentTitle || 'Unknown'}
            </Link>
          </h2>
        </header>
        <Grid className="bx--no-gutter" fullWidth>
          <Row className={style.row}>
            <Column>
              <section className={style.truncate}>
                <h3 className={style.title}>
                  {plur('Grantor', document.grantors.length)}:
                </h3>
                ({document.grantors.length})
                {document.grantors
                  .map((grantor: any) => grantor.name)
                  .join(', ')}
              </section>
            </Column>
            <Column>
              <section className={style.truncate}>
                <h3 className={style.title}>
                  {plur('Grantee', document.grantees.length)}:
                </h3>
                ({document.grantees.length})
                {document.grantees
                  .map((grantee: any) => grantee.name)
                  .join(', ')}
              </section>
            </Column>
          </Row>
          <Row className={style.row}>
            <Column>
              <section>
                <h3 className={style.title}>Effective Date: </h3>
                {document.effectiveDate ? (
                  <Date date={document.effectiveDate} />
                ) : (
                  NULL_DISPLAY_VALUE
                )}
              </section>
            </Column>
            <Column>
              <section>
                <h3 className={style.title}>Recorded Date: </h3>
                {document.recordedDate ? (
                  <Date date={document.recordedDate} />
                ) : (
                  NULL_DISPLAY_VALUE
                )}
              </section>
            </Column>
          </Row>
        </Grid>
      </div>
      {onAddDocument && (
        <div>
          <Button
            iconDescription="Add Document Reference"
            renderIcon={Add16}
            size="sm"
            disabled={usedDocumentReferences.includes(document.id)}
            onClick={() => onAddDocument(document)}
          >
            Add
          </Button>
        </div>
      )}
    </Tile>
  );
};

export { usedDocumentReferencesAtom, DocumentCard };
