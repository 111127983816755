import { useAtom } from 'jotai';
import { useScenarioAssumptionsAtom } from '../common/atoms';
import { ScenarioBuilder } from '../common/scenario-builder';
import { useLocation } from 'react-router-dom';
import { PageHeader } from 'components/page-header';
import { ScenarioSkeleton } from '../common/scenario_skeleton';
import { InlineNotification } from 'carbon-components-react';
import { ScenarioAssumptionsResponse } from 'types/scenarios-api/responses';
import { EventsPanel, EventsPanelOpenButton } from 'components/events-panel';

const EventsContent = ({
  scenario,
}: {
  scenario: ScenarioAssumptionsResponse;
}) => {
  return (
    <EventsPanel
      header={scenario.name}
      title="Scenario"
      helperText="Context allows you to share information about a scenario."
      eventsHref={scenario.resources.events.href}
      commentsHref={scenario.resources.comments.href}
    />
  );
};

const EditScenarioPage = () => {
  const { pathname } = useLocation();
  const scenarioAssumptionsAtom = useScenarioAssumptionsAtom(pathname);
  const [assumptions, getAssumptions] = useAtom(scenarioAssumptionsAtom);

  if (assumptions.loading) return <ScenarioSkeleton />;

  if (assumptions.data && 'error' in assumptions.data)
    return (
      <InlineNotification
        kind="error"
        title={assumptions.data.error}
        lowContrast
      />
    );

  if (!assumptions.data)
    return (
      <InlineNotification
        kind="error"
        title="Something went wrong"
        lowContrast
      />
    );

  const scenario = assumptions.data;

  return (
    <>
      <PageHeader
        breadcrumbs={[
          ['/scenarios', 'Scenarios'],
          [`/scenarios/${scenario.id}`, scenario.name],
        ]}
        title="Edit scenario"
        actions={
          <>
            <EventsPanelOpenButton />
          </>
        }
      />
      <ScenarioBuilder
        assumptions={assumptions.data}
        getAssumptions={getAssumptions}
        type="PATCH"
        href={pathname}
        isEditing
      />
      <EventsContent scenario={assumptions.data} />
    </>
  );
};

export { EditScenarioPage };
