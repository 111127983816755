import { blue } from '@carbon/colors';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ReferenceLocationResponse } from 'types/api-responses';
import { createAbortableQuarteredGeometryAtom } from './atoms';
import PolygonArea from './polygon-area';
import { resourcesAtom } from 'atoms/root';

const QuarteredPolygon = ({
  location,
  id,
  quarterCalls,
  color = blue[70],
}: {
  location: ReferenceLocationResponse | null;
  quarterCalls: string[];
  color?: string;
  id: number | string;
}) => {
  const resources = useAtomValue(resourcesAtom);
  const geometryAtom = useMemo(
    () =>
      createAbortableQuarteredGeometryAtom(
        location,
        quarterCalls,
        resources?.quarteredGeographies.href
      ),
    [location, quarterCalls, resources?.quarteredGeographies.href]
  );
  const geometry = useAtomValue(geometryAtom);

  if (!location) {
    return null;
  }

  if (geometry) {
    return <PolygonArea geometry={geometry} id={id} color={color} />;
  }

  return null;
};

export { QuarteredPolygon };
