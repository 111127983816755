import { InlineLoading, Tag, TooltipDefinition } from 'carbon-components-react';
import { Date } from 'components/date';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  DevelopmentAreaEvaluationsResponse,
  PackageEvaluationsResponse,
} from 'types/evaluation-api/responses';

import style from './evaluation-list.module.scss';
import { useCallback, useState } from 'react';
import {
  onProgressCallback,
  usePackageNotification,
} from 'lib/hooks/usePackageNotification';
import {
  Warning16,
  Hashtag16,
  TreeViewAlt16,
  Currency16,
  Box16,
  Bot16,
  User16,
  Calendar16,
  Calculation16,
  Error16,
} from '@carbon/icons-react';
import { groupEvaluationsByRunId } from './utils';
import { capitalizeFirstLetter } from 'utils/strings';
import classNames from 'classnames';

const DraftEvaluation = ({
  onClick,
  showDraft,
  draftLabel,
}: {
  onClick: () => void;
  showDraft: boolean;
  draftLabel: string;
}) => {
  return (
    <li className={showDraft ? style.active : ''} onClick={onClick}>
      <div className={style.savedEvaluations}>
        <p>{draftLabel}</p>
      </div>
    </li>
  );
};

const EvaluationItem = ({
  evaluation,
  onEvaluationClick,
  showDraft,
}: {
  evaluation: DevelopmentAreaEvaluationsResponse | PackageEvaluationsResponse;
  onEvaluationClick: () => void;
  showDraft: boolean;
}) => {
  const [success, setSuccess] = useState(
    'progress' in evaluation ? evaluation.progress.isSuccess : undefined
  );
  const [stalled, setStalled] = useState(
    'progress' in evaluation ? evaluation.progress.isStalled : undefined
  );

  const [successPercentage, setSuccessPercentage] = useState<
    number | undefined
  >(undefined);
  const hasWarning =
    'resources' in evaluation && evaluation.resources.allocations?.hasWarning;

  const onSuccess = useCallback(() => setSuccess(true), []);
  const onStalled = useCallback(() => setStalled(true), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onProgress = useCallback(onProgressCallback(setSuccessPercentage), [
    setSuccessPercentage,
  ]);

  usePackageNotification({
    notifications:
      'notifications' in evaluation ? evaluation.notifications : undefined,
    isSuccess: success,
    onSuccess,
    onProgress,
    onStalled,
  });

  const hasError = 'progress' in evaluation && evaluation.progress.error > 0;

  return (
    <li
      key={evaluation.id}
      className={
        location.pathname === evaluation.href && !showDraft
          ? style.active
          : undefined
      }
    >
      <Link to={evaluation.href} onClick={() => onEvaluationClick()}>
        <div className={style.linkTitle}>
          <span className={style.linkInfo}>
            <div className={style.grid}>
              {hasWarning && (
                <div className={style.gridItemtTwoColumns}>
                  <Tag className={style.warningLabel} type="red" size="sm">
                    <TooltipDefinition
                      direction="bottom"
                      align="start"
                      tooltipText="There is not enough information to determine development area coverage. Asset(s) are not fully covered by development areas and may be under or over valued."
                    >
                      Warning <Warning16 />
                    </TooltipDefinition>
                  </Tag>
                </div>
              )}

              <div className={style.gridItem}>
                <TooltipDefinition
                  direction="bottom"
                  align="start"
                  tooltipText="Evaluation ID"
                >
                  <Hashtag16 />
                </TooltipDefinition>
              </div>

              <div className={classNames(style.gridItem, style.evaluationId)}>
                {evaluation.id}
              </div>
              {'scenario' in evaluation && (
                <div className={style.gridItem}>
                  <TooltipDefinition
                    direction="bottom"
                    align="start"
                    tooltipText="Scenario"
                  >
                    <TreeViewAlt16 />
                  </TooltipDefinition>
                </div>
              )}
              {'scenario' in evaluation && (
                <div className={classNames(style.gridItem, style.truncateText)}>
                  {evaluation.scenario.name}
                </div>
              )}
              {'scenario' in evaluation && (
                <div className={style.gridItem}>
                  <TooltipDefinition
                    direction="bottom"
                    align="start"
                    tooltipText="Interest Type"
                  >
                    <Currency16 />
                  </TooltipDefinition>
                </div>
              )}
              {'scenario' in evaluation && (
                <div className={classNames(style.gridItem, style.truncateText)}>
                  {capitalizeFirstLetter(evaluation.scenario.interestType)}
                </div>
              )}
              {'packageEvaluation' in evaluation &&
                evaluation.packageEvaluation && (
                  <div className={style.gridItem}>
                    <TooltipDefinition
                      direction="bottom"
                      align="start"
                      tooltipText="Package Evaluation"
                    >
                      <Box16 />
                    </TooltipDefinition>
                  </div>
                )}
              {'packageEvaluation' in evaluation &&
                evaluation.packageEvaluation && (
                  <div
                    className={classNames(style.gridItem, style.truncateText)}
                  >
                    <span
                      onClick={() =>
                        evaluation.packageEvaluation &&
                        window.open(
                          evaluation.packageEvaluation.href,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                      className={style.packageLink}
                    >
                      {evaluation.packageEvaluation.name}
                    </span>
                  </div>
                )}
              <div className={style.gridItem}>
                <TooltipDefinition
                  direction="bottom"
                  align="start"
                  tooltipText="Created By"
                >
                  {evaluation.createdBy ? <User16 /> : <Bot16 />}
                </TooltipDefinition>
              </div>
              <div className={classNames(style.gridItem, style.truncateText)}>
                {evaluation.createdBy ? evaluation.createdBy : 'Auto Generated'}
              </div>
              <div className={style.gridItem}>
                <TooltipDefinition
                  direction="bottom"
                  align="start"
                  tooltipText="Created At"
                >
                  <Calendar16 />
                </TooltipDefinition>
              </div>
              <div className={classNames(style.gridItem, style.truncateText)}>
                <Date
                  date={evaluation.createdAt}
                  format="M/DD/YYYY h:mm A"
                  showTime
                />
              </div>
            </div>
            {'progress' in evaluation ? (
              success ? null : hasError ? (
                <div className={style.alignError}>
                  <Error16 />
                </div>
              ) : stalled ? (
                <div className={style.alignError}>
                  <Warning16 />
                </div>
              ) : typeof successPercentage === 'undefined' ? (
                <div>
                  <InlineLoading />
                </div>
              ) : (
                <div className={style.progressIndicator}>
                  <span className={style.percentage}>
                    <strong>Evaluation is running&hellip;</strong>
                    <strong>{successPercentage}%</strong>
                  </span>
                  <progress value={successPercentage} max="100"></progress>
                </div>
              )
            ) : null}
          </span>
        </div>
      </Link>
    </li>
  );
};

const CombinedItem = ({
  evaluation,
  showDraft,
  onEvaluationClick,
}: {
  evaluation: PackageEvaluationsResponse;
  showDraft: boolean;
  onEvaluationClick: () => void;
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const childrenIds =
    'evaluations' in evaluation
      ? (evaluation.evaluations || []).map((evaluation) => evaluation.id)
      : [];

  const childEvaluations = evaluation.evaluations || [];
  const [success, setSuccess] = useState(
    'progress' in evaluation ? evaluation.progress.isSuccess : undefined
  );
  const [stalled, setStalled] = useState(
    'progress' in evaluation ? evaluation.progress.isStalled : undefined
  );

  const [successPercentage, setSuccessPercentage] = useState<
    number | undefined
  >(undefined);

  const hasWarning = childEvaluations
    .map((el) => el.resources.allocations?.hasWarning)
    .some((el) => el);

  const onSuccess = useCallback(() => setSuccess(true), []);
  const onStalled = useCallback(() => setStalled(true), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onProgress = useCallback(onProgressCallback(setSuccessPercentage), [
    setSuccessPercentage,
  ]);

  usePackageNotification({
    notifications: evaluation.notifications,
    isSuccess: success,
    onSuccess,
    onProgress,
    onStalled,
  });

  const hasError = 'progress' in evaluation && evaluation.progress.error > 0;

  return (
    <li
      key={evaluation.id}
      className={
        (childrenIds.join(',') === params.get('ids') && !showDraft) ||
        childrenIds.join(',') === params.get('children')
          ? style.active
          : undefined
      }
    >
      <Link to={evaluation.href} onClick={() => onEvaluationClick()}>
        <div className={style.linkTitle}>
          <span className={style.linkInfo}>
            <div className={style.grid}>
              {hasWarning && (
                <div className={style.gridItemtTwoColumns}>
                  <Tag className={style.warningLabel} type="red" size="sm">
                    <TooltipDefinition
                      direction="bottom"
                      align="start"
                      tooltipText="There is not enough information to determine development area coverage. Asset(s) are not fully covered by development areas and may be under or over valued."
                    >
                      Warning <Warning16 />
                    </TooltipDefinition>
                  </Tag>
                </div>
              )}
              {'evaluations' in evaluation && (
                <div className={classNames(style.gridItem, style.hideLabel)}>
                  <TooltipDefinition
                    direction="bottom"
                    align="start"
                    tooltipText="Evaluations"
                  >
                    <Calculation16 />
                  </TooltipDefinition>
                </div>
              )}
              {'evaluations' in evaluation && (
                <div className={classNames(style.gridItem, style.evalBtns)}>
                  {evaluation.evaluations?.map((el) => (
                    <Tag
                      size="sm"
                      type="blue"
                      className={classNames({
                        [style.childSelected]:
                          el.id === Number(params.get('childId')),
                      })}
                      key={el.id}
                      onClick={(ev) => {
                        ev.preventDefault();
                        const href = `${el.href}?children=${childrenIds.join(
                          ','
                        )}&childId=${el.id}`;
                        history.push(href);
                      }}
                    >
                      {el.id}
                    </Tag>
                  ))}
                </div>
              )}
              <div className={style.gridItem}>
                <TooltipDefinition
                  direction="bottom"
                  align="start"
                  tooltipText="Created By"
                >
                  <User16 />
                </TooltipDefinition>
              </div>
              <div className={classNames(style.gridItem, style.truncateText)}>
                {evaluation.createdBy ? evaluation.createdBy : 'Auto Generated'}
              </div>
              <div className={style.gridItem}>
                <TooltipDefinition
                  direction="bottom"
                  align="start"
                  tooltipText="Created At"
                >
                  <Calendar16 />
                </TooltipDefinition>
              </div>
              <div className={classNames(style.gridItem, style.truncateText)}>
                <Date
                  date={evaluation.createdAt}
                  format="M/DD/YYYY h:mm A"
                  showTime
                />
              </div>
            </div>

            {'progress' in evaluation ? (
              success ? null : hasError ? (
                <div className={style.alignError}>
                  <Error16 />
                </div>
              ) : stalled ? (
                <div className={style.alignError}>
                  <Warning16 />
                </div>
              ) : typeof successPercentage === 'undefined' ? (
                <div>
                  <InlineLoading />
                </div>
              ) : (
                <div className={style.progressIndicator}>
                  <span className={style.percentage}>
                    <strong>Evaluation is running&hellip;</strong>
                    <strong>{successPercentage}%</strong>
                  </span>
                  <progress value={successPercentage} max="100"></progress>
                </div>
              )
            ) : null}
          </span>
        </div>
      </Link>
    </li>
  );
};

const EvaluationList = ({
  evaluations,
  showDraft,
  onDraftClick,
  onEvaluationClick,
  draftLabel = 'Draft',
}: {
  evaluations:
    | DevelopmentAreaEvaluationsResponse[]
    | PackageEvaluationsResponse[]
    | undefined;
  showDraft: boolean;
  onDraftClick: () => void;
  onEvaluationClick: () => void;
  draftLabel?: string;
}) => {
  const groupedEvaluations = groupEvaluationsByRunId(evaluations || []);

  return (
    <ul className={style.subMenu}>
      <DraftEvaluation
        showDraft={showDraft}
        onClick={() => {
          onDraftClick();
        }}
        draftLabel={draftLabel}
      />
      {evaluations ? (
        groupedEvaluations.map((el, i) => {
          if (el.length === 1) {
            const row = el[0];

            if ('evaluations' in row) {
              return (
                <CombinedItem
                  key={row.href}
                  evaluation={row}
                  showDraft={showDraft}
                  onEvaluationClick={onEvaluationClick}
                />
              );
            }

            return (
              <EvaluationItem
                key={el[0].id}
                evaluation={el[0]}
                showDraft={showDraft}
                onEvaluationClick={onEvaluationClick}
              />
            );
          }

          return (
            <div key={i} className={style.groupedEvaluations}>
              {el.map((el) => (
                <EvaluationItem
                  key={el.id}
                  evaluation={el}
                  showDraft={showDraft}
                  onEvaluationClick={onEvaluationClick}
                />
              ))}
            </div>
          );
        })
      ) : (
        <InlineLoading />
      )}
    </ul>
  );
};

export { EvaluationList };
