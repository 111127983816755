import {
  createResourceFormAtom,
  FormFieldState,
  FormRowStatus,
  hasChangedFields,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { atom, Getter, Setter } from 'jotai';
import { sortBy } from 'lodash';
import {
  DocumentInterpretationResponse,
  LocationResponse,
} from 'types/api-responses';
import type {
  ApplicableAreaForm,
  FormInitialValues,
  LocationAugmented,
} from './types';

export type ApplicableFormAtoms = ReturnType<
  typeof createApplicableAreaFormAtom
>;

export interface ApplicableAreaErrors {
  errors: {
    referenceLocation?: string;
    startDepthInFeet?: string;
    endDepthInFeet?: string;
  };
}

const createApplicableAreaFormAtom = (
  defaultValue: ApplicableAreaForm,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<ApplicableAreaForm, ApplicableAreaErrors>(
      defaultValue
    ),
  };
};

const formDefault = (
  {
    id,
    referenceLocation,
    startDepthInFeet,
    endDepthInFeet,
  }: FormInitialValues,
  state: FormFieldState
) =>
  initializeForm<ApplicableAreaForm>(
    {
      id,
      referenceLocation,
      startDepthInFeet,
      endDepthInFeet,
    },
    state
  );

const initForm = (
  locations: DocumentInterpretationResponse['locationReferences'] | undefined,
  state: FormFieldState
) => {
  if (!locations) {
    return [];
  }
  return locations.map((lr) =>
    createApplicableAreaFormAtom(
      formDefault(
        {
          id: lr.id,
          referenceLocation: lr.location,
          startDepthInFeet: lr.startDepthInFeet,
          endDepthInFeet: lr.endDepthInFeet,
        },
        state
      ),
      rowStatus(state)
    )
  );
};

const initEmptyForm = () => {
  return createApplicableAreaFormAtom(
    formDefault(
      {
        id: undefined,
        referenceLocation: null,
        startDepthInFeet: null,
        endDepthInFeet: null,
      },
      'updated'
    ),
    'new'
  );
};

const sortLocationResults = (
  results: LocationResponse[] | undefined,
  usedReferences: Set<number>,
  documentLocations: Set<number>
): LocationAugmented[] => {
  if (!results) {
    return [];
  }

  const augmentedResults = results.map((el) => ({
    ...el,
    confirmed: usedReferences.has(el.locationId ?? el.id),
    associatedToDocument: documentLocations.has(el.locationId),
  }));

  return sortBy(augmentedResults, [
    (item) => {
      if (item.confirmed) {
        return 1;
      } else if (item.associatedToDocument) {
        return 0;
      } else {
        return 2;
      }
    },
  ]);
};

const validate = (lrAtom: ApplicableFormAtoms, get: Getter, set: Setter) => {
  let hasError = false;
  const fields = get(lrAtom.row.formAtom);
  if (!fields.referenceLocation.value) {
    set(lrAtom.row.dirtyFieldsAtom, {
      errors: { referenceLocation: 'Survey area cannot be blank' },
    });
    hasError = true;
  }
  return hasError;
};

const getPayload = (el: ApplicableFormAtoms, get: Getter) => {
  const applicableAreas = get(el.row.formAtom);
  const rowStatus = get(el.status);

  const location = applicableAreas.referenceLocation.value;
  if (!location) {
    return [];
  }

  if (rowStatus === 'trash') {
    return { _destroy: true, id: applicableAreas.id.value };
  }

  if (!hasChangedFields(applicableAreas)) return [];

  return {
    id: applicableAreas.id.value,
    locationQuarterCalls: location.quarterCalls || [],
    locationReferenceLocationId: location.referenceLocation.id,
    startDepthInFeet: applicableAreas.startDepthInFeet.value,
    endDepthInFeet: applicableAreas.endDepthInFeet.value,
  };
};

export { initForm, initEmptyForm, sortLocationResults, validate, getPayload };
