import {
  Column,
  Grid,
  InlineLoading,
  InlineNotification,
  Row,
  Tab,
  Tabs,
} from 'carbon-components-react';
import { OutputListSkeleton } from '../common/output_list_skeleton';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  TitleCalculationResponse,
  TitleWorkspaceResponse,
} from 'types/api-responses';
import { sumBy } from 'lodash';
import { Date } from 'components/date';
import { useEffect, useState } from 'react';
import { DownloadButton } from 'components/download-button';
import { OpenPanelFilledLeft16, OpenPanelLeft16 } from '@carbon/icons-react';
import { useDownloadResource } from 'lib/hooks/useDownloadFile';
import { SelectedAsset, SubTractsList, WellsList } from './by-asset';

import style from './calculation.module.scss';
import { OwnerList, SelectedOwner } from './by-owner';
import { ownersAtom } from './by-owner/atoms';
import {
  calculationDateAtom,
  calculationsMapAtom,
  lastCalculationAtom,
  lastCalculationUriAtom,
  selectedOutputAtom,
} from './atoms';
import { CalculateForm } from './calculate-form';
import classNames from 'classnames';
import { DECIMAL_FORMATTER_TWO_FRACTIONS } from 'lib/ui';
import { subscribe } from 'lib/pusher';
import { titleCalculationPusherErrorMessageAtom } from 'atoms/title-calculation-atoms';
import { CalculateFormProgress } from 'types/calculate-form-progress';
import { TitleWorkspaceAtomType } from '../atoms';

const getCalculatedAcreage = (calculation?: TitleCalculationResponse) => {
  if (calculation && !(calculation.tracts && calculation.tracts.length)) {
    return null;
  }

  const allHaveAcreage = calculation?.tracts.every(
    (tract) => tract.calculatedAcreage !== null
  );

  return allHaveAcreage
    ? sumBy(calculation?.tracts, 'calculatedAcreage')
    : null;
};

const DownloadOwnershipButton = ({
  downloadHref,
  calculation,
}: {
  downloadHref: string;
  calculation: TitleCalculationResponse | undefined;
}) => {
  const downloadOwnership = useDownloadResource(downloadHref);

  return calculation?.status === 'processed' ? (
    <DownloadButton
      loading={downloadOwnership.generatingDownload}
      loadingText="Generating Ownership Report"
      onClick={() => downloadOwnership.triggerDownload()}
    >
      Download Ownership
    </DownloadButton>
  ) : null;
};

export function CalculationImpl({
  titleWorkspace,
  calculation,
  progress,
  isTitlePusherNotificationActive,
}: {
  titleWorkspace: TitleWorkspaceResponse;
  calculation: TitleCalculationResponse | undefined;
  progress: number;
  isTitlePusherNotificationActive: boolean;
}) {
  const [isActive, setIsActive] = useState(false);
  const [selectedOutput, setSelectedOutput] = useAtom(selectedOutputAtom);
  const getOwners = useSetAtom(ownersAtom);

  const calculatedAcreage = calculation && getCalculatedAcreage(calculation);
  const isAsset = selectedOutput === 'assets';

  const lastTag = titleWorkspace.resources.lastTag;

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  return (
    <Grid condensed fullWidth className={style.calculationContent}>
      <Row>
        <Column
          md={isActive ? 0 : calculation ? 2 : 16}
          className={style.sidePanel}
        >
          <aside>
            <div className={style.sideHeader}>
              <CalculateForm loading={Boolean(lastTag && !calculation)} />
              {!calculation ? <OutputListSkeleton /> : null}
              {calculation && (
                <div className={style.typeOfOutput}>
                  <Tabs selected={isAsset ? 0 : 1}>
                    <Tab
                      id="tab-assets"
                      label="Assets"
                      onClick={() => setSelectedOutput('assets')}
                    >
                      {isTitlePusherNotificationActive ? (
                        <InlineLoading
                          role="status"
                          aria-label="Calculating..."
                          description="Calculating..."
                          className={style.leftLoading}
                        />
                      ) : (
                        <>
                          {selectedOutput === 'assets' ? (
                            <>
                              <SubTractsList />
                              <WellsList />
                            </>
                          ) : null}
                        </>
                      )}
                    </Tab>
                    <Tab
                      id="tab-owners"
                      label={'Owners'}
                      onClick={() => {
                        getOwners(calculation.resources.entities.href);
                        setSelectedOutput('owners');
                      }}
                    >
                      {isTitlePusherNotificationActive ? (
                        <InlineLoading
                          role="status"
                          aria-label="Calculating..."
                          description="Calculating..."
                          className={style.leftLoading}
                        />
                      ) : (
                        <>
                          {selectedOutput === 'owners' ? (
                            <>
                              <OwnerList />
                            </>
                          ) : null}
                        </>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              )}
            </div>
          </aside>
        </Column>

        <Column
          md={isActive ? 8 : 6}
          className={isActive ? style.trackPanelOpen : style.trackPanel}
        >
          <div className={style.downloadOwnershipRow}>
            <a className={style.closePanelBtn} onClick={handleClick}>
              {isActive ? <OpenPanelLeft16 /> : <OpenPanelFilledLeft16 />}
            </a>
            {calculation && (
              <DownloadOwnershipButton
                downloadHref={calculation.resources.downloads.href}
                calculation={calculation}
              />
            )}
          </div>
          <div className={classNames(style.titleArea)}>
            <h2 className={style.titleWorkspace}>Title Workspace</h2>
            {isTitlePusherNotificationActive ? (
              <>
                {progress > 0 && (
                  <progress value={progress} max="100"></progress>
                )}
                <InlineLoading
                  role="status"
                  aria-label="Calculating..."
                  description="Calculating..."
                  className={style.leftLoading}
                />
              </>
            ) : (
              <>
                <p
                  data-testid="calculation-date"
                  className={style.dateCalculation}
                >
                  <span>Last Calculated: </span>
                  <Date date={calculation?.createdAt} format={'lll'} showTime />
                </p>
                <p className={style.dateCalculation}>
                  <span>With As-of Date: </span>
                  <Date date={calculation?.asOfDate} />
                </p>
                {calculatedAcreage && (
                  <p className={style.dateCalculation}>
                    <span>Total Title GIS Gross Acreage: </span>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(calculatedAcreage)}
                  </p>
                )}
              </>
            )}
          </div>
          {isTitlePusherNotificationActive ? (
            <InlineLoading
              role="status"
              aria-label="Calculating..."
              description="Calculating..."
              className={style.bottomLoading}
            />
          ) : isAsset ? (
            <SelectedAsset />
          ) : (
            <SelectedOwner titleWorkspace={titleWorkspace} />
          )}
        </Column>
      </Row>
    </Grid>
  );
}

export function Calculation({
  titleWorkspace,
  titleWorkspaceAtom,
}: {
  titleWorkspace: TitleWorkspaceResponse;
  titleWorkspaceAtom: TitleWorkspaceAtomType;
}) {
  const calculationDate = useAtomValue(calculationDateAtom);
  const calculation = useAtomValue(lastCalculationAtom);
  const setCalculationsMap = useSetAtom(calculationsMapAtom);
  const setLastCalculationUri = useSetAtom(lastCalculationUriAtom);
  const getTitleWorkspace = useSetAtom(titleWorkspaceAtom);
  const [isTitlePusherNotificationActive, setIsTitlePusherNotificationActive] =
    useState(false);
  const [progress, setProgress] = useState(0);
  const [
    titleCalculationPusherErrorMessage,
    setTitleCalculationPusherErrorMessage,
  ] = useAtom(titleCalculationPusherErrorMessageAtom);

  const lastTagHref = titleWorkspace.resources.lastTag?.href;
  const lastTagHrefWithDate = lastTagHref
    ? `${lastTagHref}${calculationDate ? '?date=' + calculationDate : ''}`
    : undefined;
  const calculationResult =
    calculation && calculation.data && !('error' in calculation.data)
      ? calculation.data
      : undefined;

  const channel =
    titleWorkspace.channel || calculationResult?.notifications.channel;
  const event = calculationResult?.notifications.events[0].name || 'change';
  const calculationHref =
    titleWorkspace.resources.currentCalculation?.href ||
    calculationResult?.href;

  useEffect(() => {
    if (lastTagHrefWithDate) {
      setLastCalculationUri(lastTagHrefWithDate);
      setCalculationsMap({
        href: lastTagHrefWithDate,
      });
    }
  }, [setLastCalculationUri, setCalculationsMap, lastTagHrefWithDate]);

  useEffect(() => {
    if (!channel || !calculationHref) {
      return () => void 0;
    }

    if (titleWorkspace.channel) {
      setIsTitlePusherNotificationActive(true);
    }

    const subscription = subscribe(channel);
    subscription.bind(event, (data: CalculateFormProgress) => {
      if ('calcStatus' && 'completionPercentage' in data) {
        setProgress(data.completionPercentage);
        if (data.calcStatus === 'error') {
          setIsTitlePusherNotificationActive(false);
          setTitleCalculationPusherErrorMessage(data.errorMessage);
          subscription.unsubscribe();
        }

        if (data.calcStatus === 'complete') {
          setIsTitlePusherNotificationActive(false);
          getTitleWorkspace();
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (titleCalculationPusherErrorMessage.length > 0) {
    return (
      <InlineNotification
        kind="error"
        title={titleCalculationPusherErrorMessage}
        lowContrast
      />
    );
  }

  return (
    <CalculationImpl
      calculation={calculationResult}
      titleWorkspace={titleWorkspace}
      progress={progress}
      isTitlePusherNotificationActive={isTitlePusherNotificationActive}
    />
  );
}
