import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from 'carbon-components-react';
import { sortBy } from 'lodash';
import style from '../page.module.scss';
import { OutputAggregate } from 'types/api-responses';

export function MineralInterest({
  interest,
  interestName,
}: {
  interest: OutputAggregate;
  interestName: string;
}) {
  const rows = Object.entries(interest).flatMap(
    ([executivePartyName, lineItems]) =>
      Object.entries(lineItems).map(([interestOwnerName, percentage]) => [
        executivePartyName,
        interestOwnerName,
        percentage,
      ])
  );

  return rows.length ? (
    <TableContainer className={style.table}>
      <Table size="xs" useStaticWidth={true}>
        <TableHead>
          <TableRow>
            <TableHeader>{interestName}</TableHeader>
            <TableHeader>Executive</TableHeader>
            <TableHeader>Mineral Interest</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy(rows).map(
            ([executivePartyName, interestOwnerName, percentage]) => (
              <TableRow key={[executivePartyName, interestOwnerName].join('')}>
                <TableCell>{executivePartyName}</TableCell>
                <TableCell>{interestOwnerName}</TableCell>
                <TableCell>{percentage}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
