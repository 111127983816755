import { atom, Atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Column,
  Dropdown,
  FormGroup,
  Grid,
  NumberInput,
  NumberInputOnChangeDefaultVariant,
  Row,
  Search,
  Tag,
} from 'carbon-components-react';
import {
  DocumentResponse,
  ReferenceLocationResponse,
} from 'types/api-responses';
import ErrorToast from 'components/error-toast';
import { Documents } from './documents';
import style from './documents-search.module.scss';
import { formatNumber } from '../search/common/format-helpers';
import { DocumentSearchParams } from './types';
import {
  defaultPayload,
  referenceLocationStyle,
  ReferenceLocationStyleFn,
} from './utils';
import { debounce } from 'lodash';
import {
  searchAtom,
  resetSearchDataAtom,
  documentSearchDateSortSettingAtom,
} from './atoms';
import {
  areReferenceLocationBucketsEqual,
  onReferenceLocationClick,
  useCalculateMapDimensions,
} from 'components/map/utils';
import {
  createAbortableLocationsAtom,
  getMapDefaultConfig,
} from 'components/map/atoms';
import { Map, PolygonArea } from 'components/map';
import { LocationAtom } from 'components/map/types';
import classNames from 'classnames';
import { SearchTips } from './search-tips';
import { atomWithStorage } from 'jotai/utils';
import sortFields, { SortField } from './sort-fields';
import { ProvisionsFilter } from 'components/provision-filter';

const isValidYear = (value: string): boolean =>
  !!value.match(/\d{4}/) || value === '';

const AvailableDates = () => {
  const searchResult = useAtomValue(searchAtom);
  const facet = searchResult?.data?.facets?.instrumentDate;
  return (
    <>
      {facet ? (
        <p className={style.resultsSummary}>
          Results available from {facet.minYear}&ndash;{facet.maxYear}.
        </p>
      ) : (
        'No results available.'
      )}
    </>
  );
};

const ProvisionFilterGroup = () => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);

  const hasCompletion = searchResult.currentPayload?.hasCompletion;

  return (
    <FormGroup legendText="Document Filter">
      <ProvisionsFilter
        hasCompletion={hasCompletion}
        onChange={(hasCompletion) =>
          setSearchPayload((current) => ({
            ...current,
            page: 1,
            hasCompletion,
          }))
        }
      />
    </FormGroup>
  );
};

const SortByDate = () => {
  const setSearchPayload = useSetAtom(searchAtom);
  const [sortSettings, setSortSettings] = useAtom(
    documentSearchDateSortSettingAtom
  );

  const selectedSort = sortFields.find(
    (el) =>
      el.id === sortSettings?.sortAttribute &&
      el.direction === sortSettings?.sortDirection
  );

  return (
    <FormGroup legendText="Sort By Date">
      <Dropdown
        id="sort-documents-by-date"
        label=""
        items={sortFields}
        itemToString={(item) => (item ? item.text : '')}
        selectedItem={selectedSort ?? null}
        onChange={({ selectedItem }: { selectedItem: SortField }) => {
          setSortSettings({
            sortAttribute: selectedItem.id,
            sortDirection: selectedItem.direction,
          });
          setSearchPayload(
            (current) =>
              current && {
                ...current,
                sortAttribute: selectedItem.id,
                sortDirection: selectedItem.direction,
              }
          );
        }}
      />
    </FormGroup>
  );
};

const ReferenceLocationPills = () => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const searchPayload = searchResult.currentPayload;

  const facet = searchResult?.data?.facets?.referenceLocation;

  const selectedReferenceLocationIds =
    searchPayload?.referenceLocationIds || [];

  const selectedReferenceLocations =
    facet?.buckets?.filter((referenceLocation) => {
      return selectedReferenceLocationIds.includes(referenceLocation.id);
    }) || [];

  return (
    <FormGroup legendText="Area on Map">
      {selectedReferenceLocations.length ? (
        selectedReferenceLocations.map((referenceLocation) => (
          <Tag
            filter
            key={referenceLocation.id}
            type="purple"
            onClose={() => {
              setSearchPayload((current) => ({
                ...current,
                referenceLocationIds: current?.referenceLocationIds?.filter(
                  (id) => id !== referenceLocation.id
                ),
              }));
            }}
          >
            {`${referenceLocation.shortName} (${referenceLocation.count})`}
          </Tag>
        ))
      ) : (
        <Tag type="blue">All Areas</Tag>
      )}
    </FormGroup>
  );
};

const InstrumentDateFacets = () => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const searchPayload = searchResult.currentPayload;
  return (
    <FormGroup legendText="Year Range">
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column>
            <NumberInput
              id="documents-search-instrument-date-min-year"
              label="Start Year"
              allowEmpty={true}
              placeholder="yyyy"
              value={searchPayload?.instrumentDateMinYear || ''}
              className={style.dateField}
              // All this ugliness is carbon's fault.
              onChange={
                ((event: { imaginaryTarget: { value: string } }) => {
                  if (!isValidYear(event.imaginaryTarget.value)) return;
                  setSearchPayload((current) => ({
                    ...current,
                    page: 1,
                    instrumentDateMinYear: event.imaginaryTarget.value
                      ? Number(event.imaginaryTarget.value)
                      : undefined,
                  }));
                }) as unknown as NumberInputOnChangeDefaultVariant
              }
            />
          </Column>
          <Column>
            <NumberInput
              id="documents-search-instrument-date-max-year"
              label="End Year"
              allowEmpty={true}
              placeholder="yyyy"
              value={searchPayload?.instrumentDateMaxYear || ''}
              className={style.dateField}
              // All this ugliness is carbon's fault.
              onChange={
                ((event: { imaginaryTarget: { value: string } }) => {
                  if (!isValidYear(event.imaginaryTarget.value)) return;
                  return setSearchPayload((current) => ({
                    ...current,
                    page: 1,
                    instrumentDateMaxYear: Number(event.imaginaryTarget.value),
                  }));
                }) as unknown as NumberInputOnChangeDefaultVariant
              }
            />
          </Column>
        </Row>
      </Grid>
    </FormGroup>
  );
};

const updatedCounties = (
  countyId: number,
  currentPayload?: DocumentSearchParams
) => {
  const existingCountyIds = currentPayload?.countyIds || [];

  return existingCountyIds.includes(countyId)
    ? existingCountyIds.filter((id) => id !== countyId)
    : existingCountyIds.concat([countyId]);
};

const CountyCheckboxes = () => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const searchPayload = searchResult.currentPayload;
  const facet = searchResult?.data?.facets?.county;
  return (
    <FormGroup legendText="County">
      {facet ? (
        facet.buckets.map((bucket) => (
          <Checkbox
            key={bucket.id}
            id={`search-county-${bucket.id}`}
            labelText={`${bucket.name} (${formatNumber(bucket.count)})`}
            checked={searchPayload?.countyIds?.includes(bucket.id) ?? false}
            onChange={() => {
              setSearchPayload((current) => ({
                ...current,
                page: 1,
                countyIds: updatedCounties(bucket.id, current),
              }));
            }}
          />
        ))
      ) : (
        <Checkbox
          id={`search-county-none`}
          labelText="None"
          disabled
          checked={false}
        />
      )}
    </FormGroup>
  );
};

const mapConfigAtom = atomWithStorage(
  'insights-document-search-map-config',
  getMapDefaultConfig()
);

const MapSelection = () => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const searchPayload = searchResult.currentPayload;

  const newReferenceLocationBuckets =
    searchResult?.data?.facets?.referenceLocation?.buckets || [];

  const [currentReferenceLocationBuckets, setCurrentReferenceLocationBuckets] =
    useState(newReferenceLocationBuckets);

  if (
    !areReferenceLocationBucketsEqual(
      currentReferenceLocationBuckets,
      newReferenceLocationBuckets
    )
  ) {
    setCurrentReferenceLocationBuckets(newReferenceLocationBuckets);
  }

  const locationsAtom = useMemo(
    () =>
      atom(() => {
        return createAbortableLocationsAtom(currentReferenceLocationBuckets);
      }),
    [currentReferenceLocationBuckets]
  );

  const onClick = useCallback(
    (e) => onReferenceLocationClick(e, searchPayload || {}, setSearchPayload),
    [searchPayload, setSearchPayload]
  );

  const locationCollectionAtom = useAtomValue(useAtomValue(locationsAtom));

  const containerRef = useRef(null);

  const { latitude, longitude, zoom } = useCalculateMapDimensions({
    points: searchResult?.data?.boundingBox?.points,
    containerRef,
  });

  const facet = searchResult?.data?.facets?.referenceLocation;
  const hoveredReferenceLocations =
    facet?.buckets.filter((referenceLocation) => ({
      id: referenceLocation.id,
      name: referenceLocation.name,
    })) || [];

  const styleFn = useMemo(() => {
    const referenceLocations = facet?.buckets || [];
    let rangeOfCounts = [0, 0];
    if (referenceLocations.length) {
      rangeOfCounts = [
        referenceLocations[referenceLocations.length - 1].count,
        referenceLocations[0].count,
      ];
    }
    const [min, max] = rangeOfCounts;
    const styleFn = (location: ReferenceLocationResponse & { count: number }) =>
      referenceLocationStyle(
        location,
        searchResult?.currentPayload?.referenceLocationIds || [],
        min,
        max - min
      );
    return styleFn;
  }, [facet?.buckets, searchResult?.currentPayload?.referenceLocationIds]);

  return (
    <div className={style.mapArea} ref={containerRef}>
      <Map
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        onClick={onClick}
        geographies={hoveredReferenceLocations}
        mapConfigAtom={mapConfigAtom}
        showControls
        showMapFilters
      >
        {locationCollectionAtom.map((locationAtom) => (
          <Suspense fallback={null} key={`${locationAtom}`}>
            <AsyncPolygon locationAtom={locationAtom} styleFn={styleFn} />
          </Suspense>
        ))}
      </Map>
    </div>
  );
};

const AsyncPolygon = ({
  locationAtom,
  styleFn,
}: {
  locationAtom: LocationAtom;
  styleFn: ReferenceLocationStyleFn;
}) => {
  const location = useAtomValue(locationAtom);
  if (!location || 'error' in location) return null;
  return (
    <PolygonArea
      geometry={location.geometry}
      id={location.id}
      style={styleFn(location)}
    />
  );
};

interface Props {
  onAddDocument?: (doc: DocumentResponse) => void;
  usedDocumentsAtom?: Atom<number[]>;
  noGutter?: boolean;
  showTips?: boolean;
}

const DocumentsSearcher = ({
  onAddDocument,
  usedDocumentsAtom = atom<number[]>([]),
  noGutter,
  showTips,
}: Props) => {
  const [searchDisplayText, setSearchDisplayText] = useState('');
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const searchSettings = useAtomValue(documentSearchDateSortSettingAtom);

  useEffect(() => {
    return () => resetSearchData({ unsetCurrentPayload: true });
  }, [resetSearchData]);

  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        resetSearchData(undefined);
        setSearchPayload((current) => ({
          ...current,
          pageSize: current?.pageSize || 10,
          page: 1,
          text: value,
          sortAttribute: searchSettings
            ? searchSettings.sortAttribute
            : undefined,
          sortDirection: searchSettings
            ? searchSettings.sortDirection
            : undefined,
        }));
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchPayload, resetSearchData]
  );

  return (
    <>
      {searchResult?.error ? <ErrorToast message={searchResult.error} /> : null}
      <div className={style.search}>
        <Search
          data-modal-primary-focus
          labelText="Search"
          placeholder="Search"
          size="lg"
          autoFocus={true}
          value={searchDisplayText}
          onChange={({ target: { value } }) => {
            setSearchDisplayText(value);
            debouncedSetSearchPayload(value);
          }}
          className={style.input}
        />
        {showTips && <SearchTips />}
      </div>

      <Grid className={classNames({ 'bx--no-gutter': noGutter })} fullWidth>
        <Row>
          <Column sm={12} md={2} lg={3} className={style.documentFilters}>
            {searchResult.data?.facets && (
              <>
                <AvailableDates />
                <ResetFilters />
                <ProvisionFilterGroup />
                <SortByDate />
                <InstrumentDateFacets />
                <CountyCheckboxes />
                <ReferenceLocationPills />
              </>
            )}
          </Column>
          <Column sm={12} md={6} lg={13}>
            {searchResult.data?.results.length ? <MapSelection /> : null}
            <Documents
              searchResult={searchResult.data}
              onChange={(newSearch) =>
                setSearchPayload((current) => ({
                  ...current,
                  ...newSearch,
                }))
              }
              onAddDocument={onAddDocument}
              usedDocumentsAtom={usedDocumentsAtom}
              loading={searchResult.loading}
            />
          </Column>
        </Row>
      </Grid>
    </>
  );
};

const ResetFilters = () => {
  const setSearchPayload = useSetAtom(searchAtom);
  const setSortSettings = useSetAtom(documentSearchDateSortSettingAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  return (
    <div className={style.resetFilters}>
      <Button
        kind="ghost"
        size="md"
        onClick={() => {
          setSortSettings(undefined);
          resetSearchData(undefined);
          setSearchPayload((current) => ({
            ...defaultPayload,
            text: current?.text || '',
          }));
        }}
      >
        Reset Filters
      </Button>
    </div>
  );
};

const DocumentsSearch = (props: Props) => {
  return <DocumentsSearcher {...props} />;
};

export { DocumentsSearch };
