import { createMutationAtom } from 'atoms/create-resource-atom';
import { atom } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import { EventResponse } from 'types/api-responses';

const useEventsAtom = (href: string) => {
  return useMemo(() => createResourceAtom<EventResponse[]>(href), [href]);
};

const createCommentAtom = createMutationAtom<null, 'comments'>();
const isEventsPanelOpenAtom = atom<boolean>(false);

export { createCommentAtom, useEventsAtom, isEventsPanelOpenAtom };
