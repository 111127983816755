import { SearchParams } from 'atoms/search-atom';
import {
  Column,
  Grid,
  Row,
  SkeletonPlaceholder,
  SkeletonText,
  Tile,
} from 'carbon-components-react';
import { Pagination } from 'components/pagination';
import {
  itemRangeText,
  pageRangeText,
} from 'components/search/common/format-helpers';

import plur from 'plur';
import type { FC, ReactNode } from 'react';

import type {
  ActivitiesQueryResponse,
  ApiQueryResponse,
  InventoryResponse,
  SearchResult,
} from 'types/api-responses';
import style from './cards-grid.module.scss';
import { format } from 'lib/ui';

const PAGE_SIZES = [16, 24, 48, 64];

interface Props<ApiResponse extends ApiQueryResponse> {
  itemName: string;
  renderCard: (data: ApiResponse) => ReactNode;
  searchResponse?: SearchResult<ApiResponse> | null;
  actions: ReactNode;
  renderEmptyState: ReactNode;
  onChange: (payload: SearchParams) => void;
  emptyState?: ReactNode;
  loading?: boolean;
}

// ActivitiesQueryResponse and InventoryResponse are the only query
// responses that don't have an id
type QueryResponse = Exclude<
  Exclude<ApiQueryResponse, ActivitiesQueryResponse>,
  InventoryResponse
>;

const CardsGrid = <ApiResponse extends QueryResponse>({
  searchResponse,
  itemName,
  renderCard,
  renderEmptyState,
  onChange,
  actions = null,
  loading,
}: Props<ApiResponse>) => {
  const handlePageChange = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    window.scrollTo(0, 0);
    onChange({ page, pageSize });
  };

  const totalNumber = searchResponse ? (
    `${format.number(searchResponse.totalCount)} ${plur(
      itemName,
      searchResponse.totalCount
    )}`
  ) : (
    <SkeletonText heading width="25%" />
  );
  return (
    <Results
      header={
        itemName === 'package' ||
        itemName === 'development area' ||
        itemName === 'title workspace'
          ? null
          : totalNumber
      }
      actions={actions}
      emptyState={
        searchResponse && searchResponse.totalCount === 0 ? (
          <Tile>{renderEmptyState}</Tile>
        ) : null
      }
    >
      {loading ? (
        <Skeleton />
      ) : searchResponse ? (
        <>
          {searchResponse.results.map((el) => (
            <Column key={el.id} sm={8} md={4} lg={4} className={style.result}>
              {renderCard(el)}
            </Column>
          ))}
          {searchResponse && searchResponse.totalCount > 0 ? (
            <Column sm={4}>
              <Pagination
                pageSizes={PAGE_SIZES}
                pageSize={searchResponse.pageSize}
                page={searchResponse.page}
                totalItems={searchResponse.totalCount}
                onChange={handlePageChange}
                itemRangeText={itemRangeText}
                pageRangeText={pageRangeText}
              />
            </Column>
          ) : null}
        </>
      ) : null}
    </Results>
  );
};

interface ResultProps {
  actions?: ReactNode;
  emptyState?: ReactNode;
  header: ReactNode;
}

const Results: FC<ResultProps> = ({
  actions,
  children,
  header,
  emptyState,
}) => {
  return (
    <Grid className="bx--no-gutter">
      <header className={style.header}>
        <h2 className={style.title}>{header}</h2>
        {actions && <aside className={style.actions}>{actions}</aside>}
      </header>
      {emptyState}
      <Row>{children}</Row>
    </Grid>
  );
};

function SkeletonItem() {
  return (
    <Column md={4} lg={4} className={style.result}>
      <SkeletonPlaceholder className={style.placeholderItem} />
    </Column>
  );
}

export function Skeleton() {
  return (
    <>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </>
  );
}

export default CardsGrid;
