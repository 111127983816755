import { Dropdown } from 'carbon-components-react';
import { provisionFilters } from 'lib/constants';
import { HasCompletionType, ProvisionFilterType } from 'lib/types';

export function ProvisionsFilter({
  hasCompletion,
  onChange,
}: {
  hasCompletion?: HasCompletionType;
  onChange: (hasCompletion?: HasCompletionType) => void;
}) {
  const selectedProvisionFilter = provisionFilters.find(
    (el) => el.hasCompletion === hasCompletion
  );

  return (
    <Dropdown
      id="default"
      label={''}
      selectedItem={selectedProvisionFilter}
      items={provisionFilters}
      itemToString={(item) => (item ? item.text : '')}
      onChange={({ selectedItem }: { selectedItem: ProvisionFilterType }) => {
        const hasCompletion = selectedItem.hasCompletion;
        onChange(hasCompletion);
      }}
    />
  );
}
