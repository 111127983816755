import { getError } from 'atoms/create-resource-atom';
import { Column, Grid, Row } from 'carbon-components-react';
import { EntityAutocompleteWithCreationModal } from 'components/entity-autocomplete-with-creation-modal';
import { FormRow } from 'components/forms';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';
import { DeleteRowButton } from '../delete-row-button';
import { EntitySubform } from './types';
import { EntitySubformAtoms } from './utils';
import style from './entity-row.module.scss';

interface EntityProps {
  atoms: EntitySubformAtoms;
  atomIdx: number;
  trxRowDisabled: boolean;
  field: 'Lessor' | 'Lessee' | 'Grantor' | 'Grantee';
  id: string;
  onDelete?: (atomIdx: number) => void;
}

const EntityRow = ({
  atoms,
  atomIdx,
  trxRowDisabled,
  field,
  onDelete,
  id,
}: EntityProps) => {
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const status = useAtomValue(atoms.status);

  const disabled = trxRowDisabled || status === 'trash';

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <FormRow
      toTrash={status === 'trash'}
      key={`${field}-${atomIdx}`}
      className={style.entityGrid}
    >
      <Grid fullWidth className="bx--no-gutter">
        <Row>
          <Column sm={3} md={6} lg={14}>
            <EntityAutocompleteWithCreationModal
              onCreationSuccess={(entity) => {
                setField({
                  field: 'entity',
                  value: entity,
                });
              }}
              status={status}
              placeholder=""
              id={`${atomIdx}-${id}-${field}`}
              titleText={
                <>
                  {field}
                  <span className={style.indexIndicator}>{atomIdx + 1}</span>
                </>
              }
              selectedItem={fields.entity.value}
              onChangeSuccess={(selectedItem) => {
                setField({
                  field: 'entity',
                  value: selectedItem ?? null,
                });
              }}
              invalid={!!getError<EntitySubform>(fields, 'entity')}
              invalidText={getError<EntitySubform>(fields, 'entity')}
              disabled={disabled}
              light
            />
          </Column>
          {atomIdx !== 0 && onDelete ? (
            <Column sm={1} md={2} lg={2} className={style.removeButton}>
              <DeleteRowButton
                statusAtom={atoms.status}
                atomIdx={atomIdx}
                onDelete={onDelete}
                disabled={disabled}
              />
            </Column>
          ) : null}
        </Row>
      </Grid>
    </FormRow>
  );
};

export { EntityRow };
