import {
  Grid,
  Row,
  Column,
  Tile,
  Link,
  Button,
  Modal,
} from 'carbon-components-react';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';
import { useEffect, useMemo, useState } from 'react';

import style from './interpretation-view.module.scss';
import { InterpretationSummary } from './interpretation-summary';
import { getDocumentAtom, usePendingChanges } from './atoms';
import { PrimitiveAtom, useAtom, useSetAtom } from 'jotai';
import {
  Edit16,
  EditOff16,
  Close20,
  Launch16,
  ChevronDown16,
  ChevronUp16,
} from '@carbon/icons-react';
import { EditInterpretation } from './edit-interpretation';
import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { TOP_RUNSHEET_SECTIONS_HEIGHT_IN_PX } from 'lib/constants';

const EditInterpretationButton = ({
  label,
  renderIcon,
  disabled,
  onClick,
}: {
  label: string;
  renderIcon: string;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      hasIconOnly
      kind="ghost"
      size="lg"
      iconDescription={label}
      tooltipAlignment="center"
      tooltipPosition="bottom"
      renderIcon={renderIcon === 'summary' ? Edit16 : EditOff16}
      disabled={disabled}
      onClick={() => {
        onClick();
      }}
    />
  );
};

const InterpretationDrawerContent = (props: {
  href: string;
  documentHref?: string;
  titleWorkspace?: number;
  titleWorkspaceHref?: string;
  referenceLocationHref?: string;
  transactionId?: number;
  showEditButton?: boolean;
  currentView: ViewType;
  setCurrentView: React.Dispatch<React.SetStateAction<ViewType>>;
  setRowIsOutdated: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}) => {
  const [
    [interpretationHasEdits, setInterpretationHasEdits],
    [showPendingChangesWarning, toggleShowPendingChangesWarning],
  ] = usePendingChanges();

  const documentDownloadHrefAtom = useMemo(() => getDocumentAtom(), []);
  const [loading, getDocumentHref] = useAtom(documentDownloadHrefAtom);

  const documentHref = props.documentHref;
  const href = props.href;
  const titleWorkspaceHref = props.titleWorkspaceHref;

  return (
    <Tile className={style.summaryTile}>
      <Grid className="bx--no-gutter" fullWidth condensed>
        {showPendingChangesWarning ? (
          <DiscardPendingChangesWarning
            onYes={() => {
              props.setCurrentView({ type: 'summary' });
              toggleShowPendingChangesWarning();
              setInterpretationHasEdits(false);
            }}
            onNo={toggleShowPendingChangesWarning}
          />
        ) : documentHref ? (
          <div className={style.editButtonArea}>
            <>
              <Button
                hasIconOnly
                kind="ghost"
                size="lg"
                iconDescription="Open Interpretation"
                tooltipAlignment="center"
                tooltipPosition="bottom"
                renderIcon={Launch16}
                onClick={() =>
                  window.open(
                    `${href}${
                      titleWorkspaceHref
                        ? `?referrer=${titleWorkspaceHref}/runsheet`
                        : ''
                    }`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              />
              {props.showEditButton && (
                <EditInterpretationButton
                  label={
                    props.currentView.type === 'summary'
                      ? 'Edit Interpretation'
                      : 'View Summary'
                  }
                  renderIcon={props.currentView.type}
                  disabled={loading}
                  onClick={() => {
                    if (
                      interpretationHasEdits &&
                      props.currentView.type === 'edit'
                    ) {
                      toggleShowPendingChangesWarning();
                      return;
                    }

                    if (props.currentView.type === 'edit') {
                      props.setCurrentView({ type: 'summary' });
                      return;
                    }

                    getDocumentHref({
                      href: documentHref,
                      onSuccess: (document) => {
                        props.setCurrentView({ type: 'edit', document });
                      },
                    });
                  }}
                />
              )}
            </>
          </div>
        ) : null}
        <Row>
          <Column>
            {props.currentView.type === 'summary' && (
              <InterpretationSummary {...props} />
            )}
            {props.currentView.type === 'edit' && (
              <EditInterpretation
                document={props.currentView.document}
                interpretationHref={props.href}
                onSuccess={() => {
                  setInterpretationHasEdits(false);
                  props.setCurrentView({ type: 'summary' });
                  props.setRowIsOutdated?.(true);
                }}
              />
            )}
          </Column>
        </Row>
      </Grid>
    </Tile>
  );
};

const DiscardPendingChangesWarning = ({
  onYes,
  onNo,
}: {
  onYes: () => void;
  onNo: () => void;
}) => {
  return (
    <Modal
      modalHeading="Unsaved Changes"
      danger
      size="md"
      primaryButtonText="Yes, Discard"
      secondaryButtonText="Cancel"
      onRequestClose={onNo}
      onRequestSubmit={onYes}
      onSecondarySubmit={onNo}
      shouldSubmitOnEnter={true}
      className={style.discardChangesModal}
      open
    >
      <p>The interpretation has unsaved changes. Discard changes?</p>
    </Modal>
  );
};

type ViewType =
  | { type: 'summary' }
  | { type: 'edit'; document: DocumentResponse };

const InterpretationDrawer = ({
  open,
  onClose,
  documentTitle,
  closeConfirmation,
  interpretationHref,
  titleWorkspaceId,
  referenceLocationHref,
  titleWorkspaceHref,
  transactionId,
  documentId,
  showEditButton,
  totalRows = 0,
  rowIndex = 0,
  setRowIndex = undefined,
  setRowIsOutdated,
  currentViewState,
}: {
  open: boolean;
  onClose: () => void;
  documentTitle: string;
  closeConfirmation?: JSX.Element;
  interpretationHref: string;
  titleWorkspaceId?: number;
  referenceLocationHref?: string;
  titleWorkspaceHref?: string;
  transactionId?: number;
  documentId?: number;
  showEditButton: boolean;
  totalRows?: number;
  rowIndex?: number;
  setRowIndex?: (rowIndex: number) => void;
  setRowIsOutdated?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  currentViewState?: [ViewType, React.Dispatch<React.SetStateAction<ViewType>>];
}) => {
  const [currentView, setCurrentView] = currentViewState || [
    { type: 'summary' },
    () => void 0,
  ];

  return (
    <Drawer
      lockBackgroundScroll
      open={open}
      direction="bottom"
      size={'90%'}
      onClose={onClose}
      className={style.interpretationViewDrawer}
    >
      <DrawerHeader>
        <div className={style.drawerHeader}>
          <h3>{documentTitle}</h3>
          {setRowIndex && currentView.type === 'summary' && (
            <div
              className={
                showEditButton ? style.arrowControls : style.arrowControlsNoEdit
              }
            >
              <Button
                hasIconOnly
                kind="ghost"
                size="lg"
                disabled={rowIndex === 0}
                onClick={() => setRowIndex(rowIndex - 1)}
                iconDescription="Previous"
                tooltipAlignment="center"
                tooltipPosition="bottom"
                renderIcon={ChevronUp16}
              />
              <Button
                hasIconOnly
                kind="ghost"
                size="lg"
                disabled={rowIndex === totalRows - 1}
                onClick={() => setRowIndex(rowIndex + 1)}
                iconDescription="Next"
                tooltipAlignment="center"
                tooltipPosition="bottom"
                renderIcon={ChevronDown16}
              />
            </div>
          )}
          {closeConfirmation ? (
            <div>{closeConfirmation}</div>
          ) : (
            <Button
              hasIconOnly
              size="lg"
              kind="ghost"
              onClick={onClose}
              iconDescription={'Close'}
              tooltipAlignment="center"
              tooltipPosition="bottom"
              renderIcon={Close20}
            />
          )}
        </div>
      </DrawerHeader>
      <DrawerContent>
        {open && (
          <InterpretationDrawerContent
            currentView={currentView}
            setCurrentView={setCurrentView}
            href={interpretationHref}
            titleWorkspace={titleWorkspaceId}
            referenceLocationHref={referenceLocationHref}
            titleWorkspaceHref={titleWorkspaceHref}
            documentHref={documentId ? `/documents/${documentId}` : undefined}
            transactionId={transactionId}
            showEditButton={showEditButton}
            setRowIsOutdated={setRowIsOutdated}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
};

const InterpretationView = ({
  rows,
  rowIndex,
  titleWorkspace,
  titleWorkspaceHref,
  referenceLocationHref,
  editedRowsMapAtoms,
  showEditInterpretationButton,
  refsMap,
}: {
  rows: DocumentInterpretationResponse[];
  rowIndex: number;
  titleWorkspace?: number;
  referenceLocationHref?: string;
  titleWorkspaceHref?: string;
  showEditInterpretationButton: boolean;
  editedRowsMapAtoms: Record<number, PrimitiveAtom<boolean>>;
  refsMap?: Map<number, HTMLElement | null | undefined>;
}) => {
  const [open, setOpen] = useState(false);
  const [
    [interpretationHasEdits],
    [showPendingChangesWarning, toggleShowPendingChangesWarning],
  ] = usePendingChanges();

  const [index, setIndex] = useState(rowIndex);
  const row = rows[index];
  const editedRowAtom = editedRowsMapAtoms[index];

  const setRowIsOutdated = useSetAtom(editedRowAtom);

  const currentViewState = useState<ViewType>({ type: 'summary' });

  useEffect(() => {
    setIndex(rowIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function onClose() {
    currentViewState[1]({ type: 'summary' });
    setOpen(false);

    // Only go to the ref if the user used the Prev/Next buttons
    if (rowIndex === index) return;

    const rowRef = refsMap?.get(row.id);
    if (rowRef) {
      const topPosition = rowRef.getBoundingClientRect().top;
      const offsetPosition =
        topPosition + window.scrollY - TOP_RUNSHEET_SECTIONS_HEIGHT_IN_PX;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'instant',
      });
    }
  }

  return (
    <>
      <Link onClick={() => setOpen(true)}>{row.document.documentTitle}</Link>
      <InterpretationDrawer
        rowIndex={index}
        totalRows={rows.length}
        setRowIndex={(index) => setIndex(index)}
        setRowIsOutdated={setRowIsOutdated}
        showEditButton={showEditInterpretationButton}
        documentTitle={row.document.documentTitle}
        interpretationHref={row.href}
        open={open}
        titleWorkspaceId={titleWorkspace}
        referenceLocationHref={referenceLocationHref}
        titleWorkspaceHref={titleWorkspaceHref}
        documentId={row.document.id}
        currentViewState={currentViewState}
        closeConfirmation={
          showPendingChangesWarning ? (
            <DiscardPendingChangesWarning
              onYes={() => {
                toggleShowPendingChangesWarning();
                onClose();
              }}
              onNo={toggleShowPendingChangesWarning}
            />
          ) : (
            <Button
              hasIconOnly
              size="lg"
              kind="ghost"
              iconDescription={'Close'}
              tooltipAlignment="center"
              tooltipPosition="bottom"
              renderIcon={Close20}
              onClick={() => {
                if (interpretationHasEdits) {
                  toggleShowPendingChangesWarning();
                  return;
                }
                onClose();
              }}
            />
          )
        }
        onClose={() => {
          if (interpretationHasEdits) {
            toggleShowPendingChangesWarning();
            return;
          }
          onClose();
        }}
      />
    </>
  );
};

export { InterpretationView, InterpretationDrawer };
