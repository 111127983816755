import { PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import {
  runEvaluationAtom,
  useCreateFormAtom,
  useScenariosAtom,
} from './atoms';
import {
  Button,
  FilterableMultiSelect,
  InlineLoading,
  InlineNotification,
} from 'carbon-components-react';
import { AtomizedForm, getFormIsInvalidAtom } from 'atoms/form-atoms';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PackageEvaluationForm } from './types';

import style from './packages-evaluation.module.scss';
import { capitalizeFirstLetter } from 'utils/strings';
import { groupBy } from 'lodash';

const CreateButton = ({
  formAtom,
  href,
  scenarioIds,
  getEvaluations,
  combine,
}: {
  formAtom: PrimitiveAtom<AtomizedForm<PackageEvaluationForm>>;
  href: string;
  scenarioIds: number[];
  combine: boolean;
  getEvaluations: () => Promise<void>;
}) => {
  const isInvalidAtom = useMemo(
    () => getFormIsInvalidAtom(formAtom),
    [formAtom]
  );
  const isInvalid = useAtomValue(isInvalidAtom);
  const [status, runEvaluation] = useAtom(runEvaluationAtom);
  const [error, setError] = useState<string | undefined>(undefined);
  const history = useHistory();
  return (
    <div className={style.btnsArea}>
      {error && <InlineNotification kind="error" title={error} lowContrast />}
      <Button
        size="md"
        onClick={() => {
          setError(undefined);
          runEvaluation({
            type: 'POST',
            url: href,
            data: { scenarioIds, combine },
            onSuccess: (data) => {
              getEvaluations().then(() => {
                history.push(data.href);
              });
            },
            onError: () => {
              setError('Error creating the evaluation');
            },
          });
        }}
        disabled={isInvalid || status.loading}
      >
        Create Evaluation
      </Button>
    </div>
  );
};

const EvaluationForm = ({
  href,
  getEvaluations,
}: {
  href: string;
  getEvaluations: () => Promise<void>;
}) => {
  const scenariosAtom = useScenariosAtom();
  const scenarios = useAtomValue(scenariosAtom);
  const formAtom = useCreateFormAtom();

  const form = useAtomValue(formAtom);
  const [scenarioIds, setScenarios] = useAtom(form.scenarioIds);
  const [combine, setCombine] = useAtom(form.combine);

  if (scenarios.data && 'error' in scenarios.data)
    return (
      <InlineNotification
        kind="error"
        title={scenarios.data.error}
        lowContrast
      />
    );

  if (scenarios.loading)
    return <InlineLoading description="Loading scenarios" />;

  if (!scenarios.data) {
    return (
      <InlineNotification
        kind="error"
        title="Error loading scenarios"
        lowContrast
      />
    );
  }

  const scenariosList = scenarios.data;

  const selectedScenariosByInterestTypes = groupBy(
    scenarioIds.value.map((el) =>
      scenariosList.find((scenario) => scenario.id === el)
    ),
    'interestType'
  );

  const scenariosAreCombinable =
    selectedScenariosByInterestTypes['working']?.length === 1 &&
    selectedScenariosByInterestTypes['royalty']?.length === 1;

  if (!scenariosAreCombinable && combine.value) {
    setCombine(false);
  }

  return (
    <div>
      <h2 className={style.subtitle}>New Evaluation</h2>
      <div className={style.evaluationForm}>
        <FilterableMultiSelect
          id="scenarios"
          items={scenarios.data}
          light
          titleText="Select Scenarios"
          placeholder="Type to search scenarios"
          itemToString={(el) =>
            `${el?.name} - ${capitalizeFirstLetter(el?.interestType || '')}` ||
            ''
          }
          onChange={({ selectedItems }) => {
            if (selectedItems.length) {
              setScenarios(selectedItems.map((el) => el.id));
            } else {
              setScenarios([]);
            }
          }}
          invalid={!scenarioIds.isValid}
          invalidText={!scenarioIds.isValid ? scenarioIds.error : ''}
        ></FilterableMultiSelect>
      </div>

      <div className={style.calculateBtns}>
        <CreateButton
          formAtom={formAtom}
          href={href}
          scenarioIds={scenarioIds.value}
          combine={combine.value}
          getEvaluations={getEvaluations}
        />
      </div>
    </div>
  );
};

export { EvaluationForm };
