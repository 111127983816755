import { Column, Grid, Row, Tile } from 'carbon-components-react';
import { InterpretationForm } from 'components/interpretation-form';
import { useSetAtom } from 'jotai';
import { DocumentResponse } from 'types/api-responses';
import { interpretationHasChangesAtom } from './atoms';
import { EmptyState } from 'components/empty-state';
import DocumentSvg from 'images/srp-illustration-document.svg';
import style from './interpretation-view.module.scss';
import { DocumentHeaderAttributes } from './document-header-attributes';

const EditInterpretation = ({
  interpretationHref,
  document,
  onSuccess,
}: {
  interpretationHref: string;
  document: DocumentResponse;
  onSuccess: () => void;
}) => {
  const setPendingChanges = useSetAtom(interpretationHasChangesAtom);
  return (
    <Grid className="bx--no-gutter" fullWidth condensed>
      <Row>
        <Column
          md={10}
          lg={10}
          xlg={8}
          className={style.formFrame}
          data-floating-menu-container
        >
          <InterpretationForm
            document={document}
            interpretations={[]}
            role={{ type: 'update', href: interpretationHref }}
            refetchInterpretations={() => void 0}
            onSuccess={onSuccess}
            setPendingChanges={setPendingChanges}
            documentHeaderAttributes={
              <DocumentHeaderAttributes document={document} />
            }
          />
        </Column>
        <Column md={10} lg={6} xlg={8} className={style.documentFrame}>
          {document.resources.image ? (
            <div className={style.sticky}>
              <iframe src={document.resources.image.href} />
            </div>
          ) : (
            <Tile>
              <EmptyState
                icon={DocumentSvg}
                headerText="No Document Available"
                helperText="Currently Insights doesn’t have any document available."
              />
            </Tile>
          )}
        </Column>
      </Row>
    </Grid>
  );
};

export { EditInterpretation };
