import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import style from './section.module.scss';

interface Props {
  header?: ReactNode;
  className?: string;
  headerActions?: ReactNode;
}

const Section: FC<Props> = ({ header, children, className, headerActions }) => {
  return (
    <section className={classNames(style.section, className)}>
      {header && (
        <header>
          <div className={style.content}>
            <h3 className={style.sectionHeading}>{header}</h3>
            {headerActions && (
              <div className={style.actions}>{headerActions}</div>
            )}
          </div>
        </header>
      )}
      {children}
    </section>
  );
};

export { Section };
