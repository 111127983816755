import {
  createResourceCacheMapAtom,
  createSingleCacheEntryAtom,
} from 'lib/atoms';
import { useMemo } from 'react';
import {
  TitleCalculationDetailsResponse,
  TitleCalculationResponse,
} from 'types/api-responses';

const lastCalculationCacheAtom =
  createResourceCacheMapAtom<TitleCalculationResponse>();
const useLastCalculationAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(lastCalculationCacheAtom)(href),
    [href]
  );

const interpretationCalculationDetailsAtom =
  createResourceCacheMapAtom<TitleCalculationDetailsResponse[]>();
const useCreateInterpretationCalculationDetailsAtom = (href: string) =>
  useMemo(
    () =>
      createSingleCacheEntryAtom(interpretationCalculationDetailsAtom)(href),
    [href]
  );

export {
  useLastCalculationAtom,
  useCreateInterpretationCalculationDetailsAtom,
};
