import { RainDrop24 } from '@carbon/icons-react';
import classNames from 'classnames';

import style from './icons.module.scss';

const Liquid = () => {
  return <RainDrop24 className={classNames(style.icon, style.liquid)} />;
};

export default Liquid;
