import style from './column-actions.module.scss';

interface Props {
  handleResize: (e: React.MouseEvent) => void;
}

export const MIN_WIDTH = 106;

export function ColumnActions({ handleResize }: Props) {
  return (
    <div className={style.tableActions}>
      <div
        className={style.resizeIcon}
        onMouseDown={(e) => handleResize(e)}
      ></div>
    </div>
  );
}
