import { getMapDefaultConfig } from 'components/map/atoms';
import { WorkspaceMap } from 'components/workspace-map';
import { atomWithStorage } from 'jotai/utils';
import { LocationResponse } from 'types/api-responses';

const mapConfigAtom = atomWithStorage(
  'insights-workspace-runsheet-interpretation-view-map-config',
  getMapDefaultConfig()
);

const Map = ({
  referenceLocationHref,
  locations,
}: {
  referenceLocationHref: string;
  locations: LocationResponse[];
}) => {
  if (!locations.length) return null;
  return (
    <WorkspaceMap
      referenceLocationHref={referenceLocationHref}
      mapConfigAtom={mapConfigAtom}
      locations={locations}
    />
  );
};

export { Map };
