import {
  createMutationAtom,
  createResourceFormAtom,
  Form,
  FormRowStatus,
  getValue,
  hasChangedFields,
  initializeForm,
  MutatePayload,
} from 'atoms/create-resource-atom';
import { Atom, atom, Getter } from 'jotai';
import request from 'lib/request';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';
import * as ApplicableAreaFormUtils from './applicable-area/utils';
import * as CapacityFormUtils from './capacity/utils';
import * as TransactionFormUtils from './transaction/utils';
import * as TextEntityFormUtils from './text-entity/utils';
import * as CurativeRequirementFormUtils from './curative-requirement/utils';
import * as DocumentReferenceFormUtils from './document-reference/utils';

import { FormErrors, FormState } from './types';
import { TransactionFormAtoms } from './transaction/utils';
import { loadable } from 'jotai/utils';

const interpretationHrefAtom = atom<string | undefined>(undefined);

const refetchAtom = atom(0);
const refetchInterpretationAtom = atom(
  (get) => get(refetchAtom),
  (get, set) => set(refetchAtom, (c) => c + 1)
);

const interpretationAtomBase = atom<
  Promise<DocumentInterpretationResponse | { error: string } | undefined>
>(async (get) => {
  const href = get(interpretationHrefAtom);
  get(refetchInterpretationAtom);
  return href ? await request.get(href) : undefined;
});

const interpretationAtom = loadable(interpretationAtomBase);

const documentAtom = atom<DocumentResponse | undefined>(undefined);
const interpretationsAtom = atom<
  | (DocumentInterpretationResponse | Partial<DocumentInterpretationResponse>)[]
  | undefined
>(undefined);

export const interpretationDuplicateAtom =
  atom<DocumentInterpretationResponse | null>(null);

const formAtoms = atom((get) => {
  const dup = get(interpretationDuplicateAtom);
  const interpretationReq = get(interpretationAtom);
  const data = dup
    ? dup
    : interpretationReq.state === 'hasData' &&
      interpretationReq.data &&
      !('error' in interpretationReq.data)
    ? interpretationReq.data
    : undefined;
  const state = data && !dup ? 'stable' : dup ? 'duplicated' : 'updated';
  const initialForm = initializeForm<FormState>(
    {
      assumption: !!data?.assumption,
      legalDescription: data?.legalDescription || '',
      notes: data?.notes || '',
      pageReferences: data?.pageReferences || '',
      supersedingDate: data?.supersedingDate || '',
      locationReferences: ApplicableAreaFormUtils.initForm(
        data?.locationReferences,
        state
      ),
      capacities: CapacityFormUtils.initForm(
        data?.interpretationCapacities,
        state
      ),
      transactions: TransactionFormUtils.initForm(data?.transactions, state),
      grantors: TextEntityFormUtils.initForm(
        {
          type: 'grantor',
          entities: data?.grantorReferences || [],
        },
        state
      ),
      grantees: TextEntityFormUtils.initForm(
        {
          type: 'grantee',
          entities: data?.granteeReferences || [],
        },
        state
      ),
      curativeRequirements: CurativeRequirementFormUtils.initForm(
        data?.curativeRequirements || [],
        state
      ),
      documentReferences: DocumentReferenceFormUtils.initForm(
        data?.documentReferences || [],
        state
      ),
      reviewAttributesId: data?.reviewAttributes?.id,
      transactionsReviewed: !!data?.reviewAttributes?.transactionsReviewed,
      applicableAreasReviewed:
        !!data?.reviewAttributes?.applicableAreasReviewed,
      documentReferencesReviewed:
        !!data?.reviewAttributes?.documentReferencesReviewed,
      capacitiesReviewed: !!data?.reviewAttributes?.capacitiesReviewed,
      curativeRequirementsReviewed:
        !!data?.reviewAttributes?.curativeRequirementsReviewed,
      additionalInformationReviewed:
        !!data?.reviewAttributes?.additionalInformationReviewed,
      grantorsReviewed: !!data?.reviewAttributes?.grantorsReviewed,
      granteesReviewed: !!data?.reviewAttributes?.granteesReviewed,
    },
    state
  );

  return createResourceFormAtom<FormState, FormErrors>(initialForm);
});

const transactionsFieldAtom = atom((get) => {
  return get(get(formAtoms).formAtom).transactions;
});
const notesFieldAtom = atom((get) => {
  return get(get(formAtoms).formAtom).notes;
});
const pageReferencesFieldAtom = atom((get) => {
  return get(get(formAtoms).formAtom).pageReferences;
});
const supersedingDateFieldAtom = atom((get) => {
  return get(get(formAtoms).formAtom).supersedingDate;
});

const getRowAtoms = (
  {
    row,
    status,
  }: {
    row: { formAtom: Atom<Form<unknown>> };
    status: Atom<FormRowStatus>;
  },
  get: Getter
) => {
  return { row: get(row.formAtom), status: get(status) };
};

const transactionsChanged = (field: TransactionFormAtoms[], get: Getter) =>
  getAllTransactionAtoms(field, get).some(
    (el) => el && (hasChangedFields(el.row) || el.status === 'trash')
  );

const transactionsUpdatedAtom = atom((get) => {
  const field = get(transactionsFieldAtom);
  const supersedingDate = get(supersedingDateFieldAtom);
  const supersedingDateChanged = supersedingDate.state === 'updated';
  return transactionsChanged(field.value, get) || supersedingDateChanged
    ? new Date().getTime()
    : undefined;
});

const getAllTransactionAtoms = (
  transactionAtoms: TransactionFormAtoms[],
  get: Getter
) => {
  const allAtoms = transactionAtoms.map((atoms) => {
    if (atoms.type === 'Transaction::ConveyanceTransaction') {
      const topLevelAtoms = get(atoms.row.formAtom);
      return [
        { row: topLevelAtoms, status: get(atoms.status) },
        ...topLevelAtoms.grantee.value.map((el) => getRowAtoms(el, get)),
        ...topLevelAtoms.grantor.value.map((el) => getRowAtoms(el, get)),
        ...topLevelAtoms.documentReference.value.map((el) =>
          getRowAtoms(el, get)
        ),
        ...get(topLevelAtoms.wellReference.value).map((el) =>
          getRowAtoms(el, get)
        ),
      ];
    }

    if (atoms.type === 'Transaction::OverridingRoyalty') {
      const topLevelAtoms = get(atoms.row.formAtom);
      return [
        { row: topLevelAtoms, status: get(atoms.status) },
        ...topLevelAtoms.grantee.value.map((el) => getRowAtoms(el, get)),
        ...topLevelAtoms.grantor.value.map((el) => getRowAtoms(el, get)),
        ...topLevelAtoms.documentReference.value.map((el) =>
          getRowAtoms(el, get)
        ),
        ...get(topLevelAtoms.wellReference.value).map((el) =>
          getRowAtoms(el, get)
        ),
      ];
    }

    if (atoms.type === 'Transaction::SurfaceLeaseTransaction') {
      const topLevelAtoms = get(atoms.row.formAtom);
      return [
        { row: topLevelAtoms, status: get(atoms.status) },
        ...topLevelAtoms.lessee.value.map((el) => getRowAtoms(el, get)),
        ...topLevelAtoms.lessor.value.map((el) => getRowAtoms(el, get)),
      ];
    }

    if (atoms.type === 'Transaction::MineralLeaseTransaction') {
      const topLevelAtoms = get(atoms.row.formAtom);
      const leaseExtensionForm = get(
        topLevelAtoms.leaseManagement.value.formAtom
      );
      return [
        { row: topLevelAtoms, status: get(atoms.status) },
        { row: leaseExtensionForm, status: get(atoms.status) },
        ...topLevelAtoms.lessee.value.map((el) => getRowAtoms(el, get)),
        ...topLevelAtoms.lessor.value.map((el) => getRowAtoms(el, get)),
        ...get(
          get(topLevelAtoms.leaseManagement.value.formAtom).locationReferences
            .value
        ).map((el) => getRowAtoms(el, get)),
      ];
    }
  });

  return allAtoms.flat();
};

const additionalInformationUpdatedAtom = atom((get) => {
  const notes = get(notesFieldAtom);
  const pageReferences = get(pageReferencesFieldAtom);

  const changed =
    notes.state === 'updated' || pageReferences.state === 'updated';
  return changed ? new Date().getTime() : undefined;
});

const createUpdateTrackerAtom = (
  formField:
    | 'capacities'
    | 'curativeRequirements'
    | 'locationReferences'
    | 'documentReferences'
    | 'grantees'
    | 'grantors'
) => {
  const fieldAtom = atom((get) => {
    return get(get(formAtoms).formAtom)[formField];
  });
  return atom((get) => {
    const field = get(fieldAtom);
    const rows = field.value.map((el) =>
      get(el.row.formAtom as Atom<Form<unknown>>)
    );
    const changed = rows.some((el) => hasChangedFields(el));
    return changed ? new Date().getTime() : undefined;
  });
};

const nestedFormFields = [
  'capacities',
  'curativeRequirements',
  'documentReferences',
  'grantees',
  'grantors',
  'locationReferences',
] as const;

const pendingChangesAtom = atom((get) => {
  const form = get(get(formAtoms).formAtom);
  const rootFieldChanges = hasChangedFields(form);

  if (rootFieldChanges) return true;

  return [
    nestedFormFields.some((el) =>
      form[el].value.some((el) =>
        hasChangedFields(get(el.row.formAtom as Atom<Form<unknown>>))
      )
    ),
    transactionsChanged(form.transactions.value, get),
  ].some((el) => el);
});

const getUpdatedReviewAttributes = (fields: FormState) => {
  const reviewAttributes = {
    transactionsReviewed: getValue(fields.transactionsReviewed),
    applicableAreasReviewed: getValue(fields.applicableAreasReviewed),
    documentReferencesReviewed: getValue(fields.documentReferencesReviewed),
    capacitiesReviewed: getValue(fields.capacitiesReviewed),
    curativeRequirementsReviewed: getValue(fields.curativeRequirementsReviewed),
    additionalInformationReviewed: getValue(
      fields.additionalInformationReviewed
    ),
    grantorsReviewed: getValue(fields.grantorsReviewed),
    granteesReviewed: getValue(fields.granteesReviewed),
  };

  return Object.keys(reviewAttributes).filter(
    (key) =>
      typeof reviewAttributes[key as keyof typeof reviewAttributes] !==
      'undefined'
  ).length === 0
    ? null
    : { ...reviewAttributes, id: fields.reviewAttributesId.value };
};

const mutationAtom = createMutationAtom<
  DocumentInterpretationResponse,
  'documentInterpretation'
>();

type InterpretationPayload = Omit<
  MutatePayload<DocumentInterpretationResponse, 'documentInterpretation'>,
  'data'
>;

const saveAtom = atom(
  (get) => get(mutationAtom),
  (get, set, payload: InterpretationPayload) => {
    const parentForm = get(formAtoms);
    const fields = get(parentForm.formAtom);

    let errorCount = 0;

    // Validate applicable areas
    fields.locationReferences.value.forEach((atoms) => {
      errorCount += Number(ApplicableAreaFormUtils.validate(atoms, get, set));
    });

    // Validate capacities
    fields.capacities.value.forEach((atoms) => {
      errorCount += Number(CapacityFormUtils.validate(atoms, get, set));
    });

    // Validate transactions
    fields.transactions.value.forEach((atoms) => {
      errorCount += Number(TransactionFormUtils.validate(atoms, get, set));
    });

    // Validate Grantors
    fields.grantors.value.forEach((atoms) => {
      errorCount += Number(TextEntityFormUtils.validate(atoms, get, set));
    });

    // Validate Grantees
    fields.grantees.value.forEach((atoms) => {
      errorCount += Number(TextEntityFormUtils.validate(atoms, get, set));
    });

    // Validate Curative Requirements
    fields.curativeRequirements.value.forEach((atoms) => {
      errorCount += Number(
        CurativeRequirementFormUtils.validate(atoms, get, set)
      );
    });
    if (!errorCount) {
      set(mutationAtom, {
        ...payload,
        data: {
          legalDescription: getValue(fields.legalDescription),
          notes: getValue(fields.notes),
          pageReferences: getValue(fields.pageReferences),
          assumption: getValue(fields.assumption),
          supersedingDate: getValue(fields.supersedingDate),
          locationReferencesAttributes: fields.locationReferences.value.flatMap(
            (atoms) => ApplicableAreaFormUtils.getPayload(atoms, get)
          ),
          interpretationCapacitiesAttributes: fields.capacities.value.flatMap(
            (atoms) => {
              const payload = CapacityFormUtils.getPayload(atoms, get);
              return payload ?? [];
            }
          ),
          transactionsAttributes: fields.transactions.value.flatMap((atoms) => {
            const payload = TransactionFormUtils.getPayload(atoms, get);
            return payload ?? [];
          }),
          grantorReferencesAttributes: fields.grantors.value.flatMap(
            (atoms) => {
              const payload = TextEntityFormUtils.getPayload(
                atoms,
                get,
                'grantor'
              );
              return payload ?? [];
            }
          ),
          granteeReferencesAttributes: fields.grantees.value.flatMap(
            (atoms) => {
              const payload = TextEntityFormUtils.getPayload(
                atoms,
                get,
                'grantee'
              );
              return payload ?? [];
            }
          ),
          curativeRequirementsAttributes:
            fields.curativeRequirements.value.flatMap((atoms) => {
              const payload = CurativeRequirementFormUtils.getPayload(
                atoms,
                get
              );
              return payload ?? [];
            }),
          documentReferencesAttributes: fields.documentReferences.value.flatMap(
            (atoms) => {
              const payload = DocumentReferenceFormUtils.getPayload(atoms, get);
              return payload ?? [];
            }
          ),
          interpretationReviewAttributes: getUpdatedReviewAttributes(fields),
        },
      });
    }
  }
);

export {
  additionalInformationUpdatedAtom,
  createUpdateTrackerAtom,
  documentAtom,
  formAtoms,
  interpretationAtom,
  interpretationHrefAtom,
  interpretationsAtom,
  pendingChangesAtom,
  refetchInterpretationAtom,
  saveAtom,
  transactionsUpdatedAtom,
  mutationAtom,
};
