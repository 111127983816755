import { ErrorState } from 'atoms/form-atoms';
import { Button, Form, Tab, Tabs, Tile } from 'carbon-components-react';
import { Buttons } from 'components/buttons';
import { FormErrorList } from 'components/form-error-list';
import { atom, Atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { AdditionalInfo } from './additional-info';
import {
  duplicateDocumentAtom,
  fileAtom,
  formAtom,
  formErrorsAtom,
  formIsInvalidAtom,
  locationErrorAtom,
  nonCourthouseFormAtom,
  nonCourthouseFormErrorsAtom,
  nonCourthouseFormIsInvalidAtom,
  runMutationAtom,
  serverErrorsAtom,
} from './atoms';
import { County } from './county';
import { Dates } from './dates';
import { Details } from './details';
import { DuplicateWarning } from './duplicate-warning';
import { PartyList } from './entity';
import style from './form.module.scss';
import { NonCourthouseDates } from './noncouthouse-dates';
import { SurveyAreaList } from './survey-area';
import { ProvideRootResource } from 'components/hydrate-atoms';

const CorthouseForm = () => {
  return (
    <Form title="" className={style.form} data-testid="courthouse-form">
      <County />
      <PartyList
        title="Grantor(s)"
        addLabel="Add Grantor"
        field="grantorsAttributes"
      />
      <PartyList
        title="Grantee(s)"
        addLabel="Add Grantee"
        field="granteesAttributes"
      />
      <SurveyAreaList formAtom={formAtom} />
      <Details />
      <Dates />
      <AdditionalInfo formAtom={formAtom} />
      <FormErrorList errorsAtom={formErrorsAtom} />
      <FormErrorList
        testId="server-error-section"
        errorsAtom={serverErrorsAtom}
      />
      <DuplicateWarning />
      <SaveButton
        isInvalidAtom={formIsInvalidAtom}
        locationErrorAtom={locationErrorAtom}
        type="courthouse"
      />
    </Form>
  );
};

const NonCorthouseForm = () => {
  return (
    <Form title="" className={style.form} data-testid="noncourthouse-form">
      <SurveyAreaList formAtom={nonCourthouseFormAtom} required />
      <NonCourthouseDates />
      <AdditionalInfo formAtom={nonCourthouseFormAtom} />
      <FormErrorList errorsAtom={nonCourthouseFormErrorsAtom} />
      <DuplicateWarning />
      <SaveButton
        isInvalidAtom={nonCourthouseFormIsInvalidAtom}
        locationErrorAtom={atom({ isValid: true })}
        type="noncourthouse"
      />
    </Form>
  );
};

const SaveButton = ({
  type,
  isInvalidAtom,
  locationErrorAtom,
}: {
  type: 'courthouse' | 'noncourthouse';
  isInvalidAtom: Atom<boolean>;
  locationErrorAtom: Atom<ErrorState>;
}) => {
  const history = useHistory();
  const [mutation, runMutation] = useAtom(runMutationAtom);
  const upload = useAtomValue(fileAtom);
  const formIsInvalid = useAtomValue(isInvalidAtom);
  const locationError = useAtomValue(locationErrorAtom);
  const setDuplicateError = useSetAtom(duplicateDocumentAtom);
  const setServerErrors = useSetAtom(serverErrorsAtom);

  return (
    <Buttons>
      <Button
        onClick={() => {
          runMutation({
            type,
            onSuccess: (document) => {
              history.push(document.href);
            },
            onError: (error) => {
              if (error) {
                const { errors } = error;
                if (errors.duplicateDocument) {
                  setDuplicateError(true);
                }
                setServerErrors(
                  [errors.instrument, errors.page, errors.volume].filter(
                    (el) => el
                  ) as string[]
                );
              } else {
                setServerErrors(['Unknown error. Please try again.']);
              }
            },
          });
        }}
        disabled={
          mutation.loading ||
          upload === 'uploading' ||
          formIsInvalid ||
          !locationError.isValid
        }
      >
        Create Document
      </Button>
    </Buttons>
  );
};

const DocumentForm = () => {
  return (
    <ProvideRootResource>
      <Tile className={style.tile}>
        <Tabs className={style.variableWidth}>
          <Tab label="Courthouse Document">
            <CorthouseForm />
          </Tab>
          <Tab label="Non-Courthouse Document">
            <NonCorthouseForm />
          </Tab>
        </Tabs>
      </Tile>
    </ProvideRootResource>
  );
};

export { DocumentForm };
