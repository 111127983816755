import { ChevronUp16, ChevronDown16 } from '@carbon/icons-react';
import { DocumentAttributes } from 'components/document-attributes';
import { useState } from 'react';
import { DocumentResponse } from 'types/api-responses';
import { Button } from 'carbon-components-react';
import style from './document-header-attributes.module.scss';

const DocumentHeaderAttributes = ({
  document,
}: {
  document: DocumentResponse;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={style.documentAttributes}>
      <div className={style.buttonArea}>
        <Button
          kind="ghost"
          size="sm"
          onClick={() => setExpanded(!expanded)}
          renderIcon={expanded ? ChevronUp16 : ChevronDown16}
        >
          {expanded ? 'Close' : 'Open'} Document Attributes
        </Button>
      </div>
      {expanded && (
        <div className={style.documentaArea}>
          <DocumentAttributes document={document} />
        </div>
      )}
    </div>
  );
};

export { DocumentHeaderAttributes };
