import style from './date-filter.module.scss';
import {
  DatePicker,
  DatePickerInput,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';
import { useAtom } from 'jotai';
import { defaultPayload } from '../../utils';
import { searchAtom } from '../../atoms';
import moment from 'moment';
import { DateTarget } from 'components/runsheet/common/types';

const DateFilter = () => {
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const currentFilters = searchResults.currentPayload;

  const setDateValues = ({
    startDate,
    endDate,
  }: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => {
    setSearchPayload((current) => ({
      ...defaultPayload,
      sortDirection: current?.sortDirection || 'DESC',
      sortAttribute: current?.sortAttribute || 'updated_at',
      pageSize: current?.pageSize || 10,
      startDate: startDate?.toISOString().split('T')[0] || '',
      endDate: endDate?.toISOString().split('T')[0] || '',
      dateTarget: current?.dateTarget || 'none',
      text: current?.text || '',
    }));
  };

  const setTargetDate = (target: DateTarget) => {
    setSearchPayload((current) => ({
      ...defaultPayload,
      sortDirection: current?.sortDirection || 'DESC',
      sortAttribute: current?.sortAttribute || 'updated_at',
      pageSize: current?.pageSize || 10,
      startDate: current?.startDate || '',
      endDate: current?.endDate || '',
      dateTarget: target,
      text: current?.text || '',
    }));
  };
  return (
    <div className={style.dateFilterContainer}>
      <DatePicker
        light
        id="date-picker"
        datePickerType="range"
        dateFormat="m/d/Y"
        value={[
          currentFilters?.startDate
            ? moment(currentFilters?.startDate, 'YYYY-MM-DD').toDate()
            : '',
          currentFilters?.endDate
            ? moment(currentFilters?.endDate, 'YYYY-MM-DD').toDate()
            : '',
        ]}
        onChange={([startDate, endDate]) => {
          if (startDate && endDate) {
            setDateValues({ startDate, endDate });
          }
        }}
      >
        <DatePickerInput
          autoComplete="off"
          labelText=""
          id="interpretation-start-date"
          placeholder="mm/dd/yyyy"
          disabled={currentFilters?.dateTarget === 'none'}
        />
        <DatePickerInput
          autoComplete="off"
          labelText=""
          id="interpretation-end-date"
          placeholder="mm/dd/yyyy"
          disabled={currentFilters?.dateTarget === 'none'}
        />
      </DatePicker>
      <div className={style.targetDateChecks}>
        <RadioButtonGroup
          name="target-date-selection"
          valueSelected={currentFilters?.dateTarget || 'none'}
          onChange={(target: DateTarget) => {
            setTargetDate(target);
          }}
        >
          <RadioButton labelText="None" value="none" />
          <RadioButton labelText="Created Date" value="created_at" />
          <RadioButton labelText="Updated Date" value="updated_at" />
        </RadioButtonGroup>
      </div>
    </div>
  );
};

export { DateFilter };
