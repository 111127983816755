import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from 'carbon-components-react';
import { sortBy } from 'lodash';
import style from '../page.module.scss';
import { TitleChainCalculatorYamlResponse } from 'types/api-responses';

export function Overrides({
  overrides,
}: {
  overrides: TitleChainCalculatorYamlResponse['mineralLeaseOverrides'];
}) {
  return overrides.length ? (
    <TableContainer className={style.table}>
      <Table size="xs" useStaticWidth={true}>
        <TableHead>
          <TableRow>
            <TableHeader>From</TableHeader>
            <TableHeader>To</TableHeader>
            <TableHeader>Lease</TableHeader>
            <TableHeader>Overriding Interest</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy(overrides, ['from', 'to', 'lease']).map((entry, index) => (
            <TableRow key={index}>
              <TableCell>{entry.from}</TableCell>
              <TableCell>{entry.to}</TableCell>
              <TableCell>{entry.lease}</TableCell>
              <TableCell>{entry.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
