import classNames from 'classnames';
import { IconProps } from './types';
import { Undo32 } from '@carbon/icons-react';

import style from './icons.module.scss';

const Undo = ({ className }: IconProps) => {
  return (
    <div className={classNames(style.background, style.undo, className)}>
      <Undo32 className={classNames(style.icon, style.undo)} />
    </div>
  );
};

export default Undo;
