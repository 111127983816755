import classNames from 'classnames';
import { IconProps } from './types';
import { TrashCan32 } from '@carbon/icons-react';

import style from './icons.module.scss';

const TrashCan = ({ className }: IconProps) => {
  return (
    <div className={classNames(style.background, style.trashCan, className)}>
      <TrashCan32 className={classNames(style.icon, style.trashCan)} />
    </div>
  );
};

export default TrashCan;
