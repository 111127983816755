import classNames from 'classnames';
import { IconProps } from './types';
import { Bullhorn32 } from '@carbon/icons-react';

import style from './icons.module.scss';

const Bullhorn = ({ className }: IconProps) => {
  return (
    <div className={classNames(style.background, style.bullhorn, className)}>
      <Bullhorn32 className={classNames(style.icon, style.bullhorn)} />
    </div>
  );
};

export default Bullhorn;
