import { atom } from 'jotai';
import { ScenarioAssumptionsResponse } from 'types/scenarios-api/responses';
import { scenarioForm } from './utils';
import { AtomizedForm, AtomizedRowForm, getFormValues } from 'atoms/form-atoms';
import {
  CreateResourceAtom,
  createMutationAtom,
} from 'atoms/create-resource-atom';
import { BasinTierResponse, ScenariosResponse } from 'types/api-responses';
import { useMemo } from 'react';
import { CacheMapEntry, createResourceAtom } from 'lib/atoms';
import { ScenariosForm } from './types';
import { removeEmptyKeys } from 'utils/forms';
import { ApiPayloads } from 'types/api-payloads';
import { ErrorsType } from 'lib/types';
import { DevAreasInclusionState } from './scenario-builder';

const useScenarioAssumptionsAtom = (href: string | undefined) =>
  useMemo(() => createResourceAtom<ScenarioAssumptionsResponse>(href), [href]);

const useScenarioFormAtom = (
  assumptionsResponse: ScenarioAssumptionsResponse,
  type: 'new' | 'edit'
) =>
  useMemo(
    () =>
      atom(() => {
        if (assumptionsResponse && type === 'edit') {
          return scenarioForm(assumptionsResponse);
        }

        const newScenarioObject = {
          ...assumptionsResponse,
          id: undefined,
          name: undefined,
          tiers: [
            ...assumptionsResponse.tiers.map((el) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = el;
              return rest;
            }),
          ],
          developmentAreas: [
            ...assumptionsResponse.developmentAreas.map((el) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = el;
              return rest;
            }),
          ],
        };

        return scenarioForm(newScenarioObject);
      }),
    [assumptionsResponse, type]
  );

const useBasinTierListAtom = (href: string | undefined) =>
  useMemo(() => createResourceAtom<BasinTierResponse[]>(href), [href]);

const useUnusedTiersListAtom = (
  allTiers: CacheMapEntry<BasinTierResponse[]>,
  tiersForm: AtomizedRowForm<ScenariosForm['tiersAttributes'][0]>[]
) =>
  useMemo(
    () =>
      atom((get) => {
        const usedTiers = tiersForm;

        return (
          allTiers.data && !('error' in allTiers.data) ? allTiers.data : []
        ).filter((el) => {
          return (
            usedTiers.findIndex(
              (used) =>
                get(used.basin).value === el.basin &&
                get(used.tier).value === el.tier
            ) === -1
          );
        });
      }),
    [allTiers.data, tiersForm]
  );

const selectedAssumptionsFormAtom = atom<
  { basin: string; tier: string } | 'global' | 'developmentAreaExceptions'
>('global');
selectedAssumptionsFormAtom.onMount = (set) => set('global');

const useSortedTiersAtom = (
  formAtom: AtomizedForm<ScenariosForm>['tiersAttributes']
) => {
  return useMemo(
    () =>
      atom((get) => {
        const list = get(formAtom).value;
        return list.sort((a, b) => {
          const basinA = get(a.basin).value || '';
          const basinB = get(b.basin).value || '';

          if (basinA < basinB) return -1;
          if (basinA > basinB) return 1;

          const tierA = get(a.tier).value;
          const tierB = get(b.tier).value;

          if (!tierA || !tierB) return 0;

          return (
            parseInt(tierA.match(/\d+/)?.[0] || '0') -
            parseInt(tierB.match(/\d+/)?.[0] || '0')
          );
        });
      }),
    [formAtom]
  );
};

export type TierFormAtom = ReturnType<typeof useTierFormAtom>;
export type SortedTierAtom = ReturnType<typeof useSortedTiersAtom>;
const useTierFormAtom = (sortedTiersAtom: SortedTierAtom) =>
  useMemo(
    () =>
      atom((get) => {
        const selectedForm = get(selectedAssumptionsFormAtom);
        if (typeof selectedForm === 'string') return null;

        const tiers = get(sortedTiersAtom);
        const index = tiers.findIndex(
          (el) =>
            get(el.basin).value === selectedForm.basin &&
            get(el.tier).value === selectedForm.tier
        );
        return tiers[index];
      }),
    [sortedTiersAtom]
  );

const useSelectedFormNameAtom = (tierFormAtom: TierFormAtom) =>
  useMemo(
    () =>
      atom((get) => {
        const selectedForm = get(selectedAssumptionsFormAtom);
        if (selectedForm === 'developmentAreaExceptions')
          return 'Development Area Exceptions';

        if (selectedForm === 'global') return 'Global';

        const basinTier = get(tierFormAtom);
        if (!basinTier) return '';

        return `${get(basinTier.basin).value} ${get(basinTier.tier).value}`;
      }),
    [tierFormAtom]
  );

const useBasinTierNameListAtom = (
  formAtom: AtomizedForm<ScenariosForm>['tiersAttributes']
) => {
  const sortedBasinTier = useSortedTiersAtom(formAtom);
  return useMemo(
    () =>
      atom((get) => {
        return get(sortedBasinTier).map((el) => {
          return `${get(el.basin).value} ${get(el.tier).value}`;
        });
      }),
    [sortedBasinTier]
  );
};

const runMutationAtomBase = createMutationAtom<
  ScenariosResponse,
  'scenarios'
>();

const runMutationAtom = atom<
  CreateResourceAtom<ScenariosResponse, ErrorsType>,
  [
    {
      type: 'POST' | 'PATCH';
      href: string;
      name?: string;
      basinTierSelections?: Set<string>;
      devAreasInclusion?: DevAreasInclusionState;
      onSuccess: (data: ScenariosResponse) => void;
      onError: (errors?: ErrorsType) => void;
      form: AtomizedForm<ScenariosForm>;
    }
  ],
  void
>(
  (get) => get(runMutationAtomBase),
  (
    get,
    set,
    {
      type,
      href,
      onSuccess,
      onError,
      form,
      name,
      basinTierSelections,
      devAreasInclusion,
    }
  ) => {
    const formValues = getFormValues(
      form,
      get,
      type === 'POST' ? 'all-values' : 'dirty-values'
    ) as ApiPayloads['scenarios']['payload'];

    if (name) {
      formValues.name = name;
      formValues.tiersAttributes = [
        ...(formValues.tiersAttributes || []).map((el) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...rest } = el;
          return rest;
        }),
      ];
      formValues.developmentAreasAttributes =
        devAreasInclusion !== 'EXCLUDED'
          ? [
              ...(formValues.developmentAreasAttributes || []).map((el) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...rest } = el;
                return rest;
              }),
            ]
          : [];
    }

    if (basinTierSelections) {
      formValues.tiersAttributes = formValues.tiersAttributes.filter((el) =>
        basinTierSelections.has(`${el.basin} ${el.tier}`)
      );
    }

    set(runMutationAtomBase, {
      type,
      url: href,
      data:
        type === 'PATCH'
          ? (removeEmptyKeys(formValues, [
              'wellsToExclude',
            ]) as ApiPayloads['scenarios']['payload'])
          : formValues,
      onSuccess,
      onError,
    });
  }
);

export {
  runMutationAtom,
  selectedAssumptionsFormAtom,
  useBasinTierListAtom,
  useScenarioAssumptionsAtom,
  useScenarioFormAtom,
  useSelectedFormNameAtom,
  useSortedTiersAtom,
  useTierFormAtom,
  useUnusedTiersListAtom,
  useBasinTierNameListAtom,
};
