import searchAtomPair from 'atoms/search-atom';
import { atom } from 'jotai';

import type { WellsResponse } from 'types/api-responses';
import { AddWellFn, WellFacets, WellSearchParams } from './types';

const { searchAtom, resetSearchDataAtom } = searchAtomPair<
  WellsResponse,
  WellSearchParams,
  WellFacets
>('wells');

const addWellFnAtom = atom<{ fn: AddWellFn | undefined }>({ fn: undefined });
const usedWellsAtom = atom<number[]>([]);

export { searchAtom, resetSearchDataAtom, addWellFnAtom, usedWellsAtom };
