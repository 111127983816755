import { ScenariosForm } from '../common/types';
import { AtomField, AtomizedRowForm } from 'atoms/form-atoms';
import { tiersRow } from '../common/utils';
import { FormRowStatus } from 'atoms/create-resource-atom';
import { TrashButton } from 'components/trash-button';
import { SubtractAlt16, TrashCan16 } from '@carbon/icons-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useCallback, useState } from 'react';
import { Button, ComboBox, Link, Tag } from 'carbon-components-react';
import {
  selectedAssumptionsFormAtom,
  useBasinTierListAtom,
  useSortedTiersAtom,
  useUnusedTiersListAtom,
} from './atoms';

import style from './scenario-builder.module.scss';
import { BasinTierResponse } from 'types/api-responses';
import { resourcesAtom } from 'atoms/root';
import { ScenarioAssumptionsResponse } from 'types/scenarios-api/responses';

const BasinTierLink = ({
  basinAtom,
  tierAtom,
  rowStatusAtom,
  onDelete,
  index,
}: {
  basinAtom: AtomField<string>;
  tierAtom: AtomField<string>;
  rowStatusAtom: AtomField<FormRowStatus>;
  onDelete: (index: number) => void;
  index: number;
}) => {
  const basin = useAtomValue(basinAtom);
  const tier = useAtomValue(tierAtom);
  const [rowStatus, setRowStatus] = useAtom(rowStatusAtom);
  const [selected, setSelectedTierForm] = useAtom(selectedAssumptionsFormAtom);
  const status = rowStatus.value;
  const isSelected =
    typeof selected === 'string'
      ? false
      : selected.basin === basin.value && selected.tier === tier.value;
  return (
    <li className={isSelected ? style.subNavActive : style.subNavLinks}>
      <Link
        onClick={() =>
          basin.value &&
          tier.value &&
          setSelectedTierForm({ basin: basin.value, tier: tier.value })
        }
      >
        {basin.value} {tier.value}{' '}
        {status === 'new' && (
          <Tag type="blue" size="sm" className={style.newTag}>
            New
          </Tag>
        )}
      </Link>

      <TrashButton
        size="field"
        description="Remove"
        iconDescription={status === 'trash' ? 'Undo remove row' : 'Remove row'}
        kind={status === 'trash' ? 'danger' : 'ghost'}
        renderIcon={status === 'new' ? SubtractAlt16 : TrashCan16}
        tooltipAlignment="end"
        tooltipPosition="left"
        onClick={() => {
          if (status === 'saved') {
            setRowStatus('trash');
          } else if (status === 'trash') {
            setRowStatus('saved');
          } else {
            onDelete(index);
          }
        }}
      />
    </li>
  );
};

const Tiers = ({
  globalAssumptions,
  formAtom,
}: {
  formAtom: AtomField<AtomizedRowForm<ScenariosForm['tiersAttributes'][0]>[]>;
  globalAssumptions: ScenarioAssumptionsResponse['globalAssumptions'];
}) => {
  const [tiers, setTiers] = useAtom(formAtom);
  const [selectedBasinTier, setSelectedBasinTier] = useState<
    BasinTierResponse | undefined
  >(undefined);
  const resources = useAtomValue(resourcesAtom);
  const tiersListAtom = useBasinTierListAtom(resources?.basinTiers.href);
  const allTiers = useAtomValue(tiersListAtom);
  const unusedTiersAtom = useUnusedTiersListAtom(allTiers, tiers.value);
  const unusedTiers = useAtomValue(unusedTiersAtom);
  const sortedBasinTiersAtom = useSortedTiersAtom(formAtom);
  const sortedBasinTiers = useAtomValue(sortedBasinTiersAtom);

  const setSelectedTierForm = useSetAtom(selectedAssumptionsFormAtom);

  const onDelete = useCallback(
    (index: number) => {
      setTiers((arr) => [...arr.slice(0, index), ...arr.slice(index + 1)]);
      setSelectedTierForm('global');
    },
    [setSelectedTierForm, setTiers]
  );

  return (
    <>
      <div className={style.addTiersCombo}>
        <ComboBox<BasinTierResponse>
          placeholder="Add Basin/Tier"
          id="basinTierElements"
          value={
            selectedBasinTier
              ? `${selectedBasinTier.basin}-${selectedBasinTier.tier}`
              : ''
          }
          items={unusedTiers}
          itemToString={(el) => (el ? `${el.basin}-${el.tier}` : '')}
          onChange={({ selectedItem }) =>
            selectedItem && setSelectedBasinTier(selectedItem)
          }
        ></ComboBox>

        <Button
          disabled={!selectedBasinTier}
          size="md"
          onClick={() => {
            if (!selectedBasinTier) return;
            const newTier = tiersRow({
              tier: globalAssumptions,
              basinName: selectedBasinTier.basin,
              tierName: selectedBasinTier.tier,
            });
            setTiers((current) => [...current, newTier]);
            setSelectedTierForm({
              basin: selectedBasinTier.basin,
              tier: selectedBasinTier.tier,
            });
            setSelectedBasinTier(undefined);
          }}
        >
          Add
        </Button>
      </div>

      {sortedBasinTiers.map((el, i) => (
        <BasinTierLink
          key={i}
          index={i}
          onDelete={onDelete}
          rowStatusAtom={el._rowStatus}
          basinAtom={el.basin}
          tierAtom={el.tier}
        />
      ))}
    </>
  );
};

export { Tiers };
