import { createMutationAtom } from 'atoms/create-resource-atom';
import { AtomField, AtomizedForm, atomField, required } from 'atoms/form-atoms';
import { atom, useSetAtom } from 'jotai';
import { JSONObject, JSONValue } from 'lib/types';
import { useMemo } from 'react';
import { CompletionItemResponse } from 'types/api-responses';

export const itemMutationAtom = createMutationAtom<
  CompletionItemResponse,
  'completionPart'
>();

export const confirmActionModalAtom = atom<
  | {
      currentState: 'correct' | 'incorrect';
      terminalNode: string;
      onConfirmAction: (comment?: string) => void;
    }
  | undefined
>(undefined);

export const incorrectProvisionModalAtom = atom<
  | {
      terminalNode: string;
      terminalNodeValue: JSONValue;
      onIncorrect: (form: IncorrectFields) => void;
    }
  | undefined
>(undefined);

export const validationStateAtom = atom<JSONObject>({});

export type IncorrectFields = {
  reason?: string;
  details?: string;
  comment?: string;
};

export type IncorrectForm = AtomizedForm<IncorrectFields>;
export type CommentForm = AtomizedForm<Pick<IncorrectFields, 'comment'>>;

export const useIncorrectProvisionFormAtom = (reset: boolean) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => atom(incorrectForm()), [reset]);
};

export const useCommentFormAtom = (reset: boolean) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => atom(commentForm()), [reset]);
};

function incorrectForm() {
  return {
    reason: atomField<string>('', {
      validate: (v) => required(v, 'Reason is required'),
    }),
    details: atomField<string>(''),
    comment: atomField<string>(''),
  };
}

function commentForm() {
  return {
    comment: atomField<string>(''),
  };
}

export const useValidateDetailsAtom = (
  reasonField: AtomField<string>,
  detailsField: AtomField<string>
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const reason = get(reasonField).value;

        if (reason === 'Other') {
          set(detailsField, { validate: required });
        } else {
          set(detailsField, { validate: (v) => v });
        }
      }
    );
  }, [detailsField, reasonField]);

  return useSetAtom(validateAtom);
};

export const itemEventsAtom = atom<
  Record<string, CompletionItemResponse | undefined>
>({});
