import { CollapseAll16, ExpandAll16 } from '@carbon/icons-react';
import { Button, Checkbox, Dropdown, Search } from 'carbon-components-react';
import { DownloadButton } from 'components/download-button';
import { RecalculateWorkspace } from 'components/recalculate-workspace';
import { atom, useAtom, useSetAtom, WritableAtom } from 'jotai';
import { useDownloadResource } from 'lib/hooks/useDownloadFile';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import {
  runsheetExpandWithCalculationDetails,
  runsheetSearchPreferencesAtom,
  useRunsheetSearch,
} from './atoms';
import { Runsheet } from './runsheet';
import style from './runsheet-search.module.scss';
import sortFields from './sort-fields';
import { OnPaginationChange } from './types';
import { ProvisionsFilter } from 'components/provision-filter';
import ErrorToast from 'components/error-toast';

const RunsheetSearch = ({
  searchHref,
  runsheetDownloadsHref,
  skipUrlSearchParams = false,
  titleWorkspace,
  referenceLocationHref,
  titleWorkspaceHref,
  lastCalculationHref,
  calculationHref,
  showEditInterpretationButton = false,
  startingInterestDate,
  runningCalculationChannel,
  runningCalculationHref,
}: {
  searchHref?: string;
  runsheetDownloadsHref?: string;
  skipUrlSearchParams?: boolean;
  titleWorkspace?: number;
  referenceLocationHref?: string;
  titleWorkspaceHref?: string;
  lastCalculationHref?: string;
  calculationHref?: string;
  showEditInterpretationButton?: boolean;
  startingInterestDate?: string | null;
  runningCalculationChannel?: string | null;
  runningCalculationHref?: string;
}) => {
  const [searchResults, setSearchPayload] = useRunsheetSearch({
    skipUrlSearchParams,
    searchHref,
  });
  const [lastCalculationHrefState, setLastCalculationHrefState] =
    useState(lastCalculationHref);
  const [isTitleCalculationError, setIsTitleCalculationError] = useState(false);

  const [searchDisplayText, setSearchDisplayText] = useState('');
  const downloadList = useDownloadResource(runsheetDownloadsHref);

  const [preferences, setPreferences] = useAtom(runsheetSearchPreferencesAtom);

  const expanded = preferences.expanded;
  const expandAllRowsAtom = useMemo(() => {
    return atom<{ open: boolean }, [{ open: boolean }], void>(
      () => ({ open: expanded ?? false }),
      (get, set, payload) => {
        setPreferences((current) => ({ ...current, expanded: payload.open }));
      }
    );
  }, [expanded, setPreferences]);

  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        const newAttrs = {
          page: 1,
          text: value,
        };
        setSearchPayload({
          params: newAttrs,
          urlSearchParams: newAttrs,
          resetSearch: true,
        });
      }, 400),
    [setSearchPayload]
  );

  const onPaginationChange: OnPaginationChange = (newSearch) => {
    setSearchPayload({
      params: newSearch,
      persistenceParams: { pageSize: newSearch.pageSize },
      urlSearchParams: { page: newSearch.page, pageSize: newSearch.pageSize },
    });
  };

  const hasCompletion = searchResults.currentPayload?.hasCompletion;

  const label = sortFields.find(
    (el) =>
      el.id === searchResults.currentPayload?.sortAttribute &&
      el.direction === searchResults.currentPayload?.sortDirection
  )?.text;

  return (
    <>
      {isTitleCalculationError && (
        <ErrorToast message="Calculation failed. Please contact support." />
      )}
      <div className={style.gridTopRow}>
        <div className={style.searchWrapper}>
          <Search
            id="search-input"
            autoFocus
            labelText="Search"
            placeholder="Search by any runsheet attributes"
            defaultValue={
              searchDisplayText || searchResults.currentPayload?.text || ''
            }
            onChange={({ target: { value } }) => {
              setSearchDisplayText(value || '');
              debouncedSetSearchPayload(value || '');
            }}
            size="lg"
          />
        </div>
        <div className={style.hideReviewed}>
          <Checkbox
            id="hide-reviewed"
            labelText="Hide Reviewed"
            checked={searchResults.currentPayload?.hideReviewed ?? false}
            onChange={(checked: boolean) => {
              const newAttrs = {
                page: 1,
                hideReviewed: checked,
              };
              setSearchPayload({
                params: newAttrs,
                persistenceParams: {
                  hideReviewed: newAttrs.hideReviewed,
                },
                urlSearchParams: newAttrs,
              });
            }}
          />
        </div>
        <div className={style.expandAllButtons}>
          <ExpandButtons expandedRowsAtom={expandAllRowsAtom} />
        </div>

        <div className={style.sortColumnWrapper}>
          <Dropdown
            id="default"
            label={label || ''}
            items={sortFields}
            itemToString={(item) => (item ? item.text : '')}
            onChange={({
              selectedItem,
            }: {
              selectedItem: {
                id: string;
                text: string;
                direction: 'ASC' | 'DESC';
              };
            }) => {
              const newAttrs = {
                page: 1,
                sortAttribute: selectedItem.id,
                sortDirection: selectedItem.direction,
              };
              setSearchPayload({
                params: newAttrs,
                persistenceParams: {
                  sortAttribute: selectedItem.id,
                  sortDirection: selectedItem.direction,
                },
                urlSearchParams: newAttrs,
              });
            }}
          />
        </div>
        <div className={style.sortColumnWrapper}>
          <ProvisionsFilter
            hasCompletion={hasCompletion}
            onChange={(hasCompletion) => {
              const newAttrs = {
                page: 1,
                hasCompletion,
              };
              setSearchPayload({
                params: newAttrs,
                persistenceParams: {
                  hasCompletion,
                },
                urlSearchParams: newAttrs,
              });
            }}
          />
        </div>

        {runsheetDownloadsHref && (
          <div className={style.downloadField}>
            <DownloadButton
              loading={downloadList.generatingDownload}
              loadingText="Building List"
              size="md"
              kind="primary"
              onClick={() => downloadList.triggerDownload()}
            >
              Download List
            </DownloadButton>
          </div>
        )}

        {calculationHref && (
          <div className={style.recalculateButton}>
            <RecalculateWorkspace
              runningCalculationChannel={runningCalculationChannel || null}
              runningCalculationHref={runningCalculationHref}
              startingInterestDate={startingInterestDate}
              calculationsHref={calculationHref}
              onSuccess={(calculationHref) => {
                setLastCalculationHrefState(calculationHref);
              }}
              onTitleCalculationError={(isTitleCalculationError) => {
                setIsTitleCalculationError(isTitleCalculationError);
              }}
            />
          </div>
        )}
      </div>

      <Runsheet
        searchAtomValue={searchResults}
        actions={undefined}
        onPaginationChange={onPaginationChange}
        expandedRowsAtom={expandAllRowsAtom}
        titleWorkspace={titleWorkspace}
        referenceLocationHref={referenceLocationHref}
        titleWorkspaceHref={titleWorkspaceHref}
        lastCalculationHref={lastCalculationHrefState}
        showEditInterpretationButton={showEditInterpretationButton}
      />
    </>
  );
};

const ExpandButtons = ({
  expandedRowsAtom,
}: {
  expandedRowsAtom: WritableAtom<{ open: boolean }, [{ open: boolean }], void>;
}) => {
  const setPreferences = useSetAtom(expandedRowsAtom);
  const [expandWithCalculationDetails, setExpandWithCalculationDetails] =
    useAtom(runsheetExpandWithCalculationDetails);

  return (
    <>
      <Button
        kind="ghost"
        hasIconOnly
        iconDescription="Expand all"
        size="md"
        onClick={() => {
          setPreferences({ open: true });
        }}
        renderIcon={ExpandAll16}
        tooltipAlignment="center"
        tooltipPosition="bottom"
      />
      <Button
        kind="ghost"
        hasIconOnly
        iconDescription="Collapse all"
        size="md"
        onClick={() => {
          setPreferences({ open: false });
        }}
        renderIcon={CollapseAll16}
        tooltipAlignment="center"
        tooltipPosition="bottom"
      />
      <Checkbox
        checked={expandWithCalculationDetails}
        id={'expand-with-calculation-details'}
        labelText={'Calc. Details'}
        onChange={(checked: boolean) => {
          setExpandWithCalculationDetails(checked);
        }}
      ></Checkbox>
    </>
  );
};

export { RunsheetSearch };
