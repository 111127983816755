import { DirectUpload } from '@rails/activestorage';
import {
  createMutationAtom,
  CreateResourceAtom,
} from 'atoms/create-resource-atom';
import {
  ErrorState,
  getFormErrorsAtom,
  getFormIsInvalidAtom,
  getFormValuesAtom,
} from 'atoms/form-atoms';
import { atom } from 'jotai';
import { FileUploadStatus } from 'lib/types';
import { ApiPayloads } from 'types/api-payloads';
import { DocumentResponse } from 'types/api-responses';
import { DocumentForms } from './types';
import {
  createDocumentForm,
  createNonCourthouseForm,
  getNonCourthousePayload,
  getPayload,
} from './utils';
import { getResources } from 'atoms/root';

const fileBaseAtom = atom<FileUploadStatus>('edit');
const fileAtom = atom<
  FileUploadStatus,
  [{ file: File; formAtom: DocumentForms }],
  void
>(
  (get) => get(fileBaseAtom),
  (get, set, { file, formAtom }) => {
    const runUpload = () => {
      const resources = getResources(get);
      const url = resources?.directUploads.href;
      if (!url) return;
      const directUpload = new DirectUpload(file, url);
      const form = get(formAtom);
      set(fileBaseAtom, 'uploading');
      directUpload.create((error, blob) => {
        if (error) {
          set(fileBaseAtom, 'edit');
        } else {
          set(fileBaseAtom, 'complete');
          set(form.file, {
            name: file.name,
            image: blob.signed_id,
          });
        }
      });
    };
    runUpload();
  }
);

const creationMap = {
  courthouse: {
    resource: 'courthouseDocuments',
    getPayload: getPayload,
  },
  noncourthouse: {
    resource: 'nonCourthouseDocuments',
    getPayload: getNonCourthousePayload,
  },
} as const;

const documentMutationAtom = createMutationAtom<DocumentResponse, 'document'>();
const runMutationAtom = atom<
  CreateResourceAtom<DocumentResponse, ApiPayloads['document']['error']>,
  [
    {
      type: 'courthouse' | 'noncourthouse';
      onSuccess: (entity: DocumentResponse) => void;
      onError: (error?: ApiPayloads['document']['error']) => void;
    }
  ],
  void
>(
  (get) => get(documentMutationAtom),
  (get, set, { onSuccess, onError, type }) => {
    const runMutation = () => {
      const utils = creationMap[type];
      const resources = getResources(get);
      const url = resources?.[utils.resource].href;
      if (!url) return;
      set(duplicateDocumentAtom, false);
      set(documentMutationAtom, {
        url: url,
        type: 'POST',
        data: utils.getPayload(get),
        onSuccess: (data) => {
          onSuccess(data);
        },
        onError: (error) => {
          onError(error);
        },
      });
    };

    runMutation();
  }
);

const locationErrorAtom = atom<ErrorState>((get) => {
  const form = get(formAtom);
  const locations = get(form.locationAttributes);
  const noLocations = get(form.noLocations);

  if (!locations.value.length && !noLocations.value) {
    return {
      isValid: false,
      error: 'Must check when survey areas are not specified',
    };
  }

  return { isValid: true };
});

const formAtom = atom(createDocumentForm());
const nonCourthouseFormAtom = atom(createNonCourthouseForm());
const formIsInvalidAtom = getFormIsInvalidAtom(formAtom);
const nonCourthouseFormIsInvalidAtom = getFormIsInvalidAtom(
  nonCourthouseFormAtom
);
const formValuesAtom = getFormValuesAtom(formAtom);
const nonCourthouseFormValuesAtom = getFormValuesAtom(nonCourthouseFormAtom);
const formErrorsAtom = getFormErrorsAtom(formAtom);
const nonCourthouseFormErrorsAtom = getFormErrorsAtom(nonCourthouseFormAtom);
const duplicateDocumentAtom = atom<boolean>(false);
const serverErrorsAtom = atom<string[]>([]);

export {
  duplicateDocumentAtom,
  fileAtom,
  formAtom,
  formErrorsAtom,
  formIsInvalidAtom,
  formValuesAtom,
  locationErrorAtom,
  nonCourthouseFormAtom,
  nonCourthouseFormErrorsAtom,
  nonCourthouseFormIsInvalidAtom,
  nonCourthouseFormValuesAtom,
  runMutationAtom,
  serverErrorsAtom,
};
