import classNames from 'classnames';
import styles from './toggle.module.scss';
import { CheckmarkFilled16, CircleFilled16 } from '@carbon/icons-react';

export function Toggle({
  isActive,
  handleToggle,
}: {
  isActive: boolean;
  handleToggle: (visibility: boolean) => void;
}) {
  return (
    <div
      className={classNames(styles.toggleContainer, {
        [styles.toggleContainerActive]: isActive,
      })}
      onClick={() => handleToggle(!isActive)}
    >
      {isActive ? (
        <CheckmarkFilled16
          className={classNames(styles.toggleHandler, styles.active)}
        ></CheckmarkFilled16>
      ) : (
        <CircleFilled16
          className={classNames(styles.toggleHandler, styles.inactive)}
        ></CircleFilled16>
      )}
    </div>
  );
}
