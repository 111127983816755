import searchAtomPair from 'atoms/search-atom';
import { DocumentFacets } from 'components/search/common/types';
import { DocumentResponse } from 'types/api-responses';
import { DocumentSearchParams } from './types';
import { atomWithStorage } from 'jotai/utils';
import { SortField } from 'components/documents-search/sort-fields';

const { searchAtom, resetSearchDataAtom } = searchAtomPair<
  DocumentResponse,
  DocumentSearchParams,
  DocumentFacets
>('documents');

const documentSearchDateSortSettingAtom = atomWithStorage<
  | {
      sortDirection: SortField['direction'];
      sortAttribute: SortField['id'];
    }
  | undefined
>('document-search-date-sort-setting', undefined, undefined, {
  getOnInit: true,
});

export { documentSearchDateSortSettingAtom, resetSearchDataAtom, searchAtom };
