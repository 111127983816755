import { DocumentInterpretationResponse } from 'types/api-responses';

export type Node = {
  key: string;
  name: string;
  transactionDate: string;
  url: string;
};

type Link = {
  key: string;
  from: string;
  to: string;
  documentTitle: string;
  transactionType: string;
  transactionDate: string;
  url?: string;
};

export type RunsheetGraphDataPoints = {
  nodes: Node[];
  links: Link[];
};

const extractRunsheetData = (
  runsheet: DocumentInterpretationResponse[]
): RunsheetGraphDataPoints => {
  const seenEntity = new Map<string, boolean>();
  const nodes: Node[] = [];
  const links: Link[] = [];

  runsheet.forEach((interpretation) => {
    const grantorIds: number[] = [];

    const transactions = interpretation.transactions;

    if (!transactions.length) return;

    const transactionDate = interpretation.interpretationDate;

    transactions.forEach((transaction) => {
      transaction.grantors.forEach((grantor) => {
        const canonicalId = String(grantor.entity.canonicalId);
        const entity = {
          key: canonicalId,
          id: canonicalId,
          name: grantor.entity.name,
          url: grantor.entity.href,
          transactionDate,
        };

        if (!seenEntity.get(canonicalId)) {
          nodes.push(entity);
          seenEntity.set(canonicalId, true);
        }
        if (grantor.entity.canonicalId) {
          grantorIds.push(grantor.entity.canonicalId);
        }
      });

      transaction.conveyances.forEach((conveyance) => {
        const canonicalId = String(conveyance.entity.canonicalId);
        const granteeKey = `${canonicalId}`;
        const entity = {
          key: canonicalId,
          id: canonicalId,
          name: conveyance.entity.name,
          transactionDate,
          url: conveyance.entity.href,
        };

        if (!seenEntity.get(canonicalId)) {
          nodes.push(entity);
        }

        grantorIds.forEach((grantorId) => {
          links.push({
            key: `${transaction.id}`,
            from: String(grantorId),
            to: String(conveyance.entity.canonicalId),
            documentTitle: interpretation.document.documentTitle,
            transactionType: transaction.type,
            transactionDate,
            url: interpretation.href,
          });

          seenEntity.set(granteeKey, true);
        });
      });
    });
  });

  return { nodes, links };
};

export { extractRunsheetData };
