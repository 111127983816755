import { Modal } from 'carbon-components-react';
import { Atom } from 'jotai';
import { DocumentResponse } from 'types/api-responses';
import { DocumentsSearch } from 'components/documents-search';

import style from './document-references-modal.module.scss';

interface Props {
  onAddDocument: (doc: DocumentResponse) => void;
  usedDocumentsAtom: Atom<number[]>;
  isOpen: boolean;
  onClose: () => void;
}

export const DocumentReferencesModal = ({
  onAddDocument,
  isOpen,
  onClose,
  usedDocumentsAtom,
}: Props) => {
  return (
    <>
      <Modal
        open={isOpen}
        size="lg"
        modalHeading="Add Document References"
        primaryButtonText="Back To Interpretation"
        onRequestSubmit={onClose}
        onRequestClose={onClose}
        className={style.documentReferencesModal}
      >
        {isOpen && (
          <DocumentsSearch
            onAddDocument={onAddDocument}
            usedDocumentsAtom={usedDocumentsAtom}
          />
        )}
      </Modal>
    </>
  );
};
