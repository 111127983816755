import NavigationPrompt from 'react-router-navigation-prompt';
import { Modal } from 'carbon-components-react';

interface Props {
  hasChanges: boolean;
  message: string;
  onSubmit?: () => void;
}
const NavigationGuard = ({ hasChanges, message, onSubmit }: Props) => (
  <NavigationPrompt
    when={(_current, next) => {
      return hasChanges && !next?.hash;
    }}
  >
    {({ onConfirm, onCancel }) => (
      <Modal
        modalHeading="Unsaved Changes"
        danger
        primaryButtonText="Yes, Discard"
        secondaryButtonText="Cancel"
        onRequestClose={onCancel}
        onRequestSubmit={() => {
          onSubmit?.();
          onConfirm();
        }}
        onSecondarySubmit={onCancel}
        shouldSubmitOnEnter={true}
        open
      >
        <p>{message}</p>
      </Modal>
    )}
  </NavigationPrompt>
);

export { NavigationGuard };
