import classNames from 'classnames';
import { IconProps } from './types';
import { Close32 } from '@carbon/icons-react';

import style from './icons.module.scss';

const Rejected = ({ className }: IconProps) => {
  return (
    <div className={classNames(style.background, style.rejected, className)}>
      <Close32 className={classNames(style.icon, style.rejected)} />
    </div>
  );
};

export default Rejected;
