import { Link } from 'carbon-components-react';
import style from './entities-anchor-jump-menu.module.scss';

const EntitiesAnchorJumpMenu = () => {
  return (
    <div className={style.subMenuList}>
      <ul>
        <li>
          <Link href="#demographic-information">Demographic Information</Link>
        </li>
        <li>
          <Link href="#relationships">Entity Relationships</Link>
        </li>
      </ul>
    </div>
  );
};

export { EntitiesAnchorJumpMenu };
