import {
  TableContainer,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { DocumentIndexTable } from 'components/document-index-table';
import { DownloadButton } from 'components/download-button';
import { useDownloadResource } from 'lib/hooks/useDownloadFile';

import style from './page.module.scss';

const DownloadTable = ({ downloadHref }: { downloadHref: string }) => {
  const downloadOwnership = useDownloadResource(downloadHref);
  return (
    <DownloadButton
      loading={downloadOwnership.generatingDownload}
      loadingText="Building List"
      kind="primary"
      onClick={() => downloadOwnership.triggerDownload()}
    >
      Download List
    </DownloadButton>
  );
};

const Toolbar = ({ downloadHref }: { downloadHref: string }) => {
  return (
    <TableToolbar>
      <TableToolbarContent>
        <DownloadTable downloadHref={downloadHref} />
      </TableToolbarContent>
    </TableToolbar>
  );
};

const DocumentsTable = ({
  downloadHref,
  referenceLocationId,
}: {
  downloadHref?: string;
  referenceLocationId: number;
}) => {
  const toolbar = downloadHref ? (
    <Toolbar downloadHref={downloadHref} />
  ) : undefined;
  return (
    <TableContainer className={style.documentsToolbarTable}>
      <DocumentIndexTable
        toolbar={toolbar}
        referenceLocationId={referenceLocationId}
      />
    </TableContainer>
  );
};

export { DocumentsTable };
