import { AtomField } from 'atoms/form-atoms';
import { TextInput, TextInputProps } from 'carbon-components-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { NumericFormat } from 'react-number-format';

interface NumberFieldProps
  extends Omit<
    TextInputProps,
    'value' | 'onChange' | 'defaultValue' | 'labelText'
  > {
  field: AtomField<number> | AtomField<number | null>;
  label?: string;
}
const NumberField = ({ field, label, ...rest }: NumberFieldProps) => {
  const setValue = useSetAtom(field);
  const fieldState = useAtomValue(field);
  return (
    <NumericFormat
      {...rest}
      type="text"
      labelText={label || ''}
      thousandSeparator=","
      onValueChange={(values) => {
        if (values.value !== '') {
          setValue(Number(values.value));
        } else {
          setValue(null);
        }
      }}
      customInput={TextInput}
      invalid={!fieldState.isValid}
      invalidText={!fieldState.isValid ? fieldState.error : ''}
      value={fieldState.value === null ? '' : fieldState.value}
    />
  );
};

export { NumberField };
