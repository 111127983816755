import { JSONObject, JSONValue } from 'lib/types';
import styles from './json-renderer.module.scss';

export function searchJSON(
  jsonValue: JSONValue,
  searchString: string
): JSONValue {
  if (!searchString) return jsonValue;

  const stringMatch = (str: string) =>
    searchString !== '' &&
    str.toLowerCase().includes(searchString.toLowerCase());

  function highlightString(str: string): string {
    if (searchString === '') return escapeHtml(str);
    const regex = new RegExp(`(${searchString})`, 'gi');
    return str.replace(regex, `<span class="${styles.highlight}">$1</span>`);
  }

  function escapeHtml(str: string): string {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  function searchRecursive(value: JSONValue): JSONValue | null {
    if (typeof value === 'string') {
      return stringMatch(value) ? highlightString(value) : null;
    }
    if (typeof value === 'number' || typeof value === 'boolean') {
      const strValue = value.toString();
      return stringMatch(strValue) ? highlightString(strValue) : null;
    }
    if (value === null) {
      return null;
    }
    if (Array.isArray(value)) {
      const result = value
        .map(searchRecursive)
        .filter((item): item is JSONValue => item !== null);
      return result.length > 0 ? result : null;
    }
    if (typeof value === 'object') {
      const result: JSONObject = {};
      let hasMatch = false;
      for (const [key, val] of Object.entries(value)) {
        const newKey = stringMatch(key)
          ? highlightString(key)
          : escapeHtml(key);
        const searchResult = searchRecursive(val);
        if (searchResult !== null || stringMatch(key)) {
          result[newKey] = searchResult !== null ? searchResult : val;
          hasMatch = true;
        }
      }
      return hasMatch ? result : null;
    }
    return null;
  }

  return searchRecursive(jsonValue) ?? {};
}
