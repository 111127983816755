import { Add16 } from '@carbon/icons-react';
import { Accordion, AccordionItem, Button } from 'carbon-components-react';
import { WellSearchModal } from 'components/wells-search-modal';
import { atom, PrimitiveAtom, useAtom, useSetAtom } from 'jotai';
import { splitAtom } from 'jotai/utils';
import { useState } from 'react';
import {
  initWellReferencesEmptyForm,
  WellReferenceSubformAtoms,
} from './utils';
import { WellReferenceFormRow } from './well-reference-row';

import style from './wellbore-section.module.scss';

interface WellboreProps {
  wellReferenceAtoms: PrimitiveAtom<WellReferenceSubformAtoms[]>;
  trxRowDisabled: boolean;
  trnIdx: number;
}

const usedWellsAtom = atom<number[]>([]);
const setUsedWellsAtom = atom<
  null,
  [PrimitiveAtom<WellReferenceSubformAtoms>[]],
  void
>(null, (get, set, payload) => {
  set(
    usedWellsAtom,
    payload.flatMap((el) => {
      const atom = get(el);
      const row = get(atom.row.formAtom);
      const docId = row.well.value?.id;
      return docId ?? [];
    })
  );
});

const WellboreSection = ({
  wellReferenceAtoms,
  trxRowDisabled,
  trnIdx,
}: WellboreProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const wellsAtomsAtom = splitAtom(wellReferenceAtoms);
  const [wells, setWells] = useAtom(wellsAtomsAtom);
  const setUsedWells = useSetAtom(setUsedWellsAtom);

  return (
    <div className={style.wellAccordion}>
      <Accordion align="end" size="md">
        <AccordionItem
          open={true}
          title={`Grants and reservations (${wells.length})`}
        >
          {wells.map((wellAtoms, i) => {
            return (
              <WellReferenceFormRow
                key={i}
                atoms={wellAtoms}
                trnIdx={trnIdx}
                atomIdx={i}
                trxRowDisabled={trxRowDisabled}
                onDelete={() => {
                  setWells({ type: 'remove', atom: wellAtoms });
                }}
              />
            );
          })}

          <WellSearchModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            usedWellsAtom={usedWellsAtom}
            onAddWell={(well) => {
              setWells({
                type: 'insert',
                value: initWellReferencesEmptyForm(well),
              });
            }}
          />
          <hr className={style.hrSeparator} />
          <Button
            kind="tertiary"
            size="md"
            renderIcon={Add16}
            onClick={() => {
              setUsedWells(wells);
              setModalOpen(true);
            }}
            disabled={trxRowDisabled}
          >
            Add Wellbore
          </Button>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export { WellboreSection };
