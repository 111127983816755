import { countBy } from 'lodash';
import wellStatusTypes from 'enums/well_status_types.json';
import { Grid, Row, Column, InlineNotification } from 'carbon-components-react';

import style from './area-summary.module.scss';
import { DevelopmentAreaResponse } from 'types/api-responses';
import { EvalSlotResponse } from 'types/evaluation-api/responses';

const SlotSummary = ({ slots }: { slots: EvalSlotResponse[] }) => {
  const actual = countBy(slots, 'status');
  const stats = Object.keys(wellStatusTypes).map((status) => ({
    status: wellStatusTypes[status as keyof typeof wellStatusTypes],
    count: actual[status] || 0,
  }));

  return (
    <>
      <h2 className={style.subtitle}>Wells</h2>
      <div className={style.summaryData}>
        {stats.map((item) => (
          <dl key={item.status}>
            <dt>{item.status}</dt>
            <dd>{item.count}</dd>
          </dl>
        ))}
      </div>
    </>
  );
};

const AreaDimensions = ({
  developmentArea,
}: {
  developmentArea: DevelopmentAreaResponse;
}) => {
  const area = developmentArea;

  if (!area || !area.length || !area.width)
    return (
      <>
        <div className={style.infoWrapper}>
          <InlineNotification
            title="Warning"
            subtitle="This development area is missing its shape information. Please verify with the GIS team before using any results on this page."
            className={style.missingShapeInfo}
            kind="warning"
            lowContrast
          />
        </div>
      </>
    );

  const normalizedLateralLength = area.lateralLength
    ? area.length / area.lateralLength
    : 1;
  return (
    <>
      <h2 className={style.subtitle}>Dimensions</h2>
      <div className={style.summaryData}>
        <dl>
          <dt>Dev Area Length</dt>
          <dd>
            {area.length.toLocaleString()} <span>ft</span>
          </dd>
        </dl>
        <dl>
          <dt>Dev Area Width</dt>
          <dd>
            {area.width.toLocaleString()} <span>ft</span>
          </dd>
        </dl>
        <dl>
          <dt>Normalized Lateral Length</dt>
          <dd>{normalizedLateralLength.toLocaleString()}</dd>
        </dl>
      </div>
    </>
  );
};

const AreaSummary = ({
  developmentArea,
  slots,
}: {
  developmentArea: DevelopmentAreaResponse;
  slots: EvalSlotResponse[];
}) => {
  return (
    <>
      <Grid className="bx--no-gutter" fullWidth condensed>
        <Row>
          <Column md={12} lg={10} className={style.wellsCol}>
            <SlotSummary slots={slots} />
          </Column>
          <Column md={12} lg={6}>
            <AreaDimensions developmentArea={developmentArea} />
          </Column>
        </Row>
      </Grid>
    </>
  );
};

export { AreaSummary };
