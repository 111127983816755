import { InlineNotification } from 'carbon-components-react';
import { ErrorsType } from 'lib/types';

const concatenateErrors = (errorsObj: ErrorsType) => {
  const errors = errorsObj.errors;
  let allErrors: string[] = [];
  for (const key in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, key)) {
      allErrors = allErrors.concat(errors[key]);
    }
  }
  return allErrors.join(', ');
};

const FieldErrorNotification = ({
  errors,
  onClose,
}: {
  errors: ErrorsType;
  onClose?: () => void;
}) => {
  const allErrors = concatenateErrors(errors);
  return (
    <InlineNotification
      kind="error"
      lowContrast
      title="Error!"
      subtitle={`Something went wrong. ${allErrors}.`}
      hideCloseButton={false}
      onCloseButtonClick={onClose}
    />
  );
};

export { FieldErrorNotification };
