import { atomWithStorage } from 'jotai/utils';
import { getMapDefaultConfig } from 'components/map/atoms';
import { RunsheetSearch } from 'components/runsheet/common/runsheet-search';
import { TitleWorkspaceResponse } from 'types/api-responses';
import { WorkspaceMap } from 'components/workspace-map';
import { useMemo } from 'react';
import { createResourceAtom } from 'lib/atoms';
import { useAtomValue } from 'jotai';
import { InlineLoading, InlineNotification } from 'carbon-components-react';

const mapConfigAtom = atomWithStorage(
  'insights-workspace-runsheet-map-config',
  getMapDefaultConfig()
);

const TitleWorkspaceRunsheetIndexPage = ({
  titleWorkspaceHref,
}: {
  titleWorkspaceHref: string;
}) => {
  const titleWorkspaceAtom = useMemo(
    () => createResourceAtom<TitleWorkspaceResponse>(titleWorkspaceHref),
    [titleWorkspaceHref]
  );

  const titleWorkspace = useAtomValue(titleWorkspaceAtom);
  if (titleWorkspace.data && 'error' in titleWorkspace.data)
    return (
      <InlineNotification
        kind="error"
        title={titleWorkspace.data.error}
        lowContrast
      />
    );
  if (titleWorkspace.loading || !titleWorkspace.data) return <InlineLoading />;
  if (!titleWorkspace.data) return null;
  const titleWorkspaceData = titleWorkspace.data;

  const referenceLocation = titleWorkspaceData.referenceLocation;
  const workspaceResources = titleWorkspaceData.resources;

  return (
    <div>
      <WorkspaceMap
        mapConfigAtom={mapConfigAtom}
        referenceLocationHref={referenceLocation.href}
      />
      <RunsheetSearch
        runningCalculationHref={workspaceResources.currentCalculation?.href}
        runningCalculationChannel={titleWorkspaceData.channel}
        titleWorkspace={titleWorkspaceData.id}
        searchHref={workspaceResources.queries.interpretations.href}
        runsheetDownloadsHref={workspaceResources.runsheetDownloads.href}
        referenceLocationHref={titleWorkspaceData.referenceLocation.href}
        titleWorkspaceHref={titleWorkspaceData.href}
        lastCalculationHref={titleWorkspaceData.resources.lastCalculation?.href}
        calculationHref={titleWorkspaceData.resources.calculations.href}
        startingInterestDate={titleWorkspaceData.startingInterestDate}
        showEditInterpretationButton
      />
    </div>
  );
};

export { TitleWorkspaceRunsheetIndexPage };
