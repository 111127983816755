import {
  RadioButtonGroup,
  RadioButton,
  OverflowMenu,
  Tile,
  Button,
} from 'carbon-components-react';
import { useSetAtom, useAtomValue, useAtom } from 'jotai';
import { mapStylesIndexAtom } from './map';
import { standardMapStyles } from './map-styles';
import { MapLayerSelector } from 'components/map/map-layers-selector';

import style from './map.module.scss';
import { SettingsAdjust16, Currency16 } from '@carbon/icons-react';
import { MapUserConfigAtom } from './types';
import { ReactNode } from 'react';

import { useComponentVisible } from 'lib/hooks/useComponentVisible';

export function StylesSelector({
  showMapFilters,
  mapConfigAtom,
  scopedLayers,
}: {
  showMapFilters: boolean;
  mapConfigAtom: MapUserConfigAtom;
  scopedLayers: ReactNode;
}) {
  const setMapStyleIndex = useSetAtom(mapStylesIndexAtom);
  const mapStyleIndex = useAtomValue(mapStylesIndexAtom);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [mapConfig, setMapConfig] = useAtom(mapConfigAtom);

  return (
    <div className={style.stylesSelector} ref={ref}>
      <Button
        kind="ghost"
        hasIconOnly
        iconDescription="Filters"
        className={style.mapFilterBtn}
        size="sm"
        tooltipAlignment="center"
        tooltipPosition="left"
        onClick={() => setIsComponentVisible((current) => !current)}
        renderIcon={SettingsAdjust16}
      />
      {isComponentVisible && (
        <Tile className={style.controlsContainer}>
          {showMapFilters && (
            <div className={style.layerSelection}>
              {scopedLayers && (
                <section>
                  <h3 className={style.label}>Scoped</h3>
                  {scopedLayers}
                </section>
              )}
              <section>
                <h3 className={style.label}>General</h3>
                <MapLayerSelector mapConfigAtom={mapConfigAtom} />
              </section>
            </div>
          )}
          <section>
            <h3 className={style.label}>Visualization</h3>

            <div className={style.radioChecks}>
              <RadioButtonGroup
                name={`radio-button-${mapStyleIndex}`}
                valueSelected={standardMapStyles[mapStyleIndex].name}
              >
                {standardMapStyles.map((style, i) => (
                  <RadioButton
                    key={style.name}
                    labelText={style.name}
                    value={style.name}
                    onClick={() => setMapStyleIndex(i)}
                  />
                ))}
              </RadioButtonGroup>
            </div>
          </section>
        </Tile>
      )}
      <OverflowMenu
        className={
          mapConfig.developmentAreas.options.pricing
            ? style.pricingActive
            : null
        }
        size="sm"
        flipped
        renderIcon={Currency16}
        onClick={() =>
          setMapConfig((current) => ({
            ...current,
            developmentAreas: {
              ...current.developmentAreas,
              options: {
                ...current.developmentAreas.options,
                pricing: !current.developmentAreas.options.pricing,
              },
            },
          }))
        }
      />
    </div>
  );
}
