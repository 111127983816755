import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  DataTableCustomRenderProps,
} from 'carbon-components-react';
import { Link } from 'components/link';
import style from './title_workspace.module.scss';
import { MineralLeaseholdRow } from 'types/api-responses';
import { Intersect } from 'types/utils';
import { LinkWithHover } from 'components/hover-card-resource';

const headers = [
  {
    key: 'entity',
    header: 'Party',
  },
  {
    key: 'lease',
    header: 'Lease',
  },
  {
    key: 'royaltyRate',
    header: 'Royalty Rate',
  },
  {
    key: 'encumberedInterest',
    header: 'Encumbered Mineral Interest',
  },
  {
    key: 'workingInterest',
    header: 'Working Interest',
  },
];
export interface MineralLeaseholdTableProps {
  rows: MineralLeaseholdRow[] | undefined;
}

export function MineralLeaseholdTable({ rows }: MineralLeaseholdTableProps) {
  const augmentedRows = rows?.map<
    Intersect<
      MineralLeaseholdRow,
      {
        id: string;
        entity: string;
        entityHref: string;
        leaseHref: string;
      }
    >
  >((row, index) => ({
    ...row,
    id: String(index),
    entity: `${row.entityName} (${row.entityId})`,
  }));
  return augmentedRows?.length ? (
    <DataTable isSortable rows={augmentedRows} headers={headers} size="sm">
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
      }: DataTableCustomRenderProps) => (
        <TableContainer className={style.table}>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })} key={header.key}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow {...getRowProps({ row })} key={row.id}>
                    {row.cells.map((cell, cellIndex) => {
                      const cellKey =
                        cell.id + '_' + rowIndex + '_' + cellIndex;
                      const str = cell.id;
                      const augmentedRowIndex = Number(str.replace(/\D/g, ''));

                      switch (cellIndex) {
                        case 0:
                          return (
                            <TableCell key={cellKey}>
                              <LinkWithHover
                                kind="entity"
                                href={`${augmentedRows[augmentedRowIndex].entityHref}`}
                              >
                                <Link
                                  to={`${augmentedRows[augmentedRowIndex].entityHref}`}
                                  target={'_blank'}
                                  rel="noopener noreferrer"
                                >
                                  {cell.value}
                                </Link>
                              </LinkWithHover>
                            </TableCell>
                          );
                        case 1:
                          return (
                            <TableCell key={cellKey}>
                              <LinkWithHover
                                kind="lease"
                                href={`${augmentedRows[augmentedRowIndex].leaseHref}`}
                              >
                                <Link
                                  to={`${augmentedRows[augmentedRowIndex].leaseHref}`}
                                  target={'_blank'}
                                  rel="noopener noreferrer"
                                >
                                  {cell.value}
                                </Link>
                              </LinkWithHover>
                            </TableCell>
                          );
                        default:
                          return (
                            <TableCell key={cellKey}>{cell.value}</TableCell>
                          );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  ) : null;
}
