import { memo, useReducer } from 'react';
import { FormRow } from 'components/forms';
import { Button, Column, Grid, Row, Tile } from 'carbon-components-react';
import { Atom, useAtomValue, useSetAtom, WritableAtom } from 'jotai';

import { FormRowManager } from '../form-row-manager';
import { TransactionCommon, TransactionFormAtoms } from './types';
import { DeleteRowButton } from '../delete-row-button';
import {
  MineralLeaseTransaction,
  SurfaceLeaseTransaction,
} from './lease-transaction';
import { OverridingRoyaltyTransaction } from './overriding-royalty-transaction';

import style from './transaction.module.scss';
import { initEmptyForm } from './utils';
import { Add16 } from '@carbon/icons-react';
import { ConveyanceTransaction } from './conveyance-transaction';
import { formAtoms, transactionsUpdatedAtom } from '../atoms';
import { ReviewSectionCheckbox } from '../review-section-checkbox';
import { TransactionHeader } from 'components/transaction-header';

interface Props {
  atomIdx: number;
  atoms: TransactionFormAtoms;
  onDelete?: (idx: number) => void;
}

const TransactionRow = ({
  atoms,
  idx,
}: {
  atoms: TransactionFormAtoms;
  idx: number;
}) => {
  const setField = useSetAtom(useAtomValue(formAtoms).formAtom);
  const transactions = useAtomValue(
    useAtomValue(formAtoms).formAtom
  ).transactions;
  const totalTransactions = transactions.value.length;
  const relativePosition = idx + 1;

  const reorder = (direction: 'up' | 'down') => {
    setField((current, get, set) => {
      const n = current.transactions.value.length - 1;
      const newIdx =
        direction === 'up'
          ? idx - 1 < 0
            ? 0
            : idx - 1
          : idx + 1 > n
          ? n
          : idx + 1;

      if (newIdx !== idx) {
        const atomRow1 = current.transactions.value[idx].row.formAtom;
        const atomRow2 = current.transactions.value[newIdx].row.formAtom;

        const sequence1 = get(atomRow1 as Atom<TransactionCommon>).sequence
          .value;
        const sequence2 = get(atomRow2 as Atom<TransactionCommon>).sequence
          .value;

        set(
          atomRow1 as WritableAtom<
            TransactionCommon,
            [{ field: 'sequence'; value: number | undefined }],
            void
          >,
          {
            field: 'sequence',
            value: sequence2,
          }
        );

        set(
          atomRow2 as WritableAtom<
            TransactionCommon,
            [{ field: 'sequence'; value: number | undefined }],
            void
          >,
          {
            field: 'sequence',
            value: sequence1,
          }
        );

        const newTransactionOrder = [...current.transactions.value];
        newTransactionOrder[idx] = current.transactions.value[newIdx];
        newTransactionOrder[newIdx] = current.transactions.value[idx];

        return {
          field: 'transactions',
          value: newTransactionOrder,
        };
      }
      return {
        field: 'transactions',
        value: [...current.transactions.value],
      };
    });
  };

  let transaction;
  let title;
  switch (atoms.type) {
    case 'Transaction::MineralLeaseTransaction':
      title = 'Mineral Lease Transaction';
      transaction = <MineralLeaseTransaction row={atoms.row} idx={idx} />;
      break;
    case 'Transaction::SurfaceLeaseTransaction':
      title = 'Surface Lease Transaction';
      transaction = <SurfaceLeaseTransaction row={atoms.row} idx={idx} />;
      break;
    case 'Transaction::OverridingRoyalty':
      title = 'Overriding Royalty';
      transaction = <OverridingRoyaltyTransaction row={atoms.row} idx={idx} />;
      break;
    case 'Transaction::ConveyanceTransaction':
      title = 'Conveyance Transaction';
      transaction = (
        <ConveyanceTransaction
          row={atoms.row}
          idx={idx}
          status={atoms.status}
        />
      );
      break;
    default:
      return null;
  }
  return (
    <fieldset className={style.transactionContainer}>
      <TransactionHeader
        atoms={atoms}
        legendTitle={title}
        relativePosition={relativePosition}
        totalTransactions={totalTransactions}
        reorder={reorder}
      ></TransactionHeader>
      {transaction}
    </fieldset>
  );
};

const Transaction = ({ atomIdx, onDelete, atoms }: Props) => {
  const status = useAtomValue(atoms.status);

  return (
    <Tile
      className={style.transactionTile}
      role="group"
      aria-label="transaction"
    >
      <FormRow toTrash={status === 'trash'}>
        <Grid className="bx--no-gutter" fullWidth>
          <TransactionRow atoms={atoms} idx={atomIdx} />
          <Row>
            <Column className={style.alignEnd}>
              <div className={style.transactionRemove}>
                <label>Remove this transaction</label>
                <DeleteRowButton
                  statusAtom={atoms.status}
                  atomIdx={atomIdx}
                  onDelete={onDelete}
                  adjustForLabel={false}
                />
              </div>
            </Column>
          </Row>
        </Grid>
      </FormRow>
    </Tile>
  );
};

const TransactionMemo = memo(Transaction);

type ManagedForType = {
  field: 'transactions';
  value: TransactionFormAtoms[];
};
const TransactionList = () => {
  const [visible, toggleVisible] = useReducer((c) => !c, false);
  const totalTransactions = useAtomValue(useAtomValue(formAtoms).formAtom)
    .transactions.value.length;

  return (
    <div>
      <FormRowManager<ManagedForType>
        MemoedComponent={TransactionMemo}
        field={'transactions'}
        label="Add Transaction"
        initEmptyForm={() =>
          initEmptyForm(
            'Transaction::MineralLeaseTransaction',
            totalTransactions
          )
        }
        customAddButton={(onAdd) => {
          return (
            <div className={style.addTrxButton}>
              <Button
                renderIcon={Add16}
                size="md"
                kind={'primary'}
                onClick={() => toggleVisible()}
              >
                Add Transaction
              </Button>
              {visible ? (
                <ul role="menu">
                  <li>
                    <Button
                      kind={'ghost'}
                      onClick={() => {
                        onAdd(
                          initEmptyForm(
                            'Transaction::ConveyanceTransaction',
                            totalTransactions
                          )
                        );
                        toggleVisible();
                      }}
                    >
                      Conveyance
                    </Button>
                  </li>
                  <li>
                    <Button
                      kind={'ghost'}
                      onClick={() => {
                        onAdd(
                          initEmptyForm(
                            'Transaction::MineralLeaseTransaction',
                            totalTransactions
                          )
                        );
                        toggleVisible();
                      }}
                    >
                      Mineral Lease
                    </Button>
                  </li>
                  <li>
                    <Button
                      kind={'ghost'}
                      onClick={() => {
                        onAdd(
                          initEmptyForm(
                            'Transaction::SurfaceLeaseTransaction',
                            totalTransactions
                          )
                        );
                        toggleVisible();
                      }}
                    >
                      Surface Lease
                    </Button>
                  </li>
                  <li>
                    <Button
                      kind={'ghost'}
                      onClick={() => {
                        onAdd(
                          initEmptyForm(
                            'Transaction::OverridingRoyalty',
                            totalTransactions
                          )
                        );
                        toggleVisible();
                      }}
                    >
                      Carve Out ORRI
                    </Button>
                  </li>
                </ul>
              ) : null}
            </div>
          );
        }}
      />
      <ReviewSectionCheckbox
        updateTrackingAtom={transactionsUpdatedAtom}
        field={'transactionsReviewed'}
        label="Transactions Reviewed"
        helpMessage="Editing any transaction will change the review status to unreviewed"
      />
    </div>
  );
};

export { TransactionList };
