import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styles from './json-renderer.module.scss';
import {
  CollapseAll16,
  ExpandAll16,
  ChevronDown16,
  ChevronRight16,
  CloseOutline16,
  CloseFilled16,
  CheckmarkOutline16,
  CheckmarkFilled16,
  AddComment16,
  Close16,
} from '@carbon/icons-react';
import {
  Button,
  Modal,
  Tile,
  TooltipDefinition,
} from 'carbon-components-react';
import { JSONValue, JSONObject } from 'lib/types';
import { searchJSON } from './search-json';
import { DebouncedInput } from 'components/debounced-input';
import { EmptyState } from 'components/empty-state';
import { CopyButton } from 'components/copy-button/copy-button';
import {
  IncorrectFields,
  confirmActionModalAtom,
  incorrectProvisionModalAtom,
  itemEventsAtom,
  itemMutationAtom,
  useCommentFormAtom,
  useIncorrectProvisionFormAtom,
  useValidateDetailsAtom,
  validationStateAtom,
} from './atoms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DropdownField } from 'components/form-fields/dropdown-field';
import { TextField } from 'components/entity-form/text-field';
import { TextAreaField } from 'components/form-fields/text-area-field';
import { getFormIsInvalidAtom, getFormValuesAtom } from 'atoms/form-atoms';
import {
  checkChildrenStatus,
  createItemsMap,
  filterNullValues,
  getPathState,
  snakeToCapitalCase,
  updateValidationState,
} from './utils';
import { CompletionItemResponse } from 'types/api-responses';
import {
  HoverCard,
  HoverCardArrow,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/hover-card';
import { CreateFirstComment, EventsList } from 'components/events-panel';
import { capitalizeFirstLetter } from 'utils/strings';
import { useComponentVisible } from 'lib/hooks/useComponentVisible';

interface JSONRendererProps {
  data: JSONValue;
  completionHref: string;
  itemsHref: string;
  items: CompletionItemResponse[];
  state: JSONObject;
}

interface JSONNodeProps {
  data: JSONValue;
  expandState: { version: number; expanded: boolean };
  level: number;
  name?: string;
  validationState: JSONObject;
  path: string[];
  completionHref: string;
  itemsHref: string;
}

export function JSONRenderer({
  data,
  completionHref,
  itemsHref,
  items,
  state,
}: JSONRendererProps) {
  const [expandState, setExpandState] = useState<{
    version: number;
    expanded: boolean;
  }>({ version: 0, expanded: false });
  const [search, setSearch] = useState<string>('');
  const [validationState, setValidationState] = useAtom(validationStateAtom);
  const setItemsMap = useSetAtom(itemEventsAtom);
  const nonNullData = filterNullValues(data);

  useEffect(() => {
    setValidationState(state);

    setItemsMap(createItemsMap(items));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpandAll = () => {
    setExpandState({ version: Date.now(), expanded: true });
  };

  const handleCollapseAll = () => {
    setExpandState({ version: Date.now(), expanded: false });
  };

  const filteredData = searchJSON(nonNullData, search);

  return (
    <div className={styles.jsonRenderer}>
      <ConfirmActionModal />
      <IncorrectProvisionModal />
      <div className={styles.actionsArea}>
        <DebouncedInput
          placeholder="Search the provision..."
          value={search}
          onChange={(value) => {
            if (typeof value === 'string') {
              if (value !== '') handleExpandAll();
              setSearch(value);
            }
          }}
        />
        <div className={styles.actionsArea}>
          <Button
            kind="tertiary"
            hasIconOnly
            iconDescription={'Collapse All'}
            size="md"
            onClick={handleCollapseAll}
            renderIcon={CollapseAll16}
            tooltipAlignment="end"
            tooltipPosition="bottom"
          />
          <Button
            kind="tertiary"
            hasIconOnly
            iconDescription={'Expand All'}
            size="md"
            onClick={handleExpandAll}
            renderIcon={ExpandAll16}
            tooltipAlignment="end"
            tooltipPosition="bottom"
          />
        </div>
      </div>

      <div className={styles.jsonRoot}>
        {filteredData && Object.keys(filteredData).length === 0 ? (
          <Tile>
            <EmptyState
              headerText="No Results Found"
              helperText="Try adjusting your search to find what you’re looking for."
            />
          </Tile>
        ) : (
          filteredData &&
          Object.entries(filteredData as JSONObject).map(([key, value]) => (
            <JSONNode
              key={key}
              data={value}
              expandState={expandState}
              level={0}
              name={key}
              validationState={validationState}
              path={[key]}
              completionHref={completionHref}
              itemsHref={itemsHref}
            />
          ))
        )}
      </div>
    </div>
  );
}

const JSONNode: React.FC<JSONNodeProps> = ({
  data,
  expandState,
  level,
  name,
  validationState,
  path,
  completionHref,
  itemsHref,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    setIsExpanded(expandState.expanded);
  }, [expandState]);

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const reviewCount = checkChildrenStatus(
    getPathState(validationState, [...path])
  );

  const renderValidationButtons = () => {
    const status = getPathState(validationState, path);

    if (typeof status === 'boolean' || status === null) {
      return (
        <StatusButtons
          status={status}
          nodeValue={data}
          path={path}
          completionHref={completionHref}
          itemsHref={itemsHref}
        />
      );
    }

    return null;
  };

  if (data === null || data === undefined) {
    return null;
  }

  if (typeof data !== 'object') {
    return (
      <div className={styles.jsonNode} style={{ marginLeft: `${level * 3}px` }}>
        <span className={styles.jsonKey}>
          {name && (
            <div className={styles.actionButtons}>
              <span
                dangerouslySetInnerHTML={{ __html: snakeToCapitalCase(name) }}
              />
              <span className={styles.jsonCopybutton}>
                <CopyButton node={data} />
                <CommentButton path={path} itemsHref={itemsHref} node={name} />
                {renderValidationButtons()}
              </span>
            </div>
          )}
        </span>
        <div
          className={styles.jsonValue}
          dangerouslySetInnerHTML={{ __html: String(data) }}
        />
      </div>
    );
  }

  const isArray = Array.isArray(data);

  return (
    <div className={styles.jsonNode} style={{ marginLeft: `${level * 3}px` }}>
      <div onClick={toggleExpand} className={styles.jsonExpandable}>
        <div className={styles.jsonNodeTitle}>
          <span className={styles.jsonArrow}>
            {isExpanded ? <ChevronDown16 /> : <ChevronRight16 />}
          </span>
          {name && (
            <span
              className={styles.jsonKey}
              dangerouslySetInnerHTML={{ __html: snakeToCapitalCase(name) }}
            />
          )}
        </div>
        <div>
          {reviewCount && <EventsCount reviewCount={reviewCount} path={path} />}
        </div>
      </div>
      {isExpanded && (
        <div className={styles.jsonChildren}>
          {Object.entries(data as JSONObject).map(([key, value], index) => (
            <JSONNode
              key={key}
              data={value}
              expandState={expandState}
              level={level + 1}
              name={isArray ? `${index + 1}` : key}
              validationState={validationState}
              path={[...path, key]}
              completionHref={completionHref}
              itemsHref={itemsHref}
            />
          ))}
        </div>
      )}
    </div>
  );
};

function EventsCount({
  path,
  reviewCount,
}: {
  path: string[];
  reviewCount: { correct: number; incorrect: number };
}) {
  const itemEvents = useAtomValue(itemEventsAtom);
  const joinedPath = path.join('.');
  let total = 0;
  for (const itemEventKey of Object.keys(itemEvents)) {
    if (itemEventKey.includes(joinedPath)) {
      const event = itemEvents[itemEventKey];
      total += event ? event.commentsCount : 0;
    }
  }
  return (
    <div className={styles.eventsCount}>
      {total > 0 && (
        <>
          <AddComment16 /> {total}
        </>
      )}
      {reviewCount.correct > 0 && (
        <>
          <CheckmarkFilled16 /> {reviewCount.correct}
        </>
      )}
      {reviewCount.incorrect > 0 && (
        <>
          <CloseFilled16 /> {reviewCount.incorrect}
        </>
      )}
    </div>
  );
}

function StatusButtons({
  status,
  nodeValue,
  path,
  itemsHref,
}: {
  status: boolean | null;
  nodeValue: JSONValue;
  path: string[];
  completionHref: string;
  itemsHref: string;
}) {
  const [, setItemEvents] = useAtom(itemEventsAtom);
  const setConfirmationModal = useSetAtom(confirmActionModalAtom);
  const setIncorrectProvisionModal = useSetAtom(incorrectProvisionModalAtom);
  const [validationState, setValidationState] = useAtom(validationStateAtom);
  const [completionItem, completionItemMutation] = useAtom(itemMutationAtom);
  const node = path.slice(-1).pop();
  const terminalNode = snakeToCapitalCase(node || '');
  const terminalNodeValue = nodeValue;
  const joinedPath = path.join('.');

  const onChange = (
    status: boolean | null,
    incorrectFields?: IncorrectFields
  ) => {
    const newState = updateValidationState(path, validationState, status);
    completionItemMutation({
      url: itemsHref,
      type: 'POST',
      data: {
        path: path.join('.'),
        comment: incorrectFields?.comment
          ? { content: incorrectFields.comment }
          : undefined,
        event: {
          type:
            status === null
              ? null
              : status
              ? 'completion_correct'
              : 'completion_incorrect',
          reason: incorrectFields?.reason,
          details: incorrectFields?.details,
        },
      },
      onSuccess: (item) => {
        setValidationState(newState);
        setItemEvents((current) => ({
          ...current,
          [joinedPath]: item,
        }));
      },
    });
  };
  return (
    <div
      className={
        status === false || status === true
          ? styles.statusButtonsOn
          : styles.statusButtons
      }
    >
      <Button
        size="sm"
        hasIconOnly
        kind={'ghost'}
        iconDescription={
          status === false ? 'Remove Incorrect Status' : 'Mark as Incorrect'
        }
        tooltipAlignment="center"
        tooltipPosition="top"
        renderIcon={status === false ? CloseFilled16 : CloseOutline16}
        className={status === false ? styles.notApprovedBtn : ''}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (status === false) {
            setConfirmationModal({
              currentState: 'incorrect',
              terminalNode,
              onConfirmAction: (comment) => {
                onChange(null, { comment: comment });
              },
            });
          }

          if (status === true || status === null) {
            setIncorrectProvisionModal({
              terminalNode,
              terminalNodeValue,
              onIncorrect: (form: IncorrectFields) => {
                onChange(false, form);
              },
            });
          }
        }}
        disabled={completionItem.loading}
      />

      <Button
        size="sm"
        hasIconOnly
        kind={'ghost'}
        iconDescription={
          status === true ? 'Remove Correct Status' : 'Mark as Correct'
        }
        tooltipAlignment="center"
        tooltipPosition="top"
        renderIcon={status === true ? CheckmarkFilled16 : CheckmarkOutline16}
        className={status === true ? styles.approvedBtn : ''}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();

          if (status === false) {
            setConfirmationModal({
              currentState: 'incorrect',
              terminalNode: snakeToCapitalCase(node || ''),
              onConfirmAction: () => {
                onChange(true);
              },
            });
          }

          if (status === true) {
            setConfirmationModal({
              currentState: 'correct',
              terminalNode: snakeToCapitalCase(node || ''),
              onConfirmAction: () => {
                onChange(null);
              },
            });
          }

          if (status === null) {
            onChange(true);
          }
        }}
        disabled={completionItem.loading}
      />
    </div>
  );
}

function CommentButton({
  path,
  itemsHref,
  node,
}: {
  path: string[];
  itemsHref: string;
  node: string;
}) {
  const [itemEvents, setItemEvents] = useAtom(itemEventsAtom);
  const [completionItem, completionItemMutation] = useAtom(itemMutationAtom);
  const joinedPath = path.join('.');
  const itemEvent = itemEvents[joinedPath];
  const title = 'No comments added';
  const helperText = 'Add a comment for yourself or leave a note for others.';
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div ref={ref}>
      <HoverCard open>
        {isComponentVisible && (
          <HoverCardContent
            align="center"
            side="bottom"
            className={styles.commentsHoverCard}
          >
            <HoverCardArrow className={styles.hoverCardArrow} />
            <div>
              <div className={styles.closeButtonArea}>
                <span
                  dangerouslySetInnerHTML={{ __html: snakeToCapitalCase(node) }}
                />
                <Button
                  kind="ghost"
                  hasIconOnly
                  iconDescription={'Close'}
                  size="md"
                  onClick={() => setIsComponentVisible((current) => !current)}
                  renderIcon={Close16}
                  className={styles.closeButton}
                  tooltipAlignment="end"
                  tooltipPosition="left"
                />
              </div>

              {itemEvent ? (
                <EventsList
                  title={title}
                  helperText={helperText}
                  eventsHref={itemEvent.resources.events.href}
                  commentsHref={itemEvent.resources.comments.href}
                  commentsPanel
                  onComment={() => {
                    setItemEvents((current) => ({
                      ...current,
                      [joinedPath]: {
                        ...itemEvent,
                        commentsCount: ++itemEvent.commentsCount,
                      },
                    }));
                  }}
                />
              ) : (
                <CreateFirstComment
                  saving={completionItem.loading}
                  title={title}
                  helperText={helperText}
                  onCreateComment={(comment) => {
                    completionItemMutation({
                      url: itemsHref,
                      type: 'POST',
                      data: {
                        path: joinedPath,
                        comment: { content: comment },
                      },
                      onSuccess: (item) => {
                        setItemEvents((current) => ({
                          ...current,
                          [joinedPath]: item,
                        }));
                      },
                    });
                  }}
                />
              )}
            </div>
          </HoverCardContent>
        )}
        <HoverCardTrigger asChild>
          <span
            className={itemEvent ? styles.activeComments : null}
            onClick={() => setIsComponentVisible((current) => !current)}
          >
            <TooltipDefinition
              direction="top"
              align="center"
              tooltipText="Add Comment"
              className={styles.commentTooltip}
            >
              <AddComment16 />

              {itemEvent && itemEvent.commentsCount > 0
                ? itemEvent.commentsCount
                : ''}
            </TooltipDefinition>
          </span>
        </HoverCardTrigger>
      </HoverCard>
    </div>
  );
}

function ConfirmActionModal() {
  const [confirmAction, setConfirmAction] = useAtom(confirmActionModalAtom);
  const confirmActionMessage = capitalizeFirstLetter(
    confirmAction?.currentState || ''
  );
  const formAtom = useCommentFormAtom(!!confirmAction);
  const form = useAtomValue(formAtom);
  const formValuesAtom = useMemo(() => getFormValuesAtom(formAtom), [formAtom]);
  const formValues = useAtomValue(formValuesAtom);

  return (
    <Modal
      size="xs"
      modalHeading={`Remove ${confirmActionMessage} Status?`}
      danger
      secondaryButtonText="Cancel"
      primaryButtonText="Remove"
      className={styles.confirmActionModal}
      open={!!confirmAction}
      onRequestClose={() => setConfirmAction(undefined)}
      onRequestSubmit={() => {
        if (confirmAction) {
          confirmAction.onConfirmAction(
            formValues.comment ? formValues.comment : undefined
          );
          setConfirmAction(undefined);
        }
      }}
    >
      {confirmAction && (
        <>
          <p>
            This will remove the {confirmActionMessage} status for &quot;
            {capitalizeFirstLetter(confirmAction.terminalNode)}&quot;.
          </p>
          {confirmAction.currentState === 'incorrect' && (
            <fieldset>
              <TextAreaField
                id="incorrect-comment"
                labelText="Add Comment (optional)"
                fieldAtom={form.comment}
              />
            </fieldset>
          )}
        </>
      )}
    </Modal>
  );
}

function IncorrectProvisionModal() {
  const [incorrectProvision, setIncorrectProvision] = useAtom(
    incorrectProvisionModalAtom
  );
  const formAtom = useIncorrectProvisionFormAtom(!!incorrectProvision);
  const form = useAtomValue(formAtom);
  const invalidFormAtom = useMemo(
    () => getFormIsInvalidAtom(formAtom),
    [formAtom]
  );
  const formValuesAtom = useMemo(() => getFormValuesAtom(formAtom), [formAtom]);
  const reason = useAtomValue(form.reason);
  const setValidation = useValidateDetailsAtom(form.reason, form.details);
  const formInvalid = useAtomValue(invalidFormAtom);
  const formValues = useAtomValue(formValuesAtom);

  useEffect(() => {
    setValidation();
  }, [reason, setValidation]);

  return (
    <Modal
      danger
      size="sm"
      className={styles.markIncorrectModal}
      secondaryButtonText="Cancel"
      primaryButtonText="Mark as Incorrect"
      primaryButtonDisabled={formInvalid}
      open={!!incorrectProvision}
      modalHeading="Mark as Incorrect"
      onRequestClose={() => setIncorrectProvision(undefined)}
      onRequestSubmit={() => {
        if (incorrectProvision) {
          incorrectProvision.onIncorrect(formValues);
          setIncorrectProvision(undefined);
        }
      }}
    >
      {incorrectProvision && (
        <div>
          <h4>{incorrectProvision.terminalNode}</h4>
          <p>{incorrectProvision.terminalNodeValue}</p>
          <fieldset>
            <DropdownField
              titleText="Select Reason "
              required
              field={form.reason}
              label="Choose an option"
              itemToString={(item: string) => item}
              size="md"
              id="incorrect-reason"
              items={[
                '',
                'Wrong Legal Description',
                'Typo/Misspelling',
                'Wrong Date',
                'Wrong Name',
                'Wrong Page',
                'Other',
              ]}
            />
          </fieldset>
          <fieldset>
            {reason.value === 'Other' && (
              <TextField
                id="reason-details"
                fieldAtom={form.details}
                labelText="Details"
                required
              />
            )}
          </fieldset>
          <fieldset>
            <TextAreaField
              id="incorrect-comment"
              labelText="Add Comment (optional)"
              fieldAtom={form.comment}
            />
          </fieldset>
        </div>
      )}
    </Modal>
  );
}
