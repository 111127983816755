import {
  Checkbox,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import classnames from 'classnames';
import { atom, useAtom, useAtomValue } from 'jotai';
import wellStatusTypes from 'enums/well_status_types.json';

import style from '../../formations-summary.module.scss';
import { AtomizedForm, getFormValuesAtom } from 'atoms/form-atoms';
import { EvaluationForm } from '../types';
import { useMemo } from 'react';
import {
  DevelopmentAreaEvaluationResponse,
  EvalSlotResponse,
  EvalSlotSchedule,
} from 'types/evaluation-api/responses';
import { Checkmark16 } from '@carbon/icons-react';
import { Date } from 'components/date';
import { Link } from 'components/link';
import { ScenariosForm } from 'pages/scenarios/common/types';
import { createWellToExcludeElement } from 'utils/wells-to-exclude';

const NULL_DISPLAY_VALUE = '--';

const IncludeCellCheckbox = ({
  form,
  slot,
}: {
  form:
    | AtomizedForm<EvaluationForm['drillingAssumptionAttributes']>
    | AtomizedForm<ScenariosForm['developmentAreasAttributes'][0]>;
  slot: EvalSlotResponse;
}) => {
  const [wellExclusions, setWellExclusions] = useAtom(form.wellsToExclude);

  const wellsInfoAtom = useMemo(() => {
    const valuesAtom = wellExclusions.value.map((el) =>
      getFormValuesAtom(atom(el), 'all-values')
    );
    return atom((get) => valuesAtom.map((el) => get(el)));
  }, [wellExclusions.value]);

  const wells = useAtomValue(wellsInfoAtom);

  return (
    <Checkbox
      id={`${slot.formationName}-${slot.slotNumber}`}
      checked={Boolean(
        !wells.filter(
          (el) =>
            el.formationName === slot.formationName &&
            el.slotNumber === slot.slotNumber
        ).length
      )}
      onChange={(checked: boolean) => {
        if (!checked) {
          setWellExclusions((el) => [...el, createWellToExcludeElement(slot)]);
        } else {
          setWellExclusions((el, get) => {
            const removeElement = el.findIndex(
              (el) =>
                get(el.formationName).value === slot.formationName &&
                get(el.slotNumber).value === slot.slotNumber
            );
            return [
              ...el.slice(0, removeElement),
              ...el.slice(removeElement + 1),
            ];
          });
        }
      }}
      labelText=""
    />
  );
};

const IncludeCellLabel = ({
  slot,
  excludedSlots,
}: {
  slot: EvalSlotResponse;
  excludedSlots: DevelopmentAreaEvaluationResponse['drillingAssumption']['wellsToExclude'];
}) => {
  const excludedIndex = excludedSlots.findIndex(
    (el) =>
      el.formationName === slot.formationName &&
      el.slotNumber === slot.slotNumber
  );

  return excludedIndex >= 0 ? null : <Checkmark16 />;
};

const VolumesScalingFactor = ({
  slotSchedule,
  formationName,
  slotNumber,
  scalingFactor,
}: {
  slotSchedule: EvalSlotSchedule[] | undefined;
  formationName: string;
  slotNumber: number;
  scalingFactor: number | null;
}) => {
  const updatedScalingFactor = slotSchedule?.find(
    (el) => el.formationName === formationName && el.slotNumber === slotNumber
  )?.volumeAdjustmentRatio;

  return updatedScalingFactor ? (
    <>{updatedScalingFactor.toFixed(4)}</>
  ) : (
    <>{scalingFactor?.toFixed(4) ?? NULL_DISPLAY_VALUE}</>
  );
};

const FirstProductionDate = ({
  slotSchedule,
  formationName,
  slotNumber,
  firstProductionDate,
}: {
  slotSchedule: EvalSlotSchedule[] | undefined;
  firstProductionDate: string | null;
  formationName: string;
  slotNumber: number;
}) => {
  const updatedSchedule = slotSchedule?.find(
    (el) => el.formationName === formationName && el.slotNumber === slotNumber
  );

  return updatedSchedule ? (
    <>
      <Date date={updatedSchedule.firstProductionDate} />
    </>
  ) : (
    <>
      {firstProductionDate ? (
        <Date date={firstProductionDate} />
      ) : (
        NULL_DISPLAY_VALUE
      )}
    </>
  );
};

const Slots = ({
  form,
  excludedSlots,
  slotList,
  evalSlots,
  devAreaSlots,
}: {
  form?:
    | AtomizedForm<EvaluationForm['drillingAssumptionAttributes']>
    | AtomizedForm<ScenariosForm['developmentAreasAttributes'][0]>;
  excludedSlots?: DevelopmentAreaEvaluationResponse['drillingAssumption']['wellsToExclude'];
  slotList?: DevelopmentAreaEvaluationResponse['response']['slotSchedule'];
  evalSlots?: DevelopmentAreaEvaluationResponse['slots'];
  devAreaSlots?: EvalSlotResponse[];
}) => {
  const slots = form ? devAreaSlots : evalSlots;

  if (!slots)
    return (
      <DataTableSkeleton
        showHeader={false}
        columnCount={14}
        showToolbar={false}
      />
    );

  return (
    <div className={style.slotsModalTableSticky}>
      <TableContainer>
        <Table aria-label="Slots" size="md">
          <TableHead className={style.stickyHeader}>
            <TableRow>
              <TableHeader scope="col">
                {form ? 'Include' : 'Included'}
              </TableHeader>
              <TableHeader scope="col">Formation</TableHeader>
              <TableHeader scope="col">Type Curve Status</TableHeader>
              <TableHeader scope="col">Slot</TableHeader>
              <TableHeader scope="col">UWI</TableHeader>
              <TableHeader scope="col">Name</TableHeader>
              <TableHeader scope="col">Operator</TableHeader>
              <TableHeader scope="col">ResCat</TableHeader>
              <TableHeader scope="col">Forecast Status</TableHeader>
              <TableHeader scope="col">Volumes Scaling Factor</TableHeader>
              <TableHeader scope="col">Permit Date</TableHeader>
              <TableHeader scope="col">Spud Date</TableHeader>
              <TableHeader scope="col">Completion Date</TableHeader>
              <TableHeader scope="col">First Production Date</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {slots.map((slot) => (
              <TableRow key={`${slot.formationName}-${slot.slotNumber}`}>
                <TableCell>
                  {form ? (
                    <IncludeCellCheckbox slot={slot} form={form} />
                  ) : (
                    <IncludeCellLabel
                      slot={slot}
                      excludedSlots={excludedSlots || []}
                    />
                  )}
                </TableCell>

                <TableCell>{slot.formationName.replace(/_/g, ' ')}</TableCell>
                <TableCell>
                  {(slot.typeCurveStatus &&
                    slot.typeCurveStatus.replace(/_/g, ' ')) ||
                    NULL_DISPLAY_VALUE}
                </TableCell>
                <TableCell>{slot.slotNumber}</TableCell>
                <TableCell>
                  {slot.uwi ? (
                    <Link to={`/wells/${slot.uwi}`} target={'_blank'}>
                      {slot.uwi}
                    </Link>
                  ) : (
                    NULL_DISPLAY_VALUE
                  )}
                </TableCell>
                <TableCell>{slot.name || NULL_DISPLAY_VALUE}</TableCell>
                <TableCell>{slot.operator || NULL_DISPLAY_VALUE}</TableCell>
                <TableCell>
                  {wellStatusTypes[slot.status as keyof typeof wellStatusTypes]}
                </TableCell>
                <TableCell
                  className={classnames(
                    style[slot.reserveCategory],
                    style[slot.forecastStatus]
                  )}
                >
                  {slot.forecastStatus.replace(/-/g, ' ')}
                </TableCell>
                <TableCell>
                  <VolumesScalingFactor
                    slotSchedule={slotList}
                    formationName={slot.formationName}
                    slotNumber={slot.slotNumber}
                    scalingFactor={slot.volumesScalingFactor}
                  />
                </TableCell>
                <TableCell>
                  {slot.permitDate ? (
                    <Date date={slot.permitDate} />
                  ) : (
                    NULL_DISPLAY_VALUE
                  )}
                </TableCell>
                <TableCell>
                  {slot.spudDate ? (
                    <Date date={slot.spudDate} />
                  ) : (
                    NULL_DISPLAY_VALUE
                  )}
                </TableCell>
                <TableCell>
                  {slot.completionDate ? (
                    <Date date={slot.completionDate} />
                  ) : (
                    NULL_DISPLAY_VALUE
                  )}
                </TableCell>
                <TableCell>
                  <FirstProductionDate
                    slotSchedule={slotList}
                    firstProductionDate={slot.firstProductionDate}
                    formationName={slot.formationName}
                    slotNumber={slot.slotNumber}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export { Slots };
