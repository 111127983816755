import { useAtomValue } from 'jotai';
import { buildPolygonStyle } from 'lib/map';
import { Layer, Source } from 'react-map-gl';
import { createMapSourceAtom } from './atoms';
import { MapUserConfigAtom } from './types';
import { mapColors } from './map-colors';
import { useMemo } from 'react';

const STYLE = buildPolygonStyle({
  color: mapColors.typeCurvesFill,
  fillOpacity: 0.05,
  lineOpacity: 0.8,
});
interface Props {
  style?: typeof STYLE;
  mapConfigAtom: MapUserConfigAtom;
}

const TypeCurvesLayer = ({ style = STYLE, mapConfigAtom }: Props) => {
  const typeCurvesSourceAtom = useMemo(
    () => createMapSourceAtom('typeCurves'),
    []
  );
  const typeCurvesSource = useAtomValue(typeCurvesSourceAtom);
  const mapConfig = useAtomValue(mapConfigAtom);
  if (!typeCurvesSource) {
    return null;
  }

  return (
    <Source id="type-curves" type="vector" url={typeCurvesSource}>
      {mapConfig.typeCurves?.checked && (
        <Layer
          id="type-curves-line"
          type="line"
          paint={style.line}
          filter={['==', ['get', 'type'], 'shape']}
          source-layer="type-curves"
        />
      )}
      {mapConfig.typeCurves?.checked && (
        <Layer
          id="type-curves-label"
          type="symbol"
          source-layer="type-curves"
          filter={['==', 'type', 'centroid']}
          layout={{
            'text-field': ['get', 'name'],
          }}
          paint={{
            'text-color': STYLE.fill['fill-color'],
          }}
        />
      )}
    </Source>
  );
};

export { TypeCurvesLayer };
