import { GeoJSON } from 'geojson';
import {
  PackageEvaluationResponse,
  PackageResponse,
} from './packages-api/responses';
import { TractsCalculationDetails } from './title-calculations/types';
import { EvalSlotResponse } from './evaluation-api/responses';
import { JSONObject, JSONValue } from 'lib/types';

export interface Resource {
  href: string;
  auth: string[];
}

export type ResourceNoAuth = Pick<Resource, 'href'>;

export interface MapSourcesResponse {
  basins: string;
  basinTiers: string;
  developmentAreas: string;
  landGrid: string;
  typeCurves: string;
  wells: string;
}

export interface ApiRootResponse {
  session?: {
    href: string;
    auth: string[];
    email?: string;
    trackingToken?: string;
    profile?: {
      name: string;
    };
  };
  version: {
    href: string;
    auth: string[];
    major: string | null;
    minor: string | null;
  };
  resources: ApiResourcesResponse | LogoutApiResourcesResponse;
}

interface LogoutApiResourcesResponse {
  sessions: {
    basic: Resource;
    oauth: Resource;
  };
}

export interface ApiResourcesResponse {
  queries: {
    activities: Resource;
    titleWorkspaces: Resource;
    referenceLocations: Resource;
    locations: Resource;
    entities: Resource;
    documents: Resource;
    wells: Resource;
    interpretations: Resource;
    transactions: Resource;
    counties: Resource;
    developmentAreas: Resource;
    packages: Resource;
  };
  validations: {
    developmentAreas: Resource;
    titleWorkspaces: Resource;
    documents: Resource;
  };
  states: Resource;
  boundingBoxes: Resource;
  quarteredGeographies: Resource;
  documents: Resource;
  wells: Resource;
  directUploads: Resource;
  courthouseDocuments: Resource;
  nonCourthouseDocuments: Resource;
  developmentAreas: Resource;
  entities: Resource;
  interpretations: Resource;
  referenceLocations: Resource;
  titleWorkspaces: Resource;
  maps: { sources: Resource };
  scenarios: Resource;
  scenariosDefault: Resource;
  packages: Resource;
  basinTiers: Resource;
}

export type ApiQueryResource = keyof ApiResourcesResponse['queries'];
export type ApiValidationResource = keyof ApiResourcesResponse['validations'];
export type ApiResource = keyof Omit<
  ApiResourcesResponse,
  'queries' | 'maps' | 'validations' | 'session'
>;

interface CountyResponse {
  stateId: number;
  name: string;
  state: {
    abbreviation: string;
  };
}

export type Point = [number, number];

export interface DocumentResponse {
  id: number;
  locations?: LocationResponse[];
  href: string;
  auth: string[];
  bookType: string;
  duplicate: boolean;
  county: CountyResponse | undefined;
  documentTitle: string;
  documentType?: string;
  referenceLocations?: ReferenceLocationResponseMinimal[];
  type?: string | null;
  instrumentDate: string;
  instrumentNumber?: string;
  interpretationsCount: number;
  newInterpretationHref: string;
  notifications: Notifications;
  grantors: TextBasedEntityResponse[];
  grantees: TextBasedEntityResponse[];
  referencedDocuments?: DocumentResponse[];
  effectiveDate?: string | null;
  recordedDate?: string;
  fileDate?: string;
  page?: string;
  volume?: string;
  resources: {
    grantors: Resource;
    grantees: Resource;
    interpretations: Resource;
    image?: Resource;
    locations?: Resource;
    referenceLocations?: Resource;
    referencedDocuments?: Resource;
    duplicateInterpretations: Resource;
    transactionParties: Resource;
    interpretationValidation: Resource;
    completions: Resource;
    completionsConfiguration: Resource;
  };
}

export interface TransactionPartyResponse {
  role: 'grantee' | 'grantor';
  interpretationIndices: number[];
  entity: EntityBaseResponse;
}

interface IndividualEntity {
  birthDate: string | null;
  deathDate: string | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
}

interface OrganizationEntity {
  formationDate: string | null;
  terminationDate: string | null;
  organizationType: string;
  primaryName: string;
}

export type EntityType =
  | 'OrganizationEntity'
  | 'IndividualEntity'
  | 'ImportedEntity';

export interface EntityBaseResponse {
  auth: string[];
  href: string;
  id: number;
  canonicalId?: number;
  hasInventory: boolean;
  name: string;
  type: EntityType;
  deleted: boolean;
  phoneNumbers: Array<{
    id: number;
    number: string;
    primary: boolean;
  }>;
  emailAddresses: Array<{
    id: number;
    email: string;
    primary: boolean;
  }>;
  addresses: Array<{
    id: number;
    streetAddress: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    primary: boolean;
  }>;
  resources: {
    queries: {
      interpretations: ResourceNoAuth;
      inventoryAssets: ResourceNoAuth;
    };
    aliasedEntities: ResourceNoAuth;
    capacities: ResourceNoAuth;
    fromAliases: ResourceNoAuth;
    ownership: ResourceNoAuth;
    deduplication: ResourceNoAuth;
    details: ResourceNoAuth;
    packages: ResourceNoAuth;
    inventoryAssetsDownload: ResourceNoAuth;
  };
}

export interface TextBasedEntityResponse {
  auth: string[];
  href: string;
  id: number;
  name: string;
}

export interface EntityResponse extends EntityBaseResponse {
  notes: string | null;
  concreteEntity: { id: number } & IndividualEntity & OrganizationEntity;
}

export interface EntityDetailsResponse {
  entityCreatedAt: string;
  entityId: number;
  transactionApplicableAreas: string;
  transactionMaxDate: string;
  transactionMinDate: string;
}

export interface AliasedEntityResponse {
  canonical: boolean;
  entity: EntityBaseResponse;
  resources: {
    alias: Resource | null;
  };
}

export interface PackagesFromEntityResponse {
  id: number;
  href: string;
  name: string;
  effectiveDate: string;
  createdAt: string;
  includeChildren: boolean;
}

export interface InventoryResponse {
  assetId: string;
  assetName: string;
  fullLegalDescription: string | null;
  startDepth: string | null;
  endDepth: string | null;
  legacyDepth: string | null;
  notes: string | null;
  assetType: string | null;
  tractGrossAcres: number;
  tractNetAcres: number;
  tractNetAcreageUnits: string;
  inventoryAssetId: string;
  inventoryAssetName: string;
  inventoryOwnerId: string | null;
  inventoryOwnerType: string | null;
  inventoryOwnerName: string | null;
  inventoryOwnerPortcoAbbreviation: string | null;
  inventoryOwnerParentId: string | null;
  inventoryOwnerParentType: string | null;
  inventoryOwnerParentName: string | null;
  inventoryOwnerParentPortcoAbbreviation: string | null;
  acquisitionDate: string;
  acquisitionUnitPrice: string;
  acquisitionTotalPrice: string;
  saleDate: string | null;
  saleUnitPrice: string | null;
  saleTotalPrice: string | null;
  acquiredFromType: string;
  acquiredFromName: string;
  soldToType: string | null;
  soldToName: string | null;
  state: string;
  county: string;
  quarterCall: string;
  tractInstrumentType: string | null;
  startEffectiveDate: string;
  endEffectiveDate: string | null;
  daysHeld: number;
}

export interface CapacityResponse {
  id: number;
  predicate: string | null;
  interpretationCount: number;
  object: EntityBaseResponse | null;
  subject: EntityBaseResponse;
}

export interface TransactionQueryResponse {
  id: number;
  date: string;
  type: string;
  documentTitle: string;
  interpretationHref: string;
}

export interface SurfaceLeaseOwnershipRow {
  party: string;
  partyId: number;
  partyHref: string;
  lease: string;
  leaseHref: string;
  encumberedSurfaceInterest: string;
  surfaceLeaseInterest: string;
}

type CalculationOutputObject = Record<string, string>;

export interface CalculationOutput {
  bonus: CalculationOutputObject;
  delay: CalculationOutputObject;
  royalty: CalculationOutputObject;
  surface: CalculationOutputObject;
  executive: CalculationOutputObject;
  royaltyRates: CalculationOutputObject;
  workingInterests: CalculationOutputObject;
  mineralEncumbrances: CalculationOutputObject;
  netRevenueInterests: CalculationOutputObject;
  mineralLeaseOverrides: MineralLeaseOverrideRow[];
  aggregateSurfaceLeaseOwnership: SurfaceLeaseOwnershipRow[];
}

export interface MineralLeaseOverrideRow {
  amount: string;
  from: string;
  fromId: number;
  fromHref: string;
  lease: string;
  leaseHref: string;
  to: string;
  toId: number;
  toHref: string;
}

export interface SurfaceCalculationOutputRow {
  entityName: string;
  entityId: number;
  entityHref: string;
  surface: string;
  preventedOverconveyance: boolean;
}

export interface DepthRangeCalculationOutputRow {
  entityName: string;
  entityId: number;
  entityHref: string;
  executive?: string;
  bonus?: string;
  royalty?: string;
  delay?: string;
  netRevenueInterest: string;
  preventedOverconveyance: boolean;
}

export type CalculationOutputRow =
  | SurfaceCalculationOutputRow
  | DepthRangeCalculationOutputRow;

export interface MineralLeaseholdRow {
  entityName: string;
  entityId: number;
  entityHref: string;
  lease: string;
  leaseHref: string;
  royaltyRate: string;
  encumberedInterest?: string;
  workingInterest?: string;
}

export interface Notifications {
  channel: string;
  events: Record<'name', string>[];
}

export interface DepthRangeResponse {
  href: string;
  name: string;
  output: CalculationOutput;
  outputRows: CalculationOutputRow[];
  mineralLeaseholdRows: MineralLeaseholdRow[];
  notifications: Notifications;
  resources: { debuggingGraph?: Resource };
}

export interface DownloadTrackerResponse extends Resource {
  notifications: Notifications;
  status: string;
}

export interface DocumentProvisionJobResponse extends Resource {
  notifications: Notifications;
}

export interface DownloadResponse extends Resource {
  id: number;
  status: string;
  fileUrl: string;
  notifications: Notifications;
}

export interface TractResponse extends Resource {
  id: number;
  status: string;
  output: CalculationOutput;
  outputRows: CalculationOutputRow[];
  calculatedAcreage: number | null;
  legalAcreage: number | null;
  derivedName: string;
  overriddenName: string | null;
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon | null;
  depthRanges: TractDepthRangeResponse[];
  notifications: Notifications;
  resources: { debuggingGraph?: Resource };
}

export interface WorkingInterestOwnerResponse {
  entity: ResourceWithName;
}

export type WellIntersectionWellResponse =
  | {
      deleted: false;
      href: string;
      auth: string[];
      name: string;
      uwi: string;
      resources: {
        features: {
          href: string;
          auth: string[];
        };
      };
    }
  | {
      deleted: true;
      name: string;
      uwi: string;
    };

export interface WellIntersectionResponse extends Resource {
  id: number;
  well: WellIntersectionWellResponse;
  workingInterestOwners: WorkingInterestOwnerResponse[];
}

export interface ResourceWithName extends Resource {
  name: string;
}

export interface TractDepthRangeResponse extends ResourceWithName {
  id: number;
}

export interface CalculationTractResponse extends ResourceWithName {
  id: number;
  calculatedAcreage: number | null;
  legalAcreage: number | null;
  geometry: GeoJSON.Geometry;
}

export interface CalculationWellIntersectionResponse extends Resource {
  id: number;
  well: WellIntersectionWellResponse;
}

export interface TitleCalculationResponse extends Resource {
  id: number;
  status: string;
  asOfDate: string;
  createdAt: string;
  tracts: CalculationTractResponse[];
  wellIntersections: CalculationWellIntersectionResponse[];
  resources: { downloads: Resource; entities: Resource };
  notifications: Notifications;
}

export interface CalculationEntity {
  href: string;
  entity: EntityBaseResponse;
  resources: {
    mineralDebuggingGraph?: Resource;
    surfaceDebuggingGraph?: Resource;
  };
}

export interface OwnershipResponse {
  assets: {
    type: 'mineral' | 'surface';
    titleWorkspace?: {
      href: string;
      id: number;
      name: string;
      referenceLocation: ReferenceLocationResponseMinimal;
    };
    date: string;
    updatedAt: string;
    coveredArea: {
      aggregateId: string;
      name: string;
      startDepthInFeet: string | null;
      endDepthInFeet: string | null;
      calculatedAcreage: string;
      legalAcreage: string;
      geojson: GeoJSON.Geometry;
    };
    lease: {
      href: string;
      name: string;
      royaltyRate: string;
      interpretation: Resource & { id: number };
    } | null;
    burdenedEntity: EntityBaseResponse | null;
    surface: string;
    executive: string;
    bonus: string;
    delay: string;
    royalty: string;
    fixedRoyalty: string;
    working: string;
    override: string;
    netRevenueInterest: string;
    preventedOverconveyance: boolean;
  }[];
}

export interface TitleWorkspaceResponse extends Resource {
  id: number;
  referenceLocation: ReferenceLocationResponseMinimal;
  name: string;
  legalAcreage?: string;
  createdBy: string;
  createdAt: string;
  lastCalculated: string | null;
  numberOfOwners: number | null;
  numberOfTracts: number | null;
  numberOfDepths: number | null;
  startingInterestDate: string | null;
  channel: string | null;
  startingInterest: Array<{
    entity: EntityBaseResponse;
    id: number;
    mineral: string;
    surface: string;
  }>;
  resources: {
    queries: { entities: Resource; interpretations: Resource };
    documents: Resource;
    entities: Resource;
    wells: Resource;
    runsheet: Resource;
    startingInterest: Resource;
    calculations: Resource;
    runsheetDownloads: Resource;
    lastCalculation: Resource | null;
    lastTag: Resource | null;
    currentCalculation: Resource | null;
  };
}

interface ReferenceLocationResponseMinimal {
  auth?: string[];
  href: string;
  id: number;
  name: string;
  shortName: string;
  county?: CountyResponse;
  acreage?: number;
}

export interface ReferenceLocationResponse
  extends ReferenceLocationResponseMinimal {
  geometry?: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  createdAt?: string;
  createdBy?: string;
  resources: {
    documentsDownloads?: Resource;
    titleWorkspace: Resource | null;
  };
}

export interface CountyQueryResponse {
  id: number;
  name: string;
  fips: string;
  stateAbbreviation: string;
}

export interface LocationResponse {
  auth: string[];
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  href: string;
  id: number;
  quarterCalls: string[];
  locationId: number;
  referenceLocationName: string;
  referenceLocation: ReferenceLocationResponse;
}

export interface ReviewAttributes {
  id: number;
  transactionsReviewed: boolean;
  applicableAreasReviewed: boolean;
  documentReferencesReviewed: boolean;
  capacitiesReviewed: boolean;
  curativeRequirementsReviewed: boolean;
  additionalInformationReviewed: boolean;
  grantorsReviewed: boolean;
  granteesReviewed: boolean;
}

interface TransactionGrantor {
  id: number;
  entityId: number;
  entity: EntityResponse;
}

interface TransactionConveyance {
  id: number;
  granteeId: number;
  entity: EntityResponse;
}

type TransactionConveyanceForOverridingRoyalty = {
  multiplier: string | null;
} & TransactionConveyance;

type TransactionConveyanceForConveyanceTransaction = {
  surfaceMultiplier?: string;
  mineralMultiplier?: string;
  bonusMultiplier?: string;
  delayMultiplier?: string;
  royaltyMultiplier?: string;
  executiveMultiplier?: string;
  workingMultiplier?: string;
  overridingMultiplier?: string;
  surfaceLeaseMultiplier?: string;
  fixed?: boolean;
} & TransactionConveyance;

export interface LeaseExtension {
  id?: number;
  transactionId?: number;
  referenceLocation: ReferenceLocationResponse;
  extensionReason: string;
  startDepthInFeet: string | null;
  endDepthInFeet: string | null;
  quarterCalls: string[];
}

type TransactionWellReference = {
  id: number;
  well: WellsResponse;
  referenceType: 'grant' | 'reserve';
};

export type TransactionResponse =
  | {
      type: 'Transaction::MineralLeaseTransaction';
      status?: StatusValues | null;
      id: number;
      sequence: number;
      mineralLease: {
        expirationDate: string | null;
        extension: number;
        heldByProduction: boolean;
        royaltyRate: string;
        hbpReason: string | null;
        term: number;
        id: number;
        topLeases: {
          id: number;
          name: string;
          interpretationHref: string;
        }[];
        subjectLeases: {
          id: number;
          name: string;
          interpretationHref: string;
        }[];
      };
      grantors: TransactionGrantor[];
      conveyances: TransactionConveyance[];
      leaseExtensions: LeaseExtension[];
    }
  | {
      type: 'Transaction::SurfaceLeaseTransaction';
      status?: StatusValues | null;
      id: number;
      sequence: number;
      surfaceLease: {
        expirationDate: string;
        extension: number;
        term: number;
        id: number;
      };
      grantors: TransactionGrantor[];
      conveyances: TransactionConveyance[];
    }
  | {
      type: 'Transaction::OverridingRoyalty';
      status?: StatusValues | null;
      id: number;
      ofType: string;
      sequence: number;
      grantors: TransactionGrantor[];
      conveyances: TransactionConveyanceForOverridingRoyalty[];
      transactionDocumentReferences: {
        id: number;
        document: DocumentResponse;
      }[];
      transactionWellReferences: TransactionWellReference[];
    }
  | {
      type: 'Transaction::ConveyanceTransaction';
      unburdened: boolean | null;
      status?: StatusValues | null;
      id: number;
      sequence: number;
      conveyanceType: string;
      ofType: string;
      grantors: TransactionGrantor[];
      conveyances: TransactionConveyanceForConveyanceTransaction[];
      transactionDocumentReferences: {
        id: number;
        document: DocumentResponse;
      }[];
      transactionWellReferences: TransactionWellReference[];
    };

interface LocationReferenceInterpretation {
  id: number;
  startDepthInFeet: string | null;
  endDepthInFeet: string | null;
  depthRangeName: string;
  location: LocationResponse;
}

export interface DocumentInterpretationResponse {
  href: string;
  id: number;
  auth: string[];
  notes: string;
  reviewed: boolean | null;
  imported: boolean;
  hasOpenRequirements: boolean;
  pageReferences: string | null;
  supersedingDate: string | null;
  legalDescription: string;
  lastSavedAt: string | null;
  lastSavedBy: { email: string } | null;
  assumption: boolean;
  interpretationDate: string;
  interpretationDateSource: string;
  transactionsCount: number;
  topLease: boolean;
  subjectLease: boolean;
  subjectLeases: {
    id: number;
    name: string;
    interpretationHref: string;
  }[];
  topLeases: {
    id: number;
    name: string;
    interpretationHref: string;
  }[];
  granteeReferences?: Array<{
    id: number;
    granteeId: number;
    name: string;
    notes: string | null;
  }>;
  grantorReferences?: Array<{
    id: number;
    grantorId: number;
    name: string;
    notes: string | null;
  }>;
  locationReferences: LocationReferenceInterpretation[];
  interpretationCapacities: {
    id: number;
    capacityId: number;
    predicate: string | null;
    object: EntityResponse | null;
    subject: EntityResponse;
  }[];
  curativeRequirements: {
    id: number;
    status: string;
    priority: string;
    description: string;
  }[];
  documentReferences: {
    id: number;
    referenceType: string;
    document: DocumentResponse;
  }[];
  document: {
    id: number;
    documentType: string;
    bookType: string;
    fileDate: string;
    instrumentDate: string;
    effectiveDate: string;
    recordedDate: string;
    documentTitle: string;
    county: CountyResponse | null;
    instrumentNumber?: string | null;
    newInterpretationHref: string;
    duplicateInterpretations: Resource;
  };
  transactions: TransactionResponse[];
  reviewAttributes: ReviewAttributes;
  supporting: {
    id: number;
    interpretationHref: string;
    documentTitle: string;
    documentType: string;
    bookType?: string;
    volume?: string;
    page?: string;
  }[];
  supportedBy: {
    id: number;
    document: {
      id: number;
      href: string;
      documentTitle: string;
      documentType: string;
      bookType: string;
      volume?: string;
      page?: string;
    };
  }[];
  resources: {
    duplicate: Resource;
  };
  updatedAt: string;
}

export interface ActivitiesQueryResponse {
  email: string;
  newInterpretationsCount: number;
  editedInterpretationsCount: number;
}

export interface WellsQueryResponse {
  href: string;
  auth: string[];
  id: number;
  uwi: string;
  name: string;
  leaseName: string;
  wellNumber: string;
  currentOperator: string;
  holeDirection: string | null;
  status: string;
  permitDate: string;
  spudDate: string;
  firstProductionDate: string | null;
  srpLandingZone: string | null;
  vendorLandingZone: string | null;
  resources: {
    features: Resource;
    productionRecord: Resource;
    productionForecast: Resource;
    comments: Resource;
    supportingFiles: Resource;
    events: Resource;
    formation: Resource;
  };
  notifications: Notifications;
}

export interface WellsResponse extends WellsQueryResponse {
  rigReleaseDate: string | null;
  completionDate: string | null;
  lastProductionDate: string | null;
  measuredDepth: number | null;
  trueVerticalDepth: number | null;
  totalLiquid: number | null;
  totalGas: number | null;
  totalWater: number | null;
  monthsFromFirstToLastProduction: number | null;
  monthsReported: number | null;
  producingFormation: string | null;
  formationAtTotalDepth: string | null;
  events: EventResponse[];
}

export interface WellFeaturesResponse {
  href: string;
  auth: string[];
  surfaceHole: GeoJSON.Point | null;
  bottomHole: GeoJSON.Point | null;
  stick: GeoJSON.Feature | null;
  survey: GeoJSON.Feature | null;
}

export interface WellFormationResponse {
  href: string;
  auth: string[];
  groundElevation: number;
  layers: Array<{
    name: string;
    elevation: number;
    economicValue: number;
  }>;
}

export interface WellProductionForecastResponse {
  liquid?: number[];
  gas?: number[];
  water?: number[];
  notes?: string;
  generatedOn?: string;
  dates?: string[];
  engineer?: string;
}

export interface WellSupportingFileResponse {
  id: number;
  createdBy: string;
  fileType: string;
  href: string;
  fileName: string;
  attachmentHref: string;
}

export interface WellProductionRecordsResponse {
  liquid?: number[];
  gas?: number[];
  water?: number[];
  dates?: string[];
}

export interface ActivitiesResponse {
  totalCount: number;
  results: {
    email: string;
    newInterpretationsCount: number;
    editedInterpretationsCount: number;
  }[];
}

export interface WellProperties {
  statusCounts: { status: string; count: number }[];
  totalProduction: {
    totalLiquid: number | null;
    totalWater: number | null;
    totalGas: number | null;
  };
  wellItemsCount: number;
  topOperators: { name: string; count: number }[];
}

export interface DevelopmentAreaResponse extends WellProperties {
  href: string;
  auth: string[];
  name: string;
  id: number;
  createdAt: string;
  isImported: boolean;
  geometry: GeoJSON.Geometry;
  basinName: string | null;
  tierName: string | null;
  developmentAreaGeographies: {
    id: number | null;
    quarterCalls: string[];
    quarteredGeography: {
      name: string;
      geometry: GeoJSON.Geometry;
    } | null;
    referenceLocation: ReferenceLocationResponse;
  }[];
  formations:
    | {
        name: string;
        depth: number;
        typeCurve: {
          name: string;
          spacing: number;
          status: string;
          oilEurMbbl30yr: number;
          gasEurMmcf30yr: number;
        } | null;
        slotCount: number;
      }[];
  width: number | null;
  length: number | null;
  lateralLength: number | null;
  resources: {
    comments: Resource;
    defaultAssumptions: Resource;
    evaluations: ResourceNoAuth;
    events: Resource;
    slots: ResourceNoAuth;
  };
}

export interface ProductionForecastResponse {
  dates?: string[];
  forecastedWellsCount?: number;
  gas?: number[];
  liquid?: number[];
  water?: number[];
}

type CommentEvent = {
  kind: 'create_comment';
  content: string;
};

type CreateSupportingFileEvent = {
  kind: 'create_supporting_file';
  content: string;
};

type DestroySupportingFile = {
  kind: 'destroy_supporting_file';
  content: string;
};

type CompletionCorrectEvent = {
  kind: 'completion_correct';
  content: null;
};

type CompletionIncorrectEvent = {
  kind: 'completion_incorrect';
  content: {
    reason: string;
    details?: string;
  };
};

type CompletionRevertedEvent = {
  kind: 'completion_reverted';
  content: null;
};

export interface EventResponse {
  id: number;
  data:
    | CommentEvent
    | CreateSupportingFileEvent
    | DestroySupportingFile
    | CompletionCorrectEvent
    | CompletionRevertedEvent
    | CompletionIncorrectEvent;
  createdBy: string;
  createdAt: string;
}

export interface DevelopmentAreaQueryResponse {
  href: string;
  auth: string[];
  name: string;
  id: number;
  createdAt?: string;
  createdBy?: string;
  isImported: boolean;
  anchorGeography: { name: string; quarterCalls: string[] };
  resources: {
    comments: Resource;
    events: Resource;
  };
}

export interface PackagesQueryResponse {
  id: string;
  href: string;
  auth: string[];
  name: string;
  sellerName: string | null;
  totalAcreage: number;
  createdAt?: string;
  acquirerName: string | null;
  closeDate: string;
  effectiveDate: string | null;
  includeChildren: boolean | null;
  completedEvals?: number;
}

export interface StateResponse {
  id: number;
  name: string;
  abbreviation: string;
}

export interface OutputPartyLease {
  [name: string]: {
    [leaseName: string]: string;
  };
}

export interface OutputAggregate {
  [executivePartyName: string]: {
    [interestOwnerName: string]: string;
  };
}

export interface TitleChainCalculatorYamlResponse {
  surface: OutputAggregate;
  aggregateRoyaltyInterest: OutputAggregate;
  aggregateBonusInterest: OutputAggregate;
  aggregateDelayInterest: OutputAggregate;
  aggregateExecutiveInterest: OutputAggregate;
  surfaceLeases: OutputPartyLease;
  aggregateMineralInterestWithRoyalty: OutputAggregate;
  aggregateMineralInterestWithBonus: OutputAggregate;
  aggregateMineralInterestWithDelay: OutputAggregate;
  surfaceEncumbrances: OutputPartyLease;
  mineralEncumbrances: OutputPartyLease;
  workingInterests: OutputPartyLease;
  mineralLeaseRoyaltyRates: CalculationOutput;
  netRevenueInterests: OutputAggregate;
  mineralLeaseOverrides: Array<{
    from: string;
    to: string;
    lease: string;
    amount: number;
  }>;
  wells: Array<{
    name: string;
    executiveInterests: OutputAggregate;
    workingInterests: OutputAggregate;
  }>;
  imageHref: string;
}

export interface BasinTierResponse {
  basin: string;
  tier: string;
}

export interface ScenariosResponse {
  id: number;
  name: string;
  interestType: string;
  href: string;
  isDefault: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface ProvisionResponse {
  id: number;
  href: string;
  ocrPath: string | null;
  outputPath: string | null;
  status: 'queued' | 'pending' | 'complete' | 'error';
  state: JSONObject;
  createdAt: string;
  updatedAt: string;
  notifications: Notifications;
  content?: JSONValue;
  metadata: {
    completion: {
      service: string;
      version: string;
      prompt: string;
      createdBy: string;
    };
    ocr: {
      service: string;
      version: string;
    };
  };
  resources: {
    comments: Resource;
    events: Resource;
    extraction: Resource;
    items: Resource;
  };
}

export interface OCRExtractionResponse {
  content: {
    paragraphs:
      | {
          spans?: {
            offset: number;
            length: number;
          }[];
          boundingRegions?: {
            pageNumber: number;
            polygon: number[];
          }[];
          role?: string;
          content: string;
        }[]
      | null;
    tables:
      | {
          rowCount: number;
          columnCount: number;
          cells: {
            kind?: string;
            rowIndex: number;
            columnIndex: number;
            content: string;
            boundingRegions?: {
              pageNumber: number;
              polygon: number[];
            }[];
            spans?: {
              offset: number;
              length: number;
            }[];
            elements?: string[];
          }[];
        }[]
      | null;
    text: string | null;
  };
  resources: {
    contentDownload: Resource;
  };
}

export interface CompletionItemResponse {
  path: string;
  commentsCount: number;
  resources: {
    comments: Resource;
    events: Resource;
  };
}

export interface CompletionConfigurationOption {
  provider: string;
  version: string;
  default: boolean;
}

export type CompletionPromptConfigurationOption = Omit<
  CompletionConfigurationOption,
  'provider'
>;

export interface CompletionConfigurationResponse {
  completionConfigurations: CompletionConfigurationOption[];
  extractionConfigurations: CompletionConfigurationOption[];
  promptConfigurations: CompletionPromptConfigurationOption[];
}

export type ApiResponse = Readonly<
  | ScenariosResponse
  | TitleWorkspaceResponse
  | ReferenceLocationResponse
  | DocumentInterpretationResponse
  | TitleCalculationResponse
  | TractResponse
  | DocumentResponse
  | EntityResponse
  | PackageEvaluationResponse
  | DevelopmentAreaResponse
  | AliasedEntityResponse
  | PackageResponse
  | EvalSlotResponse
  | CompletionItemResponse
  | { notifications: Notifications }
>;

export type ApiQueryResponse = Readonly<
  | TitleWorkspaceResponse
  | ReferenceLocationResponse
  | DocumentInterpretationResponse
  | LocationResponse
  | EntityBaseResponse
  | DocumentResponse
  | WellsQueryResponse
  | CountyQueryResponse
  | TransactionQueryResponse
  | DevelopmentAreaQueryResponse
  | ActivitiesQueryResponse
  | PackagesQueryResponse
  | InventoryResponse
>;

export interface ReferenceLocationBucket {
  href: string;
  id: number;
  auth: string[];
  count: number;
  name: string;
  shortName: string;
}

export type TitleCalculationDetailsResponse = {
  transactionId: number;
  expiration: boolean;
  interpretation: DocumentInterpretationResponse;
  changed: boolean;
  tracts: TractsCalculationDetails[];
};

export interface SearchResult<
  Data extends ApiQueryResponse,
  Facets = undefined,
  Aggregations = undefined
> {
  totalCount: number;
  page: number;
  pageSize: number;
  results: Data[];
  facets?: Facets;
  boundingBox?: { points: [Point, Point] } | null;
  aggregations?: Aggregations;
}

export const STATUS_TYPES = {
  partialHBP: 'Partial HBP',
  active: 'Active',
  inactive: 'Inactive',
  HBP: 'HBP',
} as const;

type StatusKeys = keyof typeof STATUS_TYPES;
export type StatusValues = typeof STATUS_TYPES[StatusKeys];
