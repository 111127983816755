import { getError } from 'atoms/create-resource-atom';
import { TextArea } from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { formAtoms } from '../atoms';

const LegalDescription = () => {
  const legalDescriptionAtom = useMemo(
    () => atom((get) => get(get(formAtoms).formAtom).legalDescription),
    []
  );
  const setField = useSetAtom(useAtomValue(formAtoms).formAtom);
  const legalDescription = useAtomValue(legalDescriptionAtom);

  return (
    <FormRow>
      <TextArea
        labelText="Full legal description"
        placeholder="Text Field"
        light
        id={`legalDescription`}
        value={legalDescription.value}
        onChange={({ target: { value } }) =>
          setField({ field: 'legalDescription', value })
        }
        autoFocus={true}
        invalid={!!getError(legalDescription)}
        invalidText={getError(legalDescription)}
      />
    </FormRow>
  );
};

export { LegalDescription };
