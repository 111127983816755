import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from 'carbon-components-react';
import { sortBy } from 'lodash';
import style from '../page.module.scss';
import { OutputAggregate } from 'types/api-responses';

export interface InterestProps {
  interest: OutputAggregate;
  header: string;
}

export function Interest({ interest, header, ...rest }: InterestProps) {
  const interests = Object.entries(interest);

  return interests.length ? (
    <TableContainer className={style.table} {...rest}>
      <Table size="xs" useStaticWidth={true}>
        <TableHead>
          <TableRow>
            <TableHeader>Party</TableHeader>
            <TableHeader>{header}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy(interests, [([partyName]) => partyName]).map(
            ([partyName, percentage]) => (
              <TableRow key={partyName}>
                <TableCell>{partyName}</TableCell>
                <TableCell>{percentage}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
