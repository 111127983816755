import { ErrorFilled16 } from '@carbon/icons-react';
import { createDeleteAtom } from 'atoms/create-resource-atom';
import { AtomField, AtomizedForm } from 'atoms/form-atoms';
import {
  Button,
  Column,
  Dropdown,
  Form,
  FormGroup,
  InlineNotification,
  RadioButton,
  RadioButtonGroup,
  Grid,
  Row,
  TextArea,
  Tile,
  InlineLoading,
} from 'carbon-components-react';
import classNames from 'classnames';
import { Buttons } from 'components/buttons';
import { DatePicker } from 'components/date-picker';
import { DestroyRecordButton } from 'components/destroy-record-button/destroy-record-button';
import { FormErrorList } from 'components/form-error-list';
import { FormRow, FormSection } from 'components/forms/form-section';
import { ImportedTag } from 'components/imported-tag';
import { useAtom, useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EntityResponse, EntityType } from 'types/api-responses';
import { getDateString } from 'utils/dates';
import {
  FormAtoms,
  runMutationAtom,
  useErrorListAtom,
  useFormAtoms,
  useFormHasChangesAtom,
  useFormIsInvalidAtom,
} from './atoms';

import style from './form.module.scss';
import {
  ImportedEntityForm,
  IndividualEntityForm,
  OrganizationEntityForm,
  organizationTypes,
} from './types';
import { resourcesAtom } from 'atoms/root';
import { ContactInfoRows, ContactInfoRowsAtom } from './contact-info-rows';
import { TextField } from './text-field';

const DateField = ({
  id,
  labelText,
  fieldAtom,
}: {
  id: string;
  labelText: string;
  fieldAtom: AtomField<string | null>;
}) => {
  const [field, setField] = useAtom(fieldAtom);

  return (
    <DatePicker
      light
      id={id}
      size="md"
      labelText={labelText}
      value={field.value ?? undefined}
      onChange={(date) => {
        setField(date[0] ? getDateString(date[0]) : null);
      }}
    />
  );
};

const TextAreaField = ({
  id,
  labelText,
  fieldAtom,
  rows,
}: {
  id: string;
  labelText: string;
  rows: number;
  fieldAtom: AtomField<string | null>;
}) => {
  const [field, setField] = useAtom(fieldAtom);
  return (
    <TextArea
      id={id}
      labelText={labelText}
      rows={rows}
      light
      value={field.value || ''}
      onChange={({ target: { value } }) => setField(value)}
    />
  );
};

const OrganizationType = ({
  fieldAtom,
}: {
  fieldAtom: AtomField<string | null>;
}) => {
  const [field, setField] = useAtom(fieldAtom);
  return (
    <Dropdown
      titleText={
        <label>
          Organization Type (Required)
          {!field.isValid ? (
            <span className={style.required}>
              <ErrorFilled16 />
            </span>
          ) : (
            ''
          )}
        </label>
      }
      label=""
      onChange={({ selectedItem }) => selectedItem && setField(selectedItem)}
      itemToString={(item) => item}
      light
      id={'organization-type'}
      items={organizationTypes}
      type="default"
      selectedItem={field.value}
      invalid={!field.isValid && field.isDirty}
      invalidText={!field.isValid && field.isDirty ? field.error : ''}
    />
  );
};

const Individual = ({ form }: { form: AtomizedForm<IndividualEntityForm> }) => {
  return (
    <>
      <FormRow>
        <Row className={style.rowThirds}>
          <Column>
            <TextField
              id="firstName"
              labelText="First Name (Required)"
              fieldAtom={form.concreteEntityAttributes.firstName}
            />
          </Column>
          <Column>
            <TextField
              id="middleName"
              labelText="Middle Name"
              fieldAtom={form.concreteEntityAttributes.middleName}
            />
          </Column>
          <Column>
            <TextField
              id="lastName"
              labelText="Last Name (Required)"
              fieldAtom={form.concreteEntityAttributes.lastName}
            />
          </Column>
        </Row>
      </FormRow>
      <FormRow>
        <Row>
          <Column md={4} lg={8}>
            <DateField
              id="date-of-birth"
              labelText="Date of Birth"
              fieldAtom={form.concreteEntityAttributes.birthDate}
            />
          </Column>
          <Column md={4} lg={8}>
            <DateField
              id="date-of-death"
              labelText="Date of Death"
              fieldAtom={form.concreteEntityAttributes.deathDate}
            />
          </Column>
        </Row>
      </FormRow>
      <FormRow>
        <Row>
          <Column lg={16}>
            <TextAreaField
              labelText="Notes"
              rows={5}
              id={`notes`}
              fieldAtom={form.notes}
            />
          </Column>
        </Row>
      </FormRow>
    </>
  );
};

const Organization = ({
  form,
}: {
  form: AtomizedForm<OrganizationEntityForm>;
}) => {
  return (
    <>
      <FormRow>
        <Row>
          <Column lg={6} md={4}>
            <OrganizationType
              fieldAtom={form.concreteEntityAttributes.organizationType}
            />
          </Column>
          <Column lg={10} md={4}>
            <TextField
              id="primaryName"
              labelText="Primary Name (Required)"
              fieldAtom={form.concreteEntityAttributes.primaryName}
            />
          </Column>
        </Row>
      </FormRow>
      <FormRow>
        <Row>
          <Column md={4} lg={8}>
            <DateField
              id="formation-date"
              labelText="Formation Date"
              fieldAtom={form.concreteEntityAttributes.formationDate}
            />
          </Column>
          <Column md={4} lg={8}>
            <DateField
              id="termination-date"
              labelText="Termination Date"
              fieldAtom={form.concreteEntityAttributes.terminationDate}
            />
          </Column>
        </Row>
      </FormRow>
      <FormRow>
        <Row>
          <Column lg={16}>
            <TextAreaField
              labelText="Notes"
              rows={5}
              id={`notes`}
              fieldAtom={form.notes}
            />
          </Column>
        </Row>
      </FormRow>
    </>
  );
};

const Imported = ({ form }: { form: AtomizedForm<ImportedEntityForm> }) => {
  return (
    <FormRow>
      <Row>
        <Column md={4} lg={10}>
          <TextField
            id="name"
            labelText="Name (Required)"
            fieldAtom={form.concreteEntityAttributes.name}
          />
        </Column>
      </Row>
    </FormRow>
  );
};

const DeleteButton = ({ entity }: { entity: EntityResponse | undefined }) => {
  const deleteAtom = useMemo(() => createDeleteAtom(), []);
  const resources = useAtomValue(resourcesAtom);
  const history = useHistory();

  if (!entity) return null;
  if (!entity.auth.includes('destroy')) return null;

  return (
    <DestroyRecordButton
      deleteAtom={deleteAtom}
      className={style.alignDeleteButtonRight}
      url={entity.href}
      onSuccess={() => {
        const href = resources?.entities.href;
        if (!href) return;
        history.push(href);
      }}
    />
  );
};

const ErrorList = ({ formAtoms }: { formAtoms: FormAtoms }) => {
  const errorListAtom = useErrorListAtom(formAtoms);
  const errorsAtom = useAtomValue(errorListAtom);
  return <FormErrorList errorsAtom={errorsAtom} />;
};

const MainFormButtons = ({
  isUpdatingEntity,
  formAtoms,
  entity,
  getEntity,
  onSuccess,
  showCancel,
}: {
  isUpdatingEntity: boolean;
  formAtoms: FormAtoms;
  entity: EntityResponse | undefined;
  getEntity: (() => Promise<void>) | undefined;
  onSuccess?: (entity: EntityResponse) => void;
  showCancel: boolean;
}) => {
  const [entityCreation, createEntity] = useAtom(runMutationAtom);
  const formIsInvalidAtom = useFormIsInvalidAtom(formAtoms);
  const formIsInvalid = useAtomValue(useAtomValue(formIsInvalidAtom));
  const history = useHistory();

  return (
    <>
      <Buttons justify="no-margin">
        <Button
          disabled={formIsInvalid || entityCreation.loading}
          onClick={() => {
            createEntity({
              isUpdatingEntity,
              formAtoms,
              entityUri: entity?.href,
              onError: () => void 0,
              onSuccess: (entity) => {
                if (onSuccess) {
                  onSuccess(entity);
                  return;
                }

                if (isUpdatingEntity) {
                  getEntity?.();
                } else {
                  history.push(entity.href);
                }
              },
            });
          }}
        >
          {isUpdatingEntity ? 'Update Entity' : 'Create Entity'}
        </Button>
        {showCancel && (
          <>
            <div style={{ width: '1rem' }} />
            <Button kind="secondary" onClick={() => history.push('/entities')}>
              Cancel
            </Button>
          </>
        )}
        <DeleteButton entity={entity} />
      </Buttons>
    </>
  );
};

const HasPendingChanges = ({ formAtoms }: { formAtoms: FormAtoms }) => {
  const formHasChangesAtom = useFormHasChangesAtom(formAtoms);
  const hasPendingChanges = useAtomValue(useAtomValue(formHasChangesAtom));
  if (!hasPendingChanges) return null;

  return (
    <InlineNotification
      kind="info"
      lowContrast
      title="Unsaved changes"
      hideCloseButton={true}
    />
  );
};

const EntityForm = ({
  entity,
  getEntity,
  loading,
  entityTypeValue = 'IndividualEntity',
  onSuccess,
  showCancel = true,
}: {
  entity: EntityResponse | undefined;
  getEntity: (() => Promise<void>) | undefined;
  loading: boolean;
  entityTypeValue?: EntityType;
  onSuccess?: (entity: EntityResponse) => void;
  showCancel?: boolean;
}) => {
  const [entityType, setEntityType] = useState(entityTypeValue);
  const formAtoms = useFormAtoms(entity, entityType);
  const formObj = useAtomValue(formAtoms);
  const isImportedEntity = entity?.type === 'ImportedEntity';

  const isUpdatingEntity = !!entity;

  return (
    <Tile className={style.tileEntities}>
      <Form
        title=""
        className={classNames(style.form, style.anchorSection)}
        id="demographic-information"
      >
        {loading ? (
          <InlineLoading />
        ) : (
          <Grid className="bx--no-gutter" fullWidth>
            <Row>
              <Column md={8} lg={9}>
                <Tile className={style.tileSection}>
                  <ImportedTag value={isImportedEntity} />
                  {!isImportedEntity ? (
                    <FormSection
                      title="Demographic Information"
                      iconComponent={''}
                    >
                      <FormGroup legendText="Entity Type">
                        <FormRow>
                          <RadioButtonGroup
                            name="entityType"
                            valueSelected={entityType}
                            onChange={(value) => {
                              setEntityType(value as EntityType);
                            }}
                            disabled={isUpdatingEntity}
                          >
                            <RadioButton
                              labelText="Individual"
                              value="IndividualEntity"
                            />
                            <RadioButton
                              labelText="Organization"
                              value="OrganizationEntity"
                            />
                          </RadioButtonGroup>
                        </FormRow>
                        {formObj.type === 'IndividualEntity' && (
                          <Individual form={formObj.form} />
                        )}
                        {formObj.type === 'OrganizationEntity' && (
                          <Organization form={formObj.form} />
                        )}
                      </FormGroup>
                    </FormSection>
                  ) : (
                    <FormSection
                      title="Demographic Information"
                      iconComponent={''}
                    >
                      {formObj.type === 'ImportedEntity' && (
                        <Imported form={formObj.form} />
                      )}
                    </FormSection>
                  )}
                </Tile>
                <Tile className={style.tileSection}>
                  <ContactInfoRows
                    form={
                      formObj.form.addressesAttributes as ContactInfoRowsAtom
                    }
                    title="Address Information"
                    addLabel="Add New Address"
                    type="address"
                  />
                </Tile>
              </Column>

              <Column md={8} lg={7}>
                <Tile className={style.tileSection}>
                  <ContactInfoRows
                    form={
                      formObj.form
                        .emailAddressesAttributes as ContactInfoRowsAtom
                    }
                    title="E-mail Information"
                    addLabel="Add New Email"
                    type="email"
                  />
                </Tile>
                <Tile className={style.tileSection}>
                  <ContactInfoRows
                    form={
                      formObj.form.phoneNumbersAttributes as ContactInfoRowsAtom
                    }
                    title="Phone Information"
                    addLabel="Add New Phone"
                    type="phone"
                  />
                </Tile>
              </Column>
            </Row>
            <Row>
              <Column lg={16}>
                <Tile>
                  <ErrorList formAtoms={formAtoms} />
                  <HasPendingChanges formAtoms={formAtoms} />
                  <MainFormButtons
                    showCancel={showCancel}
                    formAtoms={formAtoms}
                    isUpdatingEntity={isUpdatingEntity}
                    entity={entity}
                    getEntity={getEntity}
                    onSuccess={onSuccess}
                  />
                </Tile>
              </Column>
            </Row>
          </Grid>
        )}
      </Form>
    </Tile>
  );
};

export { EntityForm };
