import { DECIMAL_FORMATTER } from 'lib/ui';
import { DevelopmentAreaResponse } from 'types/api-responses';
import { DefinitionEntry } from './definition-entry';
import { EvalSlotResponse } from 'types/evaluation-api/responses';
import { ResourceFetcher } from 'components/resource-fetcher';
import { countBy } from 'lodash';
import wellStatusTypes from 'enums/well_status_types.json';
import { Link } from 'components/link';
import { Development20 } from '@carbon/icons-react';
import { Well as WellIcon } from 'components/icons/well';
import style from './development-area-preview.module.scss';
import { useState } from 'react';

const Slots = ({ data }: { data: EvalSlotResponse[] }) => {
  const actual = countBy(data, 'status');
  const stats = ['producing', 'duc', 'permit', 'LTD'].map((status) => ({
    status: wellStatusTypes[status as keyof typeof wellStatusTypes],
    count: actual[status] || 0,
  }));

  return (
    <div className={style.wellsSlots}>
      <div className={style.titleArea}>
        <div className={style.iconArea}>
          <WellIcon medium hasBackground={false} />
        </div>
        <div className={style.popName}>Wells</div>
      </div>
      <div className={style.summaryData}>
        {stats.map((item) => (
          <dl key={item.status}>
            <dt>{item.status}</dt>
            <dd>{DECIMAL_FORMATTER.format(item.count)}</dd>
          </dl>
        ))}
      </div>
    </div>
  );
};

const DevelopmentAreaPreviewContent = ({
  devArea,
}: {
  devArea: DevelopmentAreaResponse;
}) => {
  const [slots, setSlots] = useState<EvalSlotResponse[] | undefined>(undefined);
  const normalizedLateralLength =
    devArea.lateralLength !== null && devArea.length !== null
      ? devArea.length / devArea.lateralLength
      : 1;
  return (
    <>
      <div className={style.titleArea}>
        <div className={style.iconArea}>
          <Development20 />
        </div>
        <div className={style.popName}>
          <Link to={devArea.href} target="_blank">
            {devArea.name}
          </Link>
          <p>Development Area</p>
        </div>
      </div>
      <div className={style.defListContent}>
        <div className={style.summaryInfo}>
          {devArea.length && (
            <DefinitionEntry label="Area Length">
              <span>{DECIMAL_FORMATTER.format(devArea.length)} ft</span>
            </DefinitionEntry>
          )}
          {devArea.width && (
            <DefinitionEntry label="Area Width">
              <span>{DECIMAL_FORMATTER.format(devArea.width)} ft</span>
            </DefinitionEntry>
          )}
          <DefinitionEntry label="Lateral Length">
            <span>{DECIMAL_FORMATTER.format(normalizedLateralLength)}</span>
          </DefinitionEntry>
        </div>
        <dl className={style.operatorList}>
          {slots ? (
            <>
              <dt>Operators</dt>
              {Array.from(new Set(slots.map((item) => item.operator))).map(
                (el) => (el ? <dd>{el}</dd> : null)
              )}
            </>
          ) : null}
        </dl>
      </div>

      <ResourceFetcher<EvalSlotResponse[]>
        href={devArea.resources.slots.href}
        component={Slots}
        setData={(slots) => setSlots(slots)}
      />
    </>
  );
};

const DevelopmentAreaPreview = ({
  data,
}: {
  data: DevelopmentAreaResponse;
}) => {
  return (
    <div>
      <DevelopmentAreaPreviewContent devArea={data} />
    </div>
  );
};

export { DevelopmentAreaPreview };
