import { FormRowStatus } from 'atoms/create-resource-atom';
import searchAtomPair from 'atoms/search-atom';
import { ComboBox, ComboBoxProps, Modal } from 'carbon-components-react';
import { EntityForm } from 'components/entity-form';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EntityResponse } from 'types/api-responses';
import { shouldSkipSearch } from 'utils/forms';
import style from './entity-autocomplete-with-creation-modal.module.scss';

type Props = Omit<ComboBoxProps<EntityResponse>, 'items'> & {
  onCreationSuccess: (entity: EntityResponse) => void;
  onChangeSuccess: (entity: EntityResponse | null | undefined) => void;
  status: FormRowStatus;
};

const EntityCreationModal = ({
  isOpen,
  onClose,
  onSuccess,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (entity: EntityResponse) => void;
}) => {
  return (
    <Modal
      open={isOpen}
      size="lg"
      modalHeading="Entity Creation"
      className={style.entityCreationModal}
      passiveModal
      onRequestClose={onClose}
    >
      {isOpen && (
        <EntityForm
          entity={undefined}
          loading={false}
          getEntity={undefined}
          onSuccess={onSuccess}
          showCancel={false}
        />
      )}
    </Modal>
  );
};

const EntityAutocompleteWithCreationModal = ({
  onCreationSuccess,
  onChangeSuccess,
  status,
  ...rest
}: Props) => {
  const [openEntityModal, setOpenEntityModal] = useState(false);
  const { searchAtom, resetSearchDataAtom } = useMemo(
    () => searchAtomPair<EntityResponse>('entities'),
    []
  );

  const [searchResults, searchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);

  const debouncedSearchPayload = useMemo(
    () => debounce(searchPayload, 400),
    [searchPayload]
  );

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const results = searchResults?.data?.results || [];

  return (
    <>
      <EntityCreationModal
        isOpen={openEntityModal}
        onClose={() => {
          setOpenEntityModal(false);
        }}
        onSuccess={(entity) => {
          onCreationSuccess(entity);
          setOpenEntityModal(false);
        }}
      />
      <ComboBox
        {...rest}
        items={results.concat([{ id: -1 } as EntityResponse])}
        placeholder=""
        itemToString={(data) => {
          return data && data.id !== -1 ? `${data.name}, ${data.id}` : '';
        }}
        itemToElement={(item) => {
          if (item.id === -1) {
            return <strong>Add New Entity</strong>;
          }

          return (
            <>
              {item.name}, {item.id}
            </>
          );
        }}
        onChange={({ selectedItem }) => {
          if (selectedItem && selectedItem.id === -1) {
            setOpenEntityModal(true);
            return;
          }
          onChangeSuccess(selectedItem);
        }}
        onInputChange={(text) => {
          if (shouldSkipSearch(firstRender.current, status)) return;
          resetSearchData(undefined);
          debouncedSearchPayload((current) => ({
            ...current,
            text,
            pageSize: 10,
          }));
        }}
        light
      />
    </>
  );
};

export { EntityAutocompleteWithCreationModal };
