export const mapColors = {
  surfaceHoleFill: '#D91B1B',
  bottomHoleFill: '#3BAB5A',
  stickFill: '#0F62FE',
  verticalFill: '#2E2E2E',
  surveyFill: '#001141',
  pricingFill: '#575757',
  unitsFill: '#08BDBA',
  typeCurvesFill: '#FA9494',
  basinFill: '#57E5E5',
  tierFill: '#F1C21B',

  scopedAssetsFill: '#3388FF',
  scopedAssetsBorder: '#006AFF',
  scopedTitleFill: '#A9BEC7',
  scopedTitleBorder: '#678597',
  scopedUnitsFill: '#B833FF',
  scopedUnitsBorder: '#A600FF',
  scopedVerticalFill: '#A68CA5',
  scopedVerticalBorder: '#906F8F',
  scopedHorizontalProFill: '#78BA83',
  scopedHorizontalProBorder: '#56A964',
  scopedHorizontalDucFill: '#515151',
  scopedHorizontalDucBorder: '#2F2F2F',
  scopedHorizontalPerFill: '#8DDB9A',
  scopedHorizontalPerBorder: '#61C772',
};
