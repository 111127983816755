import { Getter, atom, createStore } from 'jotai';
import request from 'lib/request';
import { ApiRootResponse } from 'types/api-responses';

type SessionAtomValue = ApiRootResponse['session'] | undefined;

const rootResourceAtomBase = atom<ApiRootResponse | undefined>(undefined);

export const rootStore = createStore();

const rootResourceAtom = atom(
  (get) => get(rootResourceAtomBase) || rootStore.get(rootResourceAtomBase),
  async (_get, set) => {
    const rootResponse = await request.get<ApiRootResponse>('/');
    if ('error' in rootResponse) throw new Error('Error while fetching root');
    set(rootResourceAtomBase, rootResponse);
    rootStore.set(rootResourceAtomBase, rootResponse);
  }
);
rootResourceAtom.onMount = (set) => {
  set();
};

const sessionResourceAtom = atom<SessionAtomValue, [SessionAtomValue], void>(
  (get) => {
    const root = get(rootResourceAtom);
    if (!root) return;
    return root?.session;
  },
  (_get, set) => {
    set(rootResourceAtom);
  }
);

const resourcesAtom = atom((get) => {
  const root = get(rootResourceAtom);
  if (!root) return;
  const resources = root.resources;
  if (!resources) return undefined;
  if ('sessions' in resources) {
    return undefined;
  }
  return resources;
});

const logoutResourcesAtom = atom((get) => {
  const root = get(rootResourceAtom);
  if (!root) return;

  const resources = root.resources;
  if (!resources) return undefined;
  if ('sessions' in resources) {
    return resources;
  }
});

const getResources = (get: Getter) => {
  return get(resourcesAtom);
};

export {
  getResources,
  logoutResourcesAtom,
  resourcesAtom,
  rootResourceAtom,
  sessionResourceAtom,
};
