import { Document24, Edit16, NewTab16 } from '@carbon/icons-react';
import { Button, InlineNotification } from 'carbon-components-react';
import { EditDocumentModal } from 'components/edit-document-modal';
import { PageHeader } from 'components/page-header';
import { FC, useReducer } from 'react';
import { Tabs } from './tabs';
import { DocumentResponse } from 'types/api-responses';
import { useLocation } from 'react-router-dom';
import { TitleWorkspaceReferrer } from 'components/title-workspace-referrer/title-workspace-referrer';
import { DocumentAttributes } from 'components/document-attributes';
import * as ProvisionedDocument from 'components/provisioned-document';
import { useSetAtom } from 'jotai';
import { documentProvisionStore, showProvisionedDocumentAtom } from './atoms';

const ActionButtons = ({
  document,
  getDocument,
}: {
  document: DocumentResponse;
  getDocument: () => Promise<void>;
}) => {
  const [openEdit, setOpenEdit] = useReducer((s) => !s, false);
  const setShowProvisionedDocument = useSetAtom(showProvisionedDocumentAtom);

  if (!document) {
    return null;
  }

  const imageHref = document.resources.image?.href;
  const completionsHref = document.resources.completions.href;
  const configurationHref = document.resources.completionsConfiguration.href;

  return (
    <>
      {imageHref && (
        <Button
          kind="tertiary"
          size="sm"
          renderIcon={NewTab16}
          href={imageHref}
          target="_blank"
        >
          <span>View Document Image</span>
        </Button>
      )}
      <ProvisionedDocument.AvailableProvisions
        href={completionsHref}
        configurationHref={configurationHref}
        onProvisionsAvailable={() => {
          setShowProvisionedDocument(true);
        }}
        store={documentProvisionStore}
        hasImage={!!imageHref}
      />
      {document.duplicate && (
        <InlineNotification
          kind="warning"
          lowContrast
          hideCloseButton
          title="Duplicate Document"
          subtitle="This document has been marked as duplicate and should not be used. Please use the valid copy of this document."
        />
      )}
      <Button
        kind="primary"
        size="sm"
        renderIcon={Edit16}
        onClick={() => {
          setOpenEdit();
        }}
      >
        <span>Edit Document</span>
      </Button>
      <EditDocumentModal
        document={document}
        isOpen={openEdit}
        onSave={() => getDocument()}
        onClose={setOpenEdit}
      />
    </>
  );
};

const DocumentHeader: FC<{
  document: DocumentResponse;
  getDocument: () => Promise<void>;
}> = ({ children, document, getDocument }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referrer = params.get('referrer');

  return (
    <>
      <PageHeader
        breadcrumbs={[
          ['/documents', 'Search'],
          ['', document.documentTitle],
        ]}
        title={document.documentTitle}
        actions={
          <ActionButtons document={document} getDocument={getDocument} />
        }
        iconComponent={<Document24 />}
        attributes={<DocumentAttributes document={document} />}
        referrer={
          referrer ? <TitleWorkspaceReferrer referrer={referrer} /> : null
        }
        stickyHeader
      >
        <Tabs document={document} />
      </PageHeader>
      <div>{children}</div>
    </>
  );
};

export { DocumentHeader };
