import {
  InterpretationReviewAttributes,
  InterpretationSectionsReviewed,
} from './types';
import reviewStatusTypes from 'enums/review_status_types.json';

const getReviewDetails = (reviewAttributes: InterpretationReviewAttributes) => {
  const { id, ...sectionsReviewed } = reviewAttributes;
  const { sectionsCount, totalReviewed } =
    getSectionsReviewed(sectionsReviewed);

  const reviewStatus =
    sectionsCount === totalReviewed
      ? reviewStatusTypes.reviewed
      : totalReviewed === 0
      ? reviewStatusTypes.pending
      : reviewStatusTypes.partial;

  return { id, sectionsCount, totalReviewed, reviewStatus };
};

const getSectionsReviewed = (
  sectionsReviewed: InterpretationSectionsReviewed
) => {
  const sectionKeys = Object.keys(sectionsReviewed);
  const sectionsCount = sectionKeys.length;
  const totalReviewed = sectionKeys.reduce((count: number, elem: string) => {
    return sectionsReviewed[elem as keyof InterpretationSectionsReviewed]
      ? ++count
      : count;
  }, 0);
  return { sectionsCount, totalReviewed };
};

export { getReviewDetails };
