import {
  createDeleteAtom,
  createMutationAtom,
  CreateResourceAtom,
} from 'atoms/create-resource-atom';
import searchAtomPair, { SearchParams } from 'atoms/search-atom';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import { ApiPayloads } from 'types/api-payloads';
import {
  AliasedEntityResponse,
  CapacityResponse,
  InventoryResponse,
  OwnershipResponse,
  PackagesFromEntityResponse,
} from 'types/api-responses';
import { PackageResponse } from 'types/packages-api/responses';

const aliasesMutationAtom = createMutationAtom<null, 'fromAliases'>();
const createAliasAtom = atom<
  CreateResourceAtom<null, ApiPayloads['fromAliases']['error']>,
  [
    {
      url: string;
      entityId: number;
      onSuccess: () => void;
      onError: (error?: ApiPayloads['fromAliases']['error']) => void;
    }
  ],
  void
>(
  (get) => get(aliasesMutationAtom),
  (get, set, { url, entityId, onSuccess, onError }) => {
    set(aliasesMutationAtom, {
      url,
      type: 'POST',
      data: {
        toId: entityId,
      },
      onSuccess: () => {
        onSuccess();
      },
      onError: (errors) => {
        onError(errors);
      },
    });
  }
);

const deleteAliasAtom = createDeleteAtom();
const aliasCreatedAtom = atomWithReset(false);

export type AliasesAtom = ReturnType<typeof useAliasedAtom>;

const useCapacitiesAtom = (href: string) =>
  useMemo(() => createResourceAtom<CapacityResponse[]>(href), [href]);
const useAssetsAtom = (href: string) =>
  useMemo(() => createResourceAtom<OwnershipResponse>(href), [href]);
const useAliasedAtom = (href: string) =>
  useMemo(() => createResourceAtom<AliasedEntityResponse[]>(href), [href]);
const usePackagesAtom = (href: string) =>
  useMemo(() => createResourceAtom<PackagesFromEntityResponse[]>(href), [href]);
const DEFAULT_PAGE_SIZE = 60;

type InventorySearchParams = {
  asOfDate?: string;
  includeChildren?: boolean;
} & SearchParams;

const useSearchInventoryAtom = (href: string, todayDate: string) => {
  return useMemo(() => {
    return searchAtomPair<InventoryResponse, InventorySearchParams>(
      { href },
      {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        asOfDate: todayDate,
        includeChildren: true,
      }
    );
  }, [href, todayDate]);
};

const entityPackageMutationAtom = createMutationAtom<
  PackageResponse,
  'entityPackage'
>();

export {
  DEFAULT_PAGE_SIZE,
  aliasCreatedAtom,
  createAliasAtom,
  deleteAliasAtom,
  entityPackageMutationAtom,
  useAliasedAtom,
  useAssetsAtom,
  useCapacitiesAtom,
  usePackagesAtom,
  useSearchInventoryAtom,
};
