import { atom } from 'jotai';
import request from 'lib/request';
import {
  MapSourcesResponse,
  ReferenceLocationBucket,
  ReferenceLocationResponse,
} from 'types/api-responses';
import { LocationAtom, MapUserConfig } from './types';
import { getQuarteredGeometry } from './utils';
import { getResources } from 'atoms/root';
import { CacheMapEntry } from 'lib/atoms';

const hoveredLocationsAtom = atom<number[]>([]);

const createAbortableLocationsAtom = (
  buckets?: (ReferenceLocationBucket & { count: number })[]
) => {
  const abortableAtom = atom<LocationAtom[]>([]);

  abortableAtom.onMount = (setAtom) => {
    const abortController = new AbortController();
    const referenceLocationAttrs = buckets?.map((b) => ({
      href: b.href,
      count: b.count,
    }));

    const promises = referenceLocationAttrs?.map(({ href, count }) =>
      request
        .get<ReferenceLocationResponse & { count: number }>(
          href,
          abortController
        )
        .then((el) => {
          if ('error' in el) return el;

          el['count'] = count;
          return el;
        })
    );

    const asyncAtoms = promises ? promises.map((promise) => atom(promise)) : [];

    setAtom(asyncAtoms);
    return () => abortController.abort();
  };
  return abortableAtom;
};

type Points = [[number, number], [number, number]];
const createBoundingBoxAtom = (
  locationIds: number[],
  href: string | undefined
) => {
  const boundingBoxAtom = atom<Points>([
    [0, 0],
    [0, 0],
  ]);

  boundingBoxAtom.onMount = (setAtom) => {
    const abortController = new AbortController();
    if (!href) return;
    const getBoundingBox = async () => {
      const response = await request.post<
        { points: Points },
        { referenceLocationIds: number[] }
      >(href, { referenceLocationIds: locationIds }, abortController);
      if (response.type === 'success') {
        setAtom(response.data.points);
      }
    };

    getBoundingBox();

    return () => abortController.abort();
  };

  return boundingBoxAtom;
};

const createAbortableQuarteredGeometryAtom = (
  location: ReferenceLocationResponse | null,
  quarterCalls: string[],
  href: string | undefined
) => {
  const abortableAtom = atom<GeoJSON.Geometry | undefined>(undefined);

  abortableAtom.onMount = (setAtom) => {
    const abortController = new AbortController();

    const getGeography = async () => {
      if (!location) return;
      if (!href) return;

      const result = await getQuarteredGeometry(
        location.id,
        quarterCalls,
        href,
        abortController
      );

      if (result.type === 'success') {
        setAtom(result.data.geometry);
      }
    };

    getGeography();
    return () => abortController.abort();
  };
  return abortableAtom;
};

let mapSourcesAtomPromise: Promise<CacheMapEntry<MapSourcesResponse>['data']>;
const createMapSourceAtom = (source: keyof MapSourcesResponse) => {
  const sourceAtomBase = atom<string | undefined>(undefined);
  const sourceAtom = atom(
    (get) => get(sourceAtomBase),
    async (get, set) => {
      const resources = getResources(get);
      const mapSourcesHref = resources?.maps.sources.href;
      if (!mapSourcesHref) return;
      if (!mapSourcesAtomPromise) {
        mapSourcesAtomPromise = request.get<MapSourcesResponse>(mapSourcesHref);
      }
      const response = await mapSourcesAtomPromise;
      if (!response || 'error' in response) return;
      set(sourceAtomBase, response[source]);
    }
  );
  sourceAtom.onMount = (set) => {
    set();
  };

  return sourceAtom;
};

const mapDefaultConfig: MapUserConfig = {
  wells: {
    checked: false,
    options: {
      surfaceHole: false,
      bottomHole: false,
      stick: false,
      survey: false,
      vertical: false,
    },
  },
  developmentAreas: {
    checked: false,
    options: {
      units: false,
      pricing: false,
    },
  },
  basinTier: {
    checked: false,
    options: {
      basin: false,
      tier: false,
    },
  },
  scrollZoom: {
    checked: false,
  },
  popups: {
    checked: true,
  },
};

const getMapDefaultConfig = (
  configOverrides?: Partial<MapUserConfig>
): MapUserConfig => {
  return {
    ...mapDefaultConfig,
    ...configOverrides,
  };
};

export {
  createAbortableLocationsAtom,
  createAbortableQuarteredGeometryAtom,
  createBoundingBoxAtom,
  createMapSourceAtom,
  getMapDefaultConfig,
  hoveredLocationsAtom,
};
