import { Button, InlineNotification } from 'carbon-components-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  CalculationTractResponse,
  CalculationWellIntersectionResponse,
  TitleCalculationResponse,
} from 'types/api-responses';
import { Tract } from './tract';

import style from '../calculation.module.scss';
import {
  selectedAssetIndexAtom,
  subtractsAtom,
  tractsMapAtom,
  wellFeaturesMapAtom,
  wellIntersectionsAtom,
  wellIntersectionsMapAtom,
} from './atoms';
import { WellIntersection } from './well-intersection';
import { calculationsMapAtom, lastCalculationAtom } from '../atoms';

const SubTractsList = () => {
  const subtracts = useAtomValue(subtractsAtom);

  return (
    <>
      <div className={style.assetSection}>Tracts</div>
      <ul className={style.subTractList}>
        {subtracts.map((tract, index: number) => (
          <li key={index}>
            <SubTractButton tract={tract} idx={index} />
          </li>
        ))}
      </ul>
    </>
  );
};

const SubTractButton = ({
  tract,
  idx,
}: {
  tract: CalculationTractResponse;
  idx: number;
}) => {
  const [selectedTract, setSelectedAsset] = useAtom(selectedAssetIndexAtom);
  const setTract = useSetAtom(tractsMapAtom);

  const isSelected =
    selectedTract.asset === 'Subtract' && selectedTract.index === idx;

  // We need the safe navigation operator here to work around an issue in the
  // test environment where the Carbon inline-edit component does not properly
  // provide its value to the onChange handler.
  const tractName = tract.name?.toUpperCase();

  return (
    <Button
      kind="ghost"
      size="sm"
      onClick={() => {
        setSelectedAsset({ asset: 'Subtract', index: idx });
        setTract({ href: tract.href });
      }}
      className={isSelected ? style.btnIsSelected : null}
    >
      {tractName}
    </Button>
  );
};

const WellsList = () => {
  const wellIntersections = useAtomValue(wellIntersectionsAtom);
  if (!wellIntersections.length) {
    return null;
  }
  return (
    <>
      <div className={style.assetSection}>Wells</div>
      <ul className={style.subTractList}>
        {wellIntersections.map((wellIntersection, index) => (
          <li key={wellIntersection.href} className={style.wellItem}>
            <WellIntersectionButton
              wellIntersection={wellIntersection}
              idx={index}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

const WellIntersectionButton = ({
  wellIntersection,
  idx,
}: {
  wellIntersection: CalculationWellIntersectionResponse;
  idx: number;
}) => {
  const [selectedTract, setSelectedAsset] = useAtom(selectedAssetIndexAtom);
  const setWellIntersection = useSetAtom(wellIntersectionsMapAtom);
  const setWellFeatures = useSetAtom(wellFeaturesMapAtom);
  const wellIntersections = useAtomValue(wellIntersectionsAtom);

  const isSelected =
    selectedTract.asset === 'WellIntersection' &&
    wellIntersections[selectedTract.index].id === wellIntersection.id;

  return (
    <Button
      kind="ghost"
      onClick={() => {
        setSelectedAsset({ asset: 'WellIntersection', index: idx });
        setWellIntersection({
          href: wellIntersection.href,
        });
        if (!wellIntersection.well.deleted) {
          setWellFeatures({
            href: wellIntersection.well.resources.features.href,
          });
        }
      }}
      className={isSelected ? style.btnIsSelected : null}
      disabled={wellIntersections[idx].well.deleted}
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div
        className={
          wellIntersections[idx].well.deleted
            ? style.deletedWell
            : style.wellButtonContent
        }
      >
        <span className={style.wellName}>
          {wellIntersections[idx].well.name}
        </span>

        <span className={style.uwi}>
          UWI: {wellIntersections[idx].well.uwi}
        </span>
      </div>
    </Button>
  );
};
const SelectedAssetImpl = ({
  calculation,
}: {
  calculation: TitleCalculationResponse;
}) => {
  const setCalculationMap = useSetAtom(calculationsMapAtom);
  const selectedAsset = useAtomValue(selectedAssetIndexAtom);
  const subtracts = useAtomValue(subtractsAtom);

  if (selectedAsset.asset === 'Subtract') {
    const selectedTract = subtracts[selectedAsset.index];

    return (
      <Tract
        tractHref={selectedTract.href}
        id={String(selectedTract.id)}
        onUpdate={() => {
          setCalculationMap({
            href: calculation.href,
            force: true,
          });
        }}
      />
    );
  }

  if (selectedAsset.asset === 'WellIntersection') {
    const selectedWellIntersection =
      calculation.wellIntersections[selectedAsset.index];
    return (
      <WellIntersection
        href={selectedWellIntersection.href}
        calculation={calculation}
      />
    );
  }

  throw new Error('Invalid asset type');
};

const SelectedAsset = () => {
  const calculation = useAtomValue(lastCalculationAtom);
  if (!calculation) return null;
  if (calculation.data && 'error' in calculation.data)
    return (
      <InlineNotification
        kind="error"
        title={calculation.data.error}
        lowContrast
      />
    );
  if (!calculation.data) return null;

  return <SelectedAssetImpl calculation={calculation.data} />;
};

export { SubTractsList, WellsList, SelectedAsset };
