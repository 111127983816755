import { PageHeader } from 'components/page-header';
import { InterpretationsSearch } from 'components/interpretation-search';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';

const Header = () => {
  return (
    <PageHeader
      breadcrumbs={[]}
      title={'Global Interpretations'}
      actions={null}
    ></PageHeader>
  );
};

const GlobalInterpretationsPage = () => {
  return (
    <>
      <Helmet>
        <title>{pageTitle('Global Interpretations')}</title>
      </Helmet>
      <Header />
      <InterpretationsSearch />
    </>
  );
};

export { GlobalInterpretationsPage };
