import { Settings16, Warning16 } from '@carbon/icons-react';
import {
  ColumnDef,
  ColumnPinningState,
  Row,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  Button,
  DataTableSkeleton,
  InlineLoading,
  InlineNotification,
  Modal,
  Tile,
  TooltipDefinition,
} from 'carbon-components-react';
import { DebouncedInput } from 'components/debounced-input';
import { EmptyState } from 'components/empty-state';
import { Link } from 'components/link';
import { MIN_WIDTH } from 'components/table/column-actions';
import { ColumnFilter } from 'components/table/column-filter';
import { HiddenColumns } from 'components/table/hidden-columns';
import { TableActions } from 'components/table/table-actions';
import DevAreaSvg from 'images/srp-development-areas.svg';
import { useAtomValue } from 'jotai';
import { useColumnFilter } from 'lib/hooks/useColumnFilter';
import { useColumnSizing } from 'lib/hooks/useColumnSizing';
import { useColumnVisibility } from 'lib/hooks/useColumnVisibilityAtom';
import {
  DECIMAL_FORMATTER,
  FORMATTER,
  PERCENT_FORMATTER_TWO_DECIMALS,
} from 'lib/ui';
import { Slots } from 'pages/development-areas/show/evaluation-form/forms/slots';
import plur from 'plur';
import { useCallback, useMemo, useRef, useState } from 'react';
import { PackageEvaluationAllocationResponse } from 'types/packages-api/responses';
import { capitalizeFirstLetter } from 'utils/strings';
import { LoadPackage } from '../page';
import { useCreateEvaluationAllocationsAtom, useSlotsAtom } from './atoms';
import style from './units.module.scss';
import {
  getInitialColumnOrder,
  getPinningStyles,
  handleDragEnd,
  handleDragMove,
} from './utils';

import { DndContext, DragMoveEvent, closestCenter } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useColumnState } from 'lib/hooks/useColumnState';
import usePinnedColumnReordering from 'lib/hooks/usePinnedColumnReordering';
import { DragCell, DragHeader } from './draggable-table-components';

const columnHelper = createColumnHelper<PackageEvaluationAllocationResponse>();

const overAllocated = (decimal: number) => {
  return decimal > 1.01;
};

const underAllocated = (decimal: number) => {
  return decimal < 0.99;
};

const UnitsAllocations = ({ href }: { href: string }) => {
  const allocationsAtom = useCreateEvaluationAllocationsAtom(href);
  const allocations = useAtomValue(allocationsAtom);
  const [selectedAsset, setSelectedAsset] = useState<
    SelectedAsset | undefined
  >();

  if (allocations.data && 'error' in allocations.data)
    return (
      <InlineNotification
        kind="error"
        title={allocations.data.error}
        lowContrast
      />
    );

  const allocationsData = allocations.data?.map((el, i) => {
    return {
      id: String(i),
      ...el,
    };
  });

  if (!allocationsData) return <InlineLoading />;

  const columns = [
    columnHelper.display({
      id: 'slots',
      header: 'Slots',
      size: 125,
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        const asset = row.original.asset;
        const devArea = row.original.developmentArea;

        return (
          <>
            <Button
              hasIconOnly
              size="sm"
              kind="ghost"
              renderIcon={Settings16}
              iconDescription="See slots"
              tooltipPosition="right"
              onClick={() => {
                setSelectedAsset({
                  slotsHref: asset.slotsHref,
                  assetName: asset.name,
                  developmentAreaName: devArea.name,
                });
              }}
            />
          </>
        );
      },
      footer: () => {
        return 'Totals';
      },
    }),
    columnHelper.accessor((row) => row.asset.name, {
      id: 'assetName',
      header: 'Asset',
      size: 125,
      cell: ({ row }) => {
        const value = row.original.asset;
        return value.salesforceHref ? (
          <a
            style={{ cursor: 'pointer' }}
            rel="noreferrer"
            href={value.salesforceHref}
            target="_blank"
          >
            {value.name || '--'}
          </a>
        ) : (
          value.name || '--'
        );
      },
    }),
    columnHelper.accessor((row) => row.subtract.name, {
      id: 'subtractName',
      header: 'Subtract',
      size: 125,
      cell: ({ row }) => {
        const value = row.original.subtract;
        return value.salesforceHref ? (
          <a
            style={{ cursor: 'pointer' }}
            rel="noreferrer"
            href={value.salesforceHref}
            target="_blank"
          >
            {value.name || '--'}
          </a>
        ) : (
          value.name || '--'
        );
      },
    }),
    columnHelper.accessor(
      (row) => {
        return `${row.developmentArea.name || '--'} `;
      },
      {
        id: 'unitEvaluationName',
        header: 'Unit Evaluation',
        size: 205,
        getGroupingValue(row) {
          return row.developmentArea.name;
        },
        cell: ({ row }) => {
          const evaluation = row.original.developmentAreaEvaluation;
          const name = `${row.getValue('unitEvaluationName')} (${
            evaluation ? evaluation.id : 'Error running eval'
          })`;
          return evaluation?.href ? (
            <Link to={evaluation.href} target="_blank">
              {name}
            </Link>
          ) : (
            name
          );
        },
      }
    ),
    columnHelper.accessor((row) => row.inventoryAsset, {
      id: 'inventoryAsset',
      header: 'Inventory Asset',
      cell: ({ row }) => {
        const value = row.original.inventoryAsset;
        return value?.salesforceHref ? (
          <a
            style={{ cursor: 'pointer' }}
            rel="noreferrer"
            href={value.salesforceHref}
            target="_blank"
          >
            {value.name || '--'}
          </a>
        ) : (
          value?.name || '--'
        );
      },
      filterFn: (row, _columnId, filterValue) => {
        const inventoryAsset = row.original.inventoryAsset;
        if (filterValue && inventoryAsset?.name) {
          return inventoryAsset.name
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        } else {
          return false;
        }
      },
    }),
    columnHelper.accessor((row) => row.operator.name, {
      id: 'operatorName',
      header: 'Operator',
      size: 165,
      cell: ({ row }) => {
        const value = row.original.operator;
        return value.salesforceHref ? (
          <a
            style={{ cursor: 'pointer' }}
            rel="noreferrer"
            href={value.salesforceHref}
            target="_blank"
          >
            {value.name || '--'}
          </a>
        ) : (
          value.name || '--'
        );
      },
      filterFn: (row, _columnId, filterValue) => {
        const operator = row.original.operator;
        if (filterValue && operator.name) {
          return operator.name
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        } else {
          return false;
        }
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.unitType, {
      id: 'unitType',
      header: 'Unit Type',
      size: 135,
    }),
    columnHelper.accessor((row) => row.allocationRatio, {
      id: 'allocationRatio',
      header: 'Allocation Percent',
      size: 165,
      cell: (row) => {
        const v = row.getValue();
        return v !== null ? PERCENT_FORMATTER_TWO_DECIMALS.format(v) : '--';
      },
      meta: {
        filterVariant: 'range',
      },
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.totalAllocationRatio, {
      id: 'totalAllocationRatio',
      header: 'Warning',
      size: 205,
      cell: ({ row }) => {
        const value = row.original.totalAllocationRatio;
        return (
          <>
            {overAllocated(value) &&
              `Over Allocated (${PERCENT_FORMATTER_TWO_DECIMALS.format(
                value
              )})`}
            {underAllocated(value) &&
              `Under Allocated (${PERCENT_FORMATTER_TWO_DECIMALS.format(
                value
              )})`}

            {overAllocated(value) || underAllocated(value) ? (
              <TooltipDefinition
                direction="top"
                align="end"
                tooltipText="Cannot determine development area coverage."
              >
                <Warning16 />
              </TooltipDefinition>
            ) : (
              '--'
            )}
          </>
        );
      },
      meta: {
        filterVariant: 'range',
      },
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.instrumentType, {
      id: 'instrumentType',
      header: 'Instrument Type',
      size: 165,
      cell: (row) => {
        const value = row.getValue();
        return value ? capitalizeFirstLetter(value) : null;
      },
    }),
    columnHelper.accessor((row) => row.royaltyRate, {
      id: 'royaltyRate',
      header: 'Royalty Rate',
      size: 135,
      cell: (row) => {
        const v = row.getValue();
        return v !== null ? PERCENT_FORMATTER_TWO_DECIMALS.format(v) : '--';
      },
      meta: {
        filterVariant: 'range',
      },
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.overridingRoyaltyRate, {
      id: 'overridingRoyaltyRate',
      header: 'ORRI',
      size: 135,
      cell: (row) => {
        const v = row.getValue();
        return v !== null ? PERCENT_FORMATTER_TWO_DECIMALS.format(v) : '--';
      },
      meta: {
        filterVariant: 'range',
      },
    }),
    columnHelper.accessor((row) => row.interestType, {
      id: 'interestType',
      header: 'Interest Type',
      size: 135,
      cell: (row) => {
        const value = row.getValue();
        return value ? capitalizeFirstLetter(value) : null;
      },
    }),
    columnHelper.accessor((row) => row.netAcreage, {
      id: 'netAcreage',
      header: 'Acreage',
      size: 135,
      cell: ({ row }) => {
        const value = row.original.netAcreage;
        const units = row.original.netAcreageUnits;
        return value !== null && value !== undefined
          ? value.toFixed(2) + ' ' + units
          : '--';
      },
      meta: {
        filterVariant: 'range',
      },
    }),
    columnHelper.accessor('targetPricePerAcre', {
      id: 'targetPricePerAcre',
      header: 'Target $/Acre',
      enableGlobalFilter: false,
      cell: (row) => {
        const v = row.getValue();
        return v ? FORMATTER.format(v) : '$0';
      },
      meta: {
        filterVariant: 'range',
      },
    }),
    columnHelper.accessor('targetPrice', {
      id: 'targetPrice',
      header: 'Target Price',
      enableGlobalFilter: false,
      aggregatedCell(props) {
        const v = props.getValue();
        return v !== null ? FORMATTER.format(v) : `$0`;
      },
      cell: (row) => {
        const value = row.getValue();
        return FORMATTER.format(value);
      },
      footer: ({ table }) => {
        return FORMATTER.format(
          table
            .getFilteredRowModel()
            .rows.reduce((total, row) => total + row.original.targetPrice, 0)
        );
      },
      meta: {
        filterVariant: 'range',
      },
    }),
    columnHelper.accessor('maxPricePerAcre', {
      id: 'maxPricePerAcre',
      header: 'Max $/Acre',
      enableGlobalFilter: false,
      cell: (row) => {
        const v = row.getValue();
        return v ? FORMATTER.format(v) : '$0';
      },
      meta: {
        filterVariant: 'range',
      },
    }),
    columnHelper.accessor('maxPrice', {
      id: 'maxPrice',
      header: 'Max Price',
      enableGlobalFilter: false,
      aggregatedCell(props) {
        const v = props.getValue();
        return v !== null ? FORMATTER.format(v) : `$0`;
      },
      cell: (row) => {
        const value = row.getValue();
        return FORMATTER.format(value);
      },
      footer: ({ table }) => {
        return FORMATTER.format(
          table
            .getFilteredRowModel()
            .rows.reduce((total, row) => total + row.original.maxPrice, 0)
        );
      },
      meta: {
        filterVariant: 'range',
      },
    }),
  ];

  return (
    <>
      <SlotsModal
        selectedAsset={selectedAsset}
        onClose={() => setSelectedAsset(undefined)}
      />
      <UnitsAllocationTable data={allocationsData} columns={columns} />
    </>
  );
};

const UnitsAllocationTable = ({
  data,
  columns,
}: {
  data: PackageEvaluationAllocationResponse[];
  columns: ColumnDef<PackageEvaluationAllocationResponse, any>[];
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const unitEvaluationsTableId = 'package-unit-allocations-table';
  const [columnVisibility, setColumnVisibility] = useColumnVisibility(
    unitEvaluationsTableId
  );
  const [columnSizing, setColumnSizing] = useColumnSizing(
    unitEvaluationsTableId
  );
  const initialColumnPinning: ColumnPinningState = {
    left: ['slots', 'assetName', 'subtractName'],
  };

  const initialColumnOrder = useMemo(
    () => getInitialColumnOrder(columns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { columnOrder, setColumnOrder, columnPinning, setColumnPinning } =
    useColumnState({
      tableId: unitEvaluationsTableId,
      initialColumnOrder,
      initialColumnPinning,
    });

  const {
    columnFilters,
    setColumnFilters,
    isColumnFilterActive,
    setIsColumnFilterActive,
  } = useColumnFilter(unitEvaluationsTableId);

  const hoveredColumnIdRef = useRef<string | null>(null);

  usePinnedColumnReordering(columnPinning, columnOrder, setColumnOrder);

  const onDragMove = useCallback(
    (e: DragMoveEvent) => {
      handleDragMove(e, hoveredColumnIdRef);
    },
    [hoveredColumnIdRef]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    columnResizeMode: 'onChange',
    defaultColumn: {
      minSize: MIN_WIDTH,
    },
    state: {
      sorting,
      globalFilter,
      columnVisibility,
      columnSizing,
      columnFilters,
      columnOrder,
      columnPinning,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onColumnSizingChange: setColumnSizing,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    initialState: {
      columnPinning: {
        left: ['slots', 'assetName', 'subtractName'],
      },
      columnOrder: initialColumnOrder,
    },
    meta: {
      reset: {
        resetCustomizations: () => {
          setIsColumnFilterActive(false);
        },
      },
    },
  });

  const { rows } = table.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 20,
  });

  if (!data) {
    return (
      <DataTableSkeleton
        showHeader={false}
        columnCount={columns.length}
        rowCount={10}
        showToolbar={false}
      />
    );
  }

  const isColumunVisible = table.getVisibleLeafColumns().length > 0;

  return (
    <LoadPackage
      render={() => (
        <>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={(e) =>
              handleDragEnd(
                e,
                columnPinning,
                columnOrder,
                setColumnOrder,
                setColumnPinning,
                hoveredColumnIdRef
              )
            }
            modifiers={[restrictToHorizontalAxis]}
            onDragMove={onDragMove}
          >
            <div ref={parentRef} className={style.stackTableArea}>
              <div className={style.tableContext}>
                <div className={style.stackSearchArea}>
                  <DebouncedInput
                    className={style.stackSearchField}
                    value={globalFilter ?? ''}
                    onChange={(value) => setGlobalFilter(String(value).trim())}
                    placeholder="Search columns..."
                  />
                </div>
                <TableActions
                  table={table}
                  isColumnFilterActive={isColumnFilterActive}
                  setIsColumnFilterActive={(isColumnFilterActive) =>
                    setIsColumnFilterActive(isColumnFilterActive)
                  }
                ></TableActions>
              </div>
              {isColumunVisible ? (
                <table className={style.stackTable}>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <>
                        <tr key={headerGroup.id}>
                          <SortableContext
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                          >
                            {headerGroup.headers.map((header) => (
                              <DragHeader
                                key={header.id}
                                header={header}
                                hoveredColumnIdRef={hoveredColumnIdRef}
                              />
                            ))}
                          </SortableContext>
                        </tr>
                        {isColumnFilterActive ? (
                          <tr>
                            <ColumnFilter
                              pinStyleGenerator={getPinningStyles}
                              headers={headerGroup.headers}
                            ></ColumnFilter>
                          </tr>
                        ) : null}
                      </>
                    ))}
                  </thead>
                  <tbody
                    style={{ minHeight: `${virtualizer.getTotalSize()}px` }}
                  >
                    {virtualizer.getVirtualItems().map((virtualRow, index) => {
                      const row = rows[
                        virtualRow.index
                      ] as Row<PackageEvaluationAllocationResponse>;
                      return (
                        <tr
                          key={row.id}
                          style={{
                            minHeight: `${virtualRow.size}px`,
                            transform: `translateY(${
                              virtualRow.start - index * virtualRow.size
                            }px)`,
                          }}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <SortableContext
                              key={cell.id}
                              items={columnOrder}
                              strategy={horizontalListSortingStrategy}
                            >
                              <DragCell
                                key={cell.id}
                                cell={cell}
                                row={row}
                                hoveredColumnIdRef={hoveredColumnIdRef}
                              />
                            </SortableContext>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                      <>
                        <tr key={footerGroup.id}>
                          {footerGroup.headers.map((header) => (
                            <th
                              style={{
                                ...getPinningStyles(header.column),
                              }}
                              key={header.id}
                              colSpan={header.colSpan}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.footer,
                                    header.getContext()
                                  )}
                            </th>
                          ))}
                        </tr>
                      </>
                    ))}
                  </tfoot>
                </table>
              ) : (
                <HiddenColumns className={style.hiddenColumn}></HiddenColumns>
              )}
            </div>
            {isColumunVisible ? (
              <div className={style.totalRowCount}>
                <label className={style.totalRows}>
                  {DECIMAL_FORMATTER.format(
                    table.getFilteredRowModel().rows.length
                  )}
                </label>
                {plur('row', table.getFilteredRowModel().rows.length)}
              </div>
            ) : null}
          </DndContext>
        </>
      )}
    />
  );
};

type SelectedAsset = {
  slotsHref: string;
  assetName: string | null;
  developmentAreaName: string | null;
};

const SlotSelectionContent = ({ slotsHref }: { slotsHref: string }) => {
  const slotsAtom = useSlotsAtom(slotsHref);
  const slots = useAtomValue(slotsAtom);

  if (slots.data && 'error' in slots.data)
    return (
      <InlineNotification kind="error" title={slots.data.error} lowContrast />
    );

  if (slots.loading)
    return (
      <InlineLoading
        className={style.paddingLoading}
        description="Loading slots..."
      />
    );
  if (!slots.data?.slots.length)
    return (
      <Tile light>
        <EmptyState
          icon={DevAreaSvg}
          headerText="No slots available for this development area"
        />
      </Tile>
    );

  return (
    <div>
      <Slots
        evalSlots={slots.data?.slots}
        excludedSlots={slots.data?.excludedSlots}
      />
    </div>
  );
};

const SlotsModal = ({
  onClose,
  selectedAsset,
}: {
  onClose: () => void;
  selectedAsset: SelectedAsset | undefined;
}) => {
  return (
    <Modal
      open={!!selectedAsset}
      size="lg"
      className={style.slotsModal}
      modalHeading={`Slots for ${selectedAsset?.assetName} (${selectedAsset?.developmentAreaName})`}
      onRequestSubmit={onClose}
      onRequestClose={onClose}
      passiveModal
    >
      {selectedAsset && (
        <SlotSelectionContent slotsHref={selectedAsset.slotsHref} />
      )}
    </Modal>
  );
};

export { UnitsAllocations };
