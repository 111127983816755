import { useAtomValue } from 'jotai';
import {
  DataTable,
  DataTableCustomRenderProps,
  DataTableSkeleton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  InlineNotification,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from 'carbon-components-react';
import {
  itemRangeText,
  pageRangeText,
} from 'components/search/common/format-helpers';
import {
  DECIMAL_FORMATTER,
  FORMATTER,
  PERCENT_FORMATTER_TWO_DECIMALS,
} from 'lib/ui';
import { useCallback, useState } from 'react';

import style from './assets.module.scss';
import classNames from 'classnames';
import { getTotalCells } from './utils';
import { Date } from 'components/date';
import { useCreateEvaluationWellsAtom } from './atoms';
import { Checkmark16 } from '@carbon/icons-react';
import { Link, PlainLink } from 'components/link';

const PAGE_SIZES = [
  {
    text: '2,000',
    value: '2000',
  },
  {
    text: '3,000',
    value: '3000',
  },
  {
    text: '4,000',
    value: '4000',
  },
];

const priceFormat = (v: unknown) => {
  if (!(typeof v === 'number')) return;
  return FORMATTER.format(v);
};

const percentageFormat = (v: unknown) => {
  if (typeof v !== 'number') return '--';
  return PERCENT_FORMATTER_TWO_DECIMALS.format(v);
};

const numberFormat = (v: unknown) => {
  if (typeof v === 'number') {
    return v.toFixed(2);
  }
  return `--`;
};

const headersList = [
  { key: 'uwi', header: 'UWI' },
  { key: 'wellName', header: 'Well Name' },
  { key: 'operatorName', header: 'Operator' },
  { key: 'developmentAreaName', header: 'Development Area' },
  { key: 'basin', header: 'Basin' },
  { key: 'tier', header: 'Tier' },
  { key: 'formationName', header: 'Formation Name' },
  { key: 'slotNumber', header: 'Slot Number' },
  { key: 'slotCategory', header: 'Slot Category' },
  { key: 'netWorkingInterest', header: 'WI (%)', format: percentageFormat },
  { key: 'netRevenueInterest', header: 'NRI (%)', format: percentageFormat },
  {
    key: 'wellProducingLength',
    header: 'Well Producing Length',
    format: numberFormat,
  },
  { key: 'oilEurMbbl', header: 'Oil EUR (Mbbl)', format: numberFormat },
  { key: 'gasEurMmcf', header: 'Gas EUR (Mmcf)', format: numberFormat },
  { key: 'wellPermitDate', header: 'Well Permit Date' },
  { key: 'spudDate', header: 'Spud Date' },
  { key: 'completionDate', header: 'Completion Date' },
  { key: 'firstProductionDate', header: 'First Production Date' },
  { key: 'shutInDate', header: 'Shut In Date' },
  { key: 'forecastStatus', header: 'Forecast Status' },
  { key: 'isExcluded', header: 'Excluded' },
  { key: 'filedWellOnly', header: 'Filed Well Only' },
  { key: 'slotVolumeSource', header: 'Slot Volume Source' },
  {
    key: 'developmentAreaGrossAcreage',
    header: 'Development Area Gross Acreage',
    format: numberFormat,
  },
  { key: 'allocatedNRA', header: 'Allocated NRA', format: numberFormat },
  { key: 'allocatedNMA', header: 'Allocated NMA', format: numberFormat },
  { key: 'typeCurveStatus', header: 'Type Curve Status' },
  { key: 'typeCurveAreaName', header: 'Type Curve Area Name' },
  { key: 'typeCurve', header: 'Type Curve Header' },
  { key: 'horizontalWellSpacingFt', header: 'Horizontal Well Spacing (Ft)' },
  { key: 'formationDepth', header: 'Formation Depth', format: numberFormat },
  { key: 'wellDepth', header: 'Well Depth', format: numberFormat },
  {
    key: 'wellProportionInDevArea',
    header: 'Well Proportion in Dev Area',
    format: numberFormat,
  },
  { key: 'unitType', header: 'Unit Type' },
  {
    key: 'irr',
    header: 'IRR (%)',
    format: percentageFormat,
  },
  {
    key: 'hurdleRate',
    header: 'Hurdle Rate (%)',
    format: percentageFormat,
  },
  {
    key: 'targetPriceInDollars',
    header: 'Target Price in Dollars',
    format: priceFormat,
  },
  {
    key: 'maxPriceInDollars',
    header: 'Max Price in Dollars',
    format: priceFormat,
  },
  {
    key: 'maxPriceInDollarsPerNra',
    header: 'Max Dollars Per NRA',
    format: priceFormat,
  },
  {
    key: 'maxPriceInDollarsPerNma',
    header: 'Max Dollars Per NMA',
    format: priceFormat,
  },
  {
    key: 'targetPriceInDollarsPerNra',
    header: 'Target Dollars Per NRA',
    format: priceFormat,
  },
  {
    key: 'targetPriceInDollarsPerNma',
    header: 'Target Dollars Per NMA',
    format: priceFormat,
  },
];

const totalsRowCells = (totals: {
  targetPrice: number;
  maxPrice: number;
  targetPricePerAcre: number;
  maxPricePerAcre: number;
}) => {
  const cellContentMapping = {
    uwi: (
      <TableCell key="totals" className={style.noSticky}>
        Totals
      </TableCell>
    ),
    targetPriceInDollars: (
      <TableCell key="targetPrice">
        {FORMATTER.format(totals.targetPrice)}
      </TableCell>
    ),
    maxPriceInDollars: (
      <TableCell key="maxPrice">{FORMATTER.format(totals.maxPrice)}</TableCell>
    ),
    maxDollarsPerAcre: (
      <TableCell key="maxPricePerAcre">
        {FORMATTER.format(totals.maxPricePerAcre)}
      </TableCell>
    ),
    targetDollarsPerAcre: (
      <TableCell key="targetPricePerAcre">
        {FORMATTER.format(totals.targetPricePerAcre)}
      </TableCell>
    ),
  };

  return getTotalCells(headersList, cellContentMapping, style.noSticky);
};

const Wells = ({ href }: { href: string }) => {
  const wellsAtom = useCreateEvaluationWellsAtom(href);
  const wells = useAtomValue(wellsAtom);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(2000);

  const onChangePagination = useCallback(
    (pageInfo: { page: number; pageSize: number }) => {
      if (page !== pageInfo.page) {
        setPage(pageInfo.page);
      }
      if (pageSize !== pageInfo.pageSize) {
        setPageSize(pageInfo.pageSize);
      }
    },
    [page, pageSize]
  );

  if (wells.data && 'error' in wells.data)
    return (
      <InlineNotification kind="error" title={wells.data.error} lowContrast />
    );

  const accumulatedTotals = {
    targetPrice: 0,
    maxPrice: 0,
    acreage: 0,
  };

  const data = wells.data;
  const augmentedRows = data?.results.map((el, i) => {
    accumulatedTotals.targetPrice += el.targetPriceInDollars || 0;
    accumulatedTotals.maxPrice += el.maxPriceInDollars || 0;

    return {
      id: String(i),
      ...el,
      targetPriceInDollars: el.targetPriceInDollars || 0,
      maxPriceInDollars: el.maxPriceInDollars || 0,
      developmentAreaName: el.developmentArea.name,
      operatorName: el.operator?.name,
      uwi: el.well?.uwi || '--',
      wellName: el.well?.name || '--',
    };
  });

  const countBySlotCategory = data?.countBySlotCategory;

  const totals = {
    ...accumulatedTotals,
    maxPricePerAcre:
      accumulatedTotals.acreage === 0
        ? 0
        : accumulatedTotals.maxPrice / accumulatedTotals.acreage,
    targetPricePerAcre:
      accumulatedTotals.acreage === 0
        ? 0
        : accumulatedTotals.targetPrice / accumulatedTotals.acreage,
  };

  if (!augmentedRows)
    return (
      <DataTableSkeleton
        showHeader={false}
        columnCount={headersList.length}
        rowCount={10}
        headers={headersList}
        showToolbar={false}
      />
    );

  return (
    <div>
      {countBySlotCategory && (
        <div className={style.summaryArea}>
          <div className={style.summaryData}>
            <legend>Count By Slot Category</legend>
            <dl>
              <dt>PDP</dt>
              <dd>{DECIMAL_FORMATTER.format(countBySlotCategory['PDP'])}</dd>
            </dl>
            <dl>
              <dt>DUC</dt>
              <dd>{DECIMAL_FORMATTER.format(countBySlotCategory['DUC'])}</dd>
            </dl>
            <dl>
              <dt>Permit</dt>
              <dd>{DECIMAL_FORMATTER.format(countBySlotCategory['Permit'])}</dd>
            </dl>
            <dl>
              <dt>LTD</dt>
              <dd>{DECIMAL_FORMATTER.format(countBySlotCategory['LTD'])}</dd>
            </dl>
          </div>
        </div>
      )}
      <DataTable rows={augmentedRows} headers={headersList} isSortable>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          onInputChange,
        }: DataTableCustomRenderProps) => (
          <TableContainer className={style.overflowAuto}>
            <TableToolbar>
              <TableToolbarContent>
                <TableToolbarSearch onChange={onInputChange} expanded />
              </TableToolbarContent>
            </TableToolbar>
            <Table
              {...getTableProps()}
              className={classNames([
                style.assetsTable,
                style.firstTwoStickyColumns,
              ])}
            >
              <TableHead>
                <TableRow>
                  {headers.map((header) => {
                    return (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        key={header.key}
                        className={style.stickyHeader}
                      >
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice((page - 1) * pageSize)
                  .slice(0, pageSize)
                  .map((row, rowIndex) => {
                    return (
                      <TableRow {...getRowProps({ row })} key={row.id}>
                        {row.cells.map((cell, cellIndex) => {
                          const cellKey =
                            cell.id + '_' + rowIndex + '_' + cellIndex;
                          const augmentedRowIndex = Number(
                            cell.id.replace(/\D/g, '')
                          );

                          const cellHeader = cell.info.header;
                          const currentRow = augmentedRows[augmentedRowIndex];

                          switch (cellHeader) {
                            case 'uwi':
                              return (
                                <TableCell key={cellKey}>
                                  {currentRow.well ? (
                                    <PlainLink
                                      target="_blank"
                                      rel="noreferrer"
                                      href={currentRow.well.href}
                                    >
                                      {currentRow.well.uwi}
                                    </PlainLink>
                                  ) : (
                                    '--'
                                  )}
                                </TableCell>
                              );
                            case 'wellName':
                              return (
                                <TableCell key={cellKey}>
                                  {currentRow.well
                                    ? currentRow.well.name
                                    : '--'}
                                </TableCell>
                              );
                            case 'operator':
                              return (
                                <TableCell key={cellKey}>
                                  {currentRow.operator ? (
                                    <PlainLink
                                      target="_blank"
                                      rel="noreferrer"
                                      href={currentRow.operator.salesforceHref}
                                    >
                                      {currentRow.operator.name}
                                    </PlainLink>
                                  ) : (
                                    '--'
                                  )}
                                </TableCell>
                              );
                            case 'developmentAreaName':
                              return (
                                <TableCell key={cellKey}>
                                  <Link
                                    target="_blank"
                                    to={currentRow.developmentArea.href}
                                  >
                                    {currentRow.developmentArea.name}
                                  </Link>
                                </TableCell>
                              );
                            case 'typeCurveName':
                              return (
                                <TableCell key={cellKey}>
                                  {currentRow.typeCurve?.name || '--'}
                                </TableCell>
                              );
                            case 'typeCurveStatus':
                              return (
                                <TableCell key={cellKey}>
                                  {currentRow.typeCurve?.status || '--'}
                                </TableCell>
                              );
                            case 'typeCurve':
                              return (
                                <TableCell key={cellKey}>
                                  {currentRow.typeCurve?.headerName || ''}
                                </TableCell>
                              );
                            case 'wellPermitDate':
                            case 'spudDate':
                            case 'completionDate':
                            case 'firstProductionDate':
                            case 'shutInDate':
                              return (
                                <TableCell key={cellKey}>
                                  {cell.value ? (
                                    <Date date={cell.value} />
                                  ) : (
                                    '--'
                                  )}
                                </TableCell>
                              );
                            case 'isExcluded':
                            case 'filedWellOnly':
                              return (
                                <TableCell key={cellKey}>
                                  {cell.value ? <Checkmark16 /> : '--'}
                                </TableCell>
                              );
                            default:
                              return (
                                <TableCell key={cellKey}>
                                  {headersList[cellIndex].format?.(
                                    cell.value
                                  ) ??
                                    (cell.value || '--')}
                                </TableCell>
                              );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                <TableRow
                  className={classNames([
                    style.assetsTable,
                    style.highlightTotal,
                    style.noSticky,
                  ])}
                >
                  {totalsRowCells(totals)}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        onChange={onChangePagination}
        page={page}
        pageSize={pageSize}
        pageSizes={PAGE_SIZES}
        totalItems={augmentedRows.length}
        itemRangeText={itemRangeText}
        pageRangeText={pageRangeText}
      />
    </div>
  );
};

export { Wells };
