import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import style from './definition-list.module.scss';

export interface DefinitionDescriptionProps
  extends HTMLAttributes<HTMLElement> {
  noBorder?: boolean;
}

const DefinitionDescription = ({
  className,
  noBorder,
  ...rest
}: DefinitionDescriptionProps) => {
  className = classNames(className, style.description, {
    [style.noBorder]: noBorder,
  });
  return <dd className={className} {...rest} />;
};

export { DefinitionDescription };
