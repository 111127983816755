import { useAtomValue } from 'jotai';
import {
  Column,
  FormGroup,
  Grid,
  Row,
  Tab,
  Tabs,
  Tile,
} from 'carbon-components-react';
import { DiscountRateAssumptions } from './forms/discount-rate-assumptions';
import { FormRow } from 'components/forms';
import { AtomizedForm } from 'atoms/form-atoms';
import { TierAssumptionsForm } from './types';
import { NumberField } from 'components/form-fields/number-field';

import style from './scenario-builder.module.scss';
import { TierFormAtom } from './atoms';
import { InfillAssumptions } from './forms/infill-assumptions';
import { VariableCosts } from './forms/variable-costs';
import { FixedCosts } from './forms/fixed-costs';
import { EconomicLimits } from './forms/economic-limits';

const CashflowAssumptions = ({
  form,
}: {
  form: AtomizedForm<TierAssumptionsForm[0]['cashflowAssumptionAttributes']>;
}) => {
  return (
    <>
      <Grid className="bx--no-gutter" fullWidth condensed>
        <Row className={style.rowThirds}>
          <Column>
            <Tile>
              <FormGroup legendText="Reservoir Assumptions">
                <FormRow>
                  <NumberField
                    id="gasShrinkPercentage"
                    label="Gas Shrink (%)"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={
                      form.reservoirAssumptionAttributes.gasShrinkPercentage
                    }
                  />
                </FormRow>
                <FormRow>
                  <NumberField
                    id="nglYield"
                    label="NGL Yield (BBL/MMCF)"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={form.reservoirAssumptionAttributes.nglYield}
                  />
                </FormRow>
              </FormGroup>
              <EconomicLimits form={form} />
              <FormGroup legendText="Royalty Rates and Burdens">
                <FormRow>
                  <NumberField
                    id="royaltyPercentage"
                    label="Royalty Rate (%)"
                    light
                    field={form.royaltyPercentage}
                  />
                </FormRow>
                <FormRow>
                  <NumberField
                    id="orriPercentage"
                    label="ORRI (%)"
                    light
                    field={form.orriPercentage}
                  />
                </FormRow>
              </FormGroup>
            </Tile>
          </Column>
          <Column>
            <Tile>
              <FormGroup legendText="Differentials">
                <FormRow>
                  <NumberField
                    id="oilDifferentialPrice"
                    label="Oil ($/BBL)"
                    light
                    field={form.differentialAttributes.oilPrice}
                  />
                </FormRow>
                <FormRow>
                  <NumberField
                    id="gasDifferentialPrice"
                    label="Gas ($/MMBTU)"
                    light
                    field={form.differentialAttributes.gasPrice}
                  />
                </FormRow>
                <FormRow>
                  <NumberField
                    id="nglDifferentialPercentage"
                    label="NGL (%)"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={form.differentialAttributes.nglPercentage}
                  />
                </FormRow>
              </FormGroup>
            </Tile>
          </Column>
        </Row>
        <Row>
          <Column>
            <Tile>
              <VariableCosts form={form} />
            </Tile>
          </Column>
          <Column>
            <Tile>
              <FixedCosts form={form} />
            </Tile>
          </Column>
        </Row>
      </Grid>
    </>
  );
};

const TierForm = ({ formAtom }: { formAtom: TierFormAtom }) => {
  const tierForm = useAtomValue(formAtom);
  if (!tierForm) return null;

  return (
    <Tabs className={style.tabsContained}>
      <Tab label="Drilling Assumptions">
        <Tile>
          <div className={style.infillFields}>
            <InfillAssumptions
              form={tierForm.drillingAssumptionAttributes.infillAttributes}
            />
          </div>
          <FormRow>
            <NumberField
              id="averageWellDrilledYears"
              label="Average Well Drilled (Years)"
              light
              field={
                tierForm.drillingAssumptionAttributes.ltdAttributes
                  .averageWellDrilledYears
              }
              min={0}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="drillStartMonths"
              label="Drill Start (Months)"
              light
              field={
                tierForm.drillingAssumptionAttributes.ltdAttributes
                  .drillStartMonths
              }
              min={0}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="spudToProductionMonths"
              label="Spud To Production (Months)"
              light
              field={
                tierForm.drillingAssumptionAttributes.ltdAttributes
                  .spudToProductionMonths
              }
              min={0}
            />
          </FormRow>
        </Tile>
      </Tab>
      <Tab label="Cashflow Assumptions">
        <CashflowAssumptions form={tierForm.cashflowAssumptionAttributes} />
      </Tab>
      <Tab label="Discount Rates Assumptions">
        <DiscountRateAssumptions form={tierForm.cashflowAssumptionAttributes} />
      </Tab>
    </Tabs>
  );
};

export { TierForm };
