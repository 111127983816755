import classNames from 'classnames';
import { FC } from 'react';
import style from './buttons.module.scss';

export interface Props {
  justify?: 'left' | 'right' | 'spaceBetween' | 'no-margin';
}

const Buttons: FC<Props> = ({ children, justify = 'left' }) => {
  const className = classNames(style.buttons, style[justify]);
  return <div className={className}>{children}</div>;
};

export { Buttons };
