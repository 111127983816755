import request from 'lib/request';
import { DocumentInterpretationResponse } from 'types/api-responses';

const toSentence = (indices: number[]) => {
  const indicesCopy = [...indices];

  const last = indicesCopy.pop();
  if (indicesCopy.length) {
    return `${indicesCopy.join(', ')} and ${last}`;
  } else {
    return last;
  }
};

const removeReferee = (href: string) => {
  return request.deleteReq<null, null | { errors: { base: string[] } }>(
    href,
    undefined,
    new AbortController()
  );
};

const markAsDuplicate = (href: string) => {
  return request.patch(href, { duplicate: true }, new AbortController());
};

const getInterpretationIndices = (
  interpretations: DocumentInterpretationResponse[],
  reference: 'grantor' | 'grantee' | 'document' | 'location',
  id: number
) => {
  return interpretations.reduce<number[]>((acc, item, index) => {
    const references = item[`${reference}References`]?.some((referer) => {
      if ('grantorId' in referer) {
        return referer.grantorId === id;
      }

      if ('granteeId' in referer) {
        return referer.granteeId === id;
      }

      if ('location' in referer) {
        return referer.location.id === id;
      }

      return referer.document.id === id;
    });

    if (references) {
      acc.push(index + 1);
    }
    return acc;
  }, []);
};

const ERROR_TEXT = 'Something went wrong.';

type ErrorResponseBody = {
  type: string;
  data?: {
    errors?: {
      base: string[];
    };
  } | null;
  message?: string;
};

const extractErrorMessage = (
  response: ErrorResponseBody,
  defaultErrorMessage = ERROR_TEXT
): string => {
  if (response.type === 'error' && response.data?.errors?.base) {
    return response.data.errors.base[0];
  }
  return defaultErrorMessage;
};

const resetRowSelectionAndError = (
  setSelectedRowIndex: React.Dispatch<React.SetStateAction<number>>,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  setSelectedRowIndex(-1);
  setError(undefined);
};

export {
  getInterpretationIndices,
  markAsDuplicate,
  removeReferee,
  toSentence,
  ERROR_TEXT,
  extractErrorMessage,
  resetRowSelectionAndError,
};
