import { FormRowStatus } from 'atoms/create-resource-atom';
import searchAtomPair, { SearchParams } from 'atoms/search-atom';
import { ComboBox, ComboBoxProps } from 'carbon-components-react';

import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { ApiQueryResource, ApiQueryResponse } from 'types/api-responses';
import { shouldSkipSearch } from 'utils/forms';

interface FormProps<Response, Params extends SearchParams>
  extends Omit<ComboBoxProps<Response>, 'items' | 'selectedItem'> {
  selectedItem: Response | undefined;
  selectedItemToString: (response: Response | null | undefined) => string;
  queryResource: ApiQueryResource;
  status: FormRowStatus;
  additionalPayload?: Params;
  light?: boolean;
  disabled?: boolean;
  className?: string;
}

const AutoComplete = <
  Response extends ApiQueryResponse,
  Params extends SearchParams = SearchParams
>({
  selectedItem,
  selectedItemToString,
  onChange,
  additionalPayload,
  className,
  titleText,
  queryResource,
  status,
  light = false,
  disabled = false,
  ...rest
}: FormProps<Response, Params>) => {
  const { searchAtom, resetSearchDataAtom } = useMemo(
    () => searchAtomPair<Response, Params>(queryResource),
    [queryResource]
  );

  const [query, setPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const debouncedSetPayload = useMemo(
    () => debounce(setPayload, 400),
    [setPayload]
  );

  return (
    <ComboBox<Response>
      titleText={titleText}
      items={query?.data?.results || []}
      className={className}
      itemToString={selectedItemToString}
      selectedItem={selectedItem || null}
      onChange={({ selectedItem }) => {
        onChange?.({ selectedItem: selectedItem ?? undefined });
      }}
      onInputChange={(text) => {
        if (shouldSkipSearch(firstRender.current, status)) return;
        resetSearchData(undefined);
        debouncedSetPayload(
          (c) =>
            ({
              ...c,
              text,
              pageSize: 10,
              ...additionalPayload,
            } as Params)
        );
      }}
      light={light}
      disabled={disabled}
      {...rest}
    />
  );
};

export { AutoComplete };
