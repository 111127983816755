import { DevelopmentAreaMap } from 'components/development-area-map';
import { MapUserConfigAtom } from 'components/map/types';
import { DevelopmentAreaResponse } from 'types/api-responses';

const Map = ({
  mapConfigAtom,
  developmentArea,
}: {
  mapConfigAtom: MapUserConfigAtom;
  developmentArea: DevelopmentAreaResponse;
}) => {
  const referenceLocationIds = developmentArea.developmentAreaGeographies.map(
    (el) => el.referenceLocation.id
  );

  const geographies = developmentArea.developmentAreaGeographies.map((el) => ({
    id: el.id,
    referenceLocation: el.referenceLocation,
    quarterCalls: el.quarterCalls,
  }));

  return (
    <DevelopmentAreaMap
      referenceLocationIds={referenceLocationIds}
      geographies={geographies}
      mapConfigAtom={mapConfigAtom}
    />
  );
};

export { Map };
