import { WellSearchParams } from './types';

const updatedCheckboxes = (newId: string, currentIds: string[] = []) => {
  return currentIds.includes(newId)
    ? currentIds.filter((id) => id !== newId)
    : currentIds.concat([newId]);
};

const defaultPayload: WellSearchParams = {
  text: '',
  page: 1,
  pageSize: 10,
  holeDirectionIds: undefined,
  statusIds: undefined,
};

export { updatedCheckboxes, defaultPayload };
