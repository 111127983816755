import { Button, ButtonProps, InlineLoading } from 'carbon-components-react';
import { Download16 } from '@carbon/icons-react';
import { FC } from 'react';

interface Props extends ButtonProps {
  loading: boolean;
  loadingText: string;
  hasIconOnly?: boolean;
  iconDescription?: string;
  kind?: 'ghost' | 'primary' | 'tertiary';
}

const DownloadButton: FC<Props> = ({
  children,
  loadingText,
  loading,
  kind = 'ghost',
  hasIconOnly = false,
  iconDescription,
  ...rest
}: Props) => {
  return loading ? (
    <Button kind="ghost" size="md" disabled>
      <InlineLoading description={loadingText} />
    </Button>
  ) : (
    <Button
      hasIconOnly={hasIconOnly}
      iconDescription={iconDescription}
      kind={kind}
      size="md"
      renderIcon={Download16}
      {...rest}
    >
      {children}
    </Button>
  );
};

export { DownloadButton };
