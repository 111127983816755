import { Map } from 'components/map';
import { getMapDefaultConfig } from 'components/map/atoms';
import { SurveyAreaAutocomplete } from 'components/survey-area-autocomplete';
import { atomWithStorage } from 'jotai/utils';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import style from './page.module.scss';

const mapConfigAtom = atomWithStorage(
  'insights-reference-locations-map-config',
  getMapDefaultConfig()
);

const ReferenceLocationsMapPage = () => {
  const latitude = 31.72;
  const longitude = -102.26;
  const [blockPopups, setBlockPopups] = useState(false);
  const history = useHistory();

  return (
    <div className={style.wrapper}>
      <Map
        latitude={latitude}
        longitude={longitude}
        className={style.map}
        blockPopups={blockPopups}
        zoom={9}
        mapConfigAtom={mapConfigAtom}
        showControls
        showMapFilters
      >
        <SurveyAreaAutocomplete
          id="reference-location-map=id"
          titleText=""
          placeholder="Search by location"
          onChange={({ selectedItem }) => {
            if (selectedItem) history.push(selectedItem.href);
          }}
          className={style.searchInput}
          onMouseOver={() => {
            setBlockPopups(true);
          }}
          onMouseLeave={() => setBlockPopups(false)}
          light
        />
      </Map>
    </div>
  );
};

export { ReferenceLocationsMapPage };
