import { TextArea } from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { formAtoms } from '../atoms';

const PageReference = () => {
  const pageReferenceAtom = useMemo(
    () => atom((get) => get(get(formAtoms).formAtom).pageReferences),
    []
  );
  const setField = useSetAtom(useAtomValue(formAtoms).formAtom);
  const pageReference = useAtomValue(pageReferenceAtom);

  return (
    <FormRow>
      <TextArea
        labelText="Page References"
        placeholder="Text Field"
        light
        id={`page-references`}
        value={pageReference.value || ''}
        onChange={({ target: { value } }) =>
          setField({ field: 'pageReferences', value })
        }
        invalid={pageReference.state === 'dirty' && !!pageReference.error}
        invalidText={pageReference.state === 'dirty' && pageReference.error}
      />
    </FormRow>
  );
};

export { PageReference };
