import { useEffect, useRef } from 'react';
import style from './gojs-viewer.module.scss';
import { init } from './gojs_graph';

interface Props {
  graph: { vertices: unknown; edges: unknown };
}

function GoJSViewer(props: Props) {
  const { graph } = props;
  const treeDiv = useRef(null);
  const overviewDiv = useRef(null);

  useEffect(() => {
    init(graph.vertices, graph.edges, treeDiv.current, overviewDiv.current);
  }, [graph.vertices, graph.edges]);

  return (
    <div className={style.graphContainer}>
      <div className={style.chainGraphs}>
        <div ref={treeDiv} className={style.viewer} />
        <div ref={overviewDiv} className={style.overview} />
      </div>
    </div>
  );
}

export default GoJSViewer;
