import searchAtomPair, { SearchParams } from 'atoms/search-atom';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tile,
  TooltipDefinition,
} from 'carbon-components-react';
import { Date } from 'components/date';
import { EmptyState } from 'components/empty-state';
import { Link } from 'components/link';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import { PaginatedTable } from 'components/table/paginated-table';
import { useAtom } from 'jotai';
import { truncate } from 'lodash';
import { useMemo } from 'react';
import { DocumentResponse, TitleWorkspaceResponse } from 'types/api-responses';

import DocumentSvg from 'images/srp-illustration-document.svg';
import style from './document-index-table.module.scss';
import { LinkWithHover } from 'components/hover-card-resource';
import { documentIndexSortPreferenceAtom } from './atoms';

const BLANK_RENDER_VALUE = '—';

interface DocumentIndexSearchParams extends SearchParams {
  withinReferenceLocationId: number;
}

const Referees = ({ list }: { list: { name: string }[] }) => {
  if (list.length) {
    const firstName = truncate(list[0].name, { length: 50, omission: '…' });
    const count = list.length > 1 ? ` (${list.length})` : '';
    return (
      <>
        {firstName}
        {count}
      </>
    );
  } else {
    return null;
  }
};

const DocumentTypeLink = ({
  type,
  href,
  workspaceHref,
}: {
  type?: string;
  href: string;
  workspaceHref?: string;
}) => {
  const documentHref = workspaceHref
    ? `${href}?referrer=${workspaceHref}/documents`
    : href;

  return type ? (
    <Link target="_blank" to={documentHref}>
      {type}
    </Link>
  ) : href ? (
    <TooltipDefinition
      className={style.toolTip}
      direction="top"
      tooltipText="Imported Document"
    >
      <Link target="_blank" to={documentHref}>
        Unknown
      </Link>
    </TooltipDefinition>
  ) : (
    <>{BLANK_RENDER_VALUE}</>
  );
};

const DocumentIndexTable = ({
  referenceLocationId,
  toolbar,
  titleWorkspace,
}: {
  referenceLocationId: number;
  toolbar?: JSX.Element;
  titleWorkspace?: TitleWorkspaceResponse;
}) => {
  const [preferences, setPreferences] = useAtom(
    documentIndexSortPreferenceAtom
  );
  const { searchAtom } = useMemo(
    () =>
      searchAtomPair<DocumentResponse, DocumentIndexSearchParams>('documents', {
        withinReferenceLocationId: referenceLocationId,
        page: 1,
        pageSize: 30,
        sortAttribute: preferences.sortAttribute,
        sortDirection: preferences.sortDirection,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceLocationId]
  );
  const [searchResults, setSearchPayload] = useAtom(searchAtom);

  const workspaceHref = titleWorkspace
    ? encodeURIComponent(titleWorkspace?.href)
    : undefined;

  const searchParams = searchResults.currentPayload;

  const pageSize = searchResults?.data?.pageSize || 30;

  if (!searchResults.loading && !searchResults.data?.results.length) {
    return (
      <Tile>
        <EmptyState
          icon={DocumentSvg}
          headerText="No Data Available"
          helperText="Currently Insights doesn’t have any available data."
        />
      </Tile>
    );
  }

  function sortableColumn(
    sortAttribute: string,
    tableHeader: string,
    active: boolean
  ) {
    return (
      <TableHeader
        isSortable={true}
        isSortHeader={active}
        sortDirection={searchParams?.sortDirection}
        onClick={() => {
          setSearchPayload((current) => {
            const newSortDirection =
              current?.sortDirection === 'ASC' ? 'DESC' : 'ASC';

            setPreferences({
              sortAttribute,
              sortDirection: newSortDirection,
            });

            return (
              current && {
                ...current,
                page: 1,
                sortAttribute,
                sortDirection: newSortDirection,
              }
            );
          });
        }}
      >
        {tableHeader}
      </TableHeader>
    );
  }

  return (
    <>
      {toolbar}
      <PaginatedTable
        pageSizes={[30, 60]}
        pageSize={pageSize}
        page={searchParams?.page || 1}
        totalItems={searchResults?.data?.totalCount || 0}
        onPaginate={({ page, pageSize }) => {
          setSearchPayload(
            (current) =>
              current && {
                ...current,
                page,
                pageSize,
              }
          );
        }}
      >
        <TableHead>
          <TableRow>
            <TableHeader>Interpretations Count</TableHeader>
            <TableHeader>Grantor</TableHeader>
            <TableHeader>Grantee</TableHeader>
            <TableHeader>Document Title</TableHeader>
            <TableHeader>Document Type</TableHeader>
            {sortableColumn(
              'instrument_date',
              'Instrument Date',
              searchParams?.sortAttribute === 'instrument_date'
            )}
            {sortableColumn(
              'file_date',
              'File Date',
              searchParams?.sortAttribute === 'file_date'
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {searchResults.loading ? (
            <SkeletonTableRows rows={pageSize} columns={7} />
          ) : searchResults.data ? (
            searchResults.data.results.map((el) => {
              return (
                <TableRow key={el.id}>
                  <TableCell>
                    {el.interpretationsCount !== 0
                      ? el.interpretationsCount
                      : BLANK_RENDER_VALUE}
                  </TableCell>
                  <TableCell>
                    <Referees list={el.grantors} />
                  </TableCell>
                  <TableCell>
                    <Referees list={el.grantees} />
                  </TableCell>
                  <TableCell>{el.documentTitle}</TableCell>
                  <TableCell>
                    <LinkWithHover kind="document" href={el.href}>
                      <DocumentTypeLink
                        type={el.documentType}
                        href={el.href}
                        workspaceHref={workspaceHref}
                      />
                    </LinkWithHover>
                  </TableCell>
                  <TableCell>
                    {el.instrumentDate ? (
                      <Date date={el.instrumentDate} />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {el.fileDate ? <Date date={el.fileDate} /> : null}
                  </TableCell>
                </TableRow>
              );
            })
          ) : null}
        </TableBody>
      </PaginatedTable>
    </>
  );
};

export { DocumentIndexTable };
