import { Chat32 } from '@carbon/icons-react';
import classNames from 'classnames';
import { IconProps } from './types';

import style from './icons.module.scss';

const Comment = ({ className }: IconProps) => {
  return (
    <div className={classNames(style.background, style.comment, className)}>
      <Chat32 className={classNames(style.icon, style.comment)} />
    </div>
  );
};

export default Comment;
