import { SubtractAlt16, TrashCan16 } from '@carbon/icons-react';
import { FormRowStatus } from 'atoms/create-resource-atom';
import { Button } from 'carbon-components-react';
import classNames from 'classnames';

import { PrimitiveAtom, useAtom } from 'jotai';

import style from './delete-row-button.module.scss';

const DeleteRowButton = ({
  statusAtom,
  onDelete,
  atomIdx,
  disabled,
  adjustForLabel = true,
}: {
  statusAtom: PrimitiveAtom<FormRowStatus>;
  atomIdx: number;
  onDelete?: (idx: number) => void;
  disabled?: boolean;
  adjustForLabel?: boolean;
}) => {
  const [status, setStatus] = useAtom(statusAtom);
  const classes = classNames({ [style.adjustForLabel]: adjustForLabel });
  return (
    <Button
      size="field"
      className={classes}
      tooltipAlignment="end"
      tooltipPosition="top"
      renderIcon={
        status === 'new' || status === 'duplicated' ? SubtractAlt16 : TrashCan16
      }
      iconDescription={status === 'trash' ? 'Undo remove row' : 'Remove row'}
      kind={status === 'trash' ? 'danger' : 'ghost'}
      onClick={() => {
        if (status === 'saved') {
          setStatus('trash');
        } else if (status === 'trash') {
          setStatus('saved');
        } else {
          onDelete?.(atomIdx);
        }
      }}
      hasIconOnly
      disabled={disabled && status !== 'trash'}
    />
  );
};

export { DeleteRowButton };
