import { useAtomValue } from 'jotai';
import { BACKGROUND_COLOR, buildPolygonStyle } from 'lib/map';
import { Layer, Source } from 'react-map-gl';
import { createMapSourceAtom } from './atoms';
import { MapUserConfigAtom } from './types';
import { standardMapStyles } from './map-styles';
import { useMemo } from 'react';

const STYLE = buildPolygonStyle({
  color: BACKGROUND_COLOR,
  fillOpacity: 0.05,
  lineOpacity: 0.5,
});

type DevelopmentAreaName = string;

interface Props {
  style?: typeof STYLE;
  excluding?: DevelopmentAreaName[];
  mapConfigAtom: MapUserConfigAtom;
}

const DevelopmentAreasLayer = ({
  style = STYLE,
  excluding = [],
  mapConfigAtom,
}: Props) => {
  const devAreasSourceAtom = useMemo(
    () => createMapSourceAtom('developmentAreas'),
    []
  );
  const filter = ['!', ['in', ['get', 'name'], ['literal', excluding]]];

  const devAreasSource = useAtomValue(devAreasSourceAtom);
  const mapConfig = useAtomValue(mapConfigAtom);
  if (!devAreasSource) {
    return null;
  }

  return (
    <Source id="development-areas" type="vector" url={devAreasSource}>
      {mapConfig.developmentAreas.options.units && (
        <Layer
          id="development-area-fill"
          type="fill"
          paint={style.fill}
          filter={filter}
          source-layer="development-areas"
        />
      )}
      {mapConfig.developmentAreas.options.units && (
        <Layer
          id="development-area-line"
          type="line"
          paint={style.line}
          filter={filter}
          source-layer="development-areas"
        />
      )}
      {mapConfig.developmentAreas.options.pricing && (
        <Layer
          id="development-area-pricing"
          type="symbol"
          source-layer="development-areas"
          minzoom={12}
          filter={['==', 'type', 'centroid']}
          layout={{
            'text-field': [
              'case',
              ['!', ['has', 'max']],
              [
                'format',
                ['upcase', ['get', 'name']],
                {
                  'font-scale': 1,
                  'text-font': [
                    'literal',
                    standardMapStyles[0].fonts.CONDENSED,
                  ],
                },
                '\n',
                {},
                'No Evaluation',
                {
                  'font-scale': 0.8,
                  'text-color': 'red',
                  'text-font': [
                    'literal',
                    standardMapStyles[0].fonts.CONDENSED_BOLD,
                  ],
                },
              ],
              [
                'format',
                ['upcase', ['get', 'name']],
                {
                  'font-scale': 1,
                  'text-font': [
                    'literal',
                    standardMapStyles[0].fonts.CONDENSED,
                  ],
                },
                '\n',
                {},
                ['concat', 'TRGT: $', ['number-format', ['get', 'target'], {}]],
                {
                  'font-scale': 0.8,
                  'text-font': [
                    'literal',
                    standardMapStyles[0].fonts.CONDENSED_BOLD,
                  ],
                },
                '\n',
                {},
                ['concat', 'MAX: $', ['number-format', ['get', 'max'], {}]],
                {
                  'font-scale': 0.8,
                  'text-font': [
                    'literal',
                    standardMapStyles[0].fonts.CONDENSED_BOLD,
                  ],
                },
              ],
            ],
            'text-justify': 'left',
            'text-size': {
              base: 1,
              stops: [
                [12, 8],
                [18, 24],
              ],
            },
          }}
          paint={{
            'text-color': 'black',
          }}
        />
      )}
    </Source>
  );
};

export { DevelopmentAreasLayer };
