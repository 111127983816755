import { atom, Getter, Setter } from 'jotai';
import request from 'lib/request';
import { Response } from 'lib/request/types';
import { ApiPayloads, AvailablePayloads } from 'types/api-payloads';
import { ApiResponse } from 'types/api-responses';
import { datadogRum } from '@datadog/browser-rum';
import { AddUndefinedToArray } from 'types/utils';

export type FormFieldState = 'dirty' | 'stable' | 'updated' | 'duplicated';
export type FormRowStatus = 'new' | 'saved' | 'trash' | 'duplicated';

export interface CreateResourceAtom<OkResponse, ErrorResponse> {
  loading: boolean;
  result: Response<OkResponse, ErrorResponse> | null;
}

export type FormField<T> = {
  value: T;
  state: FormFieldState;
  error?: string;
};

export type ExtractGeneric<Type> = Type extends FormField<infer X> ? X : never;

export type FormErrors<T extends Record<keyof T, FormField<unknown>>> = {
  errors: { [P in keyof T]?: string };
};

export type FormValues<T extends Record<keyof T, FormField<unknown>>> = {
  [P in keyof T]: T[P]['value'];
};

export type Form<T> = Record<keyof T, FormField<unknown>>;

export interface MutatePayload<
  Data extends ApiResponse | null,
  Payload extends AvailablePayloads
> {
  url: string;
  type: 'POST' | 'PATCH' | 'DELETE';
  data: AddUndefinedToArray<ApiPayloads[Payload]['payload']>;
  onSuccess?: (data: Data) => void;
  onError?: (error?: ApiPayloads[Payload]['error']) => void;
}

function createMutationAtom<
  Data extends ApiResponse | null,
  Resource extends AvailablePayloads
>() {
  const postDataAtom = atom<
    CreateResourceAtom<Data, ApiPayloads[Resource]['error']>
  >({
    loading: false,
    result: null,
  });

  return atom<
    CreateResourceAtom<Data, ApiPayloads[Resource]['error']>,
    [MutatePayload<Data, Resource>],
    undefined
  >(
    (get) => get(postDataAtom),
    (_get, set, payload) => {
      const postData = async () => {
        set(postDataAtom, (prev) => ({ ...prev, loading: true }));

        const actionMap = {
          POST: request.post,
          PATCH: request.patch,
          DELETE: request.deleteReq,
        } as const;

        const action = actionMap[payload.type];

        try {
          const response = await action<Data, ApiPayloads[Resource]['error']>(
            payload.url,
            payload.data,
            new AbortController()
          );

          if (response.type === 'fatal') {
            payload.onError?.();
            set(postDataAtom, {
              loading: false,
              result: {
                type: 'fatal',
                message: response.message,
              },
            });
            return;
          }

          set(postDataAtom, {
            loading: false,
            result: response,
          });

          if (response.type === 'success') {
            payload?.onSuccess?.(response.data);
            return;
          }

          if (response.type === 'error') {
            payload.onError?.(response.data);
            return;
          }
        } catch (error) {
          datadogRum.addError(error);
          if (window.Rollbar) {
            window.Rollbar.error(error);
          }
          set(postDataAtom, {
            loading: false,
            result: {
              type: 'fatal',
              message: error as string,
            },
          });
        }
      };
      postData();
    }
  );
}

export type DeleteAtom = ReturnType<typeof createDeleteAtom>;

function createDeleteAtom() {
  const loadingIndicatorAtom = atom<{ loading: boolean }>({ loading: false });
  return atom<
    { loading: boolean },
    [{ url: string; onSuccess: () => void; onError: (error: unknown) => void }],
    Promise<void>
  >(
    (get) => get(loadingIndicatorAtom),
    async (_get, set, payload) => {
      const { url, onSuccess, onError } = payload;
      try {
        set(loadingIndicatorAtom, { loading: true });
        const response = await request.deleteReq(
          url,
          null,
          new AbortController()
        );

        if (response.type === 'success') {
          onSuccess();
        }

        if (response.type === 'error') {
          onError(response.data);
        }
      } catch (e) {
        onError(e);
      } finally {
        set(loadingIndicatorAtom, { loading: false });
      }
    }
  );
}

const collectedErrorsAtom = atom<string[]>([]);

function createResourceFormAtom<
  FormState extends Form<FormState>,
  FormErrors extends Record<'errors', Partial<Record<keyof FormState, string>>>
>(fields: FormState) {
  type Field = {
    [P in keyof FormState]: {
      field: P;
      value: ExtractGeneric<FormState[P]>;
      state?: FormFieldState;
    };
  }[keyof FormState];

  const formAtomInner = atom<FormState>(fields);

  const dirtyFieldsAtom = atom<
    null,
    [FormErrors & { skipAccumulation?: boolean }],
    undefined
  >(null, (get, set, value) => {
    const collectedErrors: string[] = [];
    const fields = get(formAtomInner);
    const keys = Object.keys(value.errors) as Array<keyof FormState>;
    const updatedFields = keys.reduce((acc, el) => {
      const error = value.errors[el];
      if (error && !value.skipAccumulation) collectedErrors.push(error);
      return {
        ...acc,
        [el]: {
          ...fields[el],
          state: 'dirty',
          error,
        },
      };
    }, fields);
    set(collectedErrorsAtom, (current) => [...current, ...collectedErrors]);
    set(formAtomInner, updatedFields);
  });

  const formAtom = atom<
    FormState,
    [Field | ((value: FormState, get: Getter, set: Setter) => Field)],
    undefined
  >(
    (get) => get(formAtomInner),
    (get, set, value) => {
      let updatedField: Field;
      const fields = get(formAtomInner);
      if (typeof value === 'function') {
        updatedField = value(fields, get, set);
      } else {
        updatedField = value;
      }
      set(formAtomInner, {
        ...fields,
        [updatedField.field]: {
          value: updatedField.value,
          state: updatedField.state ?? 'updated',
          error: undefined,
        },
      });
    }
  );

  const resetFormFieldsStateAtom = atom<null, [FormFieldState], undefined>(
    () => null,
    (get, set, value) => {
      const fields = get(formAtomInner);
      Object.keys(fields).forEach((key) => {
        const field = key as keyof typeof fields;
        set(formAtom, {
          field,
          value: fields[field].value,
          state: value,
        } as Field);
      });
    }
  );

  return { dirtyFieldsAtom, formAtom, resetFormFieldsStateAtom };
}

const initializeForm = <T extends Form<T>>(
  formValues: FormValues<T>,
  state: FormFieldState
): T => {
  const form: Record<string, FormField<unknown>> = {};

  Object.entries(formValues).forEach(([k, v]) => {
    form[k] = { value: v, state };
  });

  return form as T;
};

const getError = <T extends Form<T>>(
  formOrField: T | FormField<unknown>,
  field?: keyof T
) => {
  if (field && !('state' in formOrField) && !('value' in formOrField)) {
    const a = formOrField[field] as FormField<typeof field>;
    return a.state === 'dirty' && a.error;
  }

  return (
    'state' in formOrField &&
    'value' in formOrField &&
    formOrField.state === 'dirty' &&
    formOrField.error
  );
};

const getValue = <T extends FormField<any>>(
  field: T
): ExtractGeneric<T> | undefined => {
  return field.state === 'updated' || field.state === 'duplicated'
    ? field.value
    : undefined;
};

const hasChangedFields = <T extends Form<T>>(form: T) => {
  return Object.values<FormField<unknown>>(form).some(
    (v) => v.state === 'updated' || v.state === 'duplicated'
  );
};

const rowStatus = (state: FormFieldState) => {
  return state === 'duplicated'
    ? 'duplicated'
    : state === 'stable'
    ? 'saved'
    : 'new';
};

export {
  collectedErrorsAtom,
  createDeleteAtom,
  createMutationAtom,
  createResourceFormAtom,
  getError,
  getValue,
  hasChangedFields,
  initializeForm,
  rowStatus,
};
