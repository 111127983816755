import { atom } from 'jotai';
import { createResourceCacheMapAtom } from 'lib/atoms';
import request from 'lib/request';
import { CalculationEntity, OwnershipResponse } from 'types/api-responses';

const ownerDetailsMapAtom = createResourceCacheMapAtom<OwnershipResponse>();

const ownersAtomBase = atom<{ loading: boolean; results: CalculationEntity[] }>(
  {
    loading: true,
    results: [],
  }
);

type Uri = string;
const ownersAtom = atom<
  { loading: boolean; results: CalculationEntity[] },
  [Uri],
  void
>(
  (get) => get(ownersAtomBase),
  async (get, set, uri) => {
    const cache = get(ownersAtomBase);
    if (!cache.loading && cache.results.length) {
      return;
    }
    const ownerList = await request.get<CalculationEntity[]>(uri);
    if ('error' in ownerList) return [];
    if (ownerList.length) {
      set(ownerDetailsMapAtom, {
        href: ownerList[0].href,
      });
    }
    set(ownersAtomBase, { loading: false, results: ownerList });
  }
);

const selectedOwnerIndexAtom = atom<number>(0);

const ownerDetailAtom = atom((get) => {
  const ownersMap = get(ownerDetailsMapAtom);
  const owners = get(ownersAtom);
  if (!owners.results.length) return;

  const selectedIndex = get(selectedOwnerIndexAtom);
  const ownerHref = owners.results[selectedIndex].href;
  return ownersMap[ownerHref];
});

const selectedOwnerAtom = atom<CalculationEntity | undefined, [number], void>(
  (get) => {
    const owners = get(ownersAtom);
    return owners.results[get(selectedOwnerIndexAtom)];
  },
  (get, set, index) => {
    const owners = get(ownersAtom);
    const owner = owners.results[index];
    if (owner) {
      set(selectedOwnerIndexAtom, index);
      set(selectedMapSubtractsAtom, []);
      set(ownerDetailsMapAtom, {
        href: owner.href,
      });
    }
  }
);

const selectedMapSubtractsAtom = atom<string[]>([]);

const resetOwnerStateAtom = atom(
  () => null,
  (_get, set) => {
    set(selectedOwnerIndexAtom, 0);
    set(selectedMapSubtractsAtom, []);
    set(ownerDetailsMapAtom, { resetCache: true });
    set(ownersAtomBase, { loading: true, results: [] });
  }
);

export {
  ownerDetailAtom,
  ownersAtom,
  resetOwnerStateAtom,
  selectedMapSubtractsAtom,
  selectedOwnerIndexAtom,
  selectedOwnerAtom,
};
