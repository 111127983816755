import { standardMapStyles } from 'components/map/map-styles';
import { PrimitiveAtom, useAtomValue } from 'jotai';
import { buildPolygonStyle } from 'lib/map';
import { SymbolLayout } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';
import { assetsLayerAtom } from './atoms';
import { mapColors } from 'components/map/map-colors';

const ASSET_STYLE = buildPolygonStyle({
  color: mapColors.scopedAssetsFill,
  lineColor: mapColors.scopedAssetsBorder,
});

interface Props {
  source: string;
  layerAtom?: PrimitiveAtom<boolean>;
}

const assetTextLayout = (): SymbolLayout => {
  return {
    'text-field': [
      'format',
      ['upcase', ['get', 'name']],
      {
        'font-scale': 1,
        'text-font': ['literal', standardMapStyles[0].fonts.CONDENSED_BOLD],
      },
      '\n',
      {},
      ['concat', 'N. Acreage: ', ['number-format', ['get', 'net_acreage'], {}]],
      {
        'font-scale': 0.8,
        'text-font': ['literal', standardMapStyles[0].fonts.CONDENSED],
      },
    ],
    'text-justify': 'left',
    'text-size': {
      base: 1,
      stops: [
        [12, 8],
        [18, 24],
      ],
    },
  };
};
const ScopedPackageAssetsLayer = ({ source, layerAtom }: Props) => {
  const assetsVisible = useAtomValue(layerAtom || assetsLayerAtom);
  return (
    <Source id="package-assets-layer-source" type="vector" url={source}>
      {assetsVisible && (
        <Layer
          id="package-assets-fill"
          type="fill"
          paint={ASSET_STYLE.fill}
          source-layer="package-assets"
        />
      )}
      <Layer
        id="package-assets-fill-isolated"
        type="fill"
        paint={ASSET_STYLE.fill}
        source-layer="package-assets"
        filter={['in', 'name', '']}
      />
      <Layer
        id="package-assets-line-isolated"
        type="line"
        paint={ASSET_STYLE.line}
        source-layer="package-assets"
        filter={['in', 'name', '']}
      />
      <Layer
        id="package-assets-line-isolated-highlighted"
        type="line"
        paint={ASSET_STYLE.lineHighlighted}
        source-layer="package-assets"
        filter={['in', 'name', '']}
      />
      {assetsVisible && (
        <Layer
          id="package-assets-line"
          type="line"
          paint={ASSET_STYLE.line}
          source-layer="package-assets"
        />
      )}
      {assetsVisible && (
        <Layer
          id="package-assets-label"
          type="symbol"
          source-layer="package-assets"
          minzoom={12}
          filter={['==', 'type', 'centroid']}
          layout={assetTextLayout()}
          paint={{
            'text-color': 'black',
          }}
        />
      )}
    </Source>
  );
};

export { ScopedPackageAssetsLayer };
