import { createMutationAtom } from 'atoms/create-resource-atom';
import { resourcesAtom } from 'atoms/root';
import { InlineNotification, Modal, TextInput } from 'carbon-components-react';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TitleWorkspaceResponse } from 'types/api-responses';

const CreateTitleWorkspaceModal = ({
  open,
  onClose,
  referenceLocationId,
}: {
  open: boolean;
  onClose: () => void;
  referenceLocationId: number;
}) => {
  const [name, setName] = useState('');
  const createAtom = useMemo(
    () => createMutationAtom<TitleWorkspaceResponse, 'titleWorkspaces'>(),
    []
  );
  const [{ loading, result }, runRequest] = useAtom(createAtom);
  const resources = useAtomValue(resourcesAtom);
  const titleWorkspaceHref = resources?.titleWorkspaces.href;
  const history = useHistory();
  const runRequestCallback = useCallback(() => {
    if (!titleWorkspaceHref) return;
    runRequest({
      type: 'POST',
      url: titleWorkspaceHref,
      data: {
        name,
        referenceLocationId,
      },
      onSuccess: (data) => {
        history.push(data.href);
      },
    });
  }, [history, name, referenceLocationId, runRequest, titleWorkspaceHref]);
  return (
    <Modal
      open={open}
      modalHeading="Create title workspace"
      primaryButtonText="Create"
      primaryButtonDisabled={loading}
      secondaryButtonText="Cancel"
      onRequestClose={onClose}
      onRequestSubmit={runRequestCallback}
    >
      <TextInput
        id="create-title-workspace-modal-name"
        labelText="Name"
        placeholder="Please name title workspace"
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        invalid={result?.type === 'error' || result?.type === 'fatal'}
        invalidText={result?.type === 'error' ? result.data?.errors.name : null}
      />
      {result?.type === 'error' && (
        <InlineNotification
          kind="error"
          lowContrast
          title="Error!"
          subtitle={[
            result.data?.errors.name,
            result.data?.errors.referenceLocation,
          ]
            .filter((el) => el)
            .join(', ')}
        />
      )}
    </Modal>
  );
};

export { CreateTitleWorkspaceModal };
