import { AtomizedForm } from 'atoms/form-atoms';
import {
  Column,
  ContentSwitcher,
  FormGroup,
  Grid,
  RadioButton,
  RadioButtonGroup,
  Row,
  Switch,
  Tile,
} from 'carbon-components-react';
import { useAtom, useSetAtom } from 'jotai';

import style from './cashflow-assumption.module.scss';
import { useEffect, useState } from 'react';
import { FormRow } from 'components/forms';
import { DateField } from 'components/form-fields/date-field';
import { NumberField } from 'components/form-fields/number-field';
import { ScenariosForm } from '../types';
import {
  useValidateCashflowFieldAtom,
  useValidateForecastSourceFieldsAtom,
} from '../validations';
import { FixedFloatingDate } from './fixed-floating-date';
import { VariableCosts } from './variable-costs';
import { FixedCosts } from './fixed-costs';
import { CommissionAdjustments } from './commission-adjustments';
import { EconomicLimits } from './economic-limits';

const getPriceTypeIndex = (
  priceType: ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']['priceAttributes']['kind']
) => {
  switch (priceType) {
    case 'strip_as_of':
    case 'strip_most_recent':
      return 0;
    case 'flat':
      return 1;
    case 'modified_strip_as_of':
    case 'modified_strip_most_recent':
      return 2;
  }
};

const Prices = ({
  form,
}: {
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
  >;
}) => {
  const priceAttributes = form.priceAttributes;
  const [priceType, setPriceType] = useAtom(priceAttributes.kind);
  const [selectedIndex, setSelectedIndex] = useState(
    getPriceTypeIndex(priceType.value)
  );
  const setAsOfDate = useSetAtom(priceAttributes.stripPriceDate);
  const validateCashflowFields = useValidateCashflowFieldAtom(form);

  useEffect(() => {
    validateCashflowFields();
  }, [validateCashflowFields]);

  const strip =
    priceType.value === 'strip_most_recent' ||
    priceType.value === 'strip_as_of';
  const flat = priceType.value === 'flat';
  const modified =
    priceType.value === 'modified_strip_most_recent' ||
    priceType.value === 'modified_strip_as_of';

  const validateStripFields = useValidateCashflowFieldAtom(form);

  return (
    <FormGroup legendText="Prices">
      <FormRow>
        <ContentSwitcher
          selectedIndex={selectedIndex}
          onChange={(e) => {
            switch (e.index) {
              case 0:
                setPriceType('strip_most_recent');
                setSelectedIndex(0);
                validateStripFields();
                break;
              case 1:
                setPriceType('flat');
                setSelectedIndex(1);
                validateStripFields();
                break;
              case 2:
                setPriceType('modified_strip_most_recent');
                setSelectedIndex(2);
                validateStripFields();
                break;
            }
          }}
        >
          <Switch text="Strip" />
          <Switch text="Flat" />
          <Switch text="Modified Strip" />
        </ContentSwitcher>
      </FormRow>
      {strip && (
        <>
          <FormRow>
            <RadioButtonGroup
              name="strip-price-radio"
              defaultSelected={priceType.value}
              orientation="horizontal"
              onChange={(
                value: ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']['priceAttributes']['kind']
              ) => {
                if (value === 'strip_most_recent') {
                  setAsOfDate('');
                }
                setPriceType(value);
                validateStripFields();
              }}
            >
              <RadioButton
                labelText="Most Recent"
                value={'strip_most_recent'}
              />
              <RadioButton labelText="As Of:" value={'strip_as_of'} />
            </RadioButtonGroup>
          </FormRow>
          <FormRow>
            <DateField
              id="as_of_date"
              light
              short
              datePickerType="single"
              labelText="As Of Date"
              disabled={priceType.value !== 'strip_as_of'}
              field={priceAttributes.stripPriceDate}
            />
          </FormRow>
        </>
      )}
      {flat && (
        <>
          <FormRow>
            <NumberField
              id="oilFlatPrice"
              label="Oil ($/BBL)"
              light
              field={priceAttributes.oilFlatPrice}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="gasFlatPrice"
              label="Gas ($/MMBTU)"
              light
              field={priceAttributes.gasFlatPrice}
            />
          </FormRow>
        </>
      )}
      {modified && (
        <>
          <FormRow>
            <RadioButtonGroup
              name="modified-strip-price-radio"
              defaultSelected={priceType.value}
              orientation="horizontal"
              onChange={(
                value: ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']['priceAttributes']['kind']
              ) => {
                if (value === 'modified_strip_most_recent') {
                  setAsOfDate('');
                }
                setPriceType(value);
                validateStripFields();
              }}
            >
              <RadioButton
                labelText="Most Recent"
                value={'modified_strip_most_recent'}
              />
              <RadioButton labelText="As Of:" value={'modified_strip_as_of'} />
            </RadioButtonGroup>
          </FormRow>
          <FormRow>
            <DateField
              id="as_of_date"
              light
              short
              datePickerType="single"
              labelText="As Of Date"
              disabled={priceType.value !== 'modified_strip_as_of'}
              field={priceAttributes.stripPriceDate}
            />
          </FormRow>
          <FormRow>
            <label className="bx--label">Modified Strip Cutoff (months)</label>
            <div className={style.twoFields}>
              <div>
                <NumberField
                  id="stripCutoffMonthsGas"
                  label="Gas"
                  light
                  field={priceAttributes.gasModifiedStripCutoffMonths}
                />
              </div>
              <div>
                <NumberField
                  id="stripCutoffMonthsOil"
                  label="Oil"
                  light
                  field={priceAttributes.oilModifiedStripCutoffMonths}
                />
              </div>
            </div>
          </FormRow>
          <FormRow>
            <NumberField
              id="modifiedStripOilScalingPercentage"
              label="Oil Price Scaling (%)"
              light
              field={priceAttributes.modifiedStripOilScalingPercentage}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="modifiedStripGasScalerPercentage"
              label="Gas Price Scaling (%)"
              light
              field={priceAttributes.modifiedStripGasScalingPercentage}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="oilFlatPrice"
              label="Oil ($/BBL)"
              light
              field={priceAttributes.oilFlatPrice}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="gasFlatPrice"
              label="Gas ($/MMBTU)"
              light
              field={priceAttributes.gasFlatPrice}
            />
          </FormRow>
        </>
      )}
    </FormGroup>
  );
};

const CashflowAssumptions = ({
  form,
}: {
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
  >;
}) => {
  const reservoirAssumptionAttributes = form.reservoirAssumptionAttributes;
  const differentialAttributes = form.differentialAttributes;
  const taxPercentageAttributes = form.taxPercentageAttributes;
  const validateForecastSourceFields = useValidateForecastSourceFieldsAtom(
    form.leaseholdAttributes
  );

  useEffect(() => {
    validateForecastSourceFields();
  }, [validateForecastSourceFields]);

  return (
    <>
      <Grid fullWidth condensed className={style.cashflowForm}>
        <Row>
          <Column md={8} lg={4}>
            <Tile>
              <h3 className={style.subtitle}>Reservoir Assumptions</h3>
              <FormGroup legendText="">
                <FormRow>
                  <NumberField
                    id="gasShrinkPercentage"
                    label="Gas Shrink (%)"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={reservoirAssumptionAttributes.gasShrinkPercentage}
                  />
                </FormRow>
                <FormRow>
                  <NumberField
                    id="nglYield"
                    label="NGL Yield (BBL/MMCF)"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={reservoirAssumptionAttributes.nglYield}
                  />
                </FormRow>
              </FormGroup>
              <EconomicLimits form={form} />
            </Tile>
          </Column>
          <Column md={8} lg={12}>
            <Tile>
              <Grid className="bx--no-gutter" fullWidth>
                <Row>
                  <Column md={8}>
                    <h3 className={style.subtitle}>Operational Assumptions</h3>
                  </Column>
                </Row>
                <Row>
                  <Column md={4}>
                    <FormGroup legendText="Differentials">
                      <FormRow>
                        <NumberField
                          id="oilDifferentialPrice"
                          label="Oil ($/BBL)"
                          light
                          field={differentialAttributes.oilPrice}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="gasDifferentialPrice"
                          label="Gas ($/MMBTU)"
                          light
                          field={differentialAttributes.gasPrice}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="nglDifferentialPercentage"
                          label="NGL (%)"
                          light
                          min={0}
                          invalidText="Must be positive number"
                          field={differentialAttributes.nglPercentage}
                        />
                      </FormRow>
                    </FormGroup>
                    <Prices form={form} />
                  </Column>
                  <Column md={4}>
                    <FormGroup legendText="Tax Rates">
                      <FormRow>
                        <NumberField
                          id="oilTaxPercentage"
                          label="Oil (%)"
                          light
                          field={taxPercentageAttributes.oil}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="gasTaxPercentage"
                          label="Gas (%)"
                          light
                          field={taxPercentageAttributes.gas}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="nglTaxPercentage"
                          label="NGL (%)"
                          light
                          field={taxPercentageAttributes.ngl}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="adValoremTaxPercentage"
                          label="Ad Valorem (%)"
                          light
                          field={taxPercentageAttributes.adValorem}
                        />
                      </FormRow>
                    </FormGroup>
                    <FormGroup legendText="">
                      <FormRow>
                        <NumberField
                          id="royaltyPercentage"
                          label="Royalty Rate (%)"
                          light
                          field={form.royaltyPercentage}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="orriPercentage"
                          label="ORRI (%)"
                          light
                          field={form.orriPercentage}
                        />
                      </FormRow>
                      <FormRow className={style.noMarginBottom}>
                        <FixedFloatingDate
                          id="cashflow-effective-date"
                          label="Effective Date"
                          dateType={form.effectiveDateType}
                          date={form.effectiveDate}
                          legendText="Effective Date"
                        />
                      </FormRow>
                      <FormRow>
                        <FixedFloatingDate
                          id="cashflow-discount-to-date"
                          label="Discount To Date"
                          dateType={form.discountToDateType}
                          date={form.discountToDate}
                          legendText="Discount To Date"
                        />
                      </FormRow>
                    </FormGroup>
                  </Column>
                </Row>
                <hr />
                <Row>
                  <Column md={4}>
                    <VariableCosts form={form} />
                    <CommissionAdjustments form={form} />
                  </Column>
                  <Column md={4}>
                    <FixedCosts form={form} />
                  </Column>
                </Row>
              </Grid>
            </Tile>
          </Column>
        </Row>
      </Grid>
    </>
  );
};

export { CashflowAssumptions };
