import {
  ApiPayloads,
  ImportedEntityAttributes,
  IndividualEntityAttributes,
  OrganizationEntityAttributes,
} from 'types/api-payloads';
import { NonUndefined, RecursiveOmit } from 'types/utils';

type EntityPayload = ApiPayloads['entity']['payload'];
type Notes = NonUndefined<EntityPayload['notes']>;

interface IndividualEntity extends EntityPayload {
  notes: Notes;
  concreteEntityAttributes: Required<IndividualEntityAttributes>;
}

interface OrganizationEntity extends EntityPayload {
  notes: Notes;
  concreteEntityAttributes: Required<OrganizationEntityAttributes>;
}

interface ImportedEntity extends EntityPayload {
  concreteEntityAttributes: Required<ImportedEntityAttributes>;
}

export type IndividualEntityForm = RecursiveOmit<IndividualEntity, '_destroy'>;
export type OrganizationEntityForm = RecursiveOmit<
  OrganizationEntity,
  '_destroy'
>;
export type ImportedEntityForm = RecursiveOmit<ImportedEntity, '_destroy'>;

export type PhoneAttributes = IndividualEntityForm['phoneNumbersAttributes'][0];

export type EmailAttributes =
  IndividualEntityForm['emailAddressesAttributes'][0];

export type AddressAttributes = IndividualEntityForm['addressesAttributes'][0];

export const organizationTypes = [
  'LLC',
  'Corporation',
  'General Partnership',
  'Limited Partnership',
  'Trust',
  'Life Estate',
  'JTWRS',
  'Marital Property',
  'State/Government Institution',
  'Church/Religious Institution',
] as const;
