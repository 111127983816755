import { AtomizedForm } from 'atoms/form-atoms';
import {
  Checkbox,
  Column,
  ContentSwitcher,
  FormGroup,
  Grid,
  RadioButton,
  RadioButtonGroup,
  Row,
  Switch,
  Tile,
} from 'carbon-components-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import style from './cashflow-assumption.module.scss';
import { useState } from 'react';
import { FormRow } from 'components/forms';
import { DateField } from 'components/form-fields/date-field';
import { NumberField } from 'components/form-fields/number-field';
import { useValidateSpudAndFirstDatePercentage } from 'lib/evaluation-parameters/validations';
import { DropdownField } from 'components/form-fields/dropdown-field';
import { CashflowAssumptionsParameters } from './types';

const getPriceTypeIndex = (
  priceType: CashflowAssumptionsParameters['priceAttributes']['kind']
) => {
  switch (priceType) {
    case 'strip_as_of':
    case 'strip_most_recent':
      return 0;
    case 'flat':
      return 1;
    case 'modified_strip_as_of':
    case 'modified_strip_most_recent':
      return 2;
  }
};

const Prices = ({
  form,
}: {
  form: AtomizedForm<CashflowAssumptionsParameters>;
}) => {
  const priceAttributes = form.priceAttributes;
  const [priceType, setPriceType] = useAtom(priceAttributes.kind);
  const [selectedIndex, setSelectedIndex] = useState(
    getPriceTypeIndex(priceType.value)
  );
  const setAsOfDate = useSetAtom(priceAttributes.stripPriceDate);

  const strip =
    priceType.value === 'strip_most_recent' ||
    priceType.value === 'strip_as_of';
  const flat = priceType.value === 'flat';
  const modified =
    priceType.value === 'modified_strip_most_recent' ||
    priceType.value === 'modified_strip_as_of';

  return (
    <FormGroup legendText="Prices">
      <FormRow>
        <ContentSwitcher
          selectedIndex={selectedIndex}
          onChange={(e) => {
            switch (e.index) {
              case 0:
                setPriceType('strip_most_recent');
                setSelectedIndex(0);
                break;
              case 1:
                setPriceType('flat');
                setSelectedIndex(1);
                break;
              case 2:
                setPriceType('modified_strip_most_recent');
                setSelectedIndex(2);
                break;
            }
          }}
        >
          <Switch text="Strip" />
          <Switch text="Flat" />
          <Switch text="Modified Strip" />
        </ContentSwitcher>
      </FormRow>
      {strip && (
        <>
          <FormRow>
            <RadioButtonGroup
              name="strip-price-radio"
              defaultSelected={priceType.value}
              orientation="horizontal"
              onChange={(
                value: CashflowAssumptionsParameters['priceAttributes']['kind']
              ) => {
                if (value === 'strip_most_recent') {
                  setAsOfDate('');
                }
                setPriceType(value);
              }}
            >
              <RadioButton
                labelText="Most Recent"
                value={'strip_most_recent'}
              />
              <RadioButton labelText="As Of:" value={'strip_as_of'} />
            </RadioButtonGroup>
          </FormRow>
          <FormRow>
            <DateField
              id="as_of_date"
              light
              short
              datePickerType="single"
              labelText="As Of Date"
              disabled={priceType.value !== 'strip_as_of'}
              field={priceAttributes.stripPriceDate}
            />
          </FormRow>
        </>
      )}
      {flat && (
        <>
          <FormRow>
            <NumberField
              id="oilFlatPrice"
              label="Oil ($/BBL)"
              light
              field={priceAttributes.oilFlatPrice}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="gasFlatPrice"
              label="Gas ($/MMBTU)"
              light
              field={priceAttributes.gasFlatPrice}
            />
          </FormRow>
        </>
      )}
      {modified && (
        <>
          <FormRow>
            <RadioButtonGroup
              name="modified-strip-price-radio"
              defaultSelected={priceType.value}
              orientation="horizontal"
              onChange={(
                value: CashflowAssumptionsParameters['priceAttributes']['kind']
              ) => {
                if (value === 'modified_strip_most_recent') {
                  setAsOfDate('');
                }
                setPriceType(value);
              }}
            >
              <RadioButton
                labelText="Most Recent"
                value={'modified_strip_most_recent'}
              />
              <RadioButton labelText="As Of:" value={'modified_strip_as_of'} />
            </RadioButtonGroup>
          </FormRow>
          <FormRow>
            <DateField
              id="as_of_date"
              light
              short
              datePickerType="single"
              labelText="As Of Date"
              disabled={priceType.value !== 'modified_strip_as_of'}
              field={priceAttributes.stripPriceDate}
            />
          </FormRow>
          <FormRow>
            <label className="bx--label">Modified Strip Cutoff (months)</label>
            <div className={style.twoFields}>
              <div>
                <NumberField
                  id="stripCutoffMonthsGas"
                  label="Gas"
                  light
                  field={priceAttributes.gasModifiedStripCutoffMonths}
                />
              </div>
              <div>
                <NumberField
                  id="stripCutoffMonthsOil"
                  label="Oil"
                  light
                  field={priceAttributes.oilModifiedStripCutoffMonths}
                />
              </div>
            </div>
          </FormRow>
          <FormRow>
            <NumberField
              id="modifiedStripOilScalingPercentage"
              label="Oil Price Scaling (%)"
              light
              field={priceAttributes.modifiedStripOilScalingPercentage}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="modifiedStripGasScalerPercentage"
              label="Gas Price Scaling (%)"
              light
              field={priceAttributes.modifiedStripGasScalingPercentage}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="oilFlatPrice"
              label="Oil ($/BBL)"
              light
              field={priceAttributes.oilFlatPrice}
            />
          </FormRow>
          <FormRow>
            <NumberField
              id="gasFlatPrice"
              label="Gas ($/MMBTU)"
              light
              field={priceAttributes.gasFlatPrice}
            />
          </FormRow>
        </>
      )}
    </FormGroup>
  );
};

const EconomicLimits = ({
  form,
}: {
  form: AtomizedForm<CashflowAssumptionsParameters>;
}) => {
  const economicLimitsAttributes = form.economicLimitAttributes;
  const [{ value: applyShutInLimit }, setApplyShutInLimit] = useAtom(
    economicLimitsAttributes.applyShutInLimit
  );

  return (
    <FormGroup legendText="Economic Limits">
      <FormRow>
        <Checkbox
          id={'applyShutInLimit'}
          checked={applyShutInLimit}
          onChange={(checked: boolean) => {
            setApplyShutInLimit(checked);
          }}
          labelText="Apply Single Well Economic Limits"
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="shutInLimit"
          label="Single Well Economic Limit ($/Month)"
          light
          field={economicLimitsAttributes.shutInLimit}
          disabled={!applyShutInLimit}
        />
      </FormRow>
    </FormGroup>
  );
};
const CashflowAssumptions = ({
  form,
}: {
  form: AtomizedForm<CashflowAssumptionsParameters>;
}) => {
  const reservoirAssumptionAttributes = form.reservoirAssumptionAttributes;
  const differentialAttributes = form.differentialAttributes;
  const taxPercentageAttributes = form.taxPercentageAttributes;
  const opexCostAttributes = form.leaseholdAttributes.opexCostAttributes;
  const waterForecastSource = useAtomValue(
    form.leaseholdAttributes.waterForecastSource
  );
  const loePerWellCostAttributes =
    form.leaseholdAttributes.loePerWellCostAttributes;
  const commissionAdjustment = form.commissionAdjustmentAttributes;
  return (
    <>
      <Grid fullWidth condensed className={style.cashflowForm}>
        <Row>
          <Column md={8} lg={4}>
            <Tile>
              <h3 className={style.subtitle}>Reservoir Assumptions</h3>
              <FormGroup legendText="">
                <FormRow>
                  <NumberField
                    id="gasShrinkPercentage"
                    label="Gas Shrink (%)"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={reservoirAssumptionAttributes.gasShrinkPercentage}
                  />
                </FormRow>
                <FormRow>
                  <NumberField
                    id="nglYield"
                    label="NGL Yield (BBL/MMCF)"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={reservoirAssumptionAttributes.nglYield}
                  />
                </FormRow>
              </FormGroup>
              <EconomicLimits form={form} />
            </Tile>
          </Column>
          <Column md={8} lg={12}>
            <Tile>
              <Grid className="bx--no-gutter" fullWidth>
                <Row>
                  <Column md={8}>
                    <h3 className={style.subtitle}>Operational Assumptions</h3>
                  </Column>
                </Row>
                <Row>
                  <Column md={4}>
                    <FormGroup legendText="Differentials">
                      <FormRow>
                        <NumberField
                          id="oilDifferentialPrice"
                          label="Oil ($/BBL)"
                          light
                          field={differentialAttributes.oilPrice}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="gasDifferentialPrice"
                          label="Gas ($/MMBTU)"
                          light
                          field={differentialAttributes.gasPrice}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="nglDifferentialPercentage"
                          label="NGL (%)"
                          light
                          min={0}
                          invalidText="Must be positive number"
                          field={differentialAttributes.nglPercentage}
                        />
                      </FormRow>
                    </FormGroup>
                    <Prices form={form} />
                  </Column>
                  <Column md={4}>
                    <FormGroup legendText="Tax Rates">
                      <FormRow>
                        <NumberField
                          id="oilTaxPercentage"
                          label="Oil (%)"
                          light
                          field={taxPercentageAttributes.oil}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="gasTaxPercentage"
                          label="Gas (%)"
                          light
                          field={taxPercentageAttributes.gas}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="nglTaxPercentage"
                          label="NGL (%)"
                          light
                          field={taxPercentageAttributes.ngl}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="adValoremTaxPercentage"
                          label="Ad Valorem (%)"
                          light
                          field={taxPercentageAttributes.adValorem}
                        />
                      </FormRow>
                    </FormGroup>
                    <FormGroup legendText="">
                      <FormRow>
                        <NumberField
                          id="royaltyPercentage"
                          label="Royalty Rate (%)"
                          light
                          field={form.royaltyPercentage}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="orriPercentage"
                          label="ORRI (%)"
                          light
                          field={form.orriPercentage}
                        />
                      </FormRow>
                      <FormRow>
                        <DateField
                          id="effectiveDate"
                          light
                          short
                          datePickerType="single"
                          labelText="Effective Date"
                          field={form.effectiveDate}
                        />
                      </FormRow>
                      <FormRow>
                        <DateField
                          id="discountToDate"
                          light
                          short
                          datePickerType="single"
                          labelText="Discount To Date"
                          field={form.discountToDate}
                        />
                      </FormRow>
                    </FormGroup>
                  </Column>
                </Row>
                <hr />
                <Row>
                  <Column md={4}>
                    <FormGroup legendText="Variable Costs">
                      <FormRow>
                        <NumberField
                          id="oilOpexCost"
                          label="Oil OpEx ($/BBL)"
                          light
                          field={opexCostAttributes.oil}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="gasOpexCost"
                          label="Gas OpEx ($/MCF)"
                          light
                          field={opexCostAttributes.gas}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="waterOpexCost"
                          label="Water OpEx ($/Barrel of Water)"
                          light
                          field={opexCostAttributes.water}
                        />
                      </FormRow>
                      <FormRow>
                        <DropdownField
                          id="waterForecastSource"
                          items={['oil', 'gas']}
                          label=""
                          titleText="Water Forecast Source"
                          light
                          field={form.leaseholdAttributes.waterForecastSource}
                        />
                      </FormRow>
                      {waterForecastSource.value === 'oil' ? (
                        <FormRow>
                          <NumberField
                            id="waterCutPercentage"
                            label="Water Cut (%) (Barrel of Water per Barrel of Liquid)"
                            light
                            field={form.leaseholdAttributes.waterCutPercentage}
                          />
                        </FormRow>
                      ) : (
                        <FormRow>
                          <NumberField
                            id="waterGasRatio"
                            label="Water Gas Ratio (BBL/MCF)"
                            light
                            field={
                              form.leaseholdAttributes.waterGasRatioInBblPerMmcf
                            }
                          />
                        </FormRow>
                      )}
                      <FormGroup legendText="Commission Adjustment (%)">
                        <FormRow>
                          <NumberField
                            id="targetCommissionPercentage"
                            light
                            min={0}
                            label="Target"
                            invalidText="Must be positive number"
                            field={commissionAdjustment.targetPercentage}
                          />
                        </FormRow>
                        <FormRow>
                          <NumberField
                            id="maxCommissionPercentage"
                            light
                            min={0}
                            label="Max"
                            invalidText="Must be positive number"
                            field={commissionAdjustment.maxPercentage}
                          />
                        </FormRow>
                        <FormRow>
                          <NumberField
                            id="maxCommissionPercentage"
                            light
                            min={0}
                            label="MTM"
                            invalidText="Must be positive number"
                            field={commissionAdjustment.markToMarketPercentage}
                          />
                        </FormRow>
                      </FormGroup>
                    </FormGroup>
                  </Column>
                  <Column md={4}>
                    <FormGroup legendText="Fixed Costs">
                      <FormRow>
                        <NumberField
                          id="dcCostPerWell"
                          label="Drilling & Completion Cost Per Well ($/10,000 ft lateral)"
                          light
                          field={form.leaseholdAttributes.dcCostPerWell}
                        />
                      </FormRow>
                      <PercentageCosts form={form} />
                      <p>Lease Operating Expense Per Well</p>
                      <FormRow>
                        <NumberField
                          id="loeCostPerWellYear1"
                          label="Year 1 ($/month)"
                          light
                          field={loePerWellCostAttributes.year1}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="loeCostPerWellYear2"
                          label="Year 2 ($/month)"
                          light
                          field={loePerWellCostAttributes.year2}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="loeCostPerWellYear3"
                          label="Year 3 ($/month)"
                          light
                          field={loePerWellCostAttributes.year3}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="loeCostPerWellYear4"
                          label="Year 4 ($/month)"
                          light
                          field={loePerWellCostAttributes.year4}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberField
                          id="loeCostPerWellYear5AndUp"
                          label="Year 5 and Up ($/month)"
                          light
                          field={loePerWellCostAttributes.year5AndUp}
                        />
                      </FormRow>
                    </FormGroup>
                  </Column>
                </Row>
              </Grid>
            </Tile>
          </Column>
        </Row>
      </Grid>
    </>
  );
};

const PercentageCosts = ({
  form,
}: {
  form: AtomizedForm<CashflowAssumptionsParameters>;
}) => {
  const spudAndFirstDatePercentageValidAtom =
    useValidateSpudAndFirstDatePercentage(
      form.leaseholdAttributes.percentageCostSpudDate,
      form.leaseholdAttributes.percentageCostFirstProductionDate
    );

  const spudAndFirstDatePercentageValid = useAtomValue(
    spudAndFirstDatePercentageValidAtom
  );

  const leaseholdAttributes = form.leaseholdAttributes;

  const spudPercentage = useAtomValue(
    form.leaseholdAttributes.percentageCostSpudDate
  );
  const firstDatePercentage = useAtomValue(
    form.leaseholdAttributes.percentageCostFirstProductionDate
  );

  const error = !spudAndFirstDatePercentageValid
    ? 'Spud Date and First Date percentage cost should sum 100%'
    : null;

  return (
    <>
      <FormRow>
        <NumberField
          id="percentageCostSpudDate"
          label="Percentage Cost SPUD Date"
          light
          field={leaseholdAttributes.percentageCostSpudDate}
          invalid={!spudAndFirstDatePercentageValid || !spudPercentage.isValid}
          invalidText={!spudPercentage.isValid ? spudPercentage.error : error}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="percentageCostFirstProductionDate"
          label="Percentage Cost First Production Date"
          light
          field={leaseholdAttributes.percentageCostFirstProductionDate}
          invalid={
            !spudAndFirstDatePercentageValid || !firstDatePercentage.isValid
          }
          invalidText={
            !firstDatePercentage.isValid ? firstDatePercentage.error : error
          }
        />
      </FormRow>
    </>
  );
};

export { CashflowAssumptions };
