import { AtomizedForm, atomField } from 'atoms/form-atoms';
import { PackageEvaluationForm } from './types';

const packageEvaluationForm = (): AtomizedForm<PackageEvaluationForm> => {
  return {
    scenarioIds: atomField<number[]>([], {
      validate: (v) => {
        if (!v.length) throw new Error('Required');
        return v;
      },
    }),
    combine: atomField<boolean>(false),
  };
};

export { packageEvaluationForm };
