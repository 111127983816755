import {
  Add16,
  Events32,
  SubtractAlt16,
  TrashCan16,
} from '@carbon/icons-react';
import { FormRow, FormSection } from 'components/forms';
import { useAtom } from 'jotai';
import { AtomizedRowForm } from 'atoms/form-atoms';
import {
  AtomizedTitleWorkspaceSettingsForm,
  StartingInterestEntry,
} from './types';
import { Button, Column, Row } from 'carbon-components-react';
import { entityRow } from './utils';
import { TrashButton } from 'components/trash-button';
import { AutoComplete } from 'components/autocomplete';
import { EntityBaseResponse } from 'types/api-responses';
import { useCallback } from 'react';
import { StringField } from './string-field';

const StartingInterestEntryRow = ({
  entry,
  index,
  onDelete,
}: {
  entry: AtomizedRowForm<StartingInterestEntry>;
  index: number;
  onDelete: (index: number) => void;
}) => {
  const [entity, setEntity] = useAtom(entry.entity);
  const [rowStatus, setRowStatus] = useAtom(entry._rowStatus);
  const status = rowStatus.value;
  return (
    <>
      <FormRow toTrash={status === 'trash'}>
        <Row>
          <Column lg={14} md={7}>
            <AutoComplete<EntityBaseResponse>
              id={`entity-autocomplete-${index}`}
              placeholder=""
              titleText="Entity"
              queryResource="entities"
              selectedItem={entity.value}
              status={status}
              selectedItemToString={(response) => {
                return response ? `${response.name}, ${response.id}` : '';
              }}
              onChange={(el) =>
                el.selectedItem !== null && setEntity(el.selectedItem)
              }
              light
              invalid={!entity.isValid}
              invalidText={!entity.isValid ? entity.error : ''}
              disabled={status === 'trash'}
            />
          </Column>
          <Column lg={1} md={1}>
            {index !== 0 ? (
              <TrashButton
                size="field"
                adjustForLabel
                description="Remove"
                iconDescription={
                  status === 'trash' ? 'Undo remove row' : 'Remove row'
                }
                kind={status === 'trash' ? 'danger' : 'ghost'}
                renderIcon={status === 'new' ? SubtractAlt16 : TrashCan16}
                onClick={() => {
                  if (status === 'saved') {
                    setRowStatus('trash');
                  } else if (status === 'trash') {
                    setRowStatus('saved');
                  } else {
                    onDelete(index);
                  }
                }}
              />
            ) : null}
          </Column>
        </Row>
      </FormRow>
      <FormRow toTrash={status === 'trash'}>
        <Row>
          <Column lg={8} md={4}>
            <StringField
              id="Surface"
              labelText="Surface"
              light
              field={entry.surface}
              disabled={status === 'trash'}
            />
          </Column>
          <Column lg={8} md={4}>
            <StringField
              id="Mineral"
              labelText="Mineral"
              light
              field={entry.mineral}
              disabled={status === 'trash'}
            />
          </Column>
        </Row>
      </FormRow>
    </>
  );
};

const StartingInterestList = ({
  form,
}: {
  form: AtomizedTitleWorkspaceSettingsForm;
}) => {
  const [rows, setRows] = useAtom(form.startingInterestEntriesAttributes);

  const onDelete = useCallback(
    (index: number) =>
      setRows((arr) => [...arr.slice(0, index), ...arr.slice(index + 1)]),
    [setRows]
  );

  return (
    <FormSection title="Entity" iconComponent={<Events32 />}>
      <span>
        Add Starting Interest to your Title Chain by entering one or more
        entities and their interests.
      </span>
      {rows.value.map((el, i) => {
        return (
          <FormSection title="" key={`${i}-interest-entry`}>
            <StartingInterestEntryRow
              entry={el}
              index={i}
              onDelete={onDelete}
            />
          </FormSection>
        );
      })}
      <FormRow>
        <Button
          renderIcon={Add16}
          kind={'ghost'}
          onClick={() => setRows((current) => [...current, entityRow()])}
        >
          Add Entity
        </Button>
      </FormRow>
    </FormSection>
  );
};

export { StartingInterestList };
