import classNames from 'classnames';
import { IconProps } from './types';
import { Checkmark32 } from '@carbon/icons-react';

import style from './icons.module.scss';

const Accepted = ({ className }: IconProps) => {
  return (
    <div className={classNames(style.background, style.accepted, className)}>
      <Checkmark32 className={classNames(style.icon, style.accepted)} />
    </div>
  );
};

export default Accepted;
