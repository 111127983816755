import { DefinitionList } from 'components/definition-list';
import { DefinitionDescription } from 'components/definition-list/definition-description';
import { DefinitionTerm } from 'components/definition-list/definition-term';

import style from './definition-entry.module.scss';

const DefinitionEntry = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element;
}) => {
  return (
    <DefinitionList className={style.definitionList}>
      <DefinitionTerm noBorder>{label}</DefinitionTerm>
      <DefinitionDescription>
        <span>{children}</span>
      </DefinitionDescription>
    </DefinitionList>
  );
};

export { DefinitionEntry };
