import * as go from 'gojs';

go.Diagram.licenseKey =
  '28f846e1bb6458c511d35a25403d7efb0eab2e61cf814da2590517f4ed0a614727cee12f54868dc7d3fe49fb197490d0d9963e2f951e0239ee6481dc46e2d6fcb76373b115581789f70122c68bea2da9ac7071fb92b323f2d87e8ca0e2fbc2c955edf6804fcd0ebb2e7e0439057aa943e3fbdb2fff01994c3e3d9da2aebeab1efb38728c8cf451ddbf5f718cefecb24e757114dc25f963a01b';

const highlightColor = 'red';

let chainDiagram;
function init(vertices, edges, treeDiv, overviewDiv) {
  let allVertices;
  // This is the single node graph returned when the calculacion was
  // big.
  if (vertices.length === 1) {
    allVertices = vertices;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, ...allVerticesButRoot] = vertices;
    allVertices = allVerticesButRoot;
  }

  // Transform the data to match the gojs graph model.
  const nodeDataArray = allVertices.map((el) => {
    const [name, ...rest] = el.label.split('\n');
    return {
      key: el.name,
      party: name,
      color: el.color === 'gray' ? '#FFFFFF' : '#B1E2A5',
      sticks: rest
        .filter((el) => !el.includes('none') && el)
        .map((el) => {
          if (
            el.includes('Royalty: ') &&
            !el.includes('Fixed Royalty:') &&
            el.length > 100
          ) {
            return {
              text: 'Royalty: Hover node to see list',
              elements: el.replace('Royalty: ', '').split(','),
            };
          } else if (el.includes('Fixed Royalty:') && el.length > 100) {
            return {
              text: 'Fixed Royalty: Hover node to see list',
              elements: el.replace('Fixed Royalty: ', '').split(','),
            };
          } else {
            return { text: el };
          }
        }),
    };
  });

  // for conciseness in defining templates
  const $ = go.GraphObject.make;

  // Defines the title chain diagram (main tree)
  chainDiagram = $(go.Diagram, {
    'allowCopy': false,
    'draggingTool.dragsTree': true,
    'commandHandler.deletesTree': true,
    'toolManager.hoverDelay': 100,
    'layout': $(go.LayeredDigraphLayout, { layerSpacing: 250 }),
    'undoManager.isEnabled': true,
    'initialAutoScale': go.Diagram.UniformToFill,
  });
  chainDiagram.div = treeDiv;

  // each action is represented by a shape and some text
  const sticksTemplate = $(
    go.Panel,
    'Horizontal',
    { margin: new go.Margin(0, 50, 0, 0) },
    $(go.TextBlock, textStyle(), new go.Binding('text'))
  );

  const tooltipTemplate = $(
    'ToolTip',
    { 'Border.fill': 'whitesmoke', 'Border.stroke': 'black' },
    new go.Binding('visible', '', (data) => {
      for (const el of data.sticks) {
        if (el.elements) return true;
      }

      return false;
    }),
    $(
      go.TextBlock,
      {
        font: '12px IBM Plex Sans, sans-serif',
        wrap: go.TextBlock.WrapFit,
        margin: new go.Margin(5, 20, 5, 5),
      },
      new go.Binding('text', '', interests)
    )
  );

  function interests(data) {
    return data.sticks.reduce((acc, stick) => {
      if (stick.elements) {
        acc += stick.elements.join('\n');
        return acc;
      }
      return acc;
    }, '');
  }

  const roundedRectangleParams = {
    parameter1: 2, // set the rounded corner
    spot1: go.Spot.TopLeft,
    spot2: go.Spot.BottomRight, // make content go all the way to inside edges of rounded corners
  };

  const ml8 = new go.Margin(0, 0, 0, 8);

  chainDiagram.nodeTemplate = $(
    go.Node,
    'Auto',
    { toolTip: tooltipTemplate },
    new go.Binding('isTreeExpanded').makeTwoWay(), // remember the expansion state for
    new go.Binding('wasTreeExpanded').makeTwoWay(), //   when the model is re-loaded
    {
      locationSpot: go.Spot.Top,
      shadowBlur: 1,
      shadowOffset: new go.Point(0, 1),
      shadowColor: 'rgba(0, 0, 0, .14)',
      selectionObjectName: 'SHAPE',
      // selection adornment to match shape of nodes
      selectionAdornmentTemplate: $(
        go.Adornment,
        'Auto',
        $(go.Shape, 'RoundedRectangle', roundedRectangleParams, {
          fill: null,
          stroke: '#7986cb',
          strokeWidth: 3,
        }),
        $(go.Placeholder)
      ), // end Adornment
      mouseEnter: (e, node) => {
        node.diagram.clearHighlighteds();
        node.linksConnected.each((l) => highlightLink(l, true));
        node.isHighlighted = true;
        const tb = node.findObject('TEXTBLOCK');
        if (tb !== null) tb.stroke = highlightColor;
      },
      mouseLeave: (e, node) => {
        node.diagram.clearHighlighteds();
        const tb = node.findObject('TEXTBLOCK');
        if (tb !== null) tb.stroke = 'black';
      },
    },
    $(
      go.Shape,
      'RoundedRectangle',
      roundedRectangleParams,
      {
        name: 'SHAPE',
        fill: '#ffffff',
        strokeWidth: 0,
      },
      // gold if highlighted, white otherwise
      new go.Binding('fill', 'isHighlighted', (h, targetObj) => {
        return h ? 'gold' : targetObj.part.data.color;
      }).ofObject()
    ),
    $(
      go.Panel,
      'Vertical',
      $(
        go.Panel,
        'Horizontal',
        { margin: 8 },
        /* The idea here is to show individual vs entity when we get more info in the graph */
        // $(
        //   go.Picture, // flag image, only visible if a nation is specified
        //   { margin: mr8, desiredSize: new go.Size(50, 50) },
        //   new go.Binding('source', 'party', getPictureUrl)
        // ),
        $(
          go.Panel,
          'Table',
          $(
            go.TextBlock,
            {
              row: 0,
              alignment: go.Spot.Left,
              font: '16px IBM Plex Sans, sans-serif',
              stroke: 'rgba(0, 0, 0, .87)',
            },
            new go.Binding('text', 'party')
          ),
          $('PanelExpanderButton', 'INFO', {
            row: 0,
            column: 1,
            rowSpan: 2,
            margin: ml8,
          })
        )
      ),
      $(
        go.Shape,
        'LineH',
        {
          stroke: 'rgba(0, 0, 0, .60)',
          strokeWidth: 1,
          height: 1,
          stretch: go.GraphObject.Horizontal,
        },
        new go.Binding('visible').ofObject('INFO') // only visible when info is expanded
      ),
      $(
        go.Panel,
        'Vertical',
        {
          name: 'INFO', // identify to the PanelExpanderButton
          padding: 5,
          stretch: go.GraphObject.Horizontal, // take up whole available width
          defaultAlignment: go.Spot.Left, // thus no need to specify alignment on each element
          itemTemplate: sticksTemplate, // the Panel created for each item in Panel.itemArray,
        },
        new go.Binding('itemArray', 'sticks') // bind Panel.itemArray to nodedata.actions
      ) // end action list Vertical Panel
    )
  );

  chainDiagram.linkTemplate = $(
    go.Link,
    { routing: go.Link.Normal, curve: go.Link.Bezier },
    $(
      go.Shape,
      new go.Binding('stroke', 'isHighlighted', (h) => {
        return h ? highlightColor : 'rgb(160, 160, 160)';
      }).ofObject(),
      new go.Binding('strokeWidth', 'isHighlighted', (h) => {
        return h ? 2 : 1;
      }).ofObject()
    ),
    $(
      go.TextBlock, // the "from" label
      {
        textAlign: 'center',
        font: '12px IBM Plex Sans, sans-serif',
        segmentIndex: 0,
        segmentOffset: new go.Point(NaN, -10),
        segmentOrientation: go.Link.None,
      },
      new go.Binding('text', 'date')
    ),
    $(
      go.TextBlock, // the "to" label
      {
        textAlign: 'center',
        font: '12px IBM Plex Sans, sans-serif',
        segmentIndex: -1,
        segmentOffset: new go.Point(NaN, 10),
        segmentOrientation: go.Link.None,
      },
      new go.Binding('text', 'document_name')
    )
  );

  // create the Model with the above data, and assign to the Diagram
  const model = new go.GraphLinksModel({
    copiesArrays: true,
    copiesArrayObjects: true,
    nodeDataArray,
    linkDataArray: edges,
  });
  chainDiagram.model = model;

  const myOverview = $(go.Overview, {
    observed: chainDiagram,
    contentAlignment: go.Spot.Center,
  });

  myOverview.div = overviewDiv;
}

function textStyle() {
  return [
    {
      font: '12px IBM Plex Sans, sans-serif',
      stroke: 'rgba(0, 0, 0, .60)',
    },
  ];
}

function highlightLink(link, show) {
  link.isHighlighted = show;
}

function searchDiagram(term) {
  chainDiagram.startTransaction('highlight search');

  if (term !== '') {
    chainDiagram.focus();
    // search four different data properties for the string, any of which may match for success
    // create a case insensitive RegExp from what the user typed
    const safe = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(safe, 'i');
    const results = chainDiagram.findNodesByExample({ party: regex });
    chainDiagram.highlightCollection(results);
    // try to center the diagram at the first node that was found
    if (results.count > 0)
      chainDiagram.centerRect(results.first().actualBounds);
  } else {
    // empty string only clears highlighteds collection
    chainDiagram.clearHighlighteds();
  }

  chainDiagram.commitTransaction('highlight search');
}

function updateLayout(newLayerSpacing) {
  if (!chainDiagram) return;
  chainDiagram.startTransaction('change Layout');
  const lay = chainDiagram.layout;
  const layerSpacing = parseFloat(newLayerSpacing, 10);
  lay.layerSpacing = layerSpacing;
  chainDiagram.commitTransaction('change Layout');
}

function clearSelection() {
  chainDiagram.clearHighlighteds();
}

export { init, searchDiagram, clearSelection, updateLayout };
