import { PackageEvaluationResponse } from 'types/packages-api/responses';
import style from './summary.module.scss';
import { Link } from 'components/link';
import { capitalizeFirstLetter } from 'utils/strings';

const Summary = ({
  data,
  isCombined,
}: {
  isCombined: boolean;
  data: PackageEvaluationResponse;
}) => {
  return (
    <div className={style.evaluationData}>
      {!isCombined && (
        <dl>
          <dt>Scenario</dt>
          <dd>
            <Link to={data.scenario.href} target="_blank">
              {data.scenario.name}
            </Link>
          </dd>
        </dl>
      )}
      <dl>
        <dt>Interest Type</dt>
        <dd>
          {isCombined
            ? 'All'
            : capitalizeFirstLetter(data.scenario.interestType)}
        </dd>
      </dl>
    </div>
  );
};

export { Summary };
