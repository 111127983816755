import { atom } from 'jotai';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
  TransactionPartyResponse,
} from 'types/api-responses';
import { getInterpretationIndices } from './utils';
import { useMemo } from 'react';
import { createResourceAtom } from 'lib/atoms';
import { createMutationAtom } from 'atoms/create-resource-atom';

/**
 * Tracks the ids of removed location referees:
 * The main goal is not having to fetch the document
 * again after removing a location.
 */
const removedAreasAtom = atom<number[]>([]);

const useAreasAtom = (
  interpretations: DocumentInterpretationResponse[] | undefined,
  locations: DocumentResponse['locations']
) => {
  return useMemo(
    () =>
      atom((get) => {
        const removedAreas = get(removedAreasAtom);

        return (locations || [])
          .map((el) => {
            return !removedAreas.includes(el.id)
              ? {
                  id: el.id,
                  name: el.referenceLocation.name,
                  quarterCalls: el.quarterCalls.join(', '),
                  href: el.href,
                  interpretationIndices: !interpretations
                    ? null
                    : getInterpretationIndices(
                        interpretations,
                        'location',
                        el.id
                      ),
                }
              : undefined;
          })
          .flatMap((el) => (el ? [el] : []));
      }),
    [interpretations, locations]
  );
};

/**
 * Tracks the ids of removed entities referees:
 * - Grantors
 * - Grantees
 * The main goal is not having to fetch the document
 * again after removing an entity.
 */
const removedPartiesAtom = atom<number[]>([]);

const usePartiesAtom = (
  interpretations: DocumentInterpretationResponse[] | undefined,
  grantors: DocumentResponse['grantors'],
  grantees: DocumentResponse['grantees']
) => {
  return useMemo(
    () =>
      atom((get) => {
        const removedParties = get(removedPartiesAtom);

        if (!document) {
          return [];
        }

        return grantors
          .map((el) => {
            return !removedParties.includes(el.id)
              ? {
                  type: 'Grantor',
                  entityId: el.id,
                  name: el.name,
                  href: el.href,
                  interpretationIndices: !interpretations
                    ? null
                    : getInterpretationIndices(
                        interpretations,
                        'grantor',
                        el.id
                      ),
                }
              : undefined;
          })
          .concat(
            grantees.map((el) => {
              return !removedParties.includes(el.id)
                ? {
                    type: 'Grantee',
                    entityId: el.id,
                    name: el.name,
                    href: el.href,
                    interpretationIndices: !interpretations
                      ? null
                      : getInterpretationIndices(
                          interpretations,
                          'grantee',
                          el.id
                        ),
                  }
                : undefined;
            })
          )
          .flatMap((el) => (el ? [el] : []));
      }),
    [grantees, grantors, interpretations]
  );
};

const useReferencedDocumentsAtom = (
  interpretations: DocumentInterpretationResponse[] | undefined,
  referencedDocuments: DocumentResponse['referencedDocuments']
) => {
  return useMemo(
    () =>
      atom(() => {
        if (!referencedDocuments) {
          return [];
        }

        return referencedDocuments.map((el) => ({
          id: el.id,
          title: el.documentTitle,
          bookType: el.bookType,
          documentType: el.documentType,
          href: el.href,
          interpretationIndices: !interpretations
            ? null
            : getInterpretationIndices(interpretations, 'document', el.id),
        }));
      }),
    [interpretations, referencedDocuments]
  );
};

const getReferenceLocationIds = (document: DocumentResponse) => {
  return (document.locations || []).map((el) => el.referenceLocation.id);
};

const getLocationGeometries = (document: DocumentResponse) => {
  return (document.locations || []).map((el) => ({
    geometry: el.geometry,
    name: el.referenceLocation.name,
    id: el.id,
    quarterCalls: el.quarterCalls,
  }));
};

const useTransactionPartiesAtom = (href: string) => {
  return useMemo(
    () => createResourceAtom<TransactionPartyResponse[]>(href),
    [href]
  );
};

const hoveredLocationsAtom = atom<number[]>([]);
const runMutationAtom = createMutationAtom<null, 'deleteResource'>();

export {
  getLocationGeometries,
  getReferenceLocationIds,
  hoveredLocationsAtom,
  removedAreasAtom,
  removedPartiesAtom,
  runMutationAtom,
  useAreasAtom,
  usePartiesAtom,
  useReferencedDocumentsAtom,
  useTransactionPartiesAtom,
};
