import { ArrowLeft16, ArrowRight16 } from '@carbon/icons-react';
import {
  Button,
  Grid,
  InlineLoading,
  InlineNotification,
  Row,
  TooltipDefinition,
} from 'carbon-components-react';
import { DatePicker } from 'components/date-picker';
import { addDays, subDays } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { TitleCalculationResponse } from 'types/api-responses';
import {
  getCurrentDateString,
  getDateString,
  parseDateString,
} from 'utils/dates';
import { calculationDateAtom, lastCalculationAtom } from './atoms';
import style from './calculate-form.module.scss';

const CalculateFormImpl = ({
  loading,
  calculation,
}: {
  loading?: boolean;
  calculation: TitleCalculationResponse | undefined;
}) => {
  const [calculationDate, setCalculationDate] = useAtom(calculationDateAtom);
  if (calculation && calculationDate === null) {
    setCalculationDate(calculation.asOfDate);
  }

  const handleDateChange = (date: Date) => {
    setCalculationDate(getDateString(date) ?? '');
  };

  const incrementDate = () => {
    const parsedDate = parseDateString(calculationDate);
    if (parsedDate) {
      const newDate = addDays(parsedDate, 1);
      handleDateChange(newDate);
    }
  };

  const decrementDate = () => {
    const parsedDate = parseDateString(calculationDate);
    if (parsedDate) {
      const newDate = subDays(parsedDate, 1);
      handleDateChange(newDate);
    }
  };
  return (
    <>
      <Grid condensed fullWidth className={style.datePickerPanel}>
        <Row>
          <div className={style.calculateActions}>
            <TooltipDefinition
              direction="top"
              align="center"
              tooltipText="Previous date"
              className={style.toolTip}
            >
              <ArrowLeft16 onClick={decrementDate} />
            </TooltipDefinition>
            <DatePicker
              id="as-of-date"
              labelText="As of:"
              size="md"
              value={
                !loading
                  ? calculationDate === null
                    ? ''
                    : calculationDate
                  : ''
              }
              onChange={(date: Date[]) => handleDateChange(date[0])}
            />
            <TooltipDefinition
              className={style.toolTip}
              direction="top"
              align="center"
              tooltipText="Next date"
            >
              <ArrowRight16 onClick={incrementDate} />
            </TooltipDefinition>
            <Button
              size="md"
              kind="ghost"
              onClick={() => {
                setCalculationDate(getCurrentDateString());
              }}
            >
              Today
            </Button>
          </div>
        </Row>
      </Grid>
    </>
  );
};

const CalculateForm = ({ loading }: { loading?: boolean }) => {
  const calculation = useAtomValue(lastCalculationAtom);

  if (!calculation)
    return <CalculateFormImpl loading={loading} calculation={undefined} />;

  if (calculation.data && 'error' in calculation.data)
    return (
      <InlineNotification
        kind="error"
        title={calculation.data.error}
        lowContrast
      />
    );
  if (calculation.loading || !calculation.data) return <InlineLoading />;

  return <CalculateFormImpl calculation={calculation.data} loading={loading} />;
};

export { CalculateForm };
