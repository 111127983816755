import { PageHeader } from 'components/page-header';
import { DocumentForm } from './form';
import { TitleWorkspaceReferrer } from 'components/title-workspace-referrer/title-workspace-referrer';
import { useLocation } from 'react-router-dom';

const NewDocumentPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referrer = params.get('referrer');
  return (
    <>
      <PageHeader
        breadcrumbs={[
          ['/documents', 'Documents'],
          ['/documents/new', 'New Document'],
        ]}
        title="Document Creation"
        referrer={
          referrer ? <TitleWorkspaceReferrer referrer={referrer} /> : null
        }
        actions={null}
      />
      <DocumentForm />
    </>
  );
};

export { NewDocumentPage };
