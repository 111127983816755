import { Tab, Tabs, Tile } from 'carbon-components-react';
import { Aliases } from './aliases';
import { Capacities } from './capacities';

import style from './entity-tabs.module.scss';
import { useState } from 'react';
import { Assets } from './assets';
import classNames from 'classnames';
import { RunsheetSearch } from 'components/runsheet/common/runsheet-search';
import { EntityResponse } from 'types/api-responses';
import { Inventory } from './inventory';
import { Packages } from './packages';

const EntityTabs = ({ entity }: { entity: EntityResponse }) => {
  const [selected, setSelected] = useState(0);
  return (
    <Tabs
      className={classNames(style.tabsContained, style.anchorSection)}
      id="relationships"
      selected={selected}
    >
      <Tab
        label="Aliases"
        key="aliases-tab"
        onClick={() => {
          setSelected(0);
        }}
      >
        {selected === 0 && (
          <Tile className={style.tileTab}>
            <Aliases entity={entity} />
          </Tile>
        )}
      </Tab>
      <Tab
        label="Capacities"
        key="capacities-tab"
        onClick={() => {
          setSelected(1);
        }}
      >
        {selected === 1 && (
          <Tile className={style.tileTab}>
            <Capacities currentEntity={entity} />
          </Tile>
        )}
      </Tab>
      <Tab
        label="Interpretations"
        key="interpretations-tab"
        onClick={() => setSelected(2)}
      >
        {selected === 2 && (
          <Tile className={style.tileRunsheetTab}>
            <RunsheetSearch
              searchHref={entity?.resources.queries.interpretations.href}
            />
          </Tile>
        )}
      </Tab>
      <Tab
        label="Title Assets"
        key="assets-tab"
        onClick={() => {
          setSelected(3);
        }}
      >
        {selected === 3 && (
          <Tile className={style.tileTab}>
            <Assets entity={entity} />
          </Tile>
        )}
      </Tab>
      {entity.hasInventory && (
        <Tab
          label="Inventory"
          key="inventory-tab"
          onClick={() => {
            setSelected(4);
          }}
        >
          {selected === 4 && (
            <Tile className={style.tileTab}>
              <Inventory entity={entity} />
            </Tile>
          )}
        </Tab>
      )}
      {entity.hasInventory && (
        <Tab
          label="Packages"
          key="packages-tab"
          onClick={() => {
            setSelected(5);
          }}
        >
          {selected === 5 && (
            <Tile className={style.tileTab}>
              <Packages entity={entity} />
            </Tile>
          )}
        </Tab>
      )}
    </Tabs>
  );
};

export { EntityTabs };
