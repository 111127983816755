import { FORMATTER, DECIMAL_FORMATTER_TWO_FRACTIONS } from 'lib/ui';
import {
  Column,
  Grid,
  Row,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  Tile,
  InlineNotification,
  InlineLoading,
  SkeletonText,
  TabsSkeleton,
  DataTableSkeleton,
  TooltipDefinition,
  Button,
} from 'carbon-components-react';
import { ChevronLeft16, ChevronRight16 } from '@carbon/icons-react';
import { EmptyState } from 'components/empty-state';
import { Default } from 'components/icons';
import { SummarySkeleton } from './summary-skeleton';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import {
  DevelopmentAreaEvaluationResponse,
  EvalEvaluationSummary,
  MOICPrice,
  EvalCommonPriceCases,
  EvalCommonPriceCase,
  EvalCashflows,
  IRRPrice,
  EvalSlotResponse,
  DevelopmentAreaEvaluationsResponse,
} from 'types/evaluation-api/responses';
import { useEvaluationAtom } from './atoms';
import { EvaluationForm } from './evaluation-form';
import { Slots } from './evaluation-form/forms/slots';
import { Date } from 'components/date';

import style from './evaluations.module.scss';
import { VolumesByMonth } from './volumes-chart';

import pricingIdentifiers from 'enums/pricing_identifiers.json';
import {
  EvaluationConfigAtomValue,
  defaultAssumptionsAtom,
  saveEvaluationConfigAtom,
  showDraftAtom,
} from './evaluation-form/atoms';
import { Route } from 'components/route';
import { LegacyNtdAttributes } from 'types/api-payloads';
import { EvaluationCount } from 'components/evaluations/evaluation-count';
import { EvaluationList } from 'components/evaluations/evaluation-list';
import { CashflowOutputSwitch, Sensitivities } from 'components/sensitivities';
import { useDownloadResource } from 'lib/hooks/useDownloadFile';
import { DownloadButton } from 'components/download-button';
import { Comments } from 'components/events-panel';

type PricingIdentifiers = keyof typeof pricingIdentifiers;

const Summary = ({ data }: { data: EvalEvaluationSummary | undefined }) => {
  if (!data) return <SummarySkeleton />;
  return (
    <div className={style.evaluationData}>
      <dl>
        <dt>Acres</dt>
        <dd>
          {data.numberOfAcres.toLocaleString()} <span>acres</span>
        </dd>
      </dl>
      <dl>
        <dt>Royalty Acres</dt>
        <dd>
          {data.numberOfRoyaltyAcres.toLocaleString()} <span>acres</span>
        </dd>
      </dl>
      <dl>
        <dt>Average Well Drilled</dt>
        <dd>
          {data.averageYearsUntilProduction ? (
            <>
              {data.averageYearsUntilProduction.toLocaleString()}{' '}
              <span>years</span>
            </>
          ) : (
            '--'
          )}
        </dd>
      </dl>
    </div>
  );
};

const LegacyNTDDrillingAssumptions = ({
  ntd,
}: {
  ntd: LegacyNtdAttributes;
}) => {
  return (
    <>
      <h3>NTD Pace Assumptions</h3>
      <TableContainer>
        <Table
          aria-label={`ntd-assumptions-params`}
          size="sm"
          className={style.drillingTable}
        >
          <TableHead>
            <TableRow>
              <TableHeader scope="col">Parameter</TableHeader>
              <TableHeader scope="col">Value</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>
                {ntd.kind === 'fractional_wells'
                  ? 'Fractional Wells'
                  : 'Discrete'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Use Blended Type Curves</TableCell>
              <TableCell>{ntd.typeCurveBlend ? 'Yes' : 'No'}</TableCell>
            </TableRow>
            {ntd.kind === 'discrete' ? (
              <>
                <TableRow>
                  <TableCell>Spud to Production (Months)</TableCell>
                  <TableCell>{ntd.spudToProductionMonths}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Permit to Production Months</TableCell>
                  <TableCell>{ntd.permitToProductionMonths}</TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>
                    <Date date={ntd.startDate} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Spud to Production (Months)</TableCell>
                  <TableCell>{ntd.spudToProductionMonths}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DUC Start Month</TableCell>
                  <TableCell>{ntd.ducStartMonth}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DUC End Month</TableCell>
                  <TableCell>{ntd.ducEndMonth}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Permit Start Month</TableCell>
                  <TableCell>{ntd.permitStartMonth}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Permit End Month</TableCell>
                  <TableCell>{ntd.permitEndMonth}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const NTDDrillingAssumptions = ({
  ntd,
  title,
}: {
  ntd:
    | DevelopmentAreaEvaluationResponse['drillingAssumption']['permit']
    | DevelopmentAreaEvaluationResponse['drillingAssumption']['duc'];
  title: 'Permit' | 'Duc';
}) => {
  if (!ntd) return null;
  return (
    <>
      <h3>{`${title} Pace Assumptions`}</h3>
      <TableContainer>
        <Table
          aria-label={`ntd-assumptions-params`}
          size="sm"
          className={style.drillingTable}
        >
          <TableHead>
            <TableRow>
              <TableHeader scope="col">Parameter</TableHeader>
              <TableHeader scope="col">Value</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>
                {ntd.kind === 'fractional_wells'
                  ? 'Fractional Wells'
                  : 'Discrete'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Use Blended Type Curves</TableCell>
              <TableCell>{ntd.typeCurveBlend ? 'Yes' : 'No'}</TableCell>
            </TableRow>
            {ntd.kind === 'discrete' ? (
              <>
                <TableRow>
                  <TableCell>Spud to Production (Months)</TableCell>
                  <TableCell>{ntd.spudToProductionMonths}</TableCell>
                </TableRow>
                {'permitToProductionMonths' in ntd ? (
                  <TableRow>
                    <TableCell>Permit to Production Months</TableCell>
                    <TableCell>{ntd.permitToProductionMonths}</TableCell>
                  </TableRow>
                ) : null}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>
                    <Date date={ntd.startDate} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Spud to Production (Months)</TableCell>
                  <TableCell>{ntd.spudToProductionMonths}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start Month</TableCell>
                  <TableCell>{ntd.startMonth}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>End Month</TableCell>
                  <TableCell>{ntd.endMonth}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const DrillingAssumptions = ({
  assumptions,
  volumes,
  hideInputs = false,
}: {
  assumptions: DevelopmentAreaEvaluationResponse['drillingAssumption'];
  volumes: DevelopmentAreaEvaluationResponse['volumesByMonth'];
  hideInputs?: boolean;
}) => {
  const typeMap: Record<
    DevelopmentAreaEvaluationResponse['drillingAssumption']['ltd']['kind'],
    string
  > = {
    fractional_wells: 'Fractional Wells',
    per_dev_area: 'Per Dev Area',
    target_most_recent: 'Target Average Well Drilled',
    target_as_of: 'Target As Of Date',
  };

  const ltd = assumptions.ltd;
  const ltdKind = ltd.kind;

  return (
    <Grid className="bx--no-gutter" fullWidth>
      {!hideInputs ? (
        <Row>
          <Column md={4}>
            <h3>LTD Pace Assumptions</h3>
            <TableContainer>
              <Table
                aria-label={`ltd-assumptions-params`}
                size="sm"
                className={style.drillingTable}
              >
                <TableHead>
                  <TableRow>
                    <TableHeader scope="col">Parameter</TableHeader>
                    <TableHeader scope="col">Value</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>{typeMap[ltdKind]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Use Blended Type Curves</TableCell>
                    <TableCell>{ltd.typeCurveBlend ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  {(ltdKind === 'target_most_recent' ||
                    ltdKind === 'target_as_of') && (
                    <>
                      <TableRow>
                        <TableCell>Target Date Type</TableCell>
                        <TableCell>
                          {ltdKind === 'target_most_recent'
                            ? 'Most Recent'
                            : `"As Of" ${ltd.asOfDate}`}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Years</TableCell>
                        <TableCell>{ltd.averageWellDrilledYears}</TableCell>
                      </TableRow>
                    </>
                  )}
                  {ltdKind === 'per_dev_area' && (
                    <TableRow>
                      <TableCell>Months Between LTD</TableCell>
                      <TableCell>{ltd.drillingIntervalMonths}</TableCell>
                    </TableRow>
                  )}
                  {ltdKind !== 'fractional_wells' && (
                    <>
                      <TableRow>
                        <TableCell>First LTD Start Date</TableCell>
                        <TableCell>
                          <Date date={ltd.firstDrillingStartDate} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Spud to Production Months</TableCell>
                        <TableCell>{ltd.spudToProductionMonths}</TableCell>
                      </TableRow>
                    </>
                  )}
                  {ltdKind === 'fractional_wells' && (
                    <>
                      <TableRow>
                        <TableCell>Start Date</TableCell>
                        <TableCell>
                          <Date date={ltd.startDate} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Average Well Drilled (Years)</TableCell>
                        <TableCell>{ltd.averageWellDrilledYears}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Drill Start (Months)</TableCell>
                        <TableCell>{ltd.drillStartMonths}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Drill Offset (Months)</TableCell>
                        <TableCell>{ltd.drillOffsetMonths}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Spud to Production (Months)</TableCell>
                        <TableCell>{ltd.spudToProductionMonths}</TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Column>
          {assumptions.ntd && (
            <Column md={4}>
              <LegacyNTDDrillingAssumptions ntd={assumptions.ntd} />
            </Column>
          )}
          {assumptions.permit && (
            <Column md={4}>
              <NTDDrillingAssumptions
                ntd={assumptions.permit}
                title={'Permit'}
              />
            </Column>
          )}
          {assumptions.duc && (
            <Column md={4}>
              <NTDDrillingAssumptions ntd={assumptions.duc} title={'Duc'} />
            </Column>
          )}
        </Row>
      ) : (
        <Row>
          <Column>
            <VolumesByMonth volumes={volumes} />
          </Column>
        </Row>
      )}
    </Grid>
  );
};

const CashflowAssumptions = ({
  assumptions,
}: {
  assumptions: DevelopmentAreaEvaluationResponse['cashflowAssumption'];
}) => {
  const hasPrice =
    assumptions.price.gasFlatPrice !== null &&
    assumptions.price.oilFlatPrice !== null;
  const pricingKind = assumptions.price.kind;
  const modifiedPricing = pricingKind.includes('modified');

  const reservoirAssumption = assumptions.reservoirAssumption;
  const differential = assumptions.differential;
  const taxPercentage = assumptions.taxPercentage;
  const price = assumptions.price;
  const opexCost = assumptions.leasehold.opexCost;
  const loePerWellCost = assumptions.leasehold.loePerWellCost;
  const waterForecastSource = assumptions.leasehold.waterForecastSource;
  const commissionAdjustments = assumptions.commissionAdjustment;
  const economicLimits = assumptions.economicLimit;

  return (
    <Grid className="bx--no-gutter" condensed fullWidth>
      <Row>
        <Column>
          <h3>Reservoir Assumptions</h3>
          <TableContainer>
            <Table
              aria-label={`cashflow-reservoir`}
              size="sm"
              className={style.reservoirTable}
            >
              <TableHead>
                <TableRow>
                  <TableHeader scope="col">Gas Shrink</TableHeader>
                  <TableHeader scope="col">NGL Yield</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                      reservoirAssumption.gasShrinkPercentage
                    )}
                    %
                  </TableCell>
                  <TableCell>{reservoirAssumption.nglYield} BBL/MMCF</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <h3>Economic Limits</h3>
          <TableContainer>
            <Table aria-label={`economic-limits`} size="sm">
              <TableHead>
                <TableRow>
                  <TableHeader scope="col">
                    Single Well Economic Limit ($/Month)
                  </TableHeader>
                  <TableHeader scope="col">DUC Hurdle Rate</TableHeader>
                  <TableHeader scope="col">Permit Hurdle Rate</TableHeader>
                  <TableHeader scope="col">LTD Hurdle Rate</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {economicLimits &&
                    typeof economicLimits.shutInLimit === 'number' &&
                    economicLimits.applyShutInLimit
                      ? `$${economicLimits.shutInLimit}`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {economicLimits &&
                    typeof economicLimits.ducHurdleRate === 'number' &&
                    economicLimits.applyDUCHurdleRate
                      ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          economicLimits.ducHurdleRate
                        )}%`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {economicLimits &&
                    typeof economicLimits.permitHurdleRate === 'number' &&
                    economicLimits.applyPermitHurdleRate
                      ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          economicLimits.permitHurdleRate
                        )}%`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {economicLimits &&
                    typeof economicLimits.ltdHurdleRate === 'number' &&
                    economicLimits.applyLTDHurdleRate
                      ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          economicLimits.ltdHurdleRate
                        )}%`
                      : '--'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <h3>Operational Assumptions</h3>
          <TableContainer>
            <Table
              aria-label={`cashflow-operational`}
              size="sm"
              className={style.operationalAssumptionsTable}
            >
              <TableHead>
                <TableRow>
                  <TableHeader scope="col">Type</TableHeader>
                  <TableHeader scope="col">Oil</TableHeader>
                  <TableHeader scope="col">Gas</TableHeader>
                  <TableHeader scope="col">NGL</TableHeader>
                  <TableHeader scope="col">Ad Valorem</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Differential</TableCell>
                  <TableCell>${differential.oilPrice}/BBL</TableCell>
                  <TableCell>${differential.gasPrice}/MMBTU</TableCell>
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                      differential.nglPercentage
                    )}
                    %
                  </TableCell>
                  <TableCell>--</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tax Rate</TableCell>
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(taxPercentage.oil)}%
                  </TableCell>
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(taxPercentage.gas)}%
                  </TableCell>
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(taxPercentage.ngl)}%
                  </TableCell>
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                      taxPercentage.adValorem
                    )}
                    %
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Price</TableCell>
                  {hasPrice ? (
                    <>
                      <TableCell>${price.oilFlatPrice}</TableCell>
                      <TableCell>${price.gasFlatPrice}</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>--</TableCell>
                      <TableCell>--</TableCell>
                    </>
                  )}
                  <TableCell>--</TableCell>
                  <TableCell>--</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table
              aria-label={`cashflow-reservoir`}
              size="sm"
              className={style.modifiedTable}
            >
              <TableHead>
                <TableRow>
                  <TableHeader scope="col">
                    Modified Strip Cutoff Oil
                  </TableHeader>
                  <TableHeader scope="col">
                    Modified Strip Cutoff Gas
                  </TableHeader>
                  <TableHeader scope="col">Modified Oil Scaling</TableHeader>
                  <TableHeader scope="col">Modified Gas Scaling</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {modifiedPricing
                      ? `${price.oilModifiedStripCutoffMonths} Months`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {modifiedPricing
                      ? `${price.gasModifiedStripCutoffMonths} Months`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {price.modifiedStripOilScalingPercentage
                      ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          price.modifiedStripOilScalingPercentage
                        )}%`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {price.modifiedStripGasScalingPercentage
                      ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          price.modifiedStripGasScalingPercentage
                        )}%`
                      : '--'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table
              aria-label={`cashflow-reservoir`}
              size="sm"
              className={style.modifiedTable}
            >
              <TableHead>
                <TableRow>
                  <TableHeader scope="col">Effective Date</TableHeader>
                  <TableHeader scope="col">Discount To Date</TableHeader>
                  <TableHeader scope="col">Royalty Rate</TableHeader>
                  <TableHeader scope="col">Strip Date</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Date date={assumptions.effectiveDate} />
                  </TableCell>
                  <TableCell>
                    {assumptions.discountToDate ? (
                      <Date date={assumptions.discountToDate} />
                    ) : (
                      '--'
                    )}
                  </TableCell>
                  <TableCell>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                      assumptions.royaltyPercentage
                    )}
                    %
                  </TableCell>
                  <TableCell>
                    {pricingKind.includes('most_recent') ? (
                      <>
                        Most Recent (
                        <Date
                          date={
                            price.stripPriceDate ||
                            assumptions.mostRecentStripPriceDate
                          }
                        />
                        )
                      </>
                    ) : pricingKind.includes('as_of') ? (
                      `As of ${price.stripPriceDate}`
                    ) : (
                      '--'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <h3>Variable Costs</h3>
          <TableContainer>
            <Table
              aria-label={`variable-costs`}
              size="sm"
              className={style.variableTable}
            >
              <TableHead>
                <TableRow>
                  <TableHeader scope="col">Gas OpEx</TableHeader>
                  <TableHeader scope="col">Oil OpEx</TableHeader>
                  <TableHeader scope="col">Water OpEx</TableHeader>
                  <TableHeader scope="col">Water Forecast Source</TableHeader>
                  {waterForecastSource === 'oil' ? (
                    <TableHeader scope="col">Water Cut</TableHeader>
                  ) : (
                    <TableHeader scope="col">Water Gas Ratio</TableHeader>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    ${opexCost.gas}
                    /MCF
                  </TableCell>
                  <TableCell>
                    ${opexCost.oil}
                    /Barrel of Oil
                  </TableCell>
                  <TableCell>
                    ${opexCost.water}
                    /Barrel of Water
                  </TableCell>
                  <TableCell>{waterForecastSource}</TableCell>
                  {waterForecastSource === 'oil' ? (
                    <TableCell>
                      {assumptions.leasehold.waterCutPercentage} Barrel of
                      Water/Barrel of Liquid
                    </TableCell>
                  ) : (
                    <TableCell>
                      {assumptions.leasehold.waterGasRatioInBblPerMmcf} BBL /
                      MCF
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <h3>Fixed Costs</h3>
          <TableContainer>
            <Table
              aria-label={`fixed-costs`}
              size="sm"
              className={style.fixedTable}
            >
              <TableHead>
                <TableRow>
                  <TableHeader scope="col">
                    Drilling & Completion Cost
                  </TableHeader>
                  <TableHeader scope="col">
                    Cost First Production Date
                  </TableHeader>
                  <TableHeader scope="col">Cost Spud Date</TableHeader>
                  <TableHeader className={style.tooltip} scope="col">
                    <TooltipDefinition tooltipText="Lease Operating Expense">
                      LOE
                    </TooltipDefinition>
                    /Well Year 1
                  </TableHeader>
                  <TableHeader className={style.tooltip} scope="col">
                    {' '}
                    <TooltipDefinition tooltipText="Lease Operating Expense">
                      LOE
                    </TooltipDefinition>
                    /Well Year 2
                  </TableHeader>
                  <TableHeader className={style.tooltip} scope="col">
                    {' '}
                    <TooltipDefinition tooltipText="Lease Operating Expense">
                      LOE
                    </TooltipDefinition>
                    /Well Year 3
                  </TableHeader>
                  <TableHeader className={style.tooltip} scope="col">
                    {' '}
                    <TooltipDefinition tooltipText="Lease Operating Expense">
                      LOE
                    </TooltipDefinition>
                    /Well Year 4
                  </TableHeader>
                  <TableHeader className={style.tooltip} scope="col">
                    <TooltipDefinition tooltipText="Lease Operating Expense">
                      LOE
                    </TooltipDefinition>
                    /Well Year 5 and Up
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {assumptions.leasehold.dcCostPerWell
                      ? `$${assumptions.leasehold.dcCostPerWell.toLocaleString()}/Well`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {assumptions.leasehold.percentageCostFirstProductionDate
                      ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          assumptions.leasehold
                            .percentageCostFirstProductionDate
                        )}%`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {assumptions.leasehold.percentageCostSpudDate
                      ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          assumptions.leasehold.percentageCostSpudDate
                        )}%`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {loePerWellCost.year1
                      ? `$${loePerWellCost.year1.toLocaleString()}/Month`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {loePerWellCost.year2
                      ? `$${loePerWellCost.year2.toLocaleString()}/Month`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {loePerWellCost.year3
                      ? `$${loePerWellCost.year3.toLocaleString()}/Month`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {loePerWellCost.year4
                      ? `$${loePerWellCost.year4.toLocaleString()}/Month`
                      : '--'}
                  </TableCell>
                  <TableCell>
                    {loePerWellCost.year5AndUp
                      ? `$${loePerWellCost.year5AndUp.toLocaleString()}/Month`
                      : '--'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {commissionAdjustments && (
            <>
              <h3>Commission Adjustment (%)</h3>
              <TableContainer>
                <Table aria-label={`commission-adjustment`} size="sm">
                  <TableHead>
                    <TableRow>
                      <TableHeader scope="col">Target</TableHeader>
                      <TableHeader scope="col">Max</TableHeader>
                      <TableHeader scope="col">MTM</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          commissionAdjustments.targetPercentage
                        )}
                        %
                      </TableCell>
                      <TableCell>
                        {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          commissionAdjustments.maxPercentage
                        )}
                        %
                      </TableCell>
                      <TableCell>
                        {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                          commissionAdjustments.markToMarketPercentage
                        )}
                        %
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Column>
      </Row>
    </Grid>
  );
};

const MOICPricesTable = ({
  collection,
}: {
  collection: MOICPrice[] | undefined;
}) => {
  const labelMap = {
    Max: 'Max (Acquisition)',
    Target: 'Target (Acquisition)',
  };

  if (!collection) return null;
  return (
    <TableContainer
      title="MOIC Limited Prices"
      className={style.moicPricesTable}
    >
      <Table aria-label="MOIC Limited Prices" size="sm">
        <TableHead>
          <TableRow>
            <TableHeader scope="col">Type</TableHeader>
            <TableHeader scope="col">Multiple</TableHeader>
            <TableHeader scope="col">Cashflow Years</TableHeader>
            <TableHeader scope="col">Price $/NRA</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection.map((cashflow) => (
            <TableRow key={cashflow.identifier}>
              <TableCell>
                {labelMap[cashflow.identifier as keyof typeof labelMap]}
              </TableCell>
              <TableCell>{cashflow.multiple}x</TableCell>
              <TableCell>{cashflow.cashflowYears || '--'}</TableCell>
              <TableCell>{FORMATTER.format(cashflow.pricePerAcre)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const IRRLimitedPricesTable = ({
  collection,
  output,
}: {
  collection: IRRPrice[] | undefined;
  output: string;
}) => {
  const labelMap = {
    max_pricing: 'Max (Acquisition)',
    target_pricing: 'Target (Acquisition)',
  };

  if (!collection) return null;
  return (
    <TableContainer
      title="IRR Limited Prices"
      className={style.irrLimitedPricesTable}
    >
      <Table aria-label="IRR Limited Prices" size="sm">
        <TableHead>
          <TableRow>
            <TableHeader scope="col">Type</TableHeader>
            <TableHeader scope="col">IRR</TableHeader>
            <TableHeader scope="col">
              Price ({output === 'royalty' ? '$/NRA' : '$/NMA'})
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection.map((cashflow) => (
            <TableRow key={cashflow.identifier}>
              <TableCell>
                {labelMap[cashflow.identifier as keyof typeof labelMap]}
              </TableCell>
              <TableCell>
                {DECIMAL_FORMATTER_TWO_FRACTIONS.format(cashflow.blendedIrr)}%
              </TableCell>
              <TableCell>{FORMATTER.format(cashflow.pricePerAcre)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Evaluation = ({ href }: { href: string }) => {
  const evaluationAtom = useEvaluationAtom(href);
  const evaluation = useAtomValue(evaluationAtom);

  if (evaluation.loading) {
    return (
      <>
        <SkeletonText heading width="180px" />
        <SummarySkeleton />
        <TabsSkeleton />
        <DataTableSkeleton
          showHeader={false}
          showToolbar={false}
          columnCount={6}
        />
      </>
    );
  }

  if (evaluation.data && 'error' in evaluation.data)
    return (
      <InlineNotification
        kind="error"
        title={evaluation.data.error}
        lowContrast
      />
    );

  return evaluation.data ? (
    <EvaluationContent key={evaluation.data.id} evaluation={evaluation.data} />
  ) : null;
};

const EvaluationContent = ({
  evaluation,
  hideInputs = false,
}: {
  evaluation: DevelopmentAreaEvaluationResponse | undefined;
  hideInputs?: boolean;
}) => {
  const [output, setOutput] = useState<'royalty' | 'working_interest'>(
    'royalty'
  );

  if (!evaluation) return null;

  const summary = evaluation.response.summary;

  const DownloadEvaluation = ({
    downloadHref,
    buttonText,
  }: {
    downloadHref: string;
    buttonText: string;
  }) => {
    const downloadOwnership = useDownloadResource(downloadHref);
    return (
      <DownloadButton
        loading={downloadOwnership.generatingDownload}
        loadingText="Building export"
        kind="primary"
        onClick={() => downloadOwnership.triggerDownload()}
      >
        {buttonText}
      </DownloadButton>
    );
  };

  return (
    <>
      <div className={style.resultTabs}>
        <h2 className={style.tabsTitle}>
          Evaluation Results ({evaluation.id})
        </h2>
        <div className={style.downloadButtons}>
          <DownloadEvaluation
            downloadHref={evaluation.resources.evaluationDetailDownload.href}
            buttonText="Evaluation Details"
          />
          <DownloadEvaluation
            downloadHref={evaluation.resources.cashflowsBySlotDownload.href}
            buttonText="Cashflows By Slot"
          />
        </div>
      </div>
      <Summary data={summary} />
      <CashflowOutputSwitch output={output} setOutput={setOutput} />
      <EvaluationTabs
        output={output}
        evaluation={evaluation}
        hideInputs={hideInputs}
      />
    </>
  );
};

function EvaluationTabs({
  output,
  evaluation,
  hideInputs,
}: {
  output: 'royalty' | 'working_interest';
  evaluation: DevelopmentAreaEvaluationResponse;
  hideInputs: boolean;
}) {
  const cashflows = evaluation.response.cashflows;
  const pricingCases = evaluation.response.pricingCases;
  const [eventsCount, setEventsCount] = useState(evaluation.eventsCount || 0);
  return (
    <Tabs id="evaluation-slots" className={style.tabsContained}>
      <Tab label="Pricing" className={style.tabOutput}>
        <PricingSummary
          commissionAdjustment={
            evaluation.cashflowAssumption.commissionAdjustment
          }
          moicLimitedPrices={
            output !== 'royalty'
              ? undefined
              : cashflows.royalty.moicLimitedPrices
          }
          irrLimitedPrices={
            output === 'royalty'
              ? cashflows.royalty.irrLimitedPrices
              : cashflows.leasehold.irrLimitedPrices
          }
          output={output}
          pricingCases={
            output === 'royalty'
              ? pricingCases.royaltyPricingCases
              : pricingCases.leaseholdPricingCases
          }
        />
      </Tab>
      <Tab label="Sensitivities" className={style.tabOutput}>
        <Sensitivities
          cashflows={
            output === 'royalty' ? cashflows.royalty : cashflows.leasehold
          }
        />
      </Tab>
      <Tab label="Volumes" className={style.tabOutput}>
        <DrillingAssumptions
          assumptions={evaluation.drillingAssumption}
          volumes={evaluation.volumesByMonth}
          hideInputs={true}
        />
      </Tab>
      <Tab className={style.tabOutput} label="Slots">
        <Slots
          excludedSlots={evaluation.drillingAssumption.wellsToExclude}
          slotList={evaluation.response.slotSchedule}
          evalSlots={evaluation.slots}
        />
      </Tab>
      <Tab label={`Comments (${eventsCount})`}>
        {evaluation && (
          <Comments
            eventsHref={evaluation.resources.events.href}
            commentsHref={evaluation.resources.comments.href}
            onComment={() => setEventsCount(eventsCount + 1)}
          />
        )}
      </Tab>
      {!hideInputs && (
        <Tab
          id="assumptions"
          className={style.tabInput}
          label="Drilling Assumptions"
        >
          <DrillingAssumptions
            assumptions={evaluation.drillingAssumption}
            volumes={evaluation.volumesByMonth}
            hideInputs={false}
          />
        </Tab>
      )}
      {!hideInputs && (
        <Tab
          id="evaluation-slots"
          className={style.tabInput}
          label="Cashflow Assumptions"
        >
          <CashflowAssumptions assumptions={evaluation.cashflowAssumption} />
        </Tab>
      )}
    </Tabs>
  );
}

const Evaluations = ({
  hasFormations,
  evaluations,
  evaluationHref,
  defaultAssumptionsUrl,
  evaluationsHref,
  getEvaluations,
  slots,
}: {
  evaluationsHref: string;
  getEvaluations: () => Promise<void>;
  hasFormations: boolean;
  evaluations?: DevelopmentAreaEvaluationsResponse[];
  evaluationHref: string;
  defaultAssumptionsUrl: string;
  slots: EvalSlotResponse[];
}) => {
  const evaluationConfig = useAtomValue(saveEvaluationConfigAtom);
  const [showDraft, setShowDraft] = useAtom(showDraftAtom);
  const [defaultAssumptions, setDefaultAssumptions] = useAtom(
    defaultAssumptionsAtom
  );

  const fetchDefaultAssumptions = useCallback(() => {
    setDefaultAssumptions(defaultAssumptionsUrl);
  }, [defaultAssumptionsUrl, setDefaultAssumptions]);

  useEffect(() => {
    setDefaultAssumptions(defaultAssumptionsUrl);
  }, [defaultAssumptionsUrl, setDefaultAssumptions]);

  const location = useLocation();
  const { path } = useRouteMatch();

  const [isEvaluationListVisible, setEvaluationListVisible] = useState(false);
  const handleClick = () => {
    setEvaluationListVisible((current) => !current);
  };

  return (
    <Grid className="bx--no-gutter" condensed fullWidth>
      <Row>
        <Column
          md={isEvaluationListVisible ? 0 : !isEvaluationListVisible ? 13 : 13}
          lg={isEvaluationListVisible ? 0 : !isEvaluationListVisible ? 3 : 3}
          className={style.scrollRelativeColumn}
        >
          {evaluations && <EvaluationCount count={evaluations?.length} />}
          <EvaluationList
            evaluations={evaluations}
            showDraft={showDraft}
            onDraftClick={() => {
              setShowDraft(true);
            }}
            onEvaluationClick={() => setShowDraft(false)}
          />
        </Column>
        <Column
          md={isEvaluationListVisible ? 16 : 12}
          lg={isEvaluationListVisible ? 16 : 13}
          className={style.contentEvaluation}
        >
          {evaluations && evaluations.length ? (
            <div className={style.closeAreabtn}>
              <Button
                renderIcon={
                  isEvaluationListVisible ? ChevronRight16 : ChevronLeft16
                }
                kind="ghost"
                hasIconOnly
                iconDescription={
                  isEvaluationListVisible
                    ? `Show Evaluation List`
                    : `Hide Evaluation List`
                }
                tooltipAlignment="start"
                tooltipPosition="top"
                size="sm"
                className={style.closePanelBtn}
                onClick={handleClick}
              />
            </div>
          ) : null}
          <Tile className={showDraft ? style.mainTileDraft : style.mainTile}>
            <Switch>
              <Route exact path={`${path}/evaluations`}>
                {evaluations && evaluations.length ? (
                  <Redirect to={evaluations[0].href} />
                ) : (
                  <InlineLoading />
                )}
              </Route>
              <Route exact path={`${path}/`}>
                {evaluations ? (
                  evaluations.length ? (
                    <Redirect to={evaluations[0].href} />
                  ) : (
                    <>
                      {hasFormations ? (
                        <EvaluationForm
                          evaluationsHref={evaluationsHref}
                          getEvaluations={getEvaluations}
                          evaluationUrl={evaluationHref}
                          defaultAssumptions={defaultAssumptions}
                          fetchDefaultAssumptions={fetchDefaultAssumptions}
                          slots={slots}
                        />
                      ) : (
                        <TileContent
                          showDraft={showDraft}
                          evaluationConfig={evaluationConfig}
                          renderWhenNoDraft={
                            <EmptyState
                              IconComponent={Default}
                              headerText="No Evaluations"
                              helperText="Evaluations would normally display here, but none have been added yet."
                            />
                          }
                        />
                      )}
                    </>
                  )
                ) : (
                  <InlineLoading />
                )}
              </Route>

              <Route exact path={`${path}/evaluations/:evalId`}>
                {showDraft && hasFormations ? (
                  <EvaluationForm
                    evaluationsHref={evaluationsHref}
                    getEvaluations={getEvaluations}
                    evaluationUrl={evaluationHref}
                    defaultAssumptions={defaultAssumptions}
                    fetchDefaultAssumptions={fetchDefaultAssumptions}
                    slots={slots}
                  />
                ) : (
                  <TileContent
                    showDraft={showDraft}
                    evaluationConfig={evaluationConfig}
                    renderWhenNoDraft={<Evaluation href={location.pathname} />}
                  />
                )}
              </Route>
            </Switch>
          </Tile>
        </Column>
      </Row>
    </Grid>
  );
};

const TileContent = ({
  evaluationConfig,
  showDraft,
  renderWhenNoDraft,
}: {
  evaluationConfig: EvaluationConfigAtomValue;
  showDraft: boolean;
  renderWhenNoDraft: JSX.Element;
}) => {
  return evaluationConfig.loading && showDraft ? (
    <InlineLoading description="Loading evaluation draft..." />
  ) : !evaluationConfig.loading && showDraft ? (
    <EvaluationContent evaluation={evaluationConfig.evaluation} hideInputs />
  ) : (
    renderWhenNoDraft
  );
};

const PricingSummaryRow = ({
  summary,
  identifier,
}: {
  summary: EvalCommonPriceCases['pricingSummary'];
  identifier: PricingIdentifiers;
}) => {
  const data = summary.find((el) => el.identifier === identifier);
  if (!data) return null;
  return (
    <TableRow key={`summary-${identifier}`}>
      <TableCell>{pricingIdentifiers[identifier]}</TableCell>
      <TableCell>{FORMATTER.format(data.total)}</TableCell>
      <TableCell>{(data.source || 'pv').toUpperCase()}</TableCell>
      <TableCell>
        {data.blendedIrr
          ? `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(data.blendedIrr)}%`
          : '--'}
      </TableCell>
    </TableRow>
  );
};

const PricingCommissionAdjustmentRow = ({
  percentage,
  cases,
  identifier,
  identifierLabel,
}: {
  percentage: number;
  cases: EvalCommonPriceCases;
  identifier: keyof Omit<EvalCommonPriceCases, 'pricingSummary'>;
  identifierLabel: string;
}) => {
  let unadjusted = 0;
  let adjusted = 0;
  cases[identifier]
    .filter((el) => el.isBaseCase)
    .forEach((el) => {
      unadjusted += el.unadjustedNpv || 0;
      adjusted += el.npv || 0;
    });
  const adjustment = unadjusted - adjusted;

  return (
    <TableRow key={`pricing-commission-row-${identifier}`}>
      <TableCell>{identifierLabel}</TableCell>
      <TableCell>{FORMATTER.format(unadjusted)}</TableCell>
      <TableCell>
        {DECIMAL_FORMATTER_TWO_FRACTIONS.format(percentage)}%
      </TableCell>
      <TableCell>{FORMATTER.format(adjustment)}</TableCell>
      <TableCell>{FORMATTER.format(adjusted)}</TableCell>
    </TableRow>
  );
};

const LegacyResCatRow = ({ row }: { row: EvalCommonPriceCase }) => {
  return (
    <TableRow>
      <TableCell>
        {row.rescat === 'permit' ? 'Permit' : row.rescat.toUpperCase()}
      </TableCell>
      <TableCell>
        {row.discountRatePercentage !== 0 && !row.discountRatePercentage
          ? '--'
          : `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
              row.discountRatePercentage
            )}%`}
      </TableCell>
      <TableCell>
        {row.npv !== 0 && !row.npv ? '--' : FORMATTER.format(row.npv)}
      </TableCell>
    </TableRow>
  );
};

const LegacyPricingCaseRows = ({
  pricingCase,
  output,
}: {
  pricingCase: EvalCommonPriceCase[];
  output: string;
}) => {
  const total = pricingCase.reduce((acc, el) => {
    if (el.npv) {
      acc = acc + el.npv;
      return acc;
    }
    return acc;
  }, 0);

  const pdp = pricingCase.find((el) => el.rescat === 'pdp');
  const ntd = pricingCase.find((el) => el.rescat === 'ntd');
  const ltd = pricingCase.find((el) => el.rescat === 'ltd');
  const duc = pricingCase.find((el) => el.rescat === 'duc');
  const permit = pricingCase.find((el) => el.rescat === 'permit');

  return (
    <>
      {pdp && <LegacyResCatRow row={pdp} />}
      {ntd && <LegacyResCatRow row={ntd} />}
      {ltd && <LegacyResCatRow row={ltd} />}
      {duc && <LegacyResCatRow row={duc} />}
      {permit && <LegacyResCatRow row={permit} />}
      <TableRow className={style.boldedRow}>
        <TableCell colSpan={2}>
          <b>Total ({output === 'royalty' ? '$/NRA' : '$/NMA'})</b>
        </TableCell>
        <TableCell colSpan={2}>{FORMATTER.format(total)}</TableCell>
      </TableRow>
    </>
  );
};

const LegacyPricingCaseTable = ({
  title,
  className,
  ariaLabel,
  pricingCase,
  output,
}: {
  title: string;
  className?: string;
  ariaLabel: string;
  pricingCase: EvalCommonPriceCase[];
  output: string;
}) => {
  return (
    <TableContainer
      title={title}
      className={className ? className : style.discountRateTable}
    >
      <Table aria-label={ariaLabel} size="sm">
        <TableHead>
          <TableRow>
            <TableHeader scope="col">ResCat</TableHeader>
            <TableHeader scope="col">Discount Rate</TableHeader>
            <TableHeader scope="col">
              {output === 'royalty' ? '$/NRA' : '$/NMA'}
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <LegacyPricingCaseRows pricingCase={pricingCase} output={output} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ResCatRow = ({
  prices,
  uniqueDates,
  baseCase,
  rescat,
  discountRatePercentage,
}: {
  prices: EvalCommonPriceCase[];
  uniqueDates: string[];
  baseCase: EvalCommonPriceCase | undefined;
  rescat: string;
  discountRatePercentage: number;
}) => {
  const dateToNpvMap = prices.reduce(
    (acc: { [date: string]: number }, item) => {
      if (item.effectiveDate && item.npv) {
        acc[item.effectiveDate] = item.npv;
      }
      return acc;
    },
    {}
  );

  return (
    <TableRow>
      <TableCell>
        {rescat === 'permit' ? 'Permit' : rescat.toUpperCase()}
      </TableCell>
      <TableCell>
        {discountRatePercentage !== 0 && !discountRatePercentage
          ? '--'
          : `${DECIMAL_FORMATTER_TWO_FRACTIONS.format(
              discountRatePercentage
            )}%`}
      </TableCell>
      {uniqueDates.map((date, index) => (
        <TableCell
          key={index}
          className={
            baseCase?.effectiveDate === date ? style.boldedRow : undefined
          }
        >
          {dateToNpvMap[date] !== 0 && !dateToNpvMap[date]
            ? '--'
            : FORMATTER.format(dateToNpvMap[date])}
        </TableCell>
      ))}
    </TableRow>
  );
};

const PricingCaseRows = ({
  pricingCase,
  output,
  uniqueDates,
  baseCase,
}: {
  pricingCase: EvalCommonPriceCase[];
  output: string;
  uniqueDates: string[];
  baseCase: EvalCommonPriceCase | undefined;
}) => {
  const totals = pricingCase.reduce((acc: { [date: string]: number }, el) => {
    if (el.npv && el.effectiveDate && acc[el.effectiveDate]) {
      acc[el.effectiveDate] = acc[el.effectiveDate] + el.npv;
    } else if (el.npv && el.effectiveDate) {
      acc[el.effectiveDate] = el.npv;
    }
    return acc;
  }, {});

  const pdp = pricingCase.filter((el) => el.rescat === 'pdp');
  const pdpRow = pdp.find((el) => !!el.discountRatePercentage);
  const ntd = pricingCase.filter((el) => el.rescat === 'ntd');
  const ntdRow = ntd.find((el) => !!el.discountRatePercentage);
  const ltd = pricingCase.filter((el) => el.rescat === 'ltd');
  const ltdRow = ltd.find((el) => !!el.discountRatePercentage);
  const duc = pricingCase.filter((el) => el.rescat === 'duc');
  const ducRow = duc.find((el) => !!el.discountRatePercentage);
  const permit = pricingCase.filter((el) => el.rescat === 'permit');
  const permitRow = permit.find((el) => !!el.discountRatePercentage);

  return (
    <>
      {pdp && (
        <ResCatRow
          prices={pdp}
          uniqueDates={uniqueDates}
          baseCase={baseCase}
          rescat="pdp"
          discountRatePercentage={pdpRow ? pdpRow.discountRatePercentage : 0}
        />
      )}
      {ntd.length > 0 && (
        <ResCatRow
          prices={ntd}
          uniqueDates={uniqueDates}
          baseCase={baseCase}
          rescat="ntd"
          discountRatePercentage={ntdRow ? ntdRow.discountRatePercentage : 0}
        />
      )}
      {duc.length > 0 && (
        <ResCatRow
          prices={duc}
          uniqueDates={uniqueDates}
          baseCase={baseCase}
          rescat="duc"
          discountRatePercentage={ducRow ? ducRow.discountRatePercentage : 0}
        />
      )}
      {permit.length > 0 && (
        <ResCatRow
          prices={permit}
          uniqueDates={uniqueDates}
          baseCase={baseCase}
          rescat="permit"
          discountRatePercentage={
            permitRow ? permitRow.discountRatePercentage : 0
          }
        />
      )}
      {ltd && (
        <ResCatRow
          prices={ltd}
          uniqueDates={uniqueDates}
          baseCase={baseCase}
          rescat="ltd"
          discountRatePercentage={ltdRow ? ltdRow.discountRatePercentage : 0}
        />
      )}
      <TableRow className={style.boldedRow}>
        <TableCell colSpan={2}>
          <b>Total ({output === 'royalty' ? '$/NRA' : '$/NMA'})</b>
        </TableCell>
        {uniqueDates.map((date, index) => (
          <TableCell key={index}>
            {totals[date] !== 0 && !totals[date]
              ? '--'
              : FORMATTER.format(totals[date])}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

const PricingCaseTable = ({
  title,
  ariaLabel,
  pricingCase,
  output,
}: {
  title: string;
  ariaLabel: string;
  pricingCase: EvalCommonPriceCase[];
  output: string;
}) => {
  const uniqueDates = Array.from(
    new Set(
      pricingCase
        .map((item) => item.effectiveDate)
        .filter((date): date is string => date !== null)
    )
  ).sort();

  const baseCase = pricingCase.find((item) => item.isBaseCase === true);

  return (
    <TableContainer title={title} className={style.pricingCasesTable}>
      <Table aria-label={ariaLabel} size="sm">
        <TableHead>
          <TableRow>
            <TableHeader scope="col">ResCat</TableHeader>
            <TableHeader scope="col">Discount Rate</TableHeader>
            {uniqueDates.map((date, index) => (
              <TableHeader key={index} scope="col">
                {date === baseCase?.effectiveDate
                  ? date + ' (Base Case)'
                  : date}
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <PricingCaseRows
            pricingCase={pricingCase}
            output={output}
            uniqueDates={uniqueDates}
            baseCase={baseCase}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PricingCaseTableComponent = (props: {
  title: string;
  ariaLabel: string;
  pricingCase: EvalCommonPriceCase[];
  output: string;
}) => {
  const hasEffectiveDate = (pricingCases: EvalCommonPriceCase[]) => {
    return pricingCases.some((pricingCase) => !!pricingCase.effectiveDate);
  };

  return hasEffectiveDate(props.pricingCase) ? (
    <PricingCaseTable {...props} />
  ) : (
    <LegacyPricingCaseTable {...props} />
  );
};

const PricingSummary = ({
  commissionAdjustment,
  pricingCases,
  moicLimitedPrices,
  irrLimitedPrices,
  output,
}: {
  commissionAdjustment: DevelopmentAreaEvaluationResponse['cashflowAssumption']['commissionAdjustment'];
  pricingCases: EvalCommonPriceCases | undefined;
  output: string;
  moicLimitedPrices: EvalCashflows['moicLimitedPrices'];
  irrLimitedPrices: EvalCashflows['irrLimitedPrices'];
}) => {
  if (!pricingCases)
    return (
      <DataTableSkeleton
        showHeader={false}
        showToolbar={false}
        columnCount={10}
      />
    );

  return (
    <>
      <TableContainer
        title={'Pricing Summary'}
        className={style.discountRateTable}
      >
        <Table
          aria-label={'pricing_summary'}
          size="sm"
          className={style.pricingSummaryTable}
        >
          <TableHead>
            <TableRow>
              <TableHeader scope="col">Identifier</TableHeader>
              <TableHeader scope="col">
                Price ({output === 'royalty' ? '$/NRA' : '$/NMA'})
              </TableHeader>
              <TableHeader scope="col">Source</TableHeader>
              <TableHeader scope="col">IRR</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <PricingSummaryRow
              summary={pricingCases.pricingSummary}
              identifier={'target_pricing'}
            />
            <PricingSummaryRow
              summary={pricingCases.pricingSummary}
              identifier={'max_pricing'}
            />
            <PricingSummaryRow
              summary={pricingCases.pricingSummary}
              identifier={'mark_to_market_pricing'}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <PricingCaseTableComponent
        title={'Target (Acquisition)'}
        ariaLabel={'target_pricing'}
        pricingCase={pricingCases.targetPricing}
        output={output}
      />
      <PricingCaseTableComponent
        title={'Max (Acquisition)'}
        ariaLabel={'max_pricing'}
        pricingCase={pricingCases.maxPricing}
        output={output}
      />
      <PricingCaseTableComponent
        title={'Mark to Market (MTM)'}
        ariaLabel={'mark_to_market_pricing'}
        pricingCase={pricingCases.markToMarketPricing}
        output={output}
      />
      {commissionAdjustment && (
        <TableContainer title={'Commission Adjustment'}>
          <Table
            aria-label={'commission_adjustment'}
            size="sm"
            className={style.commissionPricesTable}
          >
            <TableHead>
              <TableRow>
                <TableHeader scope="col">Identifier</TableHeader>
                <TableHeader scope="col">Unadjusted ($)</TableHeader>
                <TableHeader scope="col">Percentage (%)</TableHeader>
                <TableHeader scope="col">Adjustment ($)</TableHeader>
                <TableHeader scope="col">Adjusted Price ($)</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <PricingCommissionAdjustmentRow
                cases={pricingCases}
                identifier={'targetPricing'}
                identifierLabel="Target (Acquisition)"
                percentage={commissionAdjustment.targetPercentage}
              />
              <PricingCommissionAdjustmentRow
                cases={pricingCases}
                identifier={'maxPricing'}
                identifierLabel="Max (Acquisition)"
                percentage={commissionAdjustment.maxPercentage}
              />
              <PricingCommissionAdjustmentRow
                cases={pricingCases}
                identifier={'markToMarketPricing'}
                identifierLabel="Mark to Market (MTM)"
                percentage={commissionAdjustment.markToMarketPercentage}
              />
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <MOICPricesTable collection={moicLimitedPrices} />
      <IRRLimitedPricesTable collection={irrLimitedPrices} output={output} />
    </>
  );
};

export { Evaluations, EvaluationContent, Summary, PricingSummary };
