import { WorkspaceImport32 } from '@carbon/icons-react';
import classNames from 'classnames';
import { VFC } from 'react';

import type { IconProps } from './types';
import style from './icons.module.scss';

const Workspace: VFC<IconProps> = ({ className }) => {
  return (
    <div className={classNames(style.background, style.link, className)}>
      <WorkspaceImport32 className={classNames(style.icon, style.link)} />
    </div>
  );
};

export default Workspace;
