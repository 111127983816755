import { Button } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import { PageHeader } from 'components/page-header';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import { EntitySearch } from 'components/entity-search';

const EntityListPage = () => {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>{pageTitle('Global Entities Index')}</title>
      </Helmet>
      <PageHeader
        breadcrumbs={[['/entities', 'Entities']]}
        title="Entities"
        actions={
          <Button
            size="sm"
            renderIcon={Add16}
            onClick={() => history.push('/entities/new')}
          >
            <span>Add New Entity</span>
          </Button>
        }
      />
      <EntitySearch />
    </>
  );
};

export { EntityListPage };
