import { Add16 } from '@carbon/icons-react';
import { AtomField, AtomizedRowForm } from 'atoms/form-atoms';
import { FormRow, FormSection } from 'components/forms';
import { AddressAttributes, EmailAttributes, PhoneAttributes } from './types';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { Button, Checkbox, Column, Row } from 'carbon-components-react';
import { addressRow, emailRow, phoneNumberRow } from './utils';
import { TextField } from './text-field';
import { AddressAutofill } from '@mapbox/search-js-react';
import type { TitleWindow } from 'types/window';
import { TrashRowButton } from 'components/trash-row-button';

declare let window: TitleWindow;

import style from './form.module.scss';

type ContactInfoRow = {
  index: number;
  onDelete: (index: number) => void;
  onPrimaryUpdate: (index: number) => void;
};

type PhoneRow = ContactInfoRow & {
  entry: AtomizedRowForm<PhoneAttributes>;
};

type EmailRow = ContactInfoRow & {
  entry: AtomizedRowForm<EmailAttributes>;
};

type AddressRow = ContactInfoRow & {
  entry: AtomizedRowForm<AddressAttributes>;
};

export type ContactInfoRowsAtom = AtomField<
  AtomizedRowForm<PhoneAttributes | EmailAttributes | AddressAttributes>[]
>;

const PhoneRow = ({ entry, index, onDelete, onPrimaryUpdate }: PhoneRow) => {
  const rowStatus = useAtomValue(entry._rowStatus);
  const [primary, setPrimary] = useAtom(entry.primary);
  const status = rowStatus.value;
  return (
    <>
      <FormRow toTrash={status === 'trash'}>
        <Row>
          <Column md={5} lg={10}>
            <TextField
              id={`phone-number-${index}`}
              labelText="Phone Number (Required)"
              fieldAtom={entry.number}
            />
          </Column>
          <Column md={2} lg={4}>
            <Checkbox
              id={`phone-primary-${index}`}
              labelText={'Primary'}
              className={style.adjustForLabel}
              checked={primary.value}
              onChange={(checked: boolean) => {
                setPrimary(checked);
                onPrimaryUpdate(index);
              }}
            />
          </Column>
          <Column md={1} lg={2}>
            <TrashRowButton
              adjustForLabel={true}
              atom={entry._rowStatus}
              index={index}
              onDelete={onDelete}
            />
          </Column>
        </Row>
      </FormRow>
    </>
  );
};

const EmailRow = ({ entry, index, onDelete, onPrimaryUpdate }: EmailRow) => {
  const rowStatus = useAtomValue(entry._rowStatus);
  const [primary, setPrimary] = useAtom(entry.primary);
  const status = rowStatus.value;
  return (
    <>
      <FormRow toTrash={status === 'trash'}>
        <Row>
          <Column md={5} lg={10}>
            <TextField
              id={`email-${index}`}
              labelText="E-mail (Required)"
              fieldAtom={entry.email}
            />
          </Column>
          <Column md={2} lg={4}>
            <Checkbox
              id={`email-primary-${index}`}
              labelText={'Primary'}
              className={style.adjustForLabel}
              checked={primary.value}
              onChange={(checked: boolean) => {
                setPrimary(checked);
                onPrimaryUpdate(index);
              }}
            />
          </Column>
          <Column md={1} lg={2}>
            <TrashRowButton
              adjustForLabel={true}
              atom={entry._rowStatus}
              index={index}
              onDelete={onDelete}
            />
          </Column>
        </Row>
      </FormRow>
    </>
  );
};

const mapboxAutocompleteOptions = {
  lenguage: 'en',
  country: 'US',
};

const AddressRow = ({
  entry,
  index,
  onDelete,
  onPrimaryUpdate,
}: AddressRow) => {
  const rowStatus = useAtomValue(entry._rowStatus);
  const [primary, setPrimary] = useAtom(entry.primary);
  const status = rowStatus.value;

  return (
    <FormRow toTrash={status === 'trash'}>
      <Row className={style.rowThirds}>
        <Column>
          <AddressAutofill
            options={mapboxAutocompleteOptions}
            accessToken={window.CONFIG?.mapbox.token || ''}
          >
            <TextField
              id={`address-street-address-${index}`}
              labelText="Street Address"
              fieldAtom={entry.streetAddress}
              placeholder="Type the address, e.g. 123 Main..."
              autoComplete="address-line1"
            />
          </AddressAutofill>
        </Column>
        <Column>
          <TextField
            id={`address-city-${index}`}
            labelText="City"
            fieldAtom={entry.city}
            placeholder="City"
            autoComplete="address-level2"
          />
        </Column>
        <Column>
          <TextField
            id={`address-state-${index}`}
            labelText="State / Region"
            fieldAtom={entry.state}
            placeholder="State / Region"
            autoComplete="address-level1"
          />
        </Column>
      </Row>
      <Row className={style.rowThirds}>
        <Column>
          <TextField
            id={`address-zip-${index}`}
            labelText="Zip Code / Postcode"
            fieldAtom={entry.zipCode}
            placeholder="ZIP / Postcode"
            autoComplete="postal-code"
          />
        </Column>
        <Column>
          <Checkbox
            id={`address-primary-${index}`}
            labelText={'Primary'}
            className={style.adjustForLabel}
            checked={primary.value}
            onChange={(checked: boolean) => {
              setPrimary(checked);
              onPrimaryUpdate(index);
            }}
          />
        </Column>
        <Column>
          <TrashRowButton
            adjustForLabel={true}
            atom={entry._rowStatus}
            index={index}
            onDelete={onDelete}
          />
        </Column>
      </Row>
    </FormRow>
  );
};

const ContactInfoRows = <T extends ContactInfoRowsAtom>({
  form,
  title,
  addLabel,
  type,
}: {
  form: T;
  title: string;
  addLabel: string;
  type: 'phone' | 'email' | 'address';
}) => {
  const [rows, setRows] = useAtom(form);

  const onDelete = useCallback(
    (index: number) =>
      setRows((arr) => [...arr.slice(0, index), ...arr.slice(index + 1)]),
    [setRows]
  );

  const updatePrimaryFieldsAtom = useMemo(() => {
    return atom<null, [number], void>(
      () => null,
      (get, set, index) => {
        const rows = get(form);
        rows.value.map((el, i) => {
          if (i !== index) {
            set(el.primary, false);
          }
        });
      }
    );
  }, [form]);

  const rowAdder =
    type === 'phone'
      ? phoneNumberRow
      : type === 'email'
      ? emailRow
      : addressRow;

  const updatePrimaryField = useSetAtom(updatePrimaryFieldsAtom);

  return (
    <FormSection title={title}>
      {rows.value.map((el, i) => {
        return (
          <FormSection title="" key={`${i}-interest-entry`}>
            {'number' in el && (
              <PhoneRow
                entry={el}
                index={i}
                onDelete={onDelete}
                onPrimaryUpdate={updatePrimaryField}
              />
            )}
            {'email' in el && (
              <EmailRow
                entry={el}
                index={i}
                onDelete={onDelete}
                onPrimaryUpdate={updatePrimaryField}
              />
            )}
            {'streetAddress' in el && (
              <AddressRow
                entry={el}
                index={i}
                onDelete={onDelete}
                onPrimaryUpdate={updatePrimaryField}
              />
            )}
          </FormSection>
        );
      })}
      <FormRow>
        <Button
          renderIcon={Add16}
          kind={'tertiary'}
          size="md"
          onClick={() => setRows((current) => [...current, rowAdder()])}
        >
          {addLabel}
        </Button>
      </FormRow>
    </FormSection>
  );
};

export { ContactInfoRows };
