import { Add16 } from '@carbon/icons-react';
import { Button, Column, Grid, Row, TextInput } from 'carbon-components-react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import {
  MineralLeaseTransactionForm,
  SurfaceLeaseTransactionForm,
  TransactionFormAtoms,
} from './types';
import { EntitySubformAtoms, initEntityEmptyForm } from './utils';

import style from './transaction.module.scss';
import { DatePicker } from 'components/date-picker';
import { formAtoms } from '../atoms';
import { EntityRow } from './entity-row';
import { getError } from 'atoms/create-resource-atom';
import { LeaseManagementSection } from './lease-management/lease-management-section';
import { getDateString } from 'utils/dates';

export type MineralLeaseAtoms = Extract<
  TransactionFormAtoms,
  { type: 'Transaction::MineralLeaseTransaction' }
>['row'];

type SurfaceLeaseAtoms = Extract<
  TransactionFormAtoms,
  { type: 'Transaction::SurfaceLeaseTransaction' }
>['row'];

interface MineralLeaseFieldsProps {
  atoms: MineralLeaseAtoms;
  idx: number;
  trxRowDisabled: boolean;
}
const MineralLeaseFields = ({
  atoms,
  idx,
  trxRowDisabled,
}: MineralLeaseFieldsProps) => {
  const [fields, setField] = useAtom(atoms.formAtom);

  return (
    <Grid fullWidth className="bx--no-gutter">
      <Row className={style.transactionRow}>
        <Column sm={2} md={2} lg={4}>
          <TextInput
            light
            id={`${idx}-term`}
            labelText="Term (months)"
            placeholder=""
            value={fields.term.value || ''}
            disabled={trxRowDisabled}
            onChange={(event) =>
              setField({ field: 'term', value: Number(event.target.value) })
            }
            invalid={!!getError<MineralLeaseTransactionForm>(fields, 'term')}
            invalidText={getError<MineralLeaseTransactionForm>(fields, 'term')}
          />
        </Column>
        <Column sm={2} md={2} lg={4}>
          <TextInput
            light
            labelText="Extension (months)"
            value={fields.extension.value || ''}
            id={`${idx}-extension`}
            onChange={(event) =>
              setField({
                field: 'extension',
                value: Number(event.target.value),
              })
            }
            disabled={trxRowDisabled}
            invalid={
              !!getError<MineralLeaseTransactionForm>(fields, 'extension')
            }
            invalidText={getError<MineralLeaseTransactionForm>(
              fields,
              'extension'
            )}
          />
        </Column>
        <Column sm={2} md={2} lg={4}>
          <TextInput
            light
            labelText="Royalty Rate"
            value={fields.royaltyRate.value || ''}
            id={`${idx}-royalty-rate`}
            onChange={(event) =>
              setField({
                field: 'royaltyRate',
                value: event.target.value,
              })
            }
            disabled={trxRowDisabled}
            invalid={
              !!getError<MineralLeaseTransactionForm>(fields, 'royaltyRate')
            }
            invalidText={getError<MineralLeaseTransactionForm>(
              fields,
              'royaltyRate'
            )}
          />
        </Column>
        <Column sm={2} md={2} lg={4}>
          <DatePicker
            size="md"
            light
            labelText="Expiration Date"
            id={`${idx}-expiration-date`}
            value={fields.expirationDate.value ?? undefined}
            onChange={(date) => {
              setField({
                field: 'expirationDate',
                value: date[0] ? getDateString(date[0]) : null,
              });
            }}
            invalid={
              !!getError<MineralLeaseTransactionForm>(fields, 'expirationDate')
            }
            invalidText={getError<MineralLeaseTransactionForm>(
              fields,
              'expirationDate'
            )}
            disabled={trxRowDisabled}
          />
        </Column>
      </Row>
      <LeaseManagementSection
        transactionAtoms={atoms}
        leaseManagementAtoms={fields.leaseManagement.value}
        trxRowDisabled={trxRowDisabled}
      />
    </Grid>
  );
};

interface SurfaceLeaseFieldsProps {
  atoms: SurfaceLeaseAtoms;
  idx: number;
  trxRowDisabled: boolean;
}
const SurfaceLeaseFields = ({
  atoms,
  idx,
  trxRowDisabled,
}: SurfaceLeaseFieldsProps) => {
  const [fields, setField] = useAtom(atoms.formAtom);

  return (
    <Grid fullWidth className="bx--no-gutter">
      <Row>
        <Column sm={2} md={2} lg={4}>
          <TextInput
            light
            id={`${idx}-term`}
            labelText="Term (months)"
            placeholder=""
            value={fields.term.value || ''}
            disabled={trxRowDisabled}
            onChange={(event) =>
              setField({
                field: 'term',
                value: Number(event.target.value),
              })
            }
            invalid={!!getError<SurfaceLeaseTransactionForm>(fields, 'term')}
            invalidText={getError<SurfaceLeaseTransactionForm>(fields, 'term')}
          />
        </Column>
        <Column sm={2} md={2} lg={4}>
          <TextInput
            light
            labelText="Extension (months)"
            value={fields.extension.value || ''}
            id={`${idx}-extension`}
            onChange={(event) =>
              setField({
                field: 'extension',
                value: Number(event.target.value),
              })
            }
            disabled={trxRowDisabled}
            invalid={
              !!getError<SurfaceLeaseTransactionForm>(fields, 'extension')
            }
            invalidText={getError<SurfaceLeaseTransactionForm>(
              fields,
              'extension'
            )}
          />
        </Column>
        <Column sm={2} md={2} lg={4}>
          <DatePicker
            size="md"
            light
            labelText="Expiration Date"
            id={`${idx}-expiration-date`}
            value={fields.expirationDate.value ?? undefined}
            onChange={(date) => {
              setField({
                field: 'expirationDate',
                value: date[0] ? getDateString(date[0]) : null,
              });
            }}
            invalid={
              !!getError<SurfaceLeaseTransactionForm>(fields, 'expirationDate')
            }
            invalidText={getError<SurfaceLeaseTransactionForm>(
              fields,
              'expirationDate'
            )}
            disabled={trxRowDisabled}
          />
        </Column>
        <hr className={style.hrSeparator} />
      </Row>
    </Grid>
  );
};

interface CommonFieldProps {
  lessors: EntitySubformAtoms[];
  lessees: EntitySubformAtoms[];
  trxRowDisabled: boolean;
  onAdd: (field: 'lessor' | 'lessee') => void;
  onDelete: (field: 'lessor' | 'lessee', idx: number) => void;
  id: string;
}

const CommonFields = ({
  lessors,
  lessees,
  trxRowDisabled,
  onAdd,
  onDelete,
  id,
}: CommonFieldProps) => {
  return (
    <>
      {lessors.map((lessor, i) => {
        return (
          <EntityRow
            id={id}
            key={i}
            atoms={lessor}
            atomIdx={i}
            trxRowDisabled={trxRowDisabled}
            field={'Lessor'}
            onDelete={(atomIdx) => onDelete('lessor', atomIdx)}
          />
        );
      })}
      <hr className={style.hrSeparator} />
      <Button
        renderIcon={Add16}
        kind={'tertiary'}
        size="md"
        onClick={() => onAdd('lessor')}
        disabled={trxRowDisabled}
      >
        Add Lessor
      </Button>
      <hr className={style.sectionSeparator} />
      {lessees.map((lessee, i) => {
        return (
          <EntityRow
            id={id}
            key={i}
            atoms={lessee}
            atomIdx={i}
            trxRowDisabled={trxRowDisabled}
            field={'Lessee'}
            onDelete={(atomIdx) => onDelete('lessee', atomIdx)}
          />
        );
      })}
      <hr className={style.hrSeparator} />
      <Button
        renderIcon={Add16}
        kind={'tertiary'}
        size="md"
        onClick={() => onAdd('lessee')}
        disabled={trxRowDisabled}
      >
        Add Lessee
      </Button>
      <hr className={style.sectionSeparator} />
    </>
  );
};

interface SurfaceLeaseTransactionProps {
  row: SurfaceLeaseAtoms;
  idx: number;
}
const SurfaceLeaseTransaction = (props: SurfaceLeaseTransactionProps) => {
  const [fields, setField] = useAtom(props.row.formAtom);

  const trxRowStatusAtom = useMemo(
    () =>
      atom((get) => {
        const formAtom = get(formAtoms).formAtom;
        const transactionStateAtom =
          get(formAtom).transactions.value[props.idx].status;
        return get(transactionStateAtom);
      }),
    [props.idx]
  );
  const trxRowStatus = useAtomValue(trxRowStatusAtom);
  const trxRowDisabled = trxRowStatus === 'trash';

  return (
    <>
      <CommonFields
        id="surface"
        lessees={fields.lessee.value}
        lessors={fields.lessor.value}
        trxRowDisabled={trxRowDisabled}
        onAdd={(field) => {
          setField({
            field: field,
            value: [...fields[field].value, initEntityEmptyForm()],
          });
        }}
        onDelete={(field, atomIdx: number) => {
          setField({
            field: field,
            value: [
              ...fields[field].value.slice(0, atomIdx),
              ...fields[field].value.slice(atomIdx + 1),
            ],
          });
        }}
      />
      <SurfaceLeaseFields
        atoms={props.row}
        idx={props.idx}
        trxRowDisabled={trxRowDisabled}
      />
    </>
  );
};

interface MineralLeaseTransactionProps {
  row: MineralLeaseAtoms;
  idx: number;
}
const MineralLeaseTransaction = (props: MineralLeaseTransactionProps) => {
  const [fields, setField] = useAtom(props.row.formAtom);

  const trxRowStatusAtom = useMemo(
    () =>
      atom((get) => {
        const formAtom = get(formAtoms).formAtom;
        const transactionStateAtom =
          get(formAtom).transactions.value[props.idx].status;
        return get(transactionStateAtom);
      }),
    // Used to keep the atom stable, expected empty deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const trxRowStatus = useAtomValue(trxRowStatusAtom);
  const trxRowDisabled = trxRowStatus === 'trash';

  return (
    <>
      <CommonFields
        id="mineral"
        lessees={fields.lessee.value}
        lessors={fields.lessor.value}
        trxRowDisabled={trxRowDisabled}
        onAdd={(field) => {
          setField({
            field: field,
            value: [...fields[field].value, initEntityEmptyForm()],
          });
        }}
        onDelete={(field, atomIdx: number) => {
          setField({
            field: field,
            value: [
              ...fields[field].value.slice(0, atomIdx),
              ...fields[field].value.slice(atomIdx + 1),
            ],
          });
        }}
      />
      <MineralLeaseFields
        atoms={props.row}
        idx={props.idx}
        trxRowDisabled={trxRowDisabled}
      />
    </>
  );
};

export { MineralLeaseTransaction, SurfaceLeaseTransaction };
