import { atom, useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { resourcesAtom } from 'atoms/root';
import { packageEvaluationForm } from './utils';
import { createMutationAtom } from 'atoms/create-resource-atom';
import {
  PackageEvaluationAllocationResponse,
  PackageEvaluationResponse,
  PackageEvaluationSlotAllocationsResponse,
  PackageResponse,
} from 'types/packages-api/responses';
import { createResourceAtom } from 'lib/atoms';
import { PackageEvaluationsResponse } from 'types/evaluation-api/responses';
import { ScenariosResponse } from 'types/api-responses';
import request from 'lib/request';
import { atomWithStorage, loadable } from 'jotai/utils';

const createShowPackageEvalDraftAtom = (initialValue: boolean) =>
  atom<boolean>(initialValue);

const usePackageEvaluationsAtom = (href: string) =>
  useMemo(() => createResourceAtom<PackageEvaluationsResponse[]>(href), [href]);

const useScenariosAtom = () => {
  const resources = useAtomValue(resourcesAtom);
  const scenariosHref = resources?.scenarios.href;
  return useMemo(() => {
    return createResourceAtom<ScenariosResponse[]>(scenariosHref);
  }, [scenariosHref]);
};

const useCreateFormAtom = () => {
  return useMemo(() => atom(packageEvaluationForm()), []);
};

const packageHrefAtom = atom<string | undefined>(undefined);
const slotAllocationsHrefAtom = atom<string | undefined>(undefined);
const unitAllocationsHrefAtom = atom<string | undefined>(undefined);

const packageAtomBase = atom<
  Promise<PackageResponse | { error: string } | undefined>
>(async (get) => {
  const href = get(packageHrefAtom);
  return href ? await request.get(href) : undefined;
});

const slotAllocationsAtomBase = atom<
  Promise<
    PackageEvaluationSlotAllocationsResponse[] | { error: string } | undefined
  >
>(async (get) => {
  const href = get(slotAllocationsHrefAtom);
  return href ? await request.get(href) : undefined;
});

const unitAllocationsAtomBase = atom<
  Promise<PackageEvaluationAllocationResponse[] | { error: string } | undefined>
>(async (get) => {
  const href = get(unitAllocationsHrefAtom);
  return href ? await request.get(href) : undefined;
});

const packageAtom = loadable(packageAtomBase);
const slotAllocationsAtom = loadable(slotAllocationsAtomBase);
const unitAllocationsAtom = loadable(unitAllocationsAtomBase);

const runEvaluationAtom = createMutationAtom<
  PackageEvaluationResponse,
  'packageEvaluation'
>();

const accordionVisibleAtom = atomWithStorage(
  'package-show-accordion-open',
  true
);
const summaryVisibleAtom = atomWithStorage('package-show-summary-open', true);

export {
  accordionVisibleAtom,
  createShowPackageEvalDraftAtom,
  packageAtom,
  packageHrefAtom,
  runEvaluationAtom,
  slotAllocationsAtom,
  slotAllocationsHrefAtom,
  summaryVisibleAtom,
  unitAllocationsAtom,
  unitAllocationsHrefAtom,
  useCreateFormAtom,
  usePackageEvaluationsAtom,
  useScenariosAtom,
};
