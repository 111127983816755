import {
  Pagination as CarbonPagination,
  SelectItem,
} from 'carbon-components-react';

interface PaginationState {
  page: number;
}

// The standard Carbon pagination component attempts to create a select box
// with an item for every page.  Some of our tables have hundreds of thousands
// of pages... use your imagination.
export class Pagination extends CarbonPagination {
  renderSelectItems = (pageCount: number) => {
    const page = (this.state as PaginationState).page,
      firstPage = Math.max(1, page - 9),
      lastPage = Math.min(pageCount, page + 9);

    const items = [];
    for (let i = firstPage; i <= lastPage; i++) {
      items.push(<SelectItem key={i} value={i} text={String(i)} />);
    }
    return items;
  };
}
