import {
  Add16,
  CopyFile16,
  DocumentView20,
  ViewOff20,
  ChevronLeft16,
  ChevronRight16,
  SysProvision16,
  Draggable16,
} from '@carbon/icons-react';
import { createMutationAtom } from 'atoms/create-resource-atom';
import {
  Button,
  Column,
  Grid,
  Row,
  Tile,
  Loading,
  InlineNotification,
} from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import { Default } from 'components/icons';
import { InterpretationForm } from 'components/interpretation-form';
import { interpretationDuplicateAtom } from 'components/interpretation-form/atoms';
import { InterpretationSearchModal } from 'components/interpretation-search';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useState, useCallback, useRef } from 'react';
import {
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';
import {
  documentProvisionStore,
  showProvisionedDocumentAtom,
  useDocumentAtom,
  useInterpretationsAtom,
} from '../common/atoms';
import { DocumentHeader } from '../common/header';
import {
  EmptyInterpretationCard,
  InterpretationCard,
  InterpretationCardSkeleton,
} from './interpretation-card';
import plur from 'plur';
import classNames from 'classnames';
import style from './page.module.scss';
import { Route } from 'components/route';
import { Helmet } from 'react-helmet-async';
import { addSearchParamToReferrer } from '../common/utils';
import {
  showDocumentFilePreferenceAtom,
  showInterpretationListPreferenceAtom,
} from './atoms';
import * as ProvisionedDocument from 'components/provisioned-document';
import { useBodyClass } from 'lib/hooks/useBodyClass';

const createDuplicateAtom = createMutationAtom<
  DocumentInterpretationResponse,
  'duplicateInterpretation'
>();

const RESIZABLE_INITIAL_WIDTH = 50;
const RESIZABLE_MIN_WIDTH = 20;
const RESIZABLE_MAX_WIDTH = 80;

const PageContent = ({
  document,
  getDocument,
}: {
  document: DocumentResponse;
  getDocument: () => Promise<void>;
}) => {
  const [width, setWidth] = useState(RESIZABLE_INITIAL_WIDTH);
  const isDragging = useRef(false);

  const dragHandler = useCallback(
    (e) => {
      if (!isDragging.current) return;

      const parentRect = e.currentTarget.getBoundingClientRect();
      const w = ((e.clientX - parentRect.left) / parentRect.width) * 100;

      if (w >= RESIZABLE_MIN_WIDTH && w <= RESIZABLE_MAX_WIDTH && w !== width) {
        setWidth(w);
      }
    },
    [width]
  );

  const startDrag = () => {
    isDragging.current = true;
  };

  const stopDrag = () => {
    isDragging.current = false;
  };

  const interpretationsAtom = useInterpretationsAtom(
    document?.resources.interpretations.href
  );
  let { loading, data: interpretations } = useAtomValue(interpretationsAtom);
  const refetchInterpretations = useSetAtom(interpretationsAtom);
  const setInterpretationDuplicate = useSetAtom(interpretationDuplicateAtom);
  const runRequest = useSetAtom(createDuplicateAtom);

  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  const [showDocument, setShowDocument] = useAtom(
    showDocumentFilePreferenceAtom
  );

  const [isInterpretationListVisible, setInterpretationListVisible] = useAtom(
    showInterpretationListPreferenceAtom
  );

  const [showProvisionedDocument, setShowProvisionedDocument] = useAtom(
    showProvisionedDocumentAtom
  );

  if (!document) {
    return null;
  }

  if (interpretations && 'error' in interpretations) {
    return (
      <InlineNotification
        kind="error"
        title={interpretations.error}
        lowContrast
      />
    );
  }

  const params = new URLSearchParams(location.search);
  const searchParams = params.get('referrer')
    ? `?referrer=${params.get('referrer')}`
    : '';

  interpretations = interpretations || [];

  return (
    <Grid className="bx--no-gutter" fullWidth condensed>
      <Row>
        <Column
          md={isInterpretationListVisible ? 0 : 2}
          lg={isInterpretationListVisible ? 0 : 3}
          as="nav"
        >
          <Tile className={style.masthead}>
            <header>
              <div className={style.btnsArea}>
                <Button
                  renderIcon={Add16}
                  size="md"
                  kind="tertiary"
                  className={style.btnHeader}
                  onClick={() => {
                    history.replace({
                      pathname: document.newInterpretationHref,
                      search: searchParams,
                    });
                  }}
                >
                  <span>New</span>
                </Button>
                <InterpretationSearchModal
                  title="Choose an Interpretation to Duplicate"
                  isOpen={duplicateModalOpen}
                  onClose={() => setDuplicateModalOpen(false)}
                  actions={(interpretation) => (
                    <Button
                      renderIcon={CopyFile16}
                      className={style.copyBtn}
                      type="ghost"
                      size="sm"
                      onClick={() => {
                        runRequest({
                          type: 'POST',
                          url: document.resources.duplicateInterpretations.href,
                          data: {
                            interpretation: {
                              id: interpretation.id,
                            },
                          },
                          onSuccess: (
                            interpretationDuplicate: DocumentInterpretationResponse
                          ) => {
                            setInterpretationDuplicate(interpretationDuplicate);
                            history.replace({
                              pathname: `${document.newInterpretationHref}`,
                              search: addSearchParamToReferrer(
                                location.search,
                                'duplicateId',
                                interpretation.id.toString()
                              ),
                            });
                            setDuplicateModalOpen(false);
                          },
                        });
                      }}
                    >
                      Copy from...
                    </Button>
                  )}
                />
                <Button
                  renderIcon={CopyFile16}
                  kind="ghost"
                  size="md"
                  className={style.btnHeader}
                  tooltipAlignment="center"
                  tooltipPosition="bottom"
                  iconDescription="Copy from..."
                  hasIconOnly
                  onClick={() => setDuplicateModalOpen(true)}
                />
                <Button
                  renderIcon={showDocument ? ViewOff20 : DocumentView20}
                  kind="ghost"
                  size="md"
                  className={style.btnHeader}
                  tooltipAlignment="center"
                  tooltipPosition="bottom"
                  iconDescription={
                    showDocument ? 'Hide Document' : 'Show Document'
                  }
                  hasIconOnly
                  onClick={() => {
                    setShowDocument(!showDocument);
                  }}
                />
                <Button
                  renderIcon={
                    showProvisionedDocument ? ViewOff20 : SysProvision16
                  }
                  kind="ghost"
                  size="md"
                  className={style.btnHeader}
                  tooltipAlignment="center"
                  tooltipPosition="bottom"
                  iconDescription={
                    showProvisionedDocument
                      ? 'Hide Provisioned Document'
                      : 'Show Provisioned Document'
                  }
                  hasIconOnly
                  onClick={() => {
                    setShowProvisionedDocument((c) => !c);
                  }}
                />
              </div>
            </header>
            <h2 className={style.interpretationCount}>
              {`(${document.interpretationsCount}) `}
              {plur('Interpretation', document.interpretationsCount)}
            </h2>
          </Tile>
          {loading
            ? [...Array(document.interpretationsCount).keys()].map((el) => (
                <InterpretationCardSkeleton key={el} />
              ))
            : interpretations.map((el, i) => (
                <InterpretationCard
                  key={el.id}
                  interpretation={el}
                  index={i}
                  searchParams={searchParams}
                />
              ))}
          <Switch>
            <Route exact path={`${path}/new`}>
              <EmptyInterpretationCard index={interpretations.length} />
            </Route>
          </Switch>
        </Column>
        <Column
          md={isInterpretationListVisible ? 16 : 6}
          lg={isInterpretationListVisible ? 16 : 13}
          className={style.loadOverlay}
        >
          <Row
            className={style.resizableGrid}
            onMouseMove={dragHandler}
            onMouseUp={stopDrag}
            onMouseLeave={stopDrag}
          >
            <div
              style={{
                width:
                  showProvisionedDocument || showDocument
                    ? `${width}%`
                    : `100%`,
              }}
            >
              <div className={style.closeAreabtn}>
                <Button
                  renderIcon={
                    isInterpretationListVisible ? ChevronRight16 : ChevronLeft16
                  }
                  kind="ghost"
                  hasIconOnly
                  iconDescription={
                    isInterpretationListVisible
                      ? `Show Interpretation List`
                      : `Hide Interpretation List`
                  }
                  tooltipAlignment="start"
                  tooltipPosition="top"
                  size="sm"
                  className={style.closePanelBtn}
                  onClick={() => {
                    setInterpretationListVisible(!isInterpretationListVisible);
                  }}
                />
              </div>
              <Switch>
                <Route exact path={`${path}/`}>
                  {!interpretations.length ? (
                    !loading ? (
                      <Tile className={style.emptyStateCol}>
                        <EmptyState
                          IconComponent={Default}
                          headerText="No Interpretations"
                          helperText="Interpretations would normally display here, but none have been added yet."
                        />
                      </Tile>
                    ) : (
                      <Loading small withOverlay />
                    )
                  ) : (
                    <Redirect
                      to={`${interpretations[0].href}${location.search}`}
                    />
                  )}
                </Route>
                <Route exact path={`${path}/new`}>
                  <InterpretationForm
                    key={location.search}
                    document={document}
                    interpretations={interpretations}
                    role={{
                      type: 'create',
                      href: document.resources.interpretations.href,
                    }}
                    refetchInterpretations={() => {
                      refetchInterpretations();
                      getDocument();
                    }}
                  />
                </Route>
                <Route exact path={`${path}/:id`}>
                  <InterpretationForm
                    key={location.pathname}
                    document={document}
                    interpretations={interpretations}
                    role={{ type: 'update', href: location.pathname }}
                    refetchInterpretations={() => {
                      refetchInterpretations();
                      getDocument();
                    }}
                  />
                </Route>
              </Switch>
            </div>

            {showProvisionedDocument || showDocument ? (
              <div className={style.dragHandle} onMouseDown={startDrag}>
                <Draggable16 />
              </div>
            ) : null}

            {showProvisionedDocument && (
              <div
                className={classNames(style.provisionedFrame, style.dragColumn)}
              >
                <div className={style.sticky}>
                  <ProvisionedDocument.List store={documentProvisionStore} />
                </div>
              </div>
            )}
            {showDocument && (
              <div
                className={classNames(style.documentFrame, style.dragColumn)}
              >
                {document.resources.image && (
                  <div className={style.sticky}>
                    <iframe src={document.resources.image.href} />
                  </div>
                )}
              </div>
            )}
          </Row>
        </Column>
      </Row>
    </Grid>
  );
};

const InterpretationsPage = () => {
  const location = useLocation();
  const [resource, id] = location.pathname.split('/').filter((el) => el);
  const documentHref = `/${resource}/${id}`;
  const documentAtom = useDocumentAtom(documentHref);
  const [document, getDocument] = useAtom(documentAtom);
  useBodyClass('interpretationPage', true);

  if (document.data && 'error' in document.data) {
    return (
      <InlineNotification
        kind="error"
        title={document.data.error}
        lowContrast
      />
    );
  }
  if (!document.data) return null;

  return (
    <>
      <DocumentHeader document={document.data} getDocument={getDocument}>
        <Helmet>
          <title>{`${document.data.documentTitle} - Interpretations`}</title>
        </Helmet>
        <PageContent document={document.data} getDocument={getDocument} />
      </DocumentHeader>
    </>
  );
};

export { InterpretationsPage };
