import { atom, useStore } from 'jotai';
import { loadable } from 'jotai/utils';
import { createResourceAtom } from 'lib/atoms';
import request from 'lib/request';
import { useEffect, useMemo } from 'react';
import {
  CompletionConfigurationOption,
  CompletionConfigurationResponse,
  CompletionItemResponse,
  CompletionPromptConfigurationOption,
  OCRExtractionResponse,
  ProvisionResponse,
} from 'types/api-responses';

export const provisionsHrefAtom = atom<string | undefined>(undefined);
export const provisionHrefAtom = atom<string | undefined>(undefined);
export const provisionListAtom = atom<ProvisionResponse[]>([]);

const provisionsAtomBase = atom<
  Promise<ProvisionResponse[] | { error: string } | undefined>
>(async (get) => {
  const href = get(provisionsHrefAtom);
  return href ? await request.get(href) : undefined;
});

const provisionAtomBase = atom<
  Promise<ProvisionResponse | { error: string } | undefined>
>(async (get) => {
  const href = get(provisionHrefAtom);
  return href ? await request.get(href) : undefined;
});

export const provisionsAtom = loadable(provisionsAtomBase);
export const provisionAtom = loadable(provisionAtomBase);

export const useOCRExtractionAtom = (href?: string) =>
  useMemo(() => createResourceAtom<OCRExtractionResponse>(href), [href]);

export const useCompletionItems = (href?: string) =>
  useMemo(() => createResourceAtom<CompletionItemResponse[]>(href), [href]);

export const useConfigurationOptionsAtom = (href?: string) =>
  useMemo(
    () => createResourceAtom<CompletionConfigurationResponse>(href),
    [href]
  );

export function getFormDefaults(
  configurationOptions: CompletionConfigurationResponse
) {
  const extractionConfigurations =
    configurationOptions.extractionConfigurations.filter((el) => el.default)[0];
  const promptConfigurations = configurationOptions.promptConfigurations.filter(
    (el) => el.default
  )[0];
  const completionConfigurations =
    configurationOptions.completionConfigurations.filter((el) => el.default)[0];

  return {
    extractionConfigurations,
    promptConfigurations,
    completionConfigurations,
  };
}

export function getPayload({
  extractionConfiguration,
  promptConfiguration,
  completionConfiguration,
}: {
  extractionConfiguration: CompletionConfigurationOption;
  promptConfiguration: CompletionPromptConfigurationOption;
  completionConfiguration: CompletionConfigurationOption;
}) {
  return {
    extractionConfiguration: {
      provider: extractionConfiguration.provider,
      version: extractionConfiguration.version,
    },
    promptConfiguration: { version: promptConfiguration.version },
    completionConfiguration: {
      provider: completionConfiguration.provider,
      version: completionConfiguration.version,
    },
  };
}

export function useClearStore() {
  const store = useStore();
  useEffect(() => {
    return () => {
      store.set(provisionsHrefAtom, undefined);
      store.set(provisionHrefAtom, undefined);
      store.set(provisionListAtom, []);
    };
  }, [store]);
}
