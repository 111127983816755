import { Tag, TextInput } from 'carbon-components-react';
import { TextInputSharedProps } from 'carbon-components-react/lib/components/TextInput/props';
import { useCallback, useState } from 'react';

type onRemove = (item: string) => void;

const VALID_QUARTER_CALL_TOKENS = Object.seal([
  'N2',
  'S2',
  'E2',
  'W2',
  'NE',
  'NW',
  'SE',
  'SW',
]);

const isValidQuarterCall = (el: string) => {
  let token,
    remainder = el,
    valid = true;
  while (valid && remainder.length) {
    token = remainder.substring(0, 2);
    remainder = remainder.substring(2);
    valid = VALID_QUARTER_CALL_TOKENS.includes(token);
  }
  return valid;
};

const QUARTER_CALLS_DELIMITERS = /[\s,\u{1f98e}]+/u;
const extractDelimitedQuarterCalls = (text: string) => {
  const values = text.toUpperCase().split(QUARTER_CALLS_DELIMITERS);
  let remaining;

  // If the value does end with whitespace or a comma then we want to include
  // all values from the text.  In this case, the results of the split will
  // contain an empty string as the last element.
  //
  // Otherwise, leave the final value in the input.
  const finalValue = values[values.length - 1];
  if (finalValue) {
    remaining = finalValue;
    delete values[values.length - 1];
  }

  return { valuesToAdd: values.filter(Boolean), inputValue: remaining ?? '' };
};

const QuarterCallTag = ({
  name,
  onRemove,
}: {
  name: string;
  onRemove: onRemove;
}) => {
  return (
    <Tag type={'blue'} filter onClose={() => onRemove(name)}>
      {name}
    </Tag>
  );
};

interface Props extends Omit<TextInputSharedProps, 'value' | 'onChange'> {
  items: string[] | undefined;
  onRemove: onRemove;
  onAdd: (quarterCall: string) => void;
}

const QuarterCalls = ({ items = [], id, onAdd, onRemove, ...rest }: Props) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const onChange = useCallback(
    (text: string) => {
      setError('');
      const invalid: string[] = [];
      text = text.toUpperCase();
      const { valuesToAdd, inputValue } = extractDelimitedQuarterCalls(text);
      valuesToAdd.forEach((value) => {
        if (!isValidQuarterCall(value)) {
          invalid.push(value);
        } else {
          onAdd(value);
        }
      });
      if (invalid.length) {
        setError(`Invalid quarter calls not added: ${invalid.join(', ')}`);
      }
      setValue(inputValue);
    },
    [onAdd]
  );
  return (
    <>
      <TextInput
        id={id}
        type="text"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={(event) => {
          if (value) {
            if (event.key === 'Enter') {
              onChange(value + ' ');
              event.preventDefault();
            } else if (event.key === 'Escape') {
              onChange('');
            }
          }
        }}
        onBlur={(event) => onChange(event.target.value + ' ')}
        invalid={!!error}
        invalidText={error}
        {...rest}
      />
      {items.map((el) => (
        <QuarterCallTag key={el} name={el} onRemove={onRemove} />
      ))}
    </>
  );
};

export default QuarterCalls;
