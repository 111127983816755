import {
  createResourceFormAtom,
  FormFieldState,
  FormRowStatus,
  hasChangedFields,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { atom, Getter, Setter } from 'jotai';
import { DocumentInterpretationResponse } from 'types/api-responses';
import { CapacityForm, FormInitialValues } from './types';

export interface CapacityErrors {
  errors: {
    predicate?: string;
    object?: string;
    subject?: string;
  };
}

export type CapacityFormAtoms = ReturnType<typeof createFormAtom>;

const createFormAtom = (defaultValue: CapacityForm, status: FormRowStatus) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<CapacityForm, CapacityErrors>(defaultValue),
  };
};

const formDefault = (
  { id, predicate, subject, object }: FormInitialValues,
  state: FormFieldState
) =>
  initializeForm<CapacityForm>(
    {
      id,
      predicate,
      subject,
      object,
    },
    state
  );

const initForm = (
  capacities:
    | DocumentInterpretationResponse['interpretationCapacities']
    | undefined,
  state: FormFieldState
) => {
  if (!capacities) {
    return [];
  }
  return capacities.map((capacity) =>
    createFormAtom(
      formDefault(
        {
          id: capacity.id,
          predicate: capacity.predicate,
          subject: capacity.subject,
          object: capacity.object,
        },
        state
      ),
      rowStatus(state)
    )
  );
};

const initEmptyForm = () => {
  return createFormAtom(
    formDefault(
      {
        id: undefined,
        predicate: null,
        object: null,
        subject: null,
      },
      'updated'
    ),
    'new'
  );
};

const validate = (atoms: CapacityFormAtoms, get: Getter, set: Setter) => {
  let hasError = false;
  const fields = get(atoms.row.formAtom);

  const status = get(atoms.status);
  if (status === 'trash') return;

  const errorObj: CapacityErrors = { errors: {} };
  const isNewCapacity = fields.id.value === undefined;

  if (
    (isNewCapacity || fields.predicate.state === 'updated') &&
    !fields.predicate.value
  ) {
    errorObj.errors.predicate = 'Capacity predicate is mandatory';
    hasError = true;
  }
  if (
    (isNewCapacity || fields.object.state === 'updated') &&
    !fields.object.value
  ) {
    errorObj.errors.object = 'Capacity object is mandatory';
    hasError = true;
  }
  if (!fields.subject.value) {
    errorObj.errors.subject = 'Capacity subject is mandatory';
    hasError = true;
  }
  set(atoms.row.dirtyFieldsAtom, errorObj);
  return hasError;
};

const getPayload = (atoms: CapacityFormAtoms, get: Getter) => {
  const form = get(atoms.row.formAtom);
  const rowStatus = get(atoms.status);

  if (rowStatus === 'trash') {
    return { _destroy: true, id: form.id.value };
  }

  if (!hasChangedFields(form)) return;

  return {
    capacityAttributes: {
      subjectId: form.subject.value?.id,
      predicate: form.predicate.value,
      objectId: form.object.value?.id,
    },
    id: form.id.value,
  };
};

export { initForm, initEmptyForm, validate, getPayload };
