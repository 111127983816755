import { Dropdown } from 'carbon-components-react';
import { searchAtom } from '../../atoms';
import { useSetAtom } from 'jotai';
import { defaultPayload } from '../../utils';

const SortDropdown = () => {
  const setSearchPayload = useSetAtom(searchAtom);
  return (
    <Dropdown
      id="default"
      label="Last Updated - Newest"
      items={[
        {
          id: 'effective_date',
          text: 'Effective Date - Newest',
          direction: 'DESC',
        } as const,
        {
          id: 'effective_date',
          text: 'Effective Date - Oldest',
          direction: 'ASC',
        } as const,
        {
          id: 'superseding_date',
          text: 'Superseding Date - Newest',
          direction: 'DESC',
        } as const,
        {
          id: 'superseding_date',
          text: 'Superseding Date - Oldest',
          direction: 'ASC',
        } as const,
        {
          id: 'file_date',
          text: 'File Date - Newest',
          direction: 'DESC',
        } as const,
        {
          id: 'file_date',
          text: 'File Date - Oldest',
          direction: 'ASC',
        } as const,
        {
          id: 'instrument_date',
          text: 'Instrument Date - Newest',
          direction: 'DESC',
        } as const,
        {
          id: 'instrument_date',
          text: 'Instrument Date - Oldest',
          direction: 'ASC',
        } as const,
        {
          id: 'recorded_date',
          text: 'Recorded Date - Newest',
          direction: 'DESC',
        } as const,
        {
          id: 'recorded_date',
          text: 'Recorded Date - Oldest',
          direction: 'ASC',
        } as const,
        {
          id: 'updated_at',
          text: 'Last Updated - Newest',
          direction: 'DESC',
        } as const,
        {
          id: 'updated_at',
          text: 'Last Updated - Oldest',
          direction: 'ASC',
        } as const,
      ]}
      itemToString={(item) => (item ? item.text : '')}
      onChange={({ selectedItem }) => {
        selectedItem &&
          setSearchPayload((current) => ({
            ...defaultPayload,
            pageSize: current?.pageSize || 10,
            text: current?.text || '',
            sortAttribute: selectedItem.id,
            sortDirection: selectedItem.direction,
            startDate: current?.startDate || '',
            endDate: current?.endDate || '',
            dateTarget: current?.dateTarget || 'none',
          }));
      }}
    />
  );
};

export { SortDropdown };
