import { WaveDirection24 } from '@carbon/icons-react';
import classNames from 'classnames';

import style from './icons.module.scss';

const Water = () => {
  return <WaveDirection24 className={classNames(style.icon, style.water)} />;
};

export default Water;
