import { useAtomValue } from 'jotai';
import {
  useCreateEvaluationPackageOverviewAtom,
  useCreateDiscountRatesAtom,
} from './atoms';
import {
  InlineLoading,
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  PackageDiscountRates,
  PackageDiscountRatesResponse,
  PackagePricingCase,
  ReserveCategory,
  ReserveCategorySummary,
} from 'types/packages-api/responses';
import { format, parseISO } from 'date-fns';
import {
  FORMATTER,
  PERCENT_FORMATTER_TWO_DECIMALS,
  DECIMAL_FORMATTER_TWO_FRACTIONS,
} from 'lib/ui';

import style from './package-overview.module.scss';
import { Error16, Warning16 } from '@carbon/icons-react';

const NO_DATA = '--';

const DiscountRateValue = ({
  discountRate,
  investment,
  loading,
  error,
}: {
  discountRate: number | undefined;
  investment: { date: string; amount: number } | undefined;
  loading: boolean;
  error: string | null;
}) => {
  if (loading) {
    return <InlineLoading iconDescription="Loading" />;
  }

  if (error) {
    return (
      <TooltipDefinition
        className={style.toolTip}
        direction="top"
        tooltipText={'Error loading discount rate'}
      >
        <Error16 fill="#DA1E28" />
      </TooltipDefinition>
    );
  }

  if (!investment) {
    return <>{NO_DATA}</>;
  }

  return investment.amount < 0 ? (
    <TooltipDefinition
      className={style.toolTip}
      direction="top"
      tooltipText="Negative investment amounts are not supported"
    >
      <Warning16 fill="#FFAB00" />
    </TooltipDefinition>
  ) : discountRate ? (
    <>{PERCENT_FORMATTER_TWO_DECIMALS.format(discountRate)}</>
  ) : (
    <>{NO_DATA}</>
  );
};

const ResCatRow = ({
  row,
  discountRates,
  ratesLoading,
  ratesError,
  label = '',
  className,
}: {
  row: ReserveCategory | ReserveCategorySummary;
  discountRates: PackageDiscountRates[];
  ratesLoading: boolean;
  ratesError: string | null;
  label?: string;
  className?: string;
}) => {
  let unadjustedRate, commissionAdjustedRate;

  for (const rate of discountRates) {
    const includesAdjusted = rate.sensitivityName
      .toLowerCase()
      .includes('adjusted');

    if (includesAdjusted) {
      commissionAdjustedRate = rate;
    } else {
      unadjustedRate = rate;
    }
  }
  return (
    <TableRow className={className}>
      <TableCell>{'name' in row ? row.name : label}</TableCell>
      <TableCell>
        {row.netAcres
          ? DECIMAL_FORMATTER_TWO_FRACTIONS.format(row.netAcres)
          : NO_DATA}
      </TableCell>
      <TableCell>
        <DiscountRateValue
          discountRate={commissionAdjustedRate?.rate}
          investment={commissionAdjustedRate?.investment}
          loading={ratesLoading}
          error={ratesError}
        />
      </TableCell>
      <TableCell>
        {row.adjustedDollarsPerNetAcre
          ? FORMATTER.format(row.adjustedDollarsPerNetAcre)
          : NO_DATA}
      </TableCell>
      <TableCell>
        {row.adjustedTotalDollarAmount
          ? FORMATTER.format(row.adjustedTotalDollarAmount)
          : NO_DATA}
      </TableCell>

      <TableCell>
        <DiscountRateValue
          discountRate={unadjustedRate?.rate}
          investment={unadjustedRate?.investment}
          loading={ratesLoading}
          error={ratesError}
        />
      </TableCell>
      <TableCell>
        {row.dollarsPerNetAcre
          ? FORMATTER.format(row.dollarsPerNetAcre)
          : NO_DATA}
      </TableCell>
      <TableCell>
        {row.totalDollarAmount
          ? FORMATTER.format(row.totalDollarAmount)
          : NO_DATA}
      </TableCell>
    </TableRow>
  );
};

const PricingRows = ({
  pricingCase,
  discountRates,
  ratesLoading,
  ratesError,
  price,
  unitType,
}: {
  index: number;
  pricingCase: PackagePricingCase;
  discountRates: PackageDiscountRatesResponse;
  ratesLoading: boolean;
  ratesError: string | null;
  price: 'target' | 'max';
  unitType: string;
}) => {
  const rates = (
    discountRates.find(
      (el) => el.name.toLowerCase() === price.toLowerCase()
    ) || { rates: [] }
  ).rates;

  const pdp = pricingCase.reserveCategories.find((el) => el.name === 'PDP');
  const pdpRates = rates.filter((el) => el.slotCategory === 'pdp');

  const duc = pricingCase.reserveCategories.find((el) => el.name === 'DUC');
  const ducRates = rates.filter((el) => el.slotCategory === 'duc');

  const permit = pricingCase.reserveCategories.find(
    (el) => el.name === 'Permit'
  );
  const permitRates = rates.filter((el) => el.slotCategory === 'permit');

  const ltd = pricingCase.reserveCategories.find((el) => el.name === 'LTD');
  const ltdRates = rates.filter((el) => el.slotCategory === 'ltd');
  const totalRates = rates.filter((el) => el.slotCategory === 'total');
  const totalLabel = price === 'target' ? 'Target Price' : 'Max Price';

  return (
    <>
      <TableRow className={style.sectionTitle}>
        <TableCell colSpan={8}>
          {price === 'target' ? 'Target Pricing' : 'Max Pricing'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}></TableCell>
        <TableCell colSpan={3} className={style.sectionBroker}>
          Excluding Commissions (Broker Pricing)
        </TableCell>
        <TableCell colSpan={3} className={style.sectionSim}>
          Including Commissions (SIM Valuation)
        </TableCell>
      </TableRow>
      <TableRow>
        <TableHeader scope="col">Type</TableHeader>
        <TableHeader scope="col">{unitType}</TableHeader>
        <TableHeader scope="col">PV Rate</TableHeader>
        <TableHeader scope="col">$/{unitType}</TableHeader>
        <TableHeader scope="col">Total $ Amount</TableHeader>
        <TableHeader scope="col">PV Rate</TableHeader>
        <TableHeader scope="col">$/{unitType}</TableHeader>
        <TableHeader scope="col">Total $ Amount</TableHeader>
      </TableRow>

      {pdp && (
        <ResCatRow
          row={pdp}
          discountRates={pdpRates}
          ratesLoading={ratesLoading}
          ratesError={ratesError}
        />
      )}
      {duc && (
        <ResCatRow
          row={duc}
          discountRates={ducRates}
          ratesLoading={ratesLoading}
          ratesError={ratesError}
        />
      )}
      {permit && (
        <ResCatRow
          row={permit}
          discountRates={permitRates}
          ratesLoading={ratesLoading}
          ratesError={ratesError}
        />
      )}
      {ltd && (
        <ResCatRow
          row={ltd}
          discountRates={ltdRates}
          ratesLoading={ratesLoading}
          ratesError={ratesError}
        />
      )}
      <ResCatRow
        className={style.highlightTotal}
        row={pricingCase.summary}
        discountRates={totalRates}
        ratesLoading={ratesLoading}
        ratesError={ratesError}
        label={totalLabel}
      />
    </>
  );
};

const DateRow = ({
  label,
  date,
  className,
}: {
  label: string;
  date: string | null;
  className?: string;
}) => {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell colSpan={7} className={className}>
        {date && format(parseISO(date), 'MM/dd/yyyy')}
      </TableCell>
    </TableRow>
  );
};

const Pricing = ({
  href,
  pricingCases,
  unitType,
}: {
  href: string;
  pricingCases: PackagePricingCase[];
  unitType: string;
}) => {
  const discountRatesAtom = useCreateDiscountRatesAtom(href);
  const discountRates = useAtomValue(discountRatesAtom);
  const pricingTypes = ['Target', 'Max'];

  const rates: PackageDiscountRatesResponse =
    discountRates.data && !('error' in discountRates.data)
      ? discountRates.data
      : [];

  const discountRatesError =
    discountRates.data && 'error' in discountRates.data
      ? discountRates.data.error
      : null;

  return (
    <>
      {pricingTypes.map((pricingTypeName, idx) => {
        const pricingCase = pricingCases.find(
          (el) => el.name === pricingTypeName
        );

        if (!pricingCase) return null;

        return (
          <PricingRows
            index={idx}
            key={pricingTypeName}
            price={pricingTypeName.toLowerCase() as 'target' | 'max'}
            unitType={unitType}
            pricingCase={pricingCase}
            discountRates={rates}
            ratesLoading={discountRates.loading}
            ratesError={discountRatesError}
          />
        );
      })}
    </>
  );
};

const PackageOverview = ({ href }: { href: string }) => {
  const overviewAtom = useCreateEvaluationPackageOverviewAtom(href);
  const overview = useAtomValue(overviewAtom);

  if (overview.loading) return <InlineLoading title="Loading overview" />;

  if (overview.data && 'error' in overview.data)
    return (
      <InlineNotification
        kind="error"
        title={overview.data.error}
        lowContrast
      />
    );

  if (!overview.data) {
    return (
      <InlineNotification
        kind="error"
        title={'Something went wrong'}
        lowContrast
      />
    );
  }

  const unitType = overview.data.unitType;
  const today = new Date();
  const expiryDate =
    overview.data.evaluationExpirationDate !== null
      ? new Date(overview.data.evaluationExpirationDate)
      : new Date();
  const isExpired = expiryDate < today;

  return (
    <div>
      <TableContainer>
        <Table size="sm" className={style.packageOverviewTable}>
          <TableBody>
            <Pricing
              href={overview.data.resources.discountRates.href}
              pricingCases={overview.data.pricingCases}
              unitType={unitType}
            />
            <DateRow label="Eval Date" date={overview.data.evaluationDate} />
            <DateRow
              label="Eval Expiration"
              date={overview.data.evaluationExpirationDate}
              className={isExpired ? style.expiredDate : ''}
            />
            <DateRow
              label="Transaction Effective Date"
              date={overview.data.transactionEffectiveDate}
            />
            <DateRow
              label="Discount To Date"
              date={overview.data.discountToDate}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export { PackageOverview };
