import style from './facets.module.scss';
import { SortDropdown } from './sort-dropdown/';
import { DateFilter } from './date-filter/';
import { ProvisionsFilter } from './provisions-filter';

const Facets = () => {
  return (
    <div className={style.facetContainer}>
      <fieldset className={style.fieldContainer}>
        <legend className={style.legend}>Date Filter</legend>
        <div>
          <DateFilter />
        </div>
      </fieldset>
      <fieldset className={style.fieldContainer}>
        <legend className={style.legend}>Sort Order</legend>
        <div>
          <SortDropdown />
        </div>
      </fieldset>
      <fieldset className={style.fieldContainer}>
        <legend className={style.legend}>Document Filter</legend>
        <div>
          <ProvisionsFilter />
        </div>
      </fieldset>
    </div>
  );
};

export { Facets };
