import { useAtomValue } from 'jotai';
import { Layer, MapRef, Source } from 'react-map-gl';
import { createMapSourceAtom } from './atoms';
import { MapUserConfigAtom } from './types';
import { useMemo } from 'react';

const BasinLayer = ({
  mapRef,
  mapConfigAtom,
}: {
  mapRef: MapRef | null;
  mapConfigAtom: MapUserConfigAtom;
}) => {
  const basinsSourceAtom = useMemo(() => createMapSourceAtom('basins'), []);
  const basinsSource = useAtomValue(basinsSourceAtom);
  const mapConfig = useAtomValue(mapConfigAtom);
  if (!basinsSource) return null;

  const tier = mapRef?.getMap().getLayer('tier-fill') ? 'tier-fill' : undefined;
  const devAreas = mapRef?.getMap().getLayer('development-area-fill')
    ? 'development-area-fill'
    : undefined;

  const beforeLayerId = tier ? tier : devAreas ? devAreas : undefined;

  return (
    <Source id="basins" type="vector" url={basinsSource}>
      {mapConfig.basinTier.options.basin && (
        <Layer
          source-layer="basins"
          beforeId={beforeLayerId}
          id="basin-fill"
          type="fill"
          paint={{
            'fill-color': [
              'match',
              ['get', 'gis_basin'],
              'MIDLAND',
              '#E8B4D9',
              'DELAWARE',
              '#D0BEEB',
              'CENTRAL PLATFORM',
              '#B7C5E5',
              'EAGLE FORD',
              '#9ECFE0',
              'HAYNESVILLE',
              '#86D8D4',
              'BARNETT',
              '#6BD8C6',
              'WOODFORD',
              '#6ED9B9',
              'NIOBRARA',
              '#8DD3A7',
              'BAKKEN',
              '#B6CBA0',
              'MARCELLUS',
              '#D1C18E',
              'UTICA',
              '#D9B28C',
              '#EFD9CE',
            ],
            'fill-opacity': 0.4,
          }}
        />
      )}
    </Source>
  );
};

export { BasinLayer };
