import NoFormationSvg from 'images/srp-illustration-formation.svg';
import { useAtomValue } from 'jotai';
import { EmptyState } from 'components/empty-state';
import { useWellFormationAtom } from './atoms';
import { WellFormationResponse, WellsResponse } from 'types/api-responses';
import classNames from 'classnames';
import {
  InlineNotification,
  SkeletonPlaceholder,
  Tile,
} from 'carbon-components-react';
import { format } from 'lib/ui';

import style from './formation-and-depth.module.scss';
import { Field } from './field';

const formatAsFeet = (value: number | null) => {
  if (value === null) return null;
  return `${format.number(value)} ft`;
};

const Layer = ({
  layer,
  groundElevation,
}: {
  layer: WellFormationResponse['layers'][0];
  groundElevation: number;
}) => {
  const className = classNames([
    style.formationName,
    style[`economicValue${layer.economicValue}`],
  ]);

  const depth = groundElevation - layer.elevation;

  return (
    <tr>
      <th scope="row" className={className}>
        {layer.name}
      </th>
      <td className={style.formationDepth}>{formatAsFeet(depth)}</td>
    </tr>
  );
};

const Formation = ({ formation }: { formation: WellFormationResponse }) => {
  return (
    <div className={style.formation}>
      <Tile>
        <div className={style.formationWrapper}>
          <h4 className={style.sectionSubheader}>Formation</h4>
          <table className={style.formationsTable}>
            <tbody>
              {formation.layers.map((layer) => (
                <Layer
                  layer={layer}
                  groundElevation={formation.groundElevation ?? 0}
                  key={layer.name}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Tile>
    </div>
  );
};

export function Depth({ well }: { well: WellsResponse }) {
  return (
    <div className={style.infoRow}>
      <div className={style.fields}>
        <Field
          label="Vendor Measured Depth"
          value={formatAsFeet(well.measuredDepth)}
        />
        <Field
          label="Vendor True Vertical Depth"
          value={formatAsFeet(well.trueVerticalDepth)}
        />
      </div>
    </div>
  );
}

const LandingZone = ({ well }: { well: WellsResponse }) => {
  return (
    <div className={style.infoRow}>
      <div className={style.fields}>
        <Field
          label="SRP Landing Zone"
          value={well.srpLandingZone ? well.srpLandingZone : 'Not Available'}
        />
        <Field
          label="Vendor Landing Zone"
          value={
            well.vendorLandingZone ? well.vendorLandingZone : 'Not Available'
          }
        />
      </div>
    </div>
  );
};

const FormationAndDepth = ({ well }: { well: WellsResponse }) => {
  const wellFormationAtom = useWellFormationAtom(well.resources.formation.href);
  const formation = useAtomValue(wellFormationAtom);

  if (formation.loading) {
    return <SkeletonPlaceholder className={style.formationsTablePlaceholder} />;
  }

  if (formation.data && 'error' in formation.data)
    return (
      <InlineNotification
        kind="error"
        title={formation.data.error}
        lowContrast
      />
    );

  const hasEnoughFormationData = Boolean(
    formation.data?.layers?.length && formation.data?.groundElevation
  );

  return (
    <div className={style.formationAndDepth}>
      {hasEnoughFormationData ? (
        <div className={style.formationColumn}>
          {formation.data && <Formation formation={formation.data} />}
        </div>
      ) : (
        <EmptyState
          icon={NoFormationSvg}
          headerText="No Formation Data Available"
          helperText="Currently Insights doesn't have any production data."
        />
      )}
      <div className={style.depthColumn}>
        <Depth well={well} />
        <LandingZone well={well} />
      </div>
    </div>
  );
};

export { FormationAndDepth };
