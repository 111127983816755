import { Add16, Globe32 } from '@carbon/icons-react';
import { FormRowStatus } from 'atoms/create-resource-atom';
import {
  Button,
  Checkbox,
  Column,
  InlineNotification,
  Row,
} from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { DeleteRowButton } from 'components/interpretation-form/delete-row-button';
import { QuarterCalls } from 'components/quarter-calls';
import { atom, useAtom, useAtomValue } from 'jotai';
import { memo, useCallback } from 'react';
import { formAtom, locationErrorAtom } from './atoms';
import { DocumentForm, DocumentForms } from './types';

import style from './form.module.scss';
import { SurveyAreaAutocomplete } from 'components/survey-area-autocomplete';
import { AtomizedForm, ExtractAtom, getError } from 'atoms/form-atoms';
import { locationRow } from './utils';

interface FormProps {
  form: ExtractAtom<AtomizedForm<DocumentForm>['locationAttributes']>[0];
  atomIdx: number;
  onDelete?: (atomIdx: number) => void;
}

const SurveyArea = memo(({ atomIdx, form, onDelete }: FormProps) => {
  const [surveyArea, setSurveyArea] = useAtom(form.referenceLocation);
  const [quarterCalls, setQuarterCalls] = useAtom(form.quarterCalls);

  return (
    <FormRow>
      <Row>
        <Column md={4} lg={10}>
          <SurveyAreaAutocomplete
            id={`${atomIdx}-new-doc-reference-location`}
            placeholder=""
            index={atomIdx}
            location={surveyArea}
            onChange={(data) => {
              data.selectedItem && setSurveyArea(data.selectedItem);
            }}
            light
          />
        </Column>
        <Column md={3} lg={5}>
          <QuarterCalls
            light
            items={quarterCalls.value}
            labelText="Quarter Call"
            placeholder="Quarter Call"
            id={`${atomIdx}-quarter-calls`}
            disabled={!quarterCalls.value}
            onRemove={(quarterCall) => {
              setQuarterCalls((current) =>
                current.filter((el) => el !== quarterCall)
              );
            }}
            onAdd={(quarterCall) => {
              setQuarterCalls((current) => {
                const quarterCalls = current;
                if (!quarterCalls.includes(quarterCall)) {
                  return [...quarterCalls, quarterCall];
                }
                return quarterCalls;
              });
            }}
          />
        </Column>
        <Column md={1} lg={1}>
          <DeleteRowButton
            statusAtom={atom<FormRowStatus>('new')}
            atomIdx={atomIdx}
            onDelete={onDelete}
            adjustForLabel={true}
          />
        </Column>
      </Row>
    </FormRow>
  );
});
SurveyArea.displayName = 'SurveyArea';

const NoLocations = () => {
  const form = useAtomValue(formAtom);
  const [field, setField] = useAtom(form.noLocations);
  const error = useAtomValue(locationErrorAtom);
  return (
    <>
      <FormRow>
        <Checkbox
          id="noLocations"
          labelText="This document has no survey location"
          checked={field.value}
          onChange={() => setField((current) => !current)}
        />
        {!error.isValid ? (
          <span className={style.error}>{error.error}</span>
        ) : null}
      </FormRow>
    </>
  );
};

interface Props {
  formAtom: DocumentForms;
  required?: boolean;
}

const SurveyAreaList = ({ formAtom, required = false }: Props) => {
  const form = useAtomValue(formAtom);
  const [locations, setLocations] = useAtom(form.locationAttributes);
  const onDelete = useCallback(
    (index: number) => {
      setLocations((current) => current.filter((_, i) => i !== index));
    },
    [setLocations]
  );

  const error = getError(locations);

  return (
    <FormSection title="Applicable Areas" iconComponent={<Globe32 />}>
      <FormRow>
        {locations.value.map((locationForm, i) => {
          return (
            <SurveyArea
              key={i}
              form={locationForm}
              atomIdx={i}
              onDelete={onDelete}
            />
          );
        })}
        <Button
          kind="ghost"
          renderIcon={Add16}
          onClick={() => {
            setLocations((current) => [...current, locationRow()]);
          }}
        >
          Add Survey Area
        </Button>
      </FormRow>
      {!required && !locations.value.length && <NoLocations />}
      {required && !locations.value.length && error && (
        <InlineNotification lowContrast kind="error" title={error} />
      )}
    </FormSection>
  );
};

export { SurveyAreaList };
