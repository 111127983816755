import mapboxGl, { LngLatBounds } from 'mapbox-gl';
import { cyan, teal } from '@carbon/colors';
import { GeoJSON, Geometry } from 'geojson';

export const POLYGON_SELECTED_COLOR = teal[30];
export const POLYGON_UNSELECTED_COLOR = cyan[90];
export const BACKGROUND_COLOR = teal[40];

function findCenter(geometry: Geometry) {
  let coordinates: GeoJSON.Position[] = [];
  if (geometry.type === 'Polygon') {
    coordinates = geometry.coordinates.flat(1);
  } else if (geometry.type === 'MultiPolygon') {
    coordinates = geometry.coordinates.flat(2);
  }

  const bounds = coordinates.reduce(
    (bounds: LngLatBounds, coordinate) =>
      bounds.extend([coordinate[0], coordinate[1]]),
    new mapboxGl.LngLatBounds()
  );

  const center = bounds.getCenter();
  return { longitude: center.lng, latitude: center.lat };
}

function buildPolygonStyle({
  color,
  lineColor,
  fillOpacity = 0.3,
  lineOpacity = 0.4,
}: {
  color: string;
  lineColor?: string;
  fillOpacity?: number;
  lineOpacity?: number;
}) {
  return Object.seal({
    fill: {
      'fill-color': color,
      'fill-opacity': fillOpacity,
    },
    fillHighlighted: {
      'fill-color': color,
      'fill-opacity': 1,
    },
    lineHighlighted: {
      'line-color': 'yellow',
      'line-width': 3,
      'line-opacity': 1,
    },
    line: {
      'line-color': lineColor || color,
      'line-width': 3,
      'line-opacity': lineOpacity,
    },
  });
}

export { findCenter, buildPolygonStyle };
