import { DocumentResponse } from 'types/api-responses';
import { atomField, required } from 'atoms/form-atoms';
import { AtomizedDocumentEditForm } from './types';

const documentEditForm = (
  document: DocumentResponse
): AtomizedDocumentEditForm => {
  return {
    instrumentDate: atomField(document.instrumentDate || null),
    effectiveDate: atomField(document.effectiveDate || null),
    recordedDate: atomField(document.recordedDate || null),
    fileDate: atomField(document.fileDate || null),
    bookType: atomField(document.bookType || null, { validate: required }),
    documentType: atomField(document.documentType || null, {
      validate: required,
    }),
    volume: atomField(document.volume || null),
    page: atomField(document.page || null),
    instrumentNumber: atomField(document.instrumentNumber || null),
    countyId: atomField<number | null>(null),
  };
};

export { documentEditForm };
