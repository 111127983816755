import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useMemo } from 'react';
import { fetchColumnVisibility, updateColumnVisibility } from 'utils/tables';

export function useColumnVisibility(tableId: string) {
  const columnVisibilityAtom = useMemo(
    () =>
      atomWithStorage<Record<string, boolean>>(
        tableId,
        {},
        {
          getItem: (key: string) => fetchColumnVisibility(key),
          setItem: (key: string, newValue: Record<string, boolean>) =>
            updateColumnVisibility(key, newValue),
          removeItem: () => void 0,
        },
        {
          getOnInit: true,
        }
      ),
    [tableId]
  );
  return useAtom(columnVisibilityAtom);
}
