import { Add16, SubtractAlt16 } from '@carbon/icons-react';
import searchAtomPair from 'atoms/search-atom';
import {
  Button,
  ComboBox,
  InlineNotification,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { Link } from 'components/link';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { debounce } from 'lodash';
import { useMemo, useReducer, useState } from 'react';
import { EntityResponse } from 'types/api-responses';

import style from './aliases.module.scss';
import {
  AliasesAtom,
  aliasCreatedAtom,
  createAliasAtom,
  deleteAliasAtom,
  useAliasedAtom,
} from './atoms';
import { NoResults } from './no-results';

const Aliases = ({ entity }: { entity: EntityResponse }) => {
  const [openModal, toggleOpenModal] = useReducer((s) => !s, false);
  const aliasCreated = useAtomValue(aliasCreatedAtom);
  const resetAliasCreated = useResetAtom(aliasCreatedAtom);
  const aliasesAtom = useAliasedAtom(entity.resources.aliasedEntities.href);
  const getAliases = useSetAtom(aliasesAtom);

  return (
    <FormSection title="Aliases">
      <AliasedEntitiesTable
        entity={entity}
        aliasesAtom={aliasesAtom}
        getAliases={getAliases}
      />
      {aliasCreated && (
        <InlineNotification lowContrast kind="success" title="Alias created!" />
      )}
      <Button
        renderIcon={Add16}
        onClick={() => {
          toggleOpenModal();
          resetAliasCreated();
        }}
      >
        Add Alias
      </Button>
      <AddAliasModal
        open={openModal}
        onClose={() => toggleOpenModal()}
        entity={entity}
        getAliases={getAliases}
      />
    </FormSection>
  );
};

const AliasedEntitiesTable = ({
  entity,
  aliasesAtom,
  getAliases,
}: {
  entity: EntityResponse;
  aliasesAtom: AliasesAtom;
  getAliases: () => void;
}) => {
  const { loading, data } = useAtomValue(aliasesAtom);
  if (!loading && !data) {
    return <NoResults />;
  }

  if (data && 'error' in data)
    return <InlineNotification kind="error" title={data.error} lowContrast />;

  return (
    <FormRow>
      <TableContainer>
        <Table aria-label="Aliases">
          <TableHead>
            <TableRow>
              <TableHeader>ID</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Type</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <SkeletonTableRows rows={5} columns={4} />
            </TableBody>
          ) : (
            <TableBody>
              {data?.map((el) => {
                const type = el.canonical ? 'Primary' : 'Alias';
                const isCurrentEntity = el.entity.id === entity?.id;
                return (
                  <TableRow key={el.entity.id}>
                    <TableCell>
                      {el.entity.id}{' '}
                      {el.entity.id === entity?.id && '(current entity)'}
                    </TableCell>
                    <TableCell>{el.entity.name}</TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell>
                      {!isCurrentEntity && (
                        <div className={style.deleteAliasRow}>
                          <Link to={el.entity.href}>Details</Link>
                          {el.resources.alias && (
                            <DeleteAliasButton
                              url={el.resources.alias.href}
                              entity={entity}
                              getAliases={getAliases}
                            />
                          )}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </FormRow>
  );
};

const AddAliasModal = ({
  onClose,
  open,
  entity,
  getAliases,
}: {
  onClose: () => void;
  open: boolean;
  entity: EntityResponse;
  getAliases: () => void;
}) => {
  const [error, setError] = useState<string | undefined>();
  const { searchAtom, resetSearchDataAtom } = useMemo(
    () => searchAtomPair<EntityResponse>('entities'),
    []
  );
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const entityData = entity;
  const createAlias = useSetAtom(createAliasAtom);
  const [selectedEntity, setSelectedEntity] = useState<
    EntityResponse | undefined
  >();
  const setAliasCreated = useSetAtom(aliasCreatedAtom);
  const [searchResults, searchPayload] = useAtom(searchAtom);
  const debouncedSearchPayload = useMemo(
    () => debounce(searchPayload, 400),
    [searchPayload]
  );

  return (
    <Modal
      modalHeading="Add Alias"
      primaryButtonText="Save"
      open={open}
      onRequestSubmit={() => {
        if (!entityData || !selectedEntity) return;
        createAlias({
          url: entityData.resources.fromAliases.href,
          entityId: selectedEntity.id,
          onSuccess: () => {
            getAliases();
            setSelectedEntity(undefined);
            setAliasCreated(true);
            onClose();
          },
          onError: (response) => {
            if (!response) return;
            setError(response.errors.to);
          },
        });
      }}
      secondaryButtonText="Cancel"
      onRequestClose={() => {
        onClose();
        setSelectedEntity(undefined);
      }}
      className={style.allowOverflow}
    >
      <span>
        The alias with the lowest ID will be displayed as the Primary entity in
        title chain calculation outputs.
      </span>
      <FormSection>
        <FormRow>
          <ComboBox
            id="aliased-entity"
            titleText={'Alias'}
            items={searchResults?.data?.results || []}
            placeholder=""
            itemToString={(data) => {
              return data ? `${data.name}, ${data.id}` : '';
            }}
            selectedItem={selectedEntity || null}
            onChange={({ selectedItem }) => {
              selectedItem && setSelectedEntity(selectedItem);
            }}
            onInputChange={(text) => {
              setError(undefined);
              resetSearchData(undefined);
              debouncedSearchPayload((current) => ({
                ...current,
                text,
                pageSize: 10,
              }));
            }}
            light
            invalid={!!error}
            invalidText={error}
          />
        </FormRow>
      </FormSection>
    </Modal>
  );
};

const DeleteAliasButton = ({
  url,
  entity,
  getAliases,
}: {
  url: string;
  entity: EntityResponse;
  getAliases: () => void;
}) => {
  const [openModal, toggleOpenModal] = useReducer((s) => !s, false);
  const deleteAlias = useSetAtom(deleteAliasAtom);
  const entityData = entity;
  const resetAliasCreated = useResetAtom(aliasCreatedAtom);
  if (!entityData) return null;
  return (
    <>
      <Button
        kind="danger--ghost"
        renderIcon={SubtractAlt16}
        iconDescription="Remove"
        tooltipPosition="left"
        hasIconOnly
        onClick={() => {
          toggleOpenModal();
          resetAliasCreated();
        }}
      />
      <Modal
        open={openModal}
        modalHeading="Are you sure you want to delete this?"
        danger
        primaryButtonText="Yes, delete"
        secondaryButtonText="No, cancel"
        onRequestSubmit={() => {
          deleteAlias({
            url,
            onSuccess: () => {
              getAliases();
              toggleOpenModal();
            },
            onError: () => {},
          });
        }}
        onRequestClose={() => toggleOpenModal()}
      >
        It will be removed immediately. You can’t undo this action.
      </Modal>
    </>
  );
};

export { Aliases };
