import { DirectUpload } from '@rails/activestorage';
import { createMutationAtom } from 'atoms/create-resource-atom';
import { getResources } from 'atoms/root';
import { atom } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import request from 'lib/request';
import { useMemo } from 'react';
import {
  WellFeaturesResponse,
  WellFormationResponse,
  WellProductionForecastResponse,
  WellProductionRecordsResponse,
  WellsResponse,
  WellSupportingFileResponse,
} from 'types/api-responses';
import { SupportingFile } from './types';

const MAX_FILE_SIZE = 500 * 1024 * 1024;

const useWellAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellsResponse>(href), [href]);

const useProductionForecastAtom = (href: string) =>
  useMemo(
    () => createResourceAtom<WellProductionForecastResponse>(href),
    [href]
  );

const useProductionRecordAtom = (href: string) =>
  useMemo(
    () => createResourceAtom<WellProductionRecordsResponse>(href),
    [href]
  );

const useWellFormationAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellFormationResponse>(href), [href]);

const useWellFeaturesAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellFeaturesResponse>(href), [href]);

const useSupportingFilesAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellSupportingFileResponse[]>(href), [href]);

const fileBaseAtom = atom<SupportingFile>({
  status: 'edit',
  name: undefined,
  file: undefined,
  invalid: false,
});
const fileAtom = atom<SupportingFile, [File | undefined], void>(
  (get) => get(fileBaseAtom),
  (get, set, file) => {
    if (!file) {
      set(fileBaseAtom, { status: 'edit' });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      set(fileBaseAtom, { status: 'edit', invalid: true });
      return;
    }

    const resources = getResources(get);

    const url = resources?.directUploads.href;
    if (!url) return;
    const directUpload = new DirectUpload(file, url);
    set(fileBaseAtom, { status: 'edit' });
    directUpload.create((error, blob) => {
      if (error) {
        set(fileBaseAtom, { status: 'edit' });
      } else {
        set(fileBaseAtom, {
          status: 'complete',
          file: blob.signed_id,
          name: file.name,
          invalid: false,
        });
      }
    });
  }
);

const useDeleteFileAtom = (getSupportingDocs: () => Promise<void>) =>
  useMemo(
    () =>
      atom<null, [string], void>(null, async (get, set, url) => {
        await request.deleteReq(url, undefined, new AbortController());
        getSupportingDocs();
      }),
    [getSupportingDocs]
  );

const createSupportingFileAtom = createMutationAtom<
  null,
  'wellSupportingFile'
>();

export {
  createSupportingFileAtom,
  fileAtom,
  useDeleteFileAtom,
  useProductionForecastAtom,
  useProductionRecordAtom,
  useSupportingFilesAtom,
  useWellAtom,
  useWellFeaturesAtom,
  useWellFormationAtom,
};
