import { datadogRum } from '@datadog/browser-rum';
import type { DatadogWindow } from 'types/window';

declare let window: DatadogWindow;

if (window.CONFIG.datadog) {
  datadogRum.init({
    ...window.CONFIG.datadog,
    service: 'insights',
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
