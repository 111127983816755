import { Search16, Close16 } from '@carbon/icons-react';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import { Button } from 'carbon-components-react';
import classNames from 'classnames';

import style from './debounced-input.module.scss';

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  className,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  className?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const debouncedClassName = classNames(style.searchField, className);

  return (
    <div className={debouncedClassName}>
      <Search16 className={style.searchIcon} />
      <input
        {...props}
        value={value}
        className={style.searchInput}
        onChange={(e) => setValue(e.target.value)}
      />
      {value && (
        <Button
          size="sm"
          kind="ghost"
          onClick={() => setValue('')}
          className={style.clearBtn}
          aria-label="Clear search input"
        >
          <Close16 />
        </Button>
      )}
    </div>
  );
};

export { DebouncedInput };
