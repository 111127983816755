import { atom } from 'jotai';
import request from 'lib/request';
import { TitleChainCalculatorYamlResponse } from 'types/api-responses';

type TitleChainAtom =
  | TitleChainCalculatorYamlResponse
  | { error: string }
  | undefined;

const runTitleAtomBase = atom<TitleChainAtom>(undefined);

const runTitleAtom = atom<TitleChainAtom, [string], void>(
  (get) => get(runTitleAtomBase),
  async (get, set, yaml: string) => {
    const post = await request.post<TitleChainCalculatorYamlResponse, unknown>(
      '/title_chain_calculator',
      { input: yaml },
      new AbortController()
    );

    if (post.type === 'success') {
      set(runTitleAtomBase, post.data);
      return;
    }

    set(runTitleAtomBase, { error: 'Error running title' });
  }
);

export { runTitleAtom };
