import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from 'carbon-components-react';
import { sortBy } from 'lodash';
import style from '../page.module.scss';
import { OutputPartyLease } from 'types/api-responses';

export interface EncumbrancesProps {
  encumbrances: OutputPartyLease;
  interestHeader: string;
}

export function Encumbrances({
  encumbrances,
  interestHeader,
  ...rest
}: EncumbrancesProps) {
  const rows = Object.entries(encumbrances).flatMap(
    ([partyName, encumbrances]) =>
      Object.entries(encumbrances).map(([leaseName, percentage]) => [
        partyName,
        leaseName,
        percentage,
      ])
  );

  return rows.length ? (
    <TableContainer className={style.table} {...rest}>
      <Table size="xs" useStaticWidth={true}>
        <TableHead>
          <TableRow>
            <TableHeader>Party</TableHeader>
            <TableHeader>Lease</TableHeader>
            <TableHeader>{interestHeader}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy(rows).map(([partyName, leaseName, percentage]) => (
            <TableRow key={[partyName, leaseName].join(', ')}>
              <TableCell>{partyName}</TableCell>
              <TableCell>{leaseName}</TableCell>
              <TableCell>{percentage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
