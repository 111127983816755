import {
  ArrowsVertical16,
  CaretDown16,
  CaretRight16,
  Pin16,
  PinFilled16,
  SortAscending16,
  SortDescending16,
} from '@carbon/icons-react';
import { useSortable } from '@dnd-kit/sortable';
import { flexRender } from '@tanstack/react-table';
import { Button, TooltipDefinition } from 'carbon-components-react';
import classNames from 'classnames';
import { DraggableHeaderIcon } from 'components/draggable-icon/draggable-header-icon';
import { ColumnActions } from 'components/table/column-actions';
import style from './draggable-table-components.module.scss';
import {
  DragCellProps,
  DragHeaderProps,
  draggingStyle,
  getPinningStyles,
} from './utils';

const DragHeader = <TData, TValue>({
  header,
  hoveredColumnIdRef,
}: DragHeaderProps<TData, TValue>) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });
  return (
    <th
      ref={setNodeRef}
      key={header.id}
      colSpan={header.colSpan}
      className={classNames(
        header.column.id === hoveredColumnIdRef.current
          ? style.targetBorderHeader
          : null,
        isDragging ? style.draggingHeader : null
      )}
      style={{
        ...getPinningStyles(header.column),
        ...(isDragging ? draggingStyle(transform) : {}),
      }}
    >
      {header.isPlaceholder ? null : (
        <div>
          <div
            className={header.column.getCanSort() ? style.labelSortPointer : ''}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </div>
          <div className={style.headerActions}>
            <div
              onClick={header.column.getToggleSortingHandler()}
              className={header.column.getCanSort() ? style.sortIcon : ''}
            >
              <TooltipDefinition
                className={style.toolTip}
                direction="top"
                align="center"
                tooltipText="Sort"
              >
                {{
                  asc: <SortAscending16 />,
                  desc: <SortDescending16 />,
                }[header.column.getIsSorted() as string] ?? (
                  <ArrowsVertical16 />
                )}
              </TooltipDefinition>
            </div>
            <DraggableHeaderIcon
              attributes={attributes}
              listeners={listeners}
            />
            {header.column.getIsPinned() !== 'left' ? (
              <Button
                hasIconOnly
                kind="ghost"
                size="sm"
                iconDescription="Pin Column"
                tooltipAlignment="center"
                tooltipPosition="top"
                renderIcon={Pin16}
                onClick={() => {
                  header.column.pin('left');
                }}
              />
            ) : null}
            {header.column.getIsPinned() ? (
              <Button
                hasIconOnly
                kind="ghost"
                size="sm"
                iconDescription="Unpin Column"
                tooltipAlignment="center"
                tooltipPosition="top"
                renderIcon={PinFilled16}
                onClick={() => {
                  header.column.pin(false);
                }}
              />
            ) : null}
          </div>
          <ColumnActions
            handleResize={header.getResizeHandler()}
          ></ColumnActions>
        </div>
      )}
    </th>
  );
};

const DragCell = <TData, TValue>({
  cell,
  row,
  hoveredColumnIdRef,
}: DragCellProps<TData, TValue>) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });
  return (
    <td
      ref={setNodeRef}
      key={cell.id}
      className={classNames(
        cell.column.id === hoveredColumnIdRef.current
          ? style.targetBorderColumn
          : null,
        isDragging ? style.draggingColumn : null
      )}
      {...{
        style: {
          ...getPinningStyles(cell.column),
          ...(isDragging ? draggingStyle(transform) : {}),
          background: cell.getIsGrouped()
            ? '#defbe6'
            : cell.getIsAggregated()
            ? '#fcf4d6'
            : cell.getIsPlaceholder()
            ? '#f2f4f8'
            : '#ffffff',
        },
      }}
    >
      {cell.getIsGrouped() ? (
        // If it's a grouped cell, add an expander and row count
        <>
          <Button
            size="sm"
            kind="ghost"
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: {
                cursor: row.getCanExpand() ? 'pointer' : 'normal',
              },
            }}
          >
            {row.getIsExpanded() ? <CaretDown16 /> : <CaretRight16 />}
            {flexRender(cell.column.columnDef.cell, cell.getContext())}(
            {row.subRows.length})
          </Button>
        </>
      ) : cell.getIsAggregated() ? (
        // If the cell is aggregated, use the Aggregated
        // renderer for cell
        flexRender(
          cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
          cell.getContext()
        )
      ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
        // Otherwise, just render the regular cell
        flexRender(cell.column.columnDef.cell, cell.getContext())
      )}
    </td>
  );
};

export { DragCell, DragHeader };
