import { Component, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';

interface Props {
  children: ReactNode;
  fallback: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    datadogRum.addError(error);
    if (window.Rollbar) {
      window.Rollbar.error(error);
    }
  }

  render() {
    const { fallback, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback || <h1>Something went wrong.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
