import { SkeletonText } from 'carbon-components-react';
import classNames from 'classnames';
import { Date } from 'components/date';
import { ImportedTag } from 'components/imported-tag';
import { padStart } from 'lodash';
import { NavLink } from 'react-router-dom';
import { DocumentInterpretationResponse } from 'types/api-responses';

import style from './interpretation-card.module.scss';
import { getReferrerAsSearchParam } from '../common/utils';

const LocationReferences = ({
  interpretation,
}: {
  interpretation: DocumentInterpretationResponse;
}) => {
  if (interpretation.locationReferences?.length) {
    return (
      <>
        {interpretation.locationReferences
          .map(
            (locationReference) =>
              locationReference.location.referenceLocation.name
          )
          .join(' · ')}
      </>
    );
  } else {
    return <>(No areas)</>;
  }
};

interface Props {
  interpretation: DocumentInterpretationResponse;
  index: number;
  searchParams: string;
}

const InterpretationCard = ({ index, interpretation, searchParams }: Props) => {
  const referrer = getReferrerAsSearchParam(searchParams);

  return (
    <NavLink
      exact
      to={referrer ? `${interpretation.href}${referrer}` : interpretation.href}
      className={classNames(
        style.summaryCard,
        'bx--tile',
        'bx--tile--clickable'
      )}
      activeClassName={style.selected}
    >
      <div className={style.summaryHeader}>
        <div className={style.summaryWrap}>
          <div className={style.index}>
            {padStart(String(index + 1), 2, '0')}
          </div>
          <div className={classNames(style.areas, style.truncate)}>
            <LocationReferences interpretation={interpretation} />
          </div>
        </div>

        <Date
          className={style.date}
          date={interpretation.lastSavedAt}
          format="ll"
        />
      </div>

      <div className={style.transactionDateContainer}>
        <div className={style.transactionDatewrap}>
          <p className={style.dateText}>Transaction Date:</p>
          <Date
            className={style.dateTransaction}
            date={interpretation.interpretationDate}
            format="L"
          />{' '}
          {`(${interpretation.interpretationDateSource})`}
        </div>
        <ImportedTag value={interpretation.imported} />
      </div>
    </NavLink>
  );
};

interface EmptyInterpretationCardProps {
  index: number;
}
const EmptyInterpretationCard = (props: EmptyInterpretationCardProps) => {
  return (
    <div
      className={classNames(
        style.summaryCard,
        style.selected,
        'bx--tile',
        'bx--tile--clickable'
      )}
    >
      <div className={style.summaryHeader}>
        <div className={style.index}>
          {padStart(String(props.index + 1), 2, '0')}
        </div>
        <div className={style.content}>
          <div className={classNames(style.areas, style.truncate)}>
            (No areas)
          </div>
        </div>
      </div>
    </div>
  );
};

const InterpretationCardSkeleton = () => {
  return (
    <div className={style.interpretationHolder}>
      <div>
        <SkeletonText heading width="75%" />
        <SkeletonText width="50%" />
      </div>
      <SkeletonText width="10%" />
    </div>
  );
};

export {
  InterpretationCard,
  InterpretationCardSkeleton,
  EmptyInterpretationCard,
};
