import { CenteredMap, PolygonArea } from 'components/map';
import { MapUserConfigAtom } from 'components/map/types';
import { useReferenceLocationWithGeometryAtom } from './atoms';

import style from './workspace-map.module.scss';
import { useAtomValue } from 'jotai';
import { InlineLoading, InlineNotification } from 'carbon-components-react';
import { ReactNode } from 'react';
import {
  LocationResponse,
  ReferenceLocationResponse,
} from 'types/api-responses';
import { QuarteredPolygon } from 'components/map/quartered-polygon';
import { green } from '@carbon/colors';

const WorkspaceMap = ({
  referenceLocationHref,
  mapConfigAtom,
  locations,
  children,
}: {
  referenceLocationHref: string;
  mapConfigAtom: MapUserConfigAtom;
  locations?: LocationResponse[];
  children?: ({
    referenceLocation,
  }: {
    referenceLocation: ReferenceLocationResponse;
  }) => ReactNode;
}) => {
  const referenceLocationAtom = useReferenceLocationWithGeometryAtom(
    referenceLocationHref
  );
  const referenceLocation = useAtomValue(referenceLocationAtom);

  if (referenceLocation.data && 'error' in referenceLocation.data)
    return (
      <InlineNotification
        kind="error"
        title={referenceLocation.data.error}
        lowContrast
      />
    );

  if (referenceLocation.loading || !referenceLocation.data)
    return <InlineLoading />;

  return (
    <>
      <div className={style.mapContainer}>
        {referenceLocation && referenceLocation.data.geometry && (
          <CenteredMap
            geometry={referenceLocation.data.geometry}
            className={style.map}
            mapConfigAtom={mapConfigAtom}
          >
            <PolygonArea
              id={referenceLocation.data.id}
              geometry={referenceLocation.data.geometry}
            />
            {locations &&
              locations.map((location, i) => (
                <QuarteredPolygon
                  id={`${location.id}-${i}`}
                  key={i}
                  location={location.referenceLocation}
                  quarterCalls={location.quarterCalls}
                  color={green[70]}
                />
              ))}
          </CenteredMap>
        )}
      </div>
      {children
        ? children({ referenceLocation: referenceLocation.data })
        : null}
    </>
  );
};

export { WorkspaceMap };
