import {
  logoutResourcesAtom,
  rootResourceAtom,
  sessionResourceAtom,
} from 'atoms/root';
import { atom } from 'jotai';
import request from 'lib/request';

const showSideNavAtom = atom(false);
const showUserMenuAtom = atom(false);

const logoutAtom = atom(
  () => null,
  async (get, set) => {
    const session = get(sessionResourceAtom);
    if (session) {
      await request.deleteReq<null, null>(
        session.href,
        null,
        new AbortController()
      );
      set(rootResourceAtom);
    }
  }
);

const oauthAtomBase = atom<boolean>(false);
const oauthAtom = atom<boolean, [HTMLFormElement], void>(
  (get) => get(oauthAtomBase),
  async (get, set, form) => {
    const resources = get(logoutResourcesAtom);
    if (!resources) return;

    set(oauthAtomBase, true);
    const response = await request.post<{ oauthLoginHref: string }, null>(
      resources.sessions.oauth.href,
      null,
      new AbortController()
    );

    if (response.type === 'success') {
      form.action = response.data.oauthLoginHref;
      form.submit();
    }

    set(oauthAtomBase, false);
  }
);

const basicAtomBase = atom<{ loading: boolean; error?: string }>({
  loading: false,
});
const basicAuthAtom = atom<
  { loading: boolean; error?: string },
  [{ email: string; password: string }],
  void
>(
  (get) => get(basicAtomBase),
  async (get, set, payload) => {
    const resources = get(logoutResourcesAtom);
    if (!resources) return;

    set(basicAtomBase, { loading: true });
    const response = await request.post<
      { email: string; password: string },
      { error: string }
    >(resources.sessions.basic.href, payload, new AbortController(), () => {
      set(basicAtomBase, { loading: false, error: 'Unable to authenticate' });
    });

    if (response.type === 'success') {
      set(rootResourceAtom);
      set(basicAtomBase, { loading: false });
      return;
    }

    if (response.type === 'error') {
      set(basicAtomBase, { loading: false, error: response.data?.error });
      return;
    }
  }
);

export {
  basicAuthAtom,
  logoutAtom,
  oauthAtom,
  showSideNavAtom,
  showUserMenuAtom,
};
