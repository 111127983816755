import { Column, ComboBox, Grid, Row } from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';
import { DeleteRowButton } from '../delete-row-button';
import { WellReferenceSubformAtoms } from './utils';
import wellReferenceTypes from 'enums/well_reference_types.json';

type ReferenceType = keyof typeof wellReferenceTypes;

import style from './transaction.module.scss';
import { getError } from 'atoms/create-resource-atom';
import { WellReferenceSubform } from './types';

interface Props {
  atoms: PrimitiveAtom<WellReferenceSubformAtoms>;
  trnIdx: number;
  atomIdx: number;
  trxRowDisabled: boolean;
  onDelete?: (atomIdx: number) => void;
}

const WellReferenceFormRow = ({
  trnIdx,
  atomIdx,
  onDelete,
  atoms,
  trxRowDisabled,
}: Props) => {
  const rowAtoms = useAtomValue(atoms);
  const [fields, setField] = useAtom(rowAtoms.row.formAtom);
  const status = useAtomValue(rowAtoms.status);
  const disabled = trxRowDisabled || status === 'trash';

  if (!fields.well.value) {
    return null;
  }

  const { leaseName, wellNumber, uwi } = fields.well.value;
  const wellName = `${leaseName} #${wellNumber}`;

  return (
    <FormRow toTrash={disabled}>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column sm={1} md={3} lg={5} className={style.centerLink}>
            <Link target="_blank" to={fields.well.value.href}>
              {uwi} &middot; {wellName}
            </Link>
          </Column>
          <Column sm={2} md={3} lg={5}>
            <ComboBox
              light
              titleText="Wellbore"
              placeholder=""
              onChange={({ selectedItem }) => {
                const key = Object.keys(wellReferenceTypes).find(
                  (key) =>
                    wellReferenceTypes[key as ReferenceType] === selectedItem
                );
                setField({ field: 'referenceType', value: key || '' });
              }}
              id={`${trnIdx}-${atomIdx}-reference-type`}
              items={Object.values(wellReferenceTypes)}
              type="default"
              disabled={disabled}
              selectedItem={
                fields.referenceType.value
                  ? wellReferenceTypes[
                      fields.referenceType.value as ReferenceType
                    ]
                  : null
              }
              invalid={
                !!getError<WellReferenceSubform>(fields, 'referenceType')
              }
              invalidText={getError<WellReferenceSubform>(
                fields,
                'referenceType'
              )}
            />
          </Column>
          <Column sm={1} md={2} lg={2} className={style.centerLink}>
            <DeleteRowButton
              statusAtom={rowAtoms.status}
              atomIdx={atomIdx}
              onDelete={onDelete}
              disabled={disabled}
              adjustForLabel={true}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

export { WellReferenceFormRow };
