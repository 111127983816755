import {
  Grid,
  Row,
  SkeletonText,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import style from './documents-search-skeleton.module.scss';

export function DocumentSearchSkeleton({
  elementCount,
}: {
  elementCount: number;
}) {
  return (
    <Grid condensed fullWidth className={style.skeletonRunsheetTable}>
      {[...Array(elementCount).keys()].map((el) => (
        <Row key={el} className={style.skeletonCardGrid}>
          <div className={style.skeletonCardTitleArea}>
            <SkeletonPlaceholder className={style.skeletonIcon} />
          </div>
          <div className={style.skeletonCardContent}>
            <div className={style.skeletonRow}>
              <SkeletonText className={style.skeletonDesc} />
              <SkeletonText heading className={style.skeletonTitle} />
            </div>
            <div className={style.skeletonCardContentParty}>
              <div className={style.skeletonSubRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
              <div className={style.skeletonSubRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
            </div>
            <div className={style.skeletonCardContentParty}>
              <div className={style.skeletonSubRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
              <div className={style.skeletonSubRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
            </div>
          </div>
        </Row>
      ))}
    </Grid>
  );
}
