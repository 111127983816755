import { DocumentsSearch } from 'components/documents-search';
import { PageHeader } from 'components/page-header';
import { SearchTabs } from 'components/search-tabs';

const Header = () => {
  return (
    <PageHeader breadcrumbs={[]} title={'Search'} actions={null}>
      <SearchTabs />
    </PageHeader>
  );
};

const DocumentSearchPage = () => {
  return (
    <>
      <Header />
      <DocumentsSearch noGutter showTips />
    </>
  );
};

export { DocumentSearchPage };
