import moment from 'moment';
import { HTMLAttributes, ReactNode, TimeHTMLAttributes } from 'react';

type DateProps = (
  | TimeHTMLAttributes<HTMLTimeElement>
  | HTMLAttributes<HTMLSpanElement>
) & {
  date?: string | null;
  format?: string;
  fallback?: ReactNode;
  showTime?: boolean;
};

export const Date = ({
  date,
  format = 'l',
  showTime = false,
  fallback,
  ...rest
}: DateProps) => {
  const momentDate = date ? moment(date) : undefined;
  return momentDate ? (
    <time
      dateTime={momentDate.format(
        showTime ? moment.HTML5_FMT.DATETIME_LOCAL : moment.HTML5_FMT.DATE
      )}
      {...rest}
    >
      {momentDate.format(format)}
    </time>
  ) : (
    <span {...rest}>{fallback}</span>
  );
};
