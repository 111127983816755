import {
  ContentSwitcher,
  DataTableSkeleton,
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Switch as CarbonSwitch,
} from 'carbon-components-react';
import { FORMATTER } from 'lib/ui';
import { EvalCashflows, Sensitivity } from 'types/evaluation-api/responses';
import { SensitivityTableTypes } from 'pages/packages/show/evaluation-result/types';

import style from './sensitivities.module.scss';

const SensitivitiesTable = ({
  title,
  columnHeader,
  collection,
}: {
  title: string;
  columnHeader: string;
  collection: Sensitivity[];
}) => {
  if (!collection.length) {
    return (
      <InlineNotification kind="warning" title={`Empty ${title}`} lowContrast />
    );
  }

  const headers = Object.keys(collection[0]).filter(
    (key) => key !== 'identifier' && key !== 'isBaseCase'
  ) as (keyof Omit<Sensitivity, 'identifier' | 'isBaseCase'>)[];

  return (
    <TableContainer title={title} className={style.sensitivitiesTable}>
      <Table aria-label={title} size="sm">
        <TableHead>
          <TableRow>
            <TableHeader scope="col">{columnHeader}</TableHeader>
            {headers.map((header) => (
              <TableHeader key={header} scope="col">
                {'PV' + header.replace('npv', '')}
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {collection.map((cashflow, index) => (
            <TableRow
              key={index}
              className={cashflow.isBaseCase ? style.boldedRow : undefined}
            >
              <TableCell>{cashflow.identifier}</TableCell>
              {headers.map((header) => (
                <TableCell key={header}>
                  {FORMATTER.format(cashflow[header])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface SensitivitiesProps {
  cashflows?: EvalCashflows;
  hide?: SensitivityTableTypes[];
}

const Sensitivities = ({ cashflows, hide = [] }: SensitivitiesProps) => {
  if (!cashflows)
    return (
      <DataTableSkeleton
        showHeader={false}
        showToolbar={false}
        columnCount={10}
      />
    );

  return (
    <div>
      {hide.includes(SensitivityTableTypes.PriceDeckSensitivities) ? (
        <SensitivityHiddenBanner sensitivitiesTableName="Price Deck Sensitivities" />
      ) : (
        <SensitivitiesTable
          title={'Price Deck Sensitivities'}
          columnHeader={'Pricing'}
          collection={cashflows.priceDeckSensitivities}
        />
      )}
      {hide.includes(SensitivityTableTypes.EffectiveDateSensitivities) ? (
        <SensitivityHiddenBanner sensitivitiesTableName="Effective Date Sensitivities" />
      ) : (
        <SensitivitiesTable
          title={'Effective Date Sensitivities'}
          columnHeader={'Date'}
          collection={cashflows.effectiveDateSensitivities}
        />
      )}

      {hide.includes(SensitivityTableTypes.AverageWellsDrilledSensitivities) ? (
        <SensitivityHiddenBanner sensitivitiesTableName="Average Well Drilled Years Sensitivities" />
      ) : (
        <SensitivitiesTable
          title={'Average Well Drilled Years Sensitivities'}
          columnHeader={'Years'}
          collection={cashflows.averageWellsDrilledYearsSensitivities}
        />
      )}

      {hide.includes(SensitivityTableTypes.EurRiskSensitivities) ? (
        <SensitivityHiddenBanner sensitivitiesTableName="EUR Risk Sensitivities" />
      ) : (
        <SensitivitiesTable
          title={'EUR Risk Sensitivities'}
          columnHeader={'EUR Risk'}
          collection={cashflows.eurRiskSensitivities}
        />
      )}
    </div>
  );
};

const SensitivityHiddenBanner = ({
  sensitivitiesTableName,
}: {
  sensitivitiesTableName: string;
}) => {
  return (
    <InlineNotification
      kind="warning"
      title={`${sensitivitiesTableName} are hidden for alterations`}
      lowContrast
    />
  );
};

const CashflowOutputSwitch = ({
  output,
  setOutput,
  className,
}: {
  output?: 'royalty' | 'working_interest';
  setOutput: React.Dispatch<
    React.SetStateAction<'royalty' | 'working_interest'>
  >;
  className?: string;
}) => {
  return (
    <div>
      <ContentSwitcher
        className={className}
        selectedIndex={output === 'royalty' ? 0 : 1}
        onChange={(e) => {
          if (e.index === 0) {
            setOutput('royalty');
          } else {
            setOutput('working_interest');
          }
        }}
      >
        <CarbonSwitch text="Royalty" />
        <CarbonSwitch text="Working Interest" />
      </ContentSwitcher>
    </div>
  );
};

export { CashflowOutputSwitch, Sensitivities };
