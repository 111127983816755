import { Edit16 } from '@carbon/icons-react';
import {
  Button,
  SkeletonText,
  Tag,
  Tooltip,
  Grid,
  Row,
  Column,
  InlineNotification,
} from 'carbon-components-react';
import { DevelopmentAreaWizard } from 'components/development-area-wizard';
import { CenteredMap, PolygonArea } from 'components/map';
import { PageHeader } from 'components/page-header';
import { Section } from 'components/section';
import { useAtom, useAtomValue } from 'jotai';
import { useReducer } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AreaSummary } from './area-summary';
import { DevelopmentAreaResponse } from 'types/api-responses';
import { Evaluations } from './evaluations';
import { FormationNotAvailable, FormationsSummary } from './formations-summary';
import { Map } from './map';

import style from './page.module.scss';
import { devAreaIsInEvaluationDatabase } from './utils';
import { atomWithStorage } from 'jotai/utils';
import { getMapDefaultConfig } from 'components/map/atoms';
import {
  useDevelopmentAreaAtom,
  useDevelopmentAreaSlotsAtom,
  useEvaluationListAtom,
} from './atoms';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import { EventsPanel, EventsPanelOpenButton } from 'components/events-panel';

const mapConfigAtom = atomWithStorage(
  'insights-development-area-map-config',
  getMapDefaultConfig()
);

const PageContent = ({
  developmentArea,
}: {
  developmentArea: DevelopmentAreaResponse;
}) => {
  const evaluationListAtom = useEvaluationListAtom(
    developmentArea.resources.evaluations.href
  );
  const slotsAtom = useDevelopmentAreaSlotsAtom(
    developmentArea.resources.slots.href
  );
  const [evaluationList, getEvaluationList] = useAtom(evaluationListAtom);
  const slots = useAtomValue(slotsAtom);

  const isAreaAvailableInEvaluation =
    devAreaIsInEvaluationDatabase(developmentArea);

  const hasFormations = Boolean(developmentArea.formations.length);
  const evaluationListError =
    evaluationList.data && 'error' in evaluationList.data;
  const slotsHasError = slots.data && 'error' in slots.data;
  const slotsList =
    slots.data && 'error' in slots.data ? [] : !slots.data ? [] : slots.data;

  return (
    <>
      <Section>
        <Grid className="bx--no-gutter" fullWidth condensed>
          <Row className={style.mapRow}>
            <Column md={12} lg={10} className={style.colSlots}>
              {isAreaAvailableInEvaluation && hasFormations ? (
                <div className={style.formationsCol}>
                  <FormationsSummary
                    formations={developmentArea.formations}
                    slots={slotsList}
                  />
                </div>
              ) : (
                <FormationNotAvailable />
              )}
            </Column>
            <Column md={12} lg={6} className={style.colMap}>
              <div className={style.map}>
                {developmentArea.isImported ? (
                  <CenteredMap
                    geometry={developmentArea.geometry}
                    mapConfigAtom={mapConfigAtom}
                  >
                    <PolygonArea
                      id={'dev-area'}
                      geometry={developmentArea.geometry}
                    />
                  </CenteredMap>
                ) : (
                  <Map
                    mapConfigAtom={mapConfigAtom}
                    developmentArea={developmentArea}
                  />
                )}
              </div>
            </Column>
          </Row>
        </Grid>
      </Section>
      {isAreaAvailableInEvaluation &&
        (slotsHasError ? (
          <InlineNotification
            kind="error"
            title="Notification"
            lowContrast
            subtitle={`Error loading slot list`}
          />
        ) : (
          <Section header="Area summary">
            <AreaSummary slots={slotsList} developmentArea={developmentArea} />
          </Section>
        ))}

      {isAreaAvailableInEvaluation &&
        hasFormations &&
        (evaluationListError ? (
          <InlineNotification
            kind="error"
            title="Notification"
            lowContrast
            subtitle={`Error loading evaluations`}
          />
        ) : (
          <Section header="Evaluation">
            <Evaluations
              evaluationsHref={developmentArea.resources.evaluations.href}
              getEvaluations={getEvaluationList}
              hasFormations={hasFormations}
              evaluations={
                evaluationList.data && 'error' in evaluationList.data
                  ? undefined
                  : evaluationList.data
              }
              evaluationHref={developmentArea.resources.evaluations.href}
              defaultAssumptionsUrl={
                developmentArea.resources.defaultAssumptions.href
              }
              slots={slotsList}
            />
          </Section>
        ))}
    </>
  );
};

const Actions = ({
  developmentArea,
  getDevArea,
}: {
  developmentArea: DevelopmentAreaResponse;
  getDevArea: () => Promise<void>;
}) => {
  const [editIsOpen, toggleEditIsOpen] = useReducer((s) => !s, false);
  const [showTooltip, setShowTooltip] = useReducer((s) => !s, false);

  if (!developmentArea) return null;

  return developmentArea.isImported ? (
    <>
      <Tooltip
        open={showTooltip}
        showIcon={false}
        direction="top"
        triggerText={
          <Button
            renderIcon={Edit16}
            disabled
            size="sm"
            onPointerEnter={() => setShowTooltip()}
            onPointerLeave={() => setShowTooltip()}
          >
            <span>Edit Development Area</span>
          </Button>
        }
        focusTrap={false}
        onChange={() => {}}
      >
        Unable to edit imported Development Areas
      </Tooltip>
      <EventsPanelOpenButton />
    </>
  ) : (
    <>
      <DevelopmentAreaWizard
        developmentArea={developmentArea}
        isOpen={editIsOpen}
        onClose={() => toggleEditIsOpen()}
        onCreated={() => {
          toggleEditIsOpen();
          getDevArea();
        }}
      />
      <Button renderIcon={Edit16} onClick={() => toggleEditIsOpen()}>
        Edit Development Area
      </Button>
      <EventsPanelOpenButton />
    </>
  );
};

const Attributes = ({
  developmentArea,
}: {
  developmentArea: DevelopmentAreaResponse;
}) => {
  if (!developmentArea.basinName || !developmentArea.basinName) return null;

  return (
    <>
      <div className={style.basinList}>
        <p>{`${developmentArea.basinName} — ${developmentArea.tierName}`}</p>
      </div>
    </>
  );
};

const Header = ({
  developmentArea,
  getDevArea,
}: {
  developmentArea: DevelopmentAreaResponse | undefined;
  getDevArea: () => Promise<void>;
}) => {
  const { pathname } = useLocation();

  return (
    <PageHeader
      breadcrumbs={[
        ['/development_areas', 'Development areas'],
        [pathname, developmentArea?.name || <SkeletonText width="200px" />],
      ]}
      title={developmentArea?.name || <SkeletonText heading width="200px" />}
      aside={
        developmentArea?.isImported && (
          <Tag type="cyan" size="sm">
            Imported
          </Tag>
        )
      }
      attributes={
        developmentArea ? (
          <Attributes developmentArea={developmentArea} />
        ) : null
      }
      actions={
        developmentArea ? (
          <Actions developmentArea={developmentArea} getDevArea={getDevArea} />
        ) : null
      }
    />
  );
};

const EventsContent = ({
  developmentArea,
}: {
  developmentArea: DevelopmentAreaResponse;
}) => {
  return (
    <EventsPanel
      header={developmentArea.name}
      title="Development Area"
      helperText="Development Area context allows you to share information about a development area."
      eventsHref={developmentArea.resources.events.href}
      commentsHref={developmentArea.resources.comments.href}
    />
  );
};

const ShowDevelopmentAreaPage = () => {
  const location = useParams<{ id: string }>();
  const devAreaHref = `/development_areas/${location.id}`;
  const devAreaAtom = useDevelopmentAreaAtom(devAreaHref);

  const [developmentArea, getDevArea] = useAtom(devAreaAtom);

  if (developmentArea.data && 'error' in developmentArea.data)
    return (
      <InlineNotification
        kind="error"
        title={developmentArea.data.error}
        lowContrast
      />
    );

  return (
    <>
      <Header developmentArea={developmentArea.data} getDevArea={getDevArea} />
      {developmentArea.data && (
        <>
          <Helmet>
            <title>{pageTitle(developmentArea.data.name)}</title>
          </Helmet>
          <PageContent developmentArea={developmentArea.data} />
          <EventsContent developmentArea={developmentArea.data} />
        </>
      )}
    </>
  );
};

export { ShowDevelopmentAreaPage };
