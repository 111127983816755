import {
  Event16,
  CalendarTools16,
  WarningOther16,
  CheckmarkFilledWarning16,
  Archive16,
  CollapseAll16,
  ExpandAll16,
  DocumentAttachment16,
  CertificateCheck16,
  MapBoundary16,
  EventSchedule16,
  Reminder16,
  Events16,
  ChartCombo16,
  ChevronDown16,
  ChevronUp16,
  Intersect16,
  CalendarHeatMap16,
  Redo16,
} from '@carbon/icons-react';
import {
  TransactionType,
  DocumentTypeHeader,
  ReviewCount,
  RowDate,
  Details,
  Runsheet,
  TransactionStatusTag,
} from 'components/runsheet/common';
import { Facets } from './facets';
import {
  DocumentInterpretationResponse,
  TransactionResponse,
} from 'types/api-responses';
import ErrorToast from 'components/error-toast';
import {
  atom,
  PrimitiveAtom,
  useAtom,
  useAtomValue,
  useSetAtom,
  WritableAtom,
} from 'jotai';
import { debounce } from 'lodash';
import { useState, useMemo, useRef, Suspense, useReducer } from 'react';
import {
  createExpandableAtom,
  searchAtom,
  setToggleAtom,
  toggleAtoms,
} from './atoms';
import {
  Row,
  Search,
  Button,
  Link,
  Checkbox,
  InlineNotification,
} from 'carbon-components-react';
import { defaultPayload } from './utils';
import { atomWithReducer, atomWithStorage, useReducerAtom } from 'jotai/utils';
import style from './interpretations-search.module.scss';
import {
  areReferenceLocationBucketsEqual,
  useCalculateMapDimensions,
} from 'components/map/utils';
import {
  createAbortableLocationsAtom,
  getMapDefaultConfig,
} from 'components/map/atoms';
import { Map, PolygonArea } from 'components/map';
import { LocationAtom } from 'components/map/types';
import { InterpretationsModalCallback } from './interpretation-search-modal';
import classNames from 'classnames';
import plur from 'plur';
import { InterpretationStats } from 'components/interpretation-search/interpretation-stats';
import { OnPaginationChange } from 'components/runsheet/common/types';
import { InterpretationView } from 'components/interpretation-view/interpretation-view';
import { TOP_RUNSHEET_SECTIONS_HEIGHT_IN_PX } from 'lib/constants';
import { LinkWithHover } from 'components/hover-card-resource';

const TransactionListItem = ({
  transaction,
}: {
  transaction: TransactionResponse;
}) => {
  const [truncateGrantors, toggleTruncateGrantors] = useReducer(
    (s) => !s,
    true
  );
  const [truncateGrantees, toggleTruncateGrantees] = useReducer(
    (s) => !s,
    true
  );
  const shouldTruncateGrantors = transaction.grantors.length >= 4;
  const shouldTruncateGrantees = transaction.conveyances.length >= 4;

  return (
    <div key={transaction.id}>
      <div className={style.transactionCard}>
        <TransactionType row={transaction} />
        <strong> #{transaction.id}</strong>
        <TransactionStatusTag status={transaction.status} />
      </div>
      <div className={style.transactionPartiesContainer}>
        <div className={style.transactionParties}>
          <label className={style.partyLabel}>
            <Events16 className={style.titleIcon} />
            {plur('Grantor', transaction.grantors.length)}
          </label>
          <div
            className={
              truncateGrantors ? style.partyTruncated : style.partyNotTruncated
            }
          >
            {truncateGrantors ? (
              <PartyNames
                list={transaction.grantors.map((el) => ({
                  entityName: el.entity.name,
                  entityHref: el.entity.href,
                }))}
              />
            ) : (
              transaction.grantors.map((row) => {
                return (
                  <span key={row.id} className={style.detailsDesc}>
                    <LinkWithHover kind="entity" href={row.entity.href}>
                      <Link
                        href={row.entity.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.entity.name}
                      </Link>
                    </LinkWithHover>
                  </span>
                );
              })
            )}
          </div>
        </div>
        {shouldTruncateGrantors && (
          <div className={style.moreBtnArea}>
            <Link
              onClick={() => toggleTruncateGrantors()}
              className={style.viewMoreBtn}
              size="sm"
            >
              {truncateGrantors ? `View more grantors` : `View fewer grantors`}
            </Link>
          </div>
        )}
        <div className={style.transactionParties}>
          <label className={style.partyLabel}>
            <Events16 className={style.titleIcon} />
            {plur('Grantee', transaction.conveyances.length)}
          </label>
          <div
            className={
              truncateGrantees ? style.partyTruncated : style.partyNotTruncated
            }
          >
            {truncateGrantees ? (
              <PartyNames
                list={transaction.conveyances.map((el) => ({
                  entityName: el.entity.name,
                  entityHref: el.entity.href,
                }))}
              />
            ) : (
              transaction.conveyances.map((row) => {
                return (
                  <span key={row.id} className={style.detailsDesc}>
                    <LinkWithHover kind="entity" href={row.entity.href}>
                      <Link
                        href={row.entity.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.entity.name}
                      </Link>
                    </LinkWithHover>
                  </span>
                );
              })
            )}
          </div>
        </div>
        {shouldTruncateGrantees && (
          <div className={style.moreBtnArea}>
            <Link
              onClick={() => toggleTruncateGrantees()}
              className={style.viewMoreBtn}
              size="sm"
            >
              {truncateGrantees ? `View more grantees` : `View fewer grantees`}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const PartyNames = ({
  list,
}: {
  list: { entityName: string; entityHref: string }[];
}) => {
  const count = list.length > 1 ? `(${list.length})` : '';
  return (
    <>
      {count}{' '}
      {list.map((el, i) => (
        <span key={el.entityHref}>
          <LinkWithHover kind="entity" href={el.entityHref}>
            <Link
              href={el.entityHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {el.entityName}
            </Link>
          </LinkWithHover>
          {list.length - 1 > i && <span>, </span>}
        </span>
      ))}
    </>
  );
};

const Transactions = ({ row }: { row: DocumentInterpretationResponse }) => {
  return (
    <div className={style.interpretationTransactions}>
      {row?.transactions?.map((transaction) => (
        <TransactionListItem key={transaction.id} transaction={transaction} />
      ))}
    </div>
  );
};

export const InterpretationRow = ({
  rows,
  rowIndex,
  actions,
  editedRowsMapAtoms,
  expandedRowsAtom,
  titleWorkspace,
  referenceLocationHref,
  openRowInNewTab,
  titleWorkspaceHref,
  refsMap,
  highlightedRowState,
  lastCalculationHref,
  showEditInterpretationButton,
}: {
  rows: DocumentInterpretationResponse[];
  rowIndex: number;
  editedRowsMapAtoms: Record<number, PrimitiveAtom<boolean>>;
  actions?: InterpretationsModalCallback;
  expandedRowsAtom: WritableAtom<{ open: boolean }, [{ open: boolean }], void>;
  showEditInterpretationButton: boolean;
  titleWorkspace?: number;
  referenceLocationHref?: string;
  openRowInNewTab?: boolean;
  titleWorkspaceHref?: string;
  lastCalculationHref?: string;
  refsMap: Map<number, HTMLElement | null | undefined>;
  highlightedRowState: [
    (
      | {
          current: number;
          previous: number;
        }
      | undefined
    ),
    React.Dispatch<
      React.SetStateAction<
        | {
            current: number;
            previous: number;
          }
        | undefined
      >
    >
  ];
}) => {
  const row = rows[rowIndex];
  const allRowsExpanded = useAtomValue(expandedRowsAtom);
  const editedRowAtom = editedRowsMapAtoms[rowIndex];
  const [rowIsOutdated] = useAtom(editedRowAtom);

  const localExpandedAtom = useMemo(
    () => atomWithReducer(allRowsExpanded.open, (prev) => !prev),
    [allRowsExpanded]
  );

  const [highlightedRow, setHighlightedRow] = highlightedRowState;

  const [expanded, setExpanded] = useReducerAtom(localExpandedAtom, (v) => v);

  const className = classNames(
    style.cardGrid,
    highlightedRow?.current === row.id ? style.highlight : null
  );

  return (
    <Row key={row.id} className={className}>
      {rowIsOutdated && (
        <InlineNotification
          className={style.notificationOutdated}
          kind="warning"
          title="This row is outdated"
          lowContrast
        />
      )}
      <div
        className={style.wrapHighlight}
        ref={(el) => refsMap.set(row.id, el)}
      >
        <div className={style.cardTitleArea}>
          {highlightedRow?.current === row.id ? (
            <Link
              onClick={() => {
                const ref = refsMap.get(highlightedRow.previous);
                if (ref) {
                  const topPosition = ref.getBoundingClientRect().top;
                  const offsetPosition =
                    topPosition +
                    window.pageYOffset -
                    TOP_RUNSHEET_SECTIONS_HEIGHT_IN_PX;

                  window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                  });

                  ref.classList.add(style.highlight);

                  setHighlightedRow(undefined);

                  setTimeout(() => {
                    ref.classList.remove(style.highlight);
                  }, 2000);
                }
              }}
            >
              <Redo16 />
              Back
            </Link>
          ) : null}
          <DocumentTypeHeader document={row.document} />
          <h2 className={style.cardTitle}>
            <ReviewCount reviewAttributes={row.reviewAttributes}></ReviewCount>
            {openRowInNewTab ? (
              <a href={row.href} target="_blank" rel="noreferrer">
                {row.document.documentTitle}
              </a>
            ) : (
              <InterpretationView
                rows={rows}
                rowIndex={rowIndex}
                titleWorkspace={titleWorkspace}
                referenceLocationHref={referenceLocationHref}
                titleWorkspaceHref={titleWorkspaceHref}
                editedRowsMapAtoms={editedRowsMapAtoms}
                showEditInterpretationButton={showEditInterpretationButton}
                refsMap={refsMap}
              />
            )}
          </h2>

          <h4 className={style.transactionType}>Interpretation #{row.id}</h4>
          <span>
            {row.topLease ? (
              <div className={style.leaseOverlap}>
                <Intersect16 className={style.leaseOverlapIcon} />
                Top Lease
              </div>
            ) : null}
            {row.subjectLease ? (
              <div className={style.leaseOverlap}>
                <Intersect16 className={style.leaseOverlapIcon} />
                Subject to Top Lease
              </div>
            ) : null}
            {row.assumption ? (
              <div className={style.assumption}>
                <CheckmarkFilledWarning16 className={style.assumptionIcon} />
                Assumption
              </div>
            ) : null}
            {row.supporting.length ? (
              <div className={style.curativeRequirement}>
                <DocumentAttachment16
                  className={style.supportingDocumentIcon}
                />
                Supporting Document
              </div>
            ) : null}
            {row.hasOpenRequirements ? (
              <div className={style.curativeRequirement}>
                <WarningOther16 className={style.assumptionIcon} />
                Curative Requirements
              </div>
            ) : null}
          </span>
        </div>
        <div className={style.cardContent}>
          {row.interpretationDate && (
            <dl>
              <dt>
                <CalendarHeatMap16 className={style.titleIcon} />
                Transaction Date
              </dt>
              <dd>
                <RowDate item={row} attribute="interpretationDate" /> (
                {row.interpretationDateSource})
              </dd>
            </dl>
          )}
          {row.supersedingDate && (
            <dl>
              <dt>
                <EventSchedule16 className={style.titleIcon} />
                Superseding Date
              </dt>
              <dd>
                <RowDate item={row} attribute="supersedingDate" />
              </dd>
            </dl>
          )}
          {row.document.effectiveDate && (
            <dl>
              <dt>
                <Event16 className={style.titleIcon} />
                Effective Date
              </dt>
              <dd>
                <RowDate item={row.document} attribute="effectiveDate" />
              </dd>
            </dl>
          )}
          {row.document.instrumentDate && (
            <dl>
              <dt>
                <CalendarTools16 className={style.titleIcon} />
                Instrument Date
              </dt>
              <dd>
                <RowDate item={row.document} attribute="instrumentDate" />
              </dd>
            </dl>
          )}
          {row.document.fileDate && (
            <dl>
              <dt>
                <Archive16 className={style.titleIcon} />
                File Date
              </dt>
              <dd>
                <RowDate item={row.document} attribute="fileDate" />
              </dd>
            </dl>
          )}
          {row.document.recordedDate && (
            <dl>
              <dt>
                <CertificateCheck16 className={style.titleIcon} />
                Recorded Date
              </dt>
              <dd>
                <RowDate item={row.document} attribute="recordedDate" />
              </dd>
            </dl>
          )}

          {row.updatedAt && (
            <dl>
              <dt>
                <Reminder16 className={style.titleIcon} />
                Last-Updated
              </dt>
              <dd>
                <RowDate item={row} attribute="updatedAt" />
              </dd>
            </dl>
          )}
        </div>
        <Transactions row={row} />
        {actions?.(row)}
        {row.notes || row.locationReferences.length ? (
          <Button
            onClick={() => setExpanded(!expanded)}
            kind="ghost"
            size="sm"
            className={style.expandBtn}
          >
            {expanded ? <ChevronUp16 /> : <ChevronDown16 />}
          </Button>
        ) : null}
        {expanded ? (
          <Details
            row={row}
            titleWorkspace={titleWorkspace}
            titleWorkspaceHref={titleWorkspaceHref}
            referenceLocationHref={referenceLocationHref}
            setHighlightedRow={setHighlightedRow}
            refsMap={refsMap}
            lastCalculationHref={lastCalculationHref}
          />
        ) : null}
      </div>
    </Row>
  );
};

const Interpretations = ({
  actions,
  expandAllRowsAtom,
  openRowInNewTab,
}: Props & {
  expandAllRowsAtom: WritableAtom<{ open: boolean }, [{ open: boolean }], void>;
  openRowInNewTab?: boolean;
}) => {
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const onPagination: OnPaginationChange = (newSearch) => {
    setSearchPayload((current) => ({
      ...current,
      ...newSearch,
    }));
  };
  return (
    <Runsheet
      searchAtomValue={searchResults}
      actions={actions}
      onPaginationChange={onPagination}
      expandedRowsAtom={expandAllRowsAtom}
      openRowInNewTab={openRowInNewTab}
    />
  );
};

const ExpandButtons = ({
  expandedRowsAtom,
}: {
  expandedRowsAtom: WritableAtom<{ open: boolean }, [{ open: boolean }], void>;
}) => {
  const setAllRowsExpanded = useSetAtom(expandedRowsAtom);
  const setToggle = useSetAtom(setToggleAtom);

  return (
    <>
      <Button
        kind="ghost"
        hasIconOnly
        iconDescription="Expand all"
        size="md"
        onClick={() => {
          setAllRowsExpanded({ open: true });
        }}
        renderIcon={ExpandAll16}
        tooltipAlignment="center"
        tooltipPosition="bottom"
      />
      <Button
        kind="ghost"
        hasIconOnly
        iconDescription="Collapse all"
        size="md"
        onClick={() => {
          setAllRowsExpanded({ open: false });
        }}
        renderIcon={CollapseAll16}
        tooltipAlignment="center"
        tooltipPosition="bottom"
      />
      <Button
        kind="ghost"
        hasIconOnly
        iconDescription="Toggle Map"
        size="md"
        onClick={() => setToggle('map')}
        renderIcon={MapBoundary16}
        tooltipAlignment="center"
        tooltipPosition="bottom"
      />
      <Button
        kind="ghost"
        hasIconOnly
        iconDescription="Toggle Stats"
        size="md"
        onClick={() => setToggle('stats')}
        renderIcon={ChartCombo16}
        tooltipAlignment="center"
        tooltipPosition="bottom"
      />
    </>
  );
};

const mapConfigAtom = atomWithStorage(
  'insights-interpretation-search-map-config',
  getMapDefaultConfig()
);

const InterpretationsMap = () => {
  const searchResult = useAtomValue(searchAtom);

  const newReferenceLocationBuckets =
    searchResult?.data?.facets?.referenceLocation?.buckets || [];

  const [currentReferenceLocationBuckets, setCurrentReferenceLocationBuckets] =
    useState(newReferenceLocationBuckets);

  if (
    !areReferenceLocationBucketsEqual(
      currentReferenceLocationBuckets,
      newReferenceLocationBuckets
    )
  ) {
    setCurrentReferenceLocationBuckets(newReferenceLocationBuckets);
  }

  const locationsAtom = useMemo(
    () =>
      atom(() => {
        return createAbortableLocationsAtom(currentReferenceLocationBuckets);
      }),
    [currentReferenceLocationBuckets]
  );

  const locationCollectionAtom = useAtomValue(useAtomValue(locationsAtom));

  const containerRef = useRef(null);

  const { latitude, longitude, zoom } = useCalculateMapDimensions({
    points: searchResult?.data?.boundingBox?.points,
    containerRef,
  });

  const facet = searchResult?.data?.facets?.referenceLocation;
  const locations =
    facet?.buckets.map((referenceLocation) => ({
      id: referenceLocation.id,
      name: referenceLocation.name,
    })) || [];

  return (
    <div style={{ height: '40vh' }} ref={containerRef}>
      <Map
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        showControls
        showMapFilters
        geographies={locations}
        mapConfigAtom={mapConfigAtom}
      >
        {locationCollectionAtom.map((locationAtom) => (
          <Suspense fallback={null} key={`${locationAtom}`}>
            <AsyncPolygon locationAtom={locationAtom} />
          </Suspense>
        ))}
      </Map>
    </div>
  );
};

const AsyncPolygon = ({ locationAtom }: { locationAtom: LocationAtom }) => {
  const location = useAtomValue(locationAtom);
  if (!location || 'error' in location) return null;
  return <PolygonArea geometry={location.geometry} id={location.id} />;
};

interface Props {
  actions?: InterpretationsModalCallback;
  className?: string;
  openRowInNewTab?: boolean;
}

const InterpretationsSearcher = ({
  actions,
  className,
  openRowInNewTab,
}: Props) => {
  const toggledMap = useAtomValue(useAtomValue(toggleAtoms).map);
  const toggledStats = useAtomValue(useAtomValue(toggleAtoms).stats);
  const [searchDisplayText, setSearchDisplayText] = useState('');
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const [toggleFilters, setToggleFilters] = useState(false);
  const expandedRowsAtom = useMemo(() => createExpandableAtom(), []);

  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        setSearchPayload((current) => ({
          ...defaultPayload,
          sortDirection: current?.sortDirection || 'DESC',
          sortAttribute: current?.sortAttribute || 'updated_at',
          pageSize: current?.pageSize || 30,
          startDate: current?.startDate || '',
          endDate: current?.endDate || '',
          dateTarget: current?.dateTarget || 'none',
          text: value,
        }));
      }, 400),
    [setSearchPayload]
  );

  return (
    <>
      <div
        className={classNames([style.interpretationSearchContainer, className])}
      >
        <div className={style.gridTopRow}>
          <div className={style.searchWrapper}>
            <div className={style.searchBar}>
              <div className={style.searchInput}>
                <Search
                  data-modal-primary-focus
                  labelText="Search"
                  placeholder="Search"
                  size="lg"
                  autoFocus={true}
                  value={searchDisplayText}
                  onChange={({ target: { value } }) => {
                    setSearchDisplayText(value);
                    debouncedSetSearchPayload(value);
                  }}
                />
              </div>
              <div className={style.hideReviewed}>
                <Checkbox
                  id="hide-reviewed"
                  labelText="Hide Reviewed"
                  checked={searchResults.currentPayload?.hideReviewed ?? false}
                  onChange={(checked: boolean) => {
                    setSearchPayload((current) => ({
                      ...current,
                      page: 1,
                      hideReviewed: checked,
                    }));
                  }}
                />
              </div>
              <div className={style.expandAllButtons}>
                <ExpandButtons expandedRowsAtom={expandedRowsAtom} />
              </div>
              <div className={style.facetToggleContainer}>
                <Button
                  className={style.filtersDropdown}
                  kind="ghost"
                  hasIconOnly
                  iconDescription="Toggle Filters"
                  size="md"
                  onClick={() => setToggleFilters((current) => !current)}
                  renderIcon={toggleFilters ? ChevronUp16 : ChevronDown16}
                  tooltipAlignment="center"
                  tooltipPosition="bottom"
                />
              </div>
            </div>

            {toggleFilters && (
              <div className={style.facetContainer}>
                <Facets />
              </div>
            )}
          </div>
        </div>

        {searchResults?.data?.results.length && toggledMap ? (
          <InterpretationsMap />
        ) : null}
        {searchResults?.data?.results.length && toggledStats ? (
          <InterpretationStats />
        ) : null}
        <Interpretations
          actions={actions}
          expandAllRowsAtom={expandedRowsAtom}
          openRowInNewTab={openRowInNewTab}
        />
      </div>
      {searchResults?.error ? (
        <ErrorToast message={searchResults.error} />
      ) : null}
    </>
  );
};

const InterpretationsSearch = ({
  actions,
  className,
  openRowInNewTab,
}: Props) => {
  return (
    <InterpretationsSearcher
      actions={actions}
      className={className}
      openRowInNewTab={openRowInNewTab}
    />
  );
};

export { InterpretationsSearch };
