import { Add16 } from '@carbon/icons-react';
import { AtomField, AtomizedRowForm } from 'atoms/form-atoms';
import searchAtomPair from 'atoms/search-atom';
import {
  Tile,
  Button,
  Column,
  DataTable,
  DataTableCustomRenderProps,
  InlineLoading,
  InlineNotification,
  Link,
  Modal,
  Grid,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { TrashRowButton } from 'components/trash-row-button';
import { Atom, atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import { debounce } from 'lodash';
import { Slots } from 'pages/development-areas/show/evaluation-form/forms/slots';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DevelopmentAreaQueryResponse,
  DevelopmentAreaResponse,
} from 'types/api-responses';
import {
  EvalDefaultAssumptions,
  EvalSlotResponse,
} from 'types/evaluation-api/responses';
import { ScenariosForm } from './types';
import { developmentAreaRow } from './utils';
import { EmptyState } from 'components/empty-state';
import DevAreaSvg from 'images/srp-development-areas.svg';
import style from './dev-area-exception.module.scss';
import { createWellToExcludeElement } from 'utils/wells-to-exclude';
import classNames from 'classnames';

type ExceptionsForm = AtomizedRowForm<
  ScenariosForm['developmentAreasAttributes'][0]
>;

const headers = [
  {
    key: 'developmentAreaName',
    header: 'Development Area',
  },
  {
    key: 'wellsToExcludeCount',
    header: 'Wells To Exclude Count',
  },
  { key: 'actions', header: 'Actions' },
];

const newExceptionsFormAtom = atom<ExceptionsForm | undefined>(undefined);

const DevelopmentAreaExceptions = ({
  form,
}: {
  form: AtomField<ExceptionsForm[]>;
}) => {
  const [rows, setRows] = useAtom(form);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedExceptionsForm, setSelectedExceptionsForm] = useState<
    ExceptionsForm | undefined
  >(undefined);
  const [newExceptionsForm, setNewExceptionsForm] = useAtom(
    newExceptionsFormAtom
  );

  const onDelete = useCallback(
    (index: number) =>
      setRows((arr) => [...arr.slice(0, index), ...arr.slice(index + 1)]),
    [setRows]
  );

  const onAdd = useCallback(
    (exceptionsForm: ExceptionsForm) => {
      setRows((current) => [...current, exceptionsForm]);
    },
    [setRows]
  );

  const dataTableRowsAtom = useMemo(() => {
    return atom((get) => {
      return rows.value.map((el, i) => {
        return {
          id: String(i),
          developmentAreaName: get(el.developmentAreaName).value,
          wellsToExcludeCount: get(el.wellsToExclude).value.length,
        };
      });
    });
  }, [rows.value]);

  const developmentAreasWithExceptionsAtom = useMemo(() => {
    return atom((get) => {
      return get(form).value.map((el) => {
        return get(el.developmentAreaId).value;
      });
    });
  }, [form]);

  const dataTableRows = useAtomValue(dataTableRowsAtom);

  return (
    <Tile className={style.wrapperSlotSelection}>
      <FormSection>
        <h2 className={style.subTitle}>Current Exceptions</h2>
        <SlotSelectionModal
          isOpen={modalOpen}
          onSubmit={() => {
            if (newExceptionsForm) {
              onAdd(newExceptionsForm);
              setModalOpen(false);
              setNewExceptionsForm(undefined);
            } else {
              setModalOpen(false);
            }
          }}
          onClose={() => {
            setNewExceptionsForm(undefined);
            setModalOpen(false);
          }}
          selectedExceptionsForm={selectedExceptionsForm}
          developmentAreasWithExceptionsAtom={
            developmentAreasWithExceptionsAtom
          }
        />
        <FormRow>
          <Button
            renderIcon={Add16}
            kind={'tertiary'}
            size="md"
            onClick={() => {
              setSelectedExceptionsForm(undefined);
              setModalOpen(true);
            }}
          >
            Add Exceptions
          </Button>
        </FormRow>
        <DataTable
          isSortable
          rows={dataTableRows}
          headers={headers}
          size="sm"
          key={rows.value.length}
        >
          {({
            rows: tableRows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            onInputChange,
          }: DataTableCustomRenderProps) => (
            <TableContainer className={style.devAreaSlotsTable}>
              <TableToolbar>
                <TableToolbarContent>
                  <TableToolbarSearch onChange={onInputChange} />
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableHeader
                          {...getHeaderProps({ header })}
                          key={header.key}
                        >
                          {header.header}
                        </TableHeader>
                      );
                    })}
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row, rowIndex) => (
                    <TableRow {...getRowProps({ row })} key={rowIndex}>
                      {row.cells.map((cell, cellIndex) => {
                        const cellKey =
                          cell.id + '_' + rowIndex + '_' + cellIndex;
                        const str = cell.id;
                        const index = Number(str.replace(/\D/g, ''));
                        if (cellIndex === 0) {
                          return (
                            <TableCell>
                              <Link
                                onClick={() => {
                                  setSelectedExceptionsForm(rows.value[index]);
                                  setModalOpen(true);
                                }}
                              >
                                {cell.value}
                              </Link>
                            </TableCell>
                          );
                        }
                        if (cellIndex === 2) {
                          return (
                            <TableCell key={cellKey}>
                              <TrashRowButton
                                adjustForLabel={false}
                                index={index}
                                atom={rows.value[index]._rowStatus}
                                onDelete={onDelete}
                              />
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={cellKey}>{cell.value}</TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </FormSection>
    </Tile>
  );
};

const SlotSelectionModal = ({
  isOpen,
  onSubmit,
  onClose,
  selectedExceptionsForm,
  developmentAreasWithExceptionsAtom,
}: {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  selectedExceptionsForm?: ExceptionsForm;

  developmentAreasWithExceptionsAtom: Atom<number[]>;
}) => {
  // We want to recreate the wellsToExclude array to set all slots
  const setAllSlotsAsDirtyAtom = useMemo(() => {
    return atom<null, [ExceptionsForm['wellsToExclude']], void>(
      null,
      (get, set, wellsToExcludeAtom) => {
        if (!wellsToExcludeAtom) return;
        set(
          wellsToExcludeAtom,
          get(wellsToExcludeAtom).value.map(
            ({ formationName, slotNumber, uwi }) =>
              createWellToExcludeElement(
                {
                  formationName: get(formationName).value,
                  slotNumber: get(slotNumber).value,
                  uwi: get(uwi).value,
                },
                'new'
              )
          )
        );
      }
    );
  }, []);

  const setAllSlotsAsDirty = useSetAtom(setAllSlotsAsDirtyAtom);

  return (
    <Modal
      open={isOpen}
      size="lg"
      modalHeading="Development Area Exceptions"
      primaryButtonText={selectedExceptionsForm ? 'Close' : 'Add Exception'}
      secondaryButtonText={!selectedExceptionsForm ? 'Close' : undefined}
      className={style.devAreaModal}
      onRequestSubmit={() => {
        if (selectedExceptionsForm) {
          setAllSlotsAsDirty(selectedExceptionsForm.wellsToExclude);
        }
        onSubmit();
      }}
      onRequestClose={onClose}
    >
      {isOpen && (
        <DevelopmentAreaSlotSelection
          exceptionsForm={selectedExceptionsForm}
          developmentAreasWithExceptionsAtom={
            developmentAreasWithExceptionsAtom
          }
        />
      )}
    </Modal>
  );
};

const DevelopmentAreaSlotSelection = ({
  exceptionsForm,
  developmentAreasWithExceptionsAtom,
}: {
  exceptionsForm?: ExceptionsForm;
  developmentAreasWithExceptionsAtom: Atom<number[]>;
}) => {
  return (
    <>
      {!exceptionsForm && (
        <DevAreaSearcher
          developmentAreasWithExceptionsAtom={
            developmentAreasWithExceptionsAtom
          }
        />
      )}
      {exceptionsForm && (
        <SlotsPicker
          exceptionsForm={exceptionsForm}
          developmentAreasWithExceptionsAtom={
            developmentAreasWithExceptionsAtom
          }
        />
      )}
    </>
  );
};

const SlotsPicker = ({
  exceptionsForm,
  developmentAreasWithExceptionsAtom,
}: {
  exceptionsForm: ExceptionsForm;
  developmentAreasWithExceptionsAtom: Atom<number[]>;
}) => {
  const developmentAreaHref = useAtomValue(exceptionsForm.href);
  const developmentAreaAtom = useMemo(
    () =>
      createResourceAtom<DevelopmentAreaResponse>(developmentAreaHref.value),
    [developmentAreaHref.value]
  );
  const developmentAreaData = useAtomValue(developmentAreaAtom);

  if (developmentAreaData.loading)
    return <InlineLoading description="Loading development area..." />;

  if (!developmentAreaData.data) return null;

  if ('error' in developmentAreaData.data)
    return (
      <InlineNotification
        kind="error"
        title={developmentAreaData.data.error}
        lowContrast
      />
    );

  return (
    <SlotPickerImpl
      developmentArea={developmentAreaData.data}
      exceptionsForm={exceptionsForm}
      developmentAreasWithExceptionsAtom={developmentAreasWithExceptionsAtom}
    />
  );
};

const SlotPickerImpl = ({
  developmentArea,
  exceptionsForm,
  developmentAreasWithExceptionsAtom,
}: {
  exceptionsForm: ExceptionsForm;
  developmentArea: DevelopmentAreaResponse;
  developmentAreasWithExceptionsAtom: Atom<number[]>;
}) => {
  const formPopulated = useRef(false);
  const developmentAreasWithExceptions = useAtomValue(
    developmentAreasWithExceptionsAtom
  );
  const slotsAtom = useMemo(
    () =>
      createResourceAtom<EvalSlotResponse[]>(
        developmentArea.resources.slots.href
      ),
    [developmentArea.resources.slots.href]
  );

  const assumptionsAtom = useMemo(
    () =>
      createResourceAtom<EvalDefaultAssumptions>(
        developmentArea.resources.defaultAssumptions.href
      ),
    [developmentArea.resources.defaultAssumptions.href]
  );

  const setWellsToExclude = useSetAtom(exceptionsForm.wellsToExclude);

  const slots = useAtomValue(slotsAtom);
  const assumptions = useAtomValue(assumptionsAtom);

  if (slots.loading) return <InlineLoading description="Loading slots..." />;

  if (!slots.data) return null;

  if ('error' in slots.data)
    return (
      <InlineNotification kind="error" title={slots.data.error} lowContrast />
    );

  if (assumptions.loading)
    return <InlineLoading description="Loading assumptions..." />;

  if (!assumptions.data) return null;

  if ('error' in assumptions.data)
    return (
      <InlineNotification
        kind="error"
        title={assumptions.data.error}
        lowContrast
      />
    );

  if (!slots.data.length)
    return (
      <Tile light className={style.emptyMessage}>
        <EmptyState
          icon={DevAreaSvg}
          headerText="No slots available for this development area"
          helperText="Please select another one"
        />
      </Tile>
    );

  if (
    !formPopulated.current &&
    !developmentAreasWithExceptions.includes(developmentArea.id)
  ) {
    setWellsToExclude(
      assumptions.data.drillingAssumptions.wellsToExclude.map((el) =>
        createWellToExcludeElement(el)
      )
    );
    formPopulated.current = true;
  }

  return <Slots form={exceptionsForm} devAreaSlots={slots.data} />;
};

const { searchAtom, resetSearchDataAtom } =
  searchAtomPair<DevelopmentAreaQueryResponse>('developmentAreas');

const DevAreaSearcher = ({
  developmentAreasWithExceptionsAtom,
}: {
  developmentAreasWithExceptionsAtom: Atom<number[]>;
}) => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const [searchDisplayText, setSearchDisplayText] = useState('');
  const [exceptionsForm, setExceptionsForm] = useAtom(newExceptionsFormAtom);
  const developmentAreasWithExceptions = useAtomValue(
    developmentAreasWithExceptionsAtom
  );
  const [currentSelection, setCurrentSelection] = useState(0);

  const debouncedSetSearchPayload = useMemo(() => {
    return debounce((value) => {
      if (!value) {
        resetSearchData(undefined);
        return;
      }
      setSearchPayload((current) => ({
        ...current,
        pageSize: current?.pageSize || 10,
        text: value,
        page: 1,
      }));
    }, 400);
  }, [setSearchPayload, resetSearchData]);

  useEffect(() => {
    return () => resetSearchData(undefined);
  }, [resetSearchData]);

  return (
    <Grid condensed fullWidth>
      <Row className={style.stretch}>
        <Column sm={6} lg={3}>
          <Tile>
            <Search
              labelText="Search Development Areas"
              placeholder="Search Development Areas"
              size="lg"
              autoFocus
              value={searchDisplayText}
              onChange={({ target: { value } }) => {
                setSearchDisplayText(value);
                debouncedSetSearchPayload(value);
              }}
            />
            {searchResult.loading ? (
              <InlineLoading description="Loading results..." />
            ) : (
              searchResult.data?.results
                .filter((el) => !developmentAreasWithExceptions.includes(el.id))
                .map((el) => (
                  <ul className={style.devAreasList} key={el.id}>
                    <li>
                      <Link
                        className={classNames({
                          [style.isSelected]: currentSelection === el.id,
                        })}
                        onClick={() => {
                          setCurrentSelection(el.id);
                          setExceptionsForm(
                            developmentAreaRow({
                              developmentArea: {
                                href: el.href,
                                id: el.id,
                                name: el.name,
                                resources: {
                                  slots: el.resources.comments,
                                },
                              },
                              wellsToExclude: [],
                            })
                          );
                        }}
                      >
                        {el.name}
                      </Link>
                    </li>
                  </ul>
                ))
            )}
          </Tile>
        </Column>
        <Column sm={6} lg={13} className={style.content}>
          {exceptionsForm ? (
            <SlotsPicker
              exceptionsForm={exceptionsForm}
              developmentAreasWithExceptionsAtom={
                developmentAreasWithExceptionsAtom
              }
            />
          ) : (
            <Tile light className={style.emptyMessage}>
              <EmptyState
                icon={DevAreaSvg}
                headerText="Pick a development area first"
                helperText=""
              />
            </Tile>
          )}
        </Column>
      </Row>
    </Grid>
  );
};

export { DevelopmentAreaExceptions };
