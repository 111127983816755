import { Geometry } from 'geojson';
import { findCenter } from 'lib/map';
import { MapLayerMouseEvent } from 'mapbox-gl';
import { ReactNode } from 'react';
import { Map } from '.';
import { MapUserConfigAtom } from './types';

const CenteredMap = ({
  geometry,
  children,
  className,
  onClick,
  mapConfigAtom,
}: {
  geometry: Geometry;
  children: ReactNode;
  className?: string;
  onClick?: (e: MapLayerMouseEvent) => void;
  mapConfigAtom: MapUserConfigAtom;
}) => {
  const { latitude, longitude } = findCenter(geometry);

  return (
    <Map
      latitude={latitude}
      longitude={longitude}
      onClick={onClick}
      className={className}
      mapConfigAtom={mapConfigAtom}
      showControls
      showMapFilters
    >
      {children}
    </Map>
  );
};

export default CenteredMap;
