import { InlineLoading, Tile } from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import { Section } from 'components/section';
import { parse } from 'date-fns';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import { zip } from 'lodash';
import { EvalVolumesByMonth } from 'types/evaluation-api/responses';
import NoDataSvg from 'images/srp-illustration-data.svg';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const VolumesByMonth = ({
  volumes,
}: {
  volumes: EvalVolumesByMonth | undefined;
}) => {
  if (!volumes) return <InlineLoading description={'Loading volumes...'} />;

  const {
    dates,
    pdpLiquid,
    ntdGas,
    ntdLiquid,
    ltdLiquid,
    pdpGas,
    ltdGas,
    ducLiquid,
    ducGas,
    permitLiquid,
    permitGas,
  } = volumes;

  if (!dates) {
    return (
      <Tile>
        <EmptyState
          headerText="No Data Available"
          icon={NoDataSvg}
          helperText="No data available to display the volumes chart."
        />
      </Tile>
    );
  }

  const referenceDate = new Date();
  const parsedDates = dates.map((date) =>
    parse(date, 'yyyy-MM-dd', referenceDate).valueOf()
  );

  return (
    <Section header="">
      <Tile>
        <Chart
          highcharts={Highcharts}
          options={{
            title: 'Volumes',
            chart: {
              type: 'spline',
              height: 600,
              zoomType: 'x',
              style: {
                fontFamily: 'IBM Plex Sans',
                color: '#6f6f6f',
              },
            },
            colorByPoint: true,
            xAxis: {
              type: 'datetime',
              gridLineWidth: 1,
              title: { text: 'Year' },
              dateTimeLabelFormats: { millisecond: "%b '%y" },
            },
            yAxis: {
              type: 'logarithmic',
              minorTickInterval: 0.1,
              title: { text: 'BBL,MCF' },
            },
            tooltip: {
              shared: true,
              xDateFormat: '%B %Y',
              valueDecimals: 0,
            },
            plotOptions: {
              series: {
                animation: {
                  duration: 3000,
                },
              },
            },
            series: [
              {
                type: 'spline',
                name: `PDP (Liquid • BBL)`,
                color: '#27723b',
                dashStyle: 'line',
                lineWidth: 1,
                tooltip: { valueSuffix: ' BBL' },
                data: pdpLiquid ? zip(parsedDates, pdpLiquid) : [],
              },
              {
                type: 'spline',
                name: 'NTD (Liquid • BBL)',
                color: '#349850',
                dashStyle: 'dash',
                lineWidth: 2,
                tooltip: { valueSuffix: ' BBL' },
                data: ntdLiquid ? zip(parsedDates, ntdLiquid) : [],
              },
              {
                type: 'spline',
                name: 'LTD (Liquid • BBL)',
                color: '#42be65',
                dashStyle: 'longdash',
                lineWidth: 2,
                tooltip: { valueSuffix: ' BBL' },
                data: ltdLiquid ? zip(parsedDates, ltdLiquid) : [],
              },
              {
                type: 'spline',
                name: 'DUC (Liquid • BBL)',
                color: '#67cb83',
                dashStyle: 'ShortDot',
                lineWidth: 2,
                tooltip: { valueSuffix: ' BBL' },
                data: ducLiquid ? zip(parsedDates, ducLiquid) : [],
              },
              {
                type: 'spline',
                name: 'Permit (Liquid • BBL)',
                color: '#8dd8a2',
                dashStyle: 'ShortDashDot',
                lineWidth: 2,
                tooltip: { valueSuffix: ' BBL' },
                data: permitLiquid ? zip(parsedDates, permitLiquid) : [],
              },
              {
                type: 'spline',
                name: 'PDP (Gas • MCF)',
                color: '#fa4d56',
                dashStyle: 'line',
                lineWidth: 1,
                tooltip: { valueSuffix: ' MCF' },
                data: pdpGas ? zip(parsedDates, pdpGas) : [],
              },
              {
                type: 'spline',
                name: 'NTD (Gas • MCF)',
                color: '#c83d44',
                dashStyle: 'dash',
                lineWidth: 2,
                tooltip: { valueSuffix: ' MCF' },
                data: ntdGas ? zip(parsedDates, ntdGas) : [],
              },
              {
                type: 'spline',
                name: 'LTD (Gas • MCF)',
                color: '#fa4d56',
                dashStyle: 'longdash',
                lineWidth: 2,
                tooltip: { valueSuffix: ' MCF' },
                data: ltdGas ? zip(parsedDates, ltdGas) : [],
              },
              {
                type: 'spline',
                name: 'DUC (Gas • MCF)',
                color: '#fb7077',
                dashStyle: 'ShortDot',
                lineWidth: 2,
                tooltip: { valueSuffix: ' MCF' },
                data: ducGas ? zip(parsedDates, ducGas) : [],
              },
              {
                type: 'spline',
                name: 'Permit (Gas • MCF)',
                color: '#fc9499',
                dashStyle: 'ShortDashDot',
                lineWidth: 2,
                tooltip: { valueSuffix: ' MCF' },
                data: permitGas ? zip(parsedDates, permitGas) : [],
              },
            ],
          }}
        />
      </Tile>
    </Section>
  );
};

export { VolumesByMonth };
