import { FormRowStatus } from 'atoms/create-resource-atom';
import {
  atomField,
  AtomizedForm,
  AtomizedRowForm,
  required,
  requiredElements,
} from 'atoms/form-atoms';
import { Getter } from 'jotai';
import { ApiPayloads } from 'types/api-payloads';
import { AddUndefinedToArray } from 'types/utils';
import { formValuesAtom, nonCourthouseFormValuesAtom } from './atoms';
import { DocumentForm, NonCourthouseDocumentForm } from './types';

const createDocumentForm = (
  {
    countyId = undefined,
    bookType = null,
    documentType = null,
    volume = null,
    page = null,
    instrumentNumber = null,
    locationAttributes = [],
    grantorsAttributes = [],
    granteesAttributes = [],
    effectiveDate = null,
    instrumentDate = null,
    recordedDate = null,
    fileDate = null,
    file = undefined,
    noLocations = false,
  } = {} as DocumentForm
): AtomizedForm<DocumentForm> => {
  return {
    countyId: atomField(countyId, {
      validate: (v) => required(v, 'County is required'),
    }),
    bookType: atomField(bookType, {
      validate: (v) => required(v, 'Book Type is required'),
    }),
    documentType: atomField(documentType, {
      validate: (v) => required(v, 'Document Type is required'),
    }),
    volume: atomField(volume),
    page: atomField(page),
    instrumentNumber: atomField(instrumentNumber),
    locationAttributes: atomField(locationAttributes.map(locationRow)),
    grantorsAttributes: atomField(grantorsAttributes.map(entityRow)),
    granteesAttributes: atomField(granteesAttributes.map(entityRow)),
    effectiveDate: atomField(effectiveDate),
    instrumentDate: atomField(instrumentDate),
    recordedDate: atomField(recordedDate),
    fileDate: atomField(fileDate, {
      validate: (v) => required(v, 'File Date is required'),
    }),
    file: atomField(file, {
      validate: (v) => required(v, 'File is required'),
    }),
    noLocations: atomField(noLocations),
  };
};

const createNonCourthouseForm = (
  {
    locationAttributes = [],
    effectiveDate = null,
    file = undefined,
  } = {} as NonCourthouseDocumentForm
): AtomizedForm<NonCourthouseDocumentForm> => {
  return {
    locationAttributes: atomField(locationAttributes.map(locationRow), {
      validate: (v) => requiredElements(v, 'Locations are required'),
    }),
    effectiveDate: atomField(effectiveDate, {
      validate: (v) => required(v, 'Effective Date is required'),
    }),
    file: atomField(file, {
      validate: (v) => required(v, 'File is required'),
    }),
  };
};

const entityRow = (
  { name = '' } = {} as DocumentForm['granteesAttributes'][0]
): AtomizedRowForm<DocumentForm['granteesAttributes'][0]> => {
  return {
    name: atomField(name, {
      validate: (v) => required(v, 'Name is empty. Remove row if not needed'),
    }),
    _rowStatus: atomField<FormRowStatus>('new'),
  };
};

const locationRow = (
  {
    quarterCalls = [],
    referenceLocation = undefined,
  } = {} as DocumentForm['locationAttributes'][0]
): AtomizedRowForm<DocumentForm['locationAttributes'][0]> => {
  return {
    quarterCalls: atomField(quarterCalls),
    referenceLocation: atomField(referenceLocation, {
      validate: (v) => required(v, 'Location is required'),
    }),
    _rowStatus: atomField<FormRowStatus>('new'),
  };
};

const getInstrumentType = (
  form: AddUndefinedToArray<Omit<DocumentForm, 'file' | 'locationAttributes'>>
): ApiPayloads['document']['payload']['identifierType'] => {
  const volume = form.volume;
  const page = form.page;
  const instrument = form.instrumentNumber;

  if (volume && page && instrument) {
    return 'both';
  }

  if (instrument && !page && !volume) {
    return 'instrument';
  }

  return 'volumeAndPage';
};

const getLocationAttributes = (
  form: DocumentForm['locationAttributes'] = []
) => {
  return form
    .map((el) => {
      if (!el.referenceLocation) return;
      return {
        referenceLocationId: el.referenceLocation.id,
        quarterCalls: el.quarterCalls || [],
      };
    })
    .flatMap((el) => (el ? [el] : []));
};

const getPayload = (get: Getter): ApiPayloads['document']['payload'] => {
  const { file, locationAttributes, ...form } = get(formValuesAtom);
  return {
    ...form,
    countyId: form.countyId?.id,
    locationsAttributes: getLocationAttributes(locationAttributes),
    identifierType: getInstrumentType(form),
    image: file?.image,
    fileName: file?.name,
  };
};

const getNonCourthousePayload = (
  get: Getter
): ApiPayloads['document']['payload'] => {
  const form = get(nonCourthouseFormValuesAtom);
  return {
    locationsAttributes: getLocationAttributes(form.locationAttributes),
    effectiveDate: form.effectiveDate,
    fileName: form.file?.name,
    image: form.file?.image,
  };
};

export {
  createDocumentForm,
  createNonCourthouseForm,
  entityRow,
  getNonCourthousePayload,
  getPayload,
  locationRow,
};
