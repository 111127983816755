import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import { FC } from 'react';

export interface ColumnChartProps {
  data: Record<string, number> | undefined;
  titleText: string;
  xAxisText: string;
  yAxisText: string;
  seriesName: string;
  dataLabelsEnabled?: boolean;
}

const ColumnChart: FC<ColumnChartProps> = ({
  data,
  titleText,
  xAxisText,
  yAxisText,
  seriesName,
  dataLabelsEnabled,
}) => {
  const formattedData = data ? Object.entries(data) : [];

  return (
    <>
      <Chart
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'column',
          },
          title: {
            text: titleText,
          },
          xAxis: {
            type: 'category',
            labels: {
              rotation: -45,
              style: {
                fontSize: '1em',
                fontFamily: 'Verdana, sans-serif',
              },
            },
            title: {
              text: xAxisText,
            },
          },
          yAxis: {
            min: 0,
            title: {
              text: yAxisText,
            },
          },
          legend: {
            enabled: false,
          },
          series: [
            {
              name: seriesName,
              data: formattedData,
              dataLabels: {
                enabled: dataLabelsEnabled,
                rotation: -90,
                color: '#FFF',
                align: 'right',
                format: '{point.y:,.0f}',
                y: 10,
                style: {
                  fontSize: '13px',
                  fontFamily: 'Verdana, sans-serif',
                },
              },
            },
          ],
        }}
      />
    </>
  );
};

export { ColumnChart };
