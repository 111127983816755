import { rootResourceAtom } from 'atoms/root';
import { atom } from 'jotai';
import request from 'lib/request';
import { ApiRootResponse } from 'types/api-responses';

const newReleaseAtomBase = atom<boolean>(false);
const newReleaseAtom = atom<boolean, [undefined], void>(
  (get) => get(newReleaseAtomBase),
  (_get, set) => {
    set(newReleaseAtomBase, false);
  }
);

const newReleaseCheckerAtom = atom(
  () => null,
  async (get, set) => {
    const resources = get(rootResourceAtom);
    if (!resources) return;
    if ('error' in resources) return;

    const currentVersion = {
      major: resources.version.major,
      minor: resources.version.minor,
    };

    const version = await request.get<ApiRootResponse['version']>(
      resources.version.href
    );

    if ('error' in version) return;

    if (version.major === null && version.minor === null) {
      return;
    }

    if (
      version.major !== currentVersion?.major ||
      version.minor !== currentVersion?.minor
    ) {
      set(newReleaseAtomBase, true);
    }
  }
);

newReleaseCheckerAtom.onMount = (set) => {
  set();
};

export { newReleaseCheckerAtom, newReleaseAtom };
