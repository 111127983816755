type SortField = {
  id:
    | 'updated_at'
    | 'created_at'
    | 'file_date'
    | 'instrument_date'
    | 'recorded_date'
    | 'effective_date';
  text: string;
  direction: 'ASC' | 'DESC';
};

const sortFields: readonly SortField[] = [
  {
    id: 'effective_date',
    text: 'Effective Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'effective_date',
    text: 'Effective Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'file_date',
    text: 'File Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'file_date',
    text: 'File Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'instrument_date',
    text: 'Instrument Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'instrument_date',
    text: 'Instrument Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'recorded_date',
    text: 'Recorded Date - Newest',
    direction: 'DESC',
  },
  {
    id: 'recorded_date',
    text: 'Recorded Date - Oldest',
    direction: 'ASC',
  },
  {
    id: 'created_at',
    text: 'Created At - Newest',
    direction: 'DESC',
  },
  {
    id: 'created_at',
    text: 'Created At - Oldest',
    direction: 'ASC',
  },
  {
    id: 'updated_at',
    text: 'Updated At - Newest',
    direction: 'DESC',
  },
  {
    id: 'updated_at',
    text: 'Updated At - Oldest',
    direction: 'ASC',
  },
] as const;

export type { SortField };

export default sortFields;
