import { Grid, Row, SkeletonText } from 'carbon-components-react';
import style from './runsheet_skeleton.module.scss';

export function Skeleton({ elementCount }: { elementCount: number }) {
  return (
    <>
      <Grid condensed fullWidth className={style.skeletonRunsheetTable}>
        {[...Array(elementCount).keys()].map((el) => (
          <Row key={el} className={style.skeletonCardGrid}>
            <div className={style.skeletonCardTitleArea}>
              <SkeletonText className={style.skeletonDesc} />
              <SkeletonText className={style.skeletonTitle} />
              <SkeletonText className={style.skeletonSubDesc} />
            </div>
            <div className={style.skeletonCardContent}>
              <div className={style.skeletonRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
              <div className={style.skeletonRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
              <div className={style.skeletonRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
              <div className={style.skeletonRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
            </div>
            <div className={style.skeletonCardContentParty}>
              <div className={style.skeletonRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
              <div className={style.skeletonRow}>
                <SkeletonText className={style.skeletonDesc} />
                <SkeletonText className={style.skeletonSubDesc} />
              </div>
            </div>
          </Row>
        ))}
      </Grid>
    </>
  );
}
