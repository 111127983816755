import { memo } from 'react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import { zip } from 'lodash';
import { ProductionForecastResponse } from 'types/api-responses';
import { parse } from 'date-fns';

interface Props {
  dates: NonNullable<ProductionForecastResponse['dates']>;
  gas: ProductionForecastResponse['gas'];
  liquid: ProductionForecastResponse['liquid'];
  water: ProductionForecastResponse['water'];
  className?: string;
}

function maskZeros(n: number) {
  return n === 0 ? 0.001 : n;
}

export const ProductionGraph = memo(
  ({ dates, gas, liquid, water, className }: Props) => {
    const referenceDate = new Date();
    const parsedDates = dates.map((date) =>
      parse(date, 'yyyy-MM-dd', referenceDate).valueOf()
    );

    liquid = liquid?.map(maskZeros);
    water = water?.map(maskZeros);
    gas = gas?.map(maskZeros);

    return (
      <div className={className}>
        <Chart
          highcharts={Highcharts}
          options={{
            title: 'Production',
            chart: {
              type: 'spline',
              height: 600,
              spacingBottom: 20,
              zoomType: 'x',
              style: {
                fontFamily: 'IBM Plex Sans',
                color: '#6f6f6f',
              },
            },
            xAxis: {
              type: 'datetime',
              gridLineWidth: 1,
              title: { text: 'Time' },
              dateTimeLabelFormats: { millisecond: "%b '%y" },
            },
            yAxis: {
              type: 'logarithmic',
              minorTickInterval: 0.1,
              title: { text: 'BBL & MCF' },
              min: 1,
            },
            tooltip: {
              shared: true,
              xDateFormat: '%B %Y',
              valueDecimals: 0,
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
            plotOptions: {
              series: {
                animation: {
                  duration: 3000,
                },
              },
            },
            series: [
              {
                type: 'spline',
                name: 'liquid',
                color: '#42be65',
                tooltip: { valueSuffix: ' BBL' },
                data: zip(parsedDates, liquid),
              },
              {
                type: 'spline',
                name: 'water',
                color: '#4589ff',
                tooltip: { valueSuffix: ' BBL' },
                data: zip(parsedDates, water),
              },
              {
                type: 'spline',
                name: 'gas',
                color: '#fa4d56',
                tooltip: { valueSuffix: ' MCF' },
                data: zip(parsedDates, gas),
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 1100,
                  },
                  chartOptions: {
                    legend: {
                      align: 'left',
                      verticalAlign: 'bottom',
                      layout: 'horizontal',
                    },
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }
);

ProductionGraph.displayName = 'ProductionGraph';
