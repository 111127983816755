import {
  DevelopmentAreaEvaluationsResponse,
  PackageEvaluationsResponse,
} from 'types/evaluation-api/responses';

const groupEvaluationsByRunId = (
  input: DevelopmentAreaEvaluationsResponse[] | PackageEvaluationsResponse[]
): Array<
  Array<DevelopmentAreaEvaluationsResponse | PackageEvaluationsResponse>
> => {
  const result = [];

  for (let i = 0; i < input.length; i++) {
    const current = input[i];

    // If there's no runId, just push a new group with the current item.
    if (!('runId' in current)) {
      result.push([current]);
    } else {
      const previous = input[i - 1];
      // If the previous item had the same runId, add to its group.
      // If not (or if this is the first item), create a new group.
      if (i > 0 && 'runId' in previous && previous.runId === current.runId) {
        result[result.length - 1].push(current);
      } else {
        result.push([current]);
      }
    }
  }

  return result;
};

export { groupEvaluationsByRunId };
