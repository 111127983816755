import { PageHeader } from 'components/page-header';
import { useAtom, useAtomValue } from 'jotai';
import { useScenarioAssumptionsAtom } from '../common/atoms';
import { ScenarioBuilder } from '../common/scenario-builder';
import { ScenarioSkeleton } from '../common/scenario_skeleton';
import { InlineNotification } from 'carbon-components-react';
import { resourcesAtom } from 'atoms/root';

const NewScenarioPage = () => {
  const resources = useAtomValue(resourcesAtom);
  const scenarioAssumptionsAtom = useScenarioAssumptionsAtom(
    resources?.scenariosDefault.href
  );
  const [assumptions, getAssumptions] = useAtom(scenarioAssumptionsAtom);

  if (assumptions.loading) return <ScenarioSkeleton />;

  if (assumptions.data && 'error' in assumptions.data)
    return (
      <InlineNotification
        kind="error"
        title={assumptions.data.error}
        lowContrast
      />
    );

  if (!assumptions.data)
    return (
      <InlineNotification
        kind="error"
        title="Something went wrong"
        lowContrast
      />
    );

  return (
    <>
      <PageHeader
        breadcrumbs={[
          ['/scenarios', 'Scenarios'],
          ['/scenarios/new', 'Create Scenario'],
        ]}
        title="Create Scenario"
        actions={null}
      />
      <ScenarioBuilder
        assumptions={assumptions.data}
        type="POST"
        href="/scenarios"
        getAssumptions={getAssumptions}
      />
    </>
  );
};

export { NewScenarioPage };
