import { rootResourceAtom } from 'atoms/root';
import {
  HeaderMenu,
  HeaderMenuItem,
  Header as CarbonHeader,
  SkipToContent,
  HeaderMenuButton,
  HeaderName,
  HeaderNavigation,
  SideNav,
  SideNavItems,
  SideNavMenu,
  SideNavMenuItem,
} from 'carbon-components-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { LinkProps, Link } from 'react-router-dom';
import { ApiRootResponse } from 'types/api-responses';
import { logoutAtom, showSideNavAtom } from './atoms';
import style from './header.module.scss';
import StrongholdSvg from 'images/stronghold-logo.svg';

export interface HeaderProps {
  title: string;
}

const getSearchItems = (resources: ApiRootResponse['resources']) => {
  if ('sessions' in resources) {
    return [];
  }

  return [
    {
      title: 'Documents',
      href: resources['documents'].href,
    },
    {
      title: 'Wells',
      href: resources['wells'].href,
    },
    {
      title: 'Search by Location',
      href: resources['referenceLocations'].href,
    },
  ] as const;
};

const getEvalItems = (resources: ApiRootResponse['resources']) => {
  if ('sessions' in resources) {
    return [];
  }

  return [
    {
      title: 'Packages',
      href: resources['packages'].href,
    },
    {
      title: 'Development Areas',
      href: resources['developmentAreas'].href,
    },
    {
      title: 'Scenarios',
      href: resources['scenarios'].href,
    },
  ] as const;
};

const getTitleItems = (resources: ApiRootResponse['resources']) => {
  if ('sessions' in resources) {
    return [];
  }

  return [
    {
      title: 'Title Workspaces',
      href: resources['titleWorkspaces'].href,
    },
    {
      title: 'Global Interpretations',
      href: resources['interpretations'].href,
    },
    {
      title: 'Entities',
      href: resources['entities'].href,
    },
  ] as const;
};

const Header = ({ title }: HeaderProps) => {
  const rootResource = useAtomValue(rootResourceAtom);
  const [showSideNav, setShowSideNav] = useAtom(showSideNavAtom);
  const logout = useSetAtom(logoutAtom);
  if (!rootResource) return null;
  if (!rootResource) return null;

  const navSearchItems = getSearchItems(rootResource.resources);
  const navEvalItems = getEvalItems(rootResource.resources);
  const navTitleItems = getTitleItems(rootResource.resources);

  const menuSearchItems =
    navSearchItems?.map((navItem) => (
      <HeaderMenuItem<LinkProps>
        element={Link}
        to={navItem.href}
        key={navItem.href}
        onClick={() => setShowSideNav(false)}
      >
        {navItem.title}
      </HeaderMenuItem>
    )) ?? null;

  const menuEvalItems =
    navEvalItems?.map((navEvalItem) => (
      <HeaderMenuItem<LinkProps>
        element={Link}
        to={navEvalItem.href}
        key={navEvalItem.href}
        onClick={() => setShowSideNav(false)}
      >
        {navEvalItem.title}
      </HeaderMenuItem>
    )) ?? null;

  const menuTitleItems =
    navTitleItems?.map((navTitleItem) => (
      <HeaderMenuItem<LinkProps>
        element={Link}
        to={navTitleItem.href}
        key={navTitleItem.href}
        onClick={() => setShowSideNav(false)}
      >
        {navTitleItem.title}
      </HeaderMenuItem>
    )) ?? null;

  const fullName = rootResource.session?.profile?.name;
  const initials = fullName
    ?.trim()
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  return (
    <CarbonHeader
      aria-label={`Stronghold ${title}`}
      className={style.insightsNavbar}
    >
      <SkipToContent />
      <HeaderMenuButton
        aria-label="Open menu"
        onClick={() => setShowSideNav(!showSideNav)}
        isActive={showSideNav}
      />
      <HeaderName<LinkProps> element={Link} to="/" prefix="">
        <img
          src={StrongholdSvg}
          className={style.strongholdLogo}
          alt="Stronghold"
        />
        {title}
      </HeaderName>
      <SideNav
        aria-label="Side navigation"
        expanded={showSideNav}
        isPersistent={false}
      >
        {rootResource.session && (
          <SideNavItems className={style.mobileLinks}>
            <SideNavMenu title="Search">{menuSearchItems}</SideNavMenu>
            <SideNavMenu title="Evaluation">{menuEvalItems}</SideNavMenu>
            <SideNavMenu title="Title">{menuTitleItems}</SideNavMenu>
            <SideNavMenuItem
              href="#"
              aria-label="Log Out"
              onClick={() => logout()}
            >
              Log Out
            </SideNavMenuItem>
          </SideNavItems>
        )}
      </SideNav>
      {rootResource.session && (
        <HeaderNavigation aria-label="navbar">
          <HeaderMenu aria-label="Search" menuLinkName="Search">
            {menuSearchItems}
          </HeaderMenu>
          <HeaderMenu aria-label="Evaluation" menuLinkName="Evaluation">
            {menuEvalItems}
          </HeaderMenu>
          <HeaderMenu aria-label="Title" menuLinkName="Title">
            {menuTitleItems}
          </HeaderMenu>
        </HeaderNavigation>
      )}
      {rootResource.session ? (
        <div className={style.userNameArea}>
          <div className={style.userdata}>
            {rootResource.session.profile ? (
              <p className={style.userName}>
                {rootResource.session.profile?.name}
              </p>
            ) : null}
            <span
              className={style.logOut}
              aria-label="Log Out"
              onClick={() => logout()}
            >
              Log Out
            </span>
          </div>
          <div className={style.nameInitials}>{initials}</div>
        </div>
      ) : null}
    </CarbonHeader>
  );
};

export { Header };
