import {
  TypeLayout,
  TypeLayoutBody,
  TypeLayoutCell,
  TypeLayoutRow,
} from '@carbon/ibm-security';
import { Output } from '../calculation/output';
import { DepthRange } from './depth_range';
import { Button, InlineNotification, Tab, Tabs } from 'carbon-components-react';
import { OutputSkeleton } from '../../common/output_skeleton';
import { CalculationLoading } from '../../common/calculation-loading';
import { lazy, Suspense, useEffect, useState } from 'react';
import { TractResponse } from 'types/api-responses';
import { CenteredMap, PolygonArea } from 'components/map';

import { createMutationAtom } from 'atoms/create-resource-atom';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ColumnDependency16 } from '@carbon/icons-react';
import style from '../calculation/asset.module.scss';
import { green } from '@carbon/colors';
import {
  depthRangesMapAtom,
  tractsMapAtom,
  subtractsAtom,
  subtractCurrentDepthRangeMapAtom,
} from './atoms';
import { atomWithStorage } from 'jotai/utils';
import { getMapDefaultConfig } from 'components/map/atoms';
import { InlineEdit } from 'components/inline-edit';

import { DECIMAL_FORMATTER_TWO_FRACTIONS } from 'lib/ui';

export interface TractProps {
  onUpdate: () => void;
  tractHref: string;
  id: string;
}

const ChainViewer = lazy(() => import('../calculation/chain_viewer'));

const mutationAtom = createMutationAtom<
  TractResponse,
  'workspaceCalculationTract'
>();

const mapConfigAtom = atomWithStorage(
  'insights-tract-map-config',
  getMapDefaultConfig()
);

export function Tract({ onUpdate, tractHref }: TractProps) {
  const [depthTab, setDepthTab] = useAtom(subtractCurrentDepthRangeMapAtom);
  const subtracts = useAtomValue(subtractsAtom);
  const [graphOpen, setGraphOpen] = useState(false);
  const [, runMutation] = useAtom(mutationAtom);
  const [tractMap, setTractMap] = useAtom(tractsMapAtom);
  const setDepthRange = useSetAtom(depthRangesMapAtom);

  useEffect(() => {
    setTractMap({ href: tractHref });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tract = tractMap[tractHref];

  if (tract && tract.loading) {
    return <OutputSkeleton />;
  }

  if (!tract) return null;

  if (tract.data && 'error' in tract.data)
    return (
      <InlineNotification kind="error" title={tract.data.error} lowContrast />
    );

  if (!tract.data) return null;

  const name = tract.data.overriddenName || tract.data.derivedName;
  const tractId = tract.data.id;
  const derivedName = tract.data.derivedName;

  return (
    <section>
      <div className={style.assetGrid}>
        <div className={style.assetData}>
          <TypeLayout>
            <TypeLayoutBody>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.colLeft}>
                  Original Sub-tract Name
                </TypeLayoutCell>
                <TypeLayoutCell className={style.colRight}>
                  {tract.data.derivedName}
                </TypeLayoutCell>
              </TypeLayoutRow>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.colLeft}>
                  Sub-tract Name
                </TypeLayoutCell>
                <TypeLayoutCell className={style.colRight}>
                  <InlineEdit
                    id={`${tract.data.id}-name`}
                    value={name}
                    labelText="Sub-tract Name"
                    editDescription="Edit"
                    saveDescription="Save"
                    cancelDescription="Cancel"
                    editAlwaysVisible
                    light={false}
                    onSave={(overriddenName: string) =>
                      runMutation({
                        url: tractHref,
                        type: 'PATCH',
                        data: { overriddenName },
                        onSuccess: () => {
                          onUpdate();
                          setTractMap({ href: tractHref, force: true });
                        },
                      })
                    }
                    placeholder=""
                    size="sm"
                  />
                </TypeLayoutCell>
              </TypeLayoutRow>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.colLeft}>
                  GIS Gross Acreage
                </TypeLayoutCell>
                {tract.data.calculatedAcreage && (
                  <TypeLayoutCell className={style.colRight}>
                    {DECIMAL_FORMATTER_TWO_FRACTIONS.format(
                      tract.data.calculatedAcreage
                    )}
                  </TypeLayoutCell>
                )}
              </TypeLayoutRow>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.colLeft}>
                  Legal Acreage:
                </TypeLayoutCell>
                <TypeLayoutCell className={style.colRight}>
                  <InlineEdit
                    id={`${tract.data.id}-legal-acreage`}
                    value={tract.data.legalAcreage}
                    labelText="Legal Acreage"
                    editDescription="Edit"
                    saveDescription="Save"
                    cancelDescription="Cancel"
                    editAlwaysVisible
                    light={false}
                    onSave={(input: string) => {
                      const floatInput = parseFloat(input);
                      const legalAcreage = Number.isNaN(floatInput)
                        ? null
                        : floatInput;
                      runMutation({
                        url: tractHref,
                        type: 'PATCH',
                        data: { legalAcreage },
                        onSuccess: () => {
                          onUpdate();
                          setTractMap({ href: tractHref, force: true });
                        },
                      });
                    }}
                    placeholder=""
                    size="sm"
                  />
                </TypeLayoutCell>
              </TypeLayoutRow>
            </TypeLayoutBody>
          </TypeLayout>
        </div>
        <div className={style.assetMap}>
          {tract.data.geometry ? (
            <CenteredMap
              geometry={tract.data.geometry}
              mapConfigAtom={mapConfigAtom}
            >
              {subtracts.map(
                (el) =>
                  el.geometry && (
                    <PolygonArea
                      key={el.id}
                      geometry={el.geometry}
                      id={el.id}
                      color={el.id === tractId ? green[50] : undefined}
                    />
                  )
              )}
            </CenteredMap>
          ) : (
            <p>
              There was an issue rendering the map. The tract has no geometry.
            </p>
          )}
        </div>
      </div>
      <hr className={style.separator} />
      <Tabs
        key={tractId}
        selected={depthTab[tractId] ?? 0}
        onSelectionChange={(index) => {
          setDepthTab({ subtractId: tractId, depthIndex: index });
        }}
        className={style.tabsContained}
      >
        <Tab label="Surface">
          {tract.data.output ? (
            <>
              {tract.data.resources.debuggingGraph?.href && (
                <>
                  <div className={style.popupBtnRow}>
                    <Button
                      kind="ghost"
                      renderIcon={ColumnDependency16}
                      onClick={() => setGraphOpen(true)}
                    >
                      Open surface graph
                    </Button>
                  </div>
                  {graphOpen && (
                    <Suspense fallback={<div>Loading</div>}>
                      <ChainViewer
                        tractName={tract.data.derivedName}
                        url={tract.data.resources.debuggingGraph.href}
                        onClose={() => setGraphOpen(false)}
                        isSurface
                      />
                    </Suspense>
                  )}
                </>
              )}
              <Output
                type="surface"
                graph={tract.data.resources.debuggingGraph?.href ?? null}
                output={tract.data.output}
                tractName={tract.data.derivedName}
                outputRows={tract.data.outputRows}
                mineralLeaseholdRows={[]}
              />
            </>
          ) : (
            <CalculationLoading />
          )}
        </Tab>
        {tract.data.depthRanges.map((depthRange) => (
          <Tab
            label={depthRange.name}
            key={depthRange.name}
            onClick={() => {
              setDepthRange({
                href: depthRange.href,
              });
            }}
          >
            <DepthRange href={depthRange.href} tractName={derivedName} />
          </Tab>
        ))}
      </Tabs>
    </section>
  );
}
