import { atom, PrimitiveAtom } from 'jotai';
import request from 'lib/request';
import { documentAtom, formAtoms } from '../atoms';
import { Entity } from './types';

const createTextEntityAtoms = (entity: 'grantors' | 'grantees') => {
  const entitiesAtom = atom<Entity[]>([]);

  const entitiesMapAtom = atom((get) => {
    const entityMap = new Map<number, Entity>();
    get(entitiesAtom).forEach((el) => {
      entityMap.set(el.entityId, { entityId: el.entityId, name: el.name });
    });
    return entityMap;
  });

  const entityReferencesAtom = atom((get) => {
    return get(get(formAtoms).formAtom)[entity];
  });
  const usedEntitiesAtom = atom((get) => {
    const usedSet = new Set<number>();

    // We are only interested on recomputing the set when the
    // grantors section of the form changes.
    const references = get(entityReferencesAtom);

    references.value.forEach((el) => {
      const fields = get(el.row.formAtom);
      if (fields.entity.value?.entityId) {
        usedSet.add(fields.entity.value.entityId);
      }
    });

    return usedSet;
  });

  return { entitiesAtom, entitiesMapAtom, usedEntitiesAtom };
};

const getTextEntitiesAtom = atom<
  null,
  [
    {
      entityAtom: PrimitiveAtom<Entity[]>;
      type: 'grantors' | 'grantees';
    }
  ],
  void
>(null, async (get, set, value) => {
  const doc = get(documentAtom);
  const url =
    value.type === 'grantors'
      ? doc?.resources.grantors.href
      : doc?.resources.grantees.href;

  if (!url) return;

  const response = await request.get<{ id: number; name: string }[]>(url);

  if ('error' in response) return null;

  set(
    value.entityAtom,
    response.map((el) => ({ entityId: el.id, name: el.name }))
  );
});

const grantors = createTextEntityAtoms('grantors');
const grantees = createTextEntityAtoms('grantees');

const grantorAtoms = {
  entitiesAtom: grantors.entitiesAtom,
  entityMapAtom: grantors.entitiesMapAtom,
  usedEntitiesAtom: grantors.usedEntitiesAtom,
};

const granteeAtoms = {
  entitiesAtom: grantees.entitiesAtom,
  entityMapAtom: grantees.entitiesMapAtom,
  usedEntitiesAtom: grantees.usedEntitiesAtom,
};

export { grantorAtoms, getTextEntitiesAtom, granteeAtoms };
