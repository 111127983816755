import { Search16 } from '@carbon/icons-react';
import { Button, Modal } from 'carbon-components-react';
import { ReactNode, useReducer } from 'react';

import style from './documents-search.module.scss';

const Tip = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <>
      <dt>
        <Search16 />
        {title}
      </dt>
      <dd>{children}</dd>
    </>
  );
};

const SearchTips = () => {
  const [open, toggleOpen] = useReducer((s) => !s, false);
  return (
    <>
      <Modal
        modalHeading="Insights Search Tips"
        passiveModal={true}
        size="sm"
        onRequestClose={toggleOpen}
        open={open}
        className={style.searchTips}
      >
        <p>
          In order to get a more defined and focused set of results, try
          implementing a couple of our tips below.
        </p>

        <dl>
          <Tip title="Expedition Royalty">
            This will return results that match on either the name
            &quot;expedition&quot; or the name &quot;royalty&quot; or both.
          </Tip>
          <Tip title='"Expedition Royalty"'>
            Quotation marks around the specific phrase or name will return
            results that match the entirety of what’s between the quotations.
          </Tip>
          <Tip title="Expedition + Pontiac">
            This will return results that match on both names, not just one.
          </Tip>
          <Tip title="Expedition + (Pontiac Pony)">
            This will return results that match on Expedition and Pontiac or
            Expedition and Pony.
          </Tip>
          <Tip title="Expedition - Pony">
            This will return results that match on Expedition but not Pony.
          </Tip>
        </dl>
      </Modal>
      <Button
        kind="ghost"
        size="md"
        className={style.tips}
        onClick={() => toggleOpen()}
      >
        Search Tips
      </Button>
    </>
  );
};

export { SearchTips };
