import {
  Column,
  Grid,
  InlineLoading,
  InlineNotification,
  Link,
  Row,
  Tile,
} from 'carbon-components-react';
import { RetainedAreas } from 'components/runsheet/common/retained-areas';
import { capitalizeFirstLetter } from 'utils/strings';
import { TransactionStatusTag } from 'components/runsheet/common';
import { Date } from 'components/date';
import { Map } from './map';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import plur from 'plur';
import { useInterpretationAtom } from './atoms';

import style from './interpretation-view.module.scss';
import { LinkWithHover } from 'components/hover-card-resource';

type TransactionType =
  | 'Transaction::MineralLeaseTransaction'
  | 'Transaction::SurfaceLeaseTransaction'
  | 'Transaction::OverridingRoyalty'
  | 'Transaction::ConveyanceTransaction';

const TransactionFromType = ({ type }: { type: TransactionType }) => {
  switch (type) {
    case 'Transaction::ConveyanceTransaction':
      return <>Conveyance</>;
    case 'Transaction::MineralLeaseTransaction':
      return <>Mineral Lease</>;
    case 'Transaction::OverridingRoyalty':
      return <>Overriding</>;
    case 'Transaction::SurfaceLeaseTransaction':
      return <>Surface Lease</>;
    default:
      return null;
  }
};

const NO_DATA = '--';

const Leases = ({
  documentReferencesCount,
}: {
  documentReferencesCount: number;
}) => {
  return (
    <small>
      {documentReferencesCount === 0
        ? 'All leases'
        : `${documentReferencesCount} ${plur(
            'lease',
            documentReferencesCount
          )}`}
    </small>
  );
};

const InterpretationSummary = ({
  titleWorkspace,
  referenceLocationHref,
  href,
  transactionId,
}: {
  titleWorkspace?: number;
  referenceLocationHref?: string;
  href: string;
  transactionId?: number;
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const interpretationAtom = useInterpretationAtom(href, titleWorkspace);
  const response = useAtomValue(interpretationAtom);

  if (response.data && 'error' in response.data)
    return (
      <Tile>
        <InlineNotification
          kind="error"
          title={response.data.error}
          lowContrast
        />
      </Tile>
    );

  if (response.loading)
    return (
      <Tile>
        <InlineLoading description="Loading interpretation..." />
      </Tile>
    );

  const interpretation = response.data;

  if (!interpretation) return null;
  const legalDescription = interpretation.legalDescription || NO_DATA;
  const legalText =
    legalDescription.length <= 120
      ? legalDescription
      : showMore
      ? legalDescription
      : `${legalDescription.slice(0, 120)} ...`;

  return (
    <Tile className={style.summaryWrap}>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column lg={referenceLocationHref ? 10 : 16}>
            {interpretation.locationReferences.length ? (
              <>
                <h4>Legal Description</h4>
                <p className={style.legalText}>{legalText}</p>
                {legalDescription.length > 120 && (
                  <Link onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Show less' : 'Show more'}
                  </Link>
                )}
                <h4>Applicable Areas Scoped To Workspace</h4>
                <table className={style.rawTable}>
                  <thead>
                    <tr>
                      <th>Survey Area</th>
                      <th>Quarter Call</th>
                      <th>Start Depth (ft)</th>
                      <th>End Depth (ft)</th>
                    </tr>
                  </thead>

                  <tbody>
                    {interpretation.locationReferences.map((el) => {
                      return (
                        <tr key={el.id}>
                          <td>
                            {el.location.referenceLocationName || NO_DATA}
                          </td>
                          <td>
                            {el.location.quarterCalls.join('-') || NO_DATA}
                          </td>
                          <td>{el.startDepthInFeet || 0}</td>
                          <td>{el.endDepthInFeet || '∞'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : null}
          </Column>
          <Column lg={referenceLocationHref ? 6 : 0}>
            {referenceLocationHref &&
              interpretation.locationReferences.length !== 0 && (
                <div className={style.mapArea}>
                  <Map
                    referenceLocationHref={referenceLocationHref}
                    locations={interpretation.locationReferences.map(
                      (el) => el.location
                    )}
                  />
                  <div className={style.legendArea}>
                    <span className={style.legend}>
                      <p>
                        <span className={style.applicableAreaColor}></span>
                        Applicable Area
                      </p>
                      <p>
                        <span className={style.workspaceSurveyColor}></span>
                        Workspace Survey Area
                      </p>
                    </span>
                  </div>
                </div>
              )}
          </Column>
        </Row>
        <Row>
          <Column>
            {interpretation.transactions.length ? (
              <>
                <h4>Transactions</h4>
                <div className={style.rawTableWrap}>
                  <table className={style.rawTable}>
                    {interpretation.transactions.map((el) => {
                      if (transactionId && el.id !== transactionId) return null;
                      return (
                        <tbody key={el.id}>
                          <tr>
                            <td className={style.halftd}>
                              <label>Type</label>
                              <TransactionFromType type={el.type} />
                              <TransactionStatusTag status={el.status} />
                              {'transactionDocumentReferences' in el && (
                                <Leases
                                  documentReferencesCount={
                                    el.transactionDocumentReferences.length
                                  }
                                />
                              )}
                            </td>

                            <td>
                              <label>Grantors/Lessors</label>
                              {el.grantors.map((grantor) => (
                                <div key={grantor.id}>
                                  <LinkWithHover
                                    kind="entity"
                                    href={grantor.entity.href}
                                  >
                                    <a
                                      href={grantor.entity.href}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {grantor.entity.name}
                                    </a>
                                  </LinkWithHover>
                                </div>
                              ))}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={2}>
                              <label>Transaction Info</label>
                              {el.type ===
                              'Transaction::MineralLeaseTransaction' ? (
                                <table className={style.rawTable}>
                                  <thead>
                                    <tr>
                                      <th>Lessees</th>
                                      {el.mineralLease.term !== null && (
                                        <th>Term</th>
                                      )}
                                      {el.mineralLease.extension !== null && (
                                        <th>Extension</th>
                                      )}
                                      {el.mineralLease.royaltyRate !== null && (
                                        <th>Royalty Rate</th>
                                      )}
                                      {el.mineralLease.expirationDate && (
                                        <th>Expiration Date</th>
                                      )}
                                      {el.leaseExtensions.length ? (
                                        <th>Retained Areas</th>
                                      ) : null}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {el.conveyances.map((lessee) => (
                                          <div key={lessee.id}>
                                            <LinkWithHover
                                              kind="entity"
                                              href={lessee.entity.href}
                                            >
                                              <a
                                                href={lessee.entity.href}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {lessee.entity.name}
                                              </a>
                                            </LinkWithHover>
                                          </div>
                                        ))}
                                      </td>
                                      {el.mineralLease.term !== null && (
                                        <td>
                                          {el.mineralLease.term || NO_DATA}
                                        </td>
                                      )}
                                      {el.mineralLease.extension !== null && (
                                        <td>
                                          {el.mineralLease.extension || NO_DATA}
                                        </td>
                                      )}
                                      {el.mineralLease.royaltyRate !== null && (
                                        <td>
                                          {el.mineralLease.royaltyRate ||
                                            NO_DATA}
                                        </td>
                                      )}
                                      {el.mineralLease.expirationDate !==
                                        null && (
                                        <td>
                                          <Date
                                            date={
                                              el.mineralLease.expirationDate
                                            }
                                          />
                                        </td>
                                      )}
                                      {el.leaseExtensions.length ? (
                                        <td>
                                          <RetainedAreas row={el} />
                                        </td>
                                      ) : null}
                                    </tr>
                                  </tbody>
                                </table>
                              ) : el.type ===
                                'Transaction::SurfaceLeaseTransaction' ? (
                                <table className={style.rawTable}>
                                  <thead>
                                    <tr>
                                      <th>Lessees</th>
                                      {el.surfaceLease.term !== null && (
                                        <th>Term</th>
                                      )}
                                      {el.surfaceLease.extension !== null && (
                                        <th>Extension</th>
                                      )}
                                      {el.surfaceLease.term !== null && (
                                        <th>Expiration Date</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {el.conveyances.map((lessee) => (
                                          <div key={lessee.id}>
                                            <LinkWithHover
                                              kind="entity"
                                              href={lessee.entity.href}
                                            >
                                              <a
                                                href={lessee.entity.href}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {lessee.entity.name}
                                              </a>
                                            </LinkWithHover>
                                          </div>
                                        ))}
                                      </td>
                                      {el.surfaceLease.term !== null && (
                                        <td>{el.surfaceLease.term}</td>
                                      )}
                                      {el.surfaceLease.extension !== null && (
                                        <td>{el.surfaceLease.extension}</td>
                                      )}
                                      {el.surfaceLease.expirationDate !==
                                        null && (
                                        <td>
                                          <Date
                                            date={
                                              el.surfaceLease.expirationDate
                                            }
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  </tbody>
                                </table>
                              ) : el.type ===
                                'Transaction::OverridingRoyalty' ? (
                                <table className={style.rawTable}>
                                  <thead>
                                    <tr>
                                      <th>Fractional Kind</th>
                                      <th>Conveyances</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Of {capitalizeFirstLetter(el.ofType)}
                                      </td>
                                      <td>
                                        <table className={style.rawTable}>
                                          <thead>
                                            <tr>
                                              <th>Grantees</th>
                                              <th>ORRI interest</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el.conveyances.map((grantee) => (
                                              <tr key={grantee.id}>
                                                <td>
                                                  <LinkWithHover
                                                    kind="entity"
                                                    href={grantee.entity.href}
                                                  >
                                                    <a
                                                      href={grantee.entity.href}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {grantee.entity.name}
                                                    </a>
                                                  </LinkWithHover>
                                                </td>
                                                <td>
                                                  {grantee.multiplier ||
                                                    NO_DATA}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : el.type ===
                                'Transaction::ConveyanceTransaction' ? (
                                <table className={style.rawTable}>
                                  <thead>
                                    <tr>
                                      <th>Fractional Kind</th>
                                      <th>Conveyance Type</th>
                                      <th>Conveyances</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Of {capitalizeFirstLetter(el.ofType)}
                                        {el.unburdened ? ` (Unburdened)` : null}
                                      </td>
                                      <td>
                                        {capitalizeFirstLetter(
                                          el.conveyanceType.replace(/_/g, ' ')
                                        )}
                                      </td>
                                      <td>
                                        {el.conveyanceType ===
                                          'surface_lease_assignment' && (
                                          <table className={style.rawTable}>
                                            <thead>
                                              <tr>
                                                <th>Grantees</th>
                                                <th>Surface Lease</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {el.conveyances.map((grantee) => (
                                                <tr key={grantee.id}>
                                                  <td>
                                                    <LinkWithHover
                                                      kind="entity"
                                                      href={grantee.entity.href}
                                                    >
                                                      <a
                                                        href={
                                                          grantee.entity.href
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {grantee.entity.name}
                                                      </a>
                                                    </LinkWithHover>
                                                  </td>
                                                  <td>
                                                    {grantee.surfaceLeaseMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        )}
                                        {el.conveyanceType ===
                                          'mineral_lease_assignment' && (
                                          <table className={style.rawTable}>
                                            <thead>
                                              <tr>
                                                <th>Grantees</th>
                                                <th>Working</th>
                                                <th>ORRI</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {el.conveyances.map((grantee) => (
                                                <tr key={grantee.id}>
                                                  <td>
                                                    <LinkWithHover
                                                      kind="entity"
                                                      href={grantee.entity.href}
                                                    >
                                                      <a
                                                        href={
                                                          grantee.entity.href
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {grantee.entity.name}
                                                      </a>
                                                    </LinkWithHover>
                                                  </td>
                                                  <td>
                                                    {grantee.workingMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                  <td>
                                                    {grantee.overridingMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        )}
                                        {el.conveyanceType ===
                                          'consolidated' && (
                                          <table className={style.rawTable}>
                                            <thead>
                                              <tr>
                                                <th>Grantees</th>
                                                <th>Surface</th>
                                                <th>Mineral</th>
                                                <th>Executive</th>
                                                <th>Bonus</th>
                                                <th>Delay</th>
                                                <th>Royalty</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {el.conveyances.map((grantee) => (
                                                <tr key={grantee.id}>
                                                  <td className={style.wraptd}>
                                                    <LinkWithHover
                                                      kind="entity"
                                                      href={grantee.entity.href}
                                                    >
                                                      <a
                                                        href={
                                                          grantee.entity.href
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {grantee.entity.name}
                                                      </a>
                                                    </LinkWithHover>
                                                  </td>
                                                  <td>
                                                    {grantee.surfaceMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                  <td>
                                                    {grantee.mineralMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                  <td>
                                                    {grantee.executiveMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                  <td>
                                                    {grantee.bonusMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                  <td>
                                                    {grantee.delayMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                  <td>
                                                    {grantee.royaltyMultiplier ||
                                                      NO_DATA}
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : (
                                'unknown'
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </>
            ) : null}
          </Column>
        </Row>
      </Grid>
    </Tile>
  );
};

export { InterpretationSummary };
