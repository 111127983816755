import { Tag, TagTypeName } from 'carbon-components-react';
import style from './tag-list-item.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  number: number;
  tagType: TagTypeName;
  background?: boolean;
  className?: string;
  children: ReactNode;
}

export function TagListItem({
  number,
  tagType,
  background = true,
  className,
  children,
}: Props) {
  className = classNames(
    style.tagListItem,
    {
      [style.background]: background,
    },
    className
  );

  return (
    <li className={className}>
      <Tag type={tagType} className={style.tag}>
        {number}
      </Tag>
      <div className={style.contents}>{children}</div>
    </li>
  );
}
