import {
  TableContainer,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react';
import { DocumentIndexTable } from 'components/document-index-table';
import { getMapDefaultConfig } from 'components/map/atoms';
import { atomWithStorage } from 'jotai/utils';
import { DownloadButton } from 'components/download-button';
import { useDownloadResource } from 'lib/hooks/useDownloadFile';

import style from '../common/title-workspace-header.module.scss';
import {
  ReferenceLocationResponse,
  TitleWorkspaceResponse,
} from 'types/api-responses';
import { WorkspaceMap } from 'components/workspace-map';

const mapConfigAtom = atomWithStorage(
  'insights-workspace-document-index-map-config',
  getMapDefaultConfig()
);

const DownloadTable = ({ downloadHref }: { downloadHref: string }) => {
  const downloadOwnership = useDownloadResource(downloadHref);
  return (
    <DownloadButton
      loading={downloadOwnership.generatingDownload}
      loadingText="Building List"
      kind="primary"
      onClick={() => downloadOwnership.triggerDownload()}
    >
      Download List
    </DownloadButton>
  );
};

const Toolbar = ({ downloadHref }: { downloadHref: string }) => {
  return (
    <TableToolbar>
      <TableToolbarContent>
        <DownloadTable downloadHref={downloadHref} />
      </TableToolbarContent>
    </TableToolbar>
  );
};

const DocumentsTable = ({
  referenceLocation,
  titleWorkspace,
}: {
  referenceLocation: ReferenceLocationResponse;
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const toolbar = referenceLocation.resources.documentsDownloads?.href ? (
    <Toolbar
      downloadHref={referenceLocation.resources.documentsDownloads.href}
    />
  ) : undefined;
  return (
    <TableContainer className={style.tableToolbar}>
      <DocumentIndexTable
        toolbar={toolbar}
        referenceLocationId={referenceLocation.id}
        titleWorkspace={titleWorkspace}
      />
    </TableContainer>
  );
};

const TitleWorkspaceDocumentIndexPage = ({
  titleWorkspace,
}: {
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const referenceLocation = titleWorkspace.referenceLocation;
  return (
    <div>
      <WorkspaceMap
        mapConfigAtom={mapConfigAtom}
        referenceLocationHref={referenceLocation.href}
      >
        {({
          referenceLocation,
        }: {
          referenceLocation: ReferenceLocationResponse;
        }) => (
          <DocumentsTable
            referenceLocation={referenceLocation}
            titleWorkspace={titleWorkspace}
          />
        )}
      </WorkspaceMap>
    </div>
  );
};

export { TitleWorkspaceDocumentIndexPage };
