import { Add16, Table16, Grid16 } from '@carbon/icons-react';
import searchAtomPair from 'atoms/search-atom';
import {
  Button,
  Search,
  Tag,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  SkeletonPlaceholder,
  Grid,
  Row,
  Tile,
  ContentSwitcher,
} from 'carbon-components-react';

import { CardsGrid } from 'components/cards-grid';
import { Date } from 'components/date';
import { DevelopmentAreaWizard } from 'components/development-area-wizard';
import { EmptyState } from 'components/empty-state';
import ErrorToast from 'components/error-toast';
import { DevelopmentArea } from 'components/icons';
import { PageHeader } from 'components/page-header';
import { SearchResultCard } from 'components/search-result-card';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useMemo, useReducer, useState } from 'react';
import { DevelopmentAreaQueryResponse } from 'types/api-responses';
import { Link } from 'components/link';
import { PaginatedTable } from 'components/table/paginated-table';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import { DEFAULT_PAGE_SIZE, format, pageSizeAtom } from 'lib/ui';
import plur from 'plur';
import style from './page.module.scss';
import { atomWithStorage } from 'jotai/utils';

const isTableViewAtom = atomWithStorage(
  'development-areas-table-preference',
  false
);

const DevelopmentAreasPage = () => {
  const [isTable] = useAtom(isTableViewAtom);
  const [isOpen, toggleIsOpen] = useReducer((s) => !s, false);
  const [pageSizePreference] = useAtom(pageSizeAtom);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAtoms = useMemo(() => createSearchAtom(pageSizePreference), []);
  const setSearchPayload = useSetAtom(searchAtoms.searchAtom);

  return (
    <>
      <Helmet>
        <title>{pageTitle('Development Areas')}</title>
      </Helmet>
      <DevelopmentAreaWizard
        developmentArea={undefined}
        isOpen={isOpen}
        onClose={() => toggleIsOpen()}
        onCreated={() => {
          setSearchPayload((current) => ({
            ...current,
            text: '',
            page: 1,
          }));
          toggleIsOpen();
        }}
      />
      <PageHeader
        title="Development Areas"
        breadcrumbs={[['development_areas', 'Development Areas']]}
        actions={
          <div className={style.sortView}>
            <Button size="sm" renderIcon={Add16} onClick={() => toggleIsOpen()}>
              <span>Create Development Area</span>
            </Button>
          </div>
        }
      />
      <Grid className="bx--no-gutter" fullWidth>
        <Row className={style.filtersArea}>
          <DevAreasTypeFilter searchAtoms={searchAtoms} />
        </Row>
      </Grid>
      {isTable ? (
        <DevelopmentAreasTable searchAtoms={searchAtoms} />
      ) : (
        <DevelopmentAreasList searchAtoms={searchAtoms} />
      )}
    </>
  );
};

const DevAreasSearchField = ({
  searchAtoms: { searchAtom, resetSearchDataAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchDisplayText, setSearchDisplayText] = useState('');

  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        resetSearchData(undefined);
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              pageSize: current?.pageSize || 10,
              text: value,
              page: 1,
            }
        );
      }, 400),
    [setSearchPayload, resetSearchData]
  );

  return (
    <div className={style.devSearchField}>
      {searchResult?.error ? (
        <ErrorToast message={searchResult?.error} />
      ) : null}
      <Search
        labelText="Search"
        placeholder="Search"
        size="lg"
        autoFocus
        value={searchDisplayText}
        onChange={({ target: { value } }) => {
          setSearchDisplayText(value);
          debouncedSetSearchPayload(value);
        }}
      />
    </div>
  );
};

const DevAreasTypeFilter = ({
  searchAtoms,
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const { searchAtom } = searchAtoms;
  const [search] = useAtom(searchAtom);
  const [isTable, setIsTable] = useAtom(isTableViewAtom);
  const totalDevAreas = search.data ? (
    `${format.number(search.data.totalCount || 0)} ${plur(
      'development area',
      search.data.totalCount
    )}`
  ) : search.loading ? (
    <SkeletonPlaceholder className={style.placeholderSkeletonItem} />
  ) : null;
  return (
    <>
      <h2 className={style.totalDevAreas}>{totalDevAreas}</h2>
      <div className={style.actionsArea}>
        <DevAreasSearchField searchAtoms={searchAtoms} />
        <ContentSwitcher
          className={style.tableGridSwitcher}
          selectedIndex={isTable ? 0 : 1}
        >
          <Button
            hasIconOnly
            size="md"
            kind={isTable ? 'secondary' : 'ghost'}
            iconDescription="Table View"
            renderIcon={Table16}
            onClick={() => setIsTable(true)}
          />
          <Button
            hasIconOnly
            size="md"
            kind={!isTable ? 'secondary' : 'ghost'}
            iconDescription="Grid View"
            renderIcon={Grid16}
            onClick={() => setIsTable(false)}
          />
        </ContentSwitcher>
      </div>
    </>
  );
};

const createSearchAtom = (pageSizePreference: number) =>
  searchAtomPair<DevelopmentAreaQueryResponse>('developmentAreas', {
    text: '',
    page: 1,
    pageSize: pageSizePreference,
  });

const DevelopmentAreasList = ({
  searchAtoms: { searchAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchResult, setSearchPayload] = useAtom(searchAtom);
  const setPageSize = useSetAtom(pageSizeAtom);

  return (
    <>
      <CardsGrid<DevelopmentAreaQueryResponse>
        searchResponse={searchResult.data}
        loading={searchResult.loading}
        itemName="development area"
        onChange={(newSearch) => {
          if (newSearch.pageSize) {
            setPageSize(newSearch.pageSize);
          }
          setSearchPayload((current) => ({ ...current, ...newSearch }));
        }}
        actions={null}
        renderCard={(developmentArea) => (
          <SearchResultCard<DevelopmentAreaQueryResponse>
            Icon={DevelopmentArea}
            item={developmentArea}
            aside={
              developmentArea.isImported && (
                <Tag type="cyan" size="sm">
                  Imported
                </Tag>
              )
            }
            cardContent={
              <>
                {developmentArea.createdBy && (
                  <dl>
                    <dt className={style.label}>Created By</dt>
                    <dd className={style.content}>
                      {developmentArea.createdBy}
                    </dd>
                  </dl>
                )}
                {developmentArea.createdAt && (
                  <dl>
                    <dt className={style.label}>Created At</dt>
                    <dd className={style.content}>
                      <Date date={developmentArea.createdAt} />
                    </dd>
                  </dl>
                )}
                <dl>
                  <dt className={style.label}>Survey Area #1</dt>
                  <dd className={style.content}>
                    {developmentArea.anchorGeography?.name ??
                      'No Survey or Abstract Found'}
                  </dd>
                </dl>
              </>
            }
          />
        )}
        renderEmptyState={
          <EmptyState
            IconComponent={DevelopmentArea}
            headerText="No development areas available"
            helperText="Currently you don’t have any development areas. Create an area to start."
          />
        }
      />
    </>
  );
};

const DevelopmentAreasTable = ({
  searchAtoms: { searchAtom },
}: {
  searchAtoms: ReturnType<typeof createSearchAtom>;
}) => {
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const searchParams = searchResults.currentPayload;
  const pageSize = searchResults?.data?.pageSize || DEFAULT_PAGE_SIZE;
  const setPageSize = useSetAtom(pageSizeAtom);

  if (!searchResults.loading && !searchResults.data?.results.length) {
    return (
      <Tile>
        <EmptyState
          IconComponent={DevelopmentArea}
          headerText="No development areas available"
          helperText="Currently you don’t have any development areas. Create an area to start."
        />
      </Tile>
    );
  }

  return (
    <PaginatedTable
      pageSizes={[16, 24, 48, DEFAULT_PAGE_SIZE]}
      pageSize={pageSize}
      className={style.devAreasTable}
      page={searchParams?.page || 1}
      totalItems={searchResults?.data?.totalCount || 0}
      onPaginate={({ page, pageSize }) => {
        setPageSize(pageSize);
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              page,
              pageSize,
            }
        );
      }}
    >
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Created By</TableHeader>
          <TableHeader>Created At</TableHeader>
          <TableHeader>Survey Area #1</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults.loading ? (
          <SkeletonTableRows rows={pageSize} columns={4} />
        ) : searchResults ? (
          searchResults.data?.results.map((el) => {
            return (
              <TableRow key={el.id}>
                <TableCell className={style.linkArea}>
                  {el.href ? (
                    <>
                      <Link to={el.href} target="_blank">
                        {el.name}
                      </Link>
                      {el.isImported && (
                        <Tag type="cyan" size="sm">
                          Imported
                        </Tag>
                      )}
                    </>
                  ) : (
                    el.name
                  )}
                </TableCell>
                <TableCell>{el.createdBy}</TableCell>
                <TableCell>
                  <Date date={el.createdAt} />
                </TableCell>
                <TableCell>
                  {el.anchorGeography?.name ?? 'No Survey or Abstract Found'}
                </TableCell>
              </TableRow>
            );
          })
        ) : null}
      </TableBody>
    </PaginatedTable>
  );
};

export { DevelopmentAreasPage };
