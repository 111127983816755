import { InlineNotification } from 'carbon-components-react';
import { Atom, useAtomValue } from 'jotai';

const FormErrorList = ({
  errorsAtom,
  testId = 'error-section',
}: {
  errorsAtom: Atom<string[]>;
  testId?: string;
}) => {
  const collectedErrors = useAtomValue(errorsAtom);
  return (
    <div data-testid={testId}>
      {collectedErrors.map((el, i) => {
        return (
          <InlineNotification
            lowContrast
            key={`${el}-${i}`}
            kind="error"
            title={el}
          />
        );
      })}
    </div>
  );
};

export { FormErrorList };
