import { InlineLoading } from 'carbon-components-react';
import { useAtomValue } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import { ApiResponse } from 'types/api-responses';

const ResourceFetcher = <Response extends ApiResponse | ApiResponse[]>({
  component,
  href,
  setData,
}: {
  href: string;
  component: (props: { data: Response }) => JSX.Element | null;
  setData?: (data: Response) => void;
}) => {
  const resourceAtom = useMemo(
    () => createResourceAtom<Response>(href),
    [href]
  );
  const resource = useAtomValue(resourceAtom);

  if (resource.loading) return <InlineLoading />;
  if (!resource.data) return null;
  if ('error' in resource.data) return <>Error loading resource</>;
  if (setData) setData(resource.data);

  return <>{component({ data: resource.data })}</>;
};

export { ResourceFetcher };
