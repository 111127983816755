import { Suspense, useMemo } from 'react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, selectAtom } from 'jotai/utils';
import {
  ComboBox,
  Modal,
  ProgressIndicator,
  ProgressStep,
  TextInput,
  Button,
  Grid,
  Row,
  Column,
  Tag,
  Loading,
} from 'carbon-components-react';

import searchAtomPair from 'atoms/search-atom';
import {
  createMutationAtom,
  createResourceFormAtom,
  FormField,
  initializeForm,
} from 'atoms/create-resource-atom';

import { Copy } from 'components/copy';
import { EmptyState } from 'components/empty-state';
import { FormRow, FormSection } from 'components/forms';
import { PolygonArea, CenteredMap } from 'components/map';
import NoMapSvg from 'images/srp-illustration-no-map.svg';

import {
  ReferenceLocationResponse,
  TitleWorkspaceResponse,
} from 'types/api-responses';

import style from './create-workspace.module.scss';
import { ApiPayloads } from 'types/api-payloads';
import { debounce } from 'lodash';
import { getMapDefaultConfig } from 'components/map/atoms';
import { createResourceAtom } from 'lib/atoms';
import { ProvideRootResource } from 'components/hydrate-atoms';

interface Props {
  onClose: () => void;
  onCreated: () => void;
}

interface FormState {
  name: FormField<string>;
  referenceLocation: FormField<ReferenceLocationResponse | null>;
}

type TitleWorkspaceErrors = ApiPayloads['titleWorkspaces']['error'];

const { dirtyFieldsAtom, formAtom } = createResourceFormAtom<
  FormState,
  TitleWorkspaceErrors
>(
  initializeForm<FormState>(
    {
      name: '',
      referenceLocation: null,
    },
    'updated'
  )
);

const isValidPayloadAtom = atom(
  (get) =>
    get(formAtom).name.value.length > 0 &&
    get(formAtom).referenceLocation.value !== null
);

const createAtom = createMutationAtom<
  TitleWorkspaceResponse,
  'titleWorkspaces'
>();
const stepAtom = atom(0);

const createUrl = '/title_workspaces';
const validateUrl = '/validations/title_workspaces';

const CreateWorkspace = ({ onClose, onCreated }: Props) => {
  const [step, setStep] = useAtom(stepAtom);
  const form = useAtomValue(formAtom);

  return (
    <Modal
      open={true}
      size="lg"
      passiveModal={true}
      modalHeading="Create Title Workspace"
      className={style.createTitleModal}
      onRequestClose={() => onClose()}
    >
      <Grid condensed fullWidth>
        <Row className={style.stretch}>
          <Column sm={4} lg={2}>
            <div className={style.steps}>
              <ProgressIndicator currentIndex={step} vertical>
                <ProgressStep label="Create Area" />
                <ProgressStep label="Review" />
              </ProgressIndicator>
            </div>
          </Column>
          <Column sm={6} lg={7} className={style.content}>
            <div className={style.main}>
              {step === 0 ? <StepOne /> : null}
              {step === 1 ? <StepTwo /> : null}
            </div>
          </Column>
          <Column sm={6} lg={7} className={style.mapContainer}>
            {!form.referenceLocation.value ? (
              <EmptyState
                icon={NoMapSvg}
                headerText="No Survey Areas Selected"
                helperText="Please select a survey area to get started."
                className={style.emptyMessage}
              />
            ) : (
              <Suspense fallback={<Loading small />}>
                <MapSelection />
              </Suspense>
            )}
          </Column>
        </Row>
        <Row className={style.btnActions}>
          <Column lg={{ span: 7, offset: 2 }}>
            <div className={style.btnAlign}>
              <Button kind="ghost" size="lg" onClick={onClose}>
                Cancel
              </Button>
              <Button
                kind="secondary"
                size="lg"
                disabled={step !== 1}
                onClick={() => {
                  setStep((c) => c - 1);
                }}
              >
                Back
              </Button>
              {step === 0 ? <NextButton /> : null}
              {step === 1 ? <SaveButton onCreated={onCreated} /> : null}
            </div>
          </Column>
        </Row>
      </Grid>
    </Modal>
  );
};

const { searchAtom, resetSearchDataAtom } =
  searchAtomPair<ReferenceLocationResponse>('referenceLocations');

const StepOne = () => {
  const [form, setFormValue] = useAtom(formAtom);
  const [query, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const { result } = useAtomValue(createAtom);
  const isError = result?.type === 'error';

  const debouncedSetPayload = useMemo(
    () =>
      debounce((value) => {
        resetSearchData(undefined);
        setSearchPayload(() => ({
          text: value,
          page: 1,
          pageSize: 16,
        }));
      }, 400),
    [resetSearchData, setSearchPayload]
  );

  return (
    <>
      <FormSection>
        <Copy
          heading="Title Workspace Name"
          subheading="What would you like to name the title workspace?"
        />
        <FormRow>
          <TextInput
            labelText="Name"
            autoFocus
            id="create-title-workspace-name"
            value={form.name.value}
            onChange={({ target: { value } }) => {
              setFormValue({ field: 'name', value });
            }}
            invalid={
              isError &&
              form.name.state === 'dirty' &&
              !!result.data?.errors.name
            }
            invalidText={
              isError && form.name.state === 'dirty' && result.data?.errors.name
            }
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <Copy
          heading="Define Area"
          subheading="What is the area this workspace covers?"
        >
          <p>
            Use survey area to define the area that the title workspace covers.
          </p>
        </Copy>
        <ComboBox
          id="reference-location"
          titleText="Survey Area"
          items={query?.data?.results || []}
          placeholder=""
          itemToString={(data) => {
            return data?.name || '';
          }}
          selectedItem={form.referenceLocation.value}
          onChange={({ selectedItem }) => {
            setFormValue({
              field: 'referenceLocation',
              value: selectedItem ?? null,
            });
          }}
          onInputChange={(text) => {
            resetSearchData(undefined);
            debouncedSetPayload(text);
          }}
          invalid={
            isError &&
            form.referenceLocation.state === 'dirty' &&
            !!result.data?.errors.referenceLocation
          }
          invalidText={
            isError &&
            form.referenceLocation.state === 'dirty' &&
            result.data?.errors.referenceLocation
          }
        />
      </FormSection>
    </>
  );
};

const StepTwo = () => {
  const form = useAtomValue(formAtom);
  return (
    <>
      <Copy heading="Review" subheading="How does everything look?" />
      <div className={style.outputTile}>
        <p className={style.outputSubTitle}>Name</p>
        <p className={style.outputValue}>{form.name.value}</p>
        <p className={style.outputSubTitle}>Section</p>
        <div className={style.outputSection}>
          <Tag type="blue" size="sm">
            1
          </Tag>
          {form.referenceLocation?.value?.name}
        </div>
      </div>
    </>
  );
};

const SaveButton = ({ onCreated }: Pick<Props, 'onCreated'>) => {
  const form = useAtomValue(formAtom);
  const runRequest = useSetAtom(createAtom);
  return (
    <>
      <Button
        kind="primary"
        size="lg"
        onClick={() => {
          if (form.referenceLocation.value) {
            runRequest({
              type: 'POST',
              url: createUrl,
              data: {
                name: form.name.value,
                referenceLocationId: form.referenceLocation.value.id,
              },
              onSuccess: onCreated,
            });
          }
        }}
      >
        Save
      </Button>
    </>
  );
};

const selectedLocationAtom = selectAtom(
  formAtom,
  (form) => form.referenceLocation.value
);

const mapConfigAtom = atomWithStorage(
  'insights-create-workspace-map-config',
  getMapDefaultConfig()
);

const MapSelection = () => {
  const selectedLocation = useAtomValue(selectedLocationAtom);
  const locationAtom = useMemo(
    () => createResourceAtom<ReferenceLocationResponse>(selectedLocation?.href),
    [selectedLocation?.href]
  );
  const area = useAtomValue(locationAtom);

  if (area.data && 'error' in area.data) return null;
  if (!area.data) return null;

  if (!area.data.geometry) {
    return null;
  }

  return (
    <CenteredMap geometry={area.data.geometry} mapConfigAtom={mapConfigAtom}>
      <PolygonArea geometry={area.data.geometry} id={area.data.id} />
    </CenteredMap>
  );
};

const NextButton = () => {
  const isValidPayload = useAtomValue(isValidPayloadAtom);
  const form = useAtomValue(formAtom);
  const runRequest = useSetAtom(createAtom);
  const setErrors = useSetAtom(dirtyFieldsAtom);
  const setStep = useSetAtom(stepAtom);

  return (
    <Button
      kind="primary"
      size="lg"
      disabled={!isValidPayload}
      onClick={() => {
        if (form.referenceLocation.value) {
          runRequest({
            type: 'POST',
            url: validateUrl,
            data: {
              name: form.name.value,
              referenceLocationId: form.referenceLocation.value.id,
            },
            onError: (error) => {
              if (error) {
                setErrors(error);
              }
            },
            onSuccess: () => {
              setStep((c) => c + 1);
            },
          });
        }
      }}
    >
      Next
    </Button>
  );
};

const CreateWorkspaceWithProvider = (props: Props) => {
  return (
    <ProvideRootResource>
      <CreateWorkspace {...props} />
    </ProvideRootResource>
  );
};

export default CreateWorkspaceWithProvider;
