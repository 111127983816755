import { Modal } from 'carbon-components-react';
import { useNewReleaseNotifier } from './hooks';
import style from './new-release-modal.module.scss';

const NewReleaseModal = () => {
  const [newRelease, rejectNewRelease] = useNewReleaseNotifier();

  return (
    <Modal
      open={newRelease}
      modalHeading="Please Refresh Your Browser"
      primaryButtonText="Refresh Now"
      secondaryButtonText="Do Not Refresh"
      onRequestClose={() => {
        rejectNewRelease(undefined);
      }}
      onRequestSubmit={() => {
        location.reload();
      }}
    >
      <div className={style.spacedParagraphs}>
        <p>
          We&rsquo;ve made some changes to Insights since you were last active.
        </p>
        <p>Please refresh your page in order to use the latest version.</p>
        <p>
          If you currently have unsaved changes, click &ldquo;Do not
          refresh&rdquo; and save your changes before manually refreshing.
        </p>
      </div>
    </Modal>
  );
};

export { NewReleaseModal };
