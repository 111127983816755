import { TooltipDefinition } from 'carbon-components-react';
import { DragHorizontal16 } from '@carbon/icons-react';
import {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import style from './draggable-header-icon.module.scss';

interface DraggableHeaderIconIconProps {
  attributes: DraggableAttributes;
  listeners: DraggableSyntheticListeners;
}

export const DraggableHeaderIcon = ({
  attributes,
  listeners,
}: DraggableHeaderIconIconProps) => {
  return (
    <div className={style.sortIcon}>
      <TooltipDefinition
        className={style.toolTip}
        direction="top"
        align="center"
        tooltipText="Move"
        {...attributes}
        {...listeners}
      >
        <DragHorizontal16 />
      </TooltipDefinition>
    </div>
  );
};
