import { useEffect } from 'react';
import { isEqual } from 'lodash';

const usePinnedColumnReordering = (
  columnPinning: { left?: string[] },
  columnOrder: string[],
  setColumnOrder: (newOrder: string[]) => void
) => {
  useEffect(() => {
    const pinnedColumnIds = columnPinning.left ?? [];
    const unpinnedColumnIds = columnOrder.filter(
      (id) => !pinnedColumnIds.includes(id)
    );
    const newColumnOrder = [...pinnedColumnIds, ...unpinnedColumnIds];

    if (!isEqual(newColumnOrder, columnOrder)) {
      setColumnOrder(newColumnOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnPinning]);
};

export default usePinnedColumnReordering;
