import { memo, useMemo } from 'react';
import { TextInput } from 'carbon-components-react';
import { useAtom, useAtomValue } from 'jotai';

import { FormRow } from 'components/forms';
import { initEmptyForm } from './utils';

import { CapacityForm as CapacityFormType, CapacityFormAtoms } from './types';

import { DeleteRowButton } from '../delete-row-button';
import { FormRowManager } from '../form-row-manager';
import { getError } from 'atoms/create-resource-atom';
import { ReviewSectionCheckbox } from '../review-section-checkbox';
import { createUpdateTrackerAtom } from '../atoms';
import style from './capacity.module.scss';
import { EntityAutocompleteWithCreationModal } from 'components/entity-autocomplete-with-creation-modal';

interface Props {
  atomIdx: number;
  atoms: CapacityFormAtoms;
  onDelete?: (idx: number) => void;
}

const CapacityForm = ({ atomIdx, onDelete, atoms }: Props) => {
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const status = useAtomValue(atoms.status);

  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setField({
      field: 'predicate',
      value: value || '',
    });
  };

  return (
    <FormRow toTrash={status === 'trash'}>
      <div className={style.capacityRow}>
        <EntityAutocompleteWithCreationModal
          id={`${atomIdx}-entity`}
          titleText="Who (Entity)"
          placeholder=""
          status={status}
          selectedItem={fields.subject.value}
          onCreationSuccess={(entity) => {
            setField({
              field: 'subject',
              value: entity,
            });
          }}
          onChangeSuccess={(selectedItem) => {
            setField({
              field: 'subject',
              value: selectedItem ?? null,
            });
          }}
          invalid={!!getError<CapacityFormType>(fields, 'subject')}
          invalidText={getError<CapacityFormType>(fields, 'subject')}
          disabled={status === 'trash'}
          light
        />
        <span className={style.semanticText}>is the</span>
        <TextInput
          id={`${atomIdx}-predicate`}
          labelText={'Capacity'}
          placeholder={''}
          value={fields.predicate.value || ''}
          onChange={handleCapacityChange}
          invalid={!!getError<CapacityFormType>(fields, 'predicate')}
          invalidText={getError<CapacityFormType>(fields, 'predicate')}
          disabled={status === 'trash'}
          light
        />
        <span className={style.semanticText}>of</span>
        <EntityAutocompleteWithCreationModal
          id={`${atomIdx}-capacity-entity`}
          titleText="Target (Entity)"
          status={status}
          placeholder=""
          selectedItem={fields.object.value}
          onCreationSuccess={(entity) => {
            setField({
              field: 'object',
              value: entity,
            });
          }}
          onChangeSuccess={(selectedItem) => {
            setField({
              field: 'object',
              value: selectedItem ?? null,
            });
          }}
          invalid={!!getError<CapacityFormType>(fields, 'object')}
          invalidText={getError<CapacityFormType>(fields, 'object')}
          disabled={status === 'trash'}
          light
        />
        <DeleteRowButton
          statusAtom={atoms.status}
          atomIdx={atomIdx}
          onDelete={onDelete}
        />
      </div>
    </FormRow>
  );
};

const CapacityMemo = memo(CapacityForm);

type ManagedFormType = {
  field: 'capacities';
  value: CapacityFormAtoms[];
};
const CapacityList = () => {
  const capacitiesUpdateAtom = useMemo(
    () => createUpdateTrackerAtom('capacities'),
    []
  );
  return (
    <>
      <FormRowManager<ManagedFormType>
        MemoedComponent={CapacityMemo}
        field={'capacities'}
        label="Add capacity"
        initEmptyForm={initEmptyForm}
      />
      <ReviewSectionCheckbox
        updateTrackingAtom={capacitiesUpdateAtom}
        field={'capacitiesReviewed'}
        label="Capacities Reviewed"
        helpMessage="Editing any capacities will change the review status to un reviewed"
      />
    </>
  );
};

export { CapacityList };
