import style from './icons.module.scss';
import classNames from 'classnames';
import { IconProps } from './types';

interface Props extends IconProps {
  hasBackground?: boolean;
  large?: boolean;
  medium?: boolean;
}

export function Well({
  hasBackground = true,
  large,
  medium,
  className,
}: Props) {
  if (hasBackground) {
    return (
      <div className={classNames(style.background, style.well, className)}>
        <WellIcon
          className={classNames(style.icon, style.well, {
            [style.large]: large,
            [style.medium]: medium,
          })}
        />
      </div>
    );
  } else {
    return (
      <WellIcon
        className={classNames(style.icon, style.well, className, {
          [style.large]: large,
          [style.medium]: medium,
        })}
      />
    );
  }
}

const WellIcon = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 26 27"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(-12 -11)" fillRule="evenodd">
        <g transform="translate(12 11)">
          <path d="M25.5 13v12.365H24V13.593l.611-.249.89-.344z" />
          <path
            fillRule="nonzero"
            d="M24.358 4.091L21.713 0 17 1.918l.966 4.773 4.206 5.68 3.145-1.28-.959-7zm-3.23-2.233l1.79 2.767.754 5.516-.987.401-3.315-4.475-.653-3.228 2.411-.981zM3.5 17v9H2v-9z"
          />
          <path
            fillRule="nonzero"
            d="M11.34 10.5l-4.36 1.507V10.58l3.354-1.205c.235.455.583.843 1.008 1.125zm7.768-4.277l.463 1.426-3.72 1.29C15.946 8.642 16 8.327 16 8c0-.218-.023-.431-.068-.636l3.176-1.141z"
          />
          <path
            fillRule="nonzero"
            d="M17.891 25.357L18 26H8l.109-.643h9.782zM10.729 9.96c.339.392.777.696 1.276.871L9.702 24.357H8.279l2.45-14.397zm4.547-.006l2.445 14.403h-1.425L13.998 10.83c.5-.176.94-.481 1.278-.876z"
          />
          <path
            strokeWidth={1.5}
            fill="none"
            stroke="currentColor"
            d="M.75 10.75h4.5v4.5H.75z"
          />
          <path
            fillRule="evenodd"
            d="M17 22.358v1.5H9.5v-1.5zm-1-4.5v1.5h-6v-1.5zm-.5-4.5v1.5H11v-1.5zm3.5 12v1.5H7v-1.5z"
          />
          <circle cx={13} cy={8} r={2} />
        </g>
      </g>
    </svg>
  );
};
