import {
  Button,
  Modal,
  SkeletonText,
  Tab,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  Tile,
  Grid,
  Column,
  Row,
  TableCell,
  InlineNotification,
} from 'carbon-components-react';
import { DestroyConfirmation } from 'components/destroy-confirmation';
import { Section } from 'components/section';
import { StatusIndicator } from 'components/status-indicator';
import { PaginatedTable } from 'components/table/paginated-table';
import { RemovableRow } from 'components/table/removable-row';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { usePagination } from 'lib/hooks/usePagination';
import { useMemo, useReducer, useRef, useState, VFC } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocumentAtom, useInterpretationsAtom } from '../common/atoms';
import { DocumentHeader } from '../common/header';
import {
  getLocationGeometries,
  getReferenceLocationIds,
  removedAreasAtom,
  removedPartiesAtom,
  runMutationAtom,
  useAreasAtom,
  usePartiesAtom,
  useReferencedDocumentsAtom,
  useTransactionPartiesAtom,
} from './atoms';
import {
  ERROR_TEXT,
  extractErrorMessage,
  markAsDuplicate,
  resetRowSelectionAndError,
  toSentence,
} from './utils';
import style from './page.module.scss';
import { Map, PolygonArea } from 'components/map';
import { useCalculateMapDimensions } from 'components/map/utils';
import { Buttons } from 'components/buttons';
import { CopyFile32 } from '@carbon/icons-react';
import { Link } from 'components/link';
import {
  createBoundingBoxAtom,
  getMapDefaultConfig,
} from 'components/map/atoms';
import { atomWithStorage } from 'jotai/utils';
import { resourcesAtom } from 'atoms/root';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
  TransactionPartyResponse,
} from 'types/api-responses';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import { capitalizeFirstLetter } from 'utils/strings';

const PageContent = ({
  document,
  getDocument,
}: {
  document: DocumentResponse;
  getDocument: () => Promise<void>;
}) => {
  const interpretationsAtom = useInterpretationsAtom(
    document.resources.interpretations.href
  );
  const interpretations = useAtomValue(interpretationsAtom);
  if (interpretations.data && 'error' in interpretations.data) {
    return (
      <InlineNotification
        kind="error"
        title={interpretations.data.error}
        lowContrast
      />
    );
  }

  return (
    <>
      <Section header="Areas Involved" className={style.container}>
        <Grid fullWidth condensed className="bx--no-gutter">
          <Row className={style.mapRow}>
            <Column sm={10} lg={10} className={style.detail}>
              <AreasInvolved
                interpretations={interpretations.data}
                document={document}
              />
            </Column>
            <Column sm={6} lg={6} className={style.colMap}>
              <AreasMap document={document} />
            </Column>
          </Row>
        </Grid>
      </Section>
      <Section header="Parties Involved">
        <PartiesInvolved
          interpretations={interpretations.data}
          document={document}
        />
      </Section>
      <Section header="Referenced Documents">
        <ReferencedDocuments
          interpretations={interpretations.data}
          document={document}
        />
        <MarkAsDuplicate document={document} getDocument={getDocument} />
      </Section>
    </>
  );
};

const AreasInvolved = ({
  interpretations,
  document,
}: {
  interpretations: DocumentInterpretationResponse[] | undefined;
  document: DocumentResponse;
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [error, setError] = useState<string | undefined>(undefined);
  const [mutation, runMutation] = useAtom(runMutationAtom);
  const areasAtom = useAreasAtom(interpretations, document.locations);
  const areas = useAtomValue(areasAtom);
  const setRemovedParties = useSetAtom(removedAreasAtom);
  const pageSize = 10;
  const { page, slicedItems, onPaginate } = usePagination<typeof areas[0]>(
    areas,
    pageSize
  );

  const area = areas[selectedRowIndex];

  return (
    <>
      <DestroyConfirmation
        error={error}
        open={selectedRowIndex >= 0}
        primaryButtonDisabled={mutation.loading}
        onRequestClose={() => {
          resetRowSelectionAndError(setSelectedRowIndex, setError);
        }}
        onRequestSubmit={() => {
          runMutation({
            url: area.href,
            type: 'DELETE',
            data: null,
            onSuccess: () => {
              setRemovedParties((current) => [...current, area.id]);
              resetRowSelectionAndError(setSelectedRowIndex, setError);
            },
            onError: (response) => {
              const errorMessage = response
                ? extractErrorMessage(response)
                : ERROR_TEXT;
              setError(errorMessage);
            },
          });
        }}
      />
      <PaginatedTable
        pageSizes={[10, 20, 30]}
        pageSize={pageSize}
        page={page}
        totalItems={areas.length}
        onPaginate={onPaginate}
        data-testid="applicable-areas-table"
      >
        <TableHead>
          <TableRow>
            <TableHeader>Applicable Areas</TableHeader>
            <TableHeader>Quarter Calls</TableHeader>
            <TableHeader colSpan={2}>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {slicedItems.map((el, i) => {
            return (
              <RemovableRow
                onRemove={(index) => {
                  setSelectedRowIndex(index);
                }}
                markedForDestruction={i === selectedRowIndex}
                index={i}
                key={el.id}
                removable={el.interpretationIndices?.length === 0}
              >
                <TableCell>{el.name}</TableCell>
                <TableCell>{el.quarterCalls}</TableCell>
                <TableCell>
                  {!el.interpretationIndices ? (
                    <SkeletonText />
                  ) : (
                    <Status interpretationIndices={el.interpretationIndices} />
                  )}
                </TableCell>
              </RemovableRow>
            );
          })}
        </TableBody>
      </PaginatedTable>
    </>
  );
};

const PartiesInvolved = ({
  interpretations,
  document,
}: {
  interpretations: DocumentInterpretationResponse[] | undefined;
  document: DocumentResponse;
}) => {
  const transactionPartiesAtom = useTransactionPartiesAtom(
    document.resources.transactionParties.href
  );
  const transactionParties = useAtomValue(transactionPartiesAtom);
  return (
    <div className={style.tabs}>
      <Tabs className={style.tabsContained}>
        <Tab label="Text based" key="text-based-tab">
          <Tile className={style.tileTab}>
            <TextBasedParties
              interpretations={interpretations}
              document={document}
            />
          </Tile>
        </Tab>
        <Tab label="Entity based" key="entity-based-tab">
          <Tile className={style.tileTab}>
            {transactionParties.data && 'error' in transactionParties.data ? (
              <InlineNotification
                kind="error"
                title={transactionParties.data.error}
                lowContrast
              />
            ) : (
              <EntityBasedParties parties={transactionParties.data || []} />
            )}
          </Tile>
        </Tab>
      </Tabs>
    </div>
  );
};

const EntityBasedParties = ({
  parties,
}: {
  parties: TransactionPartyResponse[];
}) => {
  const pageSize = 10;
  const { page, slicedItems, onPaginate } =
    usePagination<TransactionPartyResponse>(parties, pageSize);

  if (!parties.length) {
    return <p>There are no entity based parties.</p>;
  }

  return (
    <PaginatedTable
      pageSizes={[10, 20, 30]}
      pageSize={pageSize}
      page={page}
      totalItems={parties.length}
      onPaginate={onPaginate}
      data-testid="entity-based-table"
    >
      <TableHead>
        <TableRow>
          <TableHeader>Applicable Parties</TableHeader>
          <TableHeader>ID</TableHeader>
          <TableHeader>Capacity</TableHeader>
          <TableHeader colSpan={2}>Status</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {slicedItems.map((el) => {
          return (
            <TableRow key={el.entity.id}>
              <TableCell>
                <Link to={el.entity.href}>{el.entity.name}</Link>
              </TableCell>
              <TableCell>{el.entity.id}</TableCell>
              <TableCell>{capitalizeFirstLetter(el.role)}</TableCell>
              <TableCell>
                <Status interpretationIndices={el.interpretationIndices} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </PaginatedTable>
  );
};

const TextBasedParties = ({
  interpretations,
  document,
}: {
  interpretations: DocumentInterpretationResponse[] | undefined;
  document: DocumentResponse;
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [error, setError] = useState<string | undefined>(undefined);
  const [mutation, runMutation] = useAtom(runMutationAtom);
  const partiesAtom = usePartiesAtom(
    interpretations,
    document.grantors,
    document.grantees
  );
  const parties = useAtomValue(partiesAtom);
  const setRemovedParties = useSetAtom(removedPartiesAtom);
  const pageSize = 10;
  const { page, slicedItems, onPaginate } = usePagination<typeof parties[0]>(
    parties,
    pageSize
  );

  const party = parties[selectedRowIndex];

  return (
    <>
      <DestroyConfirmation
        error={error}
        open={selectedRowIndex >= 0}
        primaryButtonDisabled={mutation.loading}
        onRequestClose={() => {
          resetRowSelectionAndError(setSelectedRowIndex, setError);
        }}
        onRequestSubmit={() => {
          runMutation({
            url: party.href,
            type: 'DELETE',
            data: null,
            onSuccess: () => {
              setRemovedParties((current) => [...current, party.entityId]);
              resetRowSelectionAndError(setSelectedRowIndex, setError);
            },
            onError: (response) => {
              const errorMessage = response
                ? extractErrorMessage(response)
                : ERROR_TEXT;
              setError(errorMessage);
            },
          });
        }}
      />
      <PaginatedTable
        pageSizes={[10, 20, 30]}
        pageSize={pageSize}
        page={page}
        totalItems={parties.length}
        onPaginate={onPaginate}
        data-testid="text-based-table"
      >
        <TableHead>
          <TableRow>
            <TableHeader>Applicable Parties</TableHeader>
            <TableHeader>Capacity</TableHeader>
            <TableHeader colSpan={2}>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {slicedItems.map((el, i) => {
            return (
              <RemovableRow
                onRemove={(index) => {
                  setSelectedRowIndex(index);
                }}
                markedForDestruction={i === selectedRowIndex}
                index={i}
                key={el.entityId}
                removable={el.interpretationIndices?.length === 0}
              >
                <TableCell>{el.name}</TableCell>
                <TableCell>{el.type}</TableCell>
                <TableCell>
                  {!el.interpretationIndices ? (
                    <SkeletonText />
                  ) : (
                    <Status interpretationIndices={el.interpretationIndices} />
                  )}
                </TableCell>
              </RemovableRow>
            );
          })}
        </TableBody>
      </PaginatedTable>
    </>
  );
};

const ReferencedDocuments = ({
  interpretations,
  document,
}: {
  interpretations: DocumentInterpretationResponse[] | undefined;
  document: DocumentResponse;
}) => {
  const referencedDocumentsAtom = useReferencedDocumentsAtom(
    interpretations,
    document.referencedDocuments
  );
  const referencedDocuments = useAtomValue(referencedDocumentsAtom);
  const pageSize = 10;
  const { page, slicedItems, onPaginate } = usePagination<
    typeof referencedDocuments[0]
  >(referencedDocuments, pageSize);

  return (
    <PaginatedTable
      pageSizes={[10, 20, 30]}
      pageSize={pageSize}
      page={page}
      totalItems={referencedDocuments.length}
      onPaginate={onPaginate}
      data-testid="referenced-docs-table"
    >
      <TableHead>
        <TableRow>
          <TableHeader>Document Identifier</TableHeader>
          <TableHeader>Book Type</TableHeader>
          <TableHeader>Document Type</TableHeader>
          <TableHeader colSpan={2}>Status</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {slicedItems.map((el) => {
          return (
            <TableRow key={el.id}>
              <TableCell>{el.title}</TableCell>
              <TableCell>{el.bookType}</TableCell>
              <TableCell>{el.documentType}</TableCell>
              <TableCell>
                {!el.interpretationIndices ? (
                  <SkeletonText />
                ) : (
                  <Status interpretationIndices={el.interpretationIndices} />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </PaginatedTable>
  );
};

const mapConfigAtom = atomWithStorage(
  'insights-document-map-config',
  getMapDefaultConfig()
);

const AreasMap = ({ document }: { document: DocumentResponse }) => {
  const containerRef = useRef(null);
  const resources = useAtomValue(resourcesAtom);

  const referenceLocationIds = useMemo(
    () => getReferenceLocationIds(document),
    [document]
  );
  const geometries = useMemo(() => getLocationGeometries(document), [document]);

  const boundingBoxAtom = useMemo(
    () =>
      createBoundingBoxAtom(
        referenceLocationIds,
        resources?.boundingBoxes.href
      ),
    [referenceLocationIds, resources?.boundingBoxes.href]
  );
  const boundingBox = useAtomValue(boundingBoxAtom);
  const { latitude, longitude, zoom } = useCalculateMapDimensions({
    points: boundingBox.length ? boundingBox : undefined,
    containerRef,
  });

  const hoveredReferenceLocations =
    geometries.map((location) => ({
      id: location.id,
      name: location.name,
      quarterCalls: location.quarterCalls,
    })) || [];

  return (
    <div ref={containerRef}>
      <Map
        className={style.map}
        longitude={longitude}
        latitude={latitude}
        zoom={zoom}
        showControls
        showMapFilters
        mapConfigAtom={mapConfigAtom}
        geographies={hoveredReferenceLocations}
      >
        {geometries.map((el, i) => (
          <PolygonArea
            geometry={el.geometry}
            id={el.id}
            key={`${el.id}-${i}`}
          />
        ))}
      </Map>
    </div>
  );
};

const Status: VFC<{ interpretationIndices: number[] }> = ({
  interpretationIndices,
}) => {
  const state = interpretationIndices.length ? 'confirmed' : 'notConfirmed';

  return (
    <StatusIndicator state={state}>
      {state === 'confirmed'
        ? `Interpretation ${toSentence(interpretationIndices)}`
        : 'Not Confirmed'}
    </StatusIndicator>
  );
};

const MarkAsDuplicate = ({
  document,
  getDocument,
}: {
  document: DocumentResponse;
  getDocument: () => Promise<void>;
}) => {
  const [open, toggleOpen] = useReducer((s) => !s, false);

  if (document && document.duplicate) {
    return null;
  }

  return (
    <>
      <Buttons justify="right">
        <Button
          renderIcon={CopyFile32}
          kind="danger--ghost"
          onClick={toggleOpen}
        >
          Mark As Duplicate Document
        </Button>
      </Buttons>
      <Modal
        open={open}
        modalHeading="Mark as Duplicate Document"
        danger
        primaryButtonText="Yes, Mark Document"
        secondaryButtonText="Cancel"
        onRequestSubmit={async () => {
          if (document) {
            await markAsDuplicate(document.href);
            getDocument();
            toggleOpen();
          }
        }}
        onRequestClose={toggleOpen}
      >
        Are you sure you want to mark as a duplicate? This action cannot be
        undone and all of the document data will be removed from Insights.
      </Modal>
    </>
  );
};

const DocumentOverviewPage = () => {
  const location = useLocation();
  const [resource, id] = location.pathname.split('/').filter((el) => el);
  const documentHref = `/${resource}/${id}`;
  const documentAtom = useDocumentAtom(documentHref);
  const [document, getDocument] = useAtom(documentAtom);

  if (document.data && 'error' in document.data) {
    return (
      <InlineNotification
        kind="error"
        title={document.data.error}
        lowContrast
      />
    );
  }
  if (!document.data) return null;

  return (
    <DocumentHeader document={document.data} getDocument={getDocument}>
      <Helmet>
        <title>{pageTitle(`${document.data.documentTitle}`)}</title>
      </Helmet>
      <PageContent document={document.data} getDocument={getDocument} />
    </DocumentHeader>
  );
};

export { DocumentOverviewPage };
