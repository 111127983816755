import {
  SkeletonText,
  SkeletonPlaceholder,
  DataTableSkeleton,
  TabsSkeleton,
} from 'carbon-components-react';
import style from './output_skeleton.module.scss';

export function OutputSkeleton() {
  return (
    <>
      <div className={style.container}>
        <div className={style.leftCol}>
          <div className={style.topbar}>
            <SkeletonText width="20px" />
            <SkeletonText width="180px" />
          </div>
          <hr />
          <div className={style.topContent}>
            <SkeletonText heading />
            <div className={style.titleLines}>
              <SkeletonText paragraph width="130px" />
              <SkeletonText paragraph width="400px" />
            </div>
          </div>
        </div>
        <div className={style.rightCol}>
          <SkeletonPlaceholder className={style.map} />
        </div>
      </div>
      <TabsSkeleton />
      <DataTableSkeleton showHeader={false} columnCount={3} />
    </>
  );
}
