import { Link, Row, Column, Button, Grid } from 'carbon-components-react';
import { WellsResponse } from 'types/api-responses';
import { Date } from 'components/date';
import { Add16 } from '@carbon/icons-react';
import { addWellFnAtom, usedWellsAtom } from './atoms';
import { useAtomValue, useAtom } from 'jotai';
import { SearchCard } from 'components/search-card';
import { Well } from 'components/icons/well';

import style from 'components/search-card/search-card.module.scss';
import { Optional } from 'components/optional';

interface Props {
  well: WellsResponse;
}

export const WellCard = ({ well }: Props) => {
  const [usedWells, setUsedWells] = useAtom(usedWellsAtom);
  const addWellFn = useAtomValue(addWellFnAtom);

  return (
    <SearchCard icon={<Well />}>
      <header className={style.header}>
        <div>
          <h3 className={style.secondaryHeader}>{well.uwi}</h3>
          <h2>
            <Link
              className={style.primaryHeader}
              href={well.href}
              onClick={() => void 0}
              target="_blank"
              visited={true}
            >
              <Optional>
                {well.leaseName} #{well.wellNumber}
              </Optional>
            </Link>
          </h2>
        </div>
        {addWellFn.fn && (
          <Button
            iconDescription="Add Well Reference"
            size="sm"
            renderIcon={Add16}
            disabled={usedWells.includes(well.id)}
            onClick={() => {
              addWellFn.fn?.(well);
              setUsedWells((c) => [...c, well.id]);
            }}
          >
            Add
          </Button>
        )}
      </header>
      <Grid className="bx--no-gutter" fullWidth>
        <Row className={style.row}>
          <Column>
            <section>
              <h3 className={style.title}>Well Status: </h3>
              <Optional>{well.status}</Optional>
            </section>
          </Column>
          <Column>
            <section>
              <h3 className={style.title}>Permit Date: </h3>
              <Date date={well.permitDate} />
            </section>
          </Column>
        </Row>
        <Row className={style.row}>
          <Column>
            <section>
              <h3 className={style.title}>Hole Direction: </h3>
              <Optional>{well.holeDirection}</Optional>
            </section>
          </Column>
          <Column>
            <section>
              <h3 className={style.title}>Spud Date: </h3>
              <Date date={well.spudDate} />
            </section>
          </Column>
        </Row>
        <Row className={style.row}>
          <Column>
            <section>
              <h3 className={style.title}>Current Operator: </h3>
              {well.currentOperator}
            </section>
          </Column>
          <Column>
            <section>
              <h3 className={style.title}>First Production Date: </h3>
              <Date date={well.firstProductionDate} />
            </section>
          </Column>
        </Row>
      </Grid>
    </SearchCard>
  );
};
