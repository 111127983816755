import { AtomizedForm } from 'atoms/form-atoms';
import { FormGroup } from 'carbon-components-react';
import { DropdownField } from 'components/form-fields/dropdown-field';
import { NumberField } from 'components/form-fields/number-field';
import { FormRow } from 'components/forms';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { TierCashflowsPayload } from 'types/scenarios-api/responses';
import { ScenariosForm } from '../types';
import { useValidateForecastSourceFieldsAtom } from '../validations';

const VariableCosts = ({
  form,
}: {
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
      >
    | AtomizedForm<TierCashflowsPayload>;
}) => {
  const waterForecastSource = useAtomValue(
    form.leaseholdAttributes.waterForecastSource
  );
  const validateForecastSourceFields = useValidateForecastSourceFieldsAtom(
    form.leaseholdAttributes
  );
  const opexCostAttributes = form.leaseholdAttributes.opexCostAttributes;

  useEffect(() => {
    validateForecastSourceFields();
  }, [validateForecastSourceFields]);

  return (
    <FormGroup legendText="Variable Costs">
      <FormRow>
        <NumberField
          id="oilOpexCost"
          label="Oil OpEx ($/BBL)"
          light
          field={opexCostAttributes.oil}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="gasOpexCost"
          label="Gas OpEx ($/MCF)"
          light
          field={opexCostAttributes.gas}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="waterOpexCost"
          label="Water OpEx ($/Barrel of Water)"
          light
          field={opexCostAttributes.water}
        />
      </FormRow>
      <FormRow>
        <DropdownField
          id="waterForecastSource"
          items={['oil', 'gas']}
          label=""
          titleText="Water Forecast Source"
          light
          field={form.leaseholdAttributes.waterForecastSource}
          onChange={() => validateForecastSourceFields()}
        />
      </FormRow>
      {waterForecastSource.value === 'oil' ? (
        <FormRow>
          <NumberField
            id="waterCutPercentage"
            label="Water Cut (%) (Barrel of Water per Barrel of Liquid)"
            light
            field={form.leaseholdAttributes.waterCutPercentage}
          />
        </FormRow>
      ) : (
        <FormRow>
          <NumberField
            id="waterGasRatio"
            label="Water Gas Ratio (BBL/MCF)"
            light
            field={form.leaseholdAttributes.waterGasRatioInBblPerMmcf}
          />
        </FormRow>
      )}
    </FormGroup>
  );
};

export { VariableCosts };
