import { AtomField } from 'atoms/form-atoms';
import { TextInput, TextInputProps } from 'carbon-components-react';
import { useAtom } from 'jotai';

interface StringFieldProps extends Omit<TextInputProps, 'value'> {
  field: AtomField<string>;
}

const StringField = ({ field, ...rest }: StringFieldProps) => {
  const [fieldState, setValue] = useAtom(field);
  return (
    <TextInput
      {...rest}
      value={fieldState.value}
      onChange={(event) => setValue(event.target.value)}
      invalid={!fieldState.isValid}
      invalidText={!fieldState.isValid ? fieldState.error : ''}
    />
  );
};

export { StringField };
