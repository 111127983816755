import { Atom, atom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { formAtoms } from '../atoms';
import {
  ConveyanceGranteeSubform,
  ConveyanceTransactionAtoms,
  ConveyanceTransactionForm,
  ConveyanceType,
} from './types';

import style from './transaction.module.scss';
import {
  Button,
  Checkbox,
  Column,
  Grid,
  RadioButton,
  RadioButtonGroup,
  Row,
  TextInput,
} from 'carbon-components-react';
import { Add16, AddAlt32, SubtractAlt32 } from '@carbon/icons-react';
import { EntityRow } from './entity-row';
import {
  initDocumentReferencesEmptyForm,
  initEntityEmptyForm,
  initConveyanceGranteeEmptyForm,
  ConveyanceGranteeSubformAtoms,
  isMineralBreakout,
} from './utils';
import { FormRow } from 'components/forms';
import { DocumentReferenceFormRow } from './document-reference-row';
import { DocumentReferencesModal } from '../document-reference/document-references-modal';
import { FormRowStatus, getError } from 'atoms/create-resource-atom';
import { WellboreSection } from './wellbore-section';

interface Props {
  row: ConveyanceTransactionAtoms;
  status: Atom<FormRowStatus>;
  idx: number;
}

const ConveyanceTransaction = ({ row, status, idx }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setField] = useAtom(row.formAtom);
  const statusValue = useAtomValue(status);

  const trxRowStatusAtom = useMemo(
    () =>
      atom((get) => {
        const formAtom = get(formAtoms).formAtom;
        const transactionStateAtom =
          get(formAtom).transactions.value[idx].status;
        return get(transactionStateAtom);
      }),
    [idx]
  );

  const docReferences = fields.documentReference.value;
  const usedDocumentsAtom = useMemo(
    () =>
      atom((get) => {
        return docReferences.flatMap((el) => {
          const doc = get(el.row.formAtom);
          const docId = doc.document.value?.id;
          return docId ?? [];
        });
      }),
    [docReferences]
  );

  const trxRowStatus = useAtomValue(trxRowStatusAtom);
  const trxRowDisabled = trxRowStatus === 'trash';

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <>
      <FormRow>
        <RadioButtonGroup
          name={`${idx}-conveyanceType`}
          valueSelected={fields.conveyanceType.value}
          onChange={(value: ConveyanceType) => {
            setField({ field: 'unburdened', value: false });
            setField({ field: 'conveyanceType', value });
          }}
          disabled={trxRowDisabled || statusValue === 'saved'}
        >
          <RadioButton labelText="Consolidated" value={'consolidated'} />
          <RadioButton
            labelText="Mineral Lease Assignment"
            value={'mineral_lease_assignment'}
          />
          <RadioButton
            labelText="Surface Lease Assignment"
            value={'surface_lease_assignment'}
          />
        </RadioButtonGroup>
      </FormRow>
      <FormRow toTrash={trxRowDisabled}>
        {fields.grantor.value.map((grantor, i) => {
          return (
            <EntityRow
              id={fields.conveyanceType.value}
              key={i}
              atoms={grantor}
              atomIdx={i}
              trxRowDisabled={trxRowDisabled}
              field={'Grantor'}
              onDelete={(atomIdx) =>
                setField((form) => ({
                  field: 'grantor',
                  value: [
                    ...form['grantor'].value.slice(0, atomIdx),
                    ...form['grantor'].value.slice(atomIdx + 1),
                  ],
                }))
              }
            />
          );
        })}
        <FormRow>
          <hr className={style.hrSeparator} />
          <Button
            renderIcon={Add16}
            size="md"
            kind={'tertiary'}
            onClick={() => {
              setField((form) => ({
                field: 'grantor',
                value: [...form.grantor.value, initEntityEmptyForm()],
              }));
            }}
            disabled={trxRowDisabled}
          >
            Add Grantor
          </Button>
          <hr className={style.sectionSeparator} />
        </FormRow>
      </FormRow>
      <FormRow toTrash={trxRowDisabled}>
        {fields.grantee.value.map((grantee, i) => {
          return (
            <div className={style.conveyanceGrid} key={i}>
              <GranteeRow
                atoms={grantee}
                trxAtoms={row.formAtom}
                trxIdx={idx}
                granteeIdx={i}
                trxRowDisabled={trxRowDisabled}
                conveyanceType={fields.conveyanceType.value}
              />
            </div>
          );
        })}
        <hr className={style.hrSeparator} />
        <Button
          renderIcon={Add16}
          size="md"
          kind={'tertiary'}
          onClick={() => {
            setField((form) => ({
              field: 'grantee',
              value: [...form.grantee.value, initConveyanceGranteeEmptyForm()],
            }));
          }}
          disabled={trxRowDisabled}
        >
          Add Grantee
        </Button>
        <hr className={style.sectionSeparator} />
      </FormRow>
      <FormRow>
        <Checkbox
          id={`${idx}-toggle-all-interest`}
          labelText="All interest"
          checked={fields.allInterest.value}
          onChange={() => {
            setField((form) => ({
              field: 'allInterest',
              value: !form.allInterest.value,
            }));
          }}
          disabled={trxRowDisabled}
        />
        {fields.documentReference.value.map((docAtoms, i) => {
          return (
            <DocumentReferenceFormRow
              key={i}
              atoms={docAtoms}
              atomIdx={i}
              trxRowDisabled={trxRowDisabled || fields.allInterest.value}
              onDelete={(atomIdx) =>
                setField((form) => ({
                  field: 'documentReference',
                  value: [
                    ...form['documentReference'].value.slice(0, atomIdx),
                    ...form['documentReference'].value.slice(atomIdx + 1),
                  ],
                }))
              }
            />
          );
        })}
        {!fields.allInterest.value && (
          <>
            <DocumentReferencesModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              usedDocumentsAtom={usedDocumentsAtom}
              onAddDocument={(doc) => {
                setField((form) => ({
                  field: 'documentReference',
                  value: [
                    ...form.documentReference.value,
                    initDocumentReferencesEmptyForm(doc),
                  ],
                }));
              }}
            />
            <hr className={style.hrSeparator} />
            <Button
              kind="tertiary"
              size="md"
              renderIcon={Add16}
              onClick={() => setModalOpen(true)}
              disabled={trxRowDisabled}
            >
              Add Document Reference
            </Button>
          </>
        )}
      </FormRow>
      <FormRow toTrash={trxRowDisabled}>
        <div className={style.radioButtonGroup}>
          <RadioButtonGroup
            className={style.radioGroupLabel}
            name={`of-type-${idx}`}
            valueSelected={fields.ofType.value}
            onChange={(value) =>
              setField({ field: 'ofType', value: String(value) })
            }
            disabled={trxRowDisabled}
          >
            <RadioButton labelText="of Interest" value="interest" />
            <RadioButton labelText="of 8/8" value="8/8" />
          </RadioButtonGroup>
          {fields.ofType.value === '8/8' &&
          fields.conveyanceType.value === 'consolidated' ? (
            <Checkbox
              className={style.unburdenedCheckbox}
              id={`${idx}-unburdened-type`}
              labelText="Unburdened"
              checked={fields.unburdened.value || false}
              onChange={() => {
                setField((form) => ({
                  field: 'unburdened',
                  value: !form.unburdened.value,
                }));
              }}
              disabled={trxRowDisabled}
            />
          ) : null}
        </div>
        {getError<ConveyanceTransactionForm>(fields, 'ofType') ? (
          <div className={style.radioGroupError} data-invalid>
            {fields.ofType.error}
          </div>
        ) : null}
      </FormRow>
      {fields.conveyanceType.value !== 'surface_lease_assignment' && (
        <WellboreSection
          wellReferenceAtoms={fields.wellReference.value}
          trnIdx={idx}
          trxRowDisabled={trxRowDisabled}
        />
      )}
    </>
  );
};

interface GranteeRowProps {
  atoms: ConveyanceGranteeSubformAtoms;
  trxRowDisabled: boolean;
  trxIdx: number;
  granteeIdx: number;
  trxAtoms: ConveyanceTransactionAtoms['formAtom'];
  conveyanceType: ConveyanceType;
}
const GranteeRow = ({
  atoms,
  trxRowDisabled,
  trxIdx,
  granteeIdx,
  trxAtoms,
  conveyanceType,
}: GranteeRowProps) => {
  const [transactionFields, setFieldTrx] = useAtom(trxAtoms);
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const rowStatus = useAtomValue(atoms.status);
  const [mineralBreakout, toggleMineralBreakout] = useReducer(
    (s) => !s,
    isMineralBreakout(fields)
  );
  const label = mineralBreakout
    ? 'Disable mineral breakout'
    : 'Enable mineral breakout';
  const icon = mineralBreakout ? SubtractAlt32 : AddAlt32;

  const isDisabled = trxRowDisabled || rowStatus === 'trash';
  return (
    <>
      <EntityRow
        id={conveyanceType}
        key={granteeIdx}
        atoms={atoms}
        atomIdx={granteeIdx}
        trxRowDisabled={trxRowDisabled}
        field={'Grantee'}
        onDelete={(atomIdx) =>
          setFieldTrx((form) => ({
            field: 'grantee',
            value: [
              ...form['grantee'].value.slice(0, atomIdx),
              ...form['grantee'].value.slice(atomIdx + 1),
            ],
          }))
        }
      />
      <FormRow>
        <Grid fullWidth className="bx--no-gutter">
          {conveyanceType === 'consolidated' && (
            <>
              <Row>
                <Column sm={1} md={3} lg={7}>
                  <TextInput
                    light
                    labelText="Surface"
                    helperText=""
                    value={fields.consolidated.value.surface || ''}
                    onChange={({ target: { value } }) => {
                      setField((form) => ({
                        field: 'consolidated',
                        value: {
                          ...form.consolidated.value,
                          surface: value,
                        },
                      }));
                    }}
                    id={`${trxIdx}-${granteeIdx}-conveyance-surface`}
                    invalid={
                      !!getError<ConveyanceGranteeSubform>(
                        fields,
                        'consolidated'
                      )
                    }
                    invalidText={getError<ConveyanceGranteeSubform>(
                      fields,
                      'consolidated'
                    )}
                    disabled={isDisabled}
                  />
                </Column>

                <Column sm={1} md={3} lg={7}>
                  <TextInput
                    light
                    labelText="Mineral"
                    helperText=""
                    value={fields.consolidated.value.mineral || ''}
                    onChange={({ target: { value } }) => {
                      setField((form) => ({
                        field: 'consolidated',
                        value: {
                          ...form.consolidated.value,
                          mineral: value,
                        },
                      }));
                    }}
                    id={`${trxIdx}-${granteeIdx}-conveyance-mineral`}
                    invalid={
                      !mineralBreakout &&
                      !!getError<ConveyanceGranteeSubform>(
                        fields,
                        'consolidated'
                      )
                    }
                    invalidText={getError<ConveyanceGranteeSubform>(
                      fields,
                      'consolidated'
                    )}
                    disabled={isDisabled || mineralBreakout}
                  />
                </Column>

                {mineralBreakout && (
                  <>
                    <Column sm={1} md={3} lg={7}>
                      <TextInput
                        light
                        labelText="Executive"
                        helperText=""
                        value={fields.consolidated.value.executive || ''}
                        onChange={({ target: { value } }) =>
                          setField({
                            field: 'consolidated',
                            value: {
                              ...fields.consolidated.value,
                              executive: value,
                            },
                          })
                        }
                        id={`${trxIdx}-${granteeIdx}-conveyance-executive`}
                        invalid={
                          !!getError<ConveyanceGranteeSubform>(
                            fields,
                            'consolidated'
                          )
                        }
                        invalidText={getError<ConveyanceGranteeSubform>(
                          fields,
                          'consolidated'
                        )}
                        disabled={isDisabled}
                      />
                    </Column>
                    <Column sm={1} md={3} lg={7}>
                      <TextInput
                        light
                        labelText="Bonus"
                        helperText=""
                        value={fields.consolidated.value.bonus || ''}
                        onChange={({ target: { value } }) =>
                          setField({
                            field: 'consolidated',
                            value: {
                              ...fields.consolidated.value,
                              bonus: value,
                            },
                          })
                        }
                        id={`${trxIdx}-${granteeIdx}-conveyance-bonus`}
                        invalid={
                          !!getError<ConveyanceGranteeSubform>(
                            fields,
                            'consolidated'
                          )
                        }
                        invalidText={getError<ConveyanceGranteeSubform>(
                          fields,
                          'consolidated'
                        )}
                        disabled={isDisabled}
                      />
                    </Column>

                    <Column sm={1} md={3} lg={7}>
                      <TextInput
                        light
                        labelText="Delay"
                        helperText=""
                        value={fields.consolidated.value.delay || ''}
                        onChange={({ target: { value } }) =>
                          setField({
                            field: 'consolidated',
                            value: {
                              ...fields.consolidated.value,
                              delay: value,
                            },
                          })
                        }
                        id={`${trxIdx}-${granteeIdx}-conveyance-delay`}
                        invalid={
                          !!getError<ConveyanceGranteeSubform>(
                            fields,
                            'consolidated'
                          )
                        }
                        invalidText={getError<ConveyanceGranteeSubform>(
                          fields,
                          'consolidated'
                        )}
                        disabled={isDisabled}
                      />
                    </Column>
                    <Column sm={1} md={3} lg={7}>
                      <TextInput
                        light
                        labelText="Royalty"
                        helperText=""
                        value={fields.consolidated.value.royalty || ''}
                        onChange={({ target: { value } }) =>
                          setField({
                            field: 'consolidated',
                            value: {
                              ...fields.consolidated.value,
                              royalty: value,
                            },
                          })
                        }
                        id={`${trxIdx}-${granteeIdx}-conveyance-royalty`}
                        invalid={
                          !!getError<ConveyanceGranteeSubform>(
                            fields,
                            'consolidated'
                          )
                        }
                        invalidText={getError<ConveyanceGranteeSubform>(
                          fields,
                          'consolidated'
                        )}
                        disabled={isDisabled}
                      />
                    </Column>
                    {transactionFields.ofType.value === '8/8' && (
                      <Column sm={1} md={3} lg={7}>
                        <RadioButtonGroup
                          name={`${trxIdx}-${granteeIdx}-npri-options`}
                          legendText="NPRI Options"
                          onChange={(value) => {
                            setField({
                              field: 'fixed',
                              value: value === 'fixed',
                            });
                          }}
                          className={style.alignRadioButtonGroup}
                          valueSelected={
                            fields.fixed.value ? 'fixed' : 'floating'
                          }
                          disabled={
                            !fields.consolidated.value.royalty || isDisabled
                          }
                        >
                          <RadioButton
                            id={`${trxIdx}-${granteeIdx}-npri-options-floating`}
                            labelText="Floating"
                            value="floating"
                          />
                          <RadioButton
                            id={`${trxIdx}-${granteeIdx}-npri-options-fixed`}
                            labelText="Fixed"
                            value="fixed"
                          />
                        </RadioButtonGroup>
                      </Column>
                    )}
                  </>
                )}
                <Column sm={1} md={1} lg={2} className={style.addButton}>
                  <Button
                    aria-label={`${label}`}
                    kind="ghost"
                    size="md"
                    renderIcon={icon}
                    iconDescription={`${label}`}
                    hasIconOnly={true}
                    className={style.adjustForLabel}
                    onClick={() => {
                      if (!mineralBreakout) {
                        setField((form) => ({
                          field: 'consolidated',
                          value: {
                            ...form.consolidated.value,
                            mineral: null,
                          },
                        }));
                      } else {
                        setField((form) => ({
                          field: 'consolidated',
                          value: {
                            ...form.consolidated.value,
                            executive: null,
                            royalty: null,
                            bonus: null,
                            delay: null,
                          },
                        }));
                      }
                      toggleMineralBreakout();
                    }}
                    disabled={isDisabled}
                  />
                </Column>
                <hr className={style.hrSeparator} />
              </Row>
            </>
          )}
          {conveyanceType === 'mineral_lease_assignment' && (
            <Row>
              <Column sm={2} md={3} lg={7}>
                <TextInput
                  light
                  labelText="Working"
                  helperText=""
                  value={fields.workingOrri.value.working || ''}
                  onChange={({ target: { value } }) =>
                    setField((form) => ({
                      field: 'workingOrri',
                      value: { ...form.workingOrri.value, working: value },
                    }))
                  }
                  id={`${trxIdx}-${granteeIdx}-conveyance-working`}
                  invalid={
                    !!getError<ConveyanceGranteeSubform>(fields, 'workingOrri')
                  }
                  invalidText={getError<ConveyanceGranteeSubform>(
                    fields,
                    'workingOrri'
                  )}
                  disabled={isDisabled}
                />
              </Column>
              <Column sm={2} md={3} lg={7}>
                <TextInput
                  light
                  labelText="ORRI"
                  helperText=""
                  value={fields.workingOrri.value.orri || ''}
                  onChange={({ target: { value } }) =>
                    setField((form) => ({
                      field: 'workingOrri',
                      value: { ...form.workingOrri.value, orri: value },
                    }))
                  }
                  id={`${trxIdx}-${granteeIdx}-conveyance-orri`}
                  invalid={
                    !!getError<ConveyanceGranteeSubform>(fields, 'workingOrri')
                  }
                  invalidText={getError<ConveyanceGranteeSubform>(
                    fields,
                    'workingOrri'
                  )}
                  disabled={isDisabled}
                />
              </Column>
              <hr className={style.hrSeparator} />
            </Row>
          )}
          {conveyanceType === 'surface_lease_assignment' && (
            <Row>
              <Column sm={2} md={3} lg={7}>
                <TextInput
                  light
                  labelText="Surface Lease Interest"
                  helperText=""
                  value={fields.surfaceLeaseMultiplier.value || ''}
                  onChange={({ target: { value } }) =>
                    setField({ field: 'surfaceLeaseMultiplier', value })
                  }
                  id={`${trxIdx}-${granteeIdx}-conveyance-surfaceLeaseMultiplier`}
                  invalid={
                    !!getError<ConveyanceGranteeSubform>(
                      fields,
                      'surfaceLeaseMultiplier'
                    )
                  }
                  invalidText={getError<ConveyanceGranteeSubform>(
                    fields,
                    'surfaceLeaseMultiplier'
                  )}
                  disabled={isDisabled}
                />
              </Column>
              <hr className={style.hrSeparator} />
            </Row>
          )}
        </Grid>
      </FormRow>
    </>
  );
};

export { ConveyanceTransaction };
