import { Section } from 'components/section';
import { Interest, LeaseAggregation, Encumbrances } from '.';
import { TitleChainCalculatorYamlResponse } from 'types/api-responses';

export function Surface({
  calculator,
}: {
  calculator: TitleChainCalculatorYamlResponse;
}) {
  return (
    <Section header="Surface">
      <Interest header="Surface Interest" interest={calculator.surface} />
      <Encumbrances
        interestHeader="Encumbered Surface Interest"
        encumbrances={calculator.surfaceEncumbrances}
      />
      <LeaseAggregation
        interestHeader="Surface Lease Interest"
        leaseData={calculator.surfaceLeases}
      />
    </Section>
  );
}
