import { useAtom } from 'jotai';
import { searchAtom } from '../../atoms';
import { ProvisionsFilter as ProvisionsFilterDropdown } from 'components/provision-filter';

export function ProvisionsFilter() {
  const [searchResults, setSearchPayload] = useAtom(searchAtom);

  const hasCompletion = searchResults.currentPayload?.hasCompletion;

  return (
    <ProvisionsFilterDropdown
      hasCompletion={hasCompletion}
      onChange={(hasCompletion) =>
        setSearchPayload((current) => ({ ...current, page: 1, hasCompletion }))
      }
    />
  );
}
