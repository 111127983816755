import { Close16 } from '@carbon/icons-react';
import { TextInput, Button } from 'carbon-components-react';
import styles from './column-filter.module.scss';
import { Header, Column } from '@tanstack/react-table';
import { CSSProperties, useMemo, useState } from 'react';
import { debounce } from 'lodash';

/**
 * TData : The type of the data in the table(Each row type). Used interally by Tanstack table.
 * TValue : The type of the value in the column. Used interally by Tanstack table
 */
interface ColumnFilterProps<TData, TValue> {
  headers: Header<TData, TValue>[];
  pinStyleGenerator: (column: Column<TData>) => CSSProperties;
}

export function ColumnFilter<TData, TValue>({
  headers,
  pinStyleGenerator,
}: ColumnFilterProps<TData, TValue>) {
  return (
    <>
      {headers.map((header) => (
        <FilterInput
          key={header.id}
          header={header}
          pinStyleGenerator={pinStyleGenerator}
        ></FilterInput>
      ))}
    </>
  );
}

interface FilterInputProps<TData, TValue> {
  header: Header<TData, TValue>;
  pinStyleGenerator: (column: Column<TData>) => CSSProperties;
}

export function FilterInput<TData, TValue>({
  header,
  pinStyleGenerator,
}: FilterInputProps<TData, TValue>) {
  const column = header.column;

  return (
    <td
      key={header.id}
      className={styles.filterRow}
      style={pinStyleGenerator(header.column)}
    >
      {!header.isPlaceholder && header.column.getCanFilter() ? (
        <div className={styles.filtersContainer}>
          {header.column.columnDef.meta?.filterVariant === 'range' ? (
            <RangeFilterInput column={column}></RangeFilterInput>
          ) : (
            <TextFilterInput column={column}></TextFilterInput>
          )}
        </div>
      ) : null}
    </td>
  );
}

function TextFilterInput<TValue, TData>({
  column,
}: {
  column: Column<TData, TValue>;
}) {
  const [filter, setFilter] = useState<string | undefined>(
    column.getFilterValue() as string | undefined
  );

  const filterColumn = useMemo(
    () =>
      debounce((value?: string) => {
        column.setFilterValue(value);
      }, 300),
    [column]
  );

  const handleChange = (value?: string) => {
    setFilter(value);
    filterColumn(value);
  };

  return (
    <>
      <TextInput
        id={column.id}
        value={filter ?? ''}
        labelText=""
        placeholder={`Filter by ${column.columnDef.header?.toString()}`}
        className={styles.text}
        onChange={(e) => handleChange(e.target.value)}
      />
      <Button onClick={() => handleChange(undefined)} kind="ghost" size="sm">
        <Close16 />
      </Button>
    </>
  );
}

export type Range = [string | undefined, string | undefined] | undefined;

function RangeFilterInput<TValue, TData>({
  column,
}: {
  column: Column<TData, TValue>;
}) {
  const [filter, setFilter] = useState<Range>(column.getFilterValue() as Range);

  const filterColumn = useMemo(
    () =>
      debounce((value: Range) => {
        column.setFilterValue(value);
      }, 300),
    [column]
  );

  const handleChange = (value: Range) => {
    setFilter(value);
    filterColumn(value);
  };

  return (
    <>
      <div className={styles.rangeFilter}>
        <TextInput
          type="number"
          value={filter?.[0] ?? ''}
          id={column.id}
          labelText=""
          placeholder={`min`}
          className={styles.text}
          onChange={(e) => handleChange([e.target.value, filter?.[1]])}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <Button
          onClick={() => handleChange([undefined, filter?.[1]])}
          kind="ghost"
          size="sm"
        >
          <Close16 />
        </Button>
      </div>
      <div className={styles.rangeFilter}>
        <TextInput
          type="number"
          value={filter?.[1] ?? ''}
          id={column.id}
          labelText=""
          placeholder={`max`}
          className={styles.text}
          onChange={(e) => handleChange([filter?.[0], e.target.value])}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <Button
          onClick={() => handleChange([filter?.[0], undefined])}
          kind="ghost"
          size="sm"
        >
          <Close16 />
        </Button>
      </div>
    </>
  );
}
