import { Development32 } from '@carbon/icons-react';
import classNames from 'classnames';
import { IconProps } from './types';

import style from './icons.module.scss';

interface Props extends IconProps {
  hasBackground?: boolean;
}

const DevelopmentArea = ({ hasBackground = true, className }: Props) => {
  if (hasBackground) {
    return (
      <div
        className={classNames(
          style.background,
          style.developmentArea,
          className
        )}
      >
        <Development32
          className={classNames(style.icon, style.developmentArea)}
        />
      </div>
    );
  } else {
    return (
      <Development32
        className={classNames(style.icon, style.developmentArea, className)}
      />
    );
  }
};

export default DevelopmentArea;
