import {
  createResourceFormAtom,
  FormFieldState,
  FormRowStatus,
  hasChangedFields,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { atom, Getter, Setter } from 'jotai';
import { ApiPayloads } from 'types/api-payloads';
import { DocumentInterpretationResponse } from 'types/api-responses';
import {
  CurativeRequirementForm,
  CurativeRequirementFormErrors,
  FormInitialValues,
} from './types';

export type CurativeRequirementAtoms = ReturnType<typeof createFormAtom>;

const createFormAtom = (
  defaultValue: CurativeRequirementForm,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      CurativeRequirementForm,
      CurativeRequirementFormErrors
    >(defaultValue),
  };
};

const formDefault = (
  { id, priority, status, description }: FormInitialValues,
  state: FormFieldState
) =>
  initializeForm<CurativeRequirementForm>(
    {
      id,
      priority,
      status,
      description,
    },
    state
  );

const initForm = (
  requirements:
    | DocumentInterpretationResponse['curativeRequirements']
    | undefined,
  state: FormFieldState
) => {
  if (!requirements) {
    return [];
  }
  return requirements.map((requirement) =>
    createFormAtom(
      formDefault(
        {
          id: requirement.id,
          priority: requirement.priority,
          status: requirement.status,
          description: requirement.description,
        },
        state
      ),
      rowStatus(state)
    )
  );
};

const initEmptyForm = () => {
  return createFormAtom(
    formDefault(
      {
        id: undefined,
        priority: undefined,
        status: 'open',
        description: undefined,
      },
      'updated'
    ),
    'new'
  );
};

const validate = (
  atoms: CurativeRequirementAtoms,
  get: Getter,
  set: Setter
) => {
  let hasError = false;
  const fields = get(atoms.row.formAtom);

  const status = get(atoms.status);
  if (status === 'trash') return;

  const errorObj: CurativeRequirementFormErrors = { errors: {} };
  if (!fields.priority.value) {
    errorObj.errors.priority = 'Priority is mandatory';
    hasError = true;
  }
  if (!fields.status.value) {
    errorObj.errors.status = 'Status is mandatory';
    hasError = true;
  }
  if (!fields.description.value) {
    errorObj.errors.description = 'Description is mandatory';
    hasError = true;
  }
  set(atoms.row.dirtyFieldsAtom, errorObj);
  return hasError;
};

const getPayload = (
  atoms: CurativeRequirementAtoms,
  get: Getter
):
  | Required<
      ApiPayloads['documentInterpretation']['payload']
    >['curativeRequirementsAttributes'][0]
  | undefined => {
  const form = get(atoms.row.formAtom);
  const rowStatus = get(atoms.status);

  if (rowStatus === 'trash' && form.id.value) {
    return { _destroy: true, id: form.id.value };
  }

  if (!hasChangedFields(form)) return;

  if (!form.status.value || !form.priority.value || !form.description.value)
    return;

  return {
    status: form.status.value,
    priority: form.priority.value,
    description: form.description.value,
    id: form.id.value,
  };
};

export { initForm, initEmptyForm, validate, getPayload };
