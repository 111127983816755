import { subscribe } from 'lib/pusher';
import request from 'lib/request';
import { useState } from 'react';
import {
  DocumentProvisionJobResponse,
  ProvisionResponse,
} from 'types/api-responses';

const useProvisionDocument = <Payload = unknown>(provisionHref?: string) => {
  const [generatingProvision, setGeneratingProvision] = useState(false);

  const triggerProvision = async ({
    onError,
    onGeneratingProvision,
    payload,
  }: {
    onGeneratingProvision: (provision: ProvisionResponse) => void;
    onError: () => void;
    payload?: Payload;
  }) => {
    if (!provisionHref) return;
    setGeneratingProvision(true);

    const provisionJobResponse = await request.post<
      DocumentProvisionJobResponse,
      Payload
    >(provisionHref, payload ?? null, new AbortController());

    if (provisionJobResponse.type === 'success') {
      const provisionChannel = provisionJobResponse.data;
      const channel = provisionChannel.notifications.channel;
      const event = provisionChannel.notifications.events[0].name;
      subscribeToChannel(
        channel,
        event,
        provisionChannel.href,
        (provision) => {
          setGeneratingProvision(false);
          onGeneratingProvision?.(provision);
        },
        () => {
          setGeneratingProvision(false);
          onError();
        }
      );
    }
  };

  return {
    generatingProvision,
    setGeneratingProvision,
    triggerProvision,
  };
};

export function subscribeToChannel(
  channel: string,
  eventName: string,
  href: string,
  onComplete: (provision: ProvisionResponse) => void,
  onError: () => void
) {
  const subscription = subscribe(channel);
  subscription.bind(eventName, async () => {
    const provisionData = await request.get<ProvisionResponse>(href);

    if ('error' in provisionData) return;

    if (provisionData.status === 'complete') {
      subscription.unsubscribe();
      onComplete(provisionData);
    }

    if (provisionData.status === 'error') {
      subscription.unsubscribe();
      onError();
    }
  });
}

export type UseProvisionDocument = ReturnType<typeof useProvisionDocument>;
export { useProvisionDocument };
