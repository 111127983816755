import { Fire24 } from '@carbon/icons-react';
import classNames from 'classnames';

import style from './icons.module.scss';

const Gas = () => {
  return <Fire24 className={classNames(style.icon, style.gas)} />;
};

export default Gas;
