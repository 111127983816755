import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useMemo } from 'react';
import { fetchColumnSizes, updateColumnSizes } from 'utils/tables';

export function useColumnSizing(tableId: string) {
  const columnSizingAtom = useMemo(
    () =>
      atomWithStorage<Record<string, number>>(
        tableId,
        {},
        {
          getItem: (key: string) => fetchColumnSizes(key),
          setItem: (key: string, newValue: Record<string, number>) =>
            updateColumnSizes(key, newValue),
          removeItem: () => void 0,
        },
        {
          getOnInit: true,
        }
      ),

    [tableId]
  );

  return useAtom(columnSizingAtom);
}
