import style from '../row-details.module.scss';
import { useReducer } from 'react';
import { MineralLeaseTransaction } from 'pages/title-workspaces/runsheet/types';

const MAX_LEASE_EXTENSIONS = 5;

const RetainedAreas = ({ row }: { row: MineralLeaseTransaction }) => {
  const leaseExtensionsLength = row.leaseExtensions.length;
  const shouldTruncate = leaseExtensionsLength > MAX_LEASE_EXTENSIONS;
  const [truncated, toggleTruncated] = useReducer((s) => !s, true);

  return (
    <>
      <div>
        <h1 className={style.detailsTitle}>
          Retained Lands ({leaseExtensionsLength})
        </h1>{' '}
        {(truncated && shouldTruncate
          ? row.leaseExtensions.slice(0, MAX_LEASE_EXTENSIONS)
          : row.leaseExtensions
        ).map((extension) => {
          return (
            <p
              className={style.detailsDesc}
              key={extension.referenceLocation.id}
            >
              {extension.referenceLocation.name}{' '}
              {extension.quarterCalls.length
                ? ` • ${extension.quarterCalls.join(', ')}`
                : null}{' '}
              {extension.startDepthInFeet && extension.endDepthInFeet ? (
                <span className={style.depth}>
                  ({extension.startDepthInFeet}-{extension.endDepthInFeet} ft)
                </span>
              ) : null}
            </p>
          );
        })}
      </div>
      {shouldTruncate && (
        <a onClick={() => toggleTruncated()} className={style.viewMoreBtn}>
          {truncated ? 'View more' : 'View less'}
        </a>
      )}
    </>
  );
};

export { RetainedAreas };
