import { TextInputSkeleton } from 'carbon-components-react';
import style from './summary-skeleton.module.scss';

export function SummarySkeleton() {
  return (
    <div className={style.inputSkeletonArea}>
      <TextInputSkeleton className={style.textInputskeleton} />
      <TextInputSkeleton className={style.textInputskeleton} />
      <TextInputSkeleton className={style.textInputskeleton} />
    </div>
  );
}
