import {
  Button,
  InlineLoading,
  InlineNotification,
  SkeletonText,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import { CenteredMap, PolygonArea } from 'components/map';
import { getMapDefaultConfig } from 'components/map/atoms';
import { PageHeader } from 'components/page-header';
import { useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createResourceAtom } from 'lib/atoms';
import { useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { ReferenceLocationResponse } from 'types/api-responses';
import { CreateTitleWorkspaceModal } from './create-title-workspace-modal';
import { DocumentsTable } from './documents-table';

import style from './page.module.scss';

const Actions = ({
  referenceLocation,
}: {
  referenceLocation?: ReferenceLocationResponse;
}) => {
  const [open, toggleOpen] = useReducer((s) => !s, false);
  if (!referenceLocation) return null;
  if (referenceLocation.resources.titleWorkspace !== null) return null;

  return (
    <>
      {open && (
        <CreateTitleWorkspaceModal
          open={open}
          onClose={() => toggleOpen()}
          referenceLocationId={referenceLocation.id}
        />
      )}
      <Button renderIcon={Add16} onClick={() => toggleOpen()} size="sm">
        <span>Create Title Workspace</span>
      </Button>
    </>
  );
};

const Header = ({
  referenceLocation,
}: {
  referenceLocation: ReferenceLocationResponse | undefined;
}) => {
  const { pathname } = useLocation();

  return (
    <PageHeader
      breadcrumbs={[
        ['/reference_locations', 'Map'],
        [pathname, referenceLocation?.name || <SkeletonText width="200px" />],
      ]}
      title={referenceLocation?.name || <SkeletonText heading width="200px" />}
      actions={<Actions referenceLocation={referenceLocation} />}
    />
  );
};

const mapConfigAtom = atomWithStorage(
  'insights-reference-location-map-config',
  getMapDefaultConfig()
);

const Content = ({
  referenceLocation,
}: {
  referenceLocation: ReferenceLocationResponse;
}) => {
  return (
    <>
      <div className={style.mapContainer}>
        {referenceLocation.geometry && (
          <CenteredMap
            geometry={referenceLocation.geometry}
            className={style.map}
            mapConfigAtom={mapConfigAtom}
          >
            <PolygonArea
              id={referenceLocation.id}
              geometry={referenceLocation.geometry}
            />
          </CenteredMap>
        )}
      </div>
      <DocumentsTable
        referenceLocationId={referenceLocation.id}
        downloadHref={referenceLocation.resources.documentsDownloads?.href}
      />
    </>
  );
};

const ReferenceLocationsShowPage = () => {
  const { pathname } = useLocation();
  const locationAtom = useMemo(
    () => createResourceAtom<ReferenceLocationResponse>(pathname),
    [pathname]
  );
  const referenceLocation = useAtomValue(locationAtom);

  if (referenceLocation.data && 'error' in referenceLocation.data)
    return (
      <InlineNotification
        kind="error"
        title={referenceLocation.data.error}
        lowContrast
      />
    );

  return (
    <>
      <Header referenceLocation={referenceLocation.data} />
      {referenceLocation.data ? (
        <Content referenceLocation={referenceLocation.data} />
      ) : (
        <InlineLoading />
      )}
    </>
  );
};

export { ReferenceLocationsShowPage };
