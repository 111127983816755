import { SkeletonText } from 'carbon-components-react';
import style from './output_list_skeleton.module.scss';

const elementCount = 8;

export function OutputListSkeleton() {
  return (
    <>
      <div className={style.loadTabs}>
        <SkeletonText width="110px" />
        <SkeletonText width="100px" />
      </div>
      <SkeletonText heading width="80px" className={style.loadLabel} />
      {[...Array(elementCount).keys()].map((el) => (
        <div className={style.loadLink} key={el}>
          <SkeletonText />
        </div>
      ))}
    </>
  );
}
