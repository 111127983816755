import { PageHeader } from 'components/page-header';
import { useLocation } from 'react-router-dom';
import { EntityResponse, Notifications } from 'types/api-responses';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'utils/page-title';
import {
  Grid,
  Row,
  Column,
  Button,
  Tile,
  InlineLoading,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  ToastNotification,
} from 'carbon-components-react';
import style from './deduplicate.module.scss';
import { Add16, AddAlt20, SubtractAlt20 } from '@carbon/icons-react';
import { EntitySearch } from 'components/entity-search';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'components/link';
import { useAtom } from 'jotai';
import { runMutationAtom } from './atoms';
import ErrorToast from 'components/error-toast';
import { subscribe } from 'lib/pusher';
import { EntityDetailsPopover } from 'components/entity-details-popover';

const EntitiesModal = ({
  open,
  onClose,
  selectedEntities,
  setSelectedEntities,
  primaryEntityId,
}: {
  open: boolean;
  onClose: () => void;
  selectedEntities: EntityResponse[];
  setSelectedEntities: React.Dispatch<React.SetStateAction<EntityResponse[]>>;
  primaryEntityId: number;
}) => {
  return (
    <Modal
      open={open}
      size="lg"
      className={style.deduplicateModal}
      modalHeading={'Search Entities'}
      primaryButtonText={'Close'}
      onRequestSubmit={onClose}
      onRequestClose={onClose}
      passiveModal
    >
      <EntitySearch
        onEntityClick={(entity: EntityResponse) => (
          <Button
            kind="ghost"
            hasIconOnly
            iconDescription="Select Entity"
            size="md"
            renderIcon={AddAlt20}
            tooltipAlignment="start"
            tooltipPosition="right"
            onClick={() =>
              setSelectedEntities((current) => [...current, entity])
            }
            disabled={
              selectedEntities.findIndex((el) => el.id === entity.id) >= 0 ||
              entity.id === primaryEntityId
            }
          />
        )}
      />
    </Modal>
  );
};

const EntityDeduplicationProgress = ({
  progress,
  onComplete,
}: {
  progress: { notifications: Notifications };
  onComplete: () => void;
}) => {
  const channel = progress.notifications.channel;
  const event = progress.notifications.events[0].name;
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const subscription = subscribe(channel);
    subscription.bind(event, () => {
      onComplete();
      setPending(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [channel, event, onComplete]);

  return pending ? (
    <InlineLoading description="Running the deduplication..." />
  ) : null;
};

const SuccessToast = ({ onClose }: { onClose: () => boolean }) => {
  return (
    <ToastNotification
      className={style.toastNotification}
      title="Success deduplicating the entities"
      kind="success"
      lowContrast
      onClose={onClose}
      timeout={5000}
    />
  );
};

const PageContent = ({
  loading,
  entity,
}: {
  loading: boolean;
  entity: EntityResponse | undefined;
}) => {
  const [selectedEntities, setSelectedEntities] = useState<EntityResponse[]>(
    []
  );
  const { pathname } = useLocation();
  const [deduplicateEntities, runMutation] = useAtom(runMutationAtom);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [notifications, setNotifications] = useState<
    { notifications: Notifications } | undefined
  >(undefined);

  const onDelete = useCallback(
    (index: number) => {
      setSelectedEntities((arr) => [
        ...arr.slice(0, index),
        ...arr.slice(index + 1),
      ]);
    },
    [setSelectedEntities]
  );

  const onComplete = useCallback(() => {
    setSuccess(true);
    setSelectedEntities([]);
    setNotifications(undefined);
  }, []);

  if (loading) return <InlineLoading />;
  if (!entity) return null;
  return (
    <Grid className="bx--no-gutter" fullWidth>
      {error && <ErrorToast key={error} message={error} />}
      {success && (
        <SuccessToast
          key={String(success)}
          onClose={() => {
            setSuccess(false);
            return true;
          }}
        />
      )}
      <Tile>
        <Row>
          <Column lg={12}>
            <h2 className={style.subTitle}>Selected for deduplication</h2>

            <EntitiesModal
              open={openSearch}
              onClose={() => setOpenSearch(false)}
              selectedEntities={selectedEntities}
              setSelectedEntities={setSelectedEntities}
              primaryEntityId={entity.id}
            />
          </Column>
          <Column lg={4} className={style.actionsButtons}>
            <Button
              renderIcon={Add16}
              kind="primary"
              size="md"
              onClick={() => setOpenSearch(true)}
            >
              Search Entities
            </Button>
            {notifications ? (
              <EntityDeduplicationProgress
                onComplete={onComplete}
                progress={notifications}
              />
            ) : (
              <Button
                kind="primary"
                size="md"
                disabled={
                  deduplicateEntities.loading || !selectedEntities.length
                }
                onClick={() => {
                  runMutation({
                    url: pathname,
                    type: 'POST',
                    data: {
                      duplicate_ids: selectedEntities.map((el) => el.id),
                    },
                    onSuccess: (response) => {
                      setNotifications(response);
                    },
                    onError: () => {
                      setError('Error trying to create the deduplication job');
                    },
                  });
                }}
              >
                Submit
              </Button>
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <TableContainer className={style.dedupsTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader></TableHeader>
                    <TableHeader>Id</TableHeader>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Addresses</TableHeader>
                    <TableHeader>Phone Numbers</TableHeader>
                    <TableHeader>Emails</TableHeader>
                    <TableHeader>Entity Type</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedEntities.map((el, i) => {
                    return (
                      <TableRow key={el.id}>
                        <TableCell>
                          <div className={style.infoButtons}>
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription="Remove Entity"
                              size="md"
                              renderIcon={SubtractAlt20}
                              tooltipAlignment="start"
                              tooltipPosition="right"
                              onClick={() => onDelete(i)}
                            />
                            <EntityDetailsPopover
                              href={el.resources.details.href}
                            />
                          </div>
                        </TableCell>
                        <TableCell>{el.id}</TableCell>
                        <TableCell>
                          <Link to={el.href}>{el.name}</Link>
                        </TableCell>
                        <TableCell>
                          {el.addresses.map((el) => (
                            <p key={el.streetAddress}>
                              {el.streetAddress
                                ? `${el.streetAddress}, `
                                : null}
                              {el.city ? `${el.city}, ` : null}
                              {el.state ? `${el.state} ` : null}
                              {el.zipCode ? `${el.zipCode}` : null}
                            </p>
                          ))}
                        </TableCell>
                        <TableCell>
                          {el.phoneNumbers.map((el) => (
                            <p key={el.number}>{el.number}</p>
                          ))}
                        </TableCell>
                        <TableCell>
                          {el.emailAddresses.map((el) => (
                            <p key={el.email}>{el.email}</p>
                          ))}
                        </TableCell>
                        <TableCell>
                          {el.type?.replace(/(Entity$)/, '')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Column>
        </Row>
      </Tile>
    </Grid>
  );
};

const Header = ({ entity }: { entity: EntityResponse | undefined }) => {
  const { pathname } = useLocation();
  if (!entity) return null;
  return (
    <PageHeader
      breadcrumbs={[
        ['/entities', 'Entities'],
        [entity.href, entity.name],
        [pathname, 'Deduplicate'],
      ]}
      title={entity.name || ''}
      actions={null}
    />
  );
};

const DeDuplicateEntityPage = ({
  entity,
  loading,
}: {
  entity: EntityResponse | undefined;
  loading: boolean;
}) => {
  return (
    <>
      <Header entity={entity} />
      {entity && (
        <Helmet>
          <title>{pageTitle(`${entity.name} | Deduplication`)}</title>
        </Helmet>
      )}
      <PageContent entity={entity} loading={loading} />
    </>
  );
};

export { DeDuplicateEntityPage };
