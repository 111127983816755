import { Information16 } from '@carbon/icons-react';
import { InlineLoading, OverflowMenu, Tag } from 'carbon-components-react';
import style from './entity-details-popover.module.scss';
import { useAtomValue } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import { forwardRef, useMemo } from 'react';
import { EntityDetailsResponse } from 'types/api-responses';
import { Date } from 'components/date';

const EntityDetailsPopover = ({ href }: { href: string }) => {
  return (
    <OverflowMenu
      renderIcon={Information16}
      className={style.entityDetailsContent}
    >
      <EntityDetailsContent href={href} />
    </OverflowMenu>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EntityDetailsContent = forwardRef(({ href }: { href: string }, _ref) => {
  const entityDetailsAtom = useMemo(
    () => createResourceAtom<EntityDetailsResponse>(href),
    [href]
  );
  const details = useAtomValue(entityDetailsAtom);

  if (details.loading) return <InlineLoading />;
  if (details.data && 'error' in details.data)
    return (
      <Tag type="red" title="Error">
        {details.data.error}
      </Tag>
    );
  if (!details.data) return null;

  return (
    <div className={style.detailsDefinition}>
      <label>Created at:</label>
      <p>
        <Date date={details.data.entityCreatedAt} />
      </p>
      <label>Transaction Min Date:</label>
      <p>
        <Date date={details.data.transactionMinDate} />
      </p>
      <label>Transaction Max Date:</label>
      <p>
        <Date date={details.data.transactionMaxDate} />
      </p>
      <label>Applicable Areas</label>
      <ul>
        {details.data.transactionApplicableAreas.split(',').map((el) => (
          <li key={el}>{el}</li>
        ))}
      </ul>
    </div>
  );
});
EntityDetailsContent.displayName = 'EntityDetailsContent';

export { EntityDetailsPopover };
