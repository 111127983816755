import { atom } from 'jotai';
import { documentAtom, formAtoms, interpretationsAtom } from '../atoms';

const locationReferencesAtom = atom((get) => {
  return get(get(formAtoms).formAtom).locationReferences;
});
/**
 * This atom builds a Set of the used location references across interpretations
 * or in the current applicable areas form section. Every time a new applicable area
 * row is added, the set is re-computed. We need to keep the Set stable, that is, not
 * creating a new one, or that will trigger a re-render of all the applicable rows.
 *
 * That's why we clear the set every time a new applicable area row is added. This is the
 * expected action as dependants of this atom are only interested on the Set values and the
 * rendered output does not change depending on the Set value.
 */
const usedReferencesSet = atom<Set<number>>(new Set<number>());
const usedReferencedLocationsAtom = atom((get) => {
  const usedSet = get(usedReferencesSet);
  const interpretations = get(interpretationsAtom);

  // We are only interested on recomputing the set when the
  // locationReferences section of the form changes.
  const locationReferences = get(locationReferencesAtom);

  usedSet.clear();

  if (interpretations) {
    interpretations.forEach((i) =>
      i.locationReferences?.forEach((lr) => usedSet.add(lr.location.id))
    );
  }

  locationReferences.value.forEach((el) => {
    const fields = get(el.row.formAtom);
    const status = get(el.status);
    if (status === 'trash') return;

    if (fields.referenceLocation.value?.locationId)
      usedSet.add(fields.referenceLocation.value?.locationId);

    if (fields.referenceLocation.value?.id)
      usedSet.add(fields.referenceLocation.value?.id);
  });

  return usedSet;
});

const documentLocationsAtom = atom<Set<number>>((get) => {
  const document = get(documentAtom);
  let locations: number[] = [];
  if (document?.locations) {
    locations = document.locations.map((el) => el.id);
  }
  return new Set(locations);
});

export { usedReferencedLocationsAtom, documentLocationsAtom };
