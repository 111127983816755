import { TagListItem } from 'components/tag-list-item';
import { ReferenceLocationResponse } from 'types/api-responses';

import style from './quartered-geographies.module.scss';

interface Props {
  quarteredGeographies: {
    referenceLocation: ReferenceLocationResponse;
    quarterCalls: string[];
  }[];
}

export const QuarteredGeographies = ({ quarteredGeographies }: Props) => {
  return (
    <ul>
      {quarteredGeographies.map((quarteredGeography, index) => (
        <TagListItem
          number={index + 1}
          tagType="blue"
          key={quarteredGeography.referenceLocation.id}
        >
          <QuarteredGeography quarteredGeography={quarteredGeography} />
        </TagListItem>
      ))}
    </ul>
  );
};

const QuarteredGeography = ({
  quarteredGeography,
}: {
  quarteredGeography: Props['quarteredGeographies'][0];
}) => {
  const { referenceLocation, quarterCalls } = quarteredGeography;
  return (
    <div className={style.geography}>
      <div className={style.name}>{referenceLocation.name}</div>
      <div className={style.quarterCalls}>{quarterCalls.join(' · ')}</div>
    </div>
  );
};
