import { Tile } from 'carbon-components-react';
import { FC, ReactNode } from 'react';
import style from './search-card.module.scss';

interface Props {
  icon: ReactNode;
}

export const SearchCard: FC<Props> = ({ icon, children }) => {
  return (
    <Tile className={style.card}>
      <div className={style.iconColumn}>{icon}</div>
      <div className={style.content}>{children}</div>
    </Tile>
  );
};
