import { atomField, AtomizedForm, getFormValues } from 'atoms/form-atoms';
import { Getter } from 'jotai';
import {
  ApiPayloads,
  DucAttributes,
  PermitAttributes,
} from 'types/api-payloads';
import { EvalDefaultAssumptions } from 'types/evaluation-api/responses';
import { createWellToExcludeElement } from 'utils/wells-to-exclude';

import { EvaluationForm } from './types';

const evaluationForm = (
  assumptions: EvalDefaultAssumptions
): AtomizedForm<EvaluationForm> => {
  const excluded = assumptions.drillingAssumptions.wellsToExclude.map((el) =>
    createWellToExcludeElement(el)
  );

  const { drillingAssumptions, cashflowAssumptions } = assumptions;
  return {
    persist: atomField<boolean>(false),
    drillingAssumptionAttributes: {
      wellsToExclude: atomField(excluded ?? []),
      ltdAttributes: {
        kind: atomField<
          EvaluationForm['drillingAssumptionAttributes']['ltdAttributes']['kind']
        >(drillingAssumptions.ltdAssumptionsType),
        typeCurveBlend: atomField<boolean>(drillingAssumptions.ltdBlend),
        firstDrillingStartDate: atomField(
          drillingAssumptions.ltdAttributes.firstDrillingStartDate
        ),
        spudToProductionMonths: atomField(
          drillingAssumptions.ltdAttributes.spudToProductionMonths
        ),
        drillingIntervalMonths: atomField(
          drillingAssumptions.ltdAttributes.drillingIntervalMonths
        ),
        startDate: atomField(drillingAssumptions.ltdAttributes.startDate),
        drillStartMonths: atomField(
          drillingAssumptions.ltdAttributes.drillStartMonths
        ),
        drillOffsetMonths: atomField(
          drillingAssumptions.ltdAttributes.drillOffsetMonths
        ),
        averageWellDrilledYears: atomField(
          drillingAssumptions.ltdAttributes.averageWellDrilledYears
        ),
        asOfDate: atomField(drillingAssumptions.ltdAttributes.asOfDate),
      },
      ducAttributes: {
        kind: atomField(drillingAssumptions.ducAttributes.kind),
        typeCurveBlend: atomField(
          drillingAssumptions.ducAttributes.typeCurveBlend
        ),
        spudToProductionMonths: atomField(
          drillingAssumptions.ducAttributes.spudToProductionMonths
        ),
        startDate: atomField(drillingAssumptions.ducAttributes.startDate),
        startMonth: atomField(drillingAssumptions.ducAttributes.startMonth),
        endMonth: atomField(drillingAssumptions.ducAttributes.endMonth),
      },
      permitAttributes: {
        kind: atomField(drillingAssumptions.permitAttributes.kind),
        typeCurveBlend: atomField(
          drillingAssumptions.permitAttributes.typeCurveBlend
        ),
        spudToProductionMonths: atomField(
          drillingAssumptions.permitAttributes.spudToProductionMonths
        ),
        permitToProductionMonths: atomField(
          drillingAssumptions.permitAttributes.permitToProductionMonths
        ),
        startDate: atomField(drillingAssumptions.permitAttributes.startDate),
        startMonth: atomField(drillingAssumptions.permitAttributes.startMonth),
        endMonth: atomField(drillingAssumptions.permitAttributes.endMonth),
      },
      infillAttributes: {
        includeUpside: atomField(
          drillingAssumptions.infillAttributes.includeUpside
        ),
        formationLimitPercentage: atomField(
          drillingAssumptions.infillAttributes.formationLimitPercentage
        ),
        developmentAreaLimitPercentage: atomField(
          drillingAssumptions.infillAttributes.developmentAreaLimitPercentage
        ),
        ltdLimit: atomField(drillingAssumptions.infillAttributes.ltdLimit),
      },
    },
    cashflowAssumptionAttributes: {
      effectiveDate: atomField(cashflowAssumptions.effectiveDate),
      discountToDate: atomField(cashflowAssumptions.discountToDate),
      royaltyPercentage: atomField(cashflowAssumptions.royaltyPercentage),
      orriPercentage: atomField(cashflowAssumptions.orriPercentage),
      differentialAttributes: {
        oilPrice: atomField(cashflowAssumptions.oilDifferentialPrice),
        gasPrice: atomField(cashflowAssumptions.gasDifferentialPrice),
        nglPercentage: atomField(cashflowAssumptions.nglDifferentialPercentage),
      },
      reservoirAssumptionAttributes: {
        gasShrinkPercentage: atomField(cashflowAssumptions.gasShrinkPercentage),
        nglYield: atomField(cashflowAssumptions.nglYield),
      },
      taxPercentageAttributes: {
        oil: atomField(cashflowAssumptions.oilTaxPercentage),
        gas: atomField(cashflowAssumptions.gasTaxPercentage),
        ngl: atomField(cashflowAssumptions.nglTaxPercentage),
        adValorem: atomField(cashflowAssumptions.adValoremTaxPercentage),
      },
      priceAttributes: {
        kind: atomField<
          EvaluationForm['cashflowAssumptionAttributes']['priceAttributes']['kind']
        >(cashflowAssumptions.priceType),
        gasModifiedStripCutoffMonths: atomField(
          cashflowAssumptions.gasModifiedStripCutoffMonths
        ),
        oilModifiedStripCutoffMonths: atomField(
          cashflowAssumptions.oilModifiedStripCutoffMonths
        ),
        modifiedStripOilScalingPercentage: atomField(
          cashflowAssumptions.modifiedStripOilScalingPercentage
        ),
        modifiedStripGasScalingPercentage: atomField(
          cashflowAssumptions.modifiedStripGasScalingPercentage
        ),
        stripPriceDate: atomField(cashflowAssumptions.stripPriceDate),
        oilFlatPrice: atomField(cashflowAssumptions.oilFlatPrice),
        gasFlatPrice: atomField(cashflowAssumptions.gasFlatPrice),
      },
      economicLimitAttributes: {
        shutInLimit: atomField(cashflowAssumptions.economicLimit.shutInLimit),
        applyShutInLimit: atomField(
          cashflowAssumptions.economicLimit.applyShutInLimit
        ),
        ltdHurdleRate: atomField(
          cashflowAssumptions.economicLimit.ltdHurdleRate
        ),
        applyLTDHurdleRate: atomField(
          cashflowAssumptions.economicLimit.applyLTDHurdleRate
        ),
        ducHurdleRate: atomField(
          cashflowAssumptions.economicLimit.ducHurdleRate
        ),
        applyDUCHurdleRate: atomField(
          cashflowAssumptions.economicLimit.applyDUCHurdleRate
        ),
        permitHurdleRate: atomField(
          cashflowAssumptions.economicLimit.permitHurdleRate
        ),
        applyPermitHurdleRate: atomField(
          cashflowAssumptions.economicLimit.applyPermitHurdleRate
        ),
      },
      leaseholdAttributes: {
        waterCutPercentage: atomField(cashflowAssumptions.waterCutPercentage),
        dcCostPerWell: atomField(cashflowAssumptions.dcCostPerWell),
        percentageCostFirstProductionDate: atomField(
          cashflowAssumptions.percentageCostFirstProductionDate,
          {
            validate: numberIsBetweenZeroAndOneHundred,
          }
        ),
        percentageCostSpudDate: atomField(
          cashflowAssumptions.percentageCostSpudDate,
          {
            validate: numberIsBetweenZeroAndOneHundred,
          }
        ),
        opexCostAttributes: {
          gas: atomField(cashflowAssumptions.gasOpexCost),
          oil: atomField(cashflowAssumptions.oilOpexCost),
          water: atomField(cashflowAssumptions.waterOpexCost),
        },
        loePerWellCostAttributes: {
          year1: atomField(cashflowAssumptions.loeCostPerWellYear1),
          year2: atomField(cashflowAssumptions.loeCostPerWellYear2),
          year3: atomField(cashflowAssumptions.loeCostPerWellYear3),
          year4: atomField(cashflowAssumptions.loeCostPerWellYear4),
          year5AndUp: atomField(cashflowAssumptions.loeCostPerWellYear5AndUp),
        },
        waterForecastSource: atomField(cashflowAssumptions.waterForecastSource),
        waterGasRatioInBblPerMmcf: atomField(
          cashflowAssumptions.waterGasRatioInBblPerMmcf
        ),
      },
      pricingCaseAttributes: {
        irr: {
          maxPricing: atomField(
            cashflowAssumptions.discountRatesAssumptions.irr.maxPricing
          ),
          targetPricing: atomField(
            cashflowAssumptions.discountRatesAssumptions.irr.targetPricing
          ),
        },
        maxPricing: {
          ltd: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.ltdRate
          ),
          pdp: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.pdpRate
          ),
          duc: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.ducRate
          ),
          permit: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.permitRate
          ),
        },
        targetPricing: {
          ltd: atomField(
            cashflowAssumptions.discountRatesAssumptions.targetPricing.ltdRate
          ),
          pdp: atomField(
            cashflowAssumptions.discountRatesAssumptions.targetPricing.pdpRate
          ),
          duc: atomField(
            cashflowAssumptions.discountRatesAssumptions.targetPricing.ducRate
          ),
          permit: atomField(
            cashflowAssumptions.discountRatesAssumptions.targetPricing
              .permitRate
          ),
        },
        increasedCommissionPricing: {
          ltd: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.ltdRate
          ),
          pdp: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.pdpRate
          ),
          duc: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.ducRate
          ),
          permit: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.permitRate
          ),
        },
        markToMarketPricing: {
          ltd: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .ltdRate
          ),
          pdp: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .pdpRate
          ),
          duc: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .ducRate
          ),
          permit: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .permitRate
          ),
        },
      },
      commissionAdjustmentAttributes: {
        targetPercentage: atomField(
          cashflowAssumptions.commissionAdjustment.targetPercentage
        ),
        maxPercentage: atomField(
          cashflowAssumptions.commissionAdjustment.maxPercentage
        ),
        markToMarketPercentage: atomField(
          cashflowAssumptions.commissionAdjustment.markToMarketPercentage
        ),
      },
    },
  };
};

const numberIsBetweenZeroAndOneHundred = (value: number): number => {
  if (value < 0 || value > 100) {
    throw new Error('Should be between 0 and 100');
  }
  return value;
};

const getDrillingAssumptionsPayload = (
  form: AtomizedForm<EvaluationForm>,
  get: Getter
) => {
  const formValues = getFormValues(
    form.drillingAssumptionAttributes,
    get,
    'all-values'
  );
  const { ltdAttributes, ducAttributes, permitAttributes } = formValues;

  let ltdAttributesObj: ApiPayloads['evaluation']['payload']['drillingAssumptionAttributes']['ltdAttributes'] =
    {
      spudToProductionMonths: ltdAttributes.spudToProductionMonths,
      kind: ltdAttributes.kind,
      typeCurveBlend: ltdAttributes.typeCurveBlend,
    };

  switch (ltdAttributes.kind) {
    case 'per_dev_area':
      ltdAttributesObj = {
        ...ltdAttributesObj,
        firstDrillingStartDate: ltdAttributes.firstDrillingStartDate,
        drillingIntervalMonths: ltdAttributes.drillingIntervalMonths,
      };
      break;
    case 'target_most_recent':
      ltdAttributesObj = {
        ...ltdAttributesObj,
        firstDrillingStartDate: ltdAttributes.firstDrillingStartDate,
        averageWellDrilledYears: ltdAttributes.averageWellDrilledYears,
      };
      break;
    case 'target_as_of':
      ltdAttributesObj = {
        ...ltdAttributesObj,
        firstDrillingStartDate: ltdAttributes.firstDrillingStartDate,
        asOfDate: ltdAttributes.asOfDate,
      };
      break;
    case 'fractional_wells':
      ltdAttributesObj = {
        ...ltdAttributesObj,
        startDate: ltdAttributes.startDate,
        averageWellDrilledYears: ltdAttributes.averageWellDrilledYears,
        drillStartMonths: ltdAttributes.drillStartMonths,
        drillOffsetMonths: ltdAttributes.drillOffsetMonths,
      };
      break;
  }

  let permitAttributesObj: PermitAttributes = {
    kind: permitAttributes.kind,
    typeCurveBlend: permitAttributes.typeCurveBlend,
  };
  let ducAttributesObj: DucAttributes = {
    kind: ducAttributes.kind,
    typeCurveBlend: ducAttributes.typeCurveBlend,
    spudToProductionMonths: ducAttributes.spudToProductionMonths,
  };

  switch (permitAttributes.kind) {
    case 'discrete':
      permitAttributesObj = {
        ...permitAttributesObj,
        permitToProductionMonths: permitAttributes.permitToProductionMonths,
      };
      break;
    case 'fractional_wells':
      permitAttributesObj = {
        ...permitAttributesObj,
        spudToProductionMonths: permitAttributes.spudToProductionMonths,
        startDate: permitAttributes.startDate,
        startMonth: permitAttributes.startMonth,
        endMonth: permitAttributes.endMonth,
      };
      break;
  }

  if (ducAttributes.kind === 'fractional_wells') {
    ducAttributesObj = {
      ...ducAttributesObj,
      startDate: ducAttributes.startDate,
      startMonth: ducAttributes.startMonth,
      endMonth: ducAttributes.endMonth,
    };
  }

  return {
    wellsToExclude: (formValues.wellsToExclude || []).map(
      ({ formationName, slotNumber, uwi }) => ({
        formationName,
        slotNumber,
        uwi,
      })
    ),
    ltdAttributes: ltdAttributesObj,
    permitAttributes: permitAttributesObj,
    ducAttributes: ducAttributesObj,
  };
};

const getCashflowAssumptionsPayload = (
  form: AtomizedForm<EvaluationForm>,
  get: Getter
) => {
  const cashflowFields = form.cashflowAssumptionAttributes;
  const formValues = getFormValues(cashflowFields, get, 'all-values');
  const { priceAttributes, ...restOfFormValues } = formValues;
  const {
    kind,
    stripPriceDate,
    oilFlatPrice,
    gasFlatPrice,
    oilModifiedStripCutoffMonths,
    gasModifiedStripCutoffMonths,
    modifiedStripOilScalingPercentage,
    modifiedStripGasScalingPercentage,
  } = priceAttributes;

  const flat = kind === 'flat';
  const modified =
    kind === 'modified_strip_most_recent' || kind === 'modified_strip_as_of';

  const flatPricesAttributes =
    flat || modified ? { oilFlatPrice, gasFlatPrice } : {};

  const asOfDateAttributes =
    kind === 'strip_as_of' || kind === 'modified_strip_as_of'
      ? { stripPriceDate }
      : {};

  const priceAttributesObj = {
    kind,
    ...flatPricesAttributes,
    ...asOfDateAttributes,
    ...(modified
      ? {
          oilModifiedStripCutoffMonths,
          gasModifiedStripCutoffMonths,
          modifiedStripOilScalingPercentage,
          modifiedStripGasScalingPercentage,
        }
      : {}),
  };

  return { ...restOfFormValues, priceAttributes: priceAttributesObj };
};

const getEvaluationPayload = (
  form: AtomizedForm<EvaluationForm>,
  get: Getter
): ApiPayloads['evaluation']['payload'] => {
  return {
    persist: get(form.persist).value,
    cashflowAssumptionAttributes: getCashflowAssumptionsPayload(form, get),
    drillingAssumptionAttributes: getDrillingAssumptionsPayload(form, get),
  };
};

export { evaluationForm, getEvaluationPayload };
