import { Redo16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { EquationAST, ValueWithContext } from 'types/title-calculations/types';
import { ValueContext } from './calculation-details';
import style from './interest-math.module.scss';
import { formatInterest } from 'utils/format-interest';

const joining = (
  objects: JSX.Element[],
  separator: string,
  index: number
): JSX.Element => {
  if (index >= objects.length) {
    return <></>;
  }
  if (index === 0) {
    return (
      <>
        ({objects[0]}
        {joining(objects, separator, 1)})
      </>
    );
  } else {
    return (
      <>
        {separator}
        {objects[index]}
        {joining(objects, separator, index + 1)}
      </>
    );
  }
};

const parseOperation = (
  obj: EquationAST | ValueWithContext,
  onJump: OperationContextProps['onJump'],
  onSee: OperationContextProps['onSee']
): JSX.Element => {
  // Base case: if the object does not have an operation, return its value
  if (!('operation' in obj)) {
    return <Value value={obj} onSee={onSee} onJump={onJump} />;
  }

  if (obj.children) {
    const childParts = obj.children.map((p) =>
      parseOperation(p, onJump, onSee)
    );

    // Format the operation based on its type
    switch (obj.operation) {
      case '+':
        return joining(childParts, ' + ', 0);
      case '*':
        return joining(childParts, ' * ', 0);
      case '-':
        return joining(childParts, ' - ', 0);
      case '/':
        return joining(childParts, ' / ', 0);
      case 'neg':
        // Assuming 'neg' operation negates the first operand
        return <>-({childParts[0]})</>;
      default:
        return <></>;
    }
  } else {
    // Recursive case: parse the first and second properties
    const firstPart = obj.first ? parseOperation(obj.first, onJump, onSee) : '';
    const secondPart = obj.second
      ? parseOperation(obj.second, onJump, onSee)
      : '';

    // Format the operation based on its type
    switch (obj.operation) {
      case '+':
        return (
          <>
            ({firstPart} + {secondPart})
          </>
        );
      case '*':
        return (
          <>
            ({firstPart} * {secondPart})
          </>
        );
      case '/':
        return (
          <>
            ({firstPart} / {secondPart})
          </>
        );
      case '-':
        return (
          <>
            ({firstPart} - {secondPart})
          </>
        );
      case 'neg':
        // Assuming 'neg' operation negates the first operand
        return <>-({firstPart})</>;
      default:
        return <></>;
    }
  }
};

export type OperationContextProps = {
  onJump: (jumpTo: number) => void;
  onSee: (context: ValueContext) => void;
};

const Operation = ({
  equationAST,
  onJump,
  onSee,
}: { equationAST: EquationAST } & OperationContextProps) => {
  const expression = parseOperation(equationAST, onJump, onSee);

  return expression;
};

const Value = ({
  value,
  onSee,
  onJump,
}: {
  value: ValueWithContext;
} & OperationContextProps) => {
  const formattedInterest = formatInterest(value.value);
  const context = value.context;
  if (!context || 'starting' in context) {
    return <div className={style.alignMiddle}>{formattedInterest}</div>;
  }

  return (
    <div className={style.alignMiddle}>
      <Button
        hasIconOnly
        kind="ghost"
        size="sm"
        iconDescription="Go To Interpretation"
        tooltipAlignment="end"
        tooltipPosition="top"
        renderIcon={Redo16}
        onClick={() => {
          onJump(context.interpretationId);
        }}
      />
      <a
        onClick={() => {
          onSee({
            transactionId: context.transactionId,
            interpretationHref: context.interpretationHref,
            documentTitle: context.document.documentTitle,
          });
        }}
      >
        {formattedInterest}
      </a>
    </div>
  );
};

export { Operation, Value };
