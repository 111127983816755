import { useAtomValue } from 'jotai';
import { Layer, MapRef, Source } from 'react-map-gl';
import { createMapSourceAtom } from './atoms';
import { MapUserConfigAtom } from './types';
import { useMemo } from 'react';

const TierLayer = ({
  mapRef,
  mapConfigAtom,
}: {
  mapRef: MapRef | null;
  mapConfigAtom: MapUserConfigAtom;
}) => {
  const tiersSourceAtom = useMemo(() => createMapSourceAtom('basinTiers'), []);
  const mapConfig = useAtomValue(mapConfigAtom);
  const tiersSource = useAtomValue(tiersSourceAtom);

  if (!tiersSource) return null;

  const beforeLayerId = mapRef?.getMap().getLayer('development-area-fill')
    ? 'development-area-fill'
    : undefined;

  return (
    <Source id="basin-tiers" type="vector" url={tiersSource}>
      {mapConfig.basinTier.options.tier && (
        <Layer
          source-layer="basin-tiers"
          beforeId={beforeLayerId}
          id="tier-fill"
          type="fill"
          paint={{
            'fill-color': [
              'match',
              ['get', 'tier'],
              'Tier 1',
              '#F8D7DA',
              'Tier 2',
              '#FFE5CC',
              'Tier 3',
              '#FFF1CC',
              'Tier 4',
              '#E9F5DB',
              'Tier 5',
              '#D4EDDA',
              'Tier 1A',
              '#D0E0E3',
              'Tier 1B',
              '#D8D2E0',
              'Tier 1C',
              '#F3D9E8',
              '#FCD5CE',
            ],
            'fill-opacity': 0.4,
          }}
        />
      )}
    </Source>
  );
};

export { TierLayer };
