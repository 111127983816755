import { PageHeader } from 'components/page-header';
import { SearchTabs } from 'components/search-tabs';
import { WellsSearch } from 'components/wells-search/wells-search';

const Header = () => {
  return (
    <PageHeader breadcrumbs={[]} title={'Search'} actions={null}>
      <SearchTabs />
    </PageHeader>
  );
};

const WellSearchPage = () => {
  return (
    <>
      <Header />
      <WellsSearch noGutter />
    </>
  );
};

export { WellSearchPage };
