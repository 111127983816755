import { Calendar32 } from '@carbon/icons-react';
import { getError } from 'atoms/form-atoms';
import { Column, Row } from 'carbon-components-react';
import { DatePicker } from 'components/date-picker';

import { FormRow, FormSection } from 'components/forms';
import { getDateString } from 'utils/dates';
import { useAtom, useAtomValue } from 'jotai';
import { formAtom } from './atoms';

const Date = ({
  dateField,
  id,
  label,
}: {
  dateField: 'effectiveDate' | 'instrumentDate' | 'fileDate' | 'recordedDate';
  id: string;
  label: string;
}) => {
  const form = useAtomValue(formAtom);
  const [field, setField] = useAtom(form[dateField]);
  return (
    <DatePicker
      light
      id={id}
      labelText={label}
      value={field.value ?? undefined}
      onChange={(date) => {
        setField(date[0] ? getDateString(date[0]) : null);
      }}
      invalid={!field.isValid}
      invalidText={getError(field)}
    />
  );
};

const Dates = () => {
  return (
    <FormSection title="Document Dates" iconComponent={<Calendar32 />}>
      <FormRow>
        <Row>
          <Column>
            <Date
              id="effective-date"
              label="Effective Date"
              dateField="effectiveDate"
            />
          </Column>
          <Column>
            <Date
              id="instrument-date"
              label="Instrument Date"
              dateField="instrumentDate"
            />
          </Column>
          <Column>
            <Date id="file-date" label="File Date" dateField="fileDate" />
          </Column>
          <Column>
            <Date
              id="recorded-date"
              label="Recorded Date"
              dateField="recordedDate"
            />
          </Column>
        </Row>
      </FormRow>
    </FormSection>
  );
};

export { Dates, Date };
