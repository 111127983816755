import { InlineLoading, InlineNotification } from 'carbon-components-react';
import { Sensitivities } from 'components/sensitivities';
import { useAtomValue } from 'jotai';
import { useCreateEvaluationPackageCashflowsAtom } from './atoms';

const PackageSensitivities = ({ href }: { href: string }) => {
  const cashflowAtom = useCreateEvaluationPackageCashflowsAtom(href);
  const cashflow = useAtomValue(cashflowAtom);

  if (cashflow.data && 'error' in cashflow.data)
    return (
      <InlineNotification
        kind="error"
        title={cashflow.data.error}
        lowContrast
      />
    );

  if (cashflow.loading) return <InlineLoading title="Loading sensitivities" />;

  if (!cashflow.data) {
    return (
      <InlineNotification
        kind="error"
        title={'Something went wrong'}
        lowContrast
      />
    );
  }

  const sensitivities = cashflow.data.sensitivities;

  return (
    <>
      <Sensitivities cashflows={sensitivities} />
    </>
  );
};

export { PackageSensitivities };
