import { ToastNotification } from 'carbon-components-react';

import style from './success-toast.module.scss';

const TIMEOUT = 5000;

interface Props {
  message: string;
  subtitle?: string;
  onClose?: () => boolean;
}

export function SuccessToast({ message, subtitle, onClose }: Props) {
  return (
    <div className={style.successToast}>
      <ToastNotification
        title={message}
        kind="success"
        subtitle={subtitle}
        lowContrast
        onClose={onClose}
        timeout={TIMEOUT}
      />
    </div>
  );
}
