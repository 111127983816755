import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import { ReferenceLocationResponse } from 'types/api-responses';

const useReferenceLocationWithGeometryAtom = (href: string) => {
  return useMemo(
    () => createResourceAtom<ReferenceLocationResponse>(href),
    [href]
  );
};

export { useReferenceLocationWithGeometryAtom };
