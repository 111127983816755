import {
  Checkbox,
  Tile,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from 'carbon-components-react';
import { AtomField, AtomizedForm, required } from 'atoms/form-atoms';
import { useMemo } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import style from './discount-rate-assumptions.module.scss';
import { NumberField } from 'components/form-fields/number-field';
import { CashflowAssumptionsParameters } from './types';

const useValidateEconomicLimitsAtom = (
  applyLimit: AtomField<boolean>,
  limit: AtomField<number>
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const applyLimitValue = get(applyLimit).value;

        if (applyLimitValue) {
          set(limit, { validate: required });
        } else {
          set(limit, { validate: (v) => v });
        }
      }
    );
  }, [applyLimit, limit]);

  return useSetAtom(validateAtom);
};

const HurdleRates = ({
  form,
}: {
  form: AtomizedForm<CashflowAssumptionsParameters>;
}) => {
  const hurdleRates = form.economicLimitAttributes;

  const [{ value: applyLtdHurdleRate }, setApplyLtdHurdleRate] = useAtom(
    hurdleRates.applyLTDHurdleRate
  );
  const validateLtdHurdleRate = useValidateEconomicLimitsAtom(
    hurdleRates.applyLTDHurdleRate,
    hurdleRates.ltdHurdleRate
  );

  const [{ value: applyDucHurdleRate }, setApplyDucHurdleRate] = useAtom(
    hurdleRates.applyDUCHurdleRate
  );
  const validateDucHurdleRate = useValidateEconomicLimitsAtom(
    hurdleRates.applyDUCHurdleRate,
    hurdleRates.ducHurdleRate
  );

  const [{ value: applyPermitHurdleRate }, setApplyPermitHurdleRate] = useAtom(
    hurdleRates.applyPermitHurdleRate
  );
  const validatePermitHurdleRate = useValidateEconomicLimitsAtom(
    hurdleRates.applyPermitHurdleRate,
    hurdleRates.permitHurdleRate
  );

  return (
    <TableRow>
      <TableCell colSpan={3}>Hurdle Rates</TableCell>
      <TableCell></TableCell>
      <TableCell className={style.hurdleRateCell}>
        <NumberField
          id="hurdleRateDuc"
          light
          min={0}
          invalidText="Must be positive number"
          field={hurdleRates.ducHurdleRate}
          disabled={!applyDucHurdleRate}
        />
        <Checkbox
          id={'applyDucHurdleRate'}
          className={style.hurdleRateCheckbox}
          checked={applyDucHurdleRate}
          onChange={(checked: boolean) => {
            setApplyDucHurdleRate(checked);
            validateDucHurdleRate();
          }}
          labelText="Apply"
        />
      </TableCell>
      <TableCell>
        <NumberField
          id="hurdleRatePermit"
          light
          min={0}
          invalidText="Must be positive number"
          field={hurdleRates.permitHurdleRate}
          disabled={!applyPermitHurdleRate}
        />
        <Checkbox
          id={'applyPermitHurdleRate'}
          className={style.hurdleRateCheckbox}
          checked={applyPermitHurdleRate}
          onChange={(checked: boolean) => {
            setApplyPermitHurdleRate(checked);
            validatePermitHurdleRate();
          }}
          labelText="Apply"
        />
      </TableCell>
      <TableCell>
        <NumberField
          id="hurdleRateLtd"
          light
          min={0}
          invalidText="Must be positive number"
          field={hurdleRates.ltdHurdleRate}
          disabled={!applyLtdHurdleRate}
        />
        <Checkbox
          id={'applyLtdHurdleRate'}
          className={style.hurdleRateCheckbox}
          checked={applyLtdHurdleRate}
          onChange={(checked: boolean) => {
            setApplyLtdHurdleRate(checked);
            validateLtdHurdleRate();
          }}
          labelText="Apply"
        />
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

const DiscountRateAssumptions = ({
  form,
}: {
  form: AtomizedForm<CashflowAssumptionsParameters>;
}) => {
  const pricingCaseAttributes = form.pricingCaseAttributes;
  const increasedCommissionPricing =
    pricingCaseAttributes.increasedCommissionPricing;
  const targetPricing = pricingCaseAttributes.targetPricing;
  const irr = pricingCaseAttributes.irr;
  const maxPricing = pricingCaseAttributes.maxPricing;
  const markToMarketPricing = pricingCaseAttributes.markToMarketPricing;

  return (
    <>
      <Tile>
        <h2 className={style.subtitle}>Discounts rates assumptions</h2>
        <TableContainer>
          <Table
            aria-label="Discounts rates"
            size="short"
            className={irr ? style.discountTableIRR : style.discountTable}
          >
            <TableHead>
              <TableRow>
                <TableHeader scope="col" colSpan={3} className={style.titleCol}>
                  Discount Rate
                </TableHeader>
                <TableHeader scope="col">PDP (%)</TableHeader>
                <TableHeader scope="col">DUC (%)</TableHeader>
                <TableHeader scope="col">PERMIT (%)</TableHeader>
                <TableHeader scope="col">LTD (%)</TableHeader>
                {irr ? <TableHeader scope="col">IRR (%)</TableHeader> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={style.increasedCommissionsSection}>
                <TableCell colSpan={3}>
                  Increased Commission (Acquisition)
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.ltd}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Target (Acquisition)</TableCell>
                <TableCell>
                  <NumberField
                    id="targetPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="targetDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="targetPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="targetLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.ltd}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="targetIrr"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={irr.targetPricing}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Max (Acquisition)</TableCell>
                <TableCell>
                  <NumberField
                    id="maxPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="maxDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="maxPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="maxLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.ltd}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="maxIrr"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={irr.maxPricing}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Mark to market (MTM)</TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.ltd}
                  />
                </TableCell>
                {irr ? <TableCell></TableCell> : null}
              </TableRow>
              <HurdleRates form={form} />
            </TableBody>
          </Table>
        </TableContainer>
      </Tile>
    </>
  );
};

export { DiscountRateAssumptions };
