import { useState } from 'react';
import { Copy16, Task16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';

export function CopyButton({ node }: { node: string | number | boolean }) {
  const [copied, setCopied] = useState(false);

  const copyHandler: React.MouseEventHandler<React.ReactSVGElement> = (
    event
  ) => {
    event.stopPropagation();

    const value = typeof node === 'string' ? node.replace('"', '') : node;

    if (value) {
      navigator.clipboard.writeText(String(value));
    }

    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return copied ? (
    <Button
      size="sm"
      hasIconOnly
      kind={'ghost'}
      iconDescription={'Copied!'}
      tooltipAlignment="center"
      tooltipPosition="top"
      renderIcon={Task16}
    />
  ) : (
    <Button
      size="sm"
      hasIconOnly
      kind={'ghost'}
      iconDescription={'Copy text'}
      tooltipAlignment="center"
      tooltipPosition="top"
      renderIcon={Copy16}
      onClick={copyHandler}
    />
  );
}
