import { InlineNotification } from 'carbon-components-react';
import { useAtomValue } from 'jotai';
import { errorsAtom } from './atoms';

const Errors = () => {
  const errors = useAtomValue(errorsAtom);

  if (!errors) {
    return null;
  }

  return (
    <InlineNotification
      kind="error"
      lowContrast
      title="Error!"
      subtitle={errors}
    />
  );
};

export { Errors };
