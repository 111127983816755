import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Link,
  DataTableCustomRenderProps,
} from 'carbon-components-react';
import style from './title_workspace.module.scss';
import { Error16 } from '@carbon/icons-react';
import { CalculationOutputRow } from 'types/api-responses';
import { ReactNode } from 'react';
import { Intersect } from 'types/utils';
import { LinkWithHover } from 'components/hover-card-resource';

const headersMinerals = [
  {
    key: 'entityNameId',
    header: 'Party',
  },
  {
    key: 'executive',
    header: 'Executive',
  },
  {
    key: 'bonus',
    header: 'Bonus',
  },
  {
    key: 'delay',
    header: 'Delay',
  },
  {
    key: 'royalty',
    header: 'Royalty',
  },
  {
    key: 'netRevenueInterest',
    header: 'NRI',
  },
  {
    key: 'overConveyance',
    header: 'Warnings',
  },
];
const headersSurface = [
  {
    key: 'entityNameId',
    header: 'Party',
  },
  {
    key: 'surface',
    header: 'Surface',
  },
  {
    key: 'overConveyance',
    header: 'Warnings',
  },
];
export interface InterestProps {
  type: 'surface' | 'mineral';
  rows: CalculationOutputRow[] | undefined;
  onPartySelected: (party: string) => void;
  graphAvailable?: boolean;
}

export function InterestTable({
  type,
  rows,
  onPartySelected,
  graphAvailable,
  ...rest
}: InterestProps) {
  const augmentedRows = rows?.map<
    Intersect<
      CalculationOutputRow,
      {
        id: string;
        entityNameId: string;
        overConveyance: ReactNode | false;
        entityName: string;
      }
    >
  >((row, index) => ({
    ...row,
    id: String(index),
    entityNameId: `${row.entityName} (${row.entityId})`,
    entityName: `${row.entityName}`,
    overConveyance: row.preventedOverconveyance && (
      <div className={style.errorContainer}>
        <Error16 className={style.errorIcon}></Error16>
        <p className={style.errorText}>Over Conveyance</p>
      </div>
    ),
  }));

  return augmentedRows ? (
    <DataTable
      rows={augmentedRows}
      headers={type === 'mineral' ? headersMinerals : headersSurface}
      size="sm"
      isSortable
    >
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
      }: DataTableCustomRenderProps) => (
        <TableContainer className={style.table} {...rest}>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  return (
                    <TableHeader
                      {...getHeaderProps({ header })}
                      key={header.key}
                    >
                      {header.header}
                    </TableHeader>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow {...getRowProps({ row })} key={row.id}>
                    {row.cells.map((cell, cellIndex) => {
                      const cellKey =
                        cell.id + '_' + rowIndex + '_' + cellIndex;
                      const str = cell.id;
                      const augmentedRowIndex = Number(str.replace(/\D/g, ''));
                      if (cellIndex === 0) {
                        return (
                          <TableCell key={cellKey}>
                            {graphAvailable ? (
                              <LinkWithHover
                                kind="entity"
                                href={
                                  augmentedRows[augmentedRowIndex].entityHref
                                }
                              >
                                <Link
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    onPartySelected(
                                      augmentedRows[augmentedRowIndex]
                                        .entityName
                                    )
                                  }
                                >
                                  {cell.value}
                                </Link>
                              </LinkWithHover>
                            ) : (
                              cell.value
                            )}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cellKey}>{cell.value}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  ) : null;
}
