import { AtomizedForm, atomField, required } from 'atoms/form-atoms';
import { ScenariosForm } from './types';
import {
  ScenarioAssumptionsResponse,
  ScenarioDevelopmentAreaExceptionsResponse,
} from 'types/scenarios-api/responses';
import { FormRowStatus } from 'atoms/create-resource-atom';
import { RecursiveOmit } from 'types/utils';
import { createWellToExcludeElement } from 'utils/wells-to-exclude';

const scenarioForm = (
  assumptions:
    | ScenarioAssumptionsResponse
    | (Omit<
        ScenarioAssumptionsResponse,
        'developmentAreas' | 'id' | 'name' | 'tiers'
      > & {
        developmentAreas: Omit<
          ScenarioAssumptionsResponse['developmentAreas'][0],
          'id'
        >[];
        tiers: RecursiveOmit<ScenarioAssumptionsResponse['tiers'], 'id'>;
      })
): AtomizedForm<ScenariosForm> => {
  const { globalAssumptions, tiers, developmentAreas } = assumptions;

  const { drillingAssumptions, cashflowAssumptions } = globalAssumptions;
  // eslint-disable-next-line no-var
  var form = {
    name: atomField('name' in assumptions ? assumptions.name : '', {
      validate: required,
    }),
    interestType: atomField(
      'interestType' in assumptions ? assumptions.interestType : '',
      { validate: required }
    ),
    globalAssumptions: {
      drillingAssumptionAttributes: {
        spudToProductionMonths: atomField(
          drillingAssumptions.spudToProductionMonths,
          {
            validate: required,
          }
        ),
        startDateType: atomField<'fixed' | 'floating'>(
          drillingAssumptions.startDateType
        ),
        startDate: atomField(drillingAssumptions.startDate, {
          // We need to programmatically set the value of this field to the same value as the input.
          // If we don't change the `areEqual` definition, setting the value to the same date won't
          // set the isDirty and the field won't be sent to the server if startDateType is set to fixed.
          areEqual: () => false,
        }),
        ltdAttributes: {
          kind: atomField<
            ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']['kind']
          >(drillingAssumptions.ltdAssumptionsType),
          typeCurveBlend: atomField<boolean>(drillingAssumptions.ltdBlend),
          firstDrillingStartDate: atomField(
            drillingAssumptions.ltdAttributes.firstDrillingStartDate
          ),
          drillingIntervalMonths: atomField(
            drillingAssumptions.ltdAttributes.drillingIntervalMonths
          ),
          drillStartMonths: atomField(
            drillingAssumptions.ltdAttributes.drillStartMonths
          ),

          drillOffsetMonths: atomField(
            drillingAssumptions.ltdAttributes.drillOffsetMonths
          ),
          averageWellDrilledYears: atomField(
            drillingAssumptions.ltdAttributes.averageWellDrilledYears
          ),
          asOfDate: atomField(drillingAssumptions.ltdAttributes.asOfDate),
        },
        ducAttributes: {
          kind: atomField(drillingAssumptions.ducAttributes.kind),
          typeCurveBlend: atomField(
            drillingAssumptions.ducAttributes.typeCurveBlend
          ),
          startMonth: atomField(drillingAssumptions.ducAttributes.startMonth),
          endMonth: atomField(drillingAssumptions.ducAttributes.endMonth),
        },
        permitAttributes: {
          kind: atomField(drillingAssumptions.permitAttributes.kind),
          typeCurveBlend: atomField(
            drillingAssumptions.permitAttributes.typeCurveBlend
          ),
          permitToProductionMonths: atomField(
            drillingAssumptions.permitAttributes.permitToProductionMonths
          ),
          startMonth: atomField(
            drillingAssumptions.permitAttributes.startMonth
          ),
          endMonth: atomField(drillingAssumptions.permitAttributes.endMonth),
        },
        infillAttributes: {
          includeUpside: atomField(
            drillingAssumptions.infillAttributes.includeUpside
          ),
          formationLimitPercentage: atomField(
            drillingAssumptions.infillAttributes.formationLimitPercentage,
            { validate: required }
          ),
          developmentAreaLimitPercentage: atomField(
            drillingAssumptions.infillAttributes.developmentAreaLimitPercentage,
            { validate: required }
          ),
          ltdLimit: atomField(drillingAssumptions.infillAttributes.ltdLimit),
        },
      },
      cashflowAssumptionAttributes: {
        effectiveDate: atomField(cashflowAssumptions.effectiveDate, {
          validate: required,
        }),
        effectiveDateType: atomField<'fixed' | 'floating'>(
          cashflowAssumptions.effectiveDateType || 'fixed'
        ),
        discountToDate: atomField(cashflowAssumptions.discountToDate, {
          validate: required,
        }),
        discountToDateType: atomField<'fixed' | 'floating'>(
          cashflowAssumptions.discountToDateType || 'fixed'
        ),
        royaltyPercentage: atomField(cashflowAssumptions.royaltyPercentage, {
          validate: required,
        }),
        orriPercentage: atomField(cashflowAssumptions.orriPercentage, {
          validate: required,
        }),
        differentialAttributes: {
          oilPrice: atomField(cashflowAssumptions.oilDifferentialPrice, {
            validate: required,
          }),
          gasPrice: atomField(cashflowAssumptions.gasDifferentialPrice, {
            validate: required,
          }),
          nglPercentage: atomField(
            cashflowAssumptions.nglDifferentialPercentage,
            {
              validate: required,
            }
          ),
        },
        reservoirAssumptionAttributes: {
          gasShrinkPercentage: atomField(
            cashflowAssumptions.gasShrinkPercentage,
            {
              validate: required,
            }
          ),
          nglYield: atomField(cashflowAssumptions.nglYield, {
            validate: required,
          }),
        },
        taxPercentageAttributes: {
          oil: atomField(cashflowAssumptions.oilTaxPercentage, {
            validate: required,
          }),
          gas: atomField(cashflowAssumptions.gasTaxPercentage, {
            validate: required,
          }),
          ngl: atomField(cashflowAssumptions.nglTaxPercentage, {
            validate: required,
          }),
          adValorem: atomField(cashflowAssumptions.adValoremTaxPercentage, {
            validate: required,
          }),
        },
        priceAttributes: {
          kind: atomField<
            ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']['priceAttributes']['kind']
          >(cashflowAssumptions.priceType),
          gasModifiedStripCutoffMonths: atomField(
            cashflowAssumptions.gasModifiedStripCutoffMonths
          ),
          oilModifiedStripCutoffMonths: atomField(
            cashflowAssumptions.oilModifiedStripCutoffMonths
          ),
          modifiedStripOilScalingPercentage: atomField(
            cashflowAssumptions.modifiedStripOilScalingPercentage
          ),
          modifiedStripGasScalingPercentage: atomField(
            cashflowAssumptions.modifiedStripGasScalingPercentage
          ),
          stripPriceDate: atomField(cashflowAssumptions.stripPriceDate),
          oilFlatPrice: atomField(cashflowAssumptions.oilFlatPrice),
          gasFlatPrice: atomField(cashflowAssumptions.gasFlatPrice),
        },
        leaseholdAttributes: leaseholdAttributes(cashflowAssumptions),
        pricingCaseAttributes: {
          irr: {
            maxPricing: atomField(
              cashflowAssumptions.discountRatesAssumptions.irr.maxPricing,
              {
                validate: required,
              }
            ),
            targetPricing: atomField(
              cashflowAssumptions.discountRatesAssumptions.irr.targetPricing,
              {
                validate: required,
              }
            ),
          },
          maxPricing: {
            ltd: atomField(
              cashflowAssumptions.discountRatesAssumptions.maxPricing.ltdRate,
              {
                validate: required,
              }
            ),
            pdp: atomField(
              cashflowAssumptions.discountRatesAssumptions.maxPricing.pdpRate,
              {
                validate: required,
              }
            ),
            duc: atomField(
              cashflowAssumptions.discountRatesAssumptions.maxPricing.ducRate,
              {
                validate: required,
              }
            ),
            permit: atomField(
              cashflowAssumptions.discountRatesAssumptions.maxPricing
                .permitRate,
              {
                validate: required,
              }
            ),
          },
          targetPricing: {
            ltd: atomField(
              cashflowAssumptions.discountRatesAssumptions.targetPricing
                .ltdRate,
              {
                validate: required,
              }
            ),
            pdp: atomField(
              cashflowAssumptions.discountRatesAssumptions.targetPricing
                .pdpRate,
              {
                validate: required,
              }
            ),
            duc: atomField(
              cashflowAssumptions.discountRatesAssumptions.targetPricing
                .ducRate,
              {
                validate: required,
              }
            ),
            permit: atomField(
              cashflowAssumptions.discountRatesAssumptions.targetPricing
                .permitRate,
              {
                validate: required,
              }
            ),
          },
          increasedCommissionPricing: {
            ltd: atomField(
              cashflowAssumptions.discountRatesAssumptions
                .increasedCommissionPricing.ltdRate,
              {
                validate: required,
              }
            ),
            pdp: atomField(
              cashflowAssumptions.discountRatesAssumptions
                .increasedCommissionPricing.pdpRate,
              {
                validate: required,
              }
            ),
            duc: atomField(
              cashflowAssumptions.discountRatesAssumptions
                .increasedCommissionPricing.ducRate,
              {
                validate: required,
              }
            ),
            permit: atomField(
              cashflowAssumptions.discountRatesAssumptions
                .increasedCommissionPricing.permitRate,
              {
                validate: required,
              }
            ),
          },
          markToMarketPricing: {
            ltd: atomField(
              cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
                .ltdRate,
              {
                validate: required,
              }
            ),
            pdp: atomField(
              cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
                .pdpRate,
              {
                validate: required,
              }
            ),
            duc: atomField(
              cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
                .ducRate,
              {
                validate: required,
              }
            ),
            permit: atomField(
              cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
                .permitRate,
              {
                validate: required,
              }
            ),
          },
        },
        commissionAdjustmentAttributes:
          commissionAdjustmentAttributes(cashflowAssumptions),
        economicLimitAttributes: economicLimitAttributes(cashflowAssumptions),
      },
    },
    tiersAttributes: atomField(tiers.map((el) => tiersRow({ tier: el })) || []),
    developmentAreasAttributes: atomField(
      // TODO remove undefined check as devAreaExceptions should not be undefined
      // once the backend is ready
      (developmentAreas || []).map((el) => developmentAreaRow(el) || [])
    ),
  };

  return form;
};

const tiersRow = ({
  tier,
  basinName,
  tierName,
}: {
  tier:
    | ScenarioAssumptionsResponse['tiers'][0]
    | RecursiveOmit<ScenarioAssumptionsResponse['tiers'][0], 'id'>
    | ScenarioAssumptionsResponse['globalAssumptions'];
  basinName?: string;
  tierName?: string;
}) => {
  const cashflowAssumptions = tier.cashflowAssumptions;
  const spudToProductionMonths =
    'spudToProductionMonths' in tier.drillingAssumptions
      ? tier.drillingAssumptions.spudToProductionMonths
      : 'spudToProductionMonths' in tier.drillingAssumptions.ltdAttributes
      ? tier.drillingAssumptions.ltdAttributes.spudToProductionMonths
      : 0;
  const dirty = 'id' in tier ? false : true;
  const form = {
    _rowStatus: atomField<FormRowStatus>(
      tier && 'id' in tier ? 'saved' : 'new'
    ),
    id: atomField(tier && 'id' in tier ? tier?.id : null),
    basin: atomField(
      (tier && 'basin' in tier && tier?.basin) || basinName || '',
      {
        dirty,
      }
    ),
    tier: atomField((tier && 'tier' in tier && tier?.tier) || tierName || '', {
      dirty,
    }),
    drillingAssumptionAttributes: {
      ltdAttributes: {
        averageWellDrilledYears: atomField(
          tier.drillingAssumptions.ltdAttributes.averageWellsDrilledYears,
          {
            validate: required,
            dirty,
          }
        ),
        spudToProductionMonths: atomField(spudToProductionMonths, {
          validate: required,
          dirty,
        }),
        drillStartMonths: atomField(
          tier.drillingAssumptions.ltdAttributes.drillStartMonths,
          {
            validate: required,
            dirty,
          }
        ),
      },
      infillAttributes: {
        includeUpside: atomField(
          tier.drillingAssumptions.infillAttributes.includeUpside || false,
          {
            dirty,
          }
        ),
        formationLimitPercentage: atomField(
          tier.drillingAssumptions.infillAttributes.formationLimitPercentage,
          {
            validate: required,
            dirty,
          }
        ),
        developmentAreaLimitPercentage: atomField(
          tier.drillingAssumptions.infillAttributes
            .developmentAreaLimitPercentage,
          {
            validate: required,
            dirty,
          }
        ),
        ltdLimit: atomField(
          tier.drillingAssumptions.infillAttributes.ltdLimit,
          {
            dirty,
          }
        ),
      },
    },
    cashflowAssumptionAttributes: {
      royaltyPercentage: atomField(cashflowAssumptions.royaltyPercentage, {
        validate: required,
        dirty,
      }),
      orriPercentage: atomField(cashflowAssumptions.orriPercentage, {
        validate: required,
        dirty,
      }),
      differentialAttributes: {
        oilPrice: atomField(cashflowAssumptions.oilDifferentialPrice, {
          validate: required,
          dirty,
        }),
        gasPrice: atomField(cashflowAssumptions.gasDifferentialPrice, {
          validate: required,
          dirty,
        }),
        nglPercentage: atomField(
          cashflowAssumptions.nglDifferentialPercentage,
          {
            validate: required,
            dirty,
          }
        ),
      },
      reservoirAssumptionAttributes: {
        gasShrinkPercentage: atomField(
          cashflowAssumptions.gasShrinkPercentage,
          {
            validate: required,
            dirty,
          }
        ),
        nglYield: atomField(cashflowAssumptions.nglYield, {
          validate: required,
          dirty,
        }),
      },
      pricingCaseAttributes: {
        maxPricing: {
          ltd: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.ltdRate,
            {
              validate: required,
              dirty,
            }
          ),
          pdp: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.pdpRate,
            {
              validate: required,
              dirty,
            }
          ),
          duc: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.ducRate,
            {
              validate: required,
              dirty,
            }
          ),
          permit: atomField(
            cashflowAssumptions.discountRatesAssumptions.maxPricing.permitRate,
            {
              validate: required,
              dirty,
            }
          ),
        },
        targetPricing: {
          ltd: atomField(
            cashflowAssumptions?.discountRatesAssumptions.targetPricing.ltdRate,
            {
              validate: required,
              dirty,
            }
          ),
          pdp: atomField(
            cashflowAssumptions?.discountRatesAssumptions.targetPricing.pdpRate,
            {
              validate: required,
              dirty,
            }
          ),
          duc: atomField(
            cashflowAssumptions?.discountRatesAssumptions.targetPricing.ducRate,
            {
              validate: required,
              dirty,
            }
          ),
          permit: atomField(
            cashflowAssumptions?.discountRatesAssumptions.targetPricing
              .permitRate,
            {
              validate: required,
              dirty,
            }
          ),
        },
        increasedCommissionPricing: {
          ltd: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.ltdRate
          ),
          pdp: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.pdpRate
          ),
          duc: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.ducRate
          ),
          permit: atomField(
            cashflowAssumptions.discountRatesAssumptions
              .increasedCommissionPricing.permitRate
          ),
        },
        markToMarketPricing: {
          ltd: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .ltdRate,
            {
              validate: required,
              dirty,
            }
          ),
          pdp: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .pdpRate,
            {
              validate: required,
              dirty,
            }
          ),
          duc: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .ducRate,
            {
              validate: required,
              dirty,
            }
          ),
          permit: atomField(
            cashflowAssumptions.discountRatesAssumptions.markToMarketPricing
              .permitRate,
            {
              validate: required,
              dirty,
            }
          ),
        },
      },
      leaseholdAttributes: leaseholdAttributes(cashflowAssumptions, dirty),
      economicLimitAttributes: economicLimitAttributes(
        cashflowAssumptions,
        dirty
      ),
    },
  };

  return form;
};

const developmentAreaRow = (
  el:
    | ScenarioDevelopmentAreaExceptionsResponse
    | Omit<ScenarioDevelopmentAreaExceptionsResponse, 'id'>
) => {
  const dirty = 'id' in el ? false : true;
  return {
    _rowStatus: atomField<FormRowStatus>('id' in el ? 'saved' : 'new'),
    id: atomField('id' in el ? el.id : null),
    developmentAreaId: atomField(el.developmentArea.id, {
      dirty,
    }),
    href: atomField(el.developmentArea.href),
    developmentAreaName: atomField(el.developmentArea.name),
    wellsToExclude: atomField(
      el.wellsToExclude.map((slot) =>
        createWellToExcludeElement(slot, dirty ? 'new' : 'saved')
      ),
      { dirty }
    ),
  };
};

const leaseholdAttributes = (
  cashflowAssumptions:
    | ScenarioAssumptionsResponse['globalAssumptions']['cashflowAssumptions']
    | ScenarioAssumptionsResponse['tiers'][0]['cashflowAssumptions'],
  dirty = false
) => {
  return {
    dcCostPerWell: atomField(cashflowAssumptions.dcCostPerWell, {
      validate: required,
      dirty,
    }),
    percentageCostFirstProductionDate: atomField(
      cashflowAssumptions.percentageCostFirstProductionDate,
      {
        validate: required,
        dirty,
      }
    ),
    percentageCostSpudDate: atomField(
      cashflowAssumptions.percentageCostSpudDate,
      {
        validate: required,
        dirty,
      }
    ),
    opexCostAttributes: {
      gas: atomField(cashflowAssumptions.gasOpexCost, {
        validate: required,
        dirty,
      }),
      oil: atomField(cashflowAssumptions.oilOpexCost, {
        validate: required,
        dirty,
      }),
      water: atomField(cashflowAssumptions.waterOpexCost, {
        validate: required,
        dirty,
      }),
    },
    loePerWellCostAttributes: {
      year1: atomField(cashflowAssumptions.loeCostPerWellYear1, {
        validate: required,
        dirty,
      }),
      year2: atomField(cashflowAssumptions.loeCostPerWellYear2, {
        validate: required,
        dirty,
      }),
      year3: atomField(cashflowAssumptions.loeCostPerWellYear3, {
        validate: required,
        dirty,
      }),
      year4: atomField(cashflowAssumptions.loeCostPerWellYear4, {
        validate: required,
        dirty,
      }),
      year5AndUp: atomField(cashflowAssumptions.loeCostPerWellYear5AndUp, {
        validate: required,
        dirty,
      }),
    },
    waterForecastSource: atomField(
      cashflowAssumptions.waterForecastSource || null,
      {
        validate: required,
        dirty,
      }
    ),
    waterGasRatioInBblPerMmcf: atomField(
      cashflowAssumptions.waterGasRatioInBblPerMmcf,
      { dirty }
    ),
    waterCutPercentage: atomField(cashflowAssumptions?.waterCutPercentage, {
      dirty,
    }),
  };
};

const commissionAdjustmentAttributes = (
  cashflowAssumptions: ScenarioAssumptionsResponse['globalAssumptions']['cashflowAssumptions'],
  dirty = false
) => {
  return {
    targetPercentage: atomField(
      cashflowAssumptions.commissionAdjustment.targetPercentage,
      { validate: required, dirty }
    ),
    maxPercentage: atomField(
      cashflowAssumptions.commissionAdjustment.maxPercentage,
      { validate: required, dirty }
    ),
    markToMarketPercentage: atomField(
      cashflowAssumptions.commissionAdjustment.markToMarketPercentage,
      { validate: required, dirty }
    ),
  };
};

const economicLimitAttributes = (
  cashflowAssumptions:
    | ScenarioAssumptionsResponse['globalAssumptions']['cashflowAssumptions']
    | ScenarioAssumptionsResponse['tiers'][0]['cashflowAssumptions'],
  dirty = false
) => {
  return {
    shutInLimit: atomField(cashflowAssumptions.economicLimit.shutInLimit, {
      dirty,
    }),
    applyShutInLimit: atomField(
      cashflowAssumptions.economicLimit.applyShutInLimit,
      {
        dirty,
      }
    ),
    ltdHurdleRate: atomField(cashflowAssumptions.economicLimit.ltdHurdleRate, {
      dirty,
    }),
    applyLTDHurdleRate: atomField(
      cashflowAssumptions.economicLimit.applyLTDHurdleRate,
      {
        dirty,
      }
    ),
    ducHurdleRate: atomField(cashflowAssumptions.economicLimit.ducHurdleRate, {
      dirty,
    }),
    applyDUCHurdleRate: atomField(
      cashflowAssumptions.economicLimit.applyDUCHurdleRate,
      {
        dirty,
      }
    ),
    permitHurdleRate: atomField(
      cashflowAssumptions.economicLimit.permitHurdleRate,
      {
        dirty,
      }
    ),
    applyPermitHurdleRate: atomField(
      cashflowAssumptions.economicLimit.applyPermitHurdleRate,
      {
        dirty,
      }
    ),
  };
};

export {
  createWellToExcludeElement,
  developmentAreaRow,
  scenarioForm,
  tiersRow,
};
