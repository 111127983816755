import { subscribe } from 'lib/pusher';
import request from 'lib/request';
import { useState } from 'react';
import { DownloadResponse, DownloadTrackerResponse } from 'types/api-responses';

const useDownloadResource = <Payload = unknown>(
  downloadHref?: string,
  payload?: Payload
) => {
  const [generatingDownload, setGeneratingDownload] = useState(false);

  const triggerDownload = async (
    onGeneratingDownload?: (loading: boolean) => void
  ) => {
    if (!downloadHref) return;
    setGeneratingDownload(true);
    onGeneratingDownload?.(true);

    const downloadChannelResponse = await request.post<
      DownloadTrackerResponse,
      Payload
    >(downloadHref, payload ?? null, new AbortController());

    if (downloadChannelResponse.type === 'success') {
      const downloadChannel = downloadChannelResponse.data;
      const channel = downloadChannel.notifications.channel;
      const event = downloadChannel.notifications.events[0].name;
      subscribeToChannel(channel, event, downloadChannel.href, () => {
        setGeneratingDownload(false);
        onGeneratingDownload?.(false);
      });
    }
  };

  return {
    generatingDownload,
    setGeneratingDownload,
    triggerDownload,
  };
};

export function subscribeToChannel(
  channel: string,
  eventName: string,
  href: string,
  onComplete: () => void
) {
  const subscription = subscribe(channel);
  subscription.bind(eventName, async () => {
    const downloadData = await request.get<DownloadResponse>(href);

    if ('error' in downloadData) return;

    if (downloadData.status === 'processed') {
      window.open(downloadData.fileUrl, '_blank', 'noopener,noreferrer');
      subscription.unsubscribe();
      onComplete();
    }
  });
}

export type UseDownloadResource = ReturnType<typeof useDownloadResource>;
export { useDownloadResource };
