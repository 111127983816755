import { getError } from 'atoms/create-resource-atom';
import { Button, Column, Grid, Row, TextInput } from 'carbon-components-react';
import { Copy } from 'components/copy';
import { FormRow, FormSection } from 'components/forms';
import { DeleteRowButton } from 'components/interpretation-form/delete-row-button';
import { QuarterCalls } from 'components/quarter-calls';
import { SurveyAreaAutocomplete } from 'components/survey-area-autocomplete';
import { PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import { splitAtom } from 'jotai/utils';
import { formAtoms, geographiesAtom } from './atoms';
import { createSurveyAreaSubform, SurveyAreaSubformAtoms } from './utils';

import style from './development-area-wizard.module.scss';
import { Errors } from './errors';

export const MAX_GEOGRAPHIES = 5;

const StepOne = () => {
  const [fields, setField] = useAtom(useAtomValue(formAtoms).formAtom);
  const geographiesAtom = splitAtom(fields.surveyAreas.value);
  const [geographies, setGeographies] = useAtom(geographiesAtom);

  return (
    <>
      <FormSection>
        <Copy
          heading="Development Area Name"
          subheading="What would you like to name the area?"
        />
        <FormRow>
          <TextInput
            value={fields.name.value || ''}
            onChange={({ target: { value } }) => {
              setField({ field: 'name', value });
            }}
            id="create-dev-area-name"
            labelText="Name"
            autoFocus
            invalid={!!getError(fields.name)}
            invalidText={getError(fields.name)}
          />
        </FormRow>
      </FormSection>

      <FormSection>
        <Copy heading="Define Area" subheading="Where is this area located?">
          <p className={style.copy}>
            Use survey area to define the starting point for the development
            area then you can add additional survey areas. All selected survey
            areas must be adjacent to each other.
          </p>
        </Copy>
        <Errors />
        {geographies.map((geography, i) => (
          <SurveyAreaRow
            key={i}
            atomIndex={i}
            atoms={geography}
            onDelete={() => {
              setGeographies({ type: 'remove', atom: geography });
            }}
          />
        ))}
        {geographies.length < MAX_GEOGRAPHIES && (
          <FormRow>
            <Button
              onClick={() => {
                setGeographies({
                  type: 'insert',
                  value: createSurveyAreaSubform(undefined, 'new'),
                });
              }}
              className={style.addButton}
            >
              Add survey area
            </Button>
          </FormRow>
        )}
      </FormSection>
    </>
  );
};

const SurveyAreaRow = ({
  atoms,
  atomIndex,
  onDelete,
}: {
  atoms: PrimitiveAtom<SurveyAreaSubformAtoms>;
  onDelete: () => void;
  atomIndex: number;
}) => {
  const [fields, setField] = useAtom(useAtomValue(atoms).row.formAtom);
  const status = useAtomValue(useAtomValue(atoms).status);
  const statusAtom = useAtomValue(atoms).status;
  const geographies = useAtomValue(geographiesAtom);

  const payload = geographies.map((el) => ({
    referenceLocationId: el.referenceLocation.id,
    quarterCalls: el.quarterCalls,
  }));

  return (
    <FormRow>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column lg={8}>
            <SurveyAreaAutocomplete
              id={'dev-area-wizard-locations'}
              placeholder=""
              onChange={({ selectedItem }) => {
                selectedItem &&
                  setField({ field: 'location', value: selectedItem });
              }}
              location={fields.location}
              index={atomIndex}
              additionalPayload={{ adjacentQuarteredGeographies: payload }}
              disabled={status === 'trash'}
            />
          </Column>
          <Column lg={6}>
            <QuarterCalls
              light
              items={fields.quarterCalls.value}
              labelText="Quarter Call"
              placeholder="Quarter Call"
              id={`${atomIndex}-quarter-calls`}
              disabled={!fields.quarterCalls.value || status === 'trash'}
              onRemove={(quarterCall) => {
                setField((current) => ({
                  field: 'quarterCalls',
                  value: current.quarterCalls.value.filter(
                    (el) => el !== quarterCall
                  ),
                }));
              }}
              onAdd={(quarterCall) => {
                setField((current) => {
                  const quarterCalls = current.quarterCalls.value;
                  if (!quarterCalls.includes(quarterCall)) {
                    return {
                      field: 'quarterCalls',
                      value: [...quarterCalls, quarterCall],
                    };
                  }
                  return {
                    field: 'quarterCalls',
                    value: quarterCalls,
                  };
                });
              }}
            />
          </Column>
          <Column lg={2}>
            <DeleteRowButton
              statusAtom={statusAtom}
              atomIdx={atomIndex}
              onDelete={onDelete}
              adjustForLabel={true}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

export { StepOne };
