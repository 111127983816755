import { Button } from 'carbon-components-react';
import { PageHeader } from 'components/page-header';
import { Add16 } from '@carbon/icons-react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { pageTitle } from 'utils/page-title';
import { ScenarioList } from './scenario-list';

const ScenariosPage = () => {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>{pageTitle('Scenario List')}</title>
      </Helmet>
      <PageHeader
        breadcrumbs={[['/scenarios', 'Scenarios']]}
        title="Scenarios"
        actions={
          <Button
            size="sm"
            renderIcon={Add16}
            onClick={() => history.push('/scenarios/new')}
          >
            <span>Add New Scenario</span>
          </Button>
        }
      />
      <ScenarioList />
    </>
  );
};

export { ScenariosPage };
