import {
  createResourceFormAtom,
  FormErrors,
  FormRowStatus,
  initializeForm,
} from 'atoms/create-resource-atom';
import { atom } from 'jotai';
import { ReferenceLocationResponse } from 'types/api-responses';
import { SurveyAreasSubform } from './types';

export type SurveyAreaSubformAtoms = ReturnType<typeof createSurveyAreaSubform>;

const createSurveyAreaSubform = (
  geography:
    | {
        id: number | null;
        location: ReferenceLocationResponse;
        quarterCalls: string[];
      }
    | undefined,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      SurveyAreasSubform,
      FormErrors<SurveyAreasSubform>
    >(
      initializeForm<SurveyAreasSubform>(
        {
          id: geography?.id ?? null,
          location: geography?.location,
          quarterCalls: geography?.quarterCalls || [],
        },
        'stable'
      )
    ),
  };
};

export { createSurveyAreaSubform };
