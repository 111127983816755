import { RunsheetGraphDataPoints } from 'pages/title-workspaces/overview/runsheet-graph/utils';
import styles from './timeline.module.scss';
import classNames from 'classnames';
import { transform, NodeDate, Node, calculateXCoordinate, Link } from './utils';
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from 'carbon-components-react';
import { groupBy } from 'lodash';
import { Link as LinkComponent } from 'components/link';

const Popup = ({
  style,
  links,
  nodes,
}: {
  style: Record<string, string>;
  links: Link[];
  nodes: Record<string, Node>;
}) => {
  const groupedByDocument = groupBy(links, (item) => {
    return [item.transactionDate, item.document];
  });
  return (
    <div
      className={styles.popup}
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      <Table size="sm">
        <TableHead>
          <TableRow>
            <TableHeader scope="col">From</TableHeader>
            <TableHeader scope="col">To</TableHeader>
            <TableHeader scope="col">Transaction</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedByDocument).map(([key, value]) => {
            return (
              <>
                <TableRow key={key}>
                  <TableCell
                    colSpan={3}
                    style={{ textAlign: 'left', fontWeight: 'bold' }}
                  >
                    {key.replace(',', ' - ')}
                  </TableCell>
                </TableRow>
                {value.map((el, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <LinkComponent
                        to={`/entities/${nodes[el.from].id}`}
                        target="blank"
                        style={{ color: nodes[el.from].color }}
                      >
                        {nodes[el.from].name}
                      </LinkComponent>
                    </TableCell>
                    <TableCell>
                      <LinkComponent
                        to={`/entities/${nodes[el.to].id}`}
                        target="blank"
                        style={{ color: nodes[el.to].color }}
                      >
                        {nodes[el.to].name}
                      </LinkComponent>
                    </TableCell>
                    <TableCell
                      className={
                        styles[el.transactionType.replace('Transaction::', '')]
                      }
                    >
                      {el.transactionHref && (
                        <LinkComponent to={el.transactionHref} target="blank">
                          {el.transactionType.replace('Transaction::', '')}
                        </LinkComponent>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const YearContent = ({ year }: { year: number }) => {
  return (
    <div className={classNames([styles.cell, styles.year])}>
      <div>
        <div>{year}</div>
        <div className={classNames([styles.months])}>
          {[...Array(12).keys()].map((el) => (
            <div key={el} className={classNames([styles.monthDivision])} />
          ))}
        </div>
      </div>
    </div>
  );
};

const DisconnectedEntity = ({
  entity,
  allYears,
  color,
}: {
  entity: RunsheetGraphDataPoints['nodes'][0];
  allYears: number[];
  color: string;
}) => {
  return (
    <div
      className={styles.row}
      style={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { '--years': allYears.length }
      }
    >
      <div
        className={classNames([styles.cell, styles.name, styles.fixedLeft])}
        style={{ color: '#6f6f6f' }}
      >
        <span
          className={styles.rectangleColor}
          style={{ backgroundColor: color }}
        ></span>
        <span>{entity.name}</span>
        <Tooltip direction="right">
          Unable to get the dates of title interactions for this entity
        </Tooltip>
      </div>
      <div
        className={classNames([styles.border])}
        style={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { '--years': allYears.length }
        }
      />
    </div>
  );
};

const Entity = ({
  entity,
  dates,
  allYears,
  color,
}: {
  entity: RunsheetGraphDataPoints['nodes'][0];
  dates: NodeDate[];
  allYears: number[];
  color: string;
}) => {
  const { year: year0, month: month0 } = dates[0];
  const { year: year1, month: month1 } =
    dates[dates.length === 1 ? 0 : dates.length - 1];
  const firstYearIndex = allYears.findIndex((el) => el === year0);
  const lastYearIndex = allYears.findIndex((el) => el === year1);
  let delta = 0;
  if (lastYearIndex >= 0) {
    delta = lastYearIndex - firstYearIndex;
  }

  return (
    <div
      className={styles.row}
      style={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { '--years': allYears.length }
      }
    >
      <div
        className={classNames([styles.cell, styles.name, styles.fixedLeft])}
        style={{ color: '#6f6f6f' }}
      >
        <span
          className={styles.rectangleColor}
          style={{ backgroundColor: color }}
        ></span>
        <span>{entity.name}</span>
      </div>
      <div
        className={classNames([styles.border])}
        style={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { '--years': allYears.length }
        }
      >
        <div className={classNames([styles.entityRow])}>
          <div
            className={classNames([styles.entityLine])}
            data-start={JSON.stringify(dates[0])}
            data-end={JSON.stringify(dates[dates.length - 1])}
            data-delta={delta}
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--color': color,
              '--start': firstYearIndex * 12 + Number(month0),
              '--width': delta * 12 + (Number(month1) - Number(month0)) || 1,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const LinkLine = ({
  nodeFrom,
  nodeTo,
  transactionDate,
  transactionType,
  allYears,
  onClick,
}: {
  nodeFrom: Node;
  nodeTo: Node;
  transactionDate: string;
  transactionType: string;
  allYears: number[];
  onClick: (event: React.MouseEvent, xCoordinate: number) => void;
}) => {
  const date = new Date(transactionDate);
  const yearIndex = allYears.findIndex((el) => el === date.getFullYear());
  const month = date.getMonth() + 1;
  const xCoordinate = calculateXCoordinate(yearIndex, month);
  const start = nodeFrom.index + 1;
  const end = nodeTo.index + 1;
  const delta = end - start;
  const direction = delta < 0 ? 'up' : 'down';

  if (delta === 0) return null;
  return (
    <div
      className={classNames([
        styles.line,
        styles[direction],
        styles[transactionType.replace('Transaction::', '')],
      ])}
      data-from={nodeFrom.id}
      data-to={nodeTo.id}
      data-delta={delta}
      onClick={(e) => {
        onClick(e, xCoordinate);
      }}
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--start-top': delta < 0 ? end : start,
        '--start-left': xCoordinate,
        '--end': delta * (delta < 0 ? -1 : 1),
      }}
    ></div>
  );
};

const Timeline = ({ runsheet }: { runsheet: RunsheetGraphDataPoints }) => {
  const [popupStyles, setPopupStyles] = useState<Record<string, string>>({
    top: '0px',
    left: '0px',
    display: 'none',
  });
  const [xCoordinate, setXCoordinate] = useState(-1);
  const { runsheetData, nodesObj, uniqueYears, linksObj } = transform(runsheet);

  const onLinkLineClick = (e: React.MouseEvent, xCoordinate: number) => {
    e.preventDefault();
    e.stopPropagation();
    const x = e.clientX;
    const y = e.clientY;

    // Get the current scroll offsets
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    // Adjust for scroll offset
    const adjustedX = x + scrollX;
    const adjustedY = y + scrollY;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let positionStyles: Record<string, string>;

    // Vertical positioning
    if (y < windowHeight / 2) {
      // Upper half of the viewport
      positionStyles = { top: `${adjustedY}px` };
    } else {
      // Lower half of the viewport
      positionStyles = { bottom: `${windowHeight - adjustedY}px` };
    }

    // Horizontal positioning
    if (x < windowWidth / 2) {
      // Left half of the viewport
      positionStyles.left = `${adjustedX + 20}px`;
    } else {
      // Right half of the viewport
      positionStyles.right = `${windowWidth - adjustedX + 20}px`;
    }

    setPopupStyles({
      ...positionStyles,
      display: 'block',
    });
    setXCoordinate(xCoordinate);
  };

  return (
    <div
      className={styles.tableContainer}
      onClick={() => {
        setXCoordinate(-1);
        setPopupStyles({ display: 'none' });
      }}
    >
      {xCoordinate >= 0 && (
        <Popup
          style={popupStyles}
          links={linksObj[xCoordinate]}
          nodes={nodesObj}
        />
      )}
      <div className={styles.table}>
        <div className={classNames([styles.row, styles.fixed])}>
          <div
            className={classNames([styles.cell, styles.year, styles.year0])}
          />
          {uniqueYears.map((el) => {
            return <YearContent key={el} year={el} />;
          })}
        </div>
        {runsheetData.nodes.map((el) => {
          const dates = nodesObj[el.key].dates;
          const color = nodesObj[el.key].color;

          if (!dates.length)
            return (
              <DisconnectedEntity
                entity={el}
                color={'white'}
                allYears={uniqueYears}
              />
            );

          return (
            <Entity
              key={el.key}
              entity={el}
              dates={dates}
              allYears={uniqueYears}
              color={color}
            />
          );
        })}
        {runsheet.links.map((el, i) => {
          return (
            <LinkLine
              key={i}
              nodeFrom={nodesObj[el.from]}
              nodeTo={nodesObj[el.to]}
              transactionDate={el.transactionDate}
              transactionType={el.transactionType}
              allYears={uniqueYears}
              onClick={onLinkLineClick}
            />
          );
        })}
        <div className={styles.legendArea}>
          <label>Colors:</label>
          <div className={styles.legend}>
            <p>
              <span className={styles.conveyanceColor}></span>Conveyance
              Transaction
            </p>
            <p>
              <span className={styles.mineralLeaseColor}></span>Mineral Lease
              Transaction
            </p>
            <p>
              <span className={styles.surfaceLeaseColor}></span>Surface Lease
              Transaction
            </p>
          </div>
          <div className={styles.legend}>
            <p>
              <span className={styles.noTransactionsColor}></span>
              No Transactions In
            </p>
            <p>
              <span className={styles.transactionsInOut}></span>Transactions In
              and Out
            </p>
            <p>
              <span className={styles.terminalEntity}></span>Terminal Entity
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Timeline };
