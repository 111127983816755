export default {
  rootPath: '/',
  documentsPath: '/documents',
  interpretationsPath: '/interpretations',
  courthouseDocumentPath: '/courthouse_documents/:id',
  nonCourthouseDocumentPath: '/non_courthouse_documents/:id',
  newDocumentsPath: '/documents/new',
  documentPath: '/documents/:id',
  documentInterpretationsPath: '/documents/:documentId/interpretations',
  documentInterpretationPath: '/documents/:documentId/interpretations/:id',
  newDocumentInterpretationPath: '/documents/:documentId/interpretations/new',
  activitiesPath: '/activities',
  wellsPath: '/wells',
  wellPath: '/wells/:id',
  developmentAreasPath: '/development_areas',
  developmentAreaPath: '/development_areas/:id',
  referenceLocationsPath: '/reference_locations',
  referenceLocationPath: '/reference_locations/:id',
  titleWorkspacesPath: '/title_workspaces',
  titleWorkspacePath: '/title_workspaces/:id',
  titleWorkspaceDocumentsPath: '/title_workspaces/:id/documents',
  titleWorkspaceWellsPath: '/title_workspaces/:id/wells',
  titleWorkspaceStartingInterestPath:
    '/title_workspaces/:titleWorkspaceId/starting_interest',
  titleWorkspaceRunsheetPath: '/title_workspaces/:id/runsheet',
  titleWorkspaceRunsheetTimelinePath: '/title_workspaces/:id/timeline',
  titleChainCalculatorPath: '/title_chain_calculator',
  newEntityPath: '/entities/new',
  entitiesPath: '/entities',
  entityPath: '/entities/:id',
  packagePath: '/packages/:id',
  scenariosPath: '/scenarios',
  newScenarioPath: '/scenarios/new',
  scenarioPath: '/scenarios/:id',
  packagesPath: '/packages',
};
