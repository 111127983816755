import { Tile } from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import {
  itemRangeText,
  pageRangeText,
} from 'components/search/common/format-helpers';
import { DocumentResponse, SearchResult } from 'types/api-responses';
import { DocumentCard } from './document-card';
import { SearchParams } from 'atoms/search-atom';
import { Pagination } from 'components/pagination';
import { Atom } from 'jotai';
import { Default } from 'components/icons';
import { DocumentFacets } from 'components/search/common/types';
import { DocumentSearchSkeleton } from './documents-search-skeleton.module';

const PAGE_SIZES = [10, 30];

interface Props {
  searchResult?: SearchResult<DocumentResponse, DocumentFacets>;
  onChange: (payload: SearchParams) => void;
  onAddDocument?: (doc: DocumentResponse) => void;
  usedDocumentsAtom: Atom<number[]>;
  loading?: boolean;
}

export function Documents({
  searchResult,
  onChange,
  onAddDocument,
  usedDocumentsAtom,
  loading,
}: Props) {
  const handlePageChange = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    window.scrollTo(0, 0);
    onChange({ page, pageSize });
  };

  return loading ? (
    <DocumentSearchSkeleton elementCount={10} />
  ) : searchResult ? (
    <div style={{ position: 'relative' }}>
      {searchResult.results?.length ? (
        <>
          {searchResult.results.map((result) => (
            <DocumentCard
              key={result.id}
              document={result}
              onAddDocument={onAddDocument}
              usedDocumentsAtom={usedDocumentsAtom}
            />
          ))}
          <Pagination
            pageSizes={PAGE_SIZES}
            pageSize={searchResult.pageSize}
            page={searchResult.page}
            totalItems={searchResult.totalCount}
            onChange={handlePageChange}
            itemRangeText={itemRangeText}
            pageRangeText={pageRangeText}
          />
        </>
      ) : (
        <Tile>
          <EmptyState
            IconComponent={Default}
            headerText="No Results Found"
            helperText="Try adjusting your search options to find what you’re looking for."
          />
        </Tile>
      )}
    </div>
  ) : null;
}
