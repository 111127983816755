import { PrimitiveAtom, atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useMemo } from 'react';

export type LayerAtom = typeof assetsLayerAtom;
export type AllLayersAtom = ReturnType<typeof useCreateAllLayersAtom>;

const assetsLayerAtom = atomWithStorage('package-map-assets-layer', true);
const developmentAreaLayerAtom = atomWithStorage(
  'package-map-development-area-layer',
  true
);
const titleLayerAtom = atomWithStorage('package-map-title-layer', true);
const verticalWellsLayerAtom = atomWithStorage(
  'package-vertical-well-layer',
  true
);

const horizontalProducingWellsLayerAtom = atomWithStorage(
  'package-horizontal-producing-well-layer',
  true
);

const horizontalDUCWellsLayerAtom = atomWithStorage(
  'package-horizontal-duc-well-layer',
  true
);

const horizontalPermitWellsLayerAtom = atomWithStorage(
  'package-horizontal-permit-well-layer',
  true
);

const allLayersBaseAtom = atomWithStorage(
  'package-scoped-layers-all-checked',
  true
);

// Returns an atom used to check multiple layers at the same time.
// The atom used by the "check all checkbox" is configurable with the
// hook argument.
// The returned atom accepts the checkbox value (boolean) and an optional
// set of boolean atoms that should be checked as well.
// This is useful for example, to pass atoms that remember state in localstorage
// or regular atoms, depending on the usecase.
const useCreateAllLayersAtom = (allLayersAtom: PrimitiveAtom<boolean>) => {
  return useMemo(
    () =>
      atom<boolean, [boolean, PrimitiveAtom<boolean>[] | undefined], void>(
        (get) => get(allLayersAtom),
        (get, set, payload, atoms) => {
          set(allLayersAtom, payload);
          if (atoms) {
            for (const layer of atoms) {
              set(layer, payload);
            }
          }
          set(verticalWellsLayerAtom, payload);
          set(horizontalDUCWellsLayerAtom, payload);
          set(horizontalPermitWellsLayerAtom, payload);
          set(horizontalProducingWellsLayerAtom, payload);
        }
      ),
    [allLayersAtom]
  );
};

export {
  useCreateAllLayersAtom,
  allLayersBaseAtom,
  assetsLayerAtom,
  developmentAreaLayerAtom,
  horizontalDUCWellsLayerAtom,
  horizontalPermitWellsLayerAtom,
  horizontalProducingWellsLayerAtom,
  titleLayerAtom,
  verticalWellsLayerAtom,
};
