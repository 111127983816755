import {
  DatePicker as DatePickerCarbon,
  DatePickerProps as DatePickerPropsCarbon,
  DatePickerInput,
  DatePickerInputProps,
} from 'carbon-components-react';
import classNames from 'classnames';
import { format, parse } from 'date-fns';
import { ReactNode } from 'react';
import style from './date-picker.module.scss';

export interface DatePickerProps
  extends Omit<DatePickerPropsCarbon, 'value' | 'dateFormat'> {
  labelText: string;
  invalid?: boolean;
  invalidText?: ReactNode;
  id: string;
  disabled?: boolean;
  value?: string;
  size?: DatePickerInputProps['size'];
}

const DatePicker = ({
  light = false,
  size = 'lg',
  id,
  value,
  onChange,
  labelText,
  invalid,
  invalidText,
  disabled,
  ...rest
}: DatePickerProps) => {
  const className = classNames(style.datePicker, {
    [style.markedForDestruction]: disabled,
  });

  const dateValue = value
    ? format(parse(value, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    : undefined;

  return (
    <DatePickerCarbon
      light={light}
      dateFormat="m/d/Y"
      datePickerType="single"
      onChange={onChange}
      value={dateValue}
      className={className}
      {...rest}
    >
      <DatePickerInput
        id={id}
        placeholder="mm/dd/yyyy"
        labelText={labelText}
        type="text"
        invalid={invalid}
        invalidText={invalidText}
        autoComplete="off"
        size={size}
        disabled={disabled}
      />
    </DatePickerCarbon>
  );
};

export { DatePicker };
