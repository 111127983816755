import classNames from 'classnames';
import { format } from 'lib/ui';
import { Liquid, Gas, Water, CalendarIcon, ReportIcon } from 'components/icons';
import style from './comulative-production.module.scss';
import { WellProperties } from 'types/api-responses';

interface Props {
  cumulativeVerticalPos?: boolean;
  cumulativeHorizontalPos?: boolean;
  production: WellProperties['totalProduction'];
  monthsFromFirstToLastProduction?: number | null;
  monthsReported?: number | null;
}

const CumulativeProduction = ({
  cumulativeVerticalPos,
  cumulativeHorizontalPos,
  production,
  monthsFromFirstToLastProduction,
  monthsReported,
}: Props) => {
  const wrapperClassName = classNames({
    [style.verticalAlignment]: cumulativeVerticalPos,
    [style.horizontalAlignment]: cumulativeHorizontalPos,
  });

  return (
    <div className={wrapperClassName}>
      <Statistic
        label="Liquid"
        value={production.totalLiquid}
        unit="bbl"
        IconComponent={Liquid}
      />
      <Statistic
        label="Gas"
        value={production.totalGas}
        unit="mcf"
        IconComponent={Gas}
      />
      <Statistic
        label="Water"
        value={production.totalWater}
        unit="bbl"
        IconComponent={Water}
      />
      {!!monthsFromFirstToLastProduction && (
        <Statistic
          label="First to Last Production"
          value={monthsFromFirstToLastProduction}
          unit="months"
          IconComponent={CalendarIcon}
        />
      )}
      {!!monthsReported && (
        <Statistic
          label="Reported"
          value={monthsReported}
          unit="months"
          IconComponent={ReportIcon}
        />
      )}
    </div>
  );
};

const Statistic = ({
  label,
  value,
  unit,
  IconComponent,
}: {
  label: string;
  value: number | null;
  unit: string;
  IconComponent: React.FunctionComponent;
}) => {
  return (
    <div className={style.statistic}>
      <dl>
        <dt>
          <IconComponent />
          {label}
        </dt>
        <dd>
          {value ? (
            <>
              <span className={style.value}>{format.number(value)}</span>
              {unit && <span className={style.unit}> {unit}</span>}
            </>
          ) : (
            <span className={style.value}>&mdash;</span>
          )}
        </dd>
      </dl>
    </div>
  );
};

export { CumulativeProduction };
