import { Document20 } from '@carbon/icons-react';
import {
  DocumentInterpretationResponse,
  LeaseExtension,
  TransactionResponse,
} from 'types/api-responses';
import { DefinitionEntry } from './definition-entry';
import { Tag } from 'carbon-components-react';
import { format, parseISO } from 'date-fns';

import style from './lease-preview.module.scss';

type MineralLeaseTransaction = Extract<
  TransactionResponse,
  { type: 'Transaction::MineralLeaseTransaction' }
>;

function leaseStatus(trx: MineralLeaseTransaction) {
  const today = new Date();
  const expiryDate =
    trx.mineralLease.expirationDate !== null
      ? new Date(trx.mineralLease.expirationDate)
      : new Date();
  const isExpired = expiryDate < today;
  const isHeldByProduction = trx.mineralLease.heldByProduction;
  const hasExtensions = trx.leaseExtensions && trx.leaseExtensions.length > 0;
  const expirationDate = trx.mineralLease.expirationDate;

  let status;
  let extraInfo = '';

  if (!isExpired) {
    status = (
      <Tag size="sm" type="green">
        Active
      </Tag>
    );
    extraInfo = expirationDate
      ? `Expires on ${format(parseISO(expirationDate), 'MM/dd/yyyy')}`
      : 'No expiration date provided';
  } else if (isHeldByProduction) {
    status = (
      <Tag size="sm" type="purple">
        HBP
      </Tag>
    );
    extraInfo = `Held by production`;
  } else if (hasExtensions) {
    status = (
      <Tag size="sm" type="magenta">
        Partial HBP
      </Tag>
    );
  } else {
    status = (
      <Tag size="sm" type="gray">
        Inactive
      </Tag>
    );
    extraInfo = expirationDate
      ? `Lease expired on ${format(parseISO(expirationDate), 'MM/dd/yyyy')}`
      : 'No lease expire date provided';
  }

  return { status, extraInfo };
}

const retainedLand = (el: MineralLeaseTransaction) => {
  return el.mineralLease.heldByProduction
    ? 'all'
    : !el.mineralLease.heldByProduction && el.leaseExtensions?.length
    ? 'partial'
    : 'none';
};

const ReferenceLocation = ({
  leaseExtension,
}: {
  leaseExtension: LeaseExtension;
}) => {
  return (
    <>
      {leaseExtension.referenceLocation.name}
      {leaseExtension.quarterCalls.length ? ' | ' : null}
      {leaseExtension.quarterCalls.join(' • ')}
      {leaseExtension.startDepthInFeet
        ? ` | ${leaseExtension.startDepthInFeet}`
        : null}
      {leaseExtension.endDepthInFeet
        ? ` • ${leaseExtension.endDepthInFeet}`
        : null}
    </>
  );
};

const LeasePreview = ({ data }: { data: DocumentInterpretationResponse }) => {
  const transactions = data.transactions.filter(
    (el) => el.type === 'Transaction::MineralLeaseTransaction'
  ) as MineralLeaseTransaction[];

  return (
    <div>
      <div className={style.titleArea}>
        <div className={style.iconArea}>
          <Document20 />
        </div>

        <div className={style.popName}>
          Mineral Lease
          <p>Transaction</p>
        </div>
      </div>
      {transactions.map((el) => {
        const retained = retainedLand(el);
        const { status, extraInfo } = leaseStatus(el);
        return (
          <div key={el.id} className={style.leaseArea}>
            <dl>
              <dt>{status}</dt>
              <dd>
                <span>{extraInfo}</span>
              </dd>
            </dl>
            <DefinitionEntry label="Transaction">
              <span>#{el.id}</span>
            </DefinitionEntry>
            {retained !== 'none' ? (
              <DefinitionEntry label="Retained Land">
                <span>
                  {retained === 'all' && 'All Land'}
                  {retained === 'partial' &&
                    el.leaseExtensions.map((el) => (
                      <ReferenceLocation key={el.id} leaseExtension={el} />
                    ))}
                </span>
              </DefinitionEntry>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export { LeasePreview };
