import { DotMark16 } from '@carbon/icons-react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { Layer, Marker, Source } from 'react-map-gl';
import { WellFeaturesResponse } from 'types/api-responses';
import { LINE_STYLES } from './map-styles';
import { MapUserConfigAtom } from './types';

import style from './well.module.scss';

interface LineProps {
  id?: string;
  geojson: GeoJSON.Feature;
  style: Record<string, unknown>;
}
export const Line = ({ geojson, id, style = {} }: LineProps) => {
  return (
    <Source type="geojson" data={geojson}>
      <Layer id={id} type="line" paint={style} />
    </Source>
  );
};

interface PointProps {
  geojson: GeoJSON.Point;
  className: string;
}
export function Point({ geojson, className }: PointProps) {
  const [longitude, latitude] = geojson.coordinates;
  return (
    <Marker longitude={longitude} latitude={latitude}>
      <DotMark16 className={classNames(style.point, className)} />
    </Marker>
  );
}

interface WellProps {
  id: string;
  features: WellFeaturesResponse;
  mapConfigAtom: MapUserConfigAtom;
}
const Well = ({ id, features, mapConfigAtom }: WellProps) => {
  const { stick, survey, bottomHole, surfaceHole } = features;
  const mapConfig = useAtomValue(mapConfigAtom);
  const wellLayers = mapConfig.wells.options;
  return (
    <>
      {stick && wellLayers.stick && (
        <Line
          id={`${id}-stick`}
          geojson={stick}
          style={LINE_STYLES.BLUE_DOTTED}
        />
      )}
      {survey && wellLayers.survey && (
        <Line
          id={`${id}-survey`}
          geojson={survey}
          style={LINE_STYLES.BLACK_SOLID}
        />
      )}
      {bottomHole && wellLayers.bottomHole && (
        <Point geojson={bottomHole} className={style.bottomHole} />
      )}
      {surfaceHole && wellLayers.surfaceHole && (
        <Point geojson={surfaceHole} className={style.surfaceHole} />
      )}
    </>
  );
};

export default Well;
