import { getError } from 'atoms/create-resource-atom';
import { TextArea } from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { formAtoms } from '../atoms';

const Note = () => {
  const noteAtom = useMemo(
    () => atom((get) => get(get(formAtoms).formAtom).notes),
    []
  );
  const setField = useSetAtom(useAtomValue(formAtoms).formAtom);
  const note = useAtomValue(noteAtom);

  return (
    <FormRow>
      <TextArea
        labelText="Notes"
        placeholder="Text Field"
        light
        id={`notes`}
        value={note.value || ''}
        onChange={({ target: { value } }) =>
          setField({ field: 'notes', value })
        }
        invalid={!!getError(note)}
        invalidText={getError(note)}
      />
    </FormRow>
  );
};

export { Note };
