import { FormSection } from 'components/forms';
import { EntityResponse } from 'types/api-responses';
import {
  DEFAULT_PAGE_SIZE,
  entityPackageMutationAtom,
  useSearchInventoryAtom,
} from './atoms';
import { useAtom } from 'jotai';
import { PaginatedTable } from 'components/table/paginated-table';
import {
  Grid,
  Column,
  Row,
  Checkbox,
  RadioButton,
  RadioButtonGroup,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Button,
  InlineNotification,
} from 'carbon-components-react';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import { DatePicker } from 'components/date-picker';
import { Note } from 'components/note';
import { useCallback, useState } from 'react';
import { getDateString } from 'utils/dates';
import { Date as DateComponent } from 'components/date';
import { FORMATTER } from 'lib/ui';
import { capitalizeFirstLetter } from 'utils/strings';
import style from './inventory.module.scss';
import ErrorToast from 'components/error-toast';
import { DownloadButton } from 'components/download-button';
import { useDownloadResource } from 'lib/hooks/useDownloadFile';

const Inventory = ({ entity }: { entity: EntityResponse }) => {
  return (
    <div className={style.inventoryGrid}>
      <h3 className={style.subTitle}>Inventory</h3>
      <InventoryTable entity={entity} />
    </div>
  );
};

const EvaluateButton = ({
  href,
  disabled,
  effectiveDate,
  includeChildren,
}: {
  href: string;
  disabled: boolean;
  effectiveDate?: string;
  includeChildren?: boolean;
}) => {
  const [result, runMutation] = useAtom(entityPackageMutationAtom);
  const [error, setError] = useState(false);
  return (
    <>
      <Button
        size="md"
        disabled={disabled || result.loading}
        onClick={() => {
          if (!effectiveDate || typeof includeChildren === 'undefined') return;
          setError(false);
          runMutation({
            url: href,
            type: 'POST',
            data: { package: { effectiveDate, includeChildren } },
            onSuccess(data) {
              window.open(data.href, '_blank', 'noopener,noreferrer');
            },
            onError() {
              setError(true);
            },
          });
        }}
      >
        Evaluate
      </Button>
      {error && (
        <ErrorToast message="Error creating package. Try again please" />
      )}
    </>
  );
};

type ExportPayload = {
  asOfDate: string;
  includeChildren: boolean;
};

const ExportButton = ({
  downloadHref,
  payload,
}: {
  downloadHref: string;
  payload: ExportPayload;
}) => {
  const downloadOwnership = useDownloadResource<ExportPayload>(
    downloadHref,
    payload
  );
  return (
    <DownloadButton
      loading={downloadOwnership.generatingDownload}
      loadingText="Building List"
      kind="primary"
      onClick={() => downloadOwnership.triggerDownload()}
    >
      Download List
    </DownloadButton>
  );
};

const InventoryTable = ({ entity }: { entity: EntityResponse }) => {
  const today = getDateString(new Date());
  const { searchAtom } = useSearchInventoryAtom(
    entity.resources.queries.inventoryAssets.href,
    today
  );
  const [asOfDateChecked, setAsOfDateChecked] = useState(false);
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const searchParams = searchResults.currentPayload;
  const pageSize = searchResults?.data?.pageSize || DEFAULT_PAGE_SIZE;

  const setAsOf = useCallback(
    (asOfDate: string) => {
      setSearchPayload((current) => ({ ...current, asOfDate }));
    },
    [setSearchPayload]
  );

  const setIncludeChildren = useCallback(
    (includeChildren: boolean) => {
      setSearchPayload((current) => ({ ...current, includeChildren }));
    },
    [setSearchPayload]
  );

  const queryFailed = 'error' in searchResults;

  const selected =
    searchParams?.asOfDate === today
      ? 'now'
      : searchParams?.asOfDate === 'all'
      ? 'allTime'
      : 'date';

  return (
    <Grid className="bx--no-gutter" fullWidth>
      <Row>
        <Column lg={16}>
          <FormSection title="" className={style.inventoryFilters}>
            <div className={style.inputsCol}>
              <RadioButtonGroup
                legendText="Inventory As Of"
                name="asOf"
                valueSelected={selected}
                onChange={(value) => {
                  if (value === 'date') {
                    setAsOfDateChecked(true);
                    return;
                  }
                  setAsOfDateChecked(false);
                }}
              >
                <RadioButton
                  value="now"
                  id="now"
                  labelText="Now"
                  onClick={() => setAsOf(getDateString(new Date()))}
                />
                <RadioButton
                  id="as-of-all-time"
                  value="allTime"
                  labelText="All Time"
                  onClick={() => setAsOf('all')}
                />
                <RadioButton value="date" id="as-of-a-date" labelText="Date" />
              </RadioButtonGroup>

              <DatePicker
                id="inventory-as-of"
                labelText="As of"
                size="md"
                onChange={(date) => {
                  if (date[0]) {
                    setAsOf(getDateString(date[0]));
                  }
                }}
                disabled={!asOfDateChecked}
                light
              />

              <Checkbox
                id="include-children"
                checked={searchParams?.includeChildren || false}
                onChange={(checked: boolean) => {
                  setIncludeChildren(checked);
                }}
                labelText="Include Children"
              />
            </div>
            <div className={style.buttonsCol}>
              <EvaluateButton
                href={entity.resources.packages.href}
                effectiveDate={searchParams?.asOfDate}
                includeChildren={searchParams?.includeChildren}
                disabled={selected === 'allTime' || queryFailed}
              />

              {searchParams &&
                typeof searchParams.includeChildren !== 'undefined' &&
                searchParams.asOfDate && (
                  <ExportButton
                    downloadHref={entity.resources.inventoryAssetsDownload.href}
                    payload={{
                      includeChildren: searchParams.includeChildren,
                      asOfDate: searchParams.asOfDate,
                    }}
                  />
                )}
            </div>
          </FormSection>
        </Column>
      </Row>
      <Row>
        <Column lg={16}>
          <div className={style.inventoryTableArea}>
            {queryFailed ? (
              <InlineNotification
                kind="error"
                title="Problem running the query. Please try again"
                lowContrast
              />
            ) : (
              <PaginatedTable
                pageSizes={[DEFAULT_PAGE_SIZE, 100, 200]}
                pageSize={pageSize}
                page={searchParams?.page || 1}
                totalItems={searchResults?.data?.totalCount || 0}
                className={style.inventoryTable}
                onPaginate={({ page, pageSize }) => {
                  setSearchPayload((current) => ({
                    ...current,
                    page,
                    pageSize,
                  }));
                }}
                paginatorOutsideTableContainer
              >
                <TableHead className={style.stickyHeader}>
                  <TableRow>
                    <TableHeader>Asset</TableHeader>
                    <TableHeader>Asset Type</TableHeader>
                    <TableHeader>Full Legal Description</TableHeader>
                    <TableHeader>County</TableHeader>
                    <TableHeader>Depth</TableHeader>
                    <TableHeader>Legacy Depth</TableHeader>
                    <TableHeader>Quarter Calls</TableHeader>
                    <TableHeader>Tract Instrument Type</TableHeader>
                    <TableHeader>Tract Gross Acres</TableHeader>
                    <TableHeader>Tract Net Acres</TableHeader>
                    <TableHeader>Inventory Asset Name</TableHeader>
                    <TableHeader>Inventory Owner Name</TableHeader>
                    <TableHeader>Inventory Owner Type</TableHeader>
                    <TableHeader>Start Effective Date</TableHeader>
                    <TableHeader>End Effective Date</TableHeader>
                    <TableHeader>Days Held</TableHeader>
                    <TableHeader>Owner Portco Abbreviation</TableHeader>
                    <TableHeader>Owner Parent Name</TableHeader>
                    <TableHeader>Owner Parent Type</TableHeader>
                    <TableHeader>Owner Parent Portco Abbreviation</TableHeader>
                    <TableHeader>Acquisition Date</TableHeader>
                    <TableHeader>Acquisition Unit Price</TableHeader>
                    <TableHeader>Sale Date</TableHeader>
                    <TableHeader>Sale Unit Price</TableHeader>
                    <TableHeader>Sale Total Price</TableHeader>
                    <TableHeader>Acquired From Name</TableHeader>
                    <TableHeader>Acquired From Type</TableHeader>
                    <TableHeader>Sold To Name</TableHeader>
                    <TableHeader>Sold To Type</TableHeader>
                    <TableHeader>Notes</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResults.loading ? (
                    <SkeletonTableRows rows={pageSize} columns={30} />
                  ) : searchResults ? (
                    searchResults.data?.results.map((el) => {
                      return (
                        <TableRow key={el.assetId}>
                          <TableCell>{el.assetName}</TableCell>
                          <TableCell>
                            {el.assetType
                              ? capitalizeFirstLetter(el.assetType)
                              : null}
                          </TableCell>
                          <TableCell>{el.fullLegalDescription}</TableCell>
                          <TableCell>
                            {el.county}, {el.state}
                          </TableCell>
                          <TableCell className={style.depthsCell}>
                            {el.startDepth || 0}&ndash;{el.endDepth || '∞'} ft
                          </TableCell>
                          <TableCell>{el.legacyDepth}</TableCell>
                          <TableCell>{el.quarterCall}</TableCell>
                          <TableCell>{el.tractInstrumentType}</TableCell>
                          <TableCell>
                            {Number(el.tractGrossAcres).toFixed(2)}{' '}
                          </TableCell>
                          <TableCell>
                            {el.tractNetAcres.toFixed(2)}{' '}
                            {el.tractNetAcreageUnits}
                          </TableCell>
                          <TableCell>{el.inventoryAssetName}</TableCell>
                          <TableCell>{el.inventoryOwnerName}</TableCell>
                          <TableCell>{el.inventoryOwnerType}</TableCell>
                          <TableCell>
                            <DateComponent date={el.startEffectiveDate} />
                          </TableCell>
                          <TableCell>
                            {el.endEffectiveDate ? (
                              <DateComponent date={el.endEffectiveDate} />
                            ) : (
                              '--'
                            )}
                          </TableCell>
                          <TableCell>{el.daysHeld}</TableCell>
                          <TableCell>
                            {el.inventoryOwnerPortcoAbbreviation}
                          </TableCell>
                          <TableCell>{el.inventoryOwnerParentName}</TableCell>
                          <TableCell>{el.inventoryOwnerParentType}</TableCell>
                          <TableCell>
                            {el.inventoryOwnerParentPortcoAbbreviation}
                          </TableCell>
                          <TableCell>
                            <DateComponent date={el.acquisitionDate} />
                          </TableCell>
                          <TableCell>
                            {FORMATTER.format(Number(el.acquisitionUnitPrice))}
                          </TableCell>
                          <TableCell>
                            <DateComponent date={el.saleDate} />
                          </TableCell>
                          <TableCell>
                            {FORMATTER.format(Number(el.saleUnitPrice))}
                          </TableCell>
                          <TableCell>
                            {FORMATTER.format(Number(el.saleTotalPrice))}
                          </TableCell>
                          <TableCell>{el.acquiredFromName}</TableCell>
                          <TableCell>{el.acquiredFromType}</TableCell>
                          <TableCell>{el.soldToName}</TableCell>
                          <TableCell>{el.soldToType}</TableCell>
                          <TableCell className={style.notesColumn}>
                            <Note noteText={el.notes} />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : null}
                </TableBody>
              </PaginatedTable>
            )}
          </div>
        </Column>
      </Row>
    </Grid>
  );
};

export { Inventory };
