import {
  InlineNotification,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { useAtomValue } from 'jotai';
import { useCapacitiesAtom } from './atoms';
import { EntityBaseResponse, EntityResponse } from 'types/api-responses';
import { NoResults } from './no-results';
import { SkeletonTableRows } from 'components/skeleton-table-rows';

const Capacities = ({ currentEntity }: { currentEntity: EntityResponse }) => {
  return (
    <FormSection title="Capacities">
      <CapacitiesTable currentEntity={currentEntity} />
    </FormSection>
  );
};

const EntityLink = ({
  entity: entity,
  currentEntity,
}: {
  entity: EntityBaseResponse;
  currentEntity: EntityResponse;
}) => {
  return entity.id === currentEntity?.id ? (
    <span>{entity.name}</span>
  ) : (
    <Link href={entity.href}>{entity.name}</Link>
  );
};

const CapacitiesTable = ({
  currentEntity,
}: {
  currentEntity: EntityResponse;
}) => {
  const capacitiesAtom = useCapacitiesAtom(
    currentEntity.resources.capacities.href
  );
  const { loading, data } = useAtomValue(capacitiesAtom);

  if (data && 'error' in data)
    return <InlineNotification kind="error" title={data.error} lowContrast />;

  if (!loading && data && !('error' in data) && !data.length) {
    return <NoResults />;
  }

  return (
    <FormRow>
      <TableContainer>
        <Table aria-label="Capacities">
          <TableHead>
            <TableRow>
              <TableHeader>Who (Entity)</TableHeader>
              <TableHeader>Capacity</TableHeader>
              <TableHeader>Target (Entity)</TableHeader>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <SkeletonTableRows rows={5} columns={3} />
            </TableBody>
          ) : (
            <TableBody>
              {data?.map((capacity) => (
                <TableRow key={capacity.id}>
                  <TableCell>
                    <EntityLink
                      entity={capacity.subject}
                      currentEntity={currentEntity}
                    />
                  </TableCell>
                  <TableCell>{capacity.predicate}</TableCell>
                  <TableCell>
                    {capacity.object && (
                      <EntityLink
                        entity={capacity.object}
                        currentEntity={currentEntity}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </FormRow>
  );
};

export { Capacities };
