import { Tab, Tabs } from 'carbon-components-react';
import { AtomizedForm } from 'atoms/form-atoms';
import { DrillingAssumptions } from './forms/drilling-assumptions';
import { CashflowAssumptions } from './forms/cashflow-assumptions';
import { DiscountRateAssumptions } from './forms/discount-rate-assumptions';
import { ScenariosForm } from './types';

import style from './scenario-builder.module.scss';

const GlobalScenarioForm = ({
  evaluationParameters,
}: {
  evaluationParameters: AtomizedForm<ScenariosForm['globalAssumptions']>;
}) => {
  return (
    <Tabs className={style.tabsContained}>
      <Tab label="Drilling Assumptions">
        <DrillingAssumptions
          form={evaluationParameters.drillingAssumptionAttributes}
        />
      </Tab>
      <Tab label="Cashflow Assumptions">
        <CashflowAssumptions
          form={evaluationParameters.cashflowAssumptionAttributes}
        />
      </Tab>
      <Tab label="Discount Rates Assumptions">
        <DiscountRateAssumptions
          form={evaluationParameters.cashflowAssumptionAttributes}
        />
      </Tab>
    </Tabs>
  );
};

export { GlobalScenarioForm };
