import {
  Modal,
  FileUploaderDropContainer,
  FileUploaderItem,
  FormGroup,
  RadioButtonGroup,
  RadioButton,
  Tile,
  InlineNotification,
  SkeletonPlaceholder,
  Button,
} from 'carbon-components-react';
import { BlockField as Field } from './field';
import { DocumentImport16 } from '@carbon/icons-react';
import { Link } from 'components/link';
import { ReactNode, useReducer, useState } from 'react';
import { EmptyState } from 'components/empty-state';
import DocumentSvg from 'images/srp-illustration-document.svg';
import { DestroyConfirmation } from 'components/destroy-confirmation';
import { useAtom, useSetAtom } from 'jotai';
import {
  createSupportingFileAtom,
  fileAtom,
  useDeleteFileAtom,
  useSupportingFilesAtom,
} from './atoms';
import { WellSupportingFileResponse } from 'types/api-responses';

import style from './supporting-files.module.scss';
import { TrashButton } from 'components/trash-button';

interface Props {
  open: boolean;
  onClose: () => void;
  supportingFilesHref: string;
  getSupportingFiles: () => Promise<void>;
}

const ImportModal = ({
  open,
  onClose,
  supportingFilesHref,
  getSupportingFiles,
}: Props) => {
  const [fileType, setFileType] = useState<string | number>('');
  const [upload, setUpload] = useAtom(fileAtom);
  const [result, createFile] = useAtom(createSupportingFileAtom);
  const [errors, setErrors] = useState<string[]>([]);

  const resetAndClose = () => {
    setUpload(undefined);
    setErrors([]);
    setFileType('');
    onClose();
  };

  return (
    <Modal
      modalHeading="Upload File"
      primaryButtonText="Import"
      secondaryButtonText="Cancel"
      onRequestClose={() => {
        resetAndClose();
      }}
      onRequestSubmit={() => {
        if (!supportingFilesHref || !fileType || !upload.file) return;
        createFile({
          url: supportingFilesHref,
          type: 'POST',
          data: {
            fileType: String(fileType),
            attachment: upload.file,
          },
          onSuccess: () => {
            getSupportingFiles();
            resetAndClose();
          },
          onError: (error) => {
            if (error?.errors) {
              const allErrors: string[] = Object.values(error.errors).reduce(
                (acc, el) => acc.concat(el),
                []
              );
              setErrors(allErrors);
            }
          },
        });
      }}
      primaryButtonDisabled={!upload.file || !fileType || result.loading}
      open={open}
    >
      <FormGroup legendText="File Type">
        <RadioButtonGroup
          name="type"
          onChange={(value) => setFileType(value)}
          valueSelected={fileType}
        >
          <RadioButton labelText="Internal" value="internal" />
          <RadioButton labelText="Regulatory" value="regulatory" />
        </RadioButtonGroup>
      </FormGroup>

      <p>Max file size is 500 MB</p>
      <FileUploaderDropContainer
        labelText="Drag and drop files here or click to upload"
        onAddFiles={(_event, { addedFiles }) => {
          setUpload(addedFiles[0]);
        }}
        disabled={!!upload.file}
      />
      {upload.file && (
        <FileUploaderItem
          name={upload.name}
          status={upload.status}
          invalid={upload.invalid}
          onDelete={() => setUpload(undefined)}
        />
      )}
      {!!errors.length && (
        <InlineNotification
          kind={'error'}
          lowContrast
          title={'Error!'}
          subtitle={`${errors.join(', ')}.`}
        />
      )}
    </Modal>
  );
};

const FileList = ({ children }: { children: ReactNode }) => {
  return <ul className={style.fileList}>{children}</ul>;
};

const FileListItem = ({ children }: { children: ReactNode }) => {
  return <li className={style.fileListItem}>{children}</li>;
};

const File = ({
  file,
  onRemoveClick,
}: {
  file: WellSupportingFileResponse;
  onRemoveClick: () => void;
}) => {
  return (
    <Tile light className={style.file}>
      <p className={style.title}>{file.createdBy}</p>
      <Field
        label="File Name"
        value={
          <Link
            truncate
            to={file.attachmentHref}
            target="_blank"
            rel="noreferrer"
          >
            {file.fileName}
          </Link>
        }
        title={file.fileName}
      />
      <Field label="File Type" value={file.fileType} truncate />
      <div className={style.actions}>
        <TrashButton
          kind="ghost"
          description="Remove"
          size="md"
          tooltipPosition="left"
          onClick={onRemoveClick}
        />
      </div>
    </Tile>
  );
};

const SupportingFilesImpl = ({
  files,
  supportingFilesHref,
  getSupportingFiles,
}: {
  files: WellSupportingFileResponse[] | undefined;
  supportingFilesHref: string;
  getSupportingFiles: () => Promise<void>;
}) => {
  const [open, toggleOpen] = useReducer((s) => !s, false);
  const [fileToDeleteUri, setFileToDeleteUri] = useState('');
  const deleteFileAtom = useDeleteFileAtom(getSupportingFiles);
  const deleteFile = useSetAtom(deleteFileAtom);
  return (
    <div className={style.supportingFiles}>
      <DestroyConfirmation
        open={!!fileToDeleteUri}
        onRequestSubmit={() => {
          deleteFile(fileToDeleteUri);
          setFileToDeleteUri('');
        }}
        onRequestClose={() => {
          setFileToDeleteUri('');
        }}
      />
      <ImportModal
        open={open}
        onClose={toggleOpen}
        supportingFilesHref={supportingFilesHref}
        getSupportingFiles={getSupportingFiles}
      />
      <div className={style.btnContainer}>
        <Button
          kind="ghost"
          size="md"
          renderIcon={DocumentImport16}
          onClick={toggleOpen}
        >
          Import Files
        </Button>
      </div>
      <Tile className={style.filesContainer}>
        {files ? (
          files.length ? (
            <FileList>
              {files.map((file) => (
                <FileListItem key={file.id}>
                  <File
                    file={file}
                    onRemoveClick={() => setFileToDeleteUri(file.href)}
                  />
                </FileListItem>
              ))}
            </FileList>
          ) : (
            <EmptyState
              icon={DocumentSvg}
              headerText="No Files Available"
              helperText="Currently this well doesn't have any supporting files."
            />
          )
        ) : (
          <FileList>
            <FileListItem>
              <SkeletonPlaceholder className={style.file} />
            </FileListItem>
            <FileListItem>
              <SkeletonPlaceholder className={style.file} />
            </FileListItem>
            <FileListItem>
              <SkeletonPlaceholder className={style.file} />
            </FileListItem>
          </FileList>
        )}
      </Tile>
    </div>
  );
};

const SupportingFiles = ({ href }: { href: string }) => {
  const supportingFilesAtom = useSupportingFilesAtom(href);
  const [supportingFiles, getSupportingFiles] = useAtom(supportingFilesAtom);

  if (supportingFiles.data && 'error' in supportingFiles.data)
    return (
      <InlineNotification
        kind="error"
        title={supportingFiles.data.error}
        lowContrast
      />
    );

  return (
    <SupportingFilesImpl
      files={supportingFiles.data}
      supportingFilesHref={href}
      getSupportingFiles={getSupportingFiles}
    />
  );
};

export { SupportingFiles };
