import { subscribe } from 'lib/pusher';
import { useEffect } from 'react';
import { Notifications } from 'types/api-responses';
import { PackageEvaluationProgress } from 'types/package-evaluation-progress';

const usePackageNotification = ({
  notifications,
  isSuccess,
  onStalled,
  onSuccess,
  onProgress,
}: {
  notifications?: Notifications;
  isSuccess?: boolean;
  onSuccess: () => void;
  onStalled: () => void;
  onProgress: (event: PackageEvaluationProgress) => void;
}) => {
  useEffect(() => {
    if (!notifications || isSuccess) {
      return () => void 0;
    }

    const channel = notifications.channel;
    const event = notifications.events.find((e) => e.name === 'progress');

    if (event) {
      const subscription = subscribe(channel);

      subscription.bind(event.name, (update: PackageEvaluationProgress) => {
        if (update.isStalled) {
          onStalled();
          return;
        }
        if (update.isSuccess) {
          onSuccess();
          subscription.unsubscribe();
        } else {
          onProgress(update);
        }
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [isSuccess, notifications, onSuccess, onProgress, onStalled]);
};

const onProgressCallback =
  (setSuccessPercentage: any) => (event: PackageEvaluationProgress) => {
    const total = event.total;
    if (total === 0) {
      setSuccessPercentage(undefined);
      return;
    }
    const pending = event.pending;
    const percentage = ((total - pending) / total) * 100;
    setSuccessPercentage(Math.floor(percentage));
  };

export { usePackageNotification, onProgressCallback };
