import { CheckmarkFilled20 } from '@carbon/icons-react';
import { atom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { formAtoms } from '../atoms';
import style from './header-checkmark.module.scss';
import { ReviewableSections } from './types';

const HeaderCheckMark = ({ field }: { field: ReviewableSections }) => {
  const fieldAtom = useMemo(
    () => atom((get) => get(get(formAtoms).formAtom)[field]),
    // Used to keep the atom stable, expected empty deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const sectionReview = useAtomValue(fieldAtom);
  return sectionReview.value ? (
    <span className={style.checkmark}>
      <CheckmarkFilled20 className={style.success} />
    </span>
  ) : null;
};

export { HeaderCheckMark };
