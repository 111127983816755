import { ColumnFiltersState } from '@tanstack/react-table';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useMemo, useState } from 'react';
import {
  fetchIsColumnFilterActive,
  updateIsColumnFilterActive,
} from 'utils/tables';

export function useColumnFilter(tableId: string) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const isColumnFilterActiveAtom = useMemo(
    () =>
      atomWithStorage<boolean>(
        tableId,
        false,
        {
          getItem: (key: string) => fetchIsColumnFilterActive(key),
          setItem: (key: string, newValue: boolean) =>
            updateIsColumnFilterActive(key, newValue),
          removeItem: () => void 0,
        },
        { getOnInit: true }
      ),
    [tableId]
  );

  const [isColumnFilterActive, setIsColumnFilterActive] = useAtom(
    isColumnFilterActiveAtom
  );

  return {
    columnFilters,
    setColumnFilters,
    isColumnFilterActive,
    setIsColumnFilterActive,
  };
}
