import {
  createMutationAtom,
  FormField,
  FormRowStatus,
  initializeForm,
} from 'atoms/create-resource-atom';
import { documentAtom, formAtoms } from 'components/interpretation-form/atoms';
import { getQuarteredGeometry } from 'components/map/utils';
import { GeoJSON } from 'geojson';
import { atom, PrimitiveAtom } from 'jotai';
import {
  DocumentInterpretationResponse,
  LocationResponse,
  ReferenceLocationResponse,
} from 'types/api-responses';
import { MineralLeaseAtoms } from '../lease-transaction';
import { LeaseManagementSubform } from '../types';
import {
  leaseExtensionFormDefault,
  leaseExtensionSurveySubform,
  LeaseExtensionSurveySubformAtoms,
  leaseManagementSubform,
  LeaseManagementSubformAtoms,
  validateLeaseManagement,
  validateSurveyAreas,
} from './utils';

const leaseManagementValidationAtom = atom<
  null,
  [
    {
      leaseManagementAtoms: LeaseManagementSubformAtoms;
      transactionAtoms: MineralLeaseAtoms;
      validationUrl: string | undefined;
      onSuccess: () => void;
      onError?: () => void;
    }
  ],
  void
>(
  () => null,
  (
    get,
    set,
    {
      leaseManagementAtoms,
      transactionAtoms,
      validationUrl,
      onError,
      onSuccess,
    }
  ) => {
    const hasClientErrors = validateLeaseManagement(
      leaseManagementAtoms,
      get,
      set
    );
    if (!hasClientErrors) {
      validateSurveyAreas({
        leaseManagementAtoms,
        transactionAtoms,
        validationUrl,
        onSuccess,
        onError,
        get,
        set,
      });
    }
  }
);

const locationReferencesAtom = atom((get) => {
  const locationAtoms = get(get(formAtoms).formAtom).locationReferences.value;
  return locationAtoms.map((el) => {
    const field = get(el.row.formAtom).referenceLocation;
    return field;
  });
});

const createAbortableQuarteredGeometriesAtom = (
  interpretationLocations: FormField<LocationResponse | null>[],
  href: string | undefined
) => {
  const abortableAtom = atom<Array<GeoJSON.Polygon | GeoJSON.MultiPolygon>>([]);

  abortableAtom.onMount = (setAtom) => {
    const abortController = new AbortController();

    const promises = interpretationLocations.map((location) => {
      if (!location.value) {
        return null;
      }
      if (!href) return null;
      if (!location.value.geometry) {
        return getQuarteredGeometry(
          location.value.referenceLocation.id,
          location.value.quarterCalls,
          href,
          abortController
        ).then((res) => {
          return res.type === 'success' ? res.data.geometry : null;
        });
      }
      return Promise.resolve(location.value.geometry);
    });

    const getGeographies = async () => {
      const results = await Promise.all(promises);
      const geometries = results.flatMap((el) => (el ? [el] : []));
      setAtom(geometries);
    };

    getGeographies();

    return () => abortController.abort();
  };
  return abortableAtom;
};

const createLeaseSelectedAreasAtom = (
  areas: PrimitiveAtom<LeaseExtensionSurveySubformAtoms[]>
) => {
  return atom((get) =>
    get(areas).map((el) => ({
      referenceLocation: get(el.row.formAtom).referenceLocation,
      quarterCalls: get(el.row.formAtom).quarterCalls,
      startDepthInFeet: get(el.row.formAtom).startDepthInFeet,
      endDepthInFeet: get(el.row.formAtom).endDepthInFeet,
      forDeletion: get(el.status) === 'trash',
    }))
  );
};

const extensionsAreaValidationAtom = createMutationAtom<
  DocumentInterpretationResponse,
  'documentInterpretationValidation'
>();

interface ModalState {
  open: boolean;
  state?: {
    retainedLand: 'none' | 'all' | 'partial';
    leaseExtension: string;
    locationReferences: Array<{
      id: number | undefined;
      referenceLocation: ReferenceLocationResponse;
      extensionReason: string;
      startDepthInFeet: string | null;
      endDepthInFeet: string | null;
      quarterCalls: string[];
      rowStatus: FormRowStatus;
    }>;
  };
}

const modalStateBaseAtom = atom<ModalState>({ open: false, state: undefined });
const modalStateAtom = atom<
  ModalState,
  [{ atoms: MineralLeaseAtoms; action: 'close' | 'save' | 'open' }],
  undefined
>(
  (get) => get(modalStateBaseAtom),
  (get, set, payload) => {
    const fieldStatus = get(payload.atoms.formAtom).leaseManagement.state;
    const fields = get(payload.atoms.formAtom);
    const leaseManagement = get(fields.leaseManagement.value.formAtom);
    const state = get(modalStateBaseAtom).state;
    const newOpenState = !get(modalStateBaseAtom).open;

    // Model has been toggled to close
    if (payload.action === 'close' || payload.action === 'save') {
      if (state && payload.action === 'close') {
        const locationReferences = state.locationReferences.map((e) =>
          leaseExtensionSurveySubform(
            leaseExtensionFormDefault(
              {
                id: e.id,
                referenceLocation: e.referenceLocation,
                endDepthInFeet: e.endDepthInFeet,
                startDepthInFeet: e.startDepthInFeet,
                quarterCalls: e.quarterCalls,
              },
              fieldStatus
            ),
            e.rowStatus
          )
        );
        const leaseManagement = leaseManagementSubform(
          initializeForm<LeaseManagementSubform>(
            {
              retainedLand: state.retainedLand,
              leaseExtension: state.leaseExtension,
              locationReferences: atom(locationReferences),
            },
            'updated'
          )
        );
        set(payload.atoms.formAtom, {
          field: 'leaseManagement',
          value: leaseManagement,
        });
      }

      set(modalStateBaseAtom, {
        open: newOpenState,
        state: undefined,
      });

      return;
    }

    if (payload.action === 'open') {
      const reason =
        leaseManagement.leaseExtension.value ?? 'held_by_production';
      set(modalStateBaseAtom, {
        open: !get(modalStateBaseAtom).open,
        state: {
          retainedLand: leaseManagement.retainedLand.value,
          leaseExtension: reason,
          locationReferences: get(leaseManagement.locationReferences.value)
            .map((el) => {
              const row = get(el.row.formAtom);
              const referenceLocation = row.referenceLocation.value;
              if (!referenceLocation) return;
              return {
                id: row.id.value,
                extensionReason: reason,
                referenceLocation: referenceLocation,
                startDepthInFeet: row.startDepthInFeet.value,
                endDepthInFeet: row.endDepthInFeet.value,
                quarterCalls: row.quarterCalls.value,
                rowStatus: get(el.status),
              };
            })
            .flatMap((el) => (el ? [el] : [])),
        },
      });
    }
  }
);

const validationUrlAtom = atom<string | undefined>(
  (get) => get(documentAtom)?.resources.interpretationValidation?.href
);

export {
  createAbortableQuarteredGeometriesAtom,
  createLeaseSelectedAreasAtom,
  leaseManagementValidationAtom,
  locationReferencesAtom,
  modalStateAtom,
  extensionsAreaValidationAtom,
  validationUrlAtom,
};
