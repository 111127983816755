import {
  EquationAST,
  InterestCalculationDetails,
  ValueWithContext,
} from 'types/title-calculations/types';
import { Link } from 'components/link';
import { Operation, OperationContextProps, Value } from './operation';

import style from './interest-math.module.scss';
import { HighlightRunsheetRowProps } from 'lib/types';
import { TOP_RUNSHEET_SECTIONS_HEIGHT_IN_PX } from 'lib/constants';
import { OnSelectedTransaction } from './calculation-details';
import { formatInterest } from 'utils/format-interest';

const EquationOrValue = ({
  value,
  onJump,
  onSee,
}: {
  value: EquationAST | ValueWithContext;
} & OperationContextProps) => {
  if (!('operation' in value)) {
    return <Value value={value} onSee={onSee} onJump={onJump} />;
  }

  return (
    <>
      {formatInterest(value.value)} ={' '}
      <Operation equationAST={value} onJump={onJump} onSee={onSee} />
    </>
  );
};

const InterestMath = ({
  interest,
  setSelectedTransaction,
  refsMap,
  setHighlightedRow,
  interpretationId,
  changed,
}: {
  interest: InterestCalculationDetails | null;
  setSelectedTransaction: OnSelectedTransaction;
  interpretationId: number;
  changed: boolean;
} & HighlightRunsheetRowProps) => {
  if (!interest) return null;

  interest.byBurdenedEntity.sort((a, b) => {
    return (a.entity?.name ?? '') < (b.entity?.name ?? '') ? -1 : 1;
  });

  const onSee = (context: {
    transactionId: number;
    interpretationHref: string;
    documentTitle: string;
  }) => {
    setSelectedTransaction(context);
  };

  const onJump = (jumpToId: number) => {
    const ref = refsMap.get(jumpToId);
    if (ref) {
      const topPosition = ref.getBoundingClientRect().top;
      const offsetPosition =
        topPosition + window.screenY - TOP_RUNSHEET_SECTIONS_HEIGHT_IN_PX;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      setHighlightedRow({
        current: jumpToId,
        previous: interpretationId,
      });

      ref.classList.add(style.highlight);

      setTimeout(() => {
        ref.classList.remove(style.highlight);
      }, 2000);
    }
  };

  return (
    <table className={style.interestTable}>
      <thead>
        <tr>
          <th>Burdened Entity</th>
          {changed && <th>Before</th>}
          {changed && <th>After</th>}
          {changed || <th>Value</th>}
        </tr>
      </thead>
      <tbody>
        {interest.byBurdenedEntity.map((el, i) => {
          return (
            <tr key={i}>
              <td>
                {el.entity ? (
                  <Link to={el.entity.href} target="_blank">
                    <p className={style.truncateText}>{el.entity.name}</p>
                  </Link>
                ) : (
                  '--'
                )}
              </td>
              <td>
                <div className={style.alignOperationMiddle}>
                  <EquationOrValue
                    value={el.before}
                    onSee={onSee}
                    onJump={onJump}
                  />
                </div>
              </td>
              {changed && (
                <td>
                  <div className={style.alignOperationMiddle}>
                    <EquationOrValue
                      value={el.after}
                      onSee={onSee}
                      onJump={onJump}
                    />
                  </div>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { InterestMath };
