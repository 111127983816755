import { Checkbox } from 'carbon-components-react';
import { PrimitiveAtom, atom, useAtomValue, useSetAtom } from 'jotai';
import {
  assetsLayerAtom,
  LayerAtom,
  AllLayersAtom,
  developmentAreaLayerAtom,
  titleLayerAtom,
  allLayersBaseAtom,
  useCreateAllLayersAtom,
} from './atoms';

import style from 'components/map/map.module.scss';
import { useEffect, useMemo } from 'react';

// Represents a checkbox to select/unselect a map layer.
//
// If atoms is provided, it means that the checkbox is
// used to toggle multiple checkboxes at the same time.
// The elements of the array represents the atoms linked to
// the other checkboxes
const Layer = ({
  atom,
  id,
  label,
  atoms,
}: {
  atom: LayerAtom | AllLayersAtom;
  id: string;
  label: string;
  atoms?: PrimitiveAtom<boolean>[];
}) => {
  const value = useAtomValue(atom);
  const setValue = useSetAtom(atom);

  return (
    <Checkbox
      id={id}
      labelText={label}
      checked={value}
      onChange={(checked: boolean) => {
        setValue(checked, atoms);
      }}
    />
  );
};

const ScopedPackageLayerSelection = ({
  layerAtoms,
}: {
  layerAtoms?: {
    assets: PrimitiveAtom<boolean>;
    devAreas: PrimitiveAtom<boolean>;
    title: PrimitiveAtom<boolean>;
  };
}) => {
  const assetAtom = layerAtoms?.assets || assetsLayerAtom;
  const developmentAreaAtom = layerAtoms?.devAreas || developmentAreaLayerAtom;
  const titleAtom = layerAtoms?.title || titleLayerAtom;
  const assetsLayer = useAtomValue(assetAtom);
  const developmentAreaLayer = useAtomValue(developmentAreaAtom);
  const titleLayer = useAtomValue(titleAtom);
  const localAllLayersAtom = useMemo(() => atom(true), []);
  const allLayersAtom = useCreateAllLayersAtom(
    layerAtoms ? localAllLayersAtom : allLayersBaseAtom
  );
  const setAllLayers = useSetAtom(allLayersAtom);
  const atoms = [assetAtom, developmentAreaAtom, titleAtom];

  useEffect(() => {
    const allLayers = [assetsLayer, developmentAreaLayer, titleLayer];
    if (allLayers.every((el) => el)) {
      setAllLayers(true, undefined);
      return;
    }
    if (allLayers.some((el) => !el)) {
      setAllLayers(false, undefined);
      return;
    }
  }, [assetsLayer, developmentAreaLayer, setAllLayers, titleLayer]);

  return (
    <>
      <div className={style.titleArea}>
        <div className={style.allCheckBox}>
          <Layer
            atom={allLayersAtom}
            id={'scoped-layers'}
            label="Layers"
            atoms={atoms}
          />
        </div>
      </div>
      <div className={style.groupChecks}>
        <div className={style.scopedAssets}>
          <Layer atom={assetAtom} id={'package-assets-layer'} label="Assets" />
        </div>
        <div className={style.scopedUnits}>
          <Layer
            atom={developmentAreaAtom}
            id={'package-development-areas-layer'}
            label="Units"
          />
        </div>
        <div className={style.scopedTitle}>
          <Layer atom={titleAtom} id={'package-title-layer'} label="Title" />
        </div>
      </div>
    </>
  );
};

export { ScopedPackageLayerSelection };
