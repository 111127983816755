import {
  createMutationAtom,
  CreateResourceAtom,
  createResourceFormAtom,
  FormErrors,
  FormRowStatus,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { ApiPayloads } from 'types/api-payloads';
import {
  DevelopmentAreaResponse,
  ReferenceLocationResponse,
} from 'types/api-responses';
import { DevelopmentAreaForm, GeographyAttributes } from './types';
import { createSurveyAreaSubform, SurveyAreaSubformAtoms } from './utils';
import { getResources } from 'atoms/root';

const stepAtom = atom(0);

const developmentAreaAtom = atom<DevelopmentAreaResponse | undefined>(
  undefined
);

const developmentAreaNameAtom = selectAtom(
  developmentAreaAtom,
  (el) => el?.name
);

const formAtoms = atom((get) => {
  const devArea = get(developmentAreaAtom);
  const initialForm = initializeForm<DevelopmentAreaForm>(
    {
      name: devArea?.name || null,
      surveyAreas: atom<SurveyAreaSubformAtoms[]>(
        (devArea?.developmentAreaGeographies || []).map((el) =>
          createSurveyAreaSubform(
            {
              id: el.id,
              location: el.referenceLocation,
              quarterCalls: el.quarterCalls,
            },
            rowStatus('stable')
          )
        )
      ),
    },
    'stable'
  );

  return createResourceFormAtom<
    DevelopmentAreaForm,
    FormErrors<DevelopmentAreaForm>
  >(initialForm);
});

const errorsAtomBase = atom('');
const errorsAtom = atom<
  string,
  [ApiPayloads['developmentAreas']['error'] | undefined],
  void
>(
  (get) => get(errorsAtomBase),
  (get, set, error) => {
    if (!error) {
      set(errorsAtomBase, '');
      return;
    }

    const { errors } = error;
    const concatenatedErrors = Object.values(errors)
      .map((el) => el)
      .join(', ');

    set(errorsAtomBase, concatenatedErrors);
  }
);

const referenceLocationIdsAtom = atom<number[]>((get) => {
  const surveysAtom = get(get(formAtoms).formAtom).surveyAreas.value;
  return get(surveysAtom).flatMap((el) => {
    const id = get(el.row.formAtom).location.value?.id;
    if (id) {
      return [id];
    }
    return [];
  });
});

const geographiesAtom = atom<
  {
    id: number | null;
    referenceLocation: ReferenceLocationResponse;
    quarterCalls: string[];
    status: FormRowStatus;
  }[]
>((get) => {
  const form = get(get(formAtoms).formAtom);
  const surveysAtom = form.surveyAreas.value;
  return get(surveysAtom).flatMap((el) => {
    const surveyForm = get(el.row.formAtom);
    const status = get(el.status);
    const referenceLocation = surveyForm.location.value;
    const quarterCalls = surveyForm.quarterCalls.value;
    if (referenceLocation) {
      return [
        {
          id: surveyForm.id.value,
          referenceLocation: referenceLocation,
          quarterCalls: quarterCalls,
          status,
        },
      ];
    }
    return [];
  });
});

const geographiesAttributesAtom = atom<GeographyAttributes[]>((get) =>
  get(geographiesAtom).map((el) => ({
    id: el.id,
    referenceLocationId: el.referenceLocation.id,
    quarterCalls: el.quarterCalls,
  }))
);

const isFormValidAtom = atom<boolean>((get) => {
  let isValid = false;
  const form = get(get(formAtoms).formAtom);
  const locations = get(form.surveyAreas.value);
  const emptyLocations = locations
    .map((el) => get(el.row.formAtom).location.value)
    .some((el) => !el);

  if (form.name.value && locations.length && !emptyLocations) {
    isValid = true;
  }

  return isValid;
});

const devAreaMutationAtom = createMutationAtom<null, 'developmentAreas'>();
const runMutationAtom = atom<
  CreateResourceAtom<null, ApiPayloads['developmentAreas']['error']>,
  [
    {
      type: 'mutate' | 'validate';
      onSuccess: () => void;
      onError?: (error?: ApiPayloads['developmentAreas']['error']) => void;
    }
  ],
  void
>(
  (get) => get(devAreaMutationAtom),
  (get, set, { onSuccess, onError, type }) => {
    const createDevArea = () => {
      const devArea = get(developmentAreaAtom);
      const isEditing = type === 'mutate' && devArea;
      const resources = getResources(get);
      if (!resources) return;
      const url = isEditing
        ? devArea.href
        : type === 'mutate'
        ? resources['developmentAreas'].href
        : resources.validations['developmentAreas'].href;

      const name = get(get(formAtoms).formAtom).name.value;
      if (!name) return;

      set(devAreaMutationAtom, {
        url,
        type: isEditing ? 'PATCH' : 'POST',
        data: {
          id: devArea?.id,
          name,
          developmentAreaGeographiesAttributes: get(geographiesAtom).map(
            (el) => {
              if (el.status === 'trash' && el.id) {
                return { _destroy: true, id: el.id };
              }
              return {
                id: el.id,
                referenceLocationId: el.referenceLocation.id,
                quarterCalls: el.quarterCalls,
              };
            }
          ),
        },
        onSuccess: () => {
          if (isEditing) {
            if (name !== devArea.name) {
              window.location.href = `/development_areas/${devArea.id}`;
              return;
            }
            onSuccess();
          } else {
            onSuccess();
          }
        },
        onError: (error) => {
          set(get(formAtoms).dirtyFieldsAtom, {
            errors: { name: error?.errors.name },
          });
          onError?.(error);
        },
      });
    };

    createDevArea();
  }
);

const hoveredLocationsAtom = atom<number[]>([]);

export {
  developmentAreaAtom,
  developmentAreaNameAtom,
  errorsAtom,
  formAtoms,
  geographiesAtom,
  geographiesAttributesAtom,
  hoveredLocationsAtom,
  isFormValidAtom,
  referenceLocationIdsAtom,
  runMutationAtom,
  stepAtom,
};
