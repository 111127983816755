import { AtomizedForm } from 'atoms/form-atoms';
import { FormGroup } from 'carbon-components-react';
import { NumberField } from 'components/form-fields/number-field';
import { FormRow } from 'components/forms';
import { useAtomValue } from 'jotai';
import { useValidateSpudAndFirstDatePercentage } from 'lib/evaluation-parameters/validations';
import { TierCashflowsPayload } from 'types/scenarios-api/responses';
import { ScenariosForm } from '../types';

const PercentageCosts = ({
  form,
}: {
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
      >
    | AtomizedForm<TierCashflowsPayload>;
}) => {
  const spudAndFirstDatePercentageValidAtom =
    useValidateSpudAndFirstDatePercentage(
      form.leaseholdAttributes.percentageCostSpudDate,
      form.leaseholdAttributes.percentageCostFirstProductionDate
    );

  const spudAndFirstDatePercentageValid = useAtomValue(
    spudAndFirstDatePercentageValidAtom
  );

  const leaseholdAttributes = form.leaseholdAttributes;

  const spudPercentage = useAtomValue(
    form.leaseholdAttributes.percentageCostSpudDate
  );
  const firstDatePercentage = useAtomValue(
    form.leaseholdAttributes.percentageCostFirstProductionDate
  );

  const error = !spudAndFirstDatePercentageValid
    ? 'Spud Date and First Date percentage cost should sum 100%'
    : null;

  return (
    <>
      <FormRow>
        <NumberField
          id="percentageCostSpudDate"
          label="Percentage Cost SPUD Date"
          light
          field={leaseholdAttributes.percentageCostSpudDate}
          invalid={!spudAndFirstDatePercentageValid || !spudPercentage.isValid}
          invalidText={!spudPercentage.isValid ? spudPercentage.error : error}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="percentageCostFirstProductionDate"
          label="Percentage Cost First Production Date"
          light
          field={leaseholdAttributes.percentageCostFirstProductionDate}
          invalid={
            !spudAndFirstDatePercentageValid || !firstDatePercentage.isValid
          }
          invalidText={
            !firstDatePercentage.isValid ? firstDatePercentage.error : error
          }
        />
      </FormRow>
    </>
  );
};

const FixedCosts = ({
  form,
}: {
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
      >
    | AtomizedForm<TierCashflowsPayload>;
}) => {
  const loePerWellCostAttributes =
    form.leaseholdAttributes.loePerWellCostAttributes;
  return (
    <FormGroup legendText="Fixed Costs">
      <FormRow>
        <NumberField
          id="dcCostPerWell"
          label="Drilling & Completion Cost Per Well ($/10,000 ft lateral)"
          light
          field={form.leaseholdAttributes.dcCostPerWell}
        />
      </FormRow>
      <PercentageCosts form={form} />
      <p>Lease Operating Expense Per Well</p>
      <FormRow>
        <NumberField
          id="loeCostPerWellYear1"
          label="Year 1 ($/month)"
          light
          field={loePerWellCostAttributes.year1}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="loeCostPerWellYear2"
          label="Year 2 ($/month)"
          light
          field={loePerWellCostAttributes.year2}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="loeCostPerWellYear3"
          label="Year 3 ($/month)"
          light
          field={loePerWellCostAttributes.year3}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="loeCostPerWellYear4"
          label="Year 4 ($/month)"
          light
          field={loePerWellCostAttributes.year4}
        />
      </FormRow>
      <FormRow>
        <NumberField
          id="loeCostPerWellYear5AndUp"
          label="Year 5 and Up ($/month)"
          light
          field={loePerWellCostAttributes.year5AndUp}
        />
      </FormRow>
    </FormGroup>
  );
};

export { FixedCosts };
