import { JSONObject, JSONValue } from 'lib/types';
import { CompletionItemResponse } from 'types/api-responses';

export function filterNullValues(data: JSONValue): JSONValue {
  if (data === null || data === undefined) {
    return null;
  }
  if (typeof data !== 'object') {
    return data;
  }
  if (Array.isArray(data)) {
    const filteredArray = data.map(filterNullValues).filter((v) => v !== null);
    return filteredArray.length ? filteredArray : null;
  }
  const filteredObject: JSONObject = {};
  for (const [key, value] of Object.entries(data)) {
    const filteredValue = filterNullValues(value);
    if (filteredValue !== null) {
      filteredObject[key] = filteredValue;
    }
  }
  return Object.keys(filteredObject).length ? filteredObject : null;
}

export const checkChildrenStatus = (
  state: JSONObject
): { correct: number; incorrect: number } | null => {
  if (typeof state !== 'object' || state === null) return state;

  const collectValues = (item: JSONValue): JSONValue[] => {
    if (typeof item !== 'object' || item === null) return [item];
    if (Array.isArray(item)) {
      return item.flatMap(collectValues);
    }
    return Object.values(item).flatMap(collectValues);
  };

  const statuses = collectValues(state);
  if (statuses.length === 0) return null;

  const reviewCount = {
    correct: 0,
    incorrect: 0,
  };

  for (const status of statuses) {
    const isBoolean = typeof status === 'boolean';
    if (isBoolean) {
      if (status) {
        reviewCount.correct++;
      } else {
        reviewCount.incorrect++;
      }
    }
  }

  return reviewCount;
};

export const getPathState = (obj: Record<string, any>, path: string[]) => {
  return path.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj
  );
};

export const updateValidationState = (
  path: string[],
  state: JSONObject,
  status: boolean | null
) => {
  const newState = { ...state };

  path.reduce<{ [x: string]: any }>((acc, key, idx) => {
    if (idx === path.length - 1) {
      acc[key] = status;
    } else {
      acc[key] = acc[key] || {};
    }
    return acc[key];
  }, newState);

  return newState;
};

export function snakeToCapitalCase(input: string): string {
  // Split the string by underscore, keeping the HTML tags intact
  const parts = input.split(/(_highlight_\w+">|<\/span>)/);

  return parts
    .map((part) => {
      // Handle parts with HTML tags
      if (part.match(/(_highlight_\w+">|<\/span>)/)) {
        return part;
      }
      // Capitalize each word in the part and join them with spaces
      return part
        .split('_')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    })
    .join('');
}

export function createItemsMap(items: CompletionItemResponse[]) {
  const map: Record<string, CompletionItemResponse> = {};
  for (const part of items) {
    map[part.path] = part;
  }
  return map;
}
