import { SubtractAlt16, TrashCan16 } from '@carbon/icons-react';
import { FormRowStatus } from 'atoms/create-resource-atom';
import { AtomField } from 'atoms/form-atoms';
import { TrashButton } from 'components/trash-button';
import { useAtom } from 'jotai';

const TrashRowButton = ({
  atom,
  onDelete,
  index,
  adjustForLabel,
}: {
  atom: AtomField<FormRowStatus>;
  onDelete: (index: number) => void;
  index: number;
  adjustForLabel?: boolean;
}) => {
  const [rowStatus, setRowStatus] = useAtom(atom);
  const status = rowStatus.value;
  return (
    <TrashButton
      size="field"
      adjustForLabel={adjustForLabel}
      description="Remove"
      iconDescription={status === 'trash' ? 'Undo remove row' : 'Remove row'}
      kind={status === 'trash' ? 'danger' : 'ghost'}
      renderIcon={status === 'new' ? SubtractAlt16 : TrashCan16}
      onClick={() => {
        if (status === 'saved') {
          setRowStatus('trash');
        } else if (status === 'trash') {
          setRowStatus('saved');
        } else {
          onDelete(index);
        }
      }}
    />
  );
};

export { TrashRowButton };
