import { Tab, Tabs } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import style from './tabs.module.scss';
import { TitleWorkspaceResponse } from 'types/api-responses';

const getSelectedTabIndex = (pathname: string, workspaceHref: string) => {
  if (pathname === workspaceHref) return 0;
  if (pathname.includes('documents')) return 1;
  if (pathname.includes('runsheet')) return 2;
  if (pathname.includes('wells')) return 3;
  if (pathname.includes('entities')) return 4;
};
const WorkspaceTabs = ({
  titleWorkspace,
}: {
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const resources = titleWorkspace.resources;
  const workspaceHref = titleWorkspace.href;

  const selectedTabIndex = getSelectedTabIndex(
    location.pathname,
    workspaceHref
  );

  return (
    <div className={style.tabs}>
      <Tabs selected={selectedTabIndex}>
        <Tab
          id="workspace-overview"
          label="Workspace Overview"
          renderButton={(props) => (
            <Link {...props} to={workspaceHref}>
              Workspace Overview
            </Link>
          )}
        />
        <Tab
          id="document_index"
          label="Document Index"
          renderButton={(props) => (
            <Link {...props} to={resources.documents.href}>
              Document Index
            </Link>
          )}
        />
        <Tab
          id="runsheet"
          label="Runsheet"
          renderButton={(props) => (
            <Link {...props} to={resources.runsheet.href}>
              Runsheet
            </Link>
          )}
        />
        <Tab
          id="wells"
          label="Wells"
          renderButton={(props) => (
            <Link {...props} to={resources.wells.href}>
              Wells
            </Link>
          )}
        />
        <Tab
          id="entities"
          label="Entities"
          renderButton={(props) => (
            <Link {...props} to={resources.entities.href}>
              Entities
            </Link>
          )}
        />
      </Tabs>
    </div>
  );
};

export { WorkspaceTabs };
