import Pusher from 'pusher-js/with-encryption';

let client;

const { key, cluster } = window.CONFIG.pusher;

if (key) {
  client = new Pusher(key, { cluster });
} else {
  console.warn('Pusher is not configured.');
}

const NOOP = function () {};

const fakeChannel = {
  bind: NOOP,
  unbind: NOOP,
};

export function subscribe(...args) {
  if (client) {
    return client.subscribe(...args);
  } else {
    return fakeChannel;
  }
}
