import { Tag } from 'carbon-components-react';

export const ImportedTag = ({ value }: { value: boolean }) => {
  return value ? (
    <aside>
      <Tag type="cyan" size="sm">
        Imported
      </Tag>
    </aside>
  ) : null;
};
