import { DatePicker } from 'components/date-picker';
import { Button } from 'carbon-components-react';
import { Reset16 } from '@carbon/icons-react';
import style from './superseding-date.module.scss';
import { useMemo } from 'react';
import { formAtoms } from '../atoms';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { getDateString } from 'utils/dates';

const SupersedingDate = () => {
  const supersedingDateAtom = useMemo(
    () => atom((get) => get(get(formAtoms).formAtom).supersedingDate),
    []
  );
  const setField = useSetAtom(useAtomValue(formAtoms).formAtom);
  const supersedingDate = useAtomValue(supersedingDateAtom);

  return (
    <div className={style.container}>
      <label htmlFor="superseding-date-picker" className={style.labelHeader}>
        Superseding Date
      </label>
      <DatePicker
        size="md"
        light
        id="superseding-date-picker"
        labelText=""
        value={supersedingDate?.value ?? undefined}
        onChange={(date) => {
          setField({
            field: 'supersedingDate',
            value: date[0] ? getDateString(date[0]) : null,
          });
        }}
      ></DatePicker>
      <Button
        id="clear-date-button"
        type="reset"
        name="Clear Date"
        size="md"
        hasIconOnly={true}
        className={style.clearButton}
        kind="ghost"
        iconDescription="Clear Date"
        renderIcon={Reset16}
        onClick={() => {
          setField({ field: 'supersedingDate', value: null });
        }}
      ></Button>
    </div>
  );
};

export { SupersedingDate };
