import style from './stat-card.module.scss';
import { FC } from 'react';

interface StatCardProps {
  title: string;
  value: string | undefined;
  icon?: React.ReactNode;
}

const StatCard: FC<StatCardProps> = ({ title, value, icon }) => {
  return (
    <div className={style.statCardContainer}>
      <div className={style.statCardContent}>
        {icon && <div className={style.icon}>{icon}</div>}
        <div className={style.title}>{title}</div>
        <div className={style.value}>{value}</div>
      </div>
    </div>
  );
};

export { StatCard };
