import searchAtomPair from 'atoms/search-atom';
import { PrimitiveAtom, atom, useAtom, useSetAtom } from 'jotai';
import { atomWithLocation } from 'jotai-location';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { DocumentInterpretationResponse } from 'types/api-responses';
import {
  InterpretationSearchAggregations,
  InterpretationSearchParams,
} from './types';
import { DocumentFacets } from 'components/search/common/types';
import { HasCompletionType } from 'lib/types';
import { isProvisionType } from './utils';

export const DEFAULT_PAGE_SIZE = 500;

const runsheetSearchPreferencesAtom = atomWithStorage<{
  pageSize: number;
  sortDirection: 'DESC' | 'ASC';
  sortAttribute: string;
  hideReviewed?: boolean;
  hasCompletion?: HasCompletionType;
  expanded?: boolean;
}>(
  'runsheet-search-preferences',
  {
    pageSize: DEFAULT_PAGE_SIZE,
    sortDirection: 'DESC',
    sortAttribute: 'date',
    hideReviewed: false,
    expanded: false,
  },
  undefined,
  { getOnInit: true }
);

const runsheetExpandWithCalculationDetails = atomWithStorage<boolean>(
  'runsheet-expand-all-with-calculation-details',
  true
);

const locationAtom = atomWithLocation({ replace: true });

const useRunsheetSearch = ({
  searchHref,
  skipUrlSearchParams,
}: {
  searchHref?: string;
  skipUrlSearchParams: boolean;
}) => {
  const [searchPreferences, setSearchPreferences] = useAtom(
    runsheetSearchPreferencesAtom
  );

  const [location, setLocation] = useAtom(locationAtom);
  const hasCompletion =
    location.searchParams?.get('hasCompletion') ||
    String(searchPreferences.hasCompletion);
  const { searchAtom, resetSearchDataAtom } = useMemo(
    () =>
      searchAtomPair<
        DocumentInterpretationResponse,
        InterpretationSearchParams,
        DocumentFacets,
        InterpretationSearchAggregations
      >(searchHref ? { href: searchHref } : 'interpretations', {
        page: location.searchParams?.get('page')
          ? Number(location.searchParams?.get('page'))
          : 1,
        text: location.searchParams?.get('text') || undefined,
        sortAttribute:
          location.searchParams?.get('sortAttribute') ||
          searchPreferences.sortAttribute,
        sortDirection: (location.searchParams?.get('sortDirection') ||
          searchPreferences.sortDirection) as InterpretationSearchParams['sortDirection'],
        pageSize: searchPreferences.pageSize,
        hideReviewed:
          location.searchParams?.get('hideReviewed') === 'true'
            ? true
            : !!searchPreferences.hideReviewed,
        hasCompletion: isProvisionType(hasCompletion)
          ? hasCompletion
          : undefined,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const setFunction = useCallback(
    ({
      params,
      persistenceParams,
      urlSearchParams,
      resetSearch = false,
    }: {
      params: Partial<InterpretationSearchParams>;
      persistenceParams?: Partial<InterpretationSearchParams>;
      urlSearchParams?: Partial<InterpretationSearchParams>;
      resetSearch?: boolean;
    }) => {
      setSearchPayload(
        (current) =>
          current && {
            ...current,
            ...params,
          }
      );

      if (resetSearch) resetSearchData(undefined);

      if (persistenceParams) {
        setSearchPreferences((current) => ({
          ...current,
          ...persistenceParams,
        }));
      }

      if (skipUrlSearchParams) return;

      if (urlSearchParams) {
        const getSearchParams = (
          prev: URLSearchParams | undefined,
          next: Partial<InterpretationSearchParams>
        ) => {
          const newParams = {
            ...(prev ? Object.fromEntries(prev.entries()) : {}),
            ...next,
          };
          return new URLSearchParams([
            ...Object.entries(newParams).map((el) => [el[0], String(el[1])]),
          ]);
        };

        setLocation((prev) => ({
          ...prev,
          searchParams: getSearchParams(prev.searchParams, urlSearchParams),
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [searchResults, setFunction] as const;
};

const useCreateEditedRowsAtomsMap = (
  size: number,
  config: 'skip-atoms' | 'create-atoms' = 'create-atoms'
) => {
  return useMemo(() => {
    const map: Record<number, PrimitiveAtom<boolean>> = {};
    if (config === 'skip-atoms') return map;
    for (let i = 0; i < size; i++) {
      map[i] = atom(false);
    }
    return map;
  }, [size, config]);
};

export {
  runsheetExpandWithCalculationDetails,
  runsheetSearchPreferencesAtom,
  useCreateEditedRowsAtomsMap,
  useRunsheetSearch,
};
