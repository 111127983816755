import { atom, useAtom } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import request from 'lib/request';
import { useEffect, useMemo, useReducer } from 'react';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';

const useInterpretationAtom = (
  href: string,
  titleWorkspace: number | undefined
) => {
  const resourceHref = titleWorkspace
    ? `${href}?title_workspace_id=${titleWorkspace}`
    : href;

  return useMemo(() => {
    return createResourceAtom<DocumentInterpretationResponse>(resourceHref);
  }, [resourceHref]);
};

const getDocumentAtom = () => {
  const loadingAtom = atom(false);
  return atom<
    boolean,
    [{ href: string; onSuccess: (document: DocumentResponse) => void }],
    void
  >(
    (get) => get(loadingAtom),
    async (get, set, { href, onSuccess }) => {
      set(loadingAtom, true);
      const document = await request.get<DocumentResponse>(href);
      if ('error' in document) {
        set(loadingAtom, false);
        return;
      }
      if (document.resources.image) {
        onSuccess(document);
        set(loadingAtom, false);
      }
    }
  );
};

const interpretationHasChangesAtom = atom<boolean>(false);

const usePendingChanges = () => {
  const hasChangesState = useAtom(interpretationHasChangesAtom);
  const showWarningReducer = useReducer((s) => !s, false);

  const [, setInterpretationHasChanges] = hasChangesState;

  useEffect(() => {
    return () => {
      setInterpretationHasChanges(false);
    };
  }, [setInterpretationHasChanges]);

  return [hasChangesState, showWarningReducer] as const;
};

export {
  getDocumentAtom,
  interpretationHasChangesAtom,
  useInterpretationAtom,
  usePendingChanges,
};
