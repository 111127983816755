import {
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Link,
  Table,
  TableContainer,
  Tooltip,
  InlineNotification,
} from 'carbon-components-react';
import { useAtomValue } from 'jotai';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import { NoResults } from './no-results';
import { useAssetsAtom } from './atoms';
import { Warning16 } from '@carbon/icons-react';
import style from './assets.module.scss';

import { EntityResponse } from 'types/api-responses';
import { Date } from 'components/date';

const NULL_DISPLAY_VALUE = '--';

export const Assets = ({ entity }: { entity: EntityResponse }) => {
  return (
    <div className={style.assetsGrid}>
      <h3 className={style.subTitle}>Assets</h3>
      <AssetsTable entity={entity} />
    </div>
  );
};

const AssetsTable = ({ entity }: { entity: EntityResponse }) => {
  const capacitiesAtom = useAssetsAtom(entity.resources.ownership.href);
  const { loading, data } = useAtomValue(capacitiesAtom);

  if (!loading && !data) {
    return <NoResults />;
  }

  if (data && 'error' in data)
    return <InlineNotification kind="error" title={data.error} lowContrast />;

  const tractNameMaxLimit = 30;
  const assets = data?.assets;

  if (!loading && !assets?.length) {
    return <NoResults />;
  }

  return (
    <div className={style.assetTableArea}>
      <TableContainer className={style.assetTable}>
        <Table aria-label="Assets" size="lg">
          <TableHead className={style.stickyHeader}>
            <TableRow>
              <TableHeader>Workspace</TableHeader>
              <TableHeader>Sub-Tract Name</TableHeader>
              <TableHeader>As of Date</TableHeader>
              <TableHeader>Surface</TableHeader>
              <TableHeader>Start Depth (ft)</TableHeader>
              <TableHeader>End Depth (ft)</TableHeader>
              <TableHeader>Royalty Rate</TableHeader>
              <TableHeader>Executive</TableHeader>
              <TableHeader>Bonus</TableHeader>
              <TableHeader>Delay</TableHeader>
              <TableHeader>Royalty</TableHeader>
              <TableHeader>Fixed</TableHeader>
              <TableHeader>WI</TableHeader>
              <TableHeader>ORR</TableHeader>
              <TableHeader>NRI</TableHeader>
              <TableHeader>Gross Acreage</TableHeader>
              <TableHeader>Legal Acreage</TableHeader>
              <TableHeader>Lease</TableHeader>
              <TableHeader>County</TableHeader>
              <TableHeader>Burdened Entity</TableHeader>
              <TableHeader>Warnings</TableHeader>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <SkeletonTableRows rows={10} columns={21} />
            </TableBody>
          ) : (
            <TableBody>
              {assets?.map((asset, i) => {
                return (
                  <TableRow key={`asset-${i}`}>
                    <TableCell className={style.assetsColumn}>
                      <Link
                        href={asset.titleWorkspace?.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {asset.titleWorkspace?.name}
                      </Link>
                    </TableCell>
                    <TableCell className={style.trackNameColumn}>
                      {asset.coveredArea.name.length > tractNameMaxLimit ? (
                        <Tooltip
                          direction="top"
                          align="start"
                          tabIndex={0}
                          triggerText={
                            asset.coveredArea.name.slice(
                              0,
                              tractNameMaxLimit - 3
                            ) + '...'
                          }
                        >
                          <p>{asset.coveredArea.name}</p>
                        </Tooltip>
                      ) : (
                        <p>{asset.coveredArea.name}</p>
                      )}
                    </TableCell>
                    <TableCell>
                      <Date date={asset.date} />
                    </TableCell>
                    <TableCell>{asset.surface || NULL_DISPLAY_VALUE}</TableCell>
                    <TableCell>
                      {asset.coveredArea.startDepthInFeet || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>
                      {asset.coveredArea.endDepthInFeet || '∞'}
                    </TableCell>
                    <TableCell>
                      {asset.lease?.royaltyRate || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>
                      {asset.executive || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>{asset.bonus || NULL_DISPLAY_VALUE}</TableCell>
                    <TableCell>{asset.delay || NULL_DISPLAY_VALUE}</TableCell>
                    <TableCell>{asset.royalty || NULL_DISPLAY_VALUE}</TableCell>
                    <TableCell>
                      {asset.fixedRoyalty || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>{asset.working || NULL_DISPLAY_VALUE}</TableCell>
                    <TableCell>
                      {asset.override || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>
                      {asset.netRevenueInterest || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>
                      {asset.coveredArea.calculatedAcreage ||
                        NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>
                      {asset.coveredArea.legalAcreage || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell className={style.leaseColumn}>
                      {asset.lease?.name || NULL_DISPLAY_VALUE}
                    </TableCell>
                    <TableCell>
                      {asset.titleWorkspace?.referenceLocation.county?.name}
                    </TableCell>
                    <TableCell>
                      {asset.burdenedEntity ? (
                        <Link href={asset.burdenedEntity.href} target="_blank">
                          {asset.burdenedEntity.name}
                        </Link>
                      ) : (
                        NULL_DISPLAY_VALUE
                      )}
                    </TableCell>
                    <TableCell>
                      {asset.preventedOverconveyance ? (
                        <Warning16 />
                      ) : (
                        NULL_DISPLAY_VALUE
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};
