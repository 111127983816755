import { OverflowMenu, OverflowMenuItem, Link } from 'carbon-components-react';
import style from './anchor-jump-menu.module.scss';
import { TableOfContents32 } from '@carbon/icons-react';
import { useEffect } from 'react';

const AnchorJumpMenu = () => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';

    return () => {
      document.documentElement.style.removeProperty('scroll-behavior');
    };
  }, []);

  return (
    <>
      <div className={style.subMenuList} data-floating-menu-container>
        <div className={style.desktopList}>
          <ul>
            <li>
              <Link href="#applicable-areas">Areas</Link>
            </li>
            <li>
              <Link href="#document-references">Doc. Refs</Link>
            </li>
            <li>
              <Link href="#transactions">Transactions</Link>
            </li>
            <li>
              <Link href="#capacities">Capacities</Link>
            </li>
            <li>
              <Link href="#curative-requirements">Curative</Link>
            </li>
            <li>
              <Link href="#additional-information">Add. Info</Link>
            </li>
          </ul>
        </div>
        <div className={style.mobileList}>
          <OverflowMenu
            renderIcon={TableOfContents32}
            size="lg"
            className={style.menuContent}
          >
            <OverflowMenuItem
              className={style.menuItem}
              itemText="Applicable Areas"
              href="#applicable-areas"
            />
            <OverflowMenuItem
              className={style.menuItem}
              itemText="Document References"
              href="#document-references"
            />
            <OverflowMenuItem
              className={style.menuItem}
              itemText="Transactions"
              href="#transactions"
            />
            <OverflowMenuItem
              className={style.menuItem}
              itemText="Capacities"
              href="#capacities"
            />
            <OverflowMenuItem
              className={style.menuItem}
              itemText="Curative Requirements"
              href="#curative-requirements"
            />
            <OverflowMenuItem
              className={style.menuItem}
              itemText="Additional Information"
              href="#additional-information"
            />
          </OverflowMenu>
        </div>
      </div>
    </>
  );
};

export { AnchorJumpMenu };
