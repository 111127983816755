import {
  Button,
  InlineLoading,
  InlineNotification,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { TitleWorkspaceHeader } from '../common/title-workspace-header';
import { TitleWorkspaceDocumentIndexPage } from '../document-index/page';
import { TitleWorkspaceOverviewPage } from '../overview';
import { TitleWorkspaceRunsheetIndexPage } from '../runsheet/page';
import { TitleWorkspaceWellsIndexPage } from '../well-index/page';
import { StartingInterestPage } from '../starting-interest/page';
import { resourcesAtom } from 'atoms/root';
import { Route } from 'components/route';
import { createResourceAtom } from 'lib/atoms';
import { TitleWorkspaceResponse } from 'types/api-responses';
import { resetCalculationCacheAtom } from '../overview/atoms';
import { RunsheetTimelinePage } from '../runsheet-timeline/page';
import { TitleWorkspaceEntitiesPage } from '../entities/page';

const TitleWorkspaceEntrypointPage = () => {
  const location = useLocation();
  const [resource, id] = location.pathname.split('/').filter((el) => el);
  const titleWorkspaceHref = `/${resource}/${id}`;
  const resources = useAtomValue(resourcesAtom);
  const { path } = useRouteMatch();
  const titleWorkspaceAtom = useMemo(
    () => createResourceAtom<TitleWorkspaceResponse>(titleWorkspaceHref),
    [titleWorkspaceHref]
  );
  const titleWorkspace = useAtomValue(titleWorkspaceAtom);
  const resetCaches = useSetAtom(resetCalculationCacheAtom);

  useEffect(() => {
    return () => resetCaches();
  }, [resetCaches]);

  if (titleWorkspace.data && 'error' in titleWorkspace.data)
    return (
      <InlineNotification
        kind="error"
        title={titleWorkspace.data.error}
        lowContrast
      />
    );
  if (titleWorkspace.loading || !titleWorkspace.data) return <InlineLoading />;

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <TitleWorkspaceHeader
          key={location.pathname}
          subtitle="Workspace"
          titleWorkspace={titleWorkspace.data}
        >
          <TitleWorkspaceOverviewPage titleWorkspaceHref={titleWorkspaceHref} />
        </TitleWorkspaceHeader>
      </Route>
      <Route exact path={`${path}/documents`}>
        <TitleWorkspaceHeader
          key={location.pathname}
          subtitle="Document Index"
          actions={
            <Button
              size="sm"
              renderIcon={Add16}
              onClick={() => {
                const href = resources?.documents.href;
                if (!href) return;
                window.open(
                  `${href}/new${`?referrer=${titleWorkspaceHref}/documents`}`,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              <span>Add New Document</span>
            </Button>
          }
          titleWorkspace={titleWorkspace.data}
        >
          <TitleWorkspaceDocumentIndexPage
            titleWorkspace={titleWorkspace.data}
          />
        </TitleWorkspaceHeader>
      </Route>
      <Route exact path={`${path}/wells`}>
        <TitleWorkspaceHeader
          key={location.pathname}
          subtitle="Wells Index"
          titleWorkspace={titleWorkspace.data}
        >
          <TitleWorkspaceWellsIndexPage titleWorkspace={titleWorkspace.data} />
        </TitleWorkspaceHeader>
      </Route>
      <Route exact path={`${path}/entities`}>
        <TitleWorkspaceHeader
          key={location.pathname}
          subtitle="Entities"
          titleWorkspace={titleWorkspace.data}
        >
          <TitleWorkspaceEntitiesPage titleWorkspace={titleWorkspace.data} />
        </TitleWorkspaceHeader>
      </Route>
      <Route exact path={`${path}/runsheet`}>
        <TitleWorkspaceHeader
          key={location.pathname}
          subtitle="Runsheet"
          titleWorkspace={titleWorkspace.data}
        >
          <TitleWorkspaceRunsheetIndexPage
            titleWorkspaceHref={titleWorkspaceHref}
          />
        </TitleWorkspaceHeader>
      </Route>
      <Route exact path={`${path}/starting_interest`}>
        <TitleWorkspaceHeader
          key={location.pathname}
          title="Workspace Settings"
          subtitle="Workspace Settings"
          titleWorkspace={titleWorkspace.data}
          hideTabs
        >
          <StartingInterestPage workspaceHref={titleWorkspace.data.href} />
        </TitleWorkspaceHeader>
      </Route>
      <Route exact path={`${path}/timeline`}>
        <RunsheetTimelinePage titleWorkspace={titleWorkspace.data} />
      </Route>
    </Switch>
  );
};

export { TitleWorkspaceEntrypointPage };
