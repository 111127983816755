import plur from 'plur';

const numberFormatter = new Intl.NumberFormat('en-US');

export const formatNumber = (num: number) => numberFormatter.format(num);

export const itemRangeText = (min: number, max: number, total: number) => {
  return `
      ${formatNumber(min)}–${formatNumber(max)}
      of ${formatNumber(total)} ${plur('item', total)}
    `;
};

export const pageRangeText = (_current: number, total: number) => {
  return `of ${formatNumber(total)} ${plur('page', total)}`;
};
