import {
  createResourceAtom,
  createResourceCacheMapAtom,
  createSingleCacheEntryAtom,
} from 'lib/atoms';
import { useMemo } from 'react';
import {
  PackageCashflowsResponse,
  PackageEvaluationAllocationResponse,
  PackageEvaluationAssetsResponse,
  PackageEvaluationOverviewCombinedResponse,
  PackageEvaluationOverviewResponse,
  PackageEvaluationResponse,
  PackageEvaluationUnitsResponse,
  PackageDiscountRatesResponse,
  PackageEvaluationValuationOverviewResponse,
  PackageSlotsPerUnitAllocationResponse,
  PackageEvaluationInventoryAssetsResponse,
  PackageEvaluationWellsResponse,
  PackageEvaluationDetailsDownloads,
} from 'types/packages-api/responses';

const evaluationPackageOverviewCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationOverviewResponse>();

const evaluationPackageOverviewDiscountRatesCacheAtom =
  createResourceCacheMapAtom<PackageDiscountRatesResponse>();

const evaluationPackageOverviewCombinedCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationOverviewCombinedResponse>();

const evaluationPackageValuationOverviewCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationValuationOverviewResponse>();

const evaluationAllocationsCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationAllocationResponse[]>();

const evaluationUnitsCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationUnitsResponse[]>();

const evaluationAssetsCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationAssetsResponse[]>();

const evaluationInventoryAssetsCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationInventoryAssetsResponse[]>();

const evaluationWellsCacheAtom =
  createResourceCacheMapAtom<PackageEvaluationWellsResponse>();

const evaluationsCashflowsCacheAtom =
  createResourceCacheMapAtom<PackageCashflowsResponse>();

const usePackageEvaluation = (href: string) =>
  useMemo(() => createResourceAtom<PackageEvaluationResponse>(href), [href]);

const useCreateEvaluationAllocationsAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(evaluationAllocationsCacheAtom)(href),
    [href]
  );

const useCreateEvaluationUnitsAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(evaluationUnitsCacheAtom)(href),
    [href]
  );

const useCreateEvaluationAssetsAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(evaluationAssetsCacheAtom)(href),
    [href]
  );

const useCreateEvaluationInventoryAssetsAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(evaluationInventoryAssetsCacheAtom)(href),
    [href]
  );

const useCreateEvaluationWellsAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(evaluationWellsCacheAtom)(href),
    [href]
  );

const useCreateEvaluationPackageOverviewAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(evaluationPackageOverviewCacheAtom)(href),
    [href]
  );

const useCreateEvaluationPackageOverviewCombinedAtom = (href: string) =>
  useMemo(
    () =>
      createSingleCacheEntryAtom(evaluationPackageOverviewCombinedCacheAtom)(
        href
      ),
    [href]
  );

const useCreateDiscountRatesAtom = (href: string) =>
  useMemo(
    () =>
      createSingleCacheEntryAtom<PackageDiscountRatesResponse>(
        evaluationPackageOverviewDiscountRatesCacheAtom
      )(href),
    [href]
  );

const useCreateEvaluationPackageValuationOverviewAtom = (href: string) =>
  useMemo(
    () =>
      createSingleCacheEntryAtom(evaluationPackageValuationOverviewCacheAtom)(
        href
      ),
    [href]
  );

const useCreateEvaluationPackageCashflowsAtom = (href: string) =>
  useMemo(
    () => createSingleCacheEntryAtom(evaluationsCashflowsCacheAtom)(href),
    [href]
  );

const useSlotsAtom = (href: string) => {
  return useMemo(
    () => createResourceAtom<PackageSlotsPerUnitAllocationResponse>(href),
    [href]
  );
};

const useEvaluationDownloadsAtom = (href: string) =>
  useMemo(
    () =>
      createResourceAtom<PackageEvaluationDetailsDownloads[]>(
        href,
        'keep-old-data-on-refetch'
      ),
    [href]
  );

export {
  useCreateDiscountRatesAtom,
  useCreateEvaluationAllocationsAtom,
  useCreateEvaluationAssetsAtom,
  useCreateEvaluationInventoryAssetsAtom,
  useCreateEvaluationPackageCashflowsAtom,
  useCreateEvaluationPackageOverviewAtom,
  useCreateEvaluationPackageOverviewCombinedAtom,
  useCreateEvaluationPackageValuationOverviewAtom,
  useCreateEvaluationUnitsAtom,
  useCreateEvaluationWellsAtom,
  useEvaluationDownloadsAtom,
  usePackageEvaluation,
  useSlotsAtom,
};
