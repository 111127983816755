import type { Response } from './types';

let CSRF_TOKEN = '';

export const HOST = import.meta.env.TEST_HOST ?? null;

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-TOKEN': '',
};

const setCSRF = (token: string) => {
  CSRF_TOKEN = token;
};

const unsetCSRF = () => {
  CSRF_TOKEN = '';
};

const getHeaders = (): Record<keyof typeof headers, string> => ({
  ...headers,
  'X-CSRF-TOKEN': CSRF_TOKEN,
});

const runFetch = async <OkApiResponse, FailApiResponse>(
  url: string,
  payload: unknown,
  method: 'POST' | 'PATCH' | 'DELETE',
  abortController: AbortController,
  onNoAuth: () => void
): Promise<Response<OkApiResponse, FailApiResponse>> => {
  try {
    const reqUrl = HOST ? `${HOST}${url}` : url;
    const response = await fetch(reqUrl, {
      method,
      headers: getHeaders(),
      body: JSON.stringify(payload), // body data type must match "Content-Type" header
      signal: abortController.signal,
    });

    if (response.ok) {
      return {
        type: 'success',
        data: method === 'POST' ? await response.json() : null,
      };
    }

    if (response.status === 401) {
      onNoAuth();
    }

    if (response.status >= 500) {
      return { type: 'fatal', message: 'Internal Server Error' };
    }

    try {
      return {
        type: 'error',
        data: await response.json(),
      };
    } catch (_e) {
      return { type: 'fatal', message: response.statusText };
    }
  } catch (e) {
    if (e instanceof Error) {
      if (e.name === 'AbortError') {
        return { type: 'abort', message: 'Request aborted' };
      }
    }
    return { type: 'fatal', message: 'Failed to fetch resource' };
  }
};

const post = async <OkApiResponse, FailApiResponse>(
  url: string,
  payload: unknown,
  abortController: AbortController,
  onNoAuth?: () => void
): Promise<Response<OkApiResponse, FailApiResponse>> => {
  return await runFetch(
    url,
    payload,
    'POST',
    abortController,
    onNoAuth ? onNoAuth : () => location.reload()
  );
};

const patch = async <OkApiResponse, FailApiResponse>(
  url: string,
  payload: unknown,
  abortController: AbortController,
  onNoAuth?: () => void
): Promise<Response<OkApiResponse, FailApiResponse>> => {
  return await runFetch(
    url,
    payload,
    'PATCH',
    abortController,
    onNoAuth ? onNoAuth : () => location.reload()
  );
};

const deleteReq = async <OkApiResponse, FailApiResponse>(
  url: string,
  payload: unknown,
  abortController: AbortController,
  onNoAuth?: () => void
): Promise<Response<OkApiResponse, FailApiResponse>> => {
  return await runFetch(
    url,
    payload,
    'DELETE',
    abortController,
    onNoAuth ? onNoAuth : () => location.reload()
  );
};

const get = async <ApiResponse = unknown>(
  url: string,
  abortController?: AbortController
): Promise<ApiResponse | { error: string }> => {
  try {
    const reqUrl = HOST ? `${HOST}${url}` : url;
    const response = await fetch(reqUrl, {
      method: 'GET',
      headers: getHeaders(),
      signal: abortController?.signal,
    });

    if (response.ok) {
      return await response.json();
    }

    if (response.status >= 500) {
      return { error: 'Internal Server Error' };
    }
    return { error: 'Unknown' };
  } catch (e) {
    if (e instanceof Error && e.name === 'AbortError') {
      return { error: e.name };
    }
    return { error: 'Failed to fetch resource' };
  }
};

export { deleteReq, get, patch, post, setCSRF, unsetCSRF };
