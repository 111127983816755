import { HasCompletionType, ProvisionFilterType } from './types';

export const TOP_RUNSHEET_SECTIONS_HEIGHT_IN_PX = 235;

export const provisionFilters: ProvisionFilterType[] = [
  { id: -1, text: 'All Documents' },
  { id: 1, text: 'Provisioned Documents', hasCompletion: 'all' },
  {
    id: 2,
    text: 'Provisioned Docs w/ Comments',
    hasCompletion: 'with_comments',
  },
  { id: 0, text: 'Non-Provisioned Documents', hasCompletion: 'none' },
];

export const provisionTypes: HasCompletionType[] = [
  'all',
  'none',
  'with_comments',
];
