import { StatCard } from 'components/stat-card';
import { useAtomValue } from 'jotai';
import { searchAtom } from '../atoms';
import { Charts } from 'components/charts';
import style from './interpretation-stats.module.scss';
import { CHART_TYPES, ChartTypes } from './types';
import { Dropdown } from 'carbon-components-react';
import { useState } from 'react';

const InterpretationStats = () => {
  const searchResult = useAtomValue(searchAtom);
  const chartValues = Object.values(CHART_TYPES);
  const [selectedChart, setSelectedChart] = useState<ChartTypes>(
    CHART_TYPES.bookType
  );
  const {
    totalTransactions,
    totalLeaseTypes,
    totalBookTypes,
    totalDocumentTypes,
    totalReviewTypes,
    totalCurativeTypes,
  } = searchResult?.data?.aggregations || {};
  return (
    <section className={style.sectionContainer}>
      <div className={style.statsContainer}>
        <StatCard
          title="Total Interpretations"
          value={searchResult?.data?.totalCount.toLocaleString()}
        />
        <StatCard
          title="Total Transactions"
          value={totalTransactions ? totalTransactions.toLocaleString() : '0'}
        />
        <StatCard
          title="Total Leases"
          value={
            totalLeaseTypes?.['mineral lease transaction']
              ? totalLeaseTypes['mineral lease transaction'].toLocaleString()
              : '0'
          }
        />
      </div>
      <div className={style.graphContainer}>
        <div className={style.dropDownContainer}>
          <Dropdown
            label="Charts"
            titleText="Charts"
            onChange={({ selectedItem }: { selectedItem: ChartTypes }) => {
              selectedItem && setSelectedChart(selectedItem);
            }}
            itemToString={(item: string) => item}
            light
            id="document-type"
            items={chartValues}
            type="default"
            selectedItem={selectedChart}
          />
        </div>
        {selectedChart === CHART_TYPES.bookType && (
          <Charts
            data={totalBookTypes}
            titleText={'Book Type'}
            xAxisText={'Book Type'}
            yAxisText={'Total'}
            seriesName={'Total'}
            dataLabelsEnabled={false}
          />
        )}
        {selectedChart === CHART_TYPES.documentType && (
          <Charts
            data={totalDocumentTypes}
            titleText={'Document Type'}
            xAxisText={'Document Type'}
            yAxisText={'Total'}
            seriesName={'Total'}
            dataLabelsEnabled={false}
          />
        )}
        {selectedChart === CHART_TYPES.reviewed && (
          <Charts
            data={totalReviewTypes}
            xAxisText={'Review Type'}
            yAxisText={'Total'}
            titleText={'Review Type'}
            seriesName={'Total'}
            dataLabelsEnabled={false}
          />
        )}
        {selectedChart === CHART_TYPES.curative && (
          <Charts
            data={totalCurativeTypes}
            xAxisText={'Curative Type'}
            yAxisText={'Total'}
            titleText={'Curative Type'}
            seriesName={'Total'}
            dataLabelsEnabled={false}
          />
        )}
      </div>
    </section>
  );
};

export { InterpretationStats };
