import { createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';

export type DocumentAtom = ReturnType<typeof useDocumentAtom>;

const useDocumentAtom = (href: string) => {
  return useMemo(
    () =>
      createResourceAtom<DocumentResponse>(href, 'keep-old-data-on-refetch'),
    [href]
  );
};

const useInterpretationsAtom = (href?: string) => {
  return useMemo(
    () => createResourceAtom<DocumentInterpretationResponse[]>(href),
    [href]
  );
};

const showProvisionedDocumentAtom = atomWithStorage(
  'show-interpretation-provisioned-document',
  false
);

const documentProvisionStore = createStore();

export {
  documentProvisionStore,
  showProvisionedDocumentAtom,
  useDocumentAtom,
  useInterpretationsAtom,
};
