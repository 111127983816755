import { memo, useEffect, useMemo, useRef } from 'react';
import { Column, ComboBox, Grid, Row, TextArea } from 'carbon-components-react';
import { useAtom, useAtomValue } from 'jotai';

import { FormRow, FormSection } from 'components/forms';

import type {
  Entity,
  GrantorForm as GrantorFormType,
  TextEntityAtoms,
} from './types';

import { DeleteRowButton } from '../delete-row-button';
import { FormRowManager } from '../form-row-manager';
import { initEmptyForm } from './utils';
import { grantorAtoms, granteeAtoms } from './atoms';
import { getError } from 'atoms/create-resource-atom';
import { ReviewSectionCheckbox } from '../review-section-checkbox';
import { createUpdateTrackerAtom } from '../atoms';
import { HeaderCheckMark } from '../review-section-checkbox/header-checkmark';
import style from '../interpretation-form.module.scss';
import { shouldSkipSearch } from 'utils/forms';

const entityList = (
  entities: { name: string; entityId: number }[],
  name?: string
) => {
  const trimmedName = name?.trim();

  if (!trimmedName) {
    return entities;
  }

  const filtered = entities.filter(
    (item) =>
      trimmedName && item.name.toLowerCase().includes(trimmedName.toLowerCase())
  );

  return !filtered.length
    ? [...filtered, { name, entityId: undefined }]
    : filtered;
};

interface CommonProps {
  atoms: TextEntityAtoms;
  entities: Entity[];
  usedEntities: Set<number>;
  entityMap: Map<number, Entity>;
  atomIdx: number;
  onDelete?: (idx: number) => void;
  type: 'grantor' | 'grantee';
}
const CommonForm = ({
  entities,
  atomIdx,
  usedEntities,
  entityMap,
  atoms,
  type,
  onDelete,
}: CommonProps) => {
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const status = useAtomValue(atoms.status);

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <FormRow toTrash={status === 'trash'}>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column sm={3} md={3} lg={6}>
            <ComboBox
              id={`${atomIdx}-${type}-entity`}
              titleText={type === 'grantor' ? 'Grantor' : 'Grantee'}
              items={entityList(
                entities.filter((el) => !usedEntities.has(el.entityId)),
                fields.entity.value?.name
              )}
              placeholder={
                type === 'grantor' ? 'Enter Grantor' : 'Enter Grantee'
              }
              itemToString={(data) => data?.name ?? ''}
              itemToElement={(item: { entityId?: number; name: string }) => {
                if (item.entityId) {
                  return <>{item.name}</>;
                }
                return <>Add New &ldquo;{item.name.trim()}&rdquo;</>;
              }}
              selectedItem={
                fields.entity.value?.entityId &&
                entityMap.get(fields.entity.value.entityId)
                  ? entityMap.get(fields.entity.value.entityId)
                  : null
              }
              onChange={({ selectedItem }) => {
                if (selectedItem?.name) {
                  setField({
                    field: 'entity',
                    value: { name: selectedItem?.name, entityId: undefined },
                  });
                }
                if (selectedItem?.entityId) {
                  setField({
                    field: 'entity',
                    value: { entityId: selectedItem?.entityId },
                  });
                }
              }}
              onInputChange={(value) => {
                if (shouldSkipSearch(firstRender.current, status)) return;
                setField((form) => ({
                  field: 'entity',
                  value: { ...form.entity.value, name: value },
                }));
              }}
              invalid={!!getError<GrantorFormType>(fields, 'entity')}
              invalidText={getError<GrantorFormType>(fields, 'entity')}
              disabled={status === 'trash'}
              light
            />
          </Column>
          <Column sm={2} md={3} lg={5}>
            <TextArea
              labelText="Additional Info"
              placeholder="Text Field"
              rows={1}
              light
              id={`${type}-reference-notes-${atomIdx}`}
              value={fields.note.value || ''}
              onChange={({ target: { value } }) =>
                setField({ field: 'note', value })
              }
              disabled={status === 'trash'}
            />
          </Column>
          <Column sm={1} md={2} lg={5}>
            <DeleteRowButton
              statusAtom={atoms.status}
              atomIdx={atomIdx}
              onDelete={onDelete}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

interface FormProps {
  atomIdx: number;
  atoms: TextEntityAtoms;
  onDelete?: (idx: number) => void;
}
const GrantorForm = ({ atomIdx, onDelete, atoms }: FormProps) => {
  const grantorsMap = useAtomValue(grantorAtoms.entityMapAtom);
  const grantors = useAtomValue(grantorAtoms.entitiesAtom);
  const usedGrantors = useAtomValue(grantorAtoms.usedEntitiesAtom);

  return (
    <CommonForm
      atoms={atoms}
      onDelete={onDelete}
      atomIdx={atomIdx}
      entities={grantors}
      usedEntities={usedGrantors}
      entityMap={grantorsMap}
      type={'grantor'}
    />
  );
};

const GrantorMemo = memo(GrantorForm);

const GranteeForm = ({ atomIdx, onDelete, atoms }: FormProps) => {
  const grantorsMap = useAtomValue(granteeAtoms.entityMapAtom);
  const grantors = useAtomValue(granteeAtoms.entitiesAtom);
  const usedGrantors = useAtomValue(granteeAtoms.usedEntitiesAtom);

  return (
    <CommonForm
      atoms={atoms}
      onDelete={onDelete}
      atomIdx={atomIdx}
      entities={grantors}
      usedEntities={usedGrantors}
      entityMap={grantorsMap}
      type={'grantee'}
    />
  );
};

const GranteeMemo = memo(GranteeForm);

type ManagedFormTypeGrantor = {
  field: 'grantors';
  value: TextEntityAtoms[];
};
const GrantorList = () => {
  return (
    <FormRowManager<ManagedFormTypeGrantor>
      MemoedComponent={GrantorMemo}
      field={'grantors'}
      label="Add Grantor"
      initEmptyForm={initEmptyForm}
    />
  );
};

type ManagedFormTypeGrantee = {
  field: 'grantees';
  value: TextEntityAtoms[];
};
const GranteeList = () => {
  return (
    <FormRowManager<ManagedFormTypeGrantee>
      MemoedComponent={GranteeMemo}
      field={'grantees'}
      label="Add Grantee"
      initEmptyForm={initEmptyForm}
    />
  );
};

const GrantorsSection = () => {
  const grantorsUpdateAtom = useMemo(
    () => createUpdateTrackerAtom('grantors'),
    []
  );
  return (
    <FormSection
      title="Grantor(s)"
      className={style.positionRelative}
      headerElements={<HeaderCheckMark field="grantorsReviewed" />}
    >
      <GrantorList />
      <div className={style.alignReviewContainer}>
        <ReviewSectionCheckbox
          updateTrackingAtom={grantorsUpdateAtom}
          field={'grantorsReviewed'}
          label="Grantors Reviewed"
          helpMessage="Editing any grantor(s) will change the review status to unreviewed"
        />
      </div>
    </FormSection>
  );
};

const GranteesSection = () => {
  const granteesUpdateAtom = useMemo(
    () => createUpdateTrackerAtom('grantees'),
    []
  );
  return (
    <FormSection
      title="Grantee(s)"
      className={style.positionRelative}
      headerElements={<HeaderCheckMark field="granteesReviewed" />}
    >
      <GranteeList />
      <div className={style.alignReviewContainer}>
        <ReviewSectionCheckbox
          updateTrackingAtom={granteesUpdateAtom}
          field={'granteesReviewed'}
          label="Grantees Reviewed"
          helpMessage="Editing any grantees(s) will change the review status to unreviewed"
        />
      </div>
    </FormSection>
  );
};

const TextEntities = () => {
  return (
    <>
      <GrantorsSection />
      <GranteesSection />
    </>
  );
};

export { TextEntities };
