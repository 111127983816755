import {
  Button,
  ComboBox,
  InlineNotification,
  Tooltip,
} from 'carbon-components-react';
import { Search16, WarningFilled16 } from '@carbon/icons-react';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  ownerDetailAtom,
  ownersAtom,
  selectedOwnerIndexAtom,
  selectedOwnerAtom,
} from './atoms';
import { OutputSkeleton } from '../../common/output_skeleton';
import { OutputListSkeleton } from '../../common/output_list_skeleton';
import style from '../calculation.module.scss';
import { Owner } from './owner';
import { CalculationEntity, TitleWorkspaceResponse } from 'types/api-responses';
import { ForwardRefReturn } from 'carbon-components-react/typings/shared';

const OwnerList = () => {
  const owners = useAtomValue(ownersAtom);
  const selectOwner = useSetAtom(selectedOwnerAtom);
  if (owners.loading) {
    return <OutputListSkeleton />;
  }
  return (
    <>
      <div className={style.searchField}>
        <ComboBox
          id={'owners-list'}
          titleText={''}
          items={owners.results}
          placeholder="Search a name..."
          itemToString={(data) => {
            return data ? data.entity.name : '';
          }}
          size="lg"
          selectedItem={undefined}
          onChange={({ selectedItem }) => {
            const index = owners.results.findIndex(
              (el) => el.entity.id === selectedItem?.entity.id
            );
            if (index >= 0) {
              selectOwner(index);
            }
          }}
        />
        <Search16 className={style.searchIcon} />
        <div className={style.assetSection}>Owners</div>
        <ul className={style.subTractList}>
          {owners.results.map((owner, index: number) => (
            <li key={index}>
              <OwnerButton owner={owner} idx={index} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const OwnerButton = ({
  owner,
  idx,
}: {
  owner: CalculationEntity;
  idx: number;
}) => {
  const selectedIndex = useAtomValue(selectedOwnerIndexAtom);
  const selectOwner = useSetAtom(selectedOwnerAtom);
  const owners = useAtomValue(ownersAtom);
  const isSelected =
    owners.results[selectedIndex].entity.id === owner.entity.id;

  return (
    <div className={style.ownerButton}>
      <Button
        kind="ghost"
        size="sm"
        onClick={() => {
          selectOwner(idx);
        }}
        className={isSelected ? style.btnIsSelected : null}
      >
        {owner.entity.name}
      </Button>
      {owner.entity.deleted && (
        <Tooltip
          direction="top"
          align="start"
          className={style.btnToolTip}
          renderIcon={WarningFilled16 as ForwardRefReturn<unknown, unknown>}
          iconDescription="Warning"
          iconName="warning-icon"
        >
          <div className={style.toolTipContent}>
            <h1>Entity Deleted</h1>
            <p>
              The title workspace must be recalculated to reflect entity
              alterations
            </p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

const SelectedOwner = ({
  titleWorkspace,
}: {
  titleWorkspace: TitleWorkspaceResponse;
}) => {
  const ownerDetails = useAtomValue(ownerDetailAtom);

  if (ownerDetails?.loading) return <OutputSkeleton />;
  if (!ownerDetails) return null;

  if (ownerDetails.data && 'error' in ownerDetails.data)
    return (
      <InlineNotification
        kind="error"
        title={ownerDetails.data.error}
        lowContrast
      />
    );
  if (!ownerDetails.data) return null;

  return (
    <Owner ownerDetails={ownerDetails.data} titleWorkspace={titleWorkspace} />
  );
};

export { OwnerList, SelectedOwner };
