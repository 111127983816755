import {
  Column,
  Grid,
  Row,
  Tile,
  Link,
  Button,
  Form,
  TextInput,
  InlineNotification,
} from 'carbon-components-react';

import style from './login.module.scss';
import { useReducer, useRef, useState } from 'react';
import { MicrosoftLogo } from 'components/icons';
import { useAtom } from 'jotai';
import { basicAuthAtom, oauthAtom } from './atoms';

const Basic = () => {
  const [login, setLogin] = useAtom(basicAuthAtom);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        setLogin({ email, password });
      }}
    >
      <TextInput
        light
        id="login-email"
        className={style.row}
        type="email"
        labelText="Email"
        placeholder="email"
        autoFocus={true}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextInput
        light
        id="login-password"
        className={style.row}
        type="password"
        labelText="Password"
        placeholder="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button color="primary" type="submit" disabled={login.loading}>
        Log in
      </Button>
      {login.error && (
        <InlineNotification
          lowContrast
          kind="error"
          title="Error!"
          subtitle={login.error}
        />
      )}
    </Form>
  );
};

const Oauth = ({ csrfToken }: { csrfToken: string }) => {
  const setOauthForm = useRef<HTMLFormElement>(null);
  const [loading, login] = useAtom(oauthAtom);
  return (
    <div className={style.oauth}>
      <Button
        kind="tertiary"
        disabled={loading}
        onClick={() => {
          if (!setOauthForm.current) return;
          login(setOauthForm.current);
        }}
        renderIcon={MicrosoftLogo}
      >
        Log in with Azure
      </Button>
      <form method="POST" ref={setOauthForm}>
        <input type="hidden" name="authenticity_token" value={csrfToken} />
      </form>
      <hr />
      <div>
        <span>Need help?</span>
        <p>
          <Link href="https://slack.com/app_redirect?channel=C02UVPQKXDL">
            Contact us in the #title-chaining-tool channel in Slack
          </Link>
        </p>
      </div>
    </div>
  );
};

const LoginPage = ({ csrfToken }: { csrfToken: string }) => {
  const [useBasic, toggleLogin] = useReducer((s) => !s, false);
  return (
    <Grid className="bx--no-gutter" fullWidth>
      <Row>
        <Column
          md={6}
          lg={8}
          xlg={6}
          className="bx--offset-md-1 bx--offset-lg-4 bx--offset-xlg-5"
        >
          <Tile>
            <h2 className={style.header}>Log in</h2>
            <h4 className={style.subheader}>Start discovering insights</h4>
            {useBasic ? <Basic /> : <Oauth csrfToken={csrfToken} />}
          </Tile>
          <div className={style.toggle}>
            <Link href="#" onClick={() => toggleLogin()}>
              {useBasic ? 'Use Azure SSO' : 'Use basic form'}
            </Link>
          </div>
        </Column>
      </Row>
    </Grid>
  );
};

export { LoginPage };
