import { useMemo } from 'react';
import { ScenariosForm, TierAssumptionsForm } from './types';
import { AtomField, AtomizedForm, required } from 'atoms/form-atoms';
import { atom, useSetAtom } from 'jotai';
import { EvaluationDateType } from 'types/scenarios-api/responses';

type LTDKind =
  ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']['kind'];

type NTDKind =
  ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ducAttributes']['kind'];

type WaterForecastSource =
  ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']['leaseholdAttributes']['waterForecastSource'];

const ltdRequired = <V extends string | number>(
  ltdKind: LTDKind,
  ltdKindSet: ReadonlyArray<LTDKind>
) => {
  if (ltdKindSet.includes(ltdKind)) {
    return { validate: required };
  } else {
    return { validate: (v: V) => v };
  }
};

const ntdRequiredField = <V extends string | number>(
  kind: NTDKind,
  kindSet: ReadonlyArray<NTDKind>
) => {
  if (kindSet.includes(kind)) {
    return { validate: required };
  } else {
    return { validate: (v: V) => v };
  }
};

const waterSourceRequiredField = <V extends number | null>(
  waterSource: WaterForecastSource,
  waterSourceRequiredFor: WaterForecastSource
) => {
  if (waterSource === waterSourceRequiredFor) {
    return { validate: required };
  } else {
    return { validate: (v: V) => v };
  }
};

const useValidateLTDFieldsAtom = (
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ltdAttributes']
  >
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const kind = get(form.kind).value;
        set(form.asOfDate, ltdRequired(kind, ['target_as_of']));
        set(
          form.firstDrillingStartDate,
          ltdRequired(kind, ['fractional_wells'])
        );
        set(form.drillingIntervalMonths, ltdRequired(kind, ['per_dev_area']));
        set(form.drillStartMonths, ltdRequired(kind, ['fractional_wells']));
        set(form.drillOffsetMonths, ltdRequired(kind, ['fractional_wells']));
        set(
          form.averageWellDrilledYears,
          ltdRequired(kind, [
            'target_as_of',
            'target_most_recent',
            'fractional_wells',
          ])
        );
      }
    );
  }, [
    form.asOfDate,
    form.averageWellDrilledYears,
    form.drillOffsetMonths,
    form.drillStartMonths,
    form.drillingIntervalMonths,
    form.firstDrillingStartDate,
    form.kind,
  ]);

  return useSetAtom(validateAtom);
};

const useValidateCashflowFieldAtom = (
  form: AtomizedForm<
    ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
  >
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const field = form.priceAttributes.stripPriceDate;
        const kind = get(form.priceAttributes.kind).value;

        if (['modified_strip_as_of', 'strip_as_of'].includes(kind)) {
          set(field, { validate: required });
        } else {
          set(field, { validate: (v) => v });
        }

        const flatFields = [
          form.priceAttributes.oilFlatPrice,
          form.priceAttributes.gasFlatPrice,
        ];

        flatFields.map((el) => {
          if (
            [
              'modified_strip_as_of',
              'modified_strip_most_recent',
              'flat',
            ].includes(kind)
          ) {
            set(el, { validate: required });
          } else {
            set(el, { validate: (v) => v });
          }
        });

        const modifiedStripFields = [
          form.priceAttributes.modifiedStripGasScalingPercentage,
          form.priceAttributes.modifiedStripOilScalingPercentage,
          form.priceAttributes.oilModifiedStripCutoffMonths,
          form.priceAttributes.gasModifiedStripCutoffMonths,
        ];

        modifiedStripFields.map((el) => {
          if (
            ['modified_strip_as_of', 'modified_strip_most_recent'].includes(
              kind
            )
          ) {
            set(el, { validate: required });
          } else {
            set(el, { validate: (v) => v });
          }
        });
      }
    );
  }, [
    form.priceAttributes.gasFlatPrice,
    form.priceAttributes.kind,
    form.priceAttributes.oilModifiedStripCutoffMonths,
    form.priceAttributes.gasModifiedStripCutoffMonths,
    form.priceAttributes.modifiedStripGasScalingPercentage,
    form.priceAttributes.modifiedStripOilScalingPercentage,
    form.priceAttributes.oilFlatPrice,
    form.priceAttributes.stripPriceDate,
  ]);

  return useSetAtom(validateAtom);
};

const useValidateForecastSourceFieldsAtom = (
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']['leaseholdAttributes']
      >
    | AtomizedForm<
        TierAssumptionsForm[0]['cashflowAssumptionAttributes']['leaseholdAttributes']
      >
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const source = get(form.waterForecastSource).value;
        if (!source) return;

        set(form.waterCutPercentage, waterSourceRequiredField(source, 'oil'));
        set(
          form.waterGasRatioInBblPerMmcf,
          waterSourceRequiredField(source, 'gas')
        );
      }
    );
  }, [
    form.waterCutPercentage,
    form.waterForecastSource,
    form.waterGasRatioInBblPerMmcf,
  ]);

  return useSetAtom(validateAtom);
};

const useValidateNTDFieldsAtom = (
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['ducAttributes']
      >
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['drillingAssumptionAttributes']['permitAttributes']
      >
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const kind = get(form.kind).value;
        if ('permitToProductionMonths' in form) {
          set(
            form.permitToProductionMonths,
            ntdRequiredField(kind, ['discrete'])
          );
        }
        const { startMonth, endMonth } = form;
        set(startMonth, ntdRequiredField(kind, ['fractional_wells']));
        set(endMonth, ntdRequiredField(kind, ['fractional_wells']));
      }
    );
  }, [form]);

  return useSetAtom(validateAtom);
};

const useValidateDrillingStartDateAtom = (
  dateType: AtomField<EvaluationDateType>,
  date: AtomField<string>
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const field = date;
        const kind = get(dateType).value;

        if (kind === 'fixed') {
          set(field, { validate: required });
        } else {
          set(field, { validate: (v) => v });
        }
      }
    );
  }, [date, dateType]);

  return useSetAtom(validateAtom);
};

const useValidateEconomicLimitsAtom = (
  applyLimit: AtomField<boolean>,
  limit: AtomField<number>
) => {
  const validateAtom = useMemo(() => {
    return atom(
      () => null,
      (get, set) => {
        const applyLimitValue = get(applyLimit).value;

        if (applyLimitValue) {
          set(limit, { validate: required });
        } else {
          set(limit, { validate: (v) => v });
        }
      }
    );
  }, [applyLimit, limit]);

  return useSetAtom(validateAtom);
};

export {
  useValidateCashflowFieldAtom,
  useValidateDrillingStartDateAtom,
  useValidateForecastSourceFieldsAtom,
  useValidateLTDFieldsAtom,
  useValidateNTDFieldsAtom,
  useValidateEconomicLimitsAtom,
};
