import {
  InlineLoading,
  Tabs,
  Tab,
  Button,
  InlineNotification,
  Grid,
  Row,
  Column,
} from 'carbon-components-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  evaluationFormAtom,
  saveEvaluationAtom,
  saveEvaluationConfigAtom,
  EvaluationConfigAtomValue,
  dirtyEvaluationAtom,
  showDraftAtom,
} from './atoms';
import {
  EvalDefaultAssumptions,
  DevelopmentAreaEvaluationResponse,
  EvalSlotResponse,
} from 'types/evaluation-api/responses';
import style from './evaluation-form.module.scss';
import { SummarySkeleton } from '../summary-skeleton';
import { VolumesByMonth } from '../volumes-chart';
import { useLocation, useHistory } from 'react-router-dom';
import { PricingSummary, Summary } from '../evaluations';
import { DrillingAssumptions } from './forms/drilling-assumptions';
import { CashflowAssumptions } from './forms/cashflow-assumptions';
import { DiscountRateAssumptions } from './forms/discount-rate-assumptions';
import { Slots } from './forms/slots';
import { CashflowOutputSwitch, Sensitivities } from 'components/sensitivities';

interface FormContentProps {
  evaluationUrl: string;
  abortController: AbortController;
  evaluationsHref: string;
  getEvaluations: () => Promise<void>;
  fetchDefaultAssumptions: () => void;
  slots: EvalSlotResponse[];
}

const Volumes = ({
  volumes,
}: {
  volumes?: DevelopmentAreaEvaluationResponse['volumesByMonth'];
}) => {
  return (
    <Grid className="bx--no-gutter" fullWidth>
      <Row>
        <Column>
          <VolumesByMonth volumes={volumes} />
        </Column>
      </Row>
    </Grid>
  );
};

const DraftSummary = ({
  evaluationConfig,
}: {
  evaluationConfig: EvaluationConfigAtomValue;
}) => {
  return evaluationConfig.loading ? (
    <SummarySkeleton />
  ) : !evaluationConfig.error ? (
    <Summary data={evaluationConfig.evaluation?.response.summary} />
  ) : null;
};

const DraftVolumes = ({
  evaluationConfig,
}: {
  evaluationConfig: EvaluationConfigAtomValue;
}) => {
  return evaluationConfig?.loading ? (
    <InlineLoading />
  ) : (
    <Volumes volumes={evaluationConfig.evaluation?.volumesByMonth} />
  );
};

const DraftSensitivities = ({
  evaluationConfig,
}: {
  evaluationConfig: EvaluationConfigAtomValue;
}) => {
  return evaluationConfig?.loading ? (
    <InlineLoading />
  ) : (
    <DraftSensitivitiesContent evaluation={evaluationConfig.evaluation} />
  );
};

const DraftPricing = ({
  evaluationConfig,
}: {
  evaluationConfig: EvaluationConfigAtomValue;
}) => {
  return evaluationConfig?.loading ? (
    <InlineLoading />
  ) : (
    <DraftPricingContent evaluation={evaluationConfig.evaluation} />
  );
};

const DraftSlots = ({
  evaluationConfig,
}: {
  evaluationConfig: EvaluationConfigAtomValue;
}) => {
  return evaluationConfig?.loading ? (
    <InlineLoading />
  ) : (
    <Slots
      excludedSlots={
        evaluationConfig?.evaluation?.drillingAssumption.wellsToExclude
      }
      slotList={evaluationConfig?.evaluation?.response.slotSchedule}
      evalSlots={evaluationConfig?.evaluation?.slots}
    />
  );
};

const DraftSensitivitiesContent = ({
  evaluation,
}: {
  evaluation: DevelopmentAreaEvaluationResponse | undefined;
}) => {
  const [output, setOutput] = useState<'royalty' | 'working_interest'>(
    'royalty'
  );
  const cashflows = evaluation?.response.cashflows;

  return (
    <>
      <CashflowOutputSwitch output={output} setOutput={setOutput} />
      <Sensitivities
        cashflows={
          output === 'royalty' ? cashflows?.royalty : cashflows?.leasehold
        }
      />
    </>
  );
};

const DraftPricingContent = ({
  evaluation,
}: {
  evaluation: DevelopmentAreaEvaluationResponse | undefined;
}) => {
  const [output, setOutput] = useState<'royalty' | 'working_interest'>(
    'royalty'
  );
  const cashflows = evaluation?.response.cashflows;
  const pricingCases = evaluation?.response.pricingCases;

  return (
    <>
      <CashflowOutputSwitch output={output} setOutput={setOutput} />
      <PricingSummary
        commissionAdjustment={
          evaluation ? evaluation.cashflowAssumption.commissionAdjustment : null
        }
        moicLimitedPrices={
          output !== 'royalty'
            ? undefined
            : cashflows?.royalty.moicLimitedPrices
        }
        irrLimitedPrices={
          output !== 'royalty'
            ? cashflows?.leasehold.irrLimitedPrices
            : cashflows?.royalty.irrLimitedPrices
        }
        output={output}
        pricingCases={
          output === 'royalty'
            ? pricingCases?.royaltyPricingCases
            : pricingCases?.leaseholdPricingCases
        }
      />
    </>
  );
};

const FormContent = ({
  evaluationUrl,
  fetchDefaultAssumptions,
  abortController,
  evaluationsHref,
  getEvaluations,
  slots,
}: FormContentProps) => {
  const [evaluationConfigSaving, saveEvaluationConfig] = useAtom(
    saveEvaluationConfigAtom
  );
  const evaluationConfig = useAtomValue(saveEvaluationConfigAtom);
  const evaluationSaving = useAtomValue(saveEvaluationAtom);
  const evaluationForm = useAtomValue(evaluationFormAtom);
  const location = useLocation();

  useEffect(() => {
    if (!evaluationForm) return;
    saveEvaluationConfig({
      evaluationUrl,
      evaluationForm: evaluationForm,
      abortController,
      isLoadingStoredEvaluation: location.pathname.includes('evaluation'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const evaluationCompleted =
    !evaluationConfigSaving.loading && evaluationConfigSaving.evaluation;

  if (!evaluationForm) return null;

  return (
    <div className={style.evaluationForm}>
      <h2 className={style.tabsTitle}>Evaluation Draft</h2>
      <DraftSummary evaluationConfig={evaluationConfig} />
      {!evaluationConfigSaving.loading && evaluationConfigSaving.error && (
        <InlineNotification
          kind="error"
          title="Error"
          lowContrast
          subtitle={<p>{evaluationConfigSaving.error}</p>}
        />
      )}
      <Tabs className={style.tabsContained}>
        <Tab label="Slots" className={style.tabInput}>
          <Slots
            form={evaluationForm.drillingAssumptionAttributes}
            devAreaSlots={slots}
          />
        </Tab>
        <Tab label="Drilling Assumptions" className={style.tabInput}>
          <DrillingAssumptions
            form={evaluationForm.drillingAssumptionAttributes}
          />
        </Tab>
        <Tab label="Cashflow Assumptions" className={style.tabInput}>
          <CashflowAssumptions
            form={evaluationForm.cashflowAssumptionAttributes}
          />
        </Tab>
        <Tab label="Discount Rates Assumptions" className={style.tabInput}>
          <DiscountRateAssumptions
            form={evaluationForm.cashflowAssumptionAttributes}
          />
        </Tab>
        {evaluationConfig?.loading ? (
          <Tab label="Loading..." className={style.tabOutput}>
            <InlineLoading description="Loading..." />
          </Tab>
        ) : !evaluationConfig.error ? (
          <Tab label="Volumes" className={style.tabOutput}>
            <DraftVolumes evaluationConfig={evaluationConfig} />
          </Tab>
        ) : null}
        {evaluationConfig?.loading ? (
          <Tab label="Loading..." className={style.tabOutput}>
            <InlineLoading description="Loading..." />
          </Tab>
        ) : !evaluationConfig.error ? (
          <Tab label="Sensitivities" className={style.tabOutput}>
            <DraftSensitivities evaluationConfig={evaluationConfig} />
          </Tab>
        ) : null}
        {evaluationConfig?.loading ? (
          <Tab label="Loading..." className={style.tabOutput}>
            <InlineLoading description="Loading..." />
          </Tab>
        ) : !evaluationConfig.error ? (
          <Tab label="Pricing" className={style.tabOutput}>
            <DraftPricing evaluationConfig={evaluationConfig} />
          </Tab>
        ) : null}
        {evaluationConfig?.loading ? (
          <Tab label="Loading..." className={style.tabOutput}>
            <InlineLoading description="Loading..." />
          </Tab>
        ) : !evaluationConfig.error ? (
          <Tab label="Slots" className={style.tabOutput}>
            <DraftSlots evaluationConfig={evaluationConfig} />
          </Tab>
        ) : null}
      </Tabs>

      <div className={style.btnsTile}>
        <Button
          kind="primary"
          size="md"
          className={style.btnReset}
          disabled={
            evaluationConfigSaving.loading ||
            evaluationConfig.loading ||
            evaluationSaving.loading
          }
          onClick={() => fetchDefaultAssumptions()}
        >
          Reset defaults
        </Button>
        <Button
          kind="primary"
          size="md"
          className={style.btnEvaluate}
          disabled={
            evaluationConfigSaving.loading ||
            evaluationConfig.loading ||
            evaluationSaving.loading
          }
          onClick={() => {
            saveEvaluationConfig({
              evaluationUrl,
              evaluationForm: evaluationForm,
              abortController,
              isLoadingStoredEvaluation: false,
            });
          }}
        >
          {evaluationCompleted ? `Evaluate Again` : `Evaluate`}
        </Button>
        <SaveEvaluation
          evaluationsHref={evaluationsHref}
          getEvaluations={getEvaluations}
        />
      </div>
    </div>
  );
};

interface Props {
  evaluationsHref: string;
  getEvaluations: () => Promise<void>;
  evaluationUrl: string;
  defaultAssumptions: EvalDefaultAssumptions | undefined;
  fetchDefaultAssumptions: () => void;
  slots: EvalSlotResponse[];
}

const EvaluationForm = ({
  evaluationsHref,
  getEvaluations,
  evaluationUrl,
  defaultAssumptions,
  fetchDefaultAssumptions,
  slots,
}: Props) => {
  const abortController = new AbortController();
  return (
    <>
      {defaultAssumptions ? (
        <>
          <FormContent
            evaluationsHref={evaluationsHref}
            getEvaluations={getEvaluations}
            evaluationUrl={evaluationUrl}
            abortController={abortController}
            fetchDefaultAssumptions={fetchDefaultAssumptions}
            slots={slots}
          />
        </>
      ) : (
        <InlineLoading description="Loading Default Assumptions..." />
      )}
    </>
  );
};

const SaveEvaluation = ({
  evaluationsHref,
  getEvaluations,
}: {
  evaluationsHref: string;
  getEvaluations: () => Promise<void>;
}) => {
  const [evaluationConfig, setEvaluationConfig] = useAtom(
    saveEvaluationConfigAtom
  );
  const showDraft = useSetAtom(showDraftAtom);
  const evaluationForm = useAtomValue(evaluationFormAtom);
  const history = useHistory();

  const [evaluationSaving, saveEvaluation] = useAtom(saveEvaluationAtom);

  const evaluationCompleted =
    !evaluationConfig.loading && evaluationConfig.evaluation;

  const evaluationSaved = !evaluationSaving.loading && evaluationSaving.saved;

  const onSaveEvaluation = useCallback(() => {
    if (evaluationsHref) {
      getEvaluations().then(() => {
        history.push(evaluationsHref);
        showDraft(false);
      });
      setEvaluationConfig({ reset: true });
    }
  }, [
    evaluationsHref,
    getEvaluations,
    history,
    setEvaluationConfig,
    showDraft,
  ]);

  const isEvaluationDirtyAtom = useMemo(() => {
    return dirtyEvaluationAtom(evaluationForm);
  }, [evaluationForm]);

  const isEvaluationDirty = useAtomValue(isEvaluationDirtyAtom);

  const abortController = new AbortController();

  if (!evaluationConfig.loading && !evaluationCompleted) return null;

  return (
    <>
      <div>
        <Button
          kind="primary"
          size="md"
          disabled={
            evaluationConfig.loading ||
            evaluationSaving.loading ||
            evaluationSaved ||
            isEvaluationDirty
          }
          onClick={() => {
            if (!evaluationsHref) return;
            saveEvaluation({
              saveHref: evaluationsHref,
              evaluationForm,
              abortController,
              onSuccess: () => {
                onSaveEvaluation();
              },
            });
          }}
        >
          {evaluationSaving.loading && 'Saving...'}
          {!evaluationSaving.loading && !evaluationSaving.saved && 'Save Draft'}
        </Button>
      </div>
      {!evaluationSaving.loading && evaluationSaving.error && (
        <InlineNotification
          kind="error"
          title="Error"
          lowContrast
          subtitle={<span>{evaluationSaving.error}</span>}
        />
      )}
    </>
  );
};

export { EvaluationForm };
