import * as go from 'gojs';
import { RunsheetGraphDataPoints, Node } from './utils';

go.Diagram.licenseKey =
  '28f846e1bb6458c511d35a25403d7efb0eab2e61cf814da2590517f4ed0a614727cee12f54868dc7d3fe49fb197490d0d9963e2f951e0239ee6481dc46e2d6fcb76373b115581789f70122c68bea2da9ac7071fb92b323f2d87e8ca0e2fbc2c955edf6804fcd0ebb2e7e0439057aa943e3fbdb2fff01994c3e3d9da2aebeab1efb38728c8cf451ddbf5f718cefecb24e757114dc25f963a01b';

export type PropertyOnNodeClick = 'Opacity' | 'Visibility';

let partsPropertyOnNodeClick: PropertyOnNodeClick = 'Opacity';

let runsheetDiagram: go.Diagram;
function init(
  divRef: HTMLDivElement | null,
  overviewDiv: HTMLDivElement | null,
  dataPoints: RunsheetGraphDataPoints,
  onNodeClick: () => void
) {
  if (!divRef || !overviewDiv) return;

  const onClick = (e: go.InputEvent, obj: go.GraphObject) => {
    if (!(obj.part instanceof go.Node)) return;

    onNodeClick();

    const treeParts = findTreeParts(obj.part);

    runsheetDiagram.startTransaction('highlight');

    const applyProperties = (part: go.Part) => {
      if (!treeParts.contains(part)) {
        applyProperty(part, 0.1, false);
      } else {
        applyProperty(part, 1, true);
      }
    };

    runsheetDiagram.nodes.each(applyProperties);
    runsheetDiagram.links.each(applyProperties);

    runsheetDiagram.commitTransaction('highlight');
  };

  const $ = go.GraphObject.make;
  runsheetDiagram = $(go.Diagram, {
    layout: $(go.LayeredDigraphLayout, {
      direction: 0, // 0 means left-to-right
      layerSpacing: 500,
      columnSpacing: 100,
    }),
  });
  runsheetDiagram.div = divRef;

  runsheetDiagram.nodeTemplate = $(
    go.Node,
    'Table',
    {
      movable: true,
      layoutConditions: go.Part.LayoutStandard & ~go.Part.LayoutNodeSized,
      doubleClick: (e, node) => {
        if (node instanceof go.Node) {
          const url = node.data.url;
          window.open(url, '_blank', 'noopener,noreferrer');
        }
      },
      click: onClick,
    },
    $(
      go.Panel,
      'Auto',
      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('fill', '', (node) => {
          return getNodeColor(node);
        }).ofObject(),
        new go.Binding('stroke', 'isHighlighted', (h) => {
          return h ? 'red' : 'black';
        }).ofObject(),
        {
          stroke: '#000000',
          strokeWidth: 3,
        }
      ),
      $(
        go.Panel,
        'Table',
        $(
          go.TextBlock,
          {
            row: 0,
            stroke: '#FFFFFF',
            wrap: go.TextBlock.WrapFit,
            font: 'bold 12pt sans-serif',
            textAlign: 'center',
            margin: 4,
          },
          new go.Binding('text', '', (data) => {
            return `${data.id}, ${data.name}`;
          })
        ),
        $(
          go.TextBlock,
          {
            row: 1,
            stroke: '#FFFFFF',
            textAlign: 'center',
            margin: 4,
          },
          new go.Binding('text', 'transactionDate', (d) => d)
        ),
        $(
          go.TextBlock,
          {
            row: 2,
            stroke: '#A074C4',
            textAlign: 'center',
            margin: 4,
          },
          new go.Binding('text', 'documentTitle')
        )
      )
    )
  );

  runsheetDiagram.linkTemplate = $(
    go.Link,
    {
      routing: go.Link.Orthogonal,
      curve: go.Link.Bezier,
      doubleClick: function (e, link) {
        if (!(link instanceof go.Link)) return;

        const url = link.data.url;
        if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
        }
      },
    },
    $(
      go.Shape,
      new go.Binding('stroke', 'isHighlighted', (h) => {
        return h ? 'red' : 'black';
      }).ofObject()
    ),
    $(go.Shape, {
      toArrow: 'Standard',
    }),
    $(
      go.TextBlock,
      {
        segmentOffset: new go.Point(0, -10),
        segmentOrientation: go.Link.OrientUpright,
      },
      new go.Binding('text', 'documentTitle')
    )
  );

  // Create the model data for nodes and links
  const nodeDataArray = dataPoints.nodes;
  const linkDataArray = dataPoints.links;

  // Assign the model data to the diagram
  runsheetDiagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray);

  runsheetDiagram.addDiagramListener('InitialLayoutCompleted', () => {
    focusOnFirstNode();
  });

  const overview = $(go.Overview, {
    observed: runsheetDiagram,
    contentAlignment: go.Spot.Center,
  }); // tell it which Diagram to show and pan

  overview.div = overviewDiv;
}

function hasIncomingLinks(node: go.Node) {
  return node.findLinksInto().count > 0;
}

function hasOutgoingLinks(node: go.Node) {
  return node.findLinksOutOf().count > 0;
}

function getNodeColor(node: go.Node) {
  if (node.isHighlighted) return 'red';
  if (!hasIncomingLinks(node)) {
    return '#1E90FF';
  } else if (!hasOutgoingLinks(node)) {
    return '#FFA500';
  } else {
    return '#252526';
  }
}

// Define the matching criteria function
function matchesCriteria(node: go.Node, criteria: string) {
  return node.data.name.toLowerCase().includes(criteria.toLowerCase());
}

// Highlight nodes and links that match the criteria
function searchAndHighlight(criteria: string) {
  // Reset the highlighting before each new search
  runsheetDiagram.startTransaction('resetHighlights');
  runsheetDiagram.nodes.each((node) => {
    node.isHighlighted = false;
  });
  runsheetDiagram.links.each((link) => {
    link.isHighlighted = false;
  });
  runsheetDiagram.commitTransaction('resetHighlights');

  // Highlight matching nodes and links
  runsheetDiagram.startTransaction('highlightSearch');
  runsheetDiagram.nodes.each((node) => {
    if (matchesCriteria(node, criteria)) {
      node.isHighlighted = true;

      // Highlight connected links
      node.findLinksConnected().each((link) => {
        link.isHighlighted = true;
      });
    }
  });
  runsheetDiagram.commitTransaction('highlightSearch');
}

function focusOnFirstNode() {
  // Get the first node
  const firstNode = runsheetDiagram.nodes.first();

  if (firstNode) {
    // Focus the viewport on the first node
    runsheetDiagram.commandHandler.scrollToPart(firstNode);

    // Optionally, you can also select the first node
    runsheetDiagram.select(firstNode);
  }
}

export type GraphEntity = {
  focusNode: () => void;
  entity: Node;
};

export type EntityGraphType =
  | 'no-transactions-in'
  | 'transactions-in-out'
  | 'no-transactions-out';

function collectEntities(type: EntityGraphType): GraphEntity[] {
  const results: GraphEntity[] = [];

  runsheetDiagram.nodes.each((node) => {
    const focusNode = () => {
      runsheetDiagram.centerRect(node.actualBounds);
    };

    const hasIncoming = hasIncomingLinks(node);
    const hasOutgoing = hasOutgoingLinks(node);

    if (type === 'no-transactions-in' && !hasIncoming) {
      results.push({ focusNode, entity: node.data });
    } else if (type === 'transactions-in-out' && hasIncoming && hasOutgoing) {
      results.push({ focusNode, entity: node.data });
    } else if (type === 'no-transactions-out' && !hasOutgoing) {
      results.push({ focusNode, entity: node.data });
    }
  });

  return results;
}

const applyProperty = (part: go.Part, opacity: number, visible: boolean) => {
  if (partsPropertyOnNodeClick === 'Opacity') {
    part.opacity = opacity;
  } else {
    part.visible = visible;
  }
};

const findTreeParts = (node: go.Node) => {
  const parts = new go.Set();
  const queue = new go.List();
  queue.add(node);

  while (queue.count > 0) {
    const current = queue.first() as go.Node;
    queue.removeAt(0);
    parts.add(current);

    current.findLinksInto().each((link) => {
      parts.add(link);
      const otherNode = link.fromNode;
      if (otherNode !== null && !parts.contains(otherNode)) {
        queue.add(otherNode);
      }
    });
  }

  return parts;
};

const allPartsVisible = () => {
  runsheetDiagram.nodes.each((node) => {
    applyProperty(node, 1, true);
  });

  runsheetDiagram.links.each((link) => {
    applyProperty(link, 1, true);
  });
};

const setPartsPropertyOnNodeClick = (
  partProperty: typeof partsPropertyOnNodeClick
) => {
  partsPropertyOnNodeClick = partProperty;
};

export {
  init,
  searchAndHighlight,
  collectEntities,
  allPartsVisible,
  setPartsPropertyOnNodeClick,
};
