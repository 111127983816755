import {
  InlineLoading,
  InlineNotification,
  DataTableSkeleton,
  TabsSkeleton,
} from 'carbon-components-react';
import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PackageEvaluationResponse } from 'types/packages-api/responses';
import { ResultTabs } from './result-tabs';
import { usePackageEvaluation } from './atoms';
import { usePackageNotification } from 'lib/hooks/usePackageNotification';

const EvaluationResultImpl = ({
  packageEvaluation,
  getPackageEvaluation,
  isCombined,
}: {
  packageEvaluation: PackageEvaluationResponse | undefined;
  getPackageEvaluation: () => Promise<void>;
  isCombined: boolean;
}) => {
  const onSuccess = useCallback(
    () => getPackageEvaluation(),
    [getPackageEvaluation]
  );
  const [stalled, setStalled] = useState(
    !!packageEvaluation?.progress.isStalled
  );

  const onProgress = useCallback(() => void 0, []);
  const isSuccess = !!packageEvaluation?.progress.isSuccess;
  const onStalled = useCallback(() => setStalled(true), []);

  usePackageNotification({
    notifications: packageEvaluation?.notifications,
    isSuccess,
    onSuccess,
    onProgress,
    onStalled,
  });

  if (stalled) {
    return (
      <InlineNotification
        kind="warning"
        title="Warning"
        subtitle="Evaluation has timed out. Please try again or reach out to support."
      />
    );
  }

  if (!packageEvaluation) {
    return <InlineLoading description="Loading package evaluation..." />;
  }

  if (!isSuccess) {
    return (
      <>
        <h5>Evaluation in progress</h5>
        <p>
          <small>Please wait while we run your evaluation</small>
        </p>
        <br />
        <TabsSkeleton />
        <DataTableSkeleton
          showHeader={false}
          showToolbar={false}
          columnCount={5}
        />
      </>
    );
  }

  return <ResultTabs evaluation={packageEvaluation} isCombined={isCombined} />;
};

const EvaluationResult = ({ isCombined = false }: { isCombined?: boolean }) => {
  const { location } = useHistory();
  const packageEvaluationAtom = usePackageEvaluation(
    `${location.pathname}${location.search}`
  );
  const [packageEvaluation, getPackageEvaluation] = useAtom(
    packageEvaluationAtom
  );

  if (packageEvaluation.data && 'error' in packageEvaluation.data)
    return (
      <InlineNotification
        kind="error"
        title={packageEvaluation.data.error}
        lowContrast
      />
    );

  if (packageEvaluation.loading)
    return <InlineLoading title="Loading valuation overview" />;

  const hasError =
    packageEvaluation.data &&
    'progress' in packageEvaluation.data &&
    packageEvaluation.data.progress.error;

  if (hasError) {
    return (
      <InlineNotification
        kind="error"
        title="Error"
        subtitle="Evaluation did not run successfully. Please try again."
      />
    );
  }

  return (
    <EvaluationResultImpl
      packageEvaluation={packageEvaluation.data}
      getPackageEvaluation={getPackageEvaluation}
      isCombined={isCombined}
    />
  );
};

export { EvaluationResult };
