import searchAtomPair, { SearchParams } from 'atoms/search-atom';
import {
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  SkeletonPlaceholder,
  Search,
  TooltipDefinition,
  Grid,
  Row,
  Tile,
} from 'carbon-components-react';
import { Checkmark16, CopyFile20, Events32 } from '@carbon/icons-react';
import style from './entity-search.module.scss';
import { EntityDetailsPopover } from 'components/entity-details-popover';
import { Link } from 'components/link';
import { SkeletonTableRows } from 'components/skeleton-table-rows';
import { PaginatedTable } from 'components/table/paginated-table';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { EmptyState } from 'components/empty-state';
import ErrorToast from 'components/error-toast';
import { EntityResponse } from 'types/api-responses';
import { format } from 'lib/ui';
import plur from 'plur';

const DEFAULT_PAGE_SIZE = 16;

type EntitySearchParams = {
  hasInventory: boolean;
} & SearchParams;

const useSearchAtom = (href?: string) => {
  return useMemo(() => {
    return searchAtomPair<EntityResponse, EntitySearchParams>(
      href ? { href } : 'entities',
      {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        hasInventory: false,
      }
    );
  }, [href]);
};

const EntitySearch = ({
  href,
  onEntityClick,
}: {
  href?: string;
  onEntityClick?: (entity: EntityResponse) => ReactNode;
}) => {
  const searchAtoms = useSearchAtom(href);

  return (
    <>
      <Grid className="bx--no-gutter" fullWidth>
        <Row
          className={
            onEntityClick ? style.modalEntityFilters : style.entityFilters
          }
        >
          <EntityTypeFilter searchAtoms={searchAtoms} />
        </Row>

        <EntitySearchTable
          searchAtoms={searchAtoms}
          onEntityClick={onEntityClick}
        />
      </Grid>
    </>
  );
};

const EntityTypeFilter = ({
  searchAtoms,
}: {
  searchAtoms: ReturnType<typeof useSearchAtom>;
}) => {
  const { searchAtom } = searchAtoms;
  const [search] = useAtom(searchAtom);

  const totalEntities = search.data ? (
    `${format.number(search.data.totalCount || 0)} ${plur(
      'entity',
      search.data.totalCount
    )}`
  ) : search.loading ? (
    <SkeletonPlaceholder className={style.placeholderSkeletonItem} />
  ) : null;
  return (
    <>
      <h2 className={style.totalEntities}>{totalEntities}</h2>
      <div className={style.actionsArea}>
        <EntitiesSearchField searchAtoms={searchAtoms} />
      </div>
    </>
  );
};

const EntitiesSearchField = ({
  searchAtoms: { searchAtom, resetSearchDataAtom },
}: {
  searchAtoms: ReturnType<typeof useSearchAtom>;
}) => {
  const [searchDisplayText, setSearchDisplayText] = useState('');
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const resetSearchData = useSetAtom(resetSearchDataAtom);
  const searchParams = searchResults.currentPayload;
  const debouncedSetSearchPayload = useMemo(
    () =>
      debounce((value) => {
        resetSearchData(undefined);
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              pageSize: current?.pageSize || 10,
              page: 1,
              text: value,
            }
        );
      }, 400),
    [setSearchPayload, resetSearchData]
  );

  const setHasInventory = useCallback(
    (hasInventory: boolean) => {
      setSearchPayload((current) => ({
        ...current,
        hasInventory,
        pageSize: current?.pageSize || 10,
        page: 1,
      }));
    },
    [setSearchPayload]
  );

  return (
    <div className={style.entitiesSearchField}>
      {searchResults?.error ? (
        <ErrorToast message={searchResults?.error} />
      ) : null}
      <div className={style.hasInventoryCheckboxArea}>
        <Checkbox
          id="has-inventory"
          checked={searchParams?.hasInventory || false}
          onChange={(checked: boolean) => {
            setHasInventory(checked);
          }}
          labelText="With Inventory Only"
        />
      </div>
      <Search
        labelText="Search"
        placeholder="Search"
        size="lg"
        autoFocus
        value={searchDisplayText}
        onChange={(e) => {
          setSearchDisplayText(e.target.value);
          debouncedSetSearchPayload(e.target.value);
        }}
      />
    </div>
  );
};

const EntitySearchTable = ({
  searchAtoms: { searchAtom },
  onEntityClick,
}: {
  searchAtoms: ReturnType<typeof useSearchAtom>;
  onEntityClick?: (entity: EntityResponse) => ReactNode;
}) => {
  const [searchResults, setSearchPayload] = useAtom(searchAtom);
  const searchParams = searchResults.currentPayload;
  const pageSize = searchResults?.data?.pageSize || DEFAULT_PAGE_SIZE;

  if (!searchResults.loading && !searchResults.data?.results.length) {
    return (
      <Tile>
        <EmptyState
          IconComponent={Events32}
          headerText="No entities available"
          helperText="Currently you don't have any entities."
        />
      </Tile>
    );
  }

  return (
    <PaginatedTable
      pageSizes={[DEFAULT_PAGE_SIZE, 24, 48, 64]}
      pageSize={pageSize}
      className={style.entitiesTable}
      page={searchParams?.page || 1}
      totalItems={searchResults?.data?.totalCount || 0}
      onPaginate={({ page, pageSize }) => {
        setSearchPayload(
          (current) =>
            current && {
              ...current,
              page,
              pageSize,
            }
        );
      }}
    >
      <TableHead>
        <TableRow>
          <TableHeader></TableHeader>
          <TableHeader>Id</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Addresses</TableHeader>
          <TableHeader>Phone Numbers</TableHeader>
          <TableHeader>Emails</TableHeader>
          <TableHeader>Entity Type</TableHeader>
          <TableHeader>Inventory</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults.loading ? (
          <SkeletonTableRows rows={pageSize} columns={8} />
        ) : searchResults ? (
          searchResults.data?.results.map((el) => {
            return (
              <TableRow key={el.id}>
                <TableCell>
                  <div className={style.infoButtons}>
                    {!onEntityClick ? (
                      <TooltipDefinition
                        className={style.toolTip}
                        direction="top"
                        tooltipText="Deduplicate"
                      >
                        <Link to={el.resources.deduplication.href}>
                          <CopyFile20 />
                        </Link>
                      </TooltipDefinition>
                    ) : (
                      onEntityClick(el)
                    )}
                    <EntityDetailsPopover href={el.resources.details.href} />
                  </div>
                </TableCell>
                <TableCell>{el.id}</TableCell>
                <TableCell>
                  <Link to={el.href}>{el.name}</Link>
                </TableCell>
                <TableCell>
                  {el.addresses.map((el) => (
                    <p key={el.streetAddress}>
                      {el.streetAddress ? `${el.streetAddress}, ` : null}
                      {el.city ? `${el.city}, ` : null}
                      {el.state ? `${el.state} ` : null}
                      {el.zipCode ? `${el.zipCode}` : null}
                    </p>
                  ))}
                </TableCell>
                <TableCell>
                  {el.phoneNumbers.map((el) => (
                    <p key={el.number}>{el.number}</p>
                  ))}
                </TableCell>
                <TableCell>
                  {el.emailAddresses.map((el) => (
                    <p key={el.email}>{el.email}</p>
                  ))}
                </TableCell>
                <TableCell>{el.type?.replace(/(Entity$)/, '')}</TableCell>
                <TableCell>
                  {el.hasInventory ? <Checkmark16 /> : null}
                </TableCell>
              </TableRow>
            );
          })
        ) : null}
      </TableBody>
    </PaginatedTable>
  );
};

export { EntitySearch };
