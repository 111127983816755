import plur from 'plur';
import style from './evaluation-count.module.scss';

const EvaluationCount = ({ count }: { count: number }) => {
  return (
    <legend className={style.legendTitle}>
      ({count}) {plur('Evaluation', count)}
    </legend>
  );
};

export { EvaluationCount };
