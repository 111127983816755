import {
  TypeLayout,
  TypeLayoutBody,
  TypeLayoutCell,
  TypeLayoutRow,
} from '@carbon/ibm-security';
import {
  Button,
  InlineNotification,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from 'carbon-components-react';
import { CenteredMap, PolygonArea } from 'components/map';
import { useAtom, useAtomValue } from 'jotai';
import style from '../calculation/asset.module.scss';

import { green } from '@carbon/colors';
import { selectedMapSubtractsAtom, selectedOwnerAtom } from './atoms';
import classNames from 'classnames';
import { OwnershipResponse, TitleWorkspaceResponse } from 'types/api-responses';
import { atomWithStorage } from 'jotai/utils';
import { getMapDefaultConfig } from 'components/map/atoms';
import { LinkWithHover } from 'components/hover-card-resource';
import { ColumnDependency16 } from '@carbon/icons-react';
import { Suspense, useState } from 'react';
import ChainViewer from 'pages/title-workspaces/overview/calculation/chain_viewer';

const NULL_DISPLAY_VALUE = '--';
interface Props {
  ownerDetails: OwnershipResponse;
  titleWorkspace: TitleWorkspaceResponse;
}

const mapConfigAtom = atomWithStorage(
  'insights-owner-map-config',
  getMapDefaultConfig()
);

const Owner = ({ ownerDetails, titleWorkspace }: Props) => {
  const [selectedMapSubtracts, setSelectedMapSubtracts] = useAtom(
    selectedMapSubtractsAtom
  );
  const selectedOwner = useAtomValue(selectedOwnerAtom);
  const geometry =
    ownerDetails.assets.length && ownerDetails.assets[0].coveredArea.geojson;
  const [surfaceGraphOpen, setSurfaceGraphOpen] = useState(false);
  const [mineralGraphOpen, setMineralGraphOpen] = useState(false);

  return (
    <section>
      <div className={style.assetGrid}>
        <div
          className={classNames(style.assetData, {
            [style.bannerSpacing]: selectedOwner?.entity.deleted,
          })}
        >
          {selectedOwner?.entity.deleted && <EntityWarningBanner />}
          <TypeLayout>
            <TypeLayoutBody>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.noLeftPadding}>
                  Owner
                </TypeLayoutCell>
                <TypeLayoutCell className={style.nonEditable}>
                  {selectedOwner &&
                    (selectedOwner.entity.deleted ? (
                      selectedOwner.entity.name
                    ) : (
                      <LinkWithHover
                        kind="entity"
                        href={selectedOwner.entity.href}
                      >
                        <Link href={selectedOwner.entity.href} target="_blank">
                          {selectedOwner.entity.name}
                        </Link>
                      </LinkWithHover>
                    ))}
                </TypeLayoutCell>
              </TypeLayoutRow>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.noLeftPadding}>
                  County
                </TypeLayoutCell>
                <TypeLayoutCell className={style.nonEditable}>
                  {titleWorkspace.referenceLocation.county?.name}
                </TypeLayoutCell>
              </TypeLayoutRow>
              <TypeLayoutRow>
                <TypeLayoutCell className={style.noLeftPadding}>
                  Number of Assets Owned
                </TypeLayoutCell>
                <TypeLayoutCell className={style.nonEditable}>
                  {ownerDetails.assets.length}
                </TypeLayoutCell>
              </TypeLayoutRow>
              {selectedOwner?.resources?.surfaceDebuggingGraph && (
                <TypeLayoutRow>
                  <TypeLayoutCell className={style.noLeftPadding}>
                    Surface Debugging Graph
                  </TypeLayoutCell>
                  <TypeLayoutCell className={style.nonEditable}>
                    <Button
                      kind="ghost"
                      renderIcon={ColumnDependency16}
                      onClick={() => setSurfaceGraphOpen(true)}
                    >
                      Open surface graph
                    </Button>
                    {surfaceGraphOpen && (
                      <Suspense fallback={<div>Loading</div>}>
                        <ChainViewer
                          tractName="All"
                          url={
                            selectedOwner?.resources?.surfaceDebuggingGraph.href
                          }
                          onClose={() => setSurfaceGraphOpen(false)}
                        />
                      </Suspense>
                    )}
                  </TypeLayoutCell>
                </TypeLayoutRow>
              )}
              {selectedOwner?.resources?.mineralDebuggingGraph && (
                <TypeLayoutRow>
                  <TypeLayoutCell className={style.noLeftPadding}>
                    Mineral Debugging Graph
                  </TypeLayoutCell>
                  <TypeLayoutCell className={style.nonEditable}>
                    <Button
                      kind="ghost"
                      renderIcon={ColumnDependency16}
                      onClick={() => setMineralGraphOpen(true)}
                    >
                      Open mineral graph
                    </Button>
                    {mineralGraphOpen && (
                      <Suspense fallback={<div>Loading</div>}>
                        <ChainViewer
                          tractName="All"
                          url={
                            selectedOwner?.resources?.mineralDebuggingGraph.href
                          }
                          onClose={() => setMineralGraphOpen(false)}
                        />
                      </Suspense>
                    )}
                  </TypeLayoutCell>
                </TypeLayoutRow>
              )}
            </TypeLayoutBody>
          </TypeLayout>
        </div>
        <div className={style.assetMap}>
          {ownerDetails.assets && geometry ? (
            <CenteredMap
              geometry={geometry}
              mapConfigAtom={mapConfigAtom}
              onClick={(event) => {
                const ids = event.features?.flatMap((el) => {
                  return el.layer.id && typeof el.layer.id === 'string'
                    ? [el.layer.id.replace('fill-', '')]
                    : [];
                });
                if (ids) {
                  setSelectedMapSubtracts(ids);
                }
              }}
            >
              {ownerDetails.assets.map((el, i) => {
                return (
                  el.coveredArea.geojson && (
                    <PolygonArea
                      key={`${el.coveredArea.aggregateId}-${i}-${selectedOwner?.entity.id}`}
                      geometry={el.coveredArea.geojson}
                      id={el.coveredArea.name}
                      color={
                        selectedMapSubtracts.includes(el.coveredArea.name)
                          ? green[50]
                          : undefined
                      }
                    />
                  )
                );
              })}
            </CenteredMap>
          ) : (
            <p>
              There was an issue rendering the map. The tract has no geometry.
            </p>
          )}
        </div>
      </div>
      <hr className={style.separator} />
      <AssetsTable assets={ownerDetails.assets} />
    </section>
  );
};

const AssetsTable = ({ assets }: { assets: OwnershipResponse['assets'] }) => {
  const tractNameMaxlimit = 30;
  const [selectedMapSubtracts, setSelectedMapSubtracts] = useAtom(
    selectedMapSubtractsAtom
  );

  return (
    <TableContainer>
      <Table size="lg" useStaticWidth={true}>
        <TableHead className={style.assetTableCellHeader}>
          <TableRow>
            <TableHeader>Sub-tract Name</TableHeader>
            <TableHeader>Surface</TableHeader>
            <TableHeader>Start Depth (ft)</TableHeader>
            <TableHeader>End Depth (ft)</TableHeader>
            <TableHeader>Executive</TableHeader>
            <TableHeader>Bonus</TableHeader>
            <TableHeader>Delay</TableHeader>
            <TableHeader>Royalty</TableHeader>
            <TableHeader>Fixed Royalty</TableHeader>
            <TableHeader>WI</TableHeader>
            <TableHeader>ORRI</TableHeader>
            <TableHeader>NRI</TableHeader>
            <TableHeader>GIS Gross Acreage</TableHeader>
            <TableHeader>Legal Acreage</TableHeader>
            <TableHeader>Lease</TableHeader>
            <TableHeader>Royalty Rate</TableHeader>
            <TableHeader>Burdened Entity</TableHeader>
            <TableHeader>Warnings</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody className={style.assetTableCellContainer}>
          {assets.map((asset, i) => (
            <TableRow
              key={`${i}`}
              className={classNames({
                [style.highlightRow]: selectedMapSubtracts.includes(
                  asset.coveredArea.name
                ),
              })}
              onClick={() => {
                setSelectedMapSubtracts([asset.coveredArea.name]);
              }}
            >
              <TableCell>
                {asset.coveredArea.name.length > tractNameMaxlimit ? (
                  <Tooltip
                    direction="top"
                    align="start"
                    tabIndex={0}
                    triggerText={
                      asset.coveredArea.name.slice(0, tractNameMaxlimit - 3) +
                      '...'
                    }
                  >
                    <p>{asset.coveredArea.name}</p>
                  </Tooltip>
                ) : (
                  <p>{asset.coveredArea.name}</p>
                )}
              </TableCell>
              <TableCell>{asset.surface || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>
                {asset.coveredArea.startDepthInFeet || NULL_DISPLAY_VALUE}
              </TableCell>
              <TableCell>{asset.coveredArea.endDepthInFeet || '∞'}</TableCell>
              <TableCell>{asset.executive || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>{asset.bonus || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>{asset.delay || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>{asset.royalty || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>{asset.fixedRoyalty || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>{asset.working || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>{asset.override || NULL_DISPLAY_VALUE}</TableCell>
              <TableCell>
                {asset.netRevenueInterest || NULL_DISPLAY_VALUE}
              </TableCell>
              <TableCell>
                {asset.coveredArea.calculatedAcreage || NULL_DISPLAY_VALUE}
              </TableCell>
              <TableCell>
                {asset.coveredArea.legalAcreage || NULL_DISPLAY_VALUE}
              </TableCell>
              <TableCell>
                {asset.lease ? (
                  <Link href={asset.lease.href} target="_blank">
                    {asset.lease.name}
                  </Link>
                ) : (
                  NULL_DISPLAY_VALUE
                )}
              </TableCell>
              <TableCell>
                {asset.lease?.royaltyRate || NULL_DISPLAY_VALUE}
              </TableCell>
              <TableCell>
                {asset.burdenedEntity ? (
                  <Link href={asset.burdenedEntity.href} target="_blank">
                    {asset.burdenedEntity.name}
                  </Link>
                ) : (
                  NULL_DISPLAY_VALUE
                )}
              </TableCell>
              <TableCell>
                {asset.preventedOverconveyance || NULL_DISPLAY_VALUE}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EntityWarningBanner = () => {
  return (
    <div className={style.infoBanner}>
      <InlineNotification
        kind={'warning'}
        lowContrast
        title={'Entity Deleted'}
        subtitle={
          'The title workspace must be recalculated to reflect entity alterations'
        }
        hideCloseButton={true}
      />
    </div>
  );
};

export { Owner };
