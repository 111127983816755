import searchAtomPair from 'atoms/search-atom';
import { DocumentFacets } from 'components/search/common/types';
import { atom, Getter, Setter } from 'jotai';
import { DocumentInterpretationResponse } from 'types/api-responses';
import { ToggleTypes } from './types';
import { defaultPayload } from './utils';
import {
  InterpretationSearchAggregations,
  InterpretationSearchParams,
} from 'components/runsheet/common/types';

const toggleMapAtom = atom<boolean>(false);
const toggledStatsAtom = atom<boolean>(false);

const { searchAtom, resetSearchDataAtom } = searchAtomPair<
  DocumentInterpretationResponse,
  InterpretationSearchParams,
  DocumentFacets,
  InterpretationSearchAggregations
>('interpretations', defaultPayload);

const toggleAtoms = atom({
  map: toggleMapAtom,
  stats: toggledStatsAtom,
});

// Ensures only one toggle can be on
const setToggleAtom = atom(
  null,
  (get: Getter, set: Setter, toggle: ToggleTypes) => {
    const toggles = get(toggleAtoms);
    Object.keys(toggles).forEach((key) => {
      key === toggle
        ? set(toggles[key as keyof typeof toggles], (prev) => !prev)
        : set(toggles[key as keyof typeof toggles], false);
    });
  }
);

const createExpandableAtom = () => {
  const innerAtom = atom<{ open: boolean }>({ open: false });
  return atom<{ open: boolean }, [{ open: boolean }], void>(
    (get) => get(innerAtom),
    (get, set, payload) => {
      set(innerAtom, (current) => ({ ...current, open: payload.open }));
    }
  );
};

export {
  createExpandableAtom,
  resetSearchDataAtom,
  searchAtom,
  setToggleAtom,
  toggleAtoms,
};
