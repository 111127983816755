import { DocumentView32 } from '@carbon/icons-react';
import { getError } from 'atoms/form-atoms';
import { Column, Row, TextInput } from 'carbon-components-react';
import { FormRow, FormSection } from 'components/forms';
import { useAtom, useAtomValue } from 'jotai';
import { formAtom } from './atoms';
import { TextField } from 'components/form-fields/text-field';

const BookType = () => {
  const form = useAtomValue(formAtom);
  return (
    <TextField fieldAtom={form.bookType} id="book-type" labelText="Book Type" />
  );
};

const DocumentType = () => {
  const form = useAtomValue(formAtom);
  return (
    <TextField
      fieldAtom={form.documentType}
      id="document-type"
      labelText="Document Type"
    />
  );
};

const Volume = () => {
  const form = useAtomValue(formAtom);
  const [field, setField] = useAtom(form.volume);
  return (
    <TextInput
      light
      id="document-volume"
      value={field.value || ''}
      name="volume"
      labelText="Volume Number"
      onChange={({ target: { value } }) => setField(value)}
      invalid={!field.isValid}
      invalidText={getError(field)}
    />
  );
};

const Page = () => {
  const form = useAtomValue(formAtom);
  const [field, setField] = useAtom(form.page);
  return (
    <TextInput
      light
      id="document-page"
      value={field.value || ''}
      name="page"
      labelText="Page Number"
      onChange={({ target: { value } }) => setField(value)}
      invalid={!field.isValid}
      invalidText={getError(field)}
    />
  );
};

const Instrument = () => {
  const form = useAtomValue(formAtom);
  const [field, setField] = useAtom(form.instrumentNumber);
  return (
    <TextInput
      light
      id="document-instrument-number"
      value={field.value || ''}
      name="instrumentNumber"
      labelText="Instrument Number"
      onChange={({ target: { value } }) => setField(value)}
    />
  );
};

const Details = () => {
  return (
    <FormSection title="Document Details" iconComponent={<DocumentView32 />}>
      <FormRow>
        <BookType />
      </FormRow>
      <FormRow>
        <DocumentType />
      </FormRow>
      <FormRow>
        <Row>
          <Column>
            <Volume />
          </Column>
          <Column>
            <Page />
          </Column>
        </Row>
      </FormRow>
      <FormRow>
        <Instrument />
      </FormRow>
    </FormSection>
  );
};

export { Details };
