import { useState } from 'react';

const usePagination = <T>(items: T[], pageSize: number) => {
  const [state, setState] = useState({ page: 1, pageSize });

  const onPaginate = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setState({ page, pageSize });
  };

  const startIndex = (state.page - 1) * state.pageSize;
  const slicedItems = items.slice(startIndex, startIndex + state.pageSize);
  return { page: state.page, slicedItems, onPaginate };
};

export { usePagination };
