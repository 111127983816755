import { Calculator16 } from '@carbon/icons-react';
import { Button, Loading, TooltipDefinition } from 'carbon-components-react';
import { useAtom, useSetAtom } from 'jotai';
import { subscribe } from 'lib/pusher';
import { useEffect, useState } from 'react';
import { TitleCalculationResponse } from 'types/api-responses';
import { CalculateFormProgress } from 'types/calculate-form-progress';
import { getCurrentDateString } from 'utils/dates';

import style from './recalculate-workspace.module.scss';
import {
  newTitleCalculationAtom,
  titleCalculationPusherErrorMessageAtom,
} from 'atoms/title-calculation-atoms';

const RecalculateWorkspace = ({
  calculationsHref,
  onSuccess,
  onTitleCalculationError,
  startingInterestDate,
  runningCalculationChannel,
  runningCalculationHref,
}: {
  calculationsHref: string;
  onSuccess: (href: string) => void;
  onTitleCalculationError: (isTitleCalculationError: boolean) => void;
  startingInterestDate: string | null | undefined;
  runningCalculationChannel: string | null;
  runningCalculationHref?: string;
}) => {
  const setTitleCalculationPusherErrorMessage = useSetAtom(
    titleCalculationPusherErrorMessageAtom
  );
  const [calculation, runCalculation] = useAtom(newTitleCalculationAtom);
  const [isTitlePusherNotificationActive, setIsTitlePusherNotificationActive] =
    useState(false);
  const [newCalculation, setNewCalculation] = useState<
    TitleCalculationResponse | undefined
  >(undefined);

  const channel =
    runningCalculationChannel || newCalculation?.notifications.channel;

  const event = newCalculation?.notifications.events[0].name || 'change';

  const calculationHref = runningCalculationHref || newCalculation?.href;

  useEffect(() => {
    if (!channel || !calculationHref) {
      return () => void 0;
    }

    if (runningCalculationChannel) {
      setIsTitlePusherNotificationActive(true);
    }
    const subscription = subscribe(channel);
    subscription.bind(event, (data: CalculateFormProgress) => {
      if ('calcStatus' && 'completionPercentage' in data) {
        if (data.calcStatus === 'error') {
          setIsTitlePusherNotificationActive(false);
          setTitleCalculationPusherErrorMessage(data.errorMessage);
          subscription.unsubscribe();
        }
        if (data.calcStatus === 'complete') {
          setIsTitlePusherNotificationActive(false);
          onSuccess(calculationHref);
          subscription.unsubscribe();
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationHref, channel]);

  return (
    <TooltipDefinition
      className={style.toolTip}
      direction="top"
      tooltipText={
        !startingInterestDate ? (
          <span className={style.toolTipText}>
            {'Please add a\nstarting interest\nthen calculate'}
          </span>
        ) : (
          'Calculate workspace'
        )
      }
    >
      <Button
        size="md"
        onClick={() => {
          onTitleCalculationError(false);
          runCalculation({
            url: calculationsHref,
            type: 'POST',
            data: {
              asOfDate: getCurrentDateString(),
            },
            onSuccess: (data) => {
              setIsTitlePusherNotificationActive(true);
              setNewCalculation(data);
            },
            onError: () => {
              setIsTitlePusherNotificationActive(false);
              onTitleCalculationError(true);
            },
          });
        }}
        renderIcon={Calculator16}
        disabled={
          calculation.loading ||
          isTitlePusherNotificationActive ||
          !startingInterestDate
        }
      >
        {isTitlePusherNotificationActive && (
          <Loading description="Calculating..." small />
        )}
        Calculate
      </Button>
    </TooltipDefinition>
  );
};

export { RecalculateWorkspace };
