import classNames from 'classnames';
import { FieldsetHTMLAttributes, ReactNode } from 'react';

import style from './form.module.scss';

export interface FormSectionProps
  extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  title?: string;
  border?: boolean;
  headerElements?: JSX.Element;
  iconComponent?: ReactNode;
}

const FormSection = ({
  children,
  title,
  border = true,
  headerElements,
  iconComponent,
  className,
  ...rest
}: FormSectionProps) => {
  className = classNames(className, style.section, {
    [style.border]: border,
  });

  return (
    <fieldset className={className} {...rest}>
      <legend className={style.iconHeader}>
        {iconComponent && <span className={style.icon}>{iconComponent}</span>}
        {title && <span className={style.sectionHeader}>{title}</span>}
        {headerElements && headerElements}
      </legend>
      {children}
    </fieldset>
  );
};

interface FormRowProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  className?: string;
  toTrash?: boolean;
}

const FormRow = ({ className, toTrash, ...rest }: FormRowProps) => {
  className = classNames(className, style.row, {
    [style.toTrash]: toTrash,
  });
  return <fieldset className={className} {...rest} />;
};

export { FormRow, FormSection };
