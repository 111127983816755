import { parse, compareAsc, compareDesc, isValid } from 'date-fns';

const parseDateFromCell = (cellValue: string | null) => {
  if (cellValue === null) return '';
  const parsedDate = parse(cellValue, 'MM/dd/yyyy h:mm aaaa', new Date());
  return isValid(parsedDate) ? parsedDate : cellValue;
};

const customSortRow = (
  cellA: string,
  cellB: string,
  { sortDirection }: { sortDirection: 'ASC' | 'DESC' | 'NONE' }
) => {
  if (sortDirection === 'NONE') return 0;

  // Try to parse dates from cell values; fallback to original value if parsing fails
  const a = parseDateFromCell(cellA);
  const b = parseDateFromCell(cellB);

  if (typeof a !== 'string' && typeof b !== 'string') {
    return sortDirection === 'ASC' ? compareAsc(a, b) : compareDesc(a, b);
  } else if (typeof a === 'string' && typeof b === 'string') {
    const comparison = a.localeCompare(b, undefined, {
      sensitivity: 'base',
      numeric: true,
    });

    return sortDirection === 'ASC' ? comparison : -comparison;
  }

  return 0;
};

export { customSortRow };
