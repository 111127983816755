import { Tile } from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import NoDataSvg from 'images/srp-illustration-data.svg';

const NoResults = () => {
  return (
    <Tile>
      <EmptyState
        icon={NoDataSvg}
        headerText="No Records Found"
        helperText="Currently Insights doesn’t have any available data."
      />
    </Tile>
  );
};

export { NoResults };
