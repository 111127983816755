import { Calendar32 } from '@carbon/icons-react';
import { getError } from 'atoms/form-atoms';
import { Column, Row } from 'carbon-components-react';
import { DatePicker } from 'components/date-picker';

import { FormRow, FormSection } from 'components/forms';
import { getDateString } from 'utils/dates';
import { useAtom, useAtomValue } from 'jotai';
import { nonCourthouseFormAtom } from './atoms';

const NonCourthouseDates = () => {
  const form = useAtomValue(nonCourthouseFormAtom);
  const [field, setField] = useAtom(form.effectiveDate);
  return (
    <FormSection title="Document Dates" iconComponent={<Calendar32 />}>
      <FormRow>
        <Row>
          <Column>
            <DatePicker
              light
              id="effective-date"
              labelText="Effective Date"
              value={field.value ?? undefined}
              onChange={(date) => {
                setField(date[0] ? getDateString(date[0]) : null);
              }}
              invalid={!field.isValid}
              invalidText={getError(field)}
            />
          </Column>
        </Row>
      </FormRow>
    </FormSection>
  );
};

export { NonCourthouseDates };
