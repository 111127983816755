import { Tabs as TabsContainer, Tab } from 'carbon-components-react';
import { useHistory, useLocation } from 'react-router-dom';
import plur from 'plur';
import { DocumentResponse } from 'types/api-responses';
import { getReferrerAsSearchParam } from './utils';

const InterpretationsCount = ({ document }: { document: DocumentResponse }) => {
  const interpretationCount = document.interpretationsCount;
  return (
    <>{`${plur(
      'Interpretation',
      interpretationCount
    )} (${interpretationCount})`}</>
  );
};

const Tabs = ({ document }: { document: DocumentResponse }) => {
  const location = useLocation();
  const history = useHistory();

  const referrer = getReferrerAsSearchParam(location.search);

  return (
    <TabsContainer
      selected={location.pathname === document.href ? 0 : 1}
      selectionMode="manual"
      onSelectionChange={(index) => {
        if (index === 0) {
          history.push(`${document.href}${referrer}`);
        } else if (index === 1) {
          history.push(`${document.resources.interpretations.href}${referrer}`);
        }
      }}
    >
      <Tab id="tab-document" label="Overview" renderContent={() => null} />
      <Tab
        id="tab-interpretations"
        label={<InterpretationsCount document={document} />}
        renderContent={() => null}
      />
    </TabsContainer>
  );
};

export { Tabs };
