import { useAtomValue } from 'jotai';
import {
  DataTable,
  DataTableCustomRenderProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  DataTableSkeleton,
  InlineNotification,
  Search,
  Tile,
} from 'carbon-components-react';
import { TreeViewAlt32 } from '@carbon/icons-react';
import { Link } from 'components/link';
import { resourcesAtom } from 'atoms/root';
import { format, parseISO } from 'date-fns';

import style from './scenario-list.module.scss';
import { useScenariosAtom } from './atoms';
import { capitalizeFirstLetter } from 'utils/strings';
import { customSortRow } from 'lib/ui/custom-row-sort';
import { EmptyState } from 'components/empty-state';
import plur from 'plur';

interface TableCell {
  value: string;
  info?: { header: string };
}

const headersList = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'interestType',
    header: 'Interest Type',
    format: (el: string) => capitalizeFirstLetter(el),
  },
  {
    key: 'createdBy',
    header: 'Created By',
  },
  {
    key: 'createAtFormatted',
    header: 'Created At',
  },
  {
    key: 'updatedAtFormatted',
    header: 'Last Updated',
  },
];

const ScenarioList = () => {
  const resources = useAtomValue(resourcesAtom);
  const scenariosHref = resources?.scenarios.href;
  const scenariosAtom = useScenariosAtom(scenariosHref);
  const scenarios = useAtomValue(scenariosAtom);

  if (scenarios.data && 'error' in scenarios.data)
    return (
      <InlineNotification
        kind="error"
        title={scenarios.data.error}
        lowContrast
      />
    );

  const augmentedRows = scenarios.data?.map((el) => ({
    ...el,
    id: String(el.id),
    createAtFormatted: format(parseISO(el.createdAt), 'MM/dd/yyyy h:mm aaaa'),
    updatedAtFormatted: format(parseISO(el.updatedAt), 'MM/dd/yyyy h:mm aaaa'),
  }));

  return augmentedRows ? (
    <DataTable
      rows={augmentedRows}
      headers={headersList}
      sortRow={customSortRow}
      isSortable
    >
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        onInputChange,
      }: DataTableCustomRenderProps) => (
        <div className={style.scenariosTable}>
          <div className={style.scenariosSearch}>
            <h2 className={style.totalScenarios}>
              {rows.length.toFixed(0)} {plur('scenario', rows.length)}
            </h2>
            <div className={style.actionsArea}>
              <Search
                labelText="Search"
                placeholder="Search"
                size="lg"
                autoFocus
                onChange={onInputChange}
              />
            </div>
          </div>
          {rows.length > 0 ? (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => {
                    return (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        key={header.key}
                      >
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIndex) => {
                  return (
                    <TableRow {...getRowProps({ row })} key={row.id}>
                      {row.cells.map((cell, cellIndex) => {
                        const cellKey =
                          cell.id + '_' + rowIndex + '_' + cellIndex;
                        const augmentedRowIndex = cell.id.replace(/\D/g, '');
                        const toHref = augmentedRows.find(
                          (el) => el.id === augmentedRowIndex
                        )?.href;
                        if (cellIndex === 0) {
                          return (
                            <TableCell key={cellKey}>
                              {toHref && <Link to={toHref}>{cell.value}</Link>}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={cellKey}>
                              {headersList[cellIndex].format?.(cell.value) ??
                                (cell.value || '--')}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Tile>
              <EmptyState
                IconComponent={TreeViewAlt32}
                headerText="No scenarios available"
                helperText="Currently you don't have any scenarios."
              />
            </Tile>
          )}
        </div>
      )}
    </DataTable>
  ) : (
    <DataTableSkeleton
      className={style.scenariosTable}
      showHeader={false}
      columnCount={3}
      rowCount={10}
      headers={[
        {
          header: 'Name',
        },
        {
          header: 'Interest Type',
        },
        {
          header: 'Created By',
        },
        {
          header: 'Created At',
        },
        {
          header: 'Last Updated',
        },
      ]}
    />
  );
};

export { ScenarioList };
