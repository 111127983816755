import {
  Button,
  Column,
  Grid,
  Modal,
  ProgressIndicator,
  ProgressStep,
  Row,
} from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  developmentAreaAtom,
  errorsAtom,
  geographiesAtom,
  isFormValidAtom,
  referenceLocationIdsAtom,
  runMutationAtom,
  stepAtom,
} from './atoms';
import NoMapSvg from 'images/srp-illustration-no-map.svg';

import style from './development-area-wizard.module.scss';

import { StepOne } from './step-one';
import { StepTwo } from './step-two';
import { DevelopmentAreaMap } from 'components/development-area-map';
import { atomWithStorage } from 'jotai/utils';
import { getMapDefaultConfig } from 'components/map/atoms';
import { ProvideRootResource } from 'components/hydrate-atoms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreated: () => void;
  developmentArea: any;
}

const mapConfigAtom = atomWithStorage(
  'insights-dev-wizard-map-config',
  getMapDefaultConfig()
);

const Wizard = ({
  isOpen,
  onClose,
  onCreated,
}: Omit<Props, 'developmentArea'>) => {
  const [step, setStep] = useAtom(stepAtom);
  const geographies = useAtomValue(geographiesAtom);
  const referenceLocationIds = useAtomValue(referenceLocationIdsAtom);

  if (!isOpen) return null;

  return (
    <Modal
      open={isOpen}
      size="lg"
      passiveModal={true}
      modalHeading="Create Development Area"
      className={style.createTitleModal}
      onRequestClose={() => onClose()}
    >
      <Grid condensed fullWidth>
        <Row className={style.stretch}>
          <Column sm={4} lg={2}>
            <div className={style.steps}>
              <ProgressIndicator currentIndex={step} vertical>
                <ProgressStep label="Create Area" />
                <ProgressStep label="Review" />
              </ProgressIndicator>
            </div>
          </Column>
          <Column sm={6} lg={7} className={style.content}>
            <div className={style.main}>
              {step === 0 ? <StepOne /> : null}
              {step === 1 ? <StepTwo /> : null}
            </div>
          </Column>
          <Column sm={6} lg={7} className={style.mapContainer}>
            {!geographies.length ? (
              <EmptyState
                icon={NoMapSvg}
                headerText="No Survey Areas Selected"
                helperText="Please select a survey area to get started."
                className={style.emptyMessage}
              />
            ) : (
              <DevelopmentAreaMap
                geographies={geographies}
                referenceLocationIds={referenceLocationIds}
                mapConfigAtom={mapConfigAtom}
              />
            )}
          </Column>
        </Row>
        <Row className={style.btnActions}>
          <Column lg={{ span: 7, offset: 2 }}>
            <div className={style.btnAlign}>
              <Button kind="ghost" size="lg" onClick={onClose}>
                Cancel
              </Button>
              <Button
                kind="secondary"
                size="lg"
                disabled={step !== 1}
                onClick={() => {
                  setStep((c) => c - 1);
                }}
              >
                Back
              </Button>
              {step === 0 ? <NextButton /> : null}
              {step === 1 ? <SaveButton onCreated={onCreated} /> : null}
            </div>
          </Column>
        </Row>
      </Grid>
    </Modal>
  );
};

const NextButton = () => {
  const isFormValid = useAtomValue(isFormValidAtom);
  const [validateDevArea, runMutation] = useAtom(runMutationAtom);
  const setErrors = useSetAtom(errorsAtom);
  const setStep = useSetAtom(stepAtom);
  return (
    <Button
      kind="primary"
      size="lg"
      disabled={!isFormValid || validateDevArea.loading}
      onClick={() =>
        runMutation({
          type: 'validate',
          onSuccess: () => {
            setErrors(undefined);
            setStep((c) => c + 1);
          },
          onError: (errors) => setErrors(errors),
        })
      }
    >
      Next
    </Button>
  );
};

const SaveButton = ({ onCreated }: Pick<Props, 'onCreated'>) => {
  const [saveDevArea, runMutation] = useAtom(runMutationAtom);
  const setErrors = useSetAtom(errorsAtom);
  return (
    <>
      <Button
        kind="primary"
        size="lg"
        disabled={saveDevArea.loading}
        onClick={() =>
          runMutation({
            type: 'mutate',
            onSuccess: () => onCreated(),
            onError: (errors) => setErrors(errors),
          })
        }
      >
        Save
      </Button>
    </>
  );
};

const DevelopmentAreaWizard = ({ developmentArea, ...rest }: Props) => {
  return (
    <ProvideRootResource
      key={Number(rest.isOpen)}
      initialValues={[[developmentAreaAtom, developmentArea]]}
    >
      <Wizard {...rest} />
    </ProvideRootResource>
  );
};

export { DevelopmentAreaWizard };
