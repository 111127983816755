import { atom } from 'jotai';
import request from 'lib/request';
import {
  DocumentInterpretationResponse,
  SearchResult,
} from 'types/api-responses';
import { RunsheetGraphDataPoints, extractRunsheetData } from './utils';
import { GraphEntity } from './gojs-graph';

export type RunsheetQueryResult = SearchResult<DocumentInterpretationResponse>;

async function fetchRunsheetPage(
  runsheetQueryHref: string,
  page: number
): Promise<RunsheetQueryResult> {
  const response = await request.post<RunsheetQueryResult, unknown>(
    runsheetQueryHref,
    {
      page: page,
      pageSize: 100,
      sortAttribute: 'date',
      sortDirection: 'ASC',
    },
    new AbortController()
  );

  if (response.type === 'success') {
    return response.data;
  }

  throw new Error('Could not fetch all runsheet data');
}

const fetchAllRunsheetPages = async (runsheetQueryHref: string) => {
  const firstPageData = await fetchRunsheetPage(runsheetQueryHref, 1);
  const totalCount = firstPageData.totalCount;
  const pageSize = firstPageData.pageSize;
  const totalPages = Math.ceil(totalCount / pageSize);

  if (totalPages <= 1) {
    return firstPageData.results;
  }

  const remainingPageRequests = [];
  for (let page = 2; page <= totalPages; page++) {
    remainingPageRequests.push(fetchRunsheetPage(runsheetQueryHref, page));
  }

  const remainingPageData = await Promise.all(remainingPageRequests);
  const allData = [
    firstPageData.results,
    ...remainingPageData.map((el) => el.results),
  ].flat();

  return allData;
};

const createRunsheetAtom = (runsheetQueryHref: string | undefined) => {
  const baseAtom = atom<RunsheetGraphDataPoints | undefined>(undefined);

  const fetchRunsheetAtom = atom<
    RunsheetGraphDataPoints | undefined,
    [undefined],
    void
  >(
    (get) => {
      return get(baseAtom);
    },
    async (_get, set) => {
      if (!runsheetQueryHref) return;
      const documentInterpretationResponses = await fetchAllRunsheetPages(
        runsheetQueryHref
      );
      const points = extractRunsheetData(documentInterpretationResponses);
      set(baseAtom, points);
    }
  );
  fetchRunsheetAtom.onMount = (set) => {
    set(undefined);
  };

  return fetchRunsheetAtom;
};

export type GraphEntityType =
  | 'no-transactions-in'
  | 'transactions-in-out'
  | 'no-transactions-out';

const graphEntitiesAtom = atom<{
  type: GraphEntityType | null;
  entities: GraphEntity[];
}>({
  type: null,
  entities: [],
});

const nodeClickedAtom = atom<boolean>(false);

export { createRunsheetAtom, graphEntitiesAtom, nodeClickedAtom };
