import { InlineLoading } from 'carbon-components-react';

import style from './calculation-loading.module.scss';

export function CalculationLoading() {
  return (
    <>
      <InlineLoading
        role="status"
        aria-label="Loading..."
        description="Loading..."
        className={style.inlineLoading}
      />
    </>
  );
}
