import {
  InlineLoading,
  InlineNotification,
  SkeletonText,
} from 'carbon-components-react';
import { EventsPanel, EventsPanelOpenButton } from 'components/events-panel';
import { Well as WellIcon } from 'components/icons/well';
import { PageHeader } from 'components/page-header';
import { useAtomValue } from 'jotai';
import { useLocation } from 'react-router-dom';
import { useWellAtom } from './atoms';
import { WellsResponse } from 'types/api-responses';
import {
  WellHeaderContent,
  WellPageContent,
} from 'components/well-page-content';

const Header = ({ well }: { well: WellsResponse | undefined }) => {
  const { pathname } = useLocation();
  return (
    <PageHeader
      breadcrumbs={[
        ['/wells', 'Wells'],
        [pathname, well?.uwi || <SkeletonText width="200px" />],
      ]}
      title={well?.uwi || <SkeletonText heading width="200px" />}
      subtitle="UWI"
      iconComponent={<WellIcon medium hasBackground={false} />}
      actions={
        <>
          <EventsPanelOpenButton />
        </>
      }
    >
      {well ? <WellHeaderContent well={well} /> : null}
    </PageHeader>
  );
};

const EventsContent = ({ well }: { well: WellsResponse }) => {
  return (
    <EventsPanel
      header={well.name}
      title="Well"
      helperText="Context allows you to share information about a well."
      eventsHref={well.resources.events.href}
      commentsHref={well.resources.comments.href}
    />
  );
};

const ShowWellPage = () => {
  const { pathname } = useLocation();
  const wellAtom = useWellAtom(pathname);
  const well = useAtomValue(wellAtom);

  if (well.data && 'error' in well.data)
    return (
      <InlineNotification kind="error" title={well.data.error} lowContrast />
    );

  return (
    <>
      <Header well={well.data} />
      {well.data ? <WellPageContent well={well.data} /> : <InlineLoading />}
      {well.data ? <EventsContent well={well.data} /> : null}
    </>
  );
};

export { ShowWellPage };
