import {
  SkeletonText,
  SkeletonPlaceholder,
  DataTableSkeleton,
  TabsSkeleton,
  BreadcrumbSkeleton,
} from 'carbon-components-react';
import style from './scenario_skeleton.module.scss';

const rows = 10;

export function ScenarioSkeleton() {
  return (
    <div className={style.scenarioSkeleton}>
      <header>
        <div className={style.breadcrumbs}>
          <BreadcrumbSkeleton />
        </div>
        <div className={style.title}>
          <SkeletonText heading width="180px" />
        </div>
      </header>

      <div className={style.container}>
        <div className={style.leftCol}>
          {[...Array(rows)].map((_el, i) => (
            <div key={`skeleton-row-${i}`} className={style.titleLines}>
              <SkeletonText width="130px" />
              <SkeletonPlaceholder className={style.iconPlaceholder} />
            </div>
          ))}
          <div className={style.addForm}>
            <SkeletonText width="180px" />
            <SkeletonText width="80px" />
          </div>
        </div>
        <div className={style.rightCol}>
          <div className={style.topHeader}>
            <SkeletonText heading width="180px" />
            <SkeletonPlaceholder className={style.iconButton} />
          </div>
          <div className={style.formHeader}>
            <SkeletonText width="120px" />
            <SkeletonText heading />
          </div>
          <TabsSkeleton />
          <DataTableSkeleton
            showHeader={false}
            showToolbar={false}
            columnCount={3}
          />
        </div>
      </div>
    </div>
  );
}
