import { atom, PrimitiveAtom, WritableAtom } from 'jotai';
import request from 'lib/request';

export type ResourceCacheAtomPayload<Response> =
  | {
      href: string;
      force?: boolean;
      data?: Response;
      abortController?: AbortController;
    }
  | { resetCache: true };

export type CacheMapEntry<Response> = {
  loading: boolean;
  data: Response | { error: string } | undefined;
};

export type ResourceCacheMapAtom<Response> = WritableAtom<
  Record<string, CacheMapEntry<Response> | undefined>,
  [ResourceCacheAtomPayload<Response>],
  void
>;

export type CacheMapAtom<Response> = PrimitiveAtom<
  Record<string, CacheMapEntry<Response>>
>;

const createResourceCacheMapAtom = <
  Response
>(): ResourceCacheMapAtom<Response> => {
  const mapBaseAtom = atom<Record<string, CacheMapEntry<Response> | undefined>>(
    {}
  );
  const mapAtom = atom<
    Record<string, CacheMapEntry<Response> | undefined>,
    [ResourceCacheAtomPayload<Response>],
    void
  >(
    (get) => get(mapBaseAtom),
    async (get, set, payload) => {
      if ('resetCache' in payload) {
        set(mapBaseAtom, {});
        return;
      }

      if (payload.data) {
        set(mapBaseAtom, (current) => ({
          ...current,
          [payload.href]: { loading: false, data: payload.data },
        }));
        return;
      }

      const href = payload.href;
      if (!href) {
        return;
      }
      const detail = get(mapBaseAtom)[payload.href];
      if (!detail || payload.force) {
        set(mapBaseAtom, (current) => ({
          ...current,
          [payload.href]: { loading: true, data: undefined },
        }));
        const response = await request.get<Response>(
          href,
          payload.abortController
        );

        set(mapBaseAtom, (current) => ({
          ...current,
          [payload.href]: {
            loading: false,
            data: response,
          },
        }));
      }
    }
  );
  return mapAtom;
};

const createSingleCacheEntryAtom =
  <T>(cache: ResourceCacheMapAtom<T>) =>
  (href: string) => {
    const singleEntryAtom = atom<
      CacheMapEntry<T>,
      [ResourceCacheAtomPayload<T>],
      void
    >(
      (get) => {
        return get(cache)[href] || { loading: true, data: undefined };
      },
      (_get, set, payload) => {
        set(cache, { ...payload });
      }
    );
    singleEntryAtom.onMount = (set) => {
      set({ href });
    };
    return singleEntryAtom;
  };

// refetchOptions: If a resource is refetched, by default we clear the old data,
// but we allow clients to configure this behavior if what they want is to keep arround
// the old data while the new data is fetched.
const createResourceAtom = <Response>(
  href: string | undefined,
  refetchOptions:
    | 'keep-old-data-on-refetch'
    | 'clear-old-data-on-refetch' = 'clear-old-data-on-refetch'
) => {
  const innerAtom = atom<{
    loading: boolean;
    data: Response | { error: string } | undefined;
  }>({ loading: true, data: undefined });
  const abortController = new AbortController();
  const resourceAtom = atom(
    (get) => get(innerAtom),
    async (get, set) => {
      if (!href) return;
      set(innerAtom, {
        loading: true,
        data:
          refetchOptions === 'keep-old-data-on-refetch'
            ? get(innerAtom).data
            : undefined,
      });
      const data = await request.get<Response>(href, abortController);
      set(innerAtom, { loading: false, data });
    }
  );
  resourceAtom.onMount = (set) => {
    set();
    return () => abortController.abort();
  };

  return resourceAtom;
};

export {
  createResourceCacheMapAtom,
  createResourceAtom,
  createSingleCacheEntryAtom,
};
