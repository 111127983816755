import {
  createResourceFormAtom,
  FormErrors,
  FormFieldState,
  FormRowStatus,
  getValue,
  hasChangedFields,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { atom, Getter, Setter } from 'jotai';
import { ApiPayloads } from 'types/api-payloads';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
  WellsQueryResponse,
} from 'types/api-responses';
import {
  ConveyanceGranteeSubform,
  ConveyanceTransactionErrors,
  ConveyanceTransactionForm,
  ConveyanceType,
  DocumentReferenceSubform,
  EntitySubform,
  MineralLeaseTransactionForm,
  MineralTransactionErrors,
  OrriGranteeSubform,
  OverridingRoyaltyErrors,
  OverridingRoyaltyTransactionForm,
  SurfaceLeaseTransactionForm,
  SurfaceTransactionErrors,
  TransactionForm,
  WellReferenceSubform,
  LeaseManagementSubform,
} from './types';
import {
  leaseManagementSubform,
  initLeaseExtensionSurveySubform,
} from './lease-management/utils';

export type TransactionFormAtoms = ReturnType<typeof createFormAtom>;
export type EntitySubformAtoms = ReturnType<typeof entitySubform>;
export type DocumentReferenceSubformAtoms = ReturnType<
  typeof documentReferenceSubform
>;
export type WellReferenceSubformAtoms = ReturnType<typeof wellReferenceSubform>;
export type ConveyanceGranteeSubformAtoms = ReturnType<
  typeof conveyanceGranteeSubform
>;
export type OrriGranteeSubformAtoms = ReturnType<
  typeof createOrriConveyanceAtoms
>;

const entitySubform = (defaultValue: EntitySubform, status: FormRowStatus) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      EntitySubform,
      { errors: Record<string, unknown> }
    >(defaultValue),
  };
};

const conveyanceGranteeSubform = (
  defaultValue: ConveyanceGranteeSubform,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      ConveyanceGranteeSubform,
      FormErrors<ConveyanceGranteeSubform>
    >(defaultValue),
  };
};

const orriGranteeSubform = (
  defaultValue: OrriGranteeSubform,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      OrriGranteeSubform,
      { errors: Record<string, unknown> }
    >(defaultValue),
  };
};

const documentReferenceSubform = (
  defaultValue: DocumentReferenceSubform,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      DocumentReferenceSubform,
      { errors: Record<string, unknown> }
    >(defaultValue),
  };
};

const wellReferenceSubform = (
  defaultValue: WellReferenceSubform,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      WellReferenceSubform,
      { errors: Record<string, unknown> }
    >(defaultValue),
  };
};

const createFormAtom = (
  defaultValue: TransactionForm,
  status: FormRowStatus
) => {
  switch (defaultValue.type) {
    case 'Transaction::MineralLeaseTransaction':
      return {
        type: defaultValue.type,
        status: atom(status),
        row: createResourceFormAtom<
          MineralLeaseTransactionForm,
          MineralTransactionErrors
        >(defaultValue.form),
      };
    case 'Transaction::SurfaceLeaseTransaction':
      return {
        type: defaultValue.type,
        status: atom(status),
        row: createResourceFormAtom<
          SurfaceLeaseTransactionForm,
          SurfaceTransactionErrors
        >(defaultValue.form),
      };
    case 'Transaction::OverridingRoyalty':
      return {
        type: defaultValue.type,
        status: atom(status),
        row: createResourceFormAtom<
          OverridingRoyaltyTransactionForm,
          OverridingRoyaltyErrors
        >(defaultValue.form),
      };
    case 'Transaction::ConveyanceTransaction':
      return {
        type: defaultValue.type,
        status: atom(status),
        row: createResourceFormAtom<
          ConveyanceTransactionForm,
          ConveyanceTransactionErrors
        >(defaultValue.form),
      };
  }
};

type MineralLeaseResponse = Extract<
  DocumentInterpretationResponse['transactions'][0],
  { type: 'Transaction::MineralLeaseTransaction' }
>;
const createEntityAtoms = (
  response:
    | MineralLeaseResponse['grantors'][0]
    | MineralLeaseResponse['conveyances'][0],
  state: FormFieldState
) => {
  return entitySubform(
    initializeForm<EntitySubform>(
      { id: response.id, entity: response.entity },
      state
    ),
    rowStatus(state)
  );
};

type ConveyanceResponse = Extract<
  DocumentInterpretationResponse['transactions'][0],
  { type: 'Transaction::ConveyanceTransaction' }
>;
const createConveyanceAtoms = (
  response: ConveyanceResponse['conveyances'][0],
  state: FormFieldState
) => {
  return conveyanceGranteeSubform(
    initializeForm<ConveyanceGranteeSubform>(
      {
        id: response.id,
        entity: response.entity,
        surfaceLeaseMultiplier: response.surfaceLeaseMultiplier ?? null,
        fixed: response.fixed ?? false,
        consolidated: {
          surface: response.surfaceMultiplier ?? null,
          mineral: response.mineralMultiplier ?? null,
          executive: response.executiveMultiplier ?? null,
          bonus: response.bonusMultiplier ?? null,
          delay: response.delayMultiplier ?? null,
          royalty: response.royaltyMultiplier ?? null,
        },
        workingOrri: {
          orri: response.overridingMultiplier ?? null,
          working: response.workingMultiplier ?? null,
        },
      },
      state
    ),
    rowStatus(state)
  );
};

type OrriConveyanceResponse = Extract<
  DocumentInterpretationResponse['transactions'][0],
  { type: 'Transaction::OverridingRoyalty' }
>;
const createOrriConveyanceAtoms = (
  response: OrriConveyanceResponse['conveyances'][0],
  state: FormFieldState
) => {
  return orriGranteeSubform(
    initializeForm<OrriGranteeSubform>(
      {
        id: response.id,
        entity: response.entity,
        multiplier: response.multiplier,
      },
      state
    ),
    rowStatus(state)
  );
};

type OverridingRoyaltyResponse = Extract<
  DocumentInterpretationResponse['transactions'][0],
  { type: 'Transaction::OverridingRoyalty' }
>;
const createDocumentReferencesAtoms = (
  response: OverridingRoyaltyResponse['transactionDocumentReferences'][0],
  state: FormFieldState
) => {
  return documentReferenceSubform(
    initializeForm<DocumentReferenceSubform>(
      {
        id: response.id,
        document: response.document,
      },
      state
    ),
    rowStatus(state)
  );
};

type ConveyanceTransactionResponse = Extract<
  DocumentInterpretationResponse['transactions'][0],
  { type: 'Transaction::ConveyanceTransaction' }
>;
const createWellboreReferencesAtoms = (
  response: ConveyanceTransactionResponse['transactionWellReferences'][0],
  state: FormFieldState
) => {
  return wellReferenceSubform(
    initializeForm<WellReferenceSubform>(
      {
        id: response.id,
        well: response.well,
        referenceType: response.referenceType,
      },
      state
    ),
    rowStatus(state)
  );
};

/**
 * Type that represents a transaction as get back from the
 * interpretation, or, just the type and no other field for
 * the case when we are creating a new empty transaction.
 */
type TransactionValue = Pick<
  DocumentInterpretationResponse['transactions'][0],
  'type' | 'sequence'
> &
  Partial<Exclude<DocumentInterpretationResponse['transactions'][0], 'type'>>;

const formDefault = (
  initialValue: TransactionValue,
  state: FormFieldState
): TransactionForm => {
  switch (initialValue.type) {
    case 'Transaction::MineralLeaseTransaction':
      return {
        type: initialValue.type,
        form: initializeForm<MineralLeaseTransactionForm>(
          {
            id: initialValue.id,
            sequence: initialValue.sequence,
            lessor: initialValue.grantors?.map((el) =>
              createEntityAtoms(el, state)
            ) || [initEntityEmptyForm()],
            lessee: initialValue.conveyances?.map((el) =>
              createEntityAtoms(el, state)
            ) || [initEntityEmptyForm()],
            leaseId: initialValue.mineralLease?.id,
            term: initialValue.mineralLease?.term || null,
            extension: initialValue.mineralLease?.extension || null,
            royaltyRate: initialValue.mineralLease?.royaltyRate || null,
            expirationDate: initialValue.mineralLease?.expirationDate || null,
            heldByProduction:
              initialValue.mineralLease?.heldByProduction || null,
            leaseManagement: leaseManagementSubform(
              initializeForm<LeaseManagementSubform>(
                {
                  retainedLand: initialValue.mineralLease?.heldByProduction
                    ? 'all'
                    : !initialValue.mineralLease?.heldByProduction &&
                      initialValue.leaseExtensions?.length
                    ? 'partial'
                    : 'none',
                  leaseExtension:
                    (initialValue.mineralLease?.hbpReason ||
                      initialValue.leaseExtensions?.[0]?.extensionReason) ??
                    'held_by_production',
                  locationReferences: atom(
                    initLeaseExtensionSurveySubform(
                      initialValue.leaseExtensions ?? [],
                      state
                    )
                  ),
                },
                state
              )
            ),
          },
          state
        ),
      };
    case 'Transaction::SurfaceLeaseTransaction':
      return {
        type: initialValue.type,
        form: initializeForm<SurfaceLeaseTransactionForm>(
          {
            id: initialValue.id,
            sequence: initialValue.sequence,
            lessor: initialValue.grantors?.map((el) =>
              createEntityAtoms(el, state)
            ) || [initEntityEmptyForm()],
            lessee: initialValue.conveyances?.map((el) =>
              createEntityAtoms(el, state)
            ) || [initEntityEmptyForm()],
            term: initialValue.surfaceLease?.term || null,
            extension: initialValue.surfaceLease?.extension || null,
            expirationDate: initialValue.surfaceLease?.expirationDate || null,
            leaseId: initialValue.surfaceLease?.id,
          },
          state
        ),
      };
    case 'Transaction::OverridingRoyalty':
      return {
        type: initialValue.type,
        form: initializeForm<OverridingRoyaltyTransactionForm>(
          {
            id: initialValue.id,
            sequence: initialValue.sequence,
            ofType: initialValue.ofType,
            grantor: initialValue.grantors?.map((el) =>
              createEntityAtoms(el, state)
            ) || [initEntityEmptyForm()],
            grantee: initialValue.conveyances?.map((el) =>
              createOrriConveyanceAtoms(el, state)
            ) || [initConveyanceOrriEmptyForm()],
            documentReference: initialValue.transactionDocumentReferences?.map(
              (el) => createDocumentReferencesAtoms(el, state)
            ) || [initDocumentReferencesEmptyForm()],
            allLeases: !initialValue.transactionDocumentReferences?.length,
            wellReference: atom(
              initialValue.transactionWellReferences?.map((el) =>
                createWellboreReferencesAtoms(el, state)
              ) || []
            ),
          },
          state
        ),
      };
    case 'Transaction::ConveyanceTransaction':
      return {
        type: initialValue.type,
        form: initializeForm<ConveyanceTransactionForm>(
          {
            conveyanceType:
              (initialValue.conveyanceType as ConveyanceType) || 'consolidated',
            id: initialValue.id,
            sequence: initialValue.sequence,
            ofType: initialValue.ofType || 'interest',
            unburdened: initialValue.unburdened || false,
            grantor: initialValue.grantors?.map((el) =>
              createEntityAtoms(el, state)
            ) || [initEntityEmptyForm()],
            grantee: initialValue.conveyances?.map((el) =>
              createConveyanceAtoms(el, state)
            ) || [initConveyanceGranteeEmptyForm()],
            documentReference: initialValue.transactionDocumentReferences?.map(
              (el) => createDocumentReferencesAtoms(el, state)
            ) || [initDocumentReferencesEmptyForm()],
            allInterest: !initialValue.transactionDocumentReferences?.length,
            wellReference: atom(
              initialValue.transactionWellReferences?.map((el) =>
                createWellboreReferencesAtoms(el, state)
              ) || []
            ),
          },
          state
        ),
      };
  }
};

const initForm = (
  transactions: DocumentInterpretationResponse['transactions'] | undefined,
  state: FormFieldState
) => {
  if (!transactions) {
    return [];
  }
  return transactions.map((transaction) =>
    createFormAtom(formDefault(transaction, state), rowStatus(state))
  );
};

const initEmptyForm = (
  type: DocumentInterpretationResponse['transactions'][0]['type'],
  sequence: DocumentInterpretationResponse['transactions'][0]['sequence']
) => {
  return createFormAtom(formDefault({ type, sequence }, 'updated'), 'new');
};

const initEntityEmptyForm = () => {
  return entitySubform(
    initializeForm<EntitySubform>({ id: undefined, entity: null }, 'updated'),
    'new'
  );
};

const initConveyanceGranteeEmptyForm = () => {
  return conveyanceGranteeSubform(
    initializeForm<ConveyanceGranteeSubform>(
      {
        id: undefined,
        entity: null,
        surfaceLeaseMultiplier: null,
        fixed: false,
        workingOrri: { working: null, orri: null },
        consolidated: {
          surface: null,
          mineral: null,
          delay: null,
          royalty: null,
          executive: null,
          bonus: null,
        },
      },
      'updated'
    ),
    'new'
  );
};

const initConveyanceOrriEmptyForm = () => {
  return orriGranteeSubform(
    initializeForm<OrriGranteeSubform>(
      {
        id: undefined,
        entity: null,
        multiplier: null,
      },
      'updated'
    ),
    'new'
  );
};

const initDocumentReferencesEmptyForm = (doc?: DocumentResponse) => {
  return documentReferenceSubform(
    initializeForm<DocumentReferenceSubform>(
      {
        id: undefined,
        document: doc,
      },
      'updated'
    ),
    'new'
  );
};

const initWellReferencesEmptyForm = (well?: WellsQueryResponse) => {
  return wellReferenceSubform(
    initializeForm<WellReferenceSubform>(
      {
        id: undefined,
        well: well,
        referenceType: null,
      },
      'updated'
    ),
    'new'
  );
};

const validate = (atoms: TransactionFormAtoms, get: Getter, set: Setter) => {
  let hasError = false;
  const transactionType = atoms.type;

  const status = get(atoms.status);
  if (status === 'trash') return;

  if (transactionType === 'Transaction::MineralLeaseTransaction') {
    const errorObj: MineralTransactionErrors = { errors: {} };
    const form = get(atoms.row.formAtom);
    if (!form.term.value) {
      errorObj.errors.term = 'Term is mandatory';
      hasError = true;
    }

    if (!form.royaltyRate.value) {
      errorObj.errors.royaltyRate = 'Royalty rate is mandatory';
      hasError = true;
    }

    form.lessor.value.map((el) => {
      const row = get(el.row.formAtom);
      if (!row.entity.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Lessor is mandatory' },
        });
        hasError = true;
      }
    });

    form.lessee.value.map((el) => {
      const row = get(el.row.formAtom);
      if (!row.entity.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Lessee is mandatory' },
        });
        hasError = true;
      }
    });

    set(atoms.row.dirtyFieldsAtom, errorObj);
    return hasError;
  }

  if (transactionType === 'Transaction::SurfaceLeaseTransaction') {
    const errorObj: MineralTransactionErrors = { errors: {} };
    const form = get(atoms.row.formAtom);
    if (!form.term.value) {
      errorObj.errors.term = 'Term is mandatory';
      hasError = true;
    }
    form.lessor.value.map((el) => {
      const row = get(el.row.formAtom);
      if (!row.entity.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Lessor is mandatory' },
        });
        hasError = true;
      }
    });

    form.lessee.value.map((el) => {
      const row = get(el.row.formAtom);
      if (!row.entity.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Lessee is mandatory' },
        });
        hasError = true;
      }
    });

    set(atoms.row.dirtyFieldsAtom, errorObj);
    return hasError;
  }

  if (transactionType === 'Transaction::OverridingRoyalty') {
    const errorObj: OverridingRoyaltyErrors = { errors: {} };
    const form = get(atoms.row.formAtom);
    form.grantor.value.map((el) => {
      const row = get(el.row.formAtom);
      if (!row.entity.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Grantor is mandatory' },
        });
        hasError = true;
      }
    });

    form.grantee.value.map((el) => {
      const rowStatus = get(el.status);
      const row = get(el.row.formAtom);
      if (!row.entity.value && rowStatus !== 'trash') {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Grantee is mandatory' },
        });
        hasError = true;
      }

      if (!row.multiplier.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { multiplier: 'ORRI interest is mandatory' },
        });
        hasError = true;
      }
    });

    get(form.wellReference.value).map((el) => {
      const row = get(el.row.formAtom);
      if (!row.referenceType.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { referenceType: 'Wellbore is mandatory' },
        });
        hasError = true;
      }
    });

    if (!form.ofType.value) {
      errorObj.errors.ofType = 'Type is mandatory';
      hasError = true;
    }

    set(atoms.row.dirtyFieldsAtom, errorObj);
    return hasError;
  }

  if (transactionType === 'Transaction::ConveyanceTransaction') {
    const errorObj: ConveyanceTransactionErrors = { errors: {} };
    const form = get(atoms.row.formAtom);
    const conveyanceType = form.conveyanceType.value;

    form.grantor.value.map((el) => {
      const row = get(el.row.formAtom);
      if (!row.entity.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Grantor is mandatory' },
        });
        hasError = true;
      }
    });

    form.grantee.value.map((el) => {
      const rowStatus = get(el.status);
      const row = get(el.row.formAtom);
      if (!row.entity.value && rowStatus !== 'trash') {
        set(el.row.dirtyFieldsAtom, {
          errors: { entity: 'Grantee is mandatory' },
        });
        hasError = true;
      }

      if (conveyanceType === 'consolidated') {
        const value = row.consolidated.value;

        if (
          !value.surface &&
          !value.mineral &&
          !value.executive &&
          !value.bonus &&
          !value.royalty &&
          !value.delay
        ) {
          set(el.row.dirtyFieldsAtom, {
            errors: {
              consolidated: 'At least one interest value is required',
            },
          });
          hasError = true;
        }
      }

      if (conveyanceType === 'mineral_lease_assignment') {
        if (!row.workingOrri.value.working && !row.workingOrri.value.orri) {
          set(el.row.dirtyFieldsAtom, {
            errors: {
              workingOrri: 'At least one interest value is required',
            },
          });
          hasError = true;
        }
      }

      if (conveyanceType === 'surface_lease_assignment') {
        if (!row.surfaceLeaseMultiplier.value) {
          set(el.row.dirtyFieldsAtom, {
            errors: {
              surfaceLeaseMultiplier: 'Surface lease interest is required',
            },
          });
          hasError = true;
        }
      }
    });

    get(form.wellReference.value).map((el) => {
      const row = get(el.row.formAtom);
      if (!row.referenceType.value) {
        set(el.row.dirtyFieldsAtom, {
          errors: { referenceType: 'Wellbore is mandatory' },
        });
        hasError = true;
      }
    });

    set(atoms.row.dirtyFieldsAtom, errorObj);
    return hasError;
  }

  return hasError;
};

const grantorsAttribute = (el: EntitySubformAtoms, get: Getter) => {
  const rowStatus = get(el.status);
  const row = get(el.row.formAtom);
  if (rowStatus === 'trash') {
    return { _destroy: true, id: row.id.value };
  }
  return { entityId: row.entity.value?.id, id: row.id.value };
};

const orriGranteeAttribute = (el: OrriGranteeSubformAtoms, get: Getter) => {
  const rowStatus = get(el.status);
  const row = get(el.row.formAtom);
  if (rowStatus === 'trash') {
    return { _destroy: true, id: row.id.value };
  }
  return {
    granteeId: row.entity.value?.id,
    id: row.id.value,
    multiplier: row.multiplier.value,
  };
};

const granteesAttribute = (
  el: ConveyanceGranteeSubformAtoms,
  conveyanceType: ConveyanceType,
  ofType: string | undefined,
  get: Getter
) => {
  const rowStatus = get(el.status);
  const row = get(el.row.formAtom);
  if (rowStatus === 'trash') {
    return { _destroy: true, id: row.id.value };
  }

  return {
    granteeId: row.entity.value?.id,
    id: row.id.value,
    ...(conveyanceType === 'consolidated'
      ? {
          surfaceMultiplier: getValue(row.consolidated)?.surface,
          mineralMultiplier: getValue(row.consolidated)?.mineral,
          executiveMultiplier: getValue(row.consolidated)?.executive,
          bonusMultiplier: getValue(row.consolidated)?.bonus,
          delayMultiplier: getValue(row.consolidated)?.delay,
          royaltyMultiplier: getValue(row.consolidated)?.royalty,
          fixed:
            ofType === '8/8'
              ? getValue(row.consolidated)?.royalty
                ? row.fixed.value
                : getValue(row.fixed)
              : undefined,
        }
      : {}),
    ...(conveyanceType === 'mineral_lease_assignment'
      ? {
          workingMultiplier: getValue(row.workingOrri)?.working,
          overridingMultiplier: getValue(row.workingOrri)?.orri,
        }
      : {}),
    ...(conveyanceType === 'surface_lease_assignment'
      ? {
          surfaceLeaseMultiplier: getValue(row.surfaceLeaseMultiplier),
        }
      : {}),
  };
};

const conveyancesAttribute = (el: EntitySubformAtoms, get: Getter) => {
  const rowStatus = get(el.status);
  const row = get(el.row.formAtom);
  if (rowStatus === 'trash') {
    return { _destroy: true, id: row.id.value };
  }
  return {
    granteeId: row.entity.value?.id,
    multiplier: 1,
    id: row.id.value,
  };
};

const leaseExtensionAttributes = (
  form: LeaseManagementSubform,
  get: Getter
) => {
  const isPartialLand = form.retainedLand.value === 'partial';

  const leases = get(form.locationReferences.value).map((el) => {
    const status = get(el.status);
    const row = get(el.row.formAtom);
    const id = row.id.value;

    switch (status) {
      case 'new':
      case 'duplicated':
        if (!isPartialLand) {
          return undefined;
        }
        if (row.referenceLocation.value) {
          return {
            referenceLocationId: row.referenceLocation.value.id,
            extensionReason: form.leaseExtension.value,
            quarterCalls: row.quarterCalls.value,
            startDepthInFeet: row.startDepthInFeet.value,
            endDepthInFeet: row.endDepthInFeet.value,
          };
        }
        break;

      case 'saved':
        if (!isPartialLand && row.referenceLocation.value && id) {
          return { id: id, _destroy: true };
        }
        if (isPartialLand && row.referenceLocation.value) {
          return {
            id: id,
            referenceLocationId: row.referenceLocation.value.id,
            extensionReason: form.leaseExtension.value,
            quarterCalls: row.quarterCalls.value,
            startDepthInFeet: row.startDepthInFeet.value,
            endDepthInFeet: row.endDepthInFeet.value,
          };
        }
        break;

      case 'trash':
        if (id) return { id, _destroy: true };
        break;

      default:
        return undefined;
    }
  });

  return leases.flatMap((el) => (el ? [el] : []));
};

const documentReferencesAttributes = (
  el: DocumentReferenceSubformAtoms,
  get: Getter,
  form: OverridingRoyaltyTransactionForm | ConveyanceTransactionForm
) => {
  const status = get(el.status);
  const row = get(el.row.formAtom);
  const id = row.id.value;
  let checked = false;
  if ('allInterest' in form) {
    checked = form.allInterest.value;
  } else {
    checked = form.allLeases.value;
  }

  const documentHasValue = !!row.document.value;

  switch (status) {
    case 'new':
    case 'duplicated':
      if (checked) {
        return [];
      }
      if (row.document.value) {
        return { documentId: row.document.value.id };
      }
      break;

    case 'saved':
      if (checked && documentHasValue && id) {
        return { id: id, _destroy: true };
      }
      break;

    case 'trash':
      if (id) return { id, _destroy: true };
      break;

    default:
      return [];
  }
  return [];
};

const wellReferencesAttributes = (
  el: WellReferenceSubformAtoms,
  get: Getter
) => {
  const status = get(el.status);
  const row = get(el.row.formAtom);

  if (status === 'trash') {
    return { _destroy: true, id: row.id.value };
  }
  return {
    wellUwi: row.well.value?.uwi,
    referenceType: row.referenceType.value,
    id: row.id.value,
  };
};

const getPayload = (
  atoms: TransactionFormAtoms,
  get: Getter
):
  | Required<
      ApiPayloads['documentInterpretation']['payload']
    >['transactionsAttributes'][0]
  | undefined => {
  const transactionType = atoms.type;
  const rowStatus = get(atoms.status);

  if (transactionType === 'Transaction::MineralLeaseTransaction') {
    const form = get(atoms.row.formAtom);

    if (rowStatus === 'trash') {
      return { _destroy: true, id: form.id.value };
    }

    if (!hasPendingChangesMineralTrx(form, get)) return;

    const { term, extension, royaltyRate, sequence, leaseId } = form;
    if (!term.value || !royaltyRate.value) return undefined;

    const leaseManagementForm = get(form.leaseManagement.value.formAtom);

    const heldByProduction = leaseManagementForm.retainedLand.value === 'all';

    return {
      type: transactionType,
      id: form.id.value,
      sequence: sequence.value,
      mineralLeaseAttributes: {
        id: leaseId.value,
        term: term.value.toString(),
        extension: extension.value?.toString(),
        royaltyRate: royaltyRate.value,
        expirationDate: form.expirationDate.value,
        heldByProduction: heldByProduction,
        hbpReason: heldByProduction
          ? leaseManagementForm.leaseExtension.value
          : null,
      },
      grantorsAttributes: form.lessor.value.map((el) =>
        grantorsAttribute(el, get)
      ),
      conveyancesAttributes: form.lessee.value.map((el) =>
        conveyancesAttribute(el, get)
      ),
      leaseExtensionsAttributes: leaseExtensionAttributes(
        leaseManagementForm,
        get
      ),
    };
  }

  if (transactionType === 'Transaction::SurfaceLeaseTransaction') {
    const form = get(atoms.row.formAtom);

    if (rowStatus === 'trash') {
      return { _destroy: true, id: form.id.value };
    }

    if (!hasPendingChangesSurfaceTrx(form, get)) return;

    const { term, extension, sequence, leaseId } = form;
    if (!term.value) return undefined;

    return {
      type: transactionType,
      id: form.id.value,
      sequence: sequence.value,
      surfaceLeaseAttributes: {
        id: leaseId.value,
        term: term.value.toString(),
        extension: extension.value?.toString(),
        expirationDate: form.expirationDate.value,
      },
      grantorsAttributes: form.lessor.value.map((el) =>
        grantorsAttribute(el, get)
      ),
      conveyancesAttributes: form.lessee.value.map((el) =>
        conveyancesAttribute(el, get)
      ),
    };
  }

  if (transactionType === 'Transaction::OverridingRoyalty') {
    const form = get(atoms.row.formAtom);

    if (rowStatus === 'trash') {
      return { _destroy: true, id: form.id.value };
    }

    if (!hasPendingChangesOverridingRoyalty(form, get)) return;

    if (!form.ofType.value) return;
    return {
      type: transactionType,
      id: form.id.value,
      sequence: form.sequence.value,
      ofType: form.ofType.value,
      grantorsAttributes: form.grantor.value.map((el) =>
        grantorsAttribute(el, get)
      ),
      conveyancesAttributes: form.grantee.value.map((el) =>
        orriGranteeAttribute(el, get)
      ),
      transactionDocumentReferencesAttributes:
        form.documentReference.value.flatMap((el) =>
          documentReferencesAttributes(el, get, form)
        ),
      transactionWellReferencesAttributes: get(
        form.wellReference.value
      ).flatMap((el) => wellReferencesAttributes(el, get)),
    };
  }

  if (transactionType === 'Transaction::ConveyanceTransaction') {
    const form = get(atoms.row.formAtom);

    if (rowStatus === 'trash') {
      return { _destroy: true, id: form.id.value };
    }

    if (!hasPendingChangesConveyanceTrx(form, get)) return;

    return {
      type: transactionType,
      unburdened: getValue(form.unburdened),
      id: form.id.value,
      sequence: form.sequence.value,
      ofType: getValue(form.ofType),
      conveyanceType: getValue(form.conveyanceType),
      grantorsAttributes: form.grantor.value.map((el) =>
        grantorsAttribute(el, get)
      ),
      conveyancesAttributes: form.grantee.value.map((el) =>
        granteesAttribute(el, form.conveyanceType.value, form.ofType.value, get)
      ),
      transactionDocumentReferencesAttributes:
        form.documentReference.value.flatMap((el) =>
          documentReferencesAttributes(el, get, form)
        ),
      transactionWellReferencesAttributes: get(
        form.wellReference.value
      ).flatMap((el) => wellReferencesAttributes(el, get)),
    };
  }

  return undefined;
};

const hasPendingChangesSurfaceTrx = (
  form: SurfaceLeaseTransactionForm,
  get: Getter
) => {
  return [
    hasChangedFields(form),
    form.lessee.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
    form.lessor.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
  ].some((el) => el);
};

const hasPendingChangesMineralTrx = (
  form: MineralLeaseTransactionForm,
  get: Getter
) => {
  return [
    hasPendingChangesSurfaceTrx(form, get),
    hasChangedFields(get(form.leaseManagement.value.formAtom)),
    get(get(form.leaseManagement.value.formAtom).locationReferences.value).some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
  ].some((el) => el);
};

const hasPendingChangesOverridingRoyalty = (
  form: OverridingRoyaltyTransactionForm,
  get: Getter
) => {
  return [
    hasChangedFields(form),
    form.grantor.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
    form.grantee.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
    form.documentReference.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
    get(form.wellReference.value).some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
  ].some((el) => el);
};

const hasPendingChangesConveyanceTrx = (
  form: ConveyanceTransactionForm,
  get: Getter
) => {
  return [
    hasChangedFields(form),
    form.grantor.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
    form.grantee.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
    form.documentReference.value.some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
    get(form.wellReference.value).some(
      (el) =>
        hasChangedFields(get(el.row.formAtom)) || get(el.status) === 'trash'
    ),
  ].some((el) => el);
};

const isMineralBreakout = (form: ConveyanceGranteeSubform) => {
  const value = form.consolidated.value;

  return [value.bonus, value.delay, value.executive, value.royalty].some(
    (el) => el
  );
};

export {
  getPayload,
  initConveyanceGranteeEmptyForm,
  initConveyanceOrriEmptyForm,
  initDocumentReferencesEmptyForm,
  initEmptyForm,
  initEntityEmptyForm,
  initWellReferencesEmptyForm,
  initForm,
  validate,
  isMineralBreakout,
  leaseExtensionAttributes,
};
