import {
  createResourceFormAtom,
  FormFieldState,
  FormRowStatus,
  hasChangedFields,
  initializeForm,
  rowStatus,
} from 'atoms/create-resource-atom';
import { atom, Getter } from 'jotai';
import { ApiPayloads } from 'types/api-payloads';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';
import {
  DocumentReferenceForm,
  DocumentReferenceFormErrors,
  FormInitialValues,
} from './types';

export type DocumentReferenceAtoms = ReturnType<typeof createFormAtom>;

const createFormAtom = (
  defaultValue: DocumentReferenceForm,
  status: FormRowStatus
) => {
  return {
    status: atom(status),
    row: createResourceFormAtom<
      DocumentReferenceForm,
      DocumentReferenceFormErrors
    >(defaultValue),
  };
};

const formDefault = (
  { id, document, referenceType }: FormInitialValues,
  state: FormFieldState
) =>
  initializeForm<DocumentReferenceForm>(
    {
      id,
      document,
      referenceType,
    },
    state
  );

const initForm = (
  references: DocumentInterpretationResponse['documentReferences'] | undefined,
  state: FormFieldState
) => {
  if (!references) {
    return [];
  }
  return references.map((doc) =>
    createFormAtom(
      formDefault(
        {
          id: doc.id,
          document: doc.document,
          referenceType: doc.referenceType,
        },
        state
      ),
      rowStatus(state)
    )
  );
};

const initEmptyForm = (document?: DocumentResponse) => {
  return createFormAtom(
    formDefault(
      {
        id: undefined,
        document: document,
        referenceType: '',
      },
      'updated'
    ),
    'new'
  );
};

const getPayload = (
  atoms: DocumentReferenceAtoms,
  get: Getter
):
  | Required<
      ApiPayloads['documentInterpretation']['payload']
    >['documentReferencesAttributes'][0]
  | undefined => {
  const form = get(atoms.row.formAtom);
  const rowStatus = get(atoms.status);

  if (rowStatus === 'trash' && form.id.value) {
    return { _destroy: true, id: form.id.value };
  }

  if (!hasChangedFields(form)) return;

  if (!form.document.value) return;

  return {
    documentId: form.document.value.id,
    referenceType:
      form.referenceType.value !== '' ? form.referenceType.value : undefined,
    id: form.id.value,
  };
};

export { initEmptyForm, initForm, getPayload };
