import {
  DocumentSearchParams,
  SetSearchPayload,
} from 'components/documents-search/types';
import request from 'lib/request';
import { uniqBy } from 'lodash';
import {
  Map,
  Point as MapPoint,
  MapLayerMouseEvent,
  CirclePaint,
} from 'mapbox-gl';
import { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Point, ReferenceLocationBucket } from 'types/api-responses';
import WebMercatorViewport from 'viewport-mercator-project';
import { PopupState } from './types';

const getNewReferenceLocationIds = (
  newId: number,
  current?: DocumentSearchParams
): number[] => {
  const currentIds = current?.referenceLocationIds || [];
  return [...new Set([...currentIds, newId])];
};

const onReferenceLocationClick = (
  e: MapLayerMouseEvent,
  searchPayload: DocumentSearchParams,
  setSearchPayload: SetSearchPayload
) => {
  const validSelection = getPolygonLayerIds(e.target, e.point);
  if (!validSelection.length) return;

  const newId = Number(validSelection[0]);
  if (isReferenceLocationSelected(searchPayload, newId)) return;

  setSearchPayload((current) => ({
    ...current,
    referenceLocationIds: getNewReferenceLocationIds(newId, current),
  }));
};

const areReferenceLocationBucketsEqual = (
  current?: ReferenceLocationBucket[],
  newCollection?: ReferenceLocationBucket[]
) => {
  if (current?.length === newCollection?.length) {
    return (
      current?.every((element, index) => {
        if (element.id === newCollection?.[index].id) {
          return true;
        }

        return false;
      }) ?? false
    );
  }
  return false;
};

const useCalculateMapDimensions = ({
  points,
  containerRef,
}: {
  points?: [Point, Point];
  containerRef: React.MutableRefObject<null>;
}) => {
  const { height, width } = useResizeDetector({
    targetRef: containerRef,
    handleWidth: true,
    handleHeight: true,
    refreshMode: 'debounce',
    refreshRate: 0,
  });

  const mercator = new WebMercatorViewport({
    height: height ?? 999,
    width: width ?? 999,
  });

  return mercator.fitBounds(
    points ?? [
      [0, 0],
      [0, 0],
    ],
    {
      padding: 20,
    }
  );
};

const isReferenceLocationSelected = (
  searchPayload: DocumentSearchParams,
  id: number
): boolean => {
  return Boolean(searchPayload?.referenceLocationIds?.includes(id));
};

interface HoverLocationProps {
  e: MapLayerMouseEvent;
  setPopup: React.Dispatch<PopupState>;
}

const getLayer = (
  map: Map,
  point: MapPoint,
  layer:
    | 'development-area-fill'
    | 'basin-fill'
    | 'tier-fill'
    | 'package-assets-fill'
) => {
  return map.getLayer(layer)
    ? map.queryRenderedFeatures(point, {
        layers: [layer],
      })
    : [];
};

const getPolygonLayerIds = (map: Map, point: MapPoint) => {
  return map
    .queryRenderedFeatures(point)
    .filter((el) => el.layer.id.startsWith('polygon-fill-'))
    .flatMap((el) => (typeof el.id === 'number' ? [el.id] : []));
};

const getDevAreas = (map: Map, point: MapPoint) => {
  const devAreaFeatures = getLayer(map, point, 'development-area-fill');
  const developmentAreasData: { name: string; evalId: number }[] =
    devAreaFeatures.map((el) => ({
      name: el.properties?.name,
      evalId: el.properties?.id,
    }));
  return uniqBy(developmentAreasData, 'name');
};

const getWells = (map: Map, point: MapPoint) => {
  return map
    .queryRenderedFeatures(point)
    .filter((el) => el.layer.id.startsWith('wells-'));
};

const getAreaHref = (name: string, evalId?: number) => {
  return evalId
    ? `/development_areas/${name}/evaluations/${evalId}`
    : `/development_areas/${name}`;
};

const setPopupState = ({ e, setPopup }: HoverLocationProps) => {
  const map = e.target;
  const developmentAreas = getDevAreas(map, e.point);
  const basinLayer = getLayer(map, e.point, 'basin-fill');
  const tierLayer = getLayer(map, e.point, 'tier-fill');
  const polygonIds = getPolygonLayerIds(map, e.point);
  const wells = getWells(map, e.point);

  const baseState = {
    longitude: e.lngLat.lng,
    latitude: e.lngLat.lat,
    visible: true,
  };

  const basinTiersNames: string[] = basinLayer
    .concat(tierLayer)
    .map((el) => el.properties?.tier || el.properties?.gis_basin);

  if (wells?.length) {
    const wellsData = wells.reduce((acc, el) => {
      const wellId: string | undefined = el.properties?.uwi;
      if (wellId) {
        acc[wellId] = undefined;
      }
      return acc;
    }, {} as Record<string, undefined>);
    setPopup({
      ...baseState,
      data: { type: 'wells', value: wellsData },
    });
    return;
  }

  if (polygonIds.length) {
    setPopup({
      ...baseState,
      data: { type: 'reference-locations', value: polygonIds },
    });
    return;
  }

  if (developmentAreas?.length) {
    setPopup({
      ...baseState,
      data: {
        type: 'development-areas',
        value: {
          devAreas: developmentAreas,
          basinTier: basinTiersNames,
        },
      },
    });
    return;
  }

  if (basinLayer.length || tierLayer.length) {
    setPopup({
      ...baseState,
      data: {
        type: 'basin-tier',
        value: basinTiersNames,
      },
    });
    return;
  }

  setPopup({ latitude: 0, longitude: 0, visible: false });
};

const isLocationHovered = (state: PopupState, locationId: number) => {
  if (state.data?.type === 'reference-locations') {
    return state.data.value.includes(locationId);
  }
  return false;
};

const usePopup = () => {
  return useState<PopupState>({
    latitude: 0,
    longitude: 0,
    visible: false,
    pinned: false,
  });
};

const getQuarteredGeometry = async (
  referenceLocationId: number,
  quarterCalls: string[],
  href: string,
  abortController: AbortController
) => {
  return await request.post<
    { geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon },
    unknown
  >(
    href,
    {
      referenceLocationId,
      quarterCalls,
    },
    abortController
  );
};

const circlePaint = (color: string): CirclePaint => {
  return {
    'circle-color': color,
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      5,
      1, // At zoom level 5, circle radius is 1
      10,
      2, // At zoom level 10, circle radius is 2
      15,
      4, // At zoom level 15, circle radius is 4
    ],
  };
};

const textSizeInterpolate: mapboxgl.Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  4, // At zoom level 5, text size is 4
  10,
  8, // At zoom level 10, text size is 8
  15,
  16, // At zoom level 15, text size is 16
];

export {
  areReferenceLocationBucketsEqual,
  circlePaint,
  getAreaHref,
  getDevAreas,
  getLayer,
  getQuarteredGeometry,
  getWells,
  isLocationHovered,
  isReferenceLocationSelected,
  onReferenceLocationClick,
  setPopupState,
  textSizeInterpolate,
  useCalculateMapDimensions,
  usePopup,
};
