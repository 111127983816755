import { Grid, Row, Tile } from 'carbon-components-react';
import { EmptyState } from 'components/empty-state';
import {
  itemRangeText,
  pageRangeText,
} from 'components/search/common/format-helpers';
import style from './runsheet.module.scss';
import DocumentSvg from 'images/srp-illustration-document.svg';
import { Skeleton } from './runsheet_skeleton';
import { Pagination } from 'components/pagination';
import { InterpretationsModalCallback } from 'components/interpretation-search/interpretation-search-modal';
import { InterpretationRow } from 'components/interpretation-search/interpretations-search';
import { InterpretationSearchAtomValue, OnPaginationChange } from './types';
import { WritableAtom } from 'jotai';
import { useRef, useState } from 'react';
import { useCreateEditedRowsAtomsMap } from './atoms';

interface RunsheetProps {
  searchAtomValue: InterpretationSearchAtomValue;
  actions?: InterpretationsModalCallback;
  onPaginationChange: OnPaginationChange;
  expandedRowsAtom: WritableAtom<{ open: boolean }, [{ open: boolean }], void>;
  titleWorkspace?: number;
  referenceLocationHref?: string;
  openRowInNewTab?: boolean;
  titleWorkspaceHref?: string;
  lastCalculationHref?: string;
  showEditInterpretationButton?: boolean;
}

export const Runsheet = ({
  searchAtomValue,
  actions,
  onPaginationChange,
  expandedRowsAtom,
  titleWorkspace,
  referenceLocationHref,
  openRowInNewTab,
  titleWorkspaceHref,
  lastCalculationHref,
  showEditInterpretationButton = false,
}: RunsheetProps) => {
  const searchResult = searchAtomValue;
  const refsMap = useRef<Map<number, HTMLElement | null | undefined>>(
    new Map()
  ).current;

  const editedRowsMapAtoms = useCreateEditedRowsAtomsMap(
    (searchResult.data?.results || []).length
  );

  const highlightedRowState = useState<
    { current: number; previous: number } | undefined
  >(undefined);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onPaginationChangeGoToTop: OnPaginationChange = ({
    page,
    pageSize,
  }) => {
    onPaginationChange({ page, pageSize });

    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollIntoView();
    }
  };

  return (
    <div ref={wrapperRef} className={style.wrapperRef}>
      <Grid condensed fullWidth className={style.runsheetTable}>
        {searchResult?.data?.results?.length ? (
          <Row className={style.paginationTop}>
            <Pagination
              pageSizes={[30, 60, 100, 250, 500]}
              pageSize={searchResult.data.pageSize}
              page={searchResult.data.page}
              totalItems={searchResult.data.totalCount}
              onChange={onPaginationChangeGoToTop}
              itemRangeText={itemRangeText}
              pageRangeText={pageRangeText}
              size="sm"
            />
          </Row>
        ) : null}
        {searchResult.loading && <Skeleton elementCount={10} />}
        {!searchResult.loading &&
          searchResult?.data?.results?.map(
            (documentInterpretationResponse, index) => (
              <InterpretationRow
                key={documentInterpretationResponse.id}
                rows={searchResult.data?.results || []}
                rowIndex={index}
                editedRowsMapAtoms={editedRowsMapAtoms}
                actions={actions}
                expandedRowsAtom={expandedRowsAtom}
                titleWorkspace={titleWorkspace}
                referenceLocationHref={referenceLocationHref}
                openRowInNewTab={openRowInNewTab}
                titleWorkspaceHref={titleWorkspaceHref}
                refsMap={refsMap}
                highlightedRowState={highlightedRowState}
                lastCalculationHref={lastCalculationHref}
                showEditInterpretationButton={showEditInterpretationButton}
              />
            )
          )}
        {searchResult?.data?.results.length === 0 && (
          <Tile>
            <EmptyState
              headerText="No Data Available"
              helperText="Currently Insights doesn’t have any available data."
              icon={DocumentSvg}
            />
          </Tile>
        )}
        {searchResult?.data?.results?.length ? (
          <Row className={style.gridBottomRow}>
            <Pagination
              pageSizes={[30, 60, 100, 250, 500]}
              pageSize={searchResult.data.pageSize}
              page={searchResult.data.page}
              totalItems={searchResult.data.totalCount}
              onChange={onPaginationChangeGoToTop}
              itemRangeText={itemRangeText}
              pageRangeText={pageRangeText}
            />
          </Row>
        ) : null}
      </Grid>
    </div>
  );
};
