import { Column, Grid, Row } from 'carbon-components-react';
import { FormRow } from 'components/forms';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';
import { DeleteRowButton } from '../delete-row-button';
import { DocumentReferenceSubformAtoms } from './types';

import style from './transaction.module.scss';

interface Props {
  atoms: DocumentReferenceSubformAtoms;
  atomIdx: number;
  trxRowDisabled: boolean;
  onDelete?: (atomIdx: number) => void;
}

const DocumentReferenceFormRow = ({
  atomIdx,
  onDelete,
  atoms,
  trxRowDisabled,
}: Props) => {
  const fields = useAtomValue(atoms.row.formAtom);
  const status = useAtomValue(atoms.status);
  const disabled = trxRowDisabled || status === 'trash';

  if (!fields.document.value) {
    return null;
  }

  const { volume, page, instrumentNumber } = fields.document.value;
  const docName =
    volume && page && instrumentNumber
      ? `Vol ${volume} Pg ${page} · ${instrumentNumber}`
      : volume && page
      ? `Vol ${volume} Pg ${page}`
      : instrumentNumber
      ? `Unknown · ${instrumentNumber}`
      : `Unknown`;

  return (
    <FormRow toTrash={disabled}>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column sm={2} md={3} lg={5} className={style.centerLink}>
            <Link target="_blank" to={fields.document.value.href}>
              {docName}
            </Link>
          </Column>
          <Column sm={1} md={1} lg={1}>
            <DeleteRowButton
              statusAtom={atoms.status}
              atomIdx={atomIdx}
              onDelete={onDelete}
              disabled={disabled}
              adjustForLabel={false}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

export { DocumentReferenceFormRow };
