import React from 'react';
import ReactDOM from 'react-dom';
import { start as activeStorageStart } from '@rails/activestorage';
import '../lib/datadog';
import request from 'lib/request';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { App } from 'pages/app-shell/app';

const csrfMetaTag = document.querySelector('meta[name=csrf-token]');
const csrfToken = csrfMetaTag?.content;
request.setCSRF(csrfToken);
activeStorageStart();

const mswEnabled = import.meta.env.VITE_ENABLE_MSW;

const el = document.querySelector('[title-chaining-stage]');
const history = createBrowserHistory();

async function enableMocking() {
  if (!mswEnabled) {
    return;
  }

  const { worker } = await import('../mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() => {
  ReactDOM.render(
    React.createElement(
      Router,
      { history: history },
      React.createElement(App, { csrfToken: csrfToken })
    ),
    el
  );
});
