import {
  Accordion,
  AccordionItem,
  Button,
  InlineNotification,
  Tile,
} from 'carbon-components-react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { FC, SetStateAction, useEffect } from 'react';
import { Buttons } from 'components/buttons';
import { useHistory, useLocation } from 'react-router-dom';

import { FormSection } from 'components/forms';
import { Response } from 'lib/request/types';

import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';
import { SupersedingDate } from './superseding-date';
import { ApplicableAreaList } from './applicable-area';
import {
  interpretationAtom,
  formAtoms,
  interpretationHrefAtom,
  documentAtom,
  saveAtom,
  refetchInterpretationAtom,
  interpretationsAtom,
  pendingChangesAtom,
  interpretationDuplicateAtom,
} from './atoms';
import { LegalDescription } from './legal-description';
import { CapacityList } from './capacity';
import { TransactionList } from './transaction';
import { TextEntities } from './text-entity';
import {
  getTextEntitiesAtom,
  granteeAtoms,
  grantorAtoms,
} from './text-entity/atoms';
import { CurativeRequirementList } from './curative-requirement';
import { DocumentReferenceList } from './document-reference';
import { NavigationGuard } from 'components/navigation-guard';

import style from './interpretation-form.module.scss';
import { DateTime } from './date-time';
import { padStart } from 'lodash';
import { InterpretationNavigationButtons } from './interpretation-navigation-buttons';
import { DestroyInterpretation } from './destroy-interpretation';
import { collectedErrorsAtom } from 'atoms/create-resource-atom';
import { InterpretationSkeleton } from './interpretation_skeleton';
import { AdditionalInformation } from './additional-information';
import { HeaderCheckMark } from './review-section-checkbox/header-checkmark';
import { InterpretationReviewCount } from 'components/interpretation-review-count';
import { addSearchParamToReferrer } from 'pages/documents/common/utils';
import { ProvideRootResource } from 'components/hydrate-atoms';

const saveNotificationText = (
  result: Response<unknown, { errors: { [key: string]: string | string[] } }>
) => {
  if (result.type === 'success') {
    return 'Your interpretation has been saved';
  } else if (result.type === 'error') {
    const errors = Object.keys(result.data?.errors || {})
      .flatMap((el) => (result.data?.errors ? result.data?.errors[el] : []))
      .join(', ');
    return `Something went wrong: ${errors}`;
  } else {
    return `Fatal error: ${result.message}`;
  }
};

type Role =
  | {
      type: 'create';
      href: string;
    }
  | {
      type: 'update';
      href: string;
    };
interface Props {
  interpretationIds: number[];
  role: Role;
  refetchInterpretations: () => void;
  onSuccess?: () => void;
  setPendingChanges?: (update: SetStateAction<boolean>) => void;
  documentHeaderAttributes?: JSX.Element;
}

const navigationGuardAtom = atom(true);
const Form = ({
  role,
  interpretationIds,
  refetchInterpretations,
  onSuccess,
  setPendingChanges,
  documentHeaderAttributes,
}: Props) => {
  const clientErrors = useAtomValue(collectedErrorsAtom);
  const mutationResult = useAtomValue(saveAtom);
  const pendingChanges = useAtomValue(pendingChangesAtom);
  const mutationOk = mutationResult.result?.type === 'success';
  const interpretationReq = useAtomValue(interpretationAtom);
  const allowNavigationGuard = useAtomValue(navigationGuardAtom);
  const interpretationDuplicate = useAtomValue(interpretationDuplicateAtom);

  useEffect(() => {
    if (!pendingChanges) return () => {};

    if (setPendingChanges) {
      setPendingChanges(pendingChanges);
    }

    const beforeUnloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = 'Reload site?';
      return event.returnValue;
    };

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, [pendingChanges, setPendingChanges]);

  const history = useHistory();

  const interpretation =
    interpretationReq.state === 'hasData' ? interpretationReq.data : undefined;

  if (interpretation && 'error' in interpretation)
    return (
      <InlineNotification
        kind={'error'}
        title={interpretation.error}
        lowContrast
      />
    );

  const idx = interpretationIds.findIndex((el) => el === interpretation?.id);

  // If no index, this is a new interpretation
  const index = idx === -1 ? interpretationIds.length : idx;

  const UnsavedLabel: FC = ({ children }) => (
    <span className={style.unsavedLabel}>{children}</span>
  );
  return (
    <>
      <InterpretationNavigationButtons
        documentHeaderAttributes={documentHeaderAttributes}
      />
      <div className={style.relativeForm}>
        <NavigationGuard
          hasChanges={pendingChanges && allowNavigationGuard}
          message="Are you sure you want to discard this interpretation? All changes will be lost."
        />
        <header className={style.header}>
          <div className={style.headerInfo}>
            <h2 className={style.headerTitle}>
              {padStart(String(index + 1), 2, '0')}
            </h2>
            {interpretationDuplicate ? (
              <UnsavedLabel>Duplicate (Unsaved)</UnsavedLabel>
            ) : role.type === 'create' ? (
              <UnsavedLabel>(Unsaved)</UnsavedLabel>
            ) : null}
            <span className={style.divider}></span>
            <InterpretationReviewCount
              reviewAttributes={interpretation?.reviewAttributes}
            />
            {interpretation?.reviewAttributes && (
              <span className={style.reviewInfo}>Form Sections Reviewed</span>
            )}
          </div>
          <aside className={style.additionalInfo}>
            <>
              {interpretation?.lastSavedBy && (
                <address>{interpretation?.lastSavedBy.email}</address>
              )}
              <DateTime dateTime={interpretation?.lastSavedAt} />
            </>
          </aside>
        </header>
        <Tile className={style.formBlock}>
          <FormSection
            id="applicable-areas"
            title="Applicable Areas"
            headerElements={<HeaderCheckMark field="applicableAreasReviewed" />}
            className={style.anchorSection}
          >
            <LegalDescription />
            <ApplicableAreaList />
          </FormSection>
        </Tile>
        <Tile className={style.formBlock}>
          <FormSection
            id="document-references"
            title="Document References"
            className={style.anchorSection}
            headerElements={
              <HeaderCheckMark field="documentReferencesReviewed" />
            }
          >
            <DocumentReferenceList />
          </FormSection>
        </Tile>
        <Tile className={style.formBlock}>
          <FormSection
            id="transactions"
            title="Transactions"
            className={style.anchorSection}
            headerElements={
              <>
                <HeaderCheckMark field="transactionsReviewed" />
                <span className={style.divider}></span>
                <SupersedingDate />
              </>
            }
          >
            <TransactionList />
          </FormSection>
        </Tile>
        <Tile className={style.formBlock}>
          <FormSection
            id="capacities"
            title="Capacities"
            className={style.anchorSection}
            headerElements={<HeaderCheckMark field="capacitiesReviewed" />}
          >
            <CapacityList />
          </FormSection>
        </Tile>
        <Tile className={style.formBlock}>
          <FormSection
            id="curative-requirements"
            title="Curative Requirements"
            className={style.anchorSection}
            headerElements={
              <HeaderCheckMark field="curativeRequirementsReviewed" />
            }
          >
            <CurativeRequirementList />
          </FormSection>
        </Tile>
        <Tile className={style.formBlock}>
          <FormSection
            id="additional-information"
            title="Additional Information"
            className={style.anchorSection}
            headerElements={
              <HeaderCheckMark field="additionalInformationReviewed" />
            }
          >
            <AdditionalInformation />
          </FormSection>
        </Tile>
        <Tile className={style.formBlock}>
          {interpretation?.transactions.length ? (
            <div className={style.textAccordion}>
              <Accordion align="end">
                <AccordionItem title="Text Based Entities" open={true}>
                  <TextEntities />
                </AccordionItem>
              </Accordion>
            </div>
          ) : (
            <TextEntities />
          )}
        </Tile>
        <div className={style.saveRow}>
          <Buttons justify="spaceBetween">
            <SaveButton
              role={role}
              refetchInterpretations={refetchInterpretations}
              onSuccess={onSuccess}
            />
            <DestroyInterpretation
              refetchInterpretations={refetchInterpretations}
            />
          </Buttons>
          {pendingChanges && (
            <InlineNotification
              kind="info"
              lowContrast
              title="Unsaved changes"
              hideCloseButton={true}
            />
          )}
          {(history.location.search.includes('saved=1') ||
            history.location.search.includes('updated=1')) &&
            !pendingChanges &&
            !mutationResult.result && (
              <InlineNotification
                kind={'success'}
                lowContrast
                title={'Success!'}
                subtitle={'Interpretation has been saved'}
              />
            )}
          {mutationResult.result ? (
            <InlineNotification
              kind={mutationOk ? 'success' : 'error'}
              lowContrast
              title={mutationOk ? 'Success!' : 'Error!'}
              subtitle={saveNotificationText(mutationResult.result)}
            />
          ) : null}
          {clientErrors.length ? (
            <InlineNotification
              kind={'error'}
              lowContrast
              title={'Error!'}
              subtitle={clientErrors.join(', ')}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const dirtyFieldsAtom = selectAtom(formAtoms, (atoms) => atoms.dirtyFieldsAtom);
const SaveButton = ({
  role,
  refetchInterpretations,
  onSuccess,
}: {
  role: Role;
  refetchInterpretations: () => void;
  onSuccess?: () => void;
}) => {
  const history = useHistory();
  const location = useLocation();
  const isNewRecord = role.type === 'create';

  const setCollectedErrors = useSetAtom(collectedErrorsAtom);
  const setErrors = useSetAtom(useAtomValue(dirtyFieldsAtom));
  const refetchInterpretation = useSetAtom(refetchInterpretationAtom);
  const [mutation, runMutation] = useAtom(saveAtom);
  const getTextEntities = useSetAtom(getTextEntitiesAtom);
  const setNavigationGuard = useSetAtom(navigationGuardAtom);

  return (
    <Button
      type="submit"
      disabled={mutation.loading}
      onClick={() => {
        setCollectedErrors([]);
        runMutation({
          type: isNewRecord ? 'POST' : 'PATCH',
          url: role.href,
          onSuccess: (data) => {
            if (onSuccess) {
              onSuccess();
              return;
            }

            setNavigationGuard(false);
            if (isNewRecord) {
              history.push(
                `${data.href}?${addSearchParamToReferrer(
                  location.search,
                  'saved',
                  '1'
                )}`
              );
            } else {
              refetchInterpretation();
              history.replace(
                `${role.href}?${addSearchParamToReferrer(
                  location.search,
                  'updated',
                  '1'
                )}`
              );
            }
            refetchInterpretations();
            getTextEntities({
              entityAtom: grantorAtoms.entitiesAtom,
              type: 'grantors',
            });
            getTextEntities({
              entityAtom: granteeAtoms.entitiesAtom,
              type: 'grantees',
            });
            setNavigationGuard(true);
          },
          onError: (error) => {
            if (error) {
              setErrors(error);
            }
          },
        });
      }}
    >
      Save
    </Button>
  );
};

const LoadInterpretation = ({ children }: { children: JSX.Element }) => {
  const interpretationRequest = useAtomValue(interpretationAtom);
  if (interpretationRequest.state === 'loading')
    return <InterpretationSkeleton />;

  if (interpretationRequest.state === 'hasData') return children;

  return null;
};

interface FormProps {
  document: DocumentResponse;
  interpretations: DocumentInterpretationResponse[];
  role: Role;
  refetchInterpretations: () => void;
  onSuccess?: () => void;
  setPendingChanges?: (update: SetStateAction<boolean>) => void;
  documentHeaderAttributes?: JSX.Element;
}

const InterpretationForm = ({
  role,
  document,
  interpretations,
  refetchInterpretations,
  onSuccess,
  setPendingChanges,
  documentHeaderAttributes,
}: FormProps) => {
  const { location } = useHistory();
  const interpretationHref = role.type === 'update' ? role.href : undefined;
  const interpretationDuplicate = useAtomValue(interpretationDuplicateAtom);
  return (
    <ProvideRootResource
      initialValues={[
        [collectedErrorsAtom, []],
        [
          interpretationDuplicateAtom,
          location.search.includes('duplicateId')
            ? interpretationDuplicate
            : null,
        ],
        [interpretationHrefAtom, interpretationHref],
        [documentAtom, document],
        [interpretationsAtom, interpretations],
        [
          grantorAtoms.entitiesAtom,
          document.grantors
            .map((el) => ({
              entityId: el.id,
              name: el.name,
            }))
            .concat(
              (interpretationDuplicate?.grantorReferences || []).map((el) => ({
                entityId: el.grantorId,
                name: el.name,
              }))
            ),
        ],
        [
          granteeAtoms.entitiesAtom,
          document.grantees
            .map((el) => ({
              entityId: el.id,
              name: el.name,
            }))
            .concat(
              (interpretationDuplicate?.granteeReferences || []).map((el) => ({
                entityId: el.granteeId,
                name: el.name,
              }))
            ),
        ],
      ]}
    >
      <LoadInterpretation>
        <Form
          role={role}
          interpretationIds={interpretations.map((i) => i.id)}
          refetchInterpretations={refetchInterpretations}
          onSuccess={onSuccess}
          setPendingChanges={setPendingChanges}
          documentHeaderAttributes={documentHeaderAttributes}
        />
      </LoadInterpretation>
    </ProvideRootResource>
  );
};

export { InterpretationForm };
