import { memo, useMemo } from 'react';
import { Column, ComboBox, Grid, Row, TextArea } from 'carbon-components-react';
import { useAtom, useAtomValue } from 'jotai';

import { FormRow } from 'components/forms';
import { initEmptyForm } from './utils';

import type {
  CurativeRequirementAtoms,
  CurativeRequirementForm as CurativeRequirementFormType,
} from './types';

import { DeleteRowButton } from '../delete-row-button';
import { FormRowManager } from '../form-row-manager';

import statuses from 'enums/curative_requirement_statuses.json';
import priorities from 'enums/curative_requirement_priorities.json';

import style from './curative-requirement.module.scss';
import { getError } from 'atoms/create-resource-atom';
import { ReviewSectionCheckbox } from '../review-section-checkbox';
import { createUpdateTrackerAtom } from '../atoms';

type Statuses = keyof typeof statuses;
type Priorities = keyof typeof priorities;

interface Props {
  atomIdx: number;
  atoms: CurativeRequirementAtoms;
  onDelete?: (idx: number) => void;
}

const CurativeRequirementForm = ({ atomIdx, onDelete, atoms }: Props) => {
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const status = useAtomValue(atoms.status);

  return (
    <FormRow toTrash={status === 'trash'}>
      <Grid className="bx--no-gutter" fullWidth>
        <Row>
          <Column sm={2} md={4} lg={8}>
            <ComboBox
              light
              titleText="Status"
              placeholder=""
              onChange={({ selectedItem }) => {
                const key = Object.keys(statuses).find(
                  (key) => statuses[key as Statuses] === selectedItem
                );
                setField({ field: 'status', value: key ?? undefined });
              }}
              id={`${atomIdx}-status`}
              items={Object.values(statuses)}
              type="default"
              disabled={status === 'trash'}
              selectedItem={
                fields.status.value
                  ? statuses[fields.status.value as Statuses]
                  : null
              }
              invalid={
                !!getError<CurativeRequirementFormType>(fields, 'status')
              }
              invalidText={getError<CurativeRequirementFormType>(
                fields,
                'status'
              )}
            />
          </Column>
          <Column sm={2} md={3} lg={7}>
            <ComboBox
              light
              titleText="Priority"
              placeholder=""
              onChange={({ selectedItem }) => {
                const key = Object.keys(priorities).find(
                  (key) => priorities[key as Priorities] === selectedItem
                );
                setField({ field: 'priority', value: key ?? undefined });
              }}
              id={`${atomIdx}-priority`}
              items={Object.values(priorities)}
              type="default"
              disabled={status === 'trash'}
              selectedItem={
                fields.status.value
                  ? priorities[fields.priority.value as Priorities]
                  : null
              }
              invalid={
                !!getError<CurativeRequirementFormType>(fields, 'priority')
              }
              invalidText={getError<CurativeRequirementFormType>(
                fields,
                'priority'
              )}
            />
          </Column>
        </Row>
        <Row className={style.secondRow}>
          <Column sm={3} md={7} lg={15}>
            <FormRow>
              <TextArea
                invalid={
                  !!getError<CurativeRequirementFormType>(fields, 'description')
                }
                invalidText={getError<CurativeRequirementFormType>(
                  fields,
                  'description'
                )}
                disabled={status === 'trash'}
                labelText="Description"
                placeholder="Text Field"
                light
                id={`curative-requirement-notes-${atomIdx}`}
                value={fields.description.value}
                onChange={(event) =>
                  setField({
                    field: 'description',
                    value: event.target.value,
                  })
                }
              />
            </FormRow>
          </Column>
          <Column sm={1} md={1} lg={1}>
            <DeleteRowButton
              statusAtom={atoms.status}
              atomIdx={atomIdx}
              onDelete={onDelete}
            />
          </Column>
        </Row>
      </Grid>
    </FormRow>
  );
};

const CurativeRequirementMemo = memo(CurativeRequirementForm);

type ManagedFormType = {
  field: 'curativeRequirements';
  value: CurativeRequirementAtoms[];
};
const CurativeRequirementList = () => {
  const curativeRequirementsUpdateAtom = useMemo(
    () => createUpdateTrackerAtom('curativeRequirements'),
    []
  );
  return (
    <>
      <FormRowManager<ManagedFormType>
        MemoedComponent={CurativeRequirementMemo}
        field={'curativeRequirements'}
        label="Add Curative Requirement"
        initEmptyForm={initEmptyForm}
      />
      <ReviewSectionCheckbox
        updateTrackingAtom={curativeRequirementsUpdateAtom}
        field={'curativeRequirementsReviewed'}
        label="Curative Requirements Reviewed"
        helpMessage="Editing any curative requirements will change the review status to un reviewed"
      />
    </>
  );
};

export { CurativeRequirementList };
