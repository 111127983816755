import { standardMapStyles } from 'components/map/map-styles';
import { PrimitiveAtom, useAtomValue } from 'jotai';
import { buildPolygonStyle } from 'lib/map';
import { SymbolLayout } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';
import { developmentAreaLayerAtom } from './atoms';
import { mapColors } from 'components/map/map-colors';

const STYLE = buildPolygonStyle({
  color: mapColors.scopedUnitsFill,
  lineColor: mapColors.scopedUnitsBorder,
});

interface Props {
  source: string;
  layerAtom?: PrimitiveAtom<boolean>;
}

const textLayout = {
  'text-field': [
    'format',
    ['upcase', ['get', 'name']],
    {
      'font-scale': 1,
      'text-font': ['literal', standardMapStyles[0].fonts.CONDENSED_BOLD],
    },
    '\n',
    {},
  ],
  'text-justify': 'left',
  'text-size': {
    base: 1,
    stops: [
      [12, 8],
      [18, 24],
    ],
  },
} as SymbolLayout;

const ScopedPackageDevelopmentAreasLayer = ({ source, layerAtom }: Props) => {
  const devAreasVisible = useAtomValue(layerAtom || developmentAreaLayerAtom);
  return (
    <Source id="package-development-areas-layer" type="vector" url={source}>
      {devAreasVisible && (
        <Layer
          id="development-area-fill-scoped-package"
          type="fill"
          paint={STYLE.fill}
          source-layer="package-development-areas"
        />
      )}
      <Layer
        id="development-area-fill-scoped-package-isolated"
        type="fill"
        paint={STYLE.fill}
        source-layer="package-development-areas"
        filter={['in', 'name', '']}
      />
      <Layer
        id="development-area-line-scoped-package-isolated"
        type="line"
        paint={STYLE.line}
        source-layer="package-development-areas"
        filter={['in', 'name', '']}
      />
      <Layer
        id="development-area-fill-scoped-package-isolated-highlighted-line"
        type="line"
        paint={STYLE.lineHighlighted}
        source-layer="package-development-areas"
        filter={['in', 'name', '']}
      />
      {devAreasVisible && (
        <Layer
          id="development-area-line-scoped-package"
          type="line"
          paint={STYLE.line}
          source-layer="package-development-areas"
        />
      )}
      {devAreasVisible && (
        <Layer
          id="development-area-symbol-scoped-package"
          type="symbol"
          source-layer="package-development-areas"
          filter={['==', 'type', 'centroid']}
          layout={textLayout}
        />
      )}
    </Source>
  );
};

export { ScopedPackageDevelopmentAreasLayer };
