import { AtomizedForm, getFormValuesAtom } from 'atoms/form-atoms';
import { atom } from 'jotai';
import { createResourceCacheMapAtom } from 'lib/atoms';
import request from 'lib/request';
import {
  EvalDefaultAssumptions,
  DevelopmentAreaEvaluationResponse,
  EvalSlotSchedule,
} from 'types/evaluation-api/responses';
import { EvaluationForm } from './types';
import { evaluationForm, getEvaluationPayload } from './utils';
import { isEqual } from 'lodash';
import { AddUndefinedToArray } from 'types/utils';

type EvalError = { errors: { output: string[] } };

export type EvaluationConfigAtomValue =
  | {
      loading: true;
    }
  | {
      loading: false;
      error?: string;
      evaluation: DevelopmentAreaEvaluationResponse | undefined;
    };

type EvaluationAtom =
  | {
      loading: true;
    }
  | {
      loading: false;
      error?: string;
      saved?: boolean;
    };

const slotScheduleMapAtom = createResourceCacheMapAtom<EvalSlotSchedule[]>();
const defaultAssumptionsMapAtom =
  createResourceCacheMapAtom<EvalDefaultAssumptions>();

const defaultAssumptionsBaseAtom = atom<EvalDefaultAssumptions | undefined>(
  undefined
);
const defaultAssumptionsAtom = atom<
  EvalDefaultAssumptions | undefined,
  [string],
  void
>(
  (get) => get(defaultAssumptionsBaseAtom),
  async (get, set, href) => {
    const assumptions = await request.get<EvalDefaultAssumptions>(href);
    if (!assumptions || 'error' in assumptions) return null;
    set(defaultAssumptionsBaseAtom, assumptions);
  }
);

const evaluationFormAtom = atom((get) => {
  const assumptions = get(defaultAssumptionsAtom);
  if (!assumptions) return;
  return evaluationForm(assumptions);
});

type WellsToExclude =
  EvaluationForm['drillingAssumptionAttributes']['wellsToExclude'];

const wellsToSet = (wellsToExclude: WellsToExclude) => {
  return new Set(
    wellsToExclude.map((item) => item.formationName + '|' + item.slotNumber)
  );
};

const wellsAreDifferent = (array1: WellsToExclude, array2: WellsToExclude) => {
  const set1 = wellsToSet(array1);
  const set2 = wellsToSet(array2);

  if (set1.size !== set2.size) {
    return true;
  }

  for (const item of set1) {
    if (!set2.has(item)) {
      return true;
    }
  }

  return false;
};

const dirtyEvaluationAtom = (form: AtomizedForm<EvaluationForm> | undefined) =>
  atom((get) => {
    if (!form) return;
    const formAtom = atom(form);
    const formValuesAtom = getFormValuesAtom(formAtom, 'all-values');
    const next = get(formValuesAtom);
    const current = get(currentFormSnapshotAtom);

    if (!current) return false;

    const {
      drillingAssumptionAttributes: nextDrilling,
      cashflowAssumptionAttributes: nextCashflow,
    } = next;
    const {
      drillingAssumptionAttributes: currentDrilling,
      cashflowAssumptionAttributes: currentCashflow,
    } = current;

    const { wellsToExclude: wellsA, ...restNextDrilling } = nextDrilling;
    const { wellsToExclude: wellsB, ...restCurrentDrilling } = currentDrilling;

    return (
      !isEqual(
        { cashflow: nextCashflow, drilling: restNextDrilling },
        { cashflow: currentCashflow, drilling: restCurrentDrilling }
      ) || wellsAreDifferent(wellsA || [], wellsB || [])
    );
  });

const currentFormSnapshotAtom = atom<
  AddUndefinedToArray<EvaluationForm> | undefined
>(undefined);

const saveEvaluationConfigBaseAtom = atom<EvaluationConfigAtomValue>({
  loading: false,
  evaluation: undefined,
});
const saveEvaluationBaseAtom = atom<EvaluationAtom>({ loading: false });

const saveEvaluationConfigAtom = atom<
  EvaluationConfigAtomValue,
  [
    | {
        evaluationUrl: string;
        evaluationForm: AtomizedForm<EvaluationForm>;
        abortController: AbortController;
        isLoadingStoredEvaluation: boolean;
      }
    | { reset: boolean }
  ],
  void
>(
  (get) => get(saveEvaluationConfigBaseAtom),
  async (get, set, payload) => {
    if ('reset' in payload) {
      if (payload.reset) {
        set(saveEvaluationConfigBaseAtom, {
          loading: false,
          evaluation: undefined,
        });
      }
      return;
    }

    set(
      currentFormSnapshotAtom,
      get(getFormValuesAtom(atom(payload.evaluationForm), 'all-values'))
    );

    const {
      evaluationForm,
      evaluationUrl,
      abortController,
      isLoadingStoredEvaluation,
    } = payload;

    if (!isLoadingStoredEvaluation) {
      set(showDraftAtom, true);
    }

    set(saveEvaluationConfigBaseAtom, {
      loading: true,
    });
    set(saveEvaluationBaseAtom, {
      loading: false,
      saved: false,
      error: undefined,
    });

    try {
      const formPayload = getEvaluationPayload(evaluationForm, get);
      const result = await request.post<
        DevelopmentAreaEvaluationResponse,
        EvalError
      >(evaluationUrl, formPayload, abortController);

      if (result.type === 'success') {
        set(saveEvaluationConfigBaseAtom, {
          loading: false,
          evaluation: result.data,
        });
        return;
      }

      if (result.type === 'error') {
        set(saveEvaluationConfigBaseAtom, {
          loading: false,
          error: result.data?.errors.output.join('\n'),
          evaluation: undefined,
        });
        return;
      }

      if (result.type === 'fatal') {
        set(saveEvaluationConfigBaseAtom, {
          loading: false,
          error: 'There was an error saving the snapshot',
          evaluation: undefined,
        });
        return;
      }
    } catch (e) {
      set(saveEvaluationConfigBaseAtom, {
        loading: false,
        error: 'There was an error saving the snapshot',
        evaluation: undefined,
      });
    }
  }
);

const saveEvaluationAtom = atom<
  EvaluationAtom,
  [
    {
      saveHref: string;
      evaluationForm: AtomizedForm<EvaluationForm> | undefined;
      abortController: AbortController;
      onSuccess: () => void;
    }
  ],
  void
>(
  (get) => get(saveEvaluationBaseAtom),
  async (get, set, payload) => {
    const { abortController, onSuccess, saveHref, evaluationForm } = payload;

    if (!evaluationForm) return;

    set(saveEvaluationBaseAtom, {
      loading: true,
    });

    try {
      const formPayload = getEvaluationPayload(evaluationForm, get);
      formPayload.persist = true;
      const result = await request.post<unknown, EvalError>(
        saveHref,
        formPayload,
        abortController
      );

      if (result.type === 'success') {
        set(saveEvaluationBaseAtom, {
          loading: false,
          saved: true,
        });
        onSuccess();
        return;
      }

      if (result.type === 'error') {
        set(saveEvaluationBaseAtom, {
          loading: false,
          saved: false,
          error: result.data?.errors.output.join('\n'),
        });
        return;
      }
    } catch (e) {
      set(saveEvaluationBaseAtom, {
        loading: false,
        error: 'There was an error saving the snapshot',
      });
    }
  }
);

const showDraftAtom = atom<boolean>(false);

export {
  defaultAssumptionsAtom,
  defaultAssumptionsMapAtom,
  evaluationFormAtom,
  dirtyEvaluationAtom,
  saveEvaluationAtom,
  saveEvaluationConfigAtom,
  showDraftAtom,
  slotScheduleMapAtom,
};
