import { useMemo, useState } from 'react';
import {
  ComboBox,
  InlineLoading,
  InlineNotification,
  Link,
} from 'carbon-components-react';
import { Link as RouterLink } from 'components/link';
import {
  PackageResponse,
  PackageTitleResponse,
} from 'types/packages-api/responses';
import { Date } from 'components/date';
import style from './packages-summary.module.scss';
import { createResourceAtom } from 'lib/atoms';
import { useAtomValue } from 'jotai';
import { DECIMAL_FORMATTER_TWO_FRACTIONS } from 'lib/ui';

const NO_DATA = '--';

const TitleDetails = ({ href }: { href: string }) => {
  const titleDetailsAtom = useMemo(
    () => createResourceAtom<PackageTitleResponse[]>(href),
    [href]
  );
  const titleDetails = useAtomValue(titleDetailsAtom);

  if (titleDetails.data && 'error' in titleDetails.data)
    return (
      <InlineNotification
        kind="error"
        title={titleDetails.data.error}
        lowContrast
      />
    );

  if (titleDetails.loading) return <InlineLoading />;
  if (!titleDetails.data) return null;
  const data = titleDetails.data;

  return (
    <ComboBox
      id="package-title-details"
      items={data || []}
      placeholder="Search"
      itemToString={(data) => {
        return data ? data.name : '';
      }}
      onChange={({ selectedItem }) => {
        if (selectedItem)
          window.open(selectedItem.href, '_blank', 'noopener,noreferrer');
      }}
      light
    />
  );
};

const PackagesSummaryTable = ({
  packageData,
}: {
  packageData: PackageResponse;
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const note = packageData.notes || NO_DATA;
  const noteText =
    note.length <= 170 ? note : showMore ? note : `${note.slice(0, 170)} ...`;

  return (
    <div className={style.packagesSummaryData}>
      {packageData.resources.entity && (
        <dl>
          <dt>Account</dt>
          <dd>
            <RouterLink
              to={packageData.resources.entity.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {packageData.resources.entity.name}
            </RouterLink>
          </dd>
        </dl>
      )}
      {packageData.sellerName && (
        <dl>
          <dt>Seller</dt>
          <dd>{packageData.sellerName}</dd>
        </dl>
      )}
      {packageData.acquirerName && (
        <dl>
          <dt>Acquire Entity</dt>
          <dd>{packageData.acquirerName}</dd>
        </dl>
      )}
      {packageData.closeDate && (
        <dl>
          <dt>Close Date</dt>
          <dd>
            <Date date={packageData.closeDate} />
          </dd>
        </dl>
      )}
      {packageData.totalAcreage > 0 && (
        <dl>
          <dt>Total Acreage</dt>
          <dd>
            {DECIMAL_FORMATTER_TWO_FRACTIONS.format(packageData.totalAcreage)}
          </dd>
        </dl>
      )}
      {packageData.effectiveDate && (
        <dl>
          <dt>Effective Date</dt>
          <dd>
            <Date date={packageData.effectiveDate} />
          </dd>
        </dl>
      )}
      {packageData.includeChildren !== null && (
        <dl>
          <dt>Include Children</dt>
          <dd>{packageData.includeChildren ? 'Yes' : 'No'}</dd>
        </dl>
      )}
      <dl>
        <dt>Notes</dt>
        <dd className={style.packageNotes}>
          <p>{noteText}</p>
          {note.length > 170 && (
            <Link onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Show less' : 'Show more'}
            </Link>
          )}
        </dd>
      </dl>
      <dl className={style.titleDetailsDropdown}>
        <dt>Title Details</dt>
        <dd>
          <TitleDetails href={packageData.resources.title.href} />
        </dd>
      </dl>
    </div>
  );
};

export { PackagesSummaryTable };
