import { TitleCalculationDetailsResponse } from 'types/api-responses';
import {
  DepthCalculationDetails,
  OwnersCalculationDetails,
  StickKeys,
  TabularCalculationDetails,
  TractsCalculationDetails,
} from 'types/title-calculations/types';

const sticks: StickKeys[] = [
  'bonus',
  'delay',
  'executive',
  'fixedRoyalty',
  'netRevenueInterest',
  'override',
  'royalty',
  'surface',
  'working',
];

type FilterFn = (el: { changed: boolean }) => boolean;

const collectBurdenedEntities = (owner: OwnersCalculationDetails) => {
  const burdenedEntities: OwnersCalculationDetails['entity'][] = [];

  sticks.forEach((stick) => {
    owner[stick]?.byBurdenedEntity.map((el) => {
      if (el.entity) {
        const found =
          burdenedEntities.filter((e) => e.id === el.entity?.id).length > 0;
        if (!found) {
          burdenedEntities.push(el.entity);
        }
      }
    });
  });

  burdenedEntities.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });

  return burdenedEntities;
};

const calculateTransactionRowSpan = (
  interpretation: TitleCalculationDetailsResponse,
  filterFn: FilterFn
) => {
  return interpretation.tracts.reduce((acc, current) => {
    return (
      acc +
      current.depths.reduce((acc, depth) => {
        return acc + depth.owners.filter(filterFn).length;
      }, 0)
    );
  }, 0);
};
const calculateTractRowSpan = (
  tract: TractsCalculationDetails,
  filterFn: FilterFn
) => {
  return tract.depths.reduce((acc, depth) => {
    return acc + depth.owners.filter(filterFn).length;
  }, 0);
};
const calculateDepthRowSpan = (
  depth: DepthCalculationDetails,
  filterFn: FilterFn
) => {
  return depth.owners.filter(filterFn).length;
};

const prepareData = (
  response: TitleCalculationDetailsResponse[],
  showChangedOnly = true
) => {
  const tabularData: TabularCalculationDetails[] = [];

  let seenTransaction: Record<number, boolean> = {};
  let seenTract: Record<string, boolean> = {};
  let seenDepth: Record<string, boolean> = {};

  const filterFn = (el: { changed: boolean }) => {
    return showChangedOnly ? el.changed : true;
  };

  response.forEach((interpretation) => {
    seenTransaction = {};
    interpretation.tracts.filter(filterFn).forEach((tract) => {
      seenTract = {};
      seenDepth = {};
      tract.depths.filter(filterFn).forEach((depth) => {
        depth.owners.sort((a, b) => {
          if (a.role === b.role) {
            return a.entity.name < b.entity.name ? -1 : 1;
          }
          return a.role < b.role ? -1 : 1;
        });
        depth.owners.filter(filterFn).forEach((owner) => {
          tabularData.push({
            transactionId: interpretation.transactionId,
            expiration: interpretation.expiration,
            interpretation: interpretation.interpretation,
            entity: owner,
            tract: tract.tractName,
            depth: depth.depth,
            transactionRowSpan: !seenTransaction[interpretation.transactionId]
              ? calculateTransactionRowSpan(interpretation, filterFn)
              : undefined,
            tractRowSpan: !seenTract[tract.tractName]
              ? calculateTractRowSpan(tract, filterFn)
              : undefined,
            depthRowSpan: !seenDepth[depth.depth]
              ? calculateDepthRowSpan(depth, filterFn)
              : undefined,
            burdenedEntities: collectBurdenedEntities(owner),
          });
          seenTract[tract.tractName] = true;
          seenDepth[depth.depth] = true;
          seenTransaction[interpretation.transactionId] = true;
        });
      });
    });
  });

  return tabularData;
};

export { prepareData };
