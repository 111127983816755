import {
  Checkbox,
  Tile,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from 'carbon-components-react';
import { AtomizedForm } from 'atoms/form-atoms';
import { useAtom } from 'jotai';
import style from './discount-rate-assumptions.module.scss';
import { TierCashflowsPayload } from 'types/scenarios-api/responses';
import { NumberField } from 'components/form-fields/number-field';
import { ScenariosForm } from '../types';
import { useValidateEconomicLimitsAtom } from '../validations';

const DiscountRateAssumptions = ({
  form,
}: {
  form:
    | AtomizedForm<
        ScenariosForm['globalAssumptions']['cashflowAssumptionAttributes']
      >
    | AtomizedForm<TierCashflowsPayload>;
}) => {
  const increasedCommissionPricing =
    form.pricingCaseAttributes.increasedCommissionPricing;
  const targetPricing = form.pricingCaseAttributes.targetPricing;
  const maxPricing = form.pricingCaseAttributes.maxPricing;
  const irr =
    'irr' in form.pricingCaseAttributes
      ? form.pricingCaseAttributes.irr
      : undefined;
  const markToMarketPricing = form.pricingCaseAttributes.markToMarketPricing;
  const hurdleRates = form.economicLimitAttributes;

  const [{ value: applyLtdHurdleRate }, setApplyLtdHurdleRate] = useAtom(
    hurdleRates.applyLTDHurdleRate
  );
  const validateLtdHurdleRate = useValidateEconomicLimitsAtom(
    hurdleRates.applyLTDHurdleRate,
    hurdleRates.ltdHurdleRate
  );

  const [{ value: applyDucHurdleRate }, setApplyDucHurdleRate] = useAtom(
    hurdleRates.applyDUCHurdleRate
  );
  const validateDucHurdleRate = useValidateEconomicLimitsAtom(
    hurdleRates.applyDUCHurdleRate,
    hurdleRates.ducHurdleRate
  );

  const [{ value: applyPermitHurdleRate }, setApplyPermitHurdleRate] = useAtom(
    hurdleRates.applyPermitHurdleRate
  );
  const validatePermitHurdleRate = useValidateEconomicLimitsAtom(
    hurdleRates.applyPermitHurdleRate,
    hurdleRates.permitHurdleRate
  );

  return (
    <>
      <Tile>
        <h2 className={style.subtitle}>Discounts rates assumptions</h2>
        <TableContainer>
          <Table
            aria-label="Discounts rates"
            size="short"
            className={irr ? style.discountTableIRR : style.discountTable}
          >
            <TableHead>
              <TableRow>
                <TableHeader scope="col" colSpan={3}>
                  Discount Rate
                </TableHeader>
                <TableHeader scope="col">PDP (%)</TableHeader>
                <TableHeader scope="col">DUC (%)</TableHeader>
                <TableHeader scope="col">PERMIT (%)</TableHeader>
                <TableHeader scope="col">LTD (%)</TableHeader>
                {irr ? <TableHeader scope="col">IRR (%)</TableHeader> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={style.increasedCommissionsSection}>
                <TableCell colSpan={3}>
                  Increased Commission (Acquisition)
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="increasedCommissionLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={increasedCommissionPricing.ltd}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Target (Acquisition)</TableCell>
                <TableCell>
                  <NumberField
                    id="targetPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="targetDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="targetPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="targetLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={targetPricing.ltd}
                  />
                </TableCell>
                {irr && (
                  <TableCell>
                    <NumberField
                      id="targetIrr"
                      light
                      min={0}
                      invalidText="Must be positive number"
                      field={irr.targetPricing}
                    />
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Max (Acquisition)</TableCell>
                <TableCell>
                  <NumberField
                    id="maxPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="maxDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="maxPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="maxLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={maxPricing.ltd}
                  />
                </TableCell>
                {irr ? (
                  <TableCell>
                    <NumberField
                      id="maxIrr"
                      light
                      min={0}
                      invalidText="Must be positive number"
                      field={irr.maxPricing}
                    />
                  </TableCell>
                ) : null}
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Mark to market (MTM)</TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketPdp"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.pdp}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.duc}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketPermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.permit}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="markToMarketLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={markToMarketPricing.ltd}
                  />
                </TableCell>
                {irr ? <TableCell></TableCell> : null}
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Hurdle Rates</TableCell>
                <TableCell></TableCell>
                <TableCell className={style.hurdleRateCell}>
                  <NumberField
                    id="hurdleRateDuc"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={hurdleRates.ducHurdleRate}
                    disabled={!applyDucHurdleRate}
                  />
                  <Checkbox
                    id={'applyDucHurdleRate'}
                    className={style.hurdleRateCheckbox}
                    checked={applyDucHurdleRate}
                    onChange={(checked: boolean) => {
                      setApplyDucHurdleRate(checked);
                      validateDucHurdleRate();
                    }}
                    labelText="Apply"
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="hurdleRatePermit"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={hurdleRates.permitHurdleRate}
                    disabled={!applyPermitHurdleRate}
                  />
                  <Checkbox
                    id={'applyPermitHurdleRate'}
                    className={style.hurdleRateCheckbox}
                    checked={applyPermitHurdleRate}
                    onChange={(checked: boolean) => {
                      setApplyPermitHurdleRate(checked);
                      validatePermitHurdleRate();
                    }}
                    labelText="Apply"
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    id="hurdleRateLtd"
                    light
                    min={0}
                    invalidText="Must be positive number"
                    field={hurdleRates.ltdHurdleRate}
                    disabled={!applyLtdHurdleRate}
                  />
                  <Checkbox
                    id={'applyLtdHurdleRate'}
                    className={style.hurdleRateCheckbox}
                    checked={applyLtdHurdleRate}
                    onChange={(checked: boolean) => {
                      setApplyLtdHurdleRate(checked);
                      validateLtdHurdleRate();
                    }}
                    labelText="Apply"
                  />
                </TableCell>
                {irr ? <TableCell></TableCell> : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Tile>
    </>
  );
};

export { DiscountRateAssumptions };
