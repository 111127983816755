import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { formAtoms } from '../atoms';
import {
  OrriGranteeSubform,
  OverridingRoyaltyTransactionAtoms,
  OverridingRoyaltyTransactionForm,
} from './types';

import style from './transaction.module.scss';
import {
  Button,
  Checkbox,
  Column,
  Grid,
  RadioButton,
  RadioButtonGroup,
  Row,
  TextInput,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import { EntityRow } from './entity-row';
import {
  initConveyanceOrriEmptyForm,
  initDocumentReferencesEmptyForm,
  initEntityEmptyForm,
  OrriGranteeSubformAtoms,
} from './utils';
import { FormRow } from 'components/forms';
import { DocumentReferenceFormRow } from './document-reference-row';
import { DocumentReferencesModal } from '../document-reference/document-references-modal';
import { getError } from 'atoms/create-resource-atom';
import { WellboreSection } from './wellbore-section';

interface GranteeRowProps {
  atoms: OrriGranteeSubformAtoms;
  trxRowDisabled: boolean;
  trxIdx: number;
  granteeIdx: number;
  trxAtoms: OverridingRoyaltyTransactionAtoms['formAtom'];
}

const GranteeRow = ({
  atoms,
  trxRowDisabled,
  trxIdx,
  granteeIdx,
  trxAtoms,
}: GranteeRowProps) => {
  const setFieldTrx = useSetAtom(trxAtoms);
  const [fields, setField] = useAtom(atoms.row.formAtom);
  const rowStatus = useAtomValue(atoms.status);

  const isDiabled = trxRowDisabled || rowStatus === 'trash';
  return (
    <>
      <EntityRow
        id={`${trxIdx}-${granteeIdx}-grantee`}
        key={granteeIdx}
        atoms={atoms}
        atomIdx={granteeIdx}
        trxRowDisabled={isDiabled}
        field={'Grantee'}
        onDelete={(atomIdx) =>
          setFieldTrx((form) => ({
            field: 'grantee',
            value: [
              ...form['grantee'].value.slice(0, atomIdx),
              ...form['grantee'].value.slice(atomIdx + 1),
            ],
          }))
        }
      />
      <FormRow>
        <Grid fullWidth className="bx--no-gutter">
          <Row>
            <Column sm={1} md={3} lg={7}>
              <TextInput
                light
                labelText="ORRI interest"
                helperText=""
                value={fields.multiplier.value || ''}
                onChange={({ target: { value } }) =>
                  setField({ field: 'multiplier', value })
                }
                id={`${trxIdx}-${granteeIdx}-multiplier-orri`}
                invalid={!!getError<OrriGranteeSubform>(fields, 'multiplier')}
                invalidText={getError<OrriGranteeSubform>(fields, 'multiplier')}
                disabled={isDiabled}
              />
            </Column>
            <hr className={style.hrSeparator} />
          </Row>
        </Grid>
      </FormRow>
    </>
  );
};

interface Props {
  row: OverridingRoyaltyTransactionAtoms;
  idx: number;
}
const OverridingRoyaltyTransaction = ({ row, idx }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setField] = useAtom(row.formAtom);

  const trxRowStatusAtom = useMemo(
    () =>
      atom((get) => {
        const formAtom = get(formAtoms).formAtom;
        const transactionStateAtom =
          get(formAtom).transactions.value[idx].status;
        return get(transactionStateAtom);
      }),
    [idx]
  );

  const docReferences = fields.documentReference.value;
  const usedDocumentsAtom = useMemo(
    () =>
      atom((get) => {
        return docReferences.flatMap((el) => {
          const doc = get(el.row.formAtom);
          const docId = doc.document.value?.id;
          return docId ?? [];
        });
      }),
    [docReferences]
  );

  const trxRowStatus = useAtomValue(trxRowStatusAtom);
  const trxRowDisabled = trxRowStatus === 'trash';

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <>
      <FormRow toTrash={trxRowDisabled}>
        {fields.grantor.value.map((grantor, i) => {
          return (
            <EntityRow
              id="overriding"
              key={i}
              atoms={grantor}
              atomIdx={i}
              trxRowDisabled={trxRowDisabled}
              field={'Grantor'}
              onDelete={(atomIdx) =>
                setField((form) => ({
                  field: 'grantor',
                  value: [
                    ...form['grantor'].value.slice(0, atomIdx),
                    ...form['grantor'].value.slice(atomIdx + 1),
                  ],
                }))
              }
            />
          );
        })}
        <hr className={style.hrSeparator} />
        <Button
          renderIcon={Add16}
          kind={'tertiary'}
          size="md"
          onClick={() => {
            setField((form) => ({
              field: 'grantor',
              value: [...form.grantor.value, initEntityEmptyForm()],
            }));
          }}
          disabled={trxRowDisabled}
        >
          Add Grantor
        </Button>
        <hr className={style.sectionSeparator} />
      </FormRow>
      <FormRow toTrash={trxRowDisabled}>
        {fields.grantee.value.map((grantee, i) => {
          return (
            <div className={style.conveyanceGrid} key={i}>
              <GranteeRow
                key={i}
                atoms={grantee}
                trxAtoms={row.formAtom}
                trxIdx={idx}
                granteeIdx={i}
                trxRowDisabled={trxRowDisabled}
              />
            </div>
          );
        })}
        <hr className={style.hrSeparator} />
        <Button
          renderIcon={Add16}
          kind={'tertiary'}
          size="md"
          onClick={() => {
            setField((form) => ({
              field: 'grantee',
              value: [...form.grantee.value, initConveyanceOrriEmptyForm()],
            }));
          }}
          disabled={trxRowDisabled}
        >
          Add Grantee
        </Button>
        <hr className={style.sectionSeparator} />
      </FormRow>
      <FormRow>
        <Checkbox
          id={`${idx}-toggle-all-leases`}
          labelText="All leases"
          checked={fields.allLeases.value}
          onChange={() => {
            setField((form) => ({
              field: 'allLeases',
              value: !form.allLeases.value,
            }));
          }}
          disabled={trxRowDisabled}
        />

        {fields.documentReference.value.map((docAtoms, i) => {
          return (
            <DocumentReferenceFormRow
              key={i}
              atoms={docAtoms}
              atomIdx={i}
              trxRowDisabled={trxRowDisabled || fields.allLeases.value}
              onDelete={(atomIdx) =>
                setField((form) => ({
                  field: 'documentReference',
                  value: [
                    ...form['documentReference'].value.slice(0, atomIdx),
                    ...form['documentReference'].value.slice(atomIdx + 1),
                  ],
                }))
              }
            />
          );
        })}
        {!fields.allLeases.value && (
          <>
            <DocumentReferencesModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              usedDocumentsAtom={usedDocumentsAtom}
              onAddDocument={(doc) => {
                setField((form) => ({
                  field: 'documentReference',
                  value: [
                    ...form.documentReference.value,
                    initDocumentReferencesEmptyForm(doc),
                  ],
                }));
              }}
            />
            <Button
              kind="tertiary"
              size="md"
              renderIcon={Add16}
              onClick={() => setModalOpen(true)}
            >
              Add Document Reference
            </Button>
          </>
        )}
      </FormRow>
      <FormRow toTrash={trxRowDisabled}>
        <div className={style.radioButtonGroup}>
          <RadioButtonGroup
            className={style.radioGroupLabel}
            name={`of-type-${idx}`}
            valueSelected={fields.ofType.value}
            onChange={(value) =>
              setField({ field: 'ofType', value: String(value) })
            }
            disabled={trxRowDisabled}
          >
            <RadioButton labelText="WI" value="interest" />
            <RadioButton labelText="of 8/8" value="8/8" />
          </RadioButtonGroup>
          {getError<OverridingRoyaltyTransactionForm>(fields, 'ofType') ? (
            <div className={style.radioGroupError} data-invalid>
              {fields.ofType.error}
            </div>
          ) : null}
        </div>
      </FormRow>
      <WellboreSection
        wellReferenceAtoms={fields.wellReference.value}
        trnIdx={idx}
        trxRowDisabled={trxRowDisabled}
      />
    </>
  );
};

export { OverridingRoyaltyTransaction };
