import { Map } from 'components/map';
import { getMapDefaultConfig } from 'components/map/atoms';
import { atomWithStorage } from 'jotai/utils';
import style from './page.module.scss';

const mapConfigAtom = atomWithStorage(
  'insights-summary-map-config',
  getMapDefaultConfig({
    developmentAreas: {
      checked: true,
      options: {
        units: true,
        pricing: true,
      },
    },
    scrollZoom: {
      checked: true,
    },
  })
);

const DevelopmentAreaSummaryMapPage = () => {
  const latitude = 31.72;
  const longitude = -102.26;
  return (
    <div className={style.wrapper}>
      <Map
        latitude={latitude}
        longitude={longitude}
        className={style.map}
        zoom={13}
        showControls
        showMapFilters
        mapConfigAtom={mapConfigAtom}
      />
    </div>
  );
};

export { DevelopmentAreaSummaryMapPage };
