import { AtomField } from 'atoms/form-atoms';
import { useMemo } from 'react';
import { atom } from 'jotai';

const useValidateSpudAndFirstDatePercentage = (
  percentageCostSpudDate: AtomField<number>,
  firstDatePercentage: AtomField<number>
) => {
  return useMemo(
    () =>
      createSpudAndFirstDatePercentageValidAtom(
        percentageCostSpudDate,
        firstDatePercentage
      ),
    [firstDatePercentage, percentageCostSpudDate]
  );
};

const createSpudAndFirstDatePercentageValidAtom = (
  spudDatePercentage: AtomField<number>,
  firstDatePercentage: AtomField<number>
) => {
  return atom((get) => {
    const spudPercentageValue = get(spudDatePercentage).value;
    const firstDatePercentageValue = get(firstDatePercentage).value;

    const total =
      Math.round(spudPercentageValue) + Math.round(firstDatePercentageValue);

    return total === 100;
  });
};

export { useValidateSpudAndFirstDatePercentage };
