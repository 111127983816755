import { atom } from 'jotai';
import { createMutationAtom } from 'atoms/create-resource-atom';

import { TitleCalculationResponse } from 'types/api-responses';

const titleCalculationPusherErrorMessageAtom = atom('');

const newTitleCalculationAtom = createMutationAtom<
  TitleCalculationResponse,
  'workspaceCalculation'
>();

export { newTitleCalculationAtom, titleCalculationPusherErrorMessageAtom };
